
import Vue from 'vue';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import _keys from 'lodash/keys';
import ClipboardCopy from '@/components/ClipboardCopy.vue';

export default Vue.extend({
	name: 'AdminTeamStats',
	props: {
		team: { type: Object, required: true },
	},
	computed: {
		teamNumAdmins () {
			return _keys(this.team.adminIds || {}).length;
		},
		teamNumCreators () {
			return _keys(this.team.creatorIds || {}).length;
		},
	},
	components: {
		ClipboardCopy,
		MenuOrBottomSheet,
	},
});
