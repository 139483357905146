
import Vue from 'vue';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import { mapActions, mapState } from 'vuex';
import { db } from '@/firebase';
import moment from 'moment-timezone';
import _keys from 'lodash/keys';
import _isEmpty from 'lodash/isEmpty';
import { SNACKBAR_STATUS } from '@/constants/constants';
import AdminSoftwareOfferStats from '@/components/AdminStats/AdminSoftwareOfferStats.vue';
import ClipboardCopy from '@/components/ClipboardCopy.vue';
import { get$bindFirestoreOptions } from '@/mixins';
import AdminTeamStats from '@/components/AdminStats/AdminTeamStats.vue';

export default Vue.extend({
	name: 'AdminSessionStats',
	props: {
		session: { type: Object, default: null },
	},
	data () {
		return {
			apiKeysLoading: false,
			apiKeys: [],
		};
	},
	computed: {
		...mapState([
			'user',
			'toolbar',
			'appOffersMap',
			'hardwareOffersMap',
			'softwareOffersMap',
		]),
		initTimeStr () {
			if (!this.session || !this.session.initAt || !this.session.bootAt) return null;
			return `${moment(this.session.bootAt.toMillis()).diff(this.session.initAt.toMillis(), 'seconds')} sec`;
		},
		bootTimeStr () {
			if (!this.session || !this.session.bootAt || !this.session.readyAt) return null;
			return `${moment(this.session.readyAt.toMillis()).diff(this.session.bootAt.toMillis(), 'seconds')} sec`;
		},
		useToolbarApiKeys () {
			return !!(
				this.toolbar &&
				this.toolbar.session &&
				this.toolbar.session.id === this.session.id &&
				this.toolbar.sbudServices &&
				!_isEmpty(this.toolbar.sbudServices.secrets)
			);
		},
		computedApiKeys () {
			if (this.useToolbarApiKeys) {
				return _keys(this.toolbar.sbudServices.secrets)
					.map((id: string) => ({ id, key: this.toolbar.sbudServices.secrets[id] }));
			}
			return this.apiKeys;
		},
		apiKeysDisplayHtml () {
			return this.computedApiKeys.map(({ id, key }) => (
				key.startsWith('http://') || key.startsWith('https://')
					? `<a href="${key}" target="_blank">${id}</a>`
					: key
			)).join(' | ');
		},
		apiKeysDisplayText () {
			return this.computedApiKeys.map(({ key }) => key).join(' | ');
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		async loadApiKeys () {
			try {
				this.apiKeysLoading = true;
				const apiKeysRef = db.collection('sessions')
					.doc(this.session.id)
					.collection('apiKeys')
					.limit(1000);
				await this.$bind(
					'apiKeys',
					apiKeysRef,
					get$bindFirestoreOptions(),
				);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading apiKeys',
					show: true,
				});
			} finally {
				this.apiKeysLoading = false;
			}
		},
	},
	components: {
		AdminTeamStats,
		ClipboardCopy,
		AdminSoftwareOfferStats,
		MenuOrBottomSheet,
	},
});
