import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"outer-container",style:({
		'padding': _vm.hasBtnsSlot ? '8px' : '8px 20px',
		...(_vm.showHighlightAlert && {
			'background': 'darkred !important',
		}),
	})},[(!_vm.hideBtnsIfIsLaunchedWorkshopSessionAsAttendee || !_vm.isLaunchedWorkshopSessionAsAttendee)?_vm._t("btns"):_vm._e(),_c(VExpandTransition,[(_vm.showCounter)?_c('div',{staticClass:"counter-text",style:({
				'font-size': _vm.$vuetify.breakpoint.xsOnly ? '12px' : '16px',
				'margin': _vm.hasBtnsSlot ? '0 3px 0 10px' : '0 5px 0 5px'
			})},[_vm._v(" "+_vm._s(_vm.isCountingUp ? _vm.countUpTimerVal : _vm.countDownTimerVal)+" ")]):_vm._e()]),(!_vm.hideToggleShowCounter)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({class:{
					'ml-2': !_vm.showCounter,
				},staticStyle:{"color":"var(--blue-grey-200)"},attrs:{"fab":"","icon":"","text":"","x-small":""},on:{"click":_vm.toggleShowCounter}},'base-button',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v(_vm._s(_vm.showCounter ? 'mdi-eye-outline' : 'mdi-eye-off-outline'))])],1)]}}],null,false,3454008914)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(_vm._s(_vm.showCounter ? 'Hide' : 'Show')+" the counter timer")])]):_vm._e(),(!_vm.hideAdminStats && _vm.launchedSession && (_vm.user.isAdmin || _vm.isAdminProxyingUser))?_c('admin-session-stats',{attrs:{"session":_vm.launchedSession}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }