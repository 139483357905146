<template>
	<v-hover v-slot:default="{ hover }">
		<div style="position: relative">
			<v-expand-transition>
				<div
					v-if="hover && hasOverflow && !omitTopArrow && !atStart"
					class="transition-fast-in-fast-out hovering-arrow"
					style="top: 16px;"
				>
					<v-icon
						class="white--text"
						style="font-size: 18px"
					>mdi-arrow-up</v-icon>
				</div>
			</v-expand-transition>

			<div
				class="scrollable"
				:class="{
					'scroll-border-bottom': !omitBottomArrow && !removeShadow && (!hover || !hasOverflow || atEnd),
					'scroll-border-top': !omitTopArrow && !removeShadow && (!hover || !hasOverflow || atStart),
					'hovering-inset-bottom-shadow-only': showHoveringBottomShadow(hover) && !showHoveringTopShadow(hover),
					'hovering-inset-top-shadow-only': !showHoveringBottomShadow(hover) && showHoveringTopShadow(hover),
					'hovering-inset-bottom-and-top-shadow': showHoveringBottomShadow(hover) && showHoveringTopShadow(hover),
				}"
				:style="{
					borderBottom: showHoveringBottomShadow(hover) && !showHoveringTopShadow(hover) ? '1px solid rgba(46, 59, 80, 0.58)' : 'none',
					borderTop: !showHoveringBottomShadow(hover) && showHoveringTopShadow(hover) ? '1px solid rgba(46, 59, 80, 0.58)' : 'none',
				}"
				ref="scrollContainer"
				:id="scrollRootId"
				@mouseover="onScrollContainerMouseOver"
			>
				<slot name="scrollable"></slot>
			</div>

			<v-expand-transition>
				<div
					v-if="hover && hasOverflow && !omitBottomArrow && !atEnd"
					class="transition-fast-in-fast-out hovering-arrow"
					style="bottom: 0;"
				>
					<v-icon
						style="font-size: 18px"
						class="white--text"
					>mdi-arrow-down</v-icon>
				</div>
			</v-expand-transition>
		</div>
	</v-hover>
</template>

<script>
export default {
	props: {
		removeShadow: { type: Boolean, default: false },
		omitTopArrow: { type: Boolean, default: false },
		omitBottomArrow: { type: Boolean, default: false },
		scrollRootId: { type: String, default: null },
		borderColor: { type: String, default: 'rgba(46, 59, 80, 0.58)' },
	},
	data () {
		return {
			hasOverflow: false,
			atEnd: false,
			atStart: false,
		};
	},
	methods: {
		showHoveringBottomShadow (hover) {
			return !this.removeShadow && hover && this.hasOverflow && !this.atEnd;
		},
		showHoveringTopShadow (hover) {
			return !this.omitTopArrow && !this.removeShadow && hover && this.hasOverflow && !this.atStart;
		},
		onScrollContainerMouseOver () {
			const { scrollContainer } = this.$refs;

			// has overflow
			this.hasOverflow = (
				scrollContainer &&
				scrollContainer.offsetHeight < scrollContainer.scrollHeight
			);

			// is scrolled to bottom or top
			const epsilon = 5; // margin of error
			const containerHeight = (
				scrollContainer.scrollHeight - scrollContainer.offsetHeight
			);
			const bottomScrollDiff = Math.abs(
				scrollContainer.scrollTop - containerHeight
			);
			const topScrollDiff = Math.abs(
				containerHeight - bottomScrollDiff
			);

			this.atEnd = bottomScrollDiff <= epsilon;
			this.atStart = topScrollDiff <= epsilon;
		},
	},
};
</script>

<style lang="scss" scoped>
.scroll-border-bottom {
	border-bottom: 1px solid transparent;
}
.scroll-border-top {
	border-top: 1px solid transparent;
}
.hovering-inset-bottom-shadow-only {
	-webkit-box-shadow: inset 0 -12px 12px -12px rgba(46, 59, 80, 0.58);
	-moz-box-shadow: inset 0 -12px 12px -12px rgba(46, 59, 80, 0.58);
	box-shadow: inset 0 -12px 12px -12px rgba(46, 59, 80, 0.58);
}
.hovering-inset-top-shadow-only {
	-webkit-box-shadow: inset 0 12px 12px -12px rgba(46, 59, 80, 0.58);
	-moz-box-shadow: inset 0 12px 12px -12px rgba(46, 59, 80, 0.58);
	box-shadow: inset 0 12px 12px -12px rgba(46, 59, 80, 0.58);
}
.hovering-inset-bottom-and-top-shadow {
	border-bottom: 1px solid #DDD;
	border-top: 1px solid #DDD;
	-webkit-box-shadow: inset 0 -12px 12px -12px rgba(46, 59, 80, 0.58), inset 0 12px 12px -12px rgba(46, 59, 80, 0.58);
	-moz-box-shadow: inset 0 -12px 12px -12px rgba(46, 59, 80, 0.58), inset 0 12px 12px -12px rgba(46, 59, 80, 0.58);
	box-shadow: inset 0 -12px 12px -12px rgba(46, 59, 80, 0.58), inset 0 12px 12px -12px rgba(46, 59, 80, 0.58);
}
.hovering-arrow {
	z-index: 40;
	background: rgba(17, 25, 40, 0.7);
	border: 1px solid rgba(255, 255, 255, 0.125);
	padding: 2px 4px;
	border-radius: 50px;
	position: absolute;
	left: calc(50% - 8px); /*8px is half of width of this icon*/
}
</style>
