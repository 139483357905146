import { render, staticRenderFns } from "./RunnitsPromoCard.vue?vue&type=template&id=3510a2b7&scoped=true"
import script from "./RunnitsPromoCard.vue?vue&type=script&lang=ts"
export * from "./RunnitsPromoCard.vue?vue&type=script&lang=ts"
import style0 from "./RunnitsPromoCard.vue?vue&type=style&index=0&id=3510a2b7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3510a2b7",
  null
  
)

export default component.exports