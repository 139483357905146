
import Vue from 'vue';
import DreamBoothPostLaunch from '@/components/Tutorials/DreamBoothPostLaunch/DreamBoothPostLaunch.vue';
import DreamBoothPreLaunch from '@/components/Tutorials/DreamBoothPreLaunch/DreamBoothPreLaunch.vue';
import DreamBoothLaunched from '@/components/Tutorials/DreamBoothLaunched/DreamBoothLaunched.vue';

export default Vue.extend({
	name: 'WorkstationUi',
	props: {
		session: { type: Object, required: true },
	},
	data () {
		return {
			// Tabs
			DOWNLOAD_TAB: 'download-tab',
			SETUP_TAB: 'setup-tab',
			LAUNCHED_TAB: 'launched-tab',
			selectedTab: 'setup-tab',
		};
	},
	methods: {
		getColor (tab) {
			if (tab === this.selectedTab) {
				return 'primary';
			} else {
				return 'rgba(255, 255, 255, 0.6)';
			}
		},
		goToLaunched () {
			this.selectedTab = this.LAUNCHED_TAB;
		},
	},
	components: {
		DreamBoothLaunched,
		DreamBoothPreLaunch,
		DreamBoothPostLaunch,
	},
});
