import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.toolbar.session)?_c('div',{staticClass:"curr-session-info-container tw-shadow-xl",style:({
		...(_vm.minWidth && { minWidth: _vm.minWidth }),
		...(_vm.borderRadius && { borderRadius: _vm.borderRadius }),
		flexDirection: _vm.$vuetify.breakpoint.smAndUp ? 'row' : 'column',
		justifyContent: _vm.$vuetify.breakpoint.smAndUp ? 'space-between' : 'center',
		alignItems: _vm.$vuetify.breakpoint.smAndUp ? 'center' : 'center',
	})},[(_vm.loading)?_c('LoadingSVG',{class:{
			'mr-10': _vm.$vuetify.breakpoint.smAndUp,
			'mb-10': _vm.$vuetify.breakpoint.xsOnly,
		},attrs:{"height":_vm.$vuetify.breakpoint.smAndUp ? 160 : 100,"width":_vm.$vuetify.breakpoint.smAndUp ? 160 : 100}}):(_vm.showLogo)?_c('img',{staticClass:"logo-mobile",class:{
			'mr-4': _vm.$vuetify.breakpoint.smAndUp,
			'mb-4': _vm.$vuetify.breakpoint.xsOnly,
		},attrs:{"alt":"Logo","height":"50px","src":require("../assets/rd-logo-white-only.png")}}):_vm._e(),_c('div',[_vm._m(0),(_vm.showType && _vm.toolbar.session)?_c('div',{staticStyle:{"font-weight":"bold","color":"var(--grey-600)"},style:({ 'font-size': _vm.typeFontSize })},[_vm._v(" "+_vm._s(_vm.toolbar.session.teamId ? 'TEAM SESSION' : 'PERSONAL SESSION')+" ")]):_vm._e(),(_vm.showApp && _vm.toolbar.session && _vm.appOffersMap[_vm.toolbar.session.apps[0]])?_c('div',{staticStyle:{"font-weight":"bold"},style:({ 'font-size': _vm.appFontSize })},[_vm._v(" "+_vm._s(_vm.appOffersMap[_vm.toolbar.session.apps[0]].shortLabel)+" ")]):_vm._e(),(_vm.showHardware && _vm.hardwareOffersMap[_vm.toolbar.session.hardware])?_c('div',{staticClass:"d-flex justify-end",style:({ 'font-size': _vm.hardwareFontSize })},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":!_vm.xSmallIcons,"x-small":_vm.xSmallIcons}},[_vm._v(" mdi-server ")]),_vm._v(" "+_vm._s(_vm.hardwareOffersMap[_vm.toolbar.session.hardware].label)+" ")],1):_vm._e(),(_vm.showSoftware && _vm.softwareOffersMap[_vm.toolbar.session.software])?_c('div',{staticClass:"d-flex justify-end",style:({ 'font-size': _vm.softwareFontSize })},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":!_vm.xSmallIcons,"x-small":_vm.xSmallIcons}},[_vm._v(" mdi-content-save ")]),_vm._v(" "+_vm._s(_vm.softwareOffersMap[_vm.toolbar.session.software].shortLabel)+" ")],1):_vm._e(),(_vm.showTeam && _vm.toolbar.session.team)?_c('div',{staticStyle:{"font-weight":"bold"},style:({ 'font-size': _vm.teamFontSize })},[_vm._v(" "+_vm._s(_vm.toolbar.session.team.name)+" ")]):_vm._e(),(_vm.showWorkspace && _vm.toolbar.session.workspace)?_c('div',{staticStyle:{"font-weight":"bold"},style:({ 'font-size': _vm.workspaceFontSize })},[_vm._v(" "+_vm._s(_vm.toolbar.session.workspace.name)+" ")]):_vm._e(),(_vm.showMultiSessionsBtn)?_c('base-button',{staticClass:"mt-4",attrs:{"secondary":""},on:{"click":_vm.onMultiSessionClick}},[_vm._v(" Multi Sessions ")]):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sic-bg-gradient-blur-wrapper"},[_c('div',{staticClass:"sic-bg-gradient-blur-circle-4"}),_c('div',{staticClass:"sic-bg-gradient-blur-circle-3"}),_c('div',{staticClass:"sic-bg-gradient-blur-circle-2"}),_c('div',{staticClass:"sic-bg-gradient-blur-circle-1"})])
}]

export { render, staticRenderFns }