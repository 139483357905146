import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('menu-or-bottom-sheet',{attrs:{"menu-always-full-height":"","bottom-sheet-persistent":"","max-width":_vm.maxWidth,"min-width":_vm.minWidth,"max-height":_vm.maxHeight,"min-height":_vm.minHeight,"nudge-bottom":_vm.nudgeBottom,"nudge-left":_vm.nudgeLeft,"value":_vm.value,"close-on-click":false,"bottom-sheet-on-small":_vm.bottomSheetOnSmall,"bottom-sheet-on-medium":_vm.bottomSheetOnMedium,"is-bottom-sheet":_vm.isBottomSheet},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [_vm._t("trigger",null,{"on":on})]}},{key:"content",fn:function(){return [_c('div',{staticClass:"runnit-menu-bottom-sheet",style:({
				...(_vm.bottomSheetBreakPoint && {
					borderTopRightRadius: 'var(--global-border-radius)',
					borderTopLeftRadius: 'var(--global-border-radius)',
				}),
			})},[_c('div',{staticClass:"runnit-menu-bottom-sheet-top"},[_c('div',{staticClass:"runnit-menu-bottom-sheet-title"},[_c(VIcon,{staticClass:"gradient-text",staticStyle:{"margin":"0 0 0 8px","position":"relative","top":"-2px","font-size":"19px"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]),_c('div',{staticStyle:{"margin":"0 0 0 10px"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('div',{staticClass:"d-flex align-center"},[_vm._t("top-right"),_c('base-button',{staticStyle:{"margin":"0 5px 0 0"},attrs:{"icon":"","text":"","small":""},on:{"click":_vm.onClose}},[_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.3)","font-size":"30px"}},[_vm._v(" mdi-close-circle-outline ")])],1)],2)]),_c('div',{staticClass:"runnit-menu-bottom-sheet-bottom"},[_vm._t("background",function(){return [_c('complex-background-setting-menu',{attrs:{"top":_vm.$vuetify.breakpoint.xsOnly ? -170 : -250,"left":120}})]}),_vm._t("content")],2)])]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }