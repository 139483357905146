
import Vue from 'vue';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import _trim from 'lodash/trim';
import { getLastSsoProviderVal, setLastSsoProviderVal } from '@/utils';

export default Vue.extend({
	name: 'SsoProviderInputDialog',
	props: {
		value: { type: Boolean, default: false },
	},
	data () {
		return {
			ssoProviderVal: null,
		};
	},
	created () {
		this.ssoProviderVal = _trim(getLastSsoProviderVal()) || null;
	},
	computed: {
		computedSsoProviderValues () {
			const suffix: string = _trim(this.ssoProviderVal).toLowerCase().replace(/^saml\./, '');
			return {
				suffix: suffix || null,
				full: suffix ? `saml.${suffix}` : null,
			};
		},
	},
	methods: {
		onConfirm (closeCallback: Function) {
			if (!this.computedSsoProviderValues.full) return;
			setLastSsoProviderVal(this.computedSsoProviderValues.suffix);
			this.$emit('on-submit', closeCallback, this.computedSsoProviderValues.full);
		},
	},
	components: {
		ConfirmDialog,
	},
});
