
import Vue from 'vue';

export default Vue.extend({
	name: 'CircleIcon',
	props: {
		iconName: { type: String, default: '' },
		iconStylesObj: { type: Object, default: () => ({}) },
		iconFontSizeValue: { type: Number, default: 18 },
		iconColor: { type: String, default: 'primary' },
		widthHeightValue: { type: Number, default: 45 },
		borderSizeValue: { type: Number, default: 1 },
		borderColor: { type: String, default: '#d6d6d6' },
		backgroundColor: { type: String, default: 'white' },
		primaryBackgroundStylesObj: { type: Object, default: () => ({}) },
		preventMobileResize: { type: Boolean, default: false },
	},
	computed: {
		circleWidthHeightValue () {
			const mobileWidthHeight: number = this.preventMobileResize
				? this.widthHeightValue
				: this.widthHeightValue * 0.2;

			return this.$vuetify.breakpoint.xsOnly
				? `${mobileWidthHeight}px`
				: `${this.widthHeightValue}px`;
		},
	},
});
