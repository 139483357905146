
import Vue from 'vue';
import BaseDrawer from '@/components/base/BaseDrawer.vue';
import { mapActions, mapState } from 'vuex';
import EmptyState from '@/components/states/EmptyState.vue';
import { getProxyAdminToken } from '@/utils';
import {
	Session,
	SessionOptions,
	WorkshopSession,
	WorkshopSessionQueueItem,
	asyncForEach,
	getHardwareHourlyPrice,
} from '@run-diffusion/shared';
import WorkshopSessionCostPreview from '@/views/Workshops/WorkshopPage/AddEditWorkshopSession/WorkshopSessionCostPreview.vue';
import { CURR_SESSION_STATES, SNACKBAR_STATUS } from '@/constants/constants';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import { MyBalanceMixin } from '@/mixins';
import BigNumber from 'bignumber.js';
import BalanceDetailsDialog from '@/components/BalanceCard/BalanceDetailsDialog.vue';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import WorkshopSessionLinkDialog from '@/views/Workshops/WorkshopPage/WorkshopSessionLinkDialog.vue';
import ViewWorkshopSessionQueueItem from '@/views/Workshops/WorkshopPage/ViewWorkshopSessionQueueItem.vue';

export default Vue.extend({
	name: 'ViewWorkshopSessionQueueItems',
	mixins: [
		MyBalanceMixin,
	],
	props: {
		workshop: { type: Object, required: true },
		workshopSession: { type: Object, default: null },
		value: { type: Boolean, default: false },
		startSessionDisabled: { type: Boolean, default: false },
	},
	data () {
		return {
			sessionsMap: {}, // Map from Session ID -> Session
			isStartingSessionsMap: {}, // Map from WorkshopSession ID -> User ID -> boolean
			isStartingAnySession: false,
			isStartingAllSessions: false,
			startAllSessionCount: 0, // To show how many out of how many are in line to start when the "Start All Sessions" btn is clicked

			showInsufficientBalanceDialog: false,
		};
	},
	computed: {
		...mapState([
			'team',
			'workshopSessionQueueItems',
			'loadingWorkshopSessionQueueItems',
			'hardwareOffersMap',
		]),
		drawerTitle () {
			if (this.loadingWorkshopSessionQueueItems) return 'Attendees';
			return `Attendees (${this.workshopSessionQueueItems.length})`;
		},
		computedWorkshopSessionQueueItemsData () {
			let count: number = 0;
			let hasLive: boolean = false;
			this.workshopSessionQueueItems.forEach((item: WorkshopSessionQueueItem) => {
				if (this.isWorkshopSessionQueueItemEligibleToStart(item)) count++;
				if (
					item.sessionId &&
					this.sessionsMap[item.sessionId] &&
					CURR_SESSION_STATES.includes(this.sessionsMap[item.sessionId].state)
				) {
					hasLive = true;
				}
			}, 0);
			return {
				count,
				hasLive,
			};
		},
	},
	watch: {
		workshopSession: {
			immediate: true,
			handler (newVal: WorkshopSession, oldVal: WorkshopSession) {
				if (newVal && newVal !== oldVal) {
					this.bindWorkshopSessionQueueItems({
						workshopId: this.workshop.id,
						workshopSessionId: newVal.id,
					});
				}
			},
		},
		computedWorkshopSessionQueueItemsData: {
			immediate: true,
			handler (newVal: { count: number, hasLive: boolean }, oldVal: { count: number, hasLive: boolean }) {
				if (!!(newVal && newVal.hasLive) !== !!(oldVal && oldVal.hasLive)) {
					this.$emit('on-has-live-session', this.workshopSession, newVal.hasLive);
				}
			},
		},
		workshopSessionQueueItems: {
			immediate: true,
			handler (newVal: WorkshopSessionQueueItem[]) {
				// Reset is starting state for WorkshopSessionQueueItem to false
				(newVal || []).forEach((item: WorkshopSessionQueueItem) => this.initItemIsStartingSessionInMap(item, this.sessionsMap));
			},
		},
	},
	methods: {
		...mapActions([
			'bindWorkshopSessionQueueItems',
			'purchaseSession',
			'updateSnackbar',
		]),
		getHardwareHourlyPrice,
		setOpen (val: boolean) {
			if (val !== this.value) {
				this.$emit('input', !!val);
			}
		},
		closeDrawer () {
			this.setOpen(false);
			this.$emit('on-close');
		},
		getCostEstimateAmt (scheduledStopMins: number, hardwareHourlyPrice: number, numMultiply: number) {
			const purchaseHrsDecimal: BigNumber = new BigNumber(scheduledStopMins).div(60);
			const cost: BigNumber = purchaseHrsDecimal.times(hardwareHourlyPrice);
			const wholeIntCost: BigNumber = cost.integerValue(BigNumber.ROUND_HALF_UP);

			return wholeIntCost.times(numMultiply).toNumber();
		},
		isWorkshopSessionQueueItemEligibleToStart (item: WorkshopSessionQueueItem) {
			return !!(
				this.workshopSession &&
				!item.sessionId &&
				!(
					this.isStartingSessionsMap[this.workshopSession.id] &&
					this.isStartingSessionsMap[this.workshopSession.id][item.id]
				)
			)
		},
		initItemIsStartingSessionInMap (workshopSessionQueueItem: WorkshopSessionQueueItem, sessionMap: Record<string, Session>) {
			if (
				workshopSessionQueueItem.sessionId &&
				sessionMap[workshopSessionQueueItem.sessionId] &&
				CURR_SESSION_STATES.includes(sessionMap[workshopSessionQueueItem.sessionId].state) &&
				this.workshopSession &&
				this.isStartingSessionsMap[this.workshopSession.id] &&
				this.isStartingSessionsMap[this.workshopSession.id][workshopSessionQueueItem.id]
			) {
				this.setItemIsStartingSessionsMap(workshopSessionQueueItem, false);
			}
		},
		setItemIsStartingSessionsMap (workshopSessionQueueItem: WorkshopSessionQueueItem, val: boolean) {
			this.isStartingSessionsMap = {
				...this.isStartingSessionsMap,
				[this.workshopSession.id]: {
					...this.isStartingSessionsMap[this.workshopSession.id],
					[workshopSessionQueueItem.userId]: !!val,
				},
			};
		},
		onSessionFetched (workshopSessionQueueItem: WorkshopSessionQueueItem, session: Session) {
			if (!session) return;
			this.sessionsMap = {
				...this.sessionsMap,
				[session.id]: session,
			};
			this.initItemIsStartingSessionInMap(workshopSessionQueueItem, this.sessionsMap);
		},
		async startSessionForWorkshopSessionQueueItems (workshopSessionQueueItems: WorkshopSessionQueueItem[]) {
			if (this.startSessionDisabled) {
				return;
			}
			if (getProxyAdminToken() && !confirm(`You are proxying another user. Are you sure you want to use their account's balance to launch a session?`)) {
				return;
			}

			// Check if sufficient balance
			const currHardwareHourlyPrice: number = getHardwareHourlyPrice(this.hardwareOffersMap[this.workshopSession.hardware], this.team.id, null);
			const estimatedCost: number = this.getCostEstimateAmt(this.workshopSession.scheduledStopMins, currHardwareHourlyPrice, workshopSessionQueueItems.length);
			if (estimatedCost > this.computedTeamBalance) {
				this.showInsufficientBalanceDialog = true;
				return;
			}

			try {
				this.startAllSessionCount = workshopSessionQueueItems.length;
				this.isStartingAnySession = true;
				await asyncForEach(workshopSessionQueueItems, async (workshopSessionQueueItem: WorkshopSessionQueueItem, index: number) => {
					this.setItemIsStartingSessionsMap(workshopSessionQueueItem, true);
					if (index > 0) await new Promise(resolve => setTimeout(resolve, 5000)); // wait for 5 seconds before purchase

					const options: SessionOptions = {};
					const { success } = await this.purchaseSession({
						apps: this.workshopSession.apps,
						backendApps: [],
						hardware: this.workshopSession.hardware,
						software: this.workshopSession.software,
						options,
						apiEnabled: false,
						persistExtensions: false,
						preloadModel: null,
						purchaseMins: this.workshopSession.scheduledStopMins,
						displayName: `Session - ${workshopSessionQueueItem.userEmail}`,
						isTeamSession: true,
						workspaceId: this.workshopSession.workspaceId,
						region: this._get(this.team, 'region') || null,
						workshopSessionData: {
							workshopId: this.workshop.id,
							workshopSessionId: this.workshopSession.id,
							workshopSessionQueueItemId: workshopSessionQueueItem.id,
							userId: workshopSessionQueueItem.userId,
							userEmail: workshopSessionQueueItem.userEmail,
						},
					});

					this.startAllSessionCount--;
				});
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error starting session',
					show: true,
				});
			} finally {
				this.isStartingAnySession = false;
				this.startAllSessionCount = 0;
			}
		},
		async onStartAllSessionForWorkshopSessionQueueItems (closeDialogCallback: Function) {
			try {
				this.isStartingAllSessions = true;
				const filteredWorkshopSessionQueueItems: WorkshopSessionQueueItem[] = this.workshopSessionQueueItems
					.filter(this.isWorkshopSessionQueueItemEligibleToStart);
				await this.startSessionForWorkshopSessionQueueItems(filteredWorkshopSessionQueueItems);
			} catch (e) {
				console.error(e);
			} finally {
				this.isStartingAllSessions = false;
				if (closeDialogCallback) closeDialogCallback();
			}
		},
	},
	components: {
		WorkshopSessionLinkDialog,
		LoadingSVG,
		BalanceDetailsDialog,
		ConfirmDialog,
		WorkshopSessionCostPreview,
		EmptyState,
		BaseDrawer,
		ViewWorkshopSessionQueueItem,
	},
});
