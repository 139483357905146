
import Vue from 'vue';
import { getAuthProviderDisplayText } from '@/utils';
import SsoProviderInputDialog from '@/components/SsoProviderInputDialog.vue';

export default Vue.extend({
	name: 'AuthProviderPickers',
	props: {
		warningStepHtml: { type: String, default: null },
		continueWithText: { type: String, default: 'or continue with' },
		disabled: { type: Boolean, default: false },
		googleLoading: { type: Boolean, default: false },
		facebookLoading: { type: Boolean, default: false },
		microsoftLoading: { type: Boolean, default: false },
		githubLoading: { type: Boolean, default: false },
		ssoLoading: { type: Boolean, default: false },
		iconView: { type: Boolean, default: false },
		includeEmailPassword: { type: Boolean, default: false },
		includeSso: { type: Boolean, default: false },
		includeGoogle: { type: Boolean, default: false },
		includeFacebook: { type: Boolean, default: false },
		includeMicrosoft: { type: Boolean, default: false },
		includeGithub: { type: Boolean, default: false },
	},
	data () {
		return {
			ssoProviderInputDialogOpen: false,
			dismissedWarningStep: false,
		};
	},
	computed: {
		isAwaitingWarningStepDismissal () {
			return !!(
				this.warningStepHtml &&
				!this.dismissedWarningStep
			);
		},
	},
	methods: {
		getAuthProviderDisplayText,
		onEmailPasswordContinue () {
			if (!this.disabled) {
				this.$emit('on-email-password-continue');
			}
		},
		onSsoContinue (closeCallback: Function, providerId: string) {
			// Close this dialog cuz we are going to open the SSO popup after this anyway
			if (closeCallback) closeCallback();

			if (!this.disabled || this.ssoLoading) {
				this.$emit('on-sso-continue', providerId);
			}
		},
		onGoogleContinue () {
			if (!this.disabled || this.googleLoading) {
				this.$emit('on-google-continue');
			}
		},
		onFacebookContinue () {
			if (!this.disabled || this.facebookLoading) {
				this.$emit('on-facebook-continue');
			}
		},
		onMicrosoftContinue () {
			if (!this.disabled || this.microsoftLoading) {
				this.$emit('on-microsoft-continue');
			}
		},
		onGithubContinue () {
			if (!this.disabled || this.githubLoading) {
				this.$emit('on-github-continue');
			}
		},
	},
	components: {
		SsoProviderInputDialog,
	},
});
