
import Vue from 'vue';
import ListItem from '@/components/base/ListItem.vue';

export default Vue.extend({
	name: 'BorderSelectCard',
	props: {
		value: { type: Boolean, required: true },
		icon: { type: String, default: null },
		disabled: { type: Boolean, default: false },
		containerStyles: { type: Object, default: () => {} },
		small: { type: Boolean, default: false },
	},
	computed: {
		hasBottomRightContainerSlot () {
			return !!this.$slots.bottomRightContainer || !!this.$scopedSlots.bottomRightContainer;
		},
		hasContentSlot () {
			return !!this.$slots.content || !!this.$scopedSlots.content;
		},
	},
	methods: {
		onSelect () {
			if (!this.disabled) {
				this.$emit('on-click');
			}
		},
	},
	components: {
		ListItem,
	},
});
