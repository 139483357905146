
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import LoadingSVG from '@/assets/LoadingSVG.vue';

export default Vue.extend({
	computed: {
		...mapGetters([
			'isLoggingIn',
		]),
		...mapState([
			'authUser',
			'user',
			'loader',
		]),
	},
	components: {
		LoadingSVG,
	},
});
