
import Vue from 'vue';
import moment from 'moment-timezone';

export default Vue.extend({
	name: 'RunnitImageTimer',
	props: {
		timerStartAtMillis: { type: Number, default: null },
		parentWidth: { type: Number, default: 0 },
		parentHeight: { type: Number, default: 0 },
		coldStartWarningSeconds: { type: Number, default: null },
	},
	data () {
		return {
			timerStartMoment: null,
			elapsedTime: 0,
			timerInterval: null,
		};
	},
	computed: {
		seconds () {
			return Math.floor(this.elapsedTime / 1000);
		},
		formattedTime () {
			const milliseconds = Math.floor((this.elapsedTime % 1000) / 10);
			return `${this.seconds}.${milliseconds.toString().padStart(2, '0')}s`;
		},
		computedSpinnerSize () {
			let spinnerSize: number = 35;
			if (this.parentWidth < 90) spinnerSize = 25;
			else if (this.parentWidth < 50) spinnerSize = 20;
			return spinnerSize;
		},
		computedFontSize () {
			let fontSize: number = 12;
			if (this.parentWidth < 90) fontSize = 10;
			return fontSize;
		},
		shouldShowTimerNumbers () {
			return this.parentWidth > 50;
		},
		showColdStartWarning () {
			return this.coldStartWarningSeconds && this.seconds >= this.coldStartWarningSeconds;
		},
	},
	watch: {
		timerStartAtMillis: {
			immediate: true,
			handler (newVal: number, oldVal: number) {
				if (newVal !== oldVal) {
					if (!newVal) this.timerStartMoment = moment();
					else this.timerStartMoment = moment(newVal);
				}
			},
		},
	},
	mounted () {
		this.startTimer();
	},
	beforeDestroy () {
		this.stopTimer();
	},
	methods: {
		startTimer () {
			this.timerInterval = setInterval(() => {
				if (!this.timerStartMoment) this.elapsedTime = 0;
				else this.elapsedTime = moment().diff(this.timerStartMoment, 'ms');
			}, 50);
		},
		stopTimer () {
			clearInterval(this.timerInterval);
		},
	},
});
