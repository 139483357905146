var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"toggle-container",class:{
		'toggle-container-large': _vm.large,
	},style:({
		width: _vm.width,
	}),on:{"click":_vm.toggle}},[_c('div',{staticClass:"options-container"},[_c('span',{staticClass:"option",class:{ 'selected': !_vm.isOn },style:({
				fontSize: _vm.large ? '16px' : '14px',
			})},[_vm._t("option1")],2),_c('span',{staticClass:"option",class:{ 'selected': _vm.isOn },style:({
				fontSize: _vm.large ? '16px' : '14px',
			})},[_vm._t("option2")],2)]),_c('div',{staticClass:"slider",class:{
			'slider-right': _vm.isOn,
			'slider-large': _vm.large,
		},style:({
			left: _vm.isOn ? '50%' : '2px',
			width: 'calc(50% - 2px)'
		})})])
}
var staticRenderFns = []

export { render, staticRenderFns }