
import Vue from 'vue';
import { mapState } from 'vuex';
import { ROUTER } from '@/router/constants';
import GetSupportBtn from '@/components/Toolbar/GetSupportBtn/GetSupportBtn.vue';
import ListItem from '@/components/base/ListItem.vue';

export default Vue.extend({
	name: 'UnlockFreemium',
	props: {
		showCcOptionsOnSubscriptionsPageRoute: { type: Boolean, default: false }
	},
	computed: {
		...mapState([
			'authUser',
			'user',
		]),
		isEmailPassChecked () {
			return !!(
				this.authUser.emailVerified &&
				!this._get(this.user, 'freemiumInfo.invalidEmail')
			);
		},
		isFingerprintPassChecked () {
			return !this._get(this.user, 'freemiumInfo.dupFingerprint');
		},
		isDiscordPassChecked () {
			return !!(
				!this._get(this.user, 'freemiumInfo.noDiscord') &&
				!this._get(this.user, 'freemiumInfo.dupDiscord')
			);
		},
	},
	methods: {
		onSubscriptionsPageRoute () {
			this.routerPush(this.$route, this.$router, {
				name: ROUTER.RUNNITS_SUBSCRIPTIONS,
				query: {
					...(this.showCcOptionsOnSubscriptionsPageRoute && {
						showCcOptions: '1',
					}),
				},
			});
		},
	},
	components: {
		ListItem,
		GetSupportBtn,
	},
});
