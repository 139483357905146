
import Vue from 'vue';
import { mapState } from 'vuex';
import PreloadModelPreview from '@/components/PreloadModelPreview.vue';
import ListItem from '@/components/base/ListItem.vue';
import { getModelTypeDisplayText } from '@/utils';
import { SESSION_BACKGROUND_TASK_STATUS, SESSION_BACKGROUND_TASK_TYPE } from '@/constants/enums';
import { SessionBackgroundTask } from '@run-diffusion/shared';
import { DownloadModelInstructionsMixin } from '@/components/DownloadModelInstructions/DownloadModelInstructionsMixin';

export default Vue.extend({
	name: 'PreloadModelInstructions',
	mixins: [
		DownloadModelInstructionsMixin,
	],
	props: {
		showHideBtn: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'currSessionBackgroundTasks',
		]),
		isPreloadModelSessionBackgroundTaskComplete () {
			if (
				!this.preloadModel ||
				!this.preloadModel.civitai ||
				!this.preloadModel.civitai.model ||
				!this.preloadModel.civitai.modelVersion
			) {
				return false;
			}
			return (this.currSessionBackgroundTasks || []).some((sessionBackgroundTask: SessionBackgroundTask) => {
				const idSplit: string[] = sessionBackgroundTask.id.split('-');
				if (idSplit.length >= 3) {
					const [_, modelId, modelVersionId] = idSplit;
					return !!(
						sessionBackgroundTask.type === SESSION_BACKGROUND_TASK_TYPE.DOWNLOAD_MODEL &&
						sessionBackgroundTask.status === SESSION_BACKGROUND_TASK_STATUS.DONE &&
						modelId === this.preloadModel.civitai.model.id &&
						modelVersionId === this.preloadModel.civitai.modelVersion.id
					);
				}
				return false;
			});
		},
	},
	methods: {
		getModelTypeDisplayText,
	},
	components: {
		ListItem,
		PreloadModelPreview,
	},
});
