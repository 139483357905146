
import Vue from 'vue';

export default Vue.extend({
	name: 'CivitaiChip',
	props: {
		text: { type: String, required: true },
	},
	computed: {
		displayText () {
			return this.text.toUpperCase();
		},
	},
});
