
import Vue from 'vue';
import { db } from '@/firebase';
import { mapState } from 'vuex';
import { Survey } from '@run-diffusion/shared';
import { TRAFFIC_SOURCE } from '@/constants/constants';
import WindowStepper from '@/components/WindowStepper.vue';
import JoinOurDiscord from '@/components/Toolbar/JoinOurDiscord.vue';
import { SURVEY_KEY } from '@/constants/enums';
import { get$bindFirestoreOptions } from '@/mixins';

export default Vue.extend({
	name: 'UserUsage1Survey',
	data () {
		let windowItemIter: number = 0;
		return {
			TRAFFIC_SOURCE,

			windowView: 0,
			QUESTION_1_WINDOW: windowItemIter++,
			QUESTION_2_WINDOW: windowItemIter++,
			QUESTION_3_WINDOW: windowItemIter++,
			POST_SURVEY_WINDOW: windowItemIter++,
			WINDOW_VIEW_LENGTH: windowItemIter,
			largestWindowView: 0,

			userUsage1SurveyId: null,
			userUsage1Survey: null,
			userUsage1SurveyLoading: false,

			biggestPain: null,
			alternativeSolution: null,
			title: null,

			// FORM
			formValid: true,
			rules: {
				required: [
					v => !!v || 'Required field',
				],
			},
		};
	},
	async created () {
		if (this.user.usage1SurveyId) {
			try {
				this.userUsage1SurveyLoading = true;
				const surveyRef = db.doc(`surveys/${this.user.usage1SurveyId}`);
				const survey: Survey = await this.$bind(
					'userUsage1Survey',
					surveyRef,
					get$bindFirestoreOptions(),
				);
				this.biggestPain = this._get(survey, 'fields.biggestPain.value') || 0;
				this.alternativeSolution = this._get(survey, 'fields.alternativeSolution.value') || 0;
				this.title = this._get(survey, 'fields.title.value') || null;
			} catch (e) {
				console.error(e);
			} finally {
				this.userUsage1SurveyLoading = false;
			}
		}
	},
	watch: {
		windowView: {
			immediate: true,
			handler (newVal: number) {
				this.largestWindowView = Math.max(this.largestWindowView, newVal);
			},
		},
	},
	computed: {
		...mapState([
			'user',
		]),
		continueDisabled () {
			return !!(
				!!(
					this.windowView === this.QUESTION_1_WINDOW &&
					!this.biggestPain
				) ||
				!!(
					this.windowView === this.QUESTION_2_WINDOW &&
					!this.alternativeSolution
				)
			);
		},
		finishDisabled () {
			return !!(
				this.windowView === this.lastQuestionView &&
				!this.title
			);
		},
		lastQuestionView () {
			return this.QUESTION_3_WINDOW;
		},
	},
	methods: {
		onWindowViewBack () {
			if (this.windowView > 0) {
				this.windowView--;
			}
		},
		async onWindowViewForward () {
			if (this.windowView < this.lastQuestionView) {
				this.windowView++;
				await this.upsertSurvey(false);
			}
		},
		async onFinish () {
			this.$emit('on-finish');
			await this.upsertSurvey(true);
			this.windowView++;
		},
		async upsertSurvey (isFinished) {
			const surveyId: string = this.user.usage1SurveyId ||
				this.userUsage1SurveyId ||
				db.collection('surveys').doc().id;
			const userRef = db.doc(`users/${this.user.id}`);

			if (!this.user.usage1SurveyId) {
				await userRef.update({
					usage1SurveyId: surveyId,
				});
			}

			if (isFinished) {
				await userRef.update({
					usage1SurveyCompletedAt: new Date(),
				});
			}

			const surveyRef = db.doc(`surveys/${surveyId}`);
			await surveyRef.set({
				createdAt: this.userUsage1Survey ? this.userUsage1Survey.createdAt : new Date(),
				key: SURVEY_KEY.USER_USAGE_1,
				userId: this.user.id,
				fields: {
					...(this.biggestPain && {
						biggestPain: {
							label: 'What has been the biggest pain that our service solves for you after you have been using it?',
							type: 'TEXT',
							value: this.biggestPain,
						},
					}),
					...(this.alternativeSolution && {
						alternativeSolution: {
							label: `What else would you use to solve this pain/problem if you didn't have RunDiffusion?`,
							type: 'TEXT',
							value: this.alternativeSolution,
						},
					}),
					...(this.title && {
						title: {
							label: 'What is your title at your company?',
							type: 'TEXT',
							value: this.title,
						},
					}),
				},
			});
		},
	},
	components: {
		JoinOurDiscord,
		WindowStepper,
	},
});
