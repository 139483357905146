import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import _get from 'lodash/get';
import _remove from 'lodash/remove';
import _sampleSize from 'lodash/sampleSize';
import _findIndex from 'lodash/findIndex';
import _sortBy from 'lodash/sortBy';
import { Avatar, LoadedAsset, RunnitNodeField, RunnitNodeRunResult, RunnitUpload } from '@run-diffusion/shared';
import { NODE_RUN_RESULT_SELECTION_MODE, NodeRunResultSelectionMode } from '@/views/Runnits/constants';
import { emptySelectionState, RunnitSelectionStateType, SelectionState } from '@/store';

/**
 * Because there can be multiple inputes fighting for the the attention of the selection state but only one can
 *  truly be active at a time we have the following usage
 *
 * the runnitState.selectionStateHistory is a map of current or previously used selection states.
 *  The key for this map is the id of the source requesting the selection (inputId, top-menu-global download/delete, etc)
 *  The value of the map is a SelectionState object.
 *  In the SelectionState object there is a sortOrder property. This property is used to determine which state currently has the focus.
 *      since 0 is a falsey value, we'll use a sortOrder of 0 to mean that it is not active.
 *      The higher the sortOrder number the later is was added as selected, so the higher precedence it has to be the focused selection state
 * I went with a map instead of an array, because the side drawer input image selection state would open or close while doing the top menu
 *      selecting and trying to simply pop off an array was proving to be too difficult.
 * The hope is that since each selection state lives in the map it can work independently of the other selections and not get cleared
 *      or overwritten by a different input.
 */

export const RunnitsImageSelectMixin = Vue.extend({
	computed: {
		...mapState([
			'runnitState',
		]),
		/**
		 * converts the map of selection states into an ordered list
		 */
		orderedSelectionStates (): SelectionState[] {
			const activeStates: SelectionState[] = Object.keys(this.runnitState.selectionStateHistory).reduce((arr, key) => {
				const selectionState: SelectionState = this.runnitState.selectionStateHistory[key];
				if (selectionState.sortOrder) {
					arr.push(selectionState);
				}
				return arr;
			}, []);
			return _sortBy(activeStates, ['sortOrder']);
		},
		/**
		 * returns the active selectionState with the highest sort order
		 */
		currentSelectionState (): SelectionState {
			return this.orderedSelectionStates.length ? { ...this.orderedSelectionStates[this.orderedSelectionStates.length - 1] } : emptySelectionState;
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
		]),
		getSelectionStateHistoryClone (): Record<string, SelectionState> {
			return { ...this.runnitState.selectionStateHistory };
		},
		getSelectionStateByTrigger (triggeringId: string): SelectionState {
			return this.runnitState.selectionStateHistory[triggeringId];
		},
		/**
		 * Gets the next number in the sort order because the largest number is the priority.
		 * If the triggeringId is already linked to the largest sortOrder then return that same number
		 * @param triggeringId
		 */
		getNextPriorityNumber (triggeringId): number {
			if (!this.currentSelectionState) return 1;

			if (this.currentSelectionState.triggeringId === triggeringId) return this.currentSelectionState.sortOrder;

			return this.currentSelectionState.sortOrder + 1;
		},
		/**
		 * Adds a new selection state to the selectionStateHistory, or updates the sortOrder to give it precedence again
		 * @param nodeRunResultSelectionMode
		 * @param triggeringId
		 * @param fieldAwaitingImageGallerySelection
		 */
		enterSelectionMode (nodeRunResultSelectionMode: NodeRunResultSelectionMode, triggeringId: string, fieldAwaitingImageGallerySelection: RunnitNodeField, canSelectTypes: RunnitSelectionStateType[]) {
			const nextPriorityNumber: number = this.getNextPriorityNumber(triggeringId);

			const selectionStateHistory = this.getSelectionStateHistoryClone();
			const existingSelectionState: SelectionState = selectionStateHistory[triggeringId] ? { ...selectionStateHistory[triggeringId] } : {};

			selectionStateHistory[triggeringId] = {
				...emptySelectionState,
				...existingSelectionState,
				nodeRunResultSelectionMode,
				triggeringId,
				canSelectTypes,
				fieldAwaitingImageGallerySelection,
				sortOrder: nextPriorityNumber,
				...(nodeRunResultSelectionMode === NODE_RUN_RESULT_SELECTION_MODE.SINGLE && {
					selectedNodeRunResults: [],
					selectedLoadedAssets: [],
					selectedUploads: [],
					selectedAvatars: [],
				}),
			};
			this.updateRunnitState({
				selectionStateHistory,
			});
		},
		/**
		 * sets the current active selection state (largest sortOrder number) to have a sortOrder of 0
		 * @param triggeringId
		 */
		exitSelectionMode (triggeringId: string) {
			const selectionStateHistory: Record<string, SelectionState> = this.getSelectionStateHistoryClone();
			const existingSelectionState = selectionStateHistory[triggeringId] ? { ...selectionStateHistory[triggeringId] } : {};
			selectionStateHistory[triggeringId] = {
				...emptySelectionState,
				...existingSelectionState,
				sortOrder: 0,
			};
			this.updateRunnitState({
				selectionStateHistory,
			});
		},
		/**
		 *
		 * @param _array array to clone and check for existing
		 * @param item the item you will add if it doesn't exist
		 * @param compFunc the function to check for if the item exists
		 * @returns a new array with the item added if it didn't already exist otherwise the item would be removed
		 *
		 * example:
		 * xorArray(['a', 'b'], 'c', (item) => (item === 'c');
		 * result: ['a', 'b', 'c']
		 *
		 * xorArray(['a', 'b'], 'b', (item) => (item === 'b');
		 * result: ['a']
		 *
		 */
		xorArray (_array, item, compFunc) {
			const array = [...(_array || [])];
			const existingIndex = _findIndex(array, compFunc);
			if (existingIndex !== -1) {
				array.splice(existingIndex, 1);
			} else {
				array.push(item);
			}
			return array;
		},
		updateSelectionState (selectionState: SelectionState) {
			const selectionStateHistory: Record<string, SelectionState> = this.getSelectionStateHistoryClone();
			selectionStateHistory[selectionState.triggeringId] = {
				...emptySelectionState,
				...selectionState,
			};
			this.updateRunnitState({
				selectionStateHistory,
			})
		},
		clearSelectionsFromState (triggeringId: string) {
			this.updateSelectionState({
				...this.getSelectionStateByTrigger(triggeringId),
				selectedNodeRunResults: [],
				selectedLoadedAssets: [],
				selectedUploads: [],
				selectedAvatars: [],
			});
		},
		onNodeRunResultSingleSelection (nodeRunResult: RunnitNodeRunResult) {
			if (!this.currentSelectionState.canSelectTypes.includes(nodeRunResult.type)) return;

			const selectionState: SelectionState = {
				...this.currentSelectionState,
				nodeRunResultSelectionMode: null,
				selectedNodeRunResults: [nodeRunResult],
				selectedLoadedAssets: [],
				selectedUploads: [],
				selectedAvatars: [],
				sortOrder: 0,
			}
			this.updateSelectionState(selectionState);
		},
		onNodeRunResultMultiSelection (nodeRunResult: RunnitNodeRunResult) {
			if (!this.currentSelectionState.canSelectTypes.includes(nodeRunResult.type)) return;

			const selectionState: SelectionState = {
				...this.currentSelectionState,
				selectedNodeRunResults: this.xorArray(this.currentSelectionState.selectedNodeRunResults || [], nodeRunResult, (n: RunnitNodeRunResult) => (n.uuid === nodeRunResult.uuid)),
				selectedLoadedAssets: [],
				selectedUploads: [],
				selectedAvatars: [],
			}
			this.updateSelectionState(selectionState);
		},
		onLoadedAssetSingleSelection (loadedAsset: LoadedAsset) {
			if (!this.currentSelectionState.canSelectTypes.includes(loadedAsset.nodeRunResult.type)) return;

			const selectionState: SelectionState = {
				...this.currentSelectionState,
				nodeRunResultSelectionMode: null,
				selectedNodeRunResults: [],
				selectedLoadedAssets: [loadedAsset],
				selectedUploads: [],
				selectedAvatars: [],
				sortOrder: 0,
			}
			this.updateSelectionState(selectionState);
		},
		onLoadedAssetMultiSelection (loadedAsset: LoadedAsset) {
			if (!this.currentSelectionState.canSelectTypes.includes(loadedAsset.nodeRunResult.type)) return;

			const selectionState: SelectionState = {
				...this.currentSelectionState,
				selectedNodeRunResults: [],
				selectedLoadedAssets: this.xorArray(this.currentSelectionState.selectedLoadedAssets || [], loadedAsset, (la: LoadedAsset) => (la.src === loadedAsset.src)),
				selectedUploads: [],
				selectedAvatars: [],
			}
			this.updateSelectionState(selectionState);
		},
		onUploadSingleSelection (upload: RunnitUpload) {
			if (!this.currentSelectionState.canSelectTypes.includes(upload.type)) return;

			const selectionState: SelectionState = {
				...this.currentSelectionState,
				nodeRunResultSelectionMode: null,
				selectedNodeRunResults: [],
				selectedLoadedAssets: [],
				selectedUploads: [upload],
				selectedAvatars: [],
				sortOrder: 0,
			}
			this.updateSelectionState(selectionState);
		},
		onUploadMultiSelection (upload: RunnitUpload) {
			if (!this.currentSelectionState.canSelectTypes.includes(upload.type)) return;

			const selectionState: SelectionState = {
				...this.currentSelectionState,
				selectedNodeRunResults: [],
				selectedLoadedAssets: [],
				selectedUploads: this.xorArray(this.currentSelectionState.selectedUploads || [], upload, (u: RunnitUpload) => (u.id === upload.id)),
				selectedAvatars: [],
			}
			this.updateSelectionState(selectionState);
		},
		onAvatarSingleSelection (avatar: Avatar) {
			if (!this.currentSelectionState.canSelectTypes.includes(avatar.type)) return;

			const selectionState: SelectionState = {
				...this.currentSelectionState,
				nodeRunResultSelectionMode: null,
				selectedNodeRunResults: [],
				selectedLoadedAssets: [],
				selectedUploads: [],
				selectedAvatars: [avatar],
				sortOrder: 0,
			}
			this.updateSelectionState(selectionState);
		},
		onAvatarMultiSelection (avatar: Avatar) {
			if (!this.currentSelectionState.canSelectTypes.includes(avatar.type)) return;

			const selectionState: SelectionState = {
				...this.currentSelectionState,
				selectedNodeRunResults: [],
				selectedLoadedAssets: [],
				selectedUploads: [],
				selectedAvatars: this.xorArray(this.currentSelectionState.selectedAvatars || [], avatar, (a: Avatar) => (a.id === avatar.id)),
			}
			this.updateSelectionState(selectionState);
		},
		setFieldAwaitingImageGallerySelection (fieldAwaitingImageGallerySelection: RunnitNodeField) {
			const selectionState: SelectionState = {
				...this.currentSelectionState,
				fieldAwaitingImageGallerySelection
			};
			this.updateSelectionState(selectionState);
		}
	},
});
