import { GOOGLE_ANALYTICS_UID } from '@/constants/constants';

export const getGoogleAnalyticsClientId: Function = (gtag: any) => {
	return new Promise((resolve, reject) => {
		try {
			gtag.query('get', GOOGLE_ANALYTICS_UID, 'client_id', resolve);
		} catch (e) {
			console.error(e);
			reject(e);
		}
	});
};
