import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return ((
		!_vm.loadingUser &&
		!_vm.loadingTeam &&
		!_vm._get(_vm.team, 'isActive')
	))?_c(VContainer,[_c(VRow,[(!_vm.clubOfferBenefits.isCc)?_c(VCol,{staticStyle:{"padding-bottom":"0"},attrs:{"cols":"12","lg":"6","sm":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"cc-teaser-copy"},[_vm._v(" Try out "),_c('span',{staticClass:"gradient-text"},[_vm._v("Creator's Club")]),_vm._v("? ")]),_c('base-button',{staticClass:"ml-6",staticStyle:{"margin":"6px 0 10px 0"},attrs:{"secondary":""},on:{"click":function($event){return _vm.setUpsellDialog({ creatorsClub: true })}}},[_vm._v(" Learn More ")])],1)]):_vm._e(),_c(VCol,{staticStyle:{"padding-bottom":"0"},attrs:{"cols":"12","lg":!_vm.clubOfferBenefits.isCc ? '6' : '12',"sm":"12"}},[_c('div',{staticClass:"d-flex align-center",style:({
					'justify-content': (
						!_vm.clubOfferBenefits.isCc &&
						_vm.$vuetify.breakpoint.lgAndUp
					)
						? 'flex-end'
						: 'flex-start',
					'padding': (
						!_vm.clubOfferBenefits.isCc &&
						_vm.$vuetify.breakpoint.lgAndUp
					)
						? '0 0 0 10px'
						: '0 0 0 2px',
				})},[_c('div',{staticClass:"team-teaser-copy"},[_vm._v(" Try out "),_c('span',{staticClass:"gradient-text"},[_vm._v("Team Plan")]),_vm._v("? ")]),_c('base-button',{staticClass:"ml-6",staticStyle:{"margin":"6px 0 10px 0"},attrs:{"secondary":""},on:{"click":_vm.goToTeamRoute}},[_vm._v(" Learn More ")])],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }