
import Vue from 'vue';
import _truncate from 'lodash/truncate';
import _isNil from 'lodash/isNil';
import { SETTING_ACTIONS } from '@/views/Runnits/constants';
import { RunnitNodeField, RunnitMask } from '@run-diffusion/shared';
import {
	RUNNIT_NODE_FIELD_TYPE,
	RUNNIT_NODE_STATIC_FIELDS_KEY,
} from '@/constants/enums';
import ImageInfoFields from '@/components/ImageGallery/ImageInfoFields.vue';

export default Vue.extend({
	name: 'ImageInfo',
	props: {
		nodeRun: { type: Object, required: true },
		insideDrawer: { type: Boolean, default: false },
		insideNode: { type: Boolean, default: false },
		insideCarousel: { type: Boolean, default: false },
		ignoreTruncation: { type: Boolean, default: false },
		index: { type: [String, Number], default: null },
		imageAttributionStyles: { type: Object, default: null },
	},
	data () {
		return {
			SETTING_ACTIONS,
			RUNNIT_NODE_FIELD_TYPE,

			showMask: true,
		};
	},
	computed: {
		numImgs () {
			return this._get(this.nodeRun, 'results.length') || this._get(this.nodeRun, `staticInputs[${RUNNIT_NODE_STATIC_FIELDS_KEY.numResults}]`) || 1;
		},
		isFirstIndex () {
			return +this.index === 0;
		},
		fieldValueTruncateCharCount () {
			let charCount: number = 400;
			if (this.$vuetify.breakpoint.xsOnly || this.insideDrawer) {
				charCount = 100;
			}
			if (this.ignoreTruncation) {
				charCount = 3000;
			}
			return charCount;
		},
		firstLetterOfEmail () {
			return this.nodeRun.email ? this.nodeRun.email[0].toUpperCase() : '';
		},
	},
	methods: {
		_truncate,
		_isNil,
		determineWidth () {
			let width = '100%';
			if (
				this.$vuetify.breakpoint.smAndUp &&
				!this.insideDrawer
			) {
				width = '50%';
			}
			return width;
		},
		getInpaintingMask (field: RunnitNodeField): RunnitMask {
			return (
				field &&
				field.type === RUNNIT_NODE_FIELD_TYPE.IMG &&
				this.nodeRun.inputs[field.fieldDefUuid] &&
				this.nodeRun.inputs[field.fieldDefUuid].inpainting &&
				this.nodeRun.inputs[field.fieldDefUuid].inpainting.mask
			) || null;
		},
	},
	components: {
		ImageInfoFields,
	},
});
