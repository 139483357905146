import moment from 'moment-timezone';
import {
	ISO_DATE_PICKER_FORMAT,
	VIEW_DATE_PICKER_FORMAT,
} from '@/utils/timeUtils';

export const isDateStringValidIso = dateString => (
	!!dateString && dateString.length === ISO_DATE_PICKER_FORMAT.length && moment(dateString, ISO_DATE_PICKER_FORMAT, true).isValid()
);
export const isDateStringValidView = (dateString, dateFormat = VIEW_DATE_PICKER_FORMAT) => (
	!!dateString && dateString.length === dateFormat.length && moment(dateString, dateFormat, true).isValid()
);
