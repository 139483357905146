
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { RunnitBulkActionsMixin } from '@/mixins/RunnitBulkActionsMixin';
import BaseSlider from '@/components/base/BaseSlider.vue';
import RunnitSelectedImage from '@/views/Runnits/RunnitSelectedImage.vue';
import RunnitSettingHelperBtn from '@/views/Runnits/RunnitSettings/RunnitSettingHelperBtn.vue';

export default Vue.extend({
	name: 'ImageInputSelectedImageContainer',
	props: {
		value: { type: Object, default: null },
		libraryText: { type: String, default: 'Library' },
		hasInpainting: { type: Boolean, default: false },
		invertMaskColors: { type: Boolean, default: false },
		hasStrength: { type: Boolean, default: false },
	},
	mixins: [
		RunnitBulkActionsMixin,
	],
	computed: {
		...mapState([
			'user',
			'runnitState',
		]),
		isImageSelected () {
			return !!(
				this.imageValue &&
				(
					this.imageValue.nodeRunResult ||
					this.imageValue.upload
				)
			);
		},
		imageValue () {
			let val = this.value
			if (this.hasInpainting || this.hasStrength) {
				val = this._get(this.value, 'image') || null;
			}
			return val;
		},
		inpaintingValue () {
			if (this.hasInpainting) {
				return this._get(this.value, 'inpainting') || { mask: null };
			}
			return null;
		},
		strengthValue () {
			if (this.hasStrength) {
				return this._get(this.value, 'strength') || 0.8;
			}
			return null;
		},
	},
	data () {
		return {
			showMaskOverBackground: false,
		};
	},
	methods: {
		...mapActions([
			'updateRunnitState',
		]),
		onClear () {
			this.$emit('on-clear');
		},
		onLibrary () {
			this.$emit('on-library');
		},
		async fetchImageUrl () {
			if (this.imageValue.nodeRunResult) {
				const { imageUrl } = await this.fetchSrc(this.imageValue.nodeRunResult, 'nodeRunResult');
				return imageUrl;
			}
			if (this.imageValue.upload) {
				const { imageUrl } = await this.fetchSrc(this.imageValue.upload, 'upload');
				return imageUrl;
			}
		},
		async handleInpaintingClick () {
			if (this.runnitState.inpaintingEditorSaving) return;

			const imageUrl = await this.fetchImageUrl();
			this.updateRunnitState({
				inpaintingEditorOpen: !this.runnitState.inpaintingEditorOpen,
				inpaintingBackgroundImageUrl: imageUrl,
				// Close all other potentially open runnit menus
				promptGeneratorOpen: false,
				imageSelectOpen: false,
			});
		},
		onStrengthInput (strengthVal: number) {
			this.$emit('on-strength-change', strengthVal);
		},
	},
	components: {
		BaseSlider,
		RunnitSelectedImage,
		RunnitSettingHelperBtn,
	},
});
