<template>
	<v-sheet
		outlined
		class="full-width mt-6"
	>
		<div class="image-container pa-8 full-width">
			<div class="d-flex flex-column">
				<v-row>
					<v-col
						cols="12"
						md="6"
						:class="{
							'pl-2 pr-0': $vuetify.breakpoint.smAndUp,
						}"
					>
						<div
							:class="{
								'promo-title': $vuetify.breakpoint.smAndUp,
								'promo-title-mobile': $vuetify.breakpoint.xsOnly,
							}"
						>
							<div class="d-flex flex-column">
								{{ showStripeUserMembershipPortalBtn && clubOfferBenefits.isCc ? 'About your' : 'Sign up for' }}
								<div
									style="display: inline; line-height: 40px; font-family: 'danielbd', Helvetica, serif; padding: 6px 0 0 0;"
									:style="{
										fontSize: $vuetify.breakpoint.xsOnly ? '41px' : '46px',
										lineHeight: $vuetify.breakpoint.xsOnly ? '53px' : '53px',
									}"
								>
									<span class="gradient-text">Creator's Club</span>!
								</div>
							</div>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div class="mb-3 mr-3 d-flex justify-end" style="margin: -15px 0 0 0;">
							<a href="https://rundiffusion.com/creators-club" target="_blank">See full features</a>
						</div>
						<div class="list-items">
							<div style="flex: 50" class="pr-3">
								<list-item icon="mdi-check-circle"><b>Discount</b> on Large size hardware</list-item>
								<list-item icon="mdi-check-circle">Access to <b>MAX</b> size hardware</list-item>
								<list-item icon="mdi-check-circle"><b>100 GB Personal Storage</b></list-item>
								<list-item icon="mdi-check-circle"><b>300 GB of Shared Civitai Models</b></list-item>
								<list-item icon="mdi-check-circle">Persisted Settings</list-item>
								<list-item icon="mdi-check-circle">#CreatorsClub VIP Discord for life</list-item>
								<list-item icon="mdi-check-circle">Support us and development!</list-item>
							</div>
							<div style="flex: 50" class="pl-3">
								<list-item icon="mdi-check-circle"><b>Upload/Merge Models</b></list-item>
								<list-item icon="mdi-check-circle"><b>$10 per month</b> of promotional balance awarded</list-item>
								<list-item icon="mdi-check-circle">Upload to File Browser</list-item>
								<list-item icon="mdi-check-circle">Train Models (DreamBooth)</list-item>
								<list-item icon="mdi-check-circle">Deforum, Embeddings & Hypernetworks</list-item>
							</div>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<div class="actions-container">
							<base-button
								class="mr-1"
								large
								secondary
								:icon="$vuetify.breakpoint.xsOnly"
								@click="onCloseUpsellDialog"
							>
								<v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-close</v-icon>
								<span v-else>Close</span>
							</base-button>
							<v-spacer/>
							<buy-creators-club-button
								:class="{
									...($vuetify.breakpoint.xsOnly && {
										'd-flex': true,
										'flex-column': true,
										'align-end': true,
									}),
								}"
								:btn-props="{
									large: true,
									style: 'padding: 0 60px',
								}"
								@on-close-upsell-dialog="onCloseUpsellDialog"
							/>
						</div>
					</v-col>
				</v-row>
			</div>
		</div>
	</v-sheet>
</template>

<script>
import Vue from 'vue';
import ListItem from '../base/ListItem';
import BuyCreatorsClubButton from '@/components/BuyCreatorsClubButton';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
	name: 'CreatorsClubPromoBanner',
	computed: {
		...mapGetters([
			'showStripeUserMembershipPortalBtn',
			'clubOfferBenefits',
		]),
	},
	methods: {
		...mapActions([
			'setUpsellDialog',
		]),
		onCloseUpsellDialog () {
			this.setUpsellDialog({ creatorsClub: false });
		},
	},
	components: {
		BuyCreatorsClubButton,
		ListItem,
	},
});
</script>

<style scoped lang="scss">
	.image-container {
		background-image: url(../../assets/ccchaos.svg);
		background-size: 1030px;
		background-position: -260px -400px;
		border-radius: var(--global-border-radius);
		color: white;
	}
	.promo-title {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-items: center;
		font-size: 41px;
		max-width: 570px;
		font-weight: bold;

		&-mobile {
			font-size: 37px;
			margin: 0 0 15px 0;
			font-weight: bold;
		}
	}
	.list-items {
		display: flex;
		width: 100%;
		font-size: 14px;
	}
	.actions-container {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 0 0 20px 0;
		margin: 5px 40px 0 0;
		width: 100%;
	}
</style>
