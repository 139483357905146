
import Vue from 'vue';
import _omit from 'lodash/omit';
import { Team } from '@run-diffusion/shared';
import { TeamUserMixin } from '@/mixins';
import { db, functions } from '@/firebase';
import { mapActions, mapGetters, mapState } from 'vuex';
import { SNACKBAR_STATUS, TEAM_ROLE, TEAM_ROLE_DETAILS } from '@/constants/constants';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import ListItem from '@/components/base/ListItem.vue';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import InviteTeamMember from '@/views/Team/InviteTeamMember.vue';
import TeamSubscriptionManagement from '@/views/Team/TeamSubscriptionManagement.vue';
import DoubleConfirmDangerDialog from '@/components/base/DoubleConfirmDangerDialog.vue';

export default Vue.extend({
	name: 'TeamMembers',
	mixins: [
		TeamUserMixin,
	],
	props: {},
	data() {
		return {
			TEAM_ROLE,
			TEAM_ROLE_DETAILS,
			memberSortBy: 'email',
			memberSortDesc: false,
			rowsPerPageItems: [25, 50, 100],
			headers: [
				{
					text: 'Email',
					value: 'email',
					align: 'left',
					class: 'py-0 pl-4',
					sortable: true,
				},
				{
					text: 'Role',
					align: 'left',
					class: 'py-0 px-1',
					sortable: true,
				},
				{
					text: '',
					align: 'right',
					class: 'py-0 pl-0 pr-1',
					sortable: false,
				},
			],
			pageSize: 100,

			currPageTeamMembers: 0,
			currTeamMembers: [],
			teamMembers: [],
			lastTeamMember: null,
			loadingInitialTeamMembers: false,
			loadingMoreTeamMembers: false,

			currPagePendingTeamMembers: 0,
			currPendingTeamMembers: [],
			pendingTeamMembers: [],
			lastPendingTeamMember: null,
			loadingInitialPendingTeamMembers: false,
			loadingMorePendingTeamMembers: false,

			isStillLoadingInitialPage: true,

			mutatingTeamMember: false,

			joinTeamSuccessDialogOpen: false,
		};
	},
	async created () {
		if (this.team && this.team.id) {
			await this.loadInitialTeamMembers();
			await this.loadInitialPendingTeamMembers();
			this.initJoinTeamSuccessDialog(this.team);
		}

		// set visitedTeamPage
		if (this.user && !this.user.visitedTeamPage) {
			const userRef = db.doc(`users/${this.user.id}`);
			await userRef.update({
				visitedTeamPage: true,
			});
		}
	},
	watch: {
		async team (newVal, oldVal) {
			if (newVal !== oldVal && newVal) {
				await this.loadInitialTeamMembers();
				await this.loadInitialPendingTeamMembers();
				this.initJoinTeamSuccessDialog(newVal);
				await this.loadTeamReportEmbedUrls({ team: newVal, company: null });
			}
		},
		showStripeTeamSubscriptionPortalBtn (newVal, oldVal) {
			if (
				newVal !== oldVal &&
				newVal &&
				this.appQueryParamsData &&
				this.appQueryParamsData.openManageTeamSubscription
			) {
				// Auto open customer portal
				this.goToStripeSubscriptionPortal(this.team);
				// Reset openManageTeamSubscription to false
				this.updateAppQueryParamsData({ openManageTeamSubscription: false });
			}
		},
	},
	computed: {
		...mapGetters([
			'showStripeTeamSubscriptionPortalBtn',
		]),
		...mapState([
			'isAdminProxyingUser',
			'user',
			'team',
			'appQueryParamsData',
			'loginQueryParamsData',
		]),
		// canGoPrevPageTeamMembers () {
		// 	return this.currPageTeamMembers > 0;
		// },
		// canGoNextPageTeamMembers () {
		// 	return (
		// 		this.currPageTeamMembers < Math.floor(this.teamMembers.length / this.pageSizeTeamMembers)
		// 	);
		// },
		// canGoPrevPagePendingTeamMembers () {
		// 	return this.currPagePendingTeamMembers > 0;
		// },
		// canGoNextPagePendingTeamMembers () {
		// 	return (
		// 		this.currPagePendingTeamMembers < Math.floor(this.pendingTeamMembers.length / this.pageSizePendingTeamMembers)
		// 	);
		// },
		hasRouteHistory () {
			return window.history.length > 2;
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'updateAppQueryParamsData',
			'goToStripeSubscriptionPortal',
			'unbindTeam',
			'unbindStripeTeamsCustomer',
			'unbindTeamBalanceAccount',
			'unbindWorkspaces',
			'loadTeamReportEmbedUrls',
		]),
		initJoinTeamSuccessDialog (team: Team) {
			if (team && this.$route.query.finishedConnectingTeam) {
				this.joinTeamSuccessDialogOpen = true;
				this.routerReplace(this.$route, this.$router, {
					name: this.$route.name,
					query: _omit(this.$route.query, 'finishedConnectingTeam'),
				});
			}
		},
		onJoinTeamSuccessDialogConfirm () {
			this.joinTeamSuccessDialogOpen = false;
		},
		async loadMoreTeamMembers (nextPage: number, isPending: boolean) {
			const onError: Function = (e) => {
				console.error('Error loading Team Members: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading members',
					show: true,
				});
			};

			try {
				const functionRef = functions
					.httpsCallable('getTeamMembers');
				const { success, teamMembers } = (await functionRef({
					teamId: this.team.id,
					isPending,
					nextPage,
					pageSize: this.pageSize,
					afterId: isPending
						? (this.lastTeamMember ? this.lastTeamMember.id : null)
						: (this.lastPendingTeamMember ? this.lastPendingTeamMember.id : null)
				})).data;
				if (!success) {
					onError(new Error('getTeamMembers returned undefined response'));
				}

				return {
					success,
					teamMembers,
				};
			} catch (e) {
				onError(e);
				return {
					success: false,
					teamMembers: null,
				};
			}
		},
		async loadInitialTeamMembers () {
			if (!this.loadingInitialTeamMembers && !this.loadingMoreTeamMembers && !this.team.isOnboarding) {
				this.loadingInitialTeamMembers = true;
				const { success, teamMembers } = await this.loadMoreTeamMembers(null, false);
				this.loadingInitialTeamMembers = false;
				if (success) {
					this.currPageTeamMembers = 0;
					this.lastTeamMember = (teamMembers && teamMembers.length)
						? teamMembers[teamMembers.length - 1]
						: null;
					this.currTeamMembers = teamMembers || [];
					this.teamMembers = this.currTeamMembers;
				}
			}
		},
		async loadInitialPendingTeamMembers () {
			if (!this.loadingInitialPendingTeamMembers && !this.loadingMorePendingTeamMembers && !this.team.isOnboarding) {
				this.loadingInitialPendingTeamMembers = true;
				const { success, teamMembers } = await this.loadMoreTeamMembers(null, true);
				this.loadingInitialPendingTeamMembers = false;
				if (success) {
					this.currPagePendingTeamMembers = 0;
					this.lastPendingTeamMember = (teamMembers && teamMembers.length)
						? teamMembers[teamMembers.length - 1]
						: null;
					this.currPendingTeamMembers = teamMembers || [];
					this.pendingTeamMembers = this.currPendingTeamMembers;
				}
			}
		},
		async handleRemoveTeamMember (memberEmail, isPending, closeAndResetCallback) {
			this.mutatingTeamMember = true;
			const onError: Function = (e) => {
				console.error('Error deleting Team Member: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error deleting member',
					show: true,
				});
			};

			try {
				const functionRef = functions
					.httpsCallable('deleteTeamMember');
				const { success } = (await functionRef({
					teamId: this.team.id,
					teamMemberEmail: memberEmail,
				})).data;
				if (!success) {
					onError(new Error('deleteTeamMember returned undefined response'));
				} else {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! Removed team member`,
						show: true,
					});

					// Refresh the correct list that was deleted from
					if (isPending) {
						await this.loadInitialPendingTeamMembers();
					} else {
						await this.loadInitialTeamMembers();
					}
				}
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error deleting member, please try again',
					show: true,
				});
			} finally {
				this.mutatingTeamMember = false;
				if (closeAndResetCallback) {
					closeAndResetCallback();
				}
			}
		},
		async handleUpdateTeamMember (memberEmail, role, isPending, closeAndResetCallback) {
			this.mutatingTeamMember = true;
			const onError: Function = (e) => {
				console.error('Error updating Team Member: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error updating member',
					show: true,
				});
			};

			try {
				const functionRef = functions
					.httpsCallable('updateTeamMember');
				const { success } = (await functionRef({
					teamId: this.team.id,
					teamMemberEmail: memberEmail,
					data: {
						role,
					},
				})).data;
				if (!success) {
					onError(new Error('updateTeamMember returned undefined response'));
				} else {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! Updated team member`,
						show: true,
					});

					// Refresh the correct list that was deleted from
					if (isPending) {
						await this.loadInitialPendingTeamMembers();
					} else {
						await this.loadInitialTeamMembers();
					}
				}
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error updating member, please try again',
					show: true,
				});
			} finally {
				this.mutatingTeamMember = false;
				if (closeAndResetCallback) {
					closeAndResetCallback();
				}
			}
		},
		notSelfEmail (email) {
			return email !== this.user.email;
		},
	},
	components: {
		TeamSubscriptionManagement,
		ListItem,
		LoadingSVG,
		ConfirmDialog,
		InviteTeamMember,
		DoubleConfirmDangerDialog,
	},
});
