
import Vue from 'vue';
import ListItem from '@/components/base/ListItem.vue';
import { getModelTypeDisplayText } from '@/utils';
import { DownloadModelInstructionsMixin } from '@/components/DownloadModelInstructions/DownloadModelInstructionsMixin';

export default Vue.extend({
	name: 'DownloadedModelInstructions',
	mixins: [
		DownloadModelInstructionsMixin,
	],
	methods: {
		getModelTypeDisplayText,
	},
	components: {
		ListItem,
	},
});
