
import Vue from 'vue';
import ListItem from '@/components/base/ListItem.vue';
import AddBalanceFundsButton from '@/components/AddBalanceFundsButton.vue';
import { NEW_USER_PROMO_END_DATE, NEW_USER_PROMO_START_DATE } from '@/constants/constants';

export default Vue.extend({
	name: 'NewUserLoginPromoDialog',
	props: {
		value: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		maxWidth: { type: Number, default: 700 },
	},
	data () {
		return {
			open: false,
			promotionalStartMoment: new Date(NEW_USER_PROMO_START_DATE), // Sunday noon
			promotionalEndMoment: new Date(NEW_USER_PROMO_END_DATE), // Tuesday noon
		};
	},
	created () {
		this.open = !!this.value;
	},
	watch: {
		value (newVal: boolean) {
			this.open = !!newVal;
		},
	},
	methods: {
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
	},
	components: {
		AddBalanceFundsButton,
		ListItem,
	},
});
