
import Vue from 'vue';

export default Vue.extend({
	name: 'RunnitLibraryPage',
	props: {},
	data () {
		return {};
	},
});
