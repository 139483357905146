
import Vue from 'vue';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import ClipboardCopy from '@/components/ClipboardCopy.vue';

export default Vue.extend({
	name: 'AdminWorkspaceStats',
	props: {
		workspace: { type: Object, default: null },
	},
	data () {
		return {
		};
	},
	components: {
		MenuOrBottomSheet,
		ClipboardCopy,
	},
});
