
import Vue from 'vue';
import SessionTimer from '@/components/Toolbar/SessionTimer/SessionTimer.vue';

export default Vue.extend({
	name: 'TimeExpiringDialog',
	props: {
		value: { type: Boolean, default: false },
		minutesLeft: { type: Number, default: 10 },
	},
	watch: {
		value (newVal, oldVal) {
			if (newVal && newVal !== oldVal) {
				this.$autoSoundMachine.playSessionEndsInMinutes(false);
			}
		},
	},
	components: {
		SessionTimer,
	},
});
