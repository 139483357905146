import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":"700","disabled":_vm.inviteLoading,"persistent":_vm.inviteLoading},scopedSlots:_vm._u([{key:"activator",fn:function({ on: dialogOn, attrs: dialogAttrs }){return [_c('base-button',_vm._g(_vm._b({attrs:{"call-to-action":""}},'base-button',{ ...dialogAttrs },false),{ ...dialogOn }),[_vm._v("    "),_c('b',[_vm._v("Add Members")]),_vm._v("    ")])]}},{key:"default",fn:function(){return [_c('dialog-content',{attrs:{"close-disabled":_vm.inviteLoading,"title":"Invite Team Member(s)","content-styles":`${_vm.$vuetify.breakpoint.xsOnly ? 'height: 65vh;' : 'height: 52vh;'}`},on:{"on-cancel":_vm.onResetAndClose},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('base-button',{staticClass:"mr-6",attrs:{"text":"","disabled":_vm.inviteLoading},on:{"click":_vm.onResetAndClose}},[_vm._v(" Cancel ")]),_c('base-button',{staticStyle:{"min-width":"120px"},attrs:{"loading":_vm.inviteLoading,"disabled":(
							_vm.inviteLoading ||
							!_vm.formValid ||
							!_vm.selectedRole ||
							!_vm.inviteEmails
						)},on:{"click":_vm.onInviteClick}},[_c(VIcon,{staticStyle:{"font-size":"18px","padding":"0 6px 0 0"}},[_vm._v("mdi-email-outline")]),_vm._v("Invite ")],1)],1)]},proxy:true}])},[_c(VForm,{ref:"form",staticClass:"full-width",attrs:{"lazy-validation":"","onSubmit":"return false"},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{staticClass:"mt-6",staticStyle:{"font-size":"17px"}},[_vm._v(" Please put in a list of up to "),_c('b',[_vm._v(_vm._s(_vm.MAX_NUM_EMAILS))]),_vm._v(" emails comma separated you'd like to invite ")]),_c('div',{staticClass:"mt-8"},[_c(VTextarea,{attrs:{"outlined":"","dark":"","label":"Email(s)","placeholder":"abc@gmail.com,def@gmail.com,etc...","disabled":_vm.inviteLoading,"rules":[
							..._vm.rules.required,
							..._vm.rules.commaSeparatedList,
							..._vm.rules.maxNumberOfEmails,
						],"rows":3},model:{value:(_vm.inviteEmails),callback:function ($$v) {_vm.inviteEmails=$$v},expression:"inviteEmails"}}),_c(VSelect,{staticStyle:{"max-width":"275px","min-width":"275px"},attrs:{"label":"Role","outlined":"","disabled":_vm.inviteLoading,"items":_vm._values(_vm.TEAM_ROLE_DETAILS),"rules":_vm.rules.required,"menu-props":{ maxHeight: 225, bottom: true, offsetY: true },"item-value":"id","item-text":"label","hide-details":"","required":""},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}})],1)])],1)]},proxy:true}]),model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }