
import Vue from 'vue';
import _isNil from 'lodash/isNil';
import _trim from 'lodash/trim';
import {
	AM_PM,
	timeInMinutesToTimeInputs,
	timeInputsToTimeInMinutes,
} from '@/utils/timeUtils';
import Scroll from '@/components/base/Scroll.vue';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';

export default Vue.extend({
	props: {
		scrollRootIdSuffix: { type: String, required: true },
		label: { type: String, default: null },
		disabled: { type: Boolean, default: false },
		required: { type: Boolean, default: false },
		value: { type: Number, default: null },
		prependIcon: { type: String, default: null },
		hint: { type: String, default: null },
		useAllMinuteSelection: { type: Boolean, default: false },
		errorMessage: { type: String, default: null },
	},
	data () {
		const HOUR_ITEMS = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
		const MINUTE_ITEMS = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
		const MINUTE_ITEMS_BY_5 = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

		return {
			AM_PM,
			HOUR_ITEMS,
			MINUTE_ITEMS,
			MINUTE_ITEMS_BY_5,

			hasOpened: false,
			hour: null,
			min: null,
			amPm: AM_PM.AM,

			rules: {
				required: [
					v => !!_trim(v) || 'Required',
				],
			},
		};
	},
	created () {
		this.hasOpened = false;
		this.initTime(this.value);
	},
	watch: {
		value (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.initTime(newVal);
			}
		},
	},
	computed: {
		hourListIdScope () {
			return `TimeInput-hour-list-${this.scrollRootIdSuffix}`;
		},
		minuteListIdScope () {
			return `TimeInput-minute-list-${this.scrollRootIdSuffix}`;
		},
		selectedTime () {
			if (this.hour && this.min && this.amPm) {
				return `${this.hour}:${this.min} ${this.amPm}`;
			}
			return null;
		},
		minuteSelectItems () {
			return this.useAllMinuteSelection
				? this.MINUTE_ITEMS
				: this.MINUTE_ITEMS_BY_5;
		},
	},
	methods: {
		getHourButtonId (hour) {
			return `hour-button-${hour}-${this.scrollRootIdSuffix}`;
		},
		getMinuteButtonId (min) {
			return `min-button-${min}-${this.scrollRootIdSuffix}`;
		},
		autoScrollToValue (hour, min, onScrollDone) {
			const hourBtnEl = document.getElementById(this.getHourButtonId(hour));
			const minuteBtnEl = document.getElementById(this.getMinuteButtonId(min));
			if (hourBtnEl && minuteBtnEl) {
				this.$scrollTo(hourBtnEl, 15, {
					container: `#${this.hourListIdScope}`,
					onDone: () => {
						this.$scrollTo(minuteBtnEl, 15, {
							container: `#${this.minuteListIdScope}`,
							...(onScrollDone && {
								onDone: onScrollDone,
							}),
						});
					},
				});
			} else {
				if (onScrollDone) {
					onScrollDone();
				}
			}
		},
		onClearClick (closeMenu) {
			if (!this.disabled) {
				this.autoScrollToValue(
					this.HOUR_ITEMS[0],
					this.minuteSelectItems[0],
					() => {
						this.reset();
						if (closeMenu) {
							closeMenu();
						}
					},
				);
			}
		},
		onDoneClick (closeMenu) {
			if (closeMenu) {
				closeMenu();
			}
		},
		emitVal (val) {
			this.$emit('input', !_isNil(val) ? val : null);
		},
		reset () {
			this.hour = null;
			this.min = null;
			this.amPm = AM_PM.AM;
			this.emitVal(null);
		},
		initTime (value) {
			const { oneDigitHours, twoDigitMinutes, amPm } = timeInMinutesToTimeInputs(value, this.useAllMinuteSelection);
			this.hour = !_isNil(oneDigitHours) ? oneDigitHours : null;
			this.min = !_isNil(twoDigitMinutes) ? twoDigitMinutes : null;
			this.amPm = amPm || AM_PM.AM;
		},
		onTimeChange (hour, min, amPm) {
			this.hour = hour;
			this.min = min;
			this.amPm = amPm || AM_PM.AM;
		},
		onMenuOrBottomSheetInput (val) {
			if (val) {
				if (!this.hasOpened) {
					setTimeout(() => {
						// Wait for the container to open up
						this.autoScrollToValue(this.hour, this.min, null);
					}, 500);
					this.hasOpened = true;
				}
			} else {
				this.setCurrValue();
			}
		},
		setCurrValue () {
			const allThreeDefined = !!(!_isNil(this.hour) && !_isNil(this.min) && this.amPm);
			if (allThreeDefined) {
				const result = timeInputsToTimeInMinutes(this.hour, this.min, this.amPm);
				this.emitVal(result);
			} else if (this.selectedTime && !allThreeDefined) {
				this.emitVal(null);
			}
		},
	},
	components: {
		Scroll,
		MenuOrBottomSheet,
	},
});
