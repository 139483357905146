
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { db, storage } from '@/firebase';
import { Avatar, RunnitUpload, UploadFile } from '@run-diffusion/shared';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { AVATAR_USE_CASE, IMAGE_UPLOAD_MODE, UPLOAD_FILE_TYPE } from '@/constants/enums';

export default Vue.extend({
	name: 'ImageInput',
	props: {
		mode: {
			type: String,
			required: true,
			validator: (val: string) => Object.keys(IMAGE_UPLOAD_MODE).includes(val),
		},
		avatarUseCase: {
			type: String,
			default: AVATAR_USE_CASE.RUNNIT,
			validator: (val: string) => Object.keys(AVATAR_USE_CASE).includes(val),
		},
		disabled: { type: Boolean, default: false },
	},
	data () {

		return {
			FILE_SIZE_LIMIT: 4194304, // 4 MB in bytes

			isUploading: false,
			isBeingDraggedOver: false,
		};
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
		]),
	},
	watch: {
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
		]),
		onUploadComplete (val: RunnitUpload) {
			this.$emit('on-upload-complete', val);
		},
		handleDragOver (ev) {
			if (this.isUploading || this.disabled) return;
			this.isBeingDraggedOver = true;
			ev.preventDefault();
		},
		handleDragLeave (ev) {
			if (this.isUploading || this.disabled) return;
			this.isBeingDraggedOver = false;
			ev.preventDefault();
		},
		async handleDrop (ev) {
			if (this.isUploading || this.disabled) return;
			this.isBeingDraggedOver = false;
			let dt = ev.dataTransfer;
			let files = dt.files;
			await this.handleFiles(files);
		},
		async onHiddenFileInputChange (ev) {
			if (this.isUploading) return;
			this.isBeingDraggedOver = false;
			await this.handleFiles(ev.target.files);

			// Clear the selected file from the input
			this.$refs.fileInput.value = ''; // Reset the value of the input element
		},
		openFileDialog () {
			if (this.isUploading || this.disabled) return;
			this.$refs.fileInput.click();
		},
		async handleFiles (files) {
			this.isBeingDraggedOver = false;

			// Pull off first file
			const file = this._get([...files], '[0]') || null;
			if (!file) return;

			// Size check files
			if (file.size >= this.FILE_SIZE_LIMIT) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Image to upload must be less than 4 megabytes`,
					show: true,
				});
				return;
			}

			// Do uploading
			try {
				this.$emit('on-upload-start');
				this.isUploading = true;
				const upload: UploadFile = await this.uploadFile(file);
				this.onUploadComplete(upload);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error uploading image`,
					show: true,
				});
			} finally {
				this.isUploading = false;
				this.$emit('on-upload-end');
			}
		},
		async uploadFile (file) {
			if (this.mode === 'RUNNIT_UPLOAD') {
				return this.handleRunnitUpload(file);
			}
			if (this.mode === 'AVATAR') {
				return this.handleAvatar(file);
			}
		},
		async handleRunnitUpload (file) {

			let upload: RunnitUpload = null;

			// Calculate hash of the file data
			const fileData = await file.arrayBuffer();
			const hashBuffer = await crypto.subtle.digest('SHA-256', fileData);
			const hashArray = Array.from(new Uint8Array(hashBuffer));
			const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

			// Check if hash exists already
			const uploadsRef = db.collection('runnitUploads')
				.where('hashHex', '==', hashHex)
				.where('userId', '==', this.user.id)
				.where('deletedAt', '==', null)
				.limit(1);
			(await uploadsRef.get()).forEach(async (doc: any) => {
				upload = {
					...doc.data(),
					get id () { return doc.id },
				} as RunnitUpload;
			});

			if (!upload) {
				// Upload file
				const { name, type, size } = file;
				const fileRef = storage.ref(`runnitUploads/users/${this.user.id}/uploads/${name}`);

				try {
					// Exists
					await fileRef.getDownloadURL();
				} catch (e) {
					// Not exists
					await fileRef.put(file);
				}

				// Record upload file in database
				const nowDate: Date = new Date();
				await db.collection('runnitUploads')
				const addedUploadRef = await db.collection('runnitUploads')
					.add({
						createdAt: nowDate,
						deletedAt: null,
						userId: this.user.id,
						teamId: null,
						type: UPLOAD_FILE_TYPE.IMG,
						name: name,
						contentType: type,
						size: size,
						hashHex,
					});
				upload = {
					...(await addedUploadRef.get()).data(),
					get id () { return addedUploadRef.id },
				} as RunnitUpload;
			}

			return upload;
		},
		async handleAvatar (file) {
			// NOTE: the firebase size extension will be making this 256x256 and then deleting the originals
			let avatar: Avatar = null;

			// Calculate hash of the file data
			const fileData = await file.arrayBuffer();
			const hashBuffer = await crypto.subtle.digest('SHA-256', fileData);
			const hashArray = Array.from(new Uint8Array(hashBuffer));
			const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

			// Check if hash exists already
			const avatarsRef = db.collection('avatars')
				.where('hashHex', '==', hashHex)
				.where('userId', '==', this.user.id)
				.where('deletedAt', '==', null)
				.limit(1);
			(await avatarsRef.get()).forEach(async (doc: any) => {
				avatar = {
					...doc.data(),
					get id () { return doc.id },
				} as Avatar;
			});

			if (!avatar) {
				// avatar file
				const { name, type, size } = file;
				const fileRef = storage.ref(`avatars/users/${this.user.id}/uploads/${name}`);

				try {
					// Exists
					await fileRef.getDownloadURL();
				} catch (e) {
					// Not exists
					await fileRef.put(file);
				}

				// Record avatar file in database
				const nowDate: Date = new Date();
				await db.collection('avatars')
				const addedAvatarRef = await db.collection('avatars')
					.add({
						createdAt: nowDate,
						deletedAt: null,
						userId: this.user.id,
						teamId: null,
						type: UPLOAD_FILE_TYPE.IMG,
						name: name,
						contentType: type,
						size: size,
						hashHex,
						useCase: this.avatarUseCase || AVATAR_USE_CASE.RUNNIT,
					});
				avatar = {
					...(await addedAvatarRef.get()).data(),
					get id () { return addedAvatarRef.id },
				} as Avatar;
			}

			return avatar;
		}
	},
	components: {
	},
});
