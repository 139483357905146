
import Vue from 'vue';

export default Vue.extend({
	name: 'ResourceTile',
	props: {
		resource: { type: Object, default: null },
	},
	data() {
		return {};
	},
	methods: {
		onTileClick () {
			window.open(this.resource.url, '_blank');
		},
	},
});
