var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"white-transparent-chip",style:({
		..._vm.styles,
		padding: _vm.padding,
		fontSize: _vm.fontSize,
		width: _vm.width,
		height: _vm.height,
	})},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }