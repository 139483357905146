import 'intersection-observer'; // Vuetify v-lazy uses the w3c/IntersectionObserver under the hood. Need polyfill for more browser support.
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import AutoSoundMachine from './plugins/AutoSoundMachine';
import VueMoment from 'vue-moment';
import VueScrollTo from 'vue-scrollto';
import './filters';
import './styles/global.css';
import './styles/vuetify-overrides.css';
import _get from 'lodash/get';
import BaseButton from '@/components/base/BaseButton.vue';
import { GOOGLE_ANALYTICS_UID } from '@/constants/constants';
import { RawLocation, Route } from 'vue-router/types/router';
import VueRouter from 'vue-router';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import VueZoomer from 'vue-zoomer';
// import VueKonva from 'vue-konva';
import { firestorePlugin } from 'vuefire';
import iframeResize from 'iframe-resizer/js/iframeResizer';

Vue.config.productionTip = false;

// Plugin options: https://matteo-gabriele.gitbook.io/vue-gtag/v/master/plugin-options
Vue.use(VueGtag, {
	appName: 'RunDiffusion',
	globalObjectName: 'gtag',
	globalDataLayerName: 'dataLayer',
	config: {
		id: GOOGLE_ANALYTICS_UID,
	},
});
Vue.use(VueMeta, {
	// optional pluginOptions
	keyName: 'metaInfo',
	refreshOnceOnNavigation: true,
});
Vue.use(AutoSoundMachine);
Vue.use(firestorePlugin);
Vue.use(VueMoment);
Vue.use(VueZoomer);
// Vue.use(VueKonva);
Vue.use(VueScrollTo, {
	container: 'body',
	duration: 500,
	easing: 'ease',
	offset: -170,
	cancelable: true,
	onDone: false,
	onBack: false,
});

Vue.directive('resize', {
	bind: function(el, { value = {} }) {
		el.addEventListener('load', () => iframeResize(value, el))
	},
	unbind: function (el) {
		const iFrameResizer = _get(el, 'iFrameResizer');

		if (iFrameResizer) {
			iFrameResizer.removeListeners();
		}
	}
});

Vue.component('BaseButton', BaseButton);

// Global Mixin
Vue.mixin({
	methods: {
		_get,
		consoleLog: (...params) => console.log(...params),
		consoleError: (...params) => console.error(...params),
		routerPush: (dollarRoute: Route, dollarRouter: VueRouter, location: RawLocation): Promise<Route> => {
			const windowFullPath: string = window.location.pathname+window.location.search;
			const nextFullPath: string = dollarRouter.resolve(location).href;
			if (windowFullPath !== nextFullPath) {
				return dollarRouter.push(location) as Promise<Route>;
			}
			return Promise.resolve(dollarRoute);
		},
		routerReplace: (dollarRoute: Route, dollarRouter: VueRouter, location: RawLocation): Promise<Route> => {
			const windowFullPath: string = window.location.pathname+window.location.search;
			const nextFullPath: string = dollarRouter.resolve(location).href;
			if (windowFullPath !== nextFullPath) {
				return dollarRouter.replace(location) as Promise<Route>;
			}
			return Promise.resolve(dollarRoute);
		},
	},
});


new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app');
