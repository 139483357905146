
import Vue from 'vue';

export default Vue.extend({
	name: 'UnsupportedDialog',
	props: {
		value: { type: Boolean, default: false },
		unsupportedReason: { type: String, default: null },
		persistent: { type: Boolean, default: false },
		maxWidth: { type: Number, default: 700 },
	},
	data () {
		return {
			open: false,
		};
	},
	created () {
		this.open = !!this.value;
	},
	watch: {
		value (newVal: boolean) {
			this.open = !!newVal;
		},
	},
	methods: {
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
	},
});
