
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import LoadingState from '@/components/states/LoadingState.vue';
import { HardwareOffer, Session, SessionState } from '@run-diffusion/shared';
import _sortBy from 'lodash/sortBy';
import _isNil from 'lodash/isNil';
import AdminSessionCard from '@/views/Admin/AdminSessionsDashboard/AdminSessionCard.vue';
import { CURR_SESSION_STATES } from '@/constants/constants';

export default Vue.extend({
	name: 'RegularSessionsDashboard',
	data () {
		return {
			CURR_SESSION_STATES,
			orderedSessions: [],
			countsByState: {},
		};
	},
	computed: {
		...mapState([
			'hardwareOffersMap',
			'user',
			'adminRegularSessions',
			'loadingAdminRegularSessions',
		]),
	},
	created () {
		this.bindAdminRegularSessions(this.user);
	},
	watch: {
		adminRegularSessions: {
			immediate: true,
			handler (newVal: Session[]) {
				this.orderedSessions = _sortBy(newVal || [], (session: Session) => {
					const hardwareOffer: HardwareOffer = this.hardwareOffersMap[session.hardware];
					let sortOrder: number = hardwareOffer && hardwareOffer.sortOrder;
					sortOrder = _isNil(sortOrder) ? Infinity : sortOrder;
					return sortOrder;
				});

				this.countsByState = {};
				CURR_SESSION_STATES.forEach((state: SessionState) => {
					this.countsByState[state] = 0;
				});
				this.orderedSessions.forEach((session: Session) => {
					this.countsByState[session.state]++;
				});
			},
		},
	},
	methods: {
		...mapActions([
			'bindAdminRegularSessions',
		]),
	},
	components: {
		AdminSessionCard,
		LoadingState,
	},
});
