
import Vue from 'vue';
import { Session } from '@run-diffusion/shared';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import _trim from 'lodash/trim';
import { mapActions } from 'vuex';

export default Vue.extend({
	name: 'EditCurrSessionDialog',
	props: {
		session: { type: Object, required: true },
	},
	data () {
		return {
			dialogOpen: false,
			saving: false,

			displayName: null,
		};
	},
	computed: {
		fieldsDisabled () {
			return this.saving;
		},
		saveDisabled () {
			return !!(
				this.saving ||
				!_trim(this.displayName)
			);
		},
	},
	created () {
		this.initEditData(this.session);
	},
	watch: {
		session (newVal) {
			this.initEditData(newVal);
		},
	},
	methods: {
		...mapActions([
			'updateSessionDetails',
		]),
		initEditData (session: Session) {
			this.displayName = session.displayName || null;
		},
		async onSave () {
			try {
				this.saving = true;
				const { success } = await this.updateSessionDetails({
					sessionId: this.session.id,
					updateFields: {
						displayName: _trim(this.displayName),
					},
				});
				if (success) {
					this.dialogOpen = false;
				}
			} catch (e) {
				console.error(e);
			} finally {
				this.saving = false;
			}
		},
	},
	components: {
		ConfirmDialog,
	},
});
