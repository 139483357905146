
import Vue from 'vue';

export default Vue.extend({
	name: 'RunnitsInfoDialog',
	props: {
		value: { type: Boolean, default: false },
		title: { type: String, default: null },
		maxWidth: { type: [Number, String], default: 750 },
		persistent: { type: Boolean, default: false },
		bodyHeight: { type: String, default: null },
		bannerSrc: { type: String, default: null },
		actionsStyles: { type: [String, Object], default: null },
		showCancel: { type: Boolean, default: false },
		cancelBtnProps: { type: Object, default: () => ({ text: true }) },
		cancelText: { type: String, default: 'Close' },
		showConfirm: { type: Boolean, default: false },
		confirmBtnProps: { type: Object, default: () => ({ superPrimary: true }) },
		confirmText: { type: String, default: 'OK' },
	},
	data () {
		return {
			open: false,
		};
	},
	created () {
		this.open = !!this.value;
	},
	watch: {
		value (newVal: boolean) {
			this.open = !!newVal;
		},
	},
	methods: {
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
	},
});
