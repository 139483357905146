
import Vue from 'vue';
import _isNil from 'lodash/isNil';
import NumberWithIncrementer from '@/views/Runnits/RunnitSettings/NumberWithIncrementer.vue';

export default Vue.extend({
	name: 'BaseSlider',
	props: {
		incrementerPadding: { type: String, default: '0 14px 0 12px' },
		condensedIncrementer: { type: Boolean, default: false },
		numberMinWidth: { type: String, default: () => '40px' }
	},
	data () {
		return {
			visibleValue: null,
		};
	},
	watch: {
		'$attrs.value': {
			immediate: true,
			handler (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.visibleValue = newVal;
				}
			}
		},
	},
	methods: {
		_isNil,
		onIncrement (val: number) {
			this.$emit('input', val);
			this.$emit('change', val);
		},
		onInput (val: number) {
			this.visibleValue = val;
		},
	},
	components: {
		NumberWithIncrementer,
	},
});
