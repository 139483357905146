
import Vue from 'vue';
import _isEmpty from 'lodash/isEmpty';

export default Vue.extend({
	name: 'RunnitNavTabs',
	props: {
		tabs: { type: Array, required: true },
		currTabId: { type: String, default: null },
		tabStyles: { type: Object, default: () => ({ margin: '0 0 -4px 0' }) },
		tabsHeight: { type: String, default: '45px' },
		initialActiveTabId: { type: String, required: true },
		labelColor: { type: String, default: 'var(--grey-500)' },
		iconSize: { type: String, default: '15px' },
		noLabel: { type: Boolean, default: false },
		activeTabStyles: { type: Object, default: null },
		activeTabClasses: { type: Object, default: null },
		activeBackgroundColor: { type: String, default: 'var(--grey-950)' },
	},
	watch: {
		initialActiveTabId: {
			immediate: true,
			handler (newVal: string, oldVal: string) {
				if (newVal !== oldVal) {
					this.emitTabInput(newVal);
				}
			},
		},
	},
	methods: {
		_isEmpty,
		emitTabInput (tabId: string) {
			this.$emit('on-curr-tab-id-input', tabId);
		},
		isActive (tabId: string) {
			return this.currTabId === tabId;
		},
	}
});
