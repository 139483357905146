import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnits-pages-router"},[_c(VContainer,{staticClass:"py-0",class:{
			'px-0': _vm.shouldRemoveSideMargins,
		},attrs:{"fluid":""}},[_c(VRow,{staticClass:"my-0",class:{
				'mx-0': _vm.shouldRemoveSideMargins,
			}},[_c(VCol,{staticClass:"py-0",class:{
					'px-0': _vm.shouldRemoveSideMargins,
				},attrs:{"cols":"12"}},[(_vm.hideRouterViewForTeam)?_c('empty-state',{attrs:{"row-class":"mx-auto my-12 py-12","icon":"mdi-alert-box-outline"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center flex-column"},[_c('span',{staticClass:"text-center"},[_vm._v(" Your team \""+_vm._s(_vm.team.name)+"\" does not have a Runnits plan. ")]),_c('div',{staticClass:"mt-6 full-width d-flex justify-center align-center",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"mr-6 text-right"},[_vm._v("Switch accounts")]),_c('div',{staticClass:"pa-1",staticStyle:{"background-color":"var(--grey-900)","border-radius":"var(--global-border-radius)"}},[_c('runnit-account-selection-dialog',{attrs:{"is-side-nav-design":""}})],1)]),_c('div',{staticClass:"mt-6 full-width d-flex justify-center align-center",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"mr-6 text-right"},[_vm._v("Or upgrade your plan")]),_c('base-button',{attrs:{"super-primary":""},on:{"click":_vm.goToRunnitsSubscriptionsPage}},[_vm._v(" View Plans ")])],1)])]},proxy:true}],null,false,1481305219)}):_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }