
import Vue from 'vue';
import SessionTimer from '@/components/Toolbar/SessionTimer/SessionTimer.vue';

export default Vue.extend({
	name: 'ExtendOfferPriceCard',
	props: {
		countDownTimerVal: { type: String, required: true },
		session: { type: Object, required: true },
	},
	components: {
		SessionTimer,
	},
});
