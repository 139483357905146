import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,_vm._g(_vm._b({staticClass:"base-drawer",attrs:{"value":_vm.open,"disabled":_vm.disabled,"permanent":_vm.disabled || _vm.permanent,"fixed":_vm.fixed,"right":_vm.right,"width":_vm.drawerWidth},on:{"input":_vm.setOpen}},'v-navigation-drawer',_vm.$attrs,false),_vm.$listeners),[_c(VContainer,{staticStyle:{"background":"var(--grey-900)"},style:(_vm.containerStyles)},[_c('div',{staticClass:"d-flex"},[_vm._t("toolbar",function(){return [_c(VToolbar,{attrs:{"flat":"","color":"transparent"}},[(!_vm.closeOnRight)?_c(VBtn,{staticClass:"ml-0",attrs:{"icon":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.setOpen(false)}}},[_c(VIcon,{staticStyle:{"color":"var(--blue-grey-200)"}},[_vm._v(_vm._s(_vm.closeIcon))])],1):_vm._e(),(!_vm.closeOnRight)?_c(VSpacer):_vm._e(),_vm._t("title",function(){return [_c('div',{staticClass:"has-ellipsis",class:{
								'text-right': !_vm.closeOnRight,
								'ml-10 mr-5': !_vm.closeOnRight,
								'text-left': _vm.closeOnRight,
								'mr-8': _vm.closeOnRight,
							},staticStyle:{"font-size":"22px","color":"var(--blue-grey-200)"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]}),(_vm.closeOnRight)?_c(VSpacer):_vm._e(),(_vm.closeOnRight)?_c(VBtn,{staticClass:"ml-0",attrs:{"icon":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.setOpen(false)}}},[_c(VIcon,{staticStyle:{"color":"var(--blue-grey-200)"}},[_vm._v(_vm._s(_vm.closeIcon))])],1):_vm._e()],2)]},{"setOpen":_vm.setOpen})],2),_vm._t("default"),(_vm.actionsIslandIsFixedBottom)?_c(VSlideYReverseTransition,[(_vm.showActions)?_c('actions-island-fixed-bottom',{attrs:{"styles":_vm.actionsIslandStyles}},[_vm._t("actions")],2):_vm._e()],1):_c(VSlideYReverseTransition,[(_vm.showActions)?_c('actions-island',{attrs:{"styles":_vm.actionsIslandStyles}},[_vm._t("actions")],2):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }