import Vue from 'vue';
import { mapState } from 'vuex';
import { APP_KEY } from '@/constants/enums';

export const DownloadModelInstructionsMixin = Vue.extend({
	computed: {
		...mapState([
			'toolbar',
			'preloadModel',
			'appOffersMap',
		]),
		isFileBrowserSession () {
			return this.toolbar.session.apps[0] === APP_KEY.FILE_001;
		},
		appOfferDisplayText () {
			return this.appOffersMap[this.toolbar.session.apps[0]] ? this.appOffersMap[this.toolbar.session.apps[0]].label : this.toolbar.session.apps[0];
		},
		showAnyModelDropdownExampleScreenshot () {
			return !!(
				this.showAuto1111ModelDropdownExampleScreenshot ||
				this.showVladModelDropdownExampleScreenshot ||
				this.showFcusModelDropdownExampleScreenshot ||
				this.showInvkModelDropdownExampleScreenshot ||
				this.showEnfgModelDropdownExampleScreenshot ||
				this.showMkyoModelDropdownExampleScreenshot
			);
		},
		showAuto1111ModelDropdownExampleScreenshot () {
			return this.toolbar.session.apps[0] === APP_KEY.AUTO_001;
		},
		showVladModelDropdownExampleScreenshot () {
			return this.toolbar.session.apps[0] === APP_KEY.VLAD_001;
		},
		showFcusModelDropdownExampleScreenshot () {
			return this.toolbar.session.apps[0] === APP_KEY.FCUS_001;
		},
		showInvkModelDropdownExampleScreenshot () {
			return this.toolbar.session.apps[0] === APP_KEY.INVK_001;
		},
		showEnfgModelDropdownExampleScreenshot () {
			return this.toolbar.session.apps[0] === APP_KEY.ENFG_001;
		},
		showMkyoModelDropdownExampleScreenshot () {
			return this.toolbar.session.apps[0] === APP_KEY.MKYO_001;
		},
	},
});
