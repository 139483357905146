
import Vue from 'vue';
import moment, { Moment } from 'moment-timezone';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import { DateRangeOption } from '@/components/base/types';

export default Vue.extend({
	name: 'PredefinedDateRangeOptions',
	props: {
		value: { type: Object as () => DateRangeOption, default: null },
		todayDate: { type: String, default: null },
	},
	data () {
		const DATE_RANGE_OPTION_IDS = {
			LAST_7_DAYS: 'LAST_7_DAYS',
			PREVIOUS_7_DAYS: 'PREVIOUS_7_DAYS',
			LAST_30_DAYS: 'LAST_30_DAYS',
			PREVIOUS_30_DAYS: 'PREVIOUS_30_DAYS',
			LAST_YEAR: 'LAST_YEAR',
		};
		return {
			optionTemplates: [
				{
					id: DATE_RANGE_OPTION_IDS.LAST_7_DAYS,
					label: 'Last 7 days',
					days: 7,
					offset: 0,
				},
				{
					id: DATE_RANGE_OPTION_IDS.PREVIOUS_7_DAYS,
					label: 'Previous 7 days (7-14 days)',
					days: 7,
					offset: 7,
				},
				{
					id: DATE_RANGE_OPTION_IDS.LAST_30_DAYS,
					label: 'Last 30 days',
					days: 30,
					offset: 0,
				},
				{
					id: DATE_RANGE_OPTION_IDS.PREVIOUS_30_DAYS,
					label: 'Previous 30 days (30-60 days)',
					days: 30,
					offset: 30,
				},
				{
					id: DATE_RANGE_OPTION_IDS.LAST_YEAR,
					label: 'Last 12 months',
					days: 365,
					offset: 0,
				},
			],
			dateRangeOptions: [],
		};
	},
	created () {
		this.buildDateRangeOptions();
	},
	methods: {
		buildDateRangeOptions () {
			this.dateRangeOptions = this.optionTemplates.map((template): DateRangeOption => {
				// Use moment.js to get today's date
				const today: Moment = this.todayDate ? moment(this.todayDate) : moment();

				// Calculate the end date by subtracting the offset from today
				const endDate: Moment = moment(today).subtract(template.offset, 'days');

				// Calculate the start date by subtracting the days from the end date
				const startDate: Moment = moment(endDate).subtract(template.days, 'days');

				// Format the dates to a more readable format (e.g., 'YYYY-MM-DD')
				const formattedStartDate = startDate.format('YYYY-MM-DD');
				const formattedEndDate = endDate.format('YYYY-MM-DD');

				return {
					id: template.id,
					label: template.label,
					startDate: formattedStartDate,
					endDate: formattedEndDate
				};
			});
			this.onSelectDateRangeOption(this.dateRangeOptions[0], null);
		},
		onSelectDateRangeOption (dateRangeOption: DateRangeOption, close) {
			this.$emit('input', dateRangeOption);
			if (close) {
				close();
			}
		},
	},
	components: {
		MenuOrBottomSheet,
	},
});
