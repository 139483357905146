
import Vue from 'vue';
import CircleIcon from '@/components/base/CircleIcon.vue';

export default Vue.extend({
	name: 'EmptyState',
	props: {
		message: { type: String, default: null },
		icon: String,
		iconColor: { type: String, default: '#757575' },
		iconBackgroundColor: { type: String, default: '#252525' },
		iconBorderColor: { type: String, default: '#2a2a2a' },
		fontColor: { type: String, default: 'var(--grey-600)' },
		rowClass: { type: String, default: 'my-12 py-12' },
		preventMobileIconResize: { type: Boolean, default: false },
	},
	components: {
		CircleIcon,
	},
});
