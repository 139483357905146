
import Vue from 'vue';
import { mapState } from 'vuex';
import JoinOurDiscord from '@/components/Toolbar/JoinOurDiscord.vue';

export default Vue.extend({
	name: 'UserNotActivated',
	computed: {
		...mapState([
			'user',
		]),
	},
	components: {
		JoinOurDiscord,
	},
});
