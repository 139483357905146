
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { REPORTS } from '@/constants/constants';

export default Vue.extend({
	name: 'ImageAuditReport',
	props: {
		selectedTeam: { type: Object, default: null },
		selectedCompany: { type: Object, default: null },
	},
	data () {
		return {
			// Dashboards
			teamMetricsDashboardUrl: null,
		};
	},
	watch: {
		selectedTeam: {
			immediate: true,
			async handler (newVal, oldVal) {
				if (newVal !== oldVal) {
					await this.loadTeamReportEmbedUrls({ team: newVal, company: this.selectedCompany });
				}
			},
		},
		teamReportEmbedUrlsData: {
			immediate: true,
			handler (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.teamMetricsDashboardUrl = this._get(newVal, `dashboards[${REPORTS.DASHBOARDS.IMAGE_AUDIT_DASHBOARD}]`)
				}
			},
		},
	},
	computed: {
		...mapState([
			'user',
			'teamReportEmbedUrlsData',
			'teamReportEmbedUrlsDataLoading',
		]),
	},
	methods: {
		...mapActions([
			'loadTeamReportEmbedUrls',
		]),
	},
});
