import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VWindowItem,{attrs:{"value":_vm.windowItemValue}},[_c(VListItem,{staticClass:"menu-list-item",on:{"click":_vm.onBack}},[_c(VIcon,{staticClass:"menu-item-color"},[_vm._v("mdi-chevron-left")]),_c('div',{staticClass:"ml-5 menu-item-color"},[_vm._v(" Back ")])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('session-sounds-settings',{staticClass:"px-6",attrs:{"preview-btn-class":"mt-4"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }