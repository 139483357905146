<template>
	<div
		class="drawer-settings-container"
		:style="{
			...containerStyles,
		}"
	>
		<div class="d-flex justify-space-between">
			<div class="d-flex flex-column">
				<div
					v-if="title"
					class="drawer-settings-title"
					:style="{
						padding: hasSubtitleSlot ? '0 0 5px 0' : '0 0 15px 0',
					}"
				>
					{{ title }}
				</div>
				<div
					v-if="hasSubtitleSlot"
					class="drawer-settings-subtitle"
				>
					<slot name="subtitle"></slot>
				</div>
			</div>
			<div>
				<slot name="top-right"></slot>
			</div>
		</div>
		<div class="drawer-settings-content">
			<slot name="default"></slot>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'DrawerSettingsContainer',
	props: {
		title: { type: String, default: null },
		containerStyles: { type: Object, default: () => ({}) },
	},
	computed: {
		hasSubtitleSlot () {
			return !!this.$slots.subtitle || !!this.$scopedSlots.subtitle;
		},
	}
});
</script>

<style scoped lang="scss">
.drawer-settings {
	&-container {
		padding: 20px;
		margin: 15px 0 25px 0;
		border-radius: 12px;
		background: var(--grey-850);
	}
	&-title {
		font-size: 15px;
		font-weight: bold;
		font-family: "nasalization", sans-serif;
		color: var(--grey-450);
	}
	&-subtitle {
		font-size: 14px;
		color: var(--grey-500);
		padding: 0 0 25px 0;
	}
}
</style>
