
import Vue from 'vue';

export default Vue.extend({
	name: 'EmptyStateCircleBackground',
	props: {
		title: { type: String, default: '' },
		subtitle: { type: String, default: '' },
		circleWidth: { type: Number, default: 280 },
		circleHeight: { type: Number, default: 280 },
	},
	data () {
		return {};
	},
});
