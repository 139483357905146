import Vue from 'vue';
import { mapState } from 'vuex';

export const TeamUserMixin = Vue.extend({
	computed: {
		...mapState([
			'user',
			'team',
		]),
		isTeamAdmin () {
			return !!(
				this.user &&
				this.team &&
				this.team.adminIds &&
				this.team.adminIds[this.user.id]
			);
		},
		isTeamCreator () {
			return !!(
				this.user &&
				this.team &&
				this.team.creatorIds &&
				this.team.creatorIds[this.user.id]
			);
		},
		isOnboardingTeam () {
			return !!(
				this.team &&
				this.team.isOnboarding
			);
		},
		isTeamActive () {
			return !!(
				this.team &&
				!!this.team.isActive
			);
		},
	},
});
