
import Vue from 'vue';

export default Vue.extend({
	name: 'BaseToggleButton',
	props: {
		value: { type: Boolean, default: false },
		width: { type: String, default: '250px' },
		large: { type: Boolean, default: false },
	},
	data() {
		return {
			isOn: this.value,
		};
	},
	watch: {
		value(newValue) {
			this.isOn = newValue;
		},
	},
	methods: {
		toggle() {
			this.isOn = !this.isOn;
			this.$emit('input', this.isOn);
		},
	},
	components: {
	},
});
