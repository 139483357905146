
import Vue from 'vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'PageTopNavigation',
	props: {
		tabsData: {
			type: Array,
			required: true,
		},
		backgroundColor: { type: String, default: 'var(--grey-1000)' },
	},
	data () {
		return {};
	},
	computed: {
		selectedTab () {
			switch (this.$route.name) {
				case ROUTER.IMAGE_GENERATIONS_REPORT:
					return 0;
				case ROUTER.IMAGE_AUDIT_REPORT:
					return 1;
				case ROUTER.USER_USAGE_REPORT:
					return 2;
				case ROUTER.APP_METRICS_REPORT:
					return 3;
				default:
					return 0;
			}
		},
	},
	methods: {
		navigate (route) {
			this.routerPush(this.$route, this.$router, { name: route });
		},
	},
});
