
import Vue from 'vue';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import LimitClubAddOnSelectItem from '@/views/Runnits/pages/RunnitsSubscriptionsPage/LimitClubAddOnSelect/LimitClubAddOnSelectItem.vue';
import { LimitClubAddOn } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'LimitClubAddOnSelect',
	components: {
		LimitClubAddOnSelectItem,
		MenuOrBottomSheet,
	},
	props: {
		limitClubAddOns: { type: Array, required: true },
		clubOffer: { type: Object, required: true },
		selectedItem: { type: Object, default: null },
		currentPlanItem: { type: Object, default: null },
	},
	methods: {
		isCurrentPlanItem (item: LimitClubAddOn) {
			return !!(this.currentPlanItem && item && this.currentPlanItem.id === item.id);
		},
	},
});
