
import Vue from 'vue';
import BorderSelectCard from '@/components/BorderSelectCard.vue';
import { mapState } from 'vuex';
import { getHardwareHourlyPrice } from '@run-diffusion/shared';
import { SELECTED_STORAGE_TYPE } from '@/constants/constants';

export default Vue.extend({
	name: 'HardwareOfferCard',
	props: {
		appOffer: { type: Object, required: true },
		hardwareOffer: { type: Object, required: true },
		selected: { type: Boolean, default: false },
		selectedStorageType: {
			type: String,
			validator: (val: string) => [SELECTED_STORAGE_TYPE.PRIVATE, SELECTED_STORAGE_TYPE.TEAM].includes(val),
			required: true,
		},
	},
	data () {
		return {
			SELECTED_STORAGE_TYPE,
		};
	},
	computed: {
		...mapState([
			'team',
			'user',
		]),
		descriptionHtml () {
			return this._get(this.hardwareOffer.descriptionHtmlAppOverride, this.appOffer.app) || this.hardwareOffer.descriptionHtml;
		},
	},
	methods: {
		getHardwareHourlyPrice,
	},
	components: {
		BorderSelectCard,
	},
});
