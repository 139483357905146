
import Vue from 'vue';
// import WorkstationUi from '../Launch/WorkstationUi/WorkstationUi';
// import AffiliateAndPromotions from '@/views/Promotions/AffiliateAndPromotions';
// import PrototypeEmailAssets from '@/views/Prototyping/PrototypeEmailAssets';
import { mapActions, mapState } from 'vuex';
// import TeamPlanChoices from '@/views/Team/TeamPlanSignup';
import { functions, db } from '@/firebase';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { downloadCsvFromArray } from '@/utils';
import moment from 'moment-timezone';
import _omit from 'lodash/omit';
import _keys from 'lodash/keys';
import TeamAppMetricsReport from '@/views/Reports/TeamReports.vue';

export default Vue.extend({
	name: 'Prototyping',
	data () {
		return {
			numProcessedUserProspectingInfos: 0,
			processingUserProspectingInfos: false,
			processingUserProspectingInfosPageSize: 10,
			processingUserProspectingInfosLastDocId: null,
			downloadingUserProspectingInfos: false,
		};
	},
	computed: {
		...mapState([
			'user',
		]),
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		async runMigration () {
			try {
				const functionRef = functions
					.httpsCallable('runMigration');
				const result = (await functionRef()).data;
				console.log(result);
			} catch (e) {
				console.error(e);
			}
		},
		async getCanceledStorageVolumesData () {
			try {
				const functionRef = functions
					.httpsCallable('getCanceledStorageVolumesData');
				const result = (await functionRef({
					lastId: null,
				})).data;
				console.log(result);
			} catch (e) {
				console.error(e);
			}
		},
		async onProcessUserProspectingInfos (closeDialogCallback: Function) {
			const onError: Function = (e) => {
				console.error('Error processing user prospecting info: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error processing user prospecting info',
					show: true,
				});
			};

			try {
				this.numProcessedUserProspectingInfos = 0;
				this.processingUserProspectingInfos = true;

				// Keep fetching til we have processed all users
				let keepFetching: boolean = true;
				while (keepFetching) {
					const functionRef = functions
						.httpsCallable('processUserProspectingInfos');
					const { data, success, lastDocId } = (await functionRef({
						lastDocId: this.processingUserProspectingInfosLastDocId,
						pageSize: this.processingUserProspectingInfosPageSize,
					})).data;
					if (!success) {
						onError(new Error('processUserProspectingInfos not successful'));
						keepFetching = false;
					} else {
						console.log('processUserProspectingInfos', data);
						this.numProcessedUserProspectingInfos += (data || []).length;

						this.processingUserProspectingInfosLastDocId = lastDocId;
						if (!this.processingUserProspectingInfosLastDocId) {
							keepFetching = false;
						}
					}
				}

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Users processed`,
					show: true,
				});
			} catch (e) {
				onError(e);
			} finally {
				this.processingUserProspectingInfos = false;
				if (closeDialogCallback) closeDialogCallback();
			}
		},
		async onDownloadUserProspectingInfos (closeDialogCallback: Function) {
			const onError: Function = (e) => {
				console.error('Error downloading user prospecting info: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error downloading user prospecting info',
					show: true,
				});
			};

			try {
				this.downloadingUserProspectingInfos = true;

				let fetchedUserProspectingInfosSnapshots: any[] = [];
				const userProspectingInfosRef = db
					.collection('userProspectingInfos')
					.limit(10000);
				let lastUserProspectingInfoDoc = null;
				let userProspectingInfosSnapshot;
				do {
					if (lastUserProspectingInfoDoc) {
						userProspectingInfosSnapshot = await userProspectingInfosRef.startAfter(lastUserProspectingInfoDoc).get();
					} else {
						userProspectingInfosSnapshot = await userProspectingInfosRef.get();
					}

					if (userProspectingInfosSnapshot.empty) break;

					lastUserProspectingInfoDoc = userProspectingInfosSnapshot.docs[userProspectingInfosSnapshot.docs.length - 1];

					fetchedUserProspectingInfosSnapshots = fetchedUserProspectingInfosSnapshots.concat(userProspectingInfosSnapshot.docs);
				} while (true); // Pagination loop

				downloadCsvFromArray(
					`user-prospecting-infos-${moment().format('MM-DD-YYYY')}.csv`,
					fetchedUserProspectingInfosSnapshots.map(snap => {
						const data: any = snap.data();
						return {
							..._omit(data, 'teamIds'),
							numTeams: _keys(data.teamIds || {}).length,
						};
					}),
				);

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! CSV downloaded`,
					show: true,
				});
			} catch (e) {
				onError(e);
			} finally {
				this.downloadingUserProspectingInfos = false;
				if (closeDialogCallback) closeDialogCallback();
			}
		},
	},
	components: {
		TeamAppMetricsReport,
		// ConfirmDialog,
		// TeamPlanChoices,
		// PrototypeEmailAssets,
		// AffiliateAndPromotions,
		// WorkstationUi,
	},
});
