<template>
	<div
		class="info-asset-container"
		:style="{
			flexDirection: $vuetify.breakpoint.xsOnly ? 'column' : 'row',
		}"
	>
		<div
			v-if="$vuetify.breakpoint.xsOnly"
			class="info-asset-asset"
			:style="{
				flex: assetFlex,
				margin: '0 0 30px 0',
			}"
		>
			<slot name="asset"></slot>
		</div>
		<div
			class="info-asset-info"
			:style="{
				flex: infoFlex,
			}"
		>
			<slot name="info"></slot>
		</div>
		<div
			v-if="$vuetify.breakpoint.smAndUp"
			class="info-asset-asset"
			:style="{
				flex: assetFlex,
			}"
		>
			<slot name="asset"></slot>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'InfoAssetBanner',
	props: {
		infoFlex: { type: Number, default: 6 },
		assetFlex: { type: Number, default: 2 },
	},
});
</script>

<style scoped lang="scss">
.info-asset {
	&-container {
		display: flex;
		align-items: center;
		margin: 25px 20px 35px 20px;
	}
	&-info {
		font-size: 16px;
		color: var(--grey-500);
		padding: 0 0 0 0;
	}
	&-asset {
		display: flex;
		justify-content: center;
	}
}
</style>
