
import Vue from 'vue';

export default Vue.extend({
	name: 'ImageGalleryLoadMoreBtnsRow',
	props: {
		loadMoreLoading: { type: Boolean, default: false },
		loadMoreDisabled: { type: Boolean, default: false },
	},
});
