
import Vue from 'vue';
import VueRecaptcha from 'vue-recaptcha';

export default Vue.extend({
	name: 'RecaptchaCheckbox',
	methods: {
		emitInput (response: string) {
			this.$emit('input', response);
		},
		onVerify (response: string) {
			this.emitInput(response);
		},
		onExpired () {
			this.emitInput(null);
		},
		onRender () {
			// Do nothing
		},
		onError () {
			this.emitInput(null);
		},
	},
	components: {
		VueRecaptcha,
	},
});
