
import Vue from 'vue';
import { db } from '@/firebase';
import { mapActions } from 'vuex';
import {
	RUNNIT_NODE_DEF_TAG_TYPE,
	READABLE_RUNNIT_NODE_DEF_TAG_TYPE,
} from '@/constants/enums';
import { RunnitNodeDefTag, RunnitNodeDefTagType } from '@run-diffusion/shared';
import BaseButton from '@/components/base/BaseButton.vue';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { v4 as uuidv4 } from 'uuid';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';

export default Vue.extend({
	name: 'RunnitDuplicateEditNodeDefInternalEditor',
	props: {
		value: { type: Boolean, default: false },
		selectedTags: { type: Array, default: () => ([]) },
		disabled: { type: Boolean, default: false },
		teams: { type: Array, default: () => ([]) },
		teamsLoading: { type: Boolean, default: false },
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TAG_TYPE,
			READABLE_RUNNIT_NODE_DEF_TAG_TYPE,
			WINDOW_STEPS: {
				EXISTING: 'EXISTING',
				CREATE: 'CREATE',
				EDIT: 'EDIT',
			},
			windowStep: 'EXISTING',
			open: false,
			fetchingTags: false,
			tags: [] as RunnitNodeDefTag[],
			tagsDialogOpen: false,
			selectedTagsMap: {},


			updatingTag: false,
			tagToEdit: null,
			editFormValid: false,

			creatingTag: false,
			createFormValid: false,
			tagToCreate: null,

			confirmDialogOpen: false,
			confirmDialogText: null,
			confirmPromiseResolve: null,
		};
	},
	created () {
		// fetch existing tags (team agnostic, and team specific)
		this.fetchTags();
	},
	watch: {
		selectedTags: {
			immediate: true,
			handler (newVal: RunnitNodeDefTag[]) {
				this.selectedTagsMap = {};
				newVal.forEach(tag => {
					this.selectedTagsMap[tag.id] = true;
				});
			},
		}
	},
	computed: {
		title (): string {
			switch (this.windowStep) {
				case this.WINDOW_STEPS.CREATE:
					return 'Create Tag';
				case this.WINDOW_STEPS.EDIT:
					return 'Edit Tag';
				case this.WINDOW_STEPS.EXISTING:
				default:
					return 'Manage Tags';
			}
		},
		tagsByTypeMap (): Record<string, RunnitNodeDefTag> {
			return this.tags.reduce((map, tag) => {
				if (!map[tag.type]) {
					map[tag.type] = [];
				}
				map[tag.type] = _sortBy([...map[tag.type], tag], ['sortOrder', 'label']);
				return map;
			}, {});
		},
		tagsByTeamMap (): Record<string, RunnitNodeDefTag> {
			return this.tags.reduce((map, tag) => {
				if (tag.teamId) {
					if (!map[tag.teamId]) {
						map[tag.teamId] = [];
					}
					map[tag.teamId] = _sortBy([...map[tag.teamId], tag], ['sortOrder', 'label']);
				}
				return map;
			}, {})
		},
		teamIdToTeamName (): Record<string, string> {
			return this.teams.reduce((map, team) => {
				map[team.id] = team.name;
				return map;
			}, {});
		},
		tagTypes (): { text: string, value: RunnitNodeDefTagType }[] {
			return Object.values(RUNNIT_NODE_DEF_TAG_TYPE).map(t => {
				return {
					text: READABLE_RUNNIT_NODE_DEF_TAG_TYPE[t],
					value: t,
				}
			})
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
		async createTag () {
			this.creatingTag = true;
			try {
				if (!this.$refs.createForm.validate()) {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Form invalid, check fields',
						show: true,
					});
					return;
				}

				this.tagToCreate = {
					createdAt: new Date(),
					deletedAt: null,
					isDeleted: false,
					teamId: null,
					sortOrder: this.getLastSortOrder(this.tagToCreate.teamId || this.tagToCreate.type),
					...this.tagToCreate
				}
				const newTagDocRef = await db.collection(`runnitNodeDefTags/`).add(this.tagToCreate);
				const newTag = {
					...(await newTagDocRef.get()).data(),
					get id () { return newTagDocRef.id }
				};

				this.tags.push(newTag);

				this.$emit('on-tag-create', newTag)
				this.tagToCreate = null;
				this.changeWindowStep(this.WINDOW_STEPS.EXISTING);

			} catch (err) {
				console.error(err);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error creating tag',
					show: true,
				});
			} finally {
				this.creatingTag = false;
			}
		},
		async changeWindowStep (toStep): Promise<boolean> {
			const currentStep = this.windowStep;

			switch (currentStep) {
				case this.WINDOW_STEPS.EXISTING: {
					this.windowStep = toStep;
					return true;
				}
				case this.WINDOW_STEPS.CREATE: {
					if (this.tagToCreate && !_isEmpty(this.tagToCreate) && !(await this.didConfirm('Leaving now will cause your changes to be lost'))) {
						return false;
					}
					this.tagToCreate = null;
					this.windowStep = toStep;
					return true;
				}
				case this.WINDOW_STEPS.EDIT: {
					const sourceTag = this.tags.find(t => t.id === this.tagToEdit.id)
					if (this.tagToEdit && sourceTag && !_isEqual(this.tagToEdit, sourceTag) && !(await this.didConfirm('Leaving now will cause your changes to be lost'))) {
						return false;
					}
					this.tagToEdit = null;
					this.windowStep = toStep;
					return true;
				}
				default:
					// unknown step
					return false;
			}
		},
		async deleteTag (tag: RunnitNodeDefTag) {
			if (await this.didConfirm('this will soft delete the tag, making it no longer used in the client.')) {
				await this.updateTag({ ...tag, deletedAt: new Date(), isDeleted: true });
				// TODO set the sortOrder for every tag in that type
				await this.updateAllTagsInGroup(tag.teamId || tag.type)
			}
		},
		async updateAllTagsInGroup (groupId: RunnitNodeDefTagType | string) {
			if (this.tagsByTeamMap[groupId]) {
				await Promise.all(this.tagsByTeamMap[groupId].map(async (tag, index) => {
					this.updateTag({ ...tag, sortOrder: index });
				}))
			}
			if (this.tagsByTypeMap[groupId]) {
				await Promise.all(this.tagsByTypeMap[groupId].map(async (tag, index) => {
					this.updateTag({ ...tag, sortOrder: index });
				}))
			}
		},
		async startEditTag (tag: RunnitNodeDefTag) {
			if (await this.changeWindowStep(this.WINDOW_STEPS.EDIT)) {
				this.tagToEdit = _cloneDeep(tag);
			}
		},
		async startCreateTag () {
			if (await this.changeWindowStep(this.WINDOW_STEPS.CREATE)) {
				this.tagToCreate = {};
			}
		},
		async editTag () {
			if (!this.$refs.editForm.validate()) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Form invalid, check fields',
					show: true,
				});
				return;
			}
			await this.updateTag(this.tagToEdit);
		},
		async updateTag (tag: RunnitNodeDefTag) {
			this.updatingTag = true;
			try {
				if (tag.type !== RUNNIT_NODE_DEF_TAG_TYPE.TEAM) {
					delete tag.teamId;
				}
				const runnitNodeDefTagRef = db.doc(`runnitNodeDefTags/${tag.id}`);

				await runnitNodeDefTagRef.update({
					type: tag.type,
					teamId: tag.teamId || null,
					label: tag.label,
					deletedAt: tag.deletedAt,
					isDeleted: tag.isDeleted || false,
					sortOrder: tag.sortOrder || 0,
				});

				const tagToEditIndex = this.tags.findIndex(t => t.id === tag.id)
				if (tagToEditIndex !== -1) {
					if (tag.isDeleted) {
						this.tags.splice(tagToEditIndex, 1);
					} else {
						this.tags.splice(tagToEditIndex, 1, tag);
					}
				}
				this.$emit('on-tag-edit', _cloneDeep(tag));
				this.changeWindowStep(this.WINDOW_STEPS.EXISTING);
			} catch (err) {
				console.error(err);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error updating tag',
					show: true,
				});
			} finally {
				this.updatingTag = false;
			}
		},
		labelCannotMatchRule (label: string, id: string, type: RunnitNodeDefTagType, teamId: string) {
			if (type === RUNNIT_NODE_DEF_TAG_TYPE.TEAM) {
				if ((this.tagsByTeamMap[teamId] || []).findIndex((t) => t.label === label && t.id !== id) !== -1) {
					return 'tag labels cannot match within the team';
				}
			} else if ((this.tagsByTypeMap[type] || []).findIndex((t) => t.label === label && t.id !== id) !== -1) {
				return 'tag labels cannot match within the type';
			}
			return false;
		},
		didConfirm (text: string) {
			this.confirmDialogText = text;
			this.confirmDialogOpen = true;
			return new Promise((resolve) => {
				this.confirmPromiseResolve = (didConfirm) => {
					this.confirmDialogOpen = false;
					resolve(didConfirm);
				};
			});
		},
		async fetchTags () {
			try {
				this.fetchingTags = true;
				const tagsRef = db.collection(`runnitNodeDefTags`)
					.where('isDeleted', '==', false);

				(await tagsRef.get()).forEach(async (doc: any) => {
					const tag: RunnitNodeDefTag = {
						...doc.data(),
						get id () { return doc.id },
					} as RunnitNodeDefTag;
					this.tags.push(tag);
				});
			} catch (err) {
				console.error(err);
			} finally {
				this.fetchingTags = false;
			}
		},
		async switchSortOrder (tagA: RunnitNodeDefTag, tagB: RunnitNodeDefTag) {
			const newTagA = {
				...tagA,
				sortOrder: tagB.sortOrder,
			};

			const newTagB = {
				...tagB,
				sortOrder: tagA.sortOrder,
			}

			await Promise.all([this.updateTag(newTagA), this.updateTag(newTagB)]);
		},
		getLastSortOrder (groupId: RunnitNodeDefTagType | string) {
			if (this.tagsByTeamMap[groupId]) {
				return this.tagsByTeamMap[groupId].length;
			}
			if (this.tagsByTypeMap[groupId]) {
				return this.tagsByTypeMap[groupId].length;
			}
			return 0;
		},
	},
	components: {
		BaseButton,
		ConfirmDialog,
	},
});
