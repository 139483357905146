
import Vue from 'vue';
import { mapState } from 'vuex';
import RunnitImage from './RunnitImage.vue';
import { RunnitsCRUDMixin, TeamUserMixin } from '@/mixins';

export default Vue.extend({
	name: 'RunnitTitleBar',
	mixins: [
		RunnitsCRUDMixin,
		TeamUserMixin,
	],
	computed: {
		...mapState([
			'runnitState',
		]),
		computedTitleTruncateLength () {
			let length = 15;

			if (this.$vuetify.breakpoint.lgAndUp) {
				length = 30;
			}

			return length;
		},
		createNewMode () {
			return !!this.runnitState.runnitDraft;
		},
		canEdit () {
			if (!this.runnitState.runnit) return false;
			return !!(
				this.createNewMode ||
				this.runnitState.runnit.userId === this.user.id || // you created it
				this.isTeamAdmin // team admin
			);
		},
	},
	components: {
		RunnitImage,
	},
});
