
import Vue from 'vue';
import BigNumber from 'bignumber.js';

export default Vue.extend({
	name: 'WorkshopSessionCostPreview',
	props: {
		numMultiply: { type: Number, default: 1 },
		hardwareHourlyPrice: { type: Number, default: 0 },
		scheduledStopMins: { type: Number, default: 0 },
		showScheduledStopMinsPreview: { type: Boolean, default: false },
	},
	computed: {
		hasLowerNoteSlot () {
			return !!this.$slots.lowerNote || !!this.$scopedSlots.lowerNote;
		},
		scheduledStopMinsData () {
			const hrs: number = Math.floor(this.scheduledStopMins / 60);
			const mins: number = this.scheduledStopMins % 60;
			return {
				hrs,
				mins,
			};
		},
		costEstimateAmt () {
			const purchaseHrsDecimal: BigNumber = new BigNumber(this.scheduledStopMins).div(60);
			const cost: BigNumber = purchaseHrsDecimal.times(this.hardwareHourlyPrice);
			const wholeIntCost: BigNumber = cost.integerValue(BigNumber.ROUND_HALF_UP);

			return wholeIntCost.times(this.numMultiply).toNumber();
		},

	},
});
