
import Vue from 'vue';
import { mapState } from 'vuex';
import EmptyState from '@/components/states/EmptyState.vue';
import HardwareOfferCard from '@/views/Sessions/HardwareOffersList/HardwareOfferCard.vue';
import { SELECTED_STORAGE_TYPE } from '@/constants/constants';

export default Vue.extend({
	name: 'HardwareOffersList',
	props: {
		appOffer: { type: Object, required: true },
		hardwareOffer: { type: Object, default: null },
		selectedStorageType: {
			type: String,
			validator: (val: string) => [SELECTED_STORAGE_TYPE.PRIVATE, SELECTED_STORAGE_TYPE.TEAM].includes(val),
			required: true,
		},
	},
	computed: {
		...mapState([
			'hardwareOffers',
		]),
		filteredHardwareOffers () {
			return this.hardwareOffers.filter(({ apps }) => apps.includes(this.appOffer.app));
		},
	},
	components: {
		HardwareOfferCard,
		EmptyState,
	},
});
