
import Vue from 'vue';
import EmptyState from '@/components/states/EmptyState.vue';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import _slice from 'lodash/slice';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { functions } from '@/firebase';
import DocumentReadyDialog from "@/components/DocumentReadyDialog.vue";

export default Vue.extend({
	name: 'PaymentsHistoryDialog',
	props: {
		value: { type: Boolean, default: false },
	},
	data () {
		return {
			historyTypeValue: 0,
			PRIVATE_HISTORY_TYPE: 0,
			TEAM_HISTORY_TYPE: 1,

			currPage: 0,
			pageSize: 10,
			currPayments: [],
			payments: [],
			lastPaymentIntentsResponse: null,
			loadingInitialPayments: false,
			loadingMorePayments: false,

			paymentReceiptUrlToOpen: null,
			loadingReceiptUrlForPaymentId: null,
		};
	},
	computed: {
		...mapState([
			'team',
		]),
		canGoPrevPage () {
			return this.currPage > 0;
		},
		canGoNextPage () {
			return (
				(this.lastPaymentIntentsResponse && this.lastPaymentIntentsResponse.has_more) ||
				this.currPage < Math.floor(this.payments.length / this.pageSize)
			);
		},
	},
	created () {
		this.loadInitialPayments(this.value, this.historyTypeValue);
	},
	watch: {
		value (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.loadInitialPayments(newVal, this.historyTypeValue);
			}
		},
		historyTypeValue (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.loadInitialPayments(this.value, newVal);
			}
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		onClose () {
			this.$emit('on-close');
		},
		onOpenReceiptUrlClick () {
			if (this.paymentReceiptUrlToOpen) {
				window.open(this.paymentReceiptUrlToOpen, '_blank');
			}
			setTimeout(() => {
				this.paymentReceiptUrlToOpen = null;
			}, 300);
		},
		getPaymentMethodDetailsText (payment: any) {
			if (this._get(payment, 'invoice')) {
				return '(from invoice)';
			}
			if (this._get(payment, 'firebaseObj.charges.data[0].payment_method_details.card.last4')) {
				return `(payment method ending in ${payment.firebaseObj.charges.data[0].payment_method_details.card.last4})`;
			}
			return null;
		},
		// getNewTabPaymentReceiptUrl (payment: any) {
		// 	const successfulCharge: any = _find(this._get(payment, 'firebaseObj.charges.data', []), ['status', 'succeeded']);
		// 	return successfulCharge ? successfulCharge.receipt_url : null;
		// },
		async onDownloadPaymentReceipt (paymentId: string) {
			this.loadingReceiptUrlForPaymentId = paymentId;
			const url: string = await this.getStripePaymentReceiptUrl(paymentId);
			if (url) {
				this.paymentReceiptUrlToOpen = url;
			}
			this.loadingReceiptUrlForPaymentId = null;
		},
		sliceCurrPayments () {
			const startIndex: number = (this.currPage * this.pageSize);
			const endIndex: number = ((this.currPage + 1) * this.pageSize);
			this.currPayments = _slice(
				this.payments,
				startIndex,
				endIndex,
			);
		},
		onPrevPage () {
			this.currPage = this.currPage - 1;
			if (this.currPage < 0) this.currPage = 0;

			this.sliceCurrPayments();
		},
		async onNextPage () {
			this.currPage = this.currPage + 1;

			if (this.lastPaymentIntentsResponse && this.lastPaymentIntentsResponse.has_more) {
				const endIndex: number = (this.currPage * this.pageSize);
				if (endIndex >= this.payments.length) {
					await this.loadMorePayments();
				} else {
					this.sliceCurrPayments();
				}
			} else {
				this.sliceCurrPayments();
			}
		},
		async getStripePaymentReceiptUrl (paymentId: string) {
			const onError: Function = (e) => {
				console.error('Error loading Stripe payment receipt url: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading receipt',
					show: true,
				});
			};

			try {
				const functionRef = functions
					.httpsCallable('getStripePaymentReceiptUrl');
				const { url } = (await functionRef({
					paymentId,
					...(this.team && this.historyTypeValue === this.TEAM_HISTORY_TYPE && {
						teamId: this.team.id,
					}),
				})).data;
				if (!url) {
					onError(new Error('getStripePaymentReceiptUrl returned undefined url'));
				}

				return url;
			} catch (e) {
				onError(e);
				return null;
			}
		},
		async loadMoreStripePaymentIntents (nextPage: number, historyTypeValue: number) {
			const onError: Function = (e) => {
				console.error('Error loading Stripe payment intents: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading payments',
					show: true,
				});
			};

			try {
				const functionRef = functions
					.httpsCallable('getStripePaymentIntents');
				const { response } = (await functionRef({
					nextPage,
					pageSize: this.pageSize,
					...(this.team && historyTypeValue === this.TEAM_HISTORY_TYPE && {
						teamId: this.team.id,
					}),
				})).data;
				if (!response) {
					onError(new Error('getStripePaymentIntents returned undefined response'));
				}

				return {
					success: true,
					response,
				};
			} catch (e) {
				onError(e);
				return {
					success: false,
					response: null,
				};
			}
		},
		async loadInitialPayments (dialogOpen: boolean, historyTypeValue: number) {
			if (!this.loadingInitialPayments && !this.loadingMorePayments && dialogOpen) {
				this.loadingInitialPayments = true;
				this.lastPaymentIntentsResponse = null;
				const { success, response } = await this.loadMoreStripePaymentIntents(null, historyTypeValue);
				this.loadingInitialPayments = false;
				if (success) {
					this.currPage = 0;
					this.lastPaymentIntentsResponse = response;
					this.currPayments = this._get(response, 'data') || [];
					this.payments = this.currPayments;
				}
			}
		},
		async loadMorePayments () {
			if (!this.loadingInitialPayments && !this.loadingMorePayments) {
				this.loadingMorePayments = true;
				const { success, response } = await this.loadMoreStripePaymentIntents(this._get(this.lastPaymentIntentsResponse, 'next_page') || null, this.historyTypeValue);
				this.loadingMorePayments = false;
				if (success) {
					this.lastPaymentIntentsResponse = response;
					this.currPayments = this._get(response, 'data') || [];
					this.payments = this.payments.concat(this.currPayments);
					this.$scrollTo('#payments-history-dialog-scroll-anchor', 500, {
						container: '#payments-history-dialog-scroll-container',
					});
				}
			}
		},
	},
	components: {
		DocumentReadyDialog,
		LoadingSVG,
		EmptyState,
	},
});
