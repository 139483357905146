
import Vue from 'vue';
import { db } from '@/firebase';
import { get$bindFirestoreOptions } from '@/mixins';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { mapActions, mapState } from 'vuex';
import RunnitImage from '../RunnitImage.vue';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import { Avatar } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'HeroImageGallery',
	props: {
		imageWidth: { type: String, default: '82px' },
		imageHeight: { type: String, default: '82px' },
	},
	data () {
		return {
			realtimeAvatarsLoading: false,
			realtimeAvatars: [],
		};
	},
	created () {
		this.initializeDefaultAvatars();
	},
	computed: {
		...mapState([
			'user',
		]),
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		async initializeDefaultAvatars () {
			try {
				this.realtimeAvatarsLoading = true;

				// index created = avatars: deletedAt Ascending useCase Ascending userId Ascending name Ascending __name__ Ascending
				let avatarsRef = db.collection('avatars')
					.where('deletedAt', '==', null)
					.where('userId', '==', 'DEFAULT')
					.where('useCase', '==', 'RUNNIT_LIB')
					.orderBy('name', 'asc');

				if (this.realtimeAvatarsEndBeforeSnapshot) {
					avatarsRef = avatarsRef.endBefore(this.realtimeAvatarsEndBeforeSnapshot);
				}

				await this.$bind(
					'realtimeAvatars',
					avatarsRef,
					get$bindFirestoreOptions({ reset: false }),
				);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading avatars',
					show: true,
				});
			} finally {
				this.realtimeAvatarsLoading = false;
			}
		},
		handleImageClick (avatar: Avatar) {
			this.$emit('image-selected', avatar);
		}
	},
	components: {
		RunnitImage,
		LoadingSVG,
	}
});
