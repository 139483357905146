
import Vue from 'vue';

export default Vue.extend({
	name: 'ComplexBackgroundSettingMenu',
	props: {
		includeLeftBlobs: { type: Boolean, default: false },
		top: { type: Number, default: -140 },
		left: { type: Number, default: 0 },
		styles: { type: Object, default: null },
	},
	data () {
		return {};
	},
});
