import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"spinner-container"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"#666666","size":_vm.computedSpinnerSize,"width":"2"}}),(_vm.shouldShowTimerNumbers)?_c('div',{staticClass:"timer",style:({
			fontSize: `${_vm.computedFontSize}px`,
		})},[_vm._v(" "+_vm._s(_vm.formattedTime)+" ")]):_vm._e(),_c(VExpandTransition,[(_vm.showColdStartWarning)?_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"overlay-container",style:({
					height: hover ? '90%' : '55px',
				})},[_c('div',{staticClass:"overlay-title"},[_c(VIcon,{staticStyle:{"color":"var(--primary-blue)","font-size":"17px","margin":"0 4px 0 0","position":"relative","top":"-1.5px"}},[_vm._v("mdi-snowflake")]),_vm._v(" Cold Start ")],1),_c('div',{staticClass:"overlay-description"},[_vm._v(" Your workflow is starting up! Some workflows may take a brief moment to spin up. Once ready, your generations will process more quickly. ")])])]}}],null,false,3859410716)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }