
import Vue from 'vue';
import RunnitDialog from './RunnitDialog.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import ImageGalleryLoadMoreBtnsRow from '@/components/ImageGallery/ImageGalleryLoadMoreBtnsRow.vue';
import { THUMB_SIZE } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'RunnitModelsDialog',
	props: {
		elIdSuffix: { type: String, required: true }, // to prefix html element IDs in the DOM
        value: { type: Boolean, default: false },
        models: { type: Array, default: () => []},
        loading: { type: Boolean, default: false },
        loadingMore: { type: Boolean, default: false },
        showLoadMore: { type: Boolean, default: false },
        bodyHeight: { type: String, default: '80vh' },
		maxWidth: { type: [Number, String], default: '80%' },
		persistent: { type: Boolean, default: false },
	},
	data () {
		return {
            THUMB_SIZE,
			open: false,
		};
	},
	created () {
		this.open = !!this.value;
	},
	watch: {
		value (newVal: boolean) {
            this.setOpen(newVal);
		},
	},
	methods: {
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
        onScrollTop () {
			this.$scrollTo(`#models-dialog-node-${this.elIdSuffix}-0`, 800, {
				container: `#models-dialog-container-${this.elIdSuffix}`,
			});
		},
        onModelSelect (model) {
            this.$emit('on-model-select', model);
            this.setOpen(false);
        }
	},
	components: {
        RunnitDialog,
        LoadingState,
        ImageGalleryLoadMoreBtnsRow,
	},
});
