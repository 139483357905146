import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"team-management-container"},[_c(VRow,[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('stack-layers',{attrs:{"layer-one-styles":_vm.$vuetify.breakpoint.mdAndUp
						? `
							position: absolute;
							left: 15px;
							top: -30px;
						`
						: `
							position: absolute;
							left: 40px;
							top: -10px;
						`,"layer-two-styles":_vm.$vuetify.breakpoint.mdAndUp
						? `
							position: relative;
							left: -15px;
							top: 0;
						`
						: `
							position: relative;
							left: 0;
							top: 10px;
						`},scopedSlots:_vm._u([{key:"layer-1",fn:function(){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('img',{attrs:{"alt":"Background Blobs","width":_vm.$vuetify.breakpoint.smAndUp ? '250px' : '200px',"src":require("../../assets/layer2Blobs-PurpleBlue.svg")}}):_vm._e()]},proxy:true},{key:"layer-2",fn:function(){return [_c('img',{attrs:{"alt":"Manage team plan subscription","width":_vm.$vuetify.breakpoint.smAndUp ? '150px' : '125px',"src":require("./assets/invoice.png")}})]},proxy:true}])})],1)]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"d-flex justify-center flex-column full-height py-6"},[_c('div',{staticClass:"mb-3",staticStyle:{"font-size":"22px","color":"var(--grey-600)"}},[(_vm.isTeamActive)?_c('span',[_vm._v(" Manage Team Plan Subscription ")]):_c('span',[_vm._v(" Reactivate Team Plan Subscription ")])]),_c('div',{staticStyle:{"font-size":"17px"}},[(_vm.isTeamActive)?_c('span',[_vm._v(" Manage your team's subscription here. You can change your payment method or cancel your subscription at any time. ")]):(_vm.isTeamAdmin)?_c('span',[_vm._v(" Your team's subscription has been canceled. You can reactivate your subscription here. ")]):_c('span',[_vm._v(" Your team's subscription has been canceled. An admin on your team plan can reactivate your subscription here. ")])])])]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"full-height d-flex align-center justify-center",class:{
					'pb-6': _vm.$vuetify.breakpoint.smAndDown,
				}},[_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('base-button',{style:({
									padding: _vm.$vuetify.breakpoint.xlOnly ? '0 60px' : '0 35px',
								}),attrs:{"disabled":!_vm.isTeamAdmin,"call-to-action":"","large":_vm.$vuetify.breakpoint.lgAndUp},on:{"click":_vm.onManageOrReactivateClick}},[(_vm.isTeamActive)?_c('b',[_vm._v("Manage")]):_c('b',[_vm._v("Reactivate")])])],1)]}}])},[_c('div',{staticClass:"tooltip-max-width"},[(_vm.isTeamAdmin)?_c('span',[_vm._v("Go to the payment screen to activate your team's subscription")]):_c('span',[_vm._v("You are not an admin on this team plan")])])])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }