
import Vue from 'vue';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';

export default Vue.extend({
	name: 'RunnitTextField',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: String, default: null },
		adminOnly: { type: Boolean, default: false },
	},
	data () {
		return {};
	},
	components: {
		RunnitDynamicFieldLabelRow,
	},
});
