
import Vue from 'vue';
import BorderSelectCard from '@/components/BorderSelectCard.vue';
import AdminSoftwareOfferStats from '@/components/AdminStats/AdminSoftwareOfferStats.vue';
import { mapState } from 'vuex';
import { ReleaseStatus } from '@run-diffusion/shared';
import { RELEASE_STATUS } from '@/constants/enums';
import SoftwareOfferReleaseStatusChip from '@/components/SoftwareOfferReleaseStatusChip.vue';

export default Vue.extend({
	name: 'SoftwareOfferCard',
	props: {
		appOffer: { type: Object, default: null },
		softwareOffer: { type: Object, required: true },
		selected: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'user',
		]),
		descriptionHtml () {
			return (
				this.appOffer &&
				this._get(this.softwareOffer.descriptionHtmlAppOverride, this.appOffer.app)
			) || this.softwareOffer.descriptionHtml;
		},
	},
	methods: {
		getReleaseStatusDisplayHtml (releaseStatus: ReleaseStatus) {
			switch (releaseStatus) {
				case RELEASE_STATUS.CURRENT:
					return '<span style="font-size: 14px">⚓️</span>️&nbsp;️&nbsp;<b style="font-size: 16px">Current</b>';
				case RELEASE_STATUS.OLD:
					return '<span style="font-size: 14px">⏳</span>️&nbsp;️&nbsp;<b style="font-size: 16px">Old</b>';
				case RELEASE_STATUS.BETA:
					return '<span style="font-size: 14px">⚠️</span>&nbsp;️&nbsp;<b style="font-size: 16px">Beta</b>';
				default:
					return releaseStatus;
			}
		},
		getReleaseStatusColor (releaseStatus: ReleaseStatus) {
			switch (releaseStatus) {
				case RELEASE_STATUS.CURRENT:
					return 'green';
				case RELEASE_STATUS.OLD:
					return 'grey';
				case RELEASE_STATUS.BETA:
					return 'purple';
				default:
					return releaseStatus;
			}
		},
	},
	components: {
		SoftwareOfferReleaseStatusChip,
		AdminSoftwareOfferStats,
		BorderSelectCard,
	},
});
