
import Vue from 'vue';
import RunnitCarousel from './RunnitCarousel.vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default Vue.extend({
	name: 'RunnitTagFilter',
	props: {
		tags: { type: Array, default: () => ([]) },
		selectedTagsMap: { type: Object, default: () => ({}) },
		carousel: { type: Boolean, default: false },
	},
	data () {
		return {
		};
	},
	methods: {
		onTagClick (tag) {
			this.$emit('on-tag-click', tag);
		}
	},
	components: {
		RunnitCarousel,
		BaseButton,
	}
});
