import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._t("activator",null,{"open":_vm.setOpen}),_c(VDialog,{attrs:{"value":_vm.open,"max-width":_vm.maxWidth,"persistent":_vm.persistent,"overlay-opacity":"0.8"},on:{"input":_vm.setOpen}},[_c(VSheet,{staticClass:"d-flex flex-column justify-center align-center",class:{
				'pa-8': _vm.$vuetify.breakpoint.smAndUp,
				'py-2 px-4': _vm.$vuetify.breakpoint.xsOnly,
			},staticStyle:{"position":"relative"}},[_c('base-button',{staticStyle:{"position":"absolute","top":"20px","right":"30px"},attrs:{"secondary":"","icon":"","small":""},on:{"click":_vm.onCancel}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1),_c(VWindow,{attrs:{"touchless":""},model:{value:(_vm.windowView),callback:function ($$v) {_vm.windowView=$$v},expression:"windowView"}},[_c(VWindowItem,{attrs:{"value":_vm.DEFAULT_WELCOME_WINDOW_ITEM}},[_c('div',{staticClass:"d-flex flex-column align-center my-6",staticStyle:{"width":"100%","max-width":"850px","color":"var(--grey-500)"}},[_c('new-user-welcome-title'),_c('div',{staticStyle:{"color":"var(--grey-300)","max-width":"530px","text-align":"center"},style:({
								fontSize: _vm.$vuetify.breakpoint.mdAndUp ? '18px' : '16px',
							})},[_vm._v(" We have a lot of different apps to choose from! If you are new to AI art, we generally recommend that you first try "),_c('span',{staticClass:"gradient-text"},[_vm._v("Foooocus")]),_vm._v(". "),_c('br'),_c('br'),_vm._v(" It's a great way to get started making amazing images with simple prompting. ")]),_c('div',{style:({
								margin: _vm.$vuetify.breakpoint.smAndDown ? '50px 0 0 0' : '50px 0 0 0',
							})},[(_vm.isFocusNotRecommended)?_c(VAlert,{staticClass:"mb-8",staticStyle:{"max-width":"800px"},attrs:{"shaped":"","outlined":"","type":"warning"}},[_c('span',[_vm._v(" The model that you selected from Civitai is a \""+_vm._s(_vm.getModelTypeDisplayText(_vm.preloadModel.civitai.model.type))+"\" type, with \""+_vm._s(_vm.preloadModel.civitai.modelVersion.baseModel)+"\" as the base Stable Diffusion model version. "),_c('br'),_c('b',[_vm._v("Foooocus (Beginner)")]),_vm._v(" will not work with this selection, as that application works only for SDXL v1.0 models and newer. It's no problem! Just launch "),_c('b',[_vm._v("Auto1111 (Informed)")]),_vm._v(" instead. ")])]):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-center full-width"},[_c('border-select-card',{attrs:{"value":_vm.selection === _vm.SELECTION_CHOICES.NOT_NEW,"icon":"mdi-lightbulb-variant-outline","container-styles":{
												width: '100%',
												maxWidth: '325px',
											}},on:{"on-click":function($event){return _vm.onSelection(_vm.SELECTION_CHOICES.NOT_NEW)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"card-select-title"},[_vm._v(" Auto1111 (Informed) ")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"card-image-container",staticStyle:{"left":"-30px"}},[_c('img',{attrs:{"alt":"Auto1111 (Informed)","width":_vm.$vuetify.breakpoint.smAndUp ? '256px' : '200px',"src":"https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Fapp-offers%2FAUTO1111-screenshot1.png?alt=media&token=eb2a38a3-088b-4af3-9c57-f21e9f6b0bae"}})])]},proxy:true}])})],1)]),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-center full-width"},[_c('border-select-card',{attrs:{"value":_vm.selection === _vm.SELECTION_CHOICES.NEW,"icon":"mdi-new-box","container-styles":{
												width: '100%',
												maxWidth: '325px',
											}},on:{"on-click":function($event){return _vm.onSelection(_vm.SELECTION_CHOICES.NEW)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"card-select-title"},[_vm._v(" Foooocus (Beginner) ")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"card-image-container",staticStyle:{"left":"-25px"}},[_c('img',{attrs:{"alt":"Foooocus (Beginner)","width":_vm.$vuetify.breakpoint.smAndUp ? '230px' : '200px',"src":"https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Fapp-offers%2FFCUS01-08-28%20200701.png?alt=media&token=b610a5ff-cfad-44f7-9b24-79c6c035325b"}})])]},proxy:true}])})],1)])],1)],1)],1),_c('div',{staticClass:"my-7 d-flex justify-center"},[_c('base-button',{staticClass:"mr-6",attrs:{"text":""},on:{"click":_vm.onCancel}},[_vm._v(" Close ")]),_c('base-button',{staticClass:"mr-6 px-9",attrs:{"disabled":!_vm.selection},on:{"click":_vm.onSubmit}},[_vm._v(" Select ")])],1)])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }