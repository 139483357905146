import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.showPreview)?_c('div',[_vm._t("default")],2):(!_vm.small)?_c('div',{staticClass:"d-flex flex-column",style:(_vm.containerStyles)},[(_vm.messageHtml)?_c('div',{staticClass:"d-flex align-start"},[_c(VIcon,{staticClass:"model-explanation-icon"},[_vm._v(_vm._s(_vm.messageIcon))]),_c('div',{staticClass:"model-explanation mb-8",staticStyle:{"max-width":"400px"},domProps:{"innerHTML":_vm._s(_vm.messageHtml)}}),(_vm.showRemoveBtn)?_c('confirm-dialog',{attrs:{"body-text":"Are you sure you want to remove this model?","confirm-text":"Remove"},on:{"on-confirm":_vm.onRemovePreloadModel},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('base-button',_vm._g({staticClass:"ml-6",attrs:{"secondary":""}},on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Remove ")],1)]}}],null,false,860188674)}):_vm._e()],1):_vm._e(),(_vm.thumbnailUrl)?_c('img',{staticClass:"model-image mb-6",staticStyle:{"max-width":"400px","max-height":"400px"},attrs:{"src":_vm.thumbnailUrl,"alt":_vm.preloadModel.civitai.model.name}}):_vm._e(),(_vm.showModelName)?_c('div',{staticClass:"model-name"},[_vm._v(_vm._s(_vm.preloadModel.civitai.model.name))]):_vm._e()]):_c('div',{staticClass:"d-flex align-center",class:{
		'flex-row': _vm.$vuetify.breakpoint.smAndUp,
		'flex-column': _vm.$vuetify.breakpoint.xsOnly,
	},style:(_vm.containerStyles)},[(_vm.thumbnailUrl)?_c('img',{staticClass:"model-image",staticStyle:{"max-width":"150px","max-height":"150px"},attrs:{"src":_vm.thumbnailUrl,"alt":_vm.preloadModel.civitai.model.name}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column pl-6",class:{
			'mt-4': _vm.$vuetify.breakpoint.xsOnly,
		}},[(_vm.showModelName)?_c('div',{staticClass:"model-name"},[_vm._v(_vm._s(_vm.preloadModel.civitai.model.name))]):_vm._e(),(_vm.messageHtml)?_c('div',{staticClass:"d-flex align-start"},[_c(VIcon,{staticClass:"model-explanation-icon"},[_vm._v(_vm._s(_vm.messageIcon))]),_c('div',{staticClass:"model-explanation",domProps:{"innerHTML":_vm._s(_vm.messageHtml)}})],1):_vm._e()]),(_vm.hasActionSlot)?[_c(VSpacer),_vm._t("action")]:_vm._e(),(_vm.showRemoveBtn)?[_c(VSpacer),_c('confirm-dialog',{attrs:{"body-text":"Are you sure you want to remove this model?","confirm-text":"Remove"},on:{"on-confirm":_vm.onRemovePreloadModel},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('base-button',_vm._g({staticClass:"ml-6",attrs:{"secondary":""}},on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Remove ")],1)]}}],null,false,860188674)})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }