import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark: true,
		themes: {
			dark: {
				primary: '#6f86ff',
				accent: '#b372ce',
				error: '#fc7c7c',
				yellow: '#efefa2',
			},
		},
	},
});
