
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import RunnitImage from './RunnitImage.vue';
import RunnitVideo from './RunnitVideo.vue';

export default Vue.extend({
    name: 'RunnitResult',
    props: {
        // Runnit Image Props
        timerStartAtMillis: { type: Number, default: null },
        nodeRunResult: { type: Object, default: null },
        upload: { type: Object, default: null },
        avatar: { type: Object, default: null },
        noClick: { type: Boolean, default: false },
        isThumb: { type: Boolean, default: false },
        isAvatar: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
        width: { type: String, default: null },
        height: { type: String, default: null },
        isErrorPlaceholder: { type: Boolean, default: false },
        isLoadingPlaceholder: { type: Boolean, default: false },
        isInsideInfoCarousel: { type: Boolean, default: false },
        isSelectedImage: { type: Boolean, default: false },
        useZoomViewer: { type: Boolean, default: false },
        coldStartWarningSeconds: { type: Number, default: null },
    },
    data () {
        return {
        };
    },
    computed: {
        ...mapState([
        ]),
    },
    methods: {
        ...mapActions([
        ]),
        onSingleSelect (event) {
            this.$emit('on-single-select', event);
        },
        onMultiSelect (event) {
            this.$emit('on-multi-select', event);
        },
        onClick (event) {
            this.$emit('on-click');
        },
        onClear (event) {
            this.$emit('on-clear');
        }
    },
    components: {
        RunnitImage,
        RunnitVideo,
    },
});
