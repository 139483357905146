
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import { removeCivitaiModelId, removeCivitaiModelVersionId } from '@/utils';

export default Vue.extend({
	name: 'PreloadModelPreview',
	props: {
		containerStyles: { type: [String, Object], default: () => ({}) },
		messageHtml: { type: String, default: null },
		messageIcon: { type: String, default: 'mdi-information-outline' },
		small: { type: Boolean, default: false },
		showRemoveBtn: { type: Boolean, default: false },
		showModelName: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'preloadModel',
		]),
		hasActionSlot () {
			return !!this.$slots.action || !!this.$scopedSlots.action;
		},
		showPreview () {
			return !!(this.preloadModel.civitai && this.preloadModel.civitai.model && this.preloadModel.civitai.modelVersion);
		},
		thumbnailUrl () {
			if (!this.showPreview) return null;

			let result: string = null;
			(this.preloadModel.civitai.modelVersion.images || []).some(({ url, nsfw }) => {
				if (url && nsfw === 'None') result = url;
				return result;
			});
			return result;
		},
	},
	methods: {
		...mapActions([
			'updatePreloadModel',
		]),
		onRemovePreloadModel (closeDialogCallback: Function) {
			this.updatePreloadModel({ providerKey: 'civitai', result: null });
			removeCivitaiModelId();
			removeCivitaiModelVersionId();
			if (closeDialogCallback) closeDialogCallback();
		},
	},
	components: {
		ConfirmDialog,
	},
});
