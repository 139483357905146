
import Vue from 'vue';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { mapActions } from 'vuex';

export default Vue.extend({
	name: 'ClipboardCopy',
	props: {
		value: { type: String, default: '' },
		containerId: { type: Object, default: null }, // container to pass to VueClipboard
		tooltipTop: { type: Boolean, default: false },
		tooltipBottom: { type: Boolean, default: false },
		tooltipText: { type: String, default: 'Copy to clipboard' },
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		async navigatorCopyText (text: string) {
			await navigator.clipboard.writeText(text);
			this.$emit('on-copy');
			await this.updateSnackbar({
				status: SNACKBAR_STATUS.SUCCESS,
				message: `Copied to clipboard!`,
				show: true,
			});
		},
	},
});
