
import Vue from 'vue';
import DialogContent from '@/components/base/DialogContent.vue';

export default Vue.extend({
	name: 'DoubleConfirmDangerDialog',
	props: {
		open: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		maxWidth: { type: Number, default: 500 },
		disabled: { type: Boolean, default: false },
		actionDisabled: { type: Boolean, default: false },
		confirmLoading: { type: Boolean, default: false },
		hideCancel: { type: Boolean, default: false },
		title: { type: String, required: true },
		bodyText: { type: String, default: null },
		cancelText: { type: String, default: 'Cancel' },
		actionText: { type: String, default: 'Delete' },
		contentStyles: { type: [String, Object], default: null },
	},
	data () {
		return {
			localOpen: false,
			loading: false,
			doubleConfirmTitle: null,
		};
	},
	created () {
		this.localOpen = !!this.open;
	},
	watch: {
		open (newVal: boolean) {
			if (newVal !== this.localOpen) {
				this.localOpen = !!newVal;
			}
		},
	},
	methods: {
		reset () {
			this.loading = false;
			this.localOpen = false;
			this.doubleConfirmTitle = null;
		},
		onDialogInput (val: boolean) {
			this.localOpen = !!val;
			if (val !== this.open && !val) {
				this.onCancelAndClose();
			}
		},
		onCancelAndClose () {
			this.reset();
			this.$emit('on-close');
		},
		showDoubleConfirmText () {
			this.doubleConfirmTitle = 'Caution: This action may not be desired!';
		},
		onDeleteAnyway () {
			this.loading = true;
			this.$emit('on-confirm', this.reset);
		},
	},
	components: {
		DialogContent,
	},
});
