
import Vue from 'vue';
import _get from 'lodash/get';
import { db } from '@/firebase';
import { RunnitNode } from '@run-diffusion/shared';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import RunnitDialog from '@/views/Runnits/base/RunnitDialog.vue';

export default Vue.extend({
	name: 'RunnitNodeSettingsDialog',
	props: {
		value: { type: Boolean, default: false },
		runnitNode: { type: Object, required: true },
	},
	data () {
		return {
			open: false,

			// Form
			title: null,
			description: null,

			formValid: false,
			rules: {
				required: [
					v => !!v || 'Required field',
				],
			},

			savingRunnitNodeSettings: false,
		};
	},
	created () {
		this.open = !!this.value;
	},
	watch: {
		value (newVal: boolean) {
			this.open = !!newVal;
		},
		runnitNode: {
			immediate: true,
			async handler (newVal: RunnitNode, oldVal: RunnitNode) {
				if (newVal && newVal.id) {
					this.title = newVal.title;
					this.description = newVal.description;
				}
			},
		},
	},
	computed: {
		...mapState([
			'runnitsMap',
			'runnitState',
		]),
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.open = false;
			this.$emit('input', this.open);
		},
		async onSaveRunnitNodeSettings () {
			try {
				this.savingRunnitNodeSettings = true;
				const runnitNodeRef = db.doc(`runnits/${this.runnitNode.runnitId}/runnitNodes/${_get(this.runnitNode, 'id')}`);
				await runnitNodeRef
					.update({
						title: this.title,
						description: this.description,
					});
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! ${this.runnitNode.title} Settings saved`,
					show: true,
				});
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error! Problem saving the Tool Settings, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			} finally {
				this.savingRunnitNodeSettings = false;
				this.onCancel();
			}
		},
	},
	components: {
		RunnitDialog,
	},
});
