
import Vue from 'vue';
import SessionSoundsSettings from '@/components/SessionSoundsSettings.vue';

export default Vue.extend({
	name: 'SettingsWindowItem',
	props: {
		windowItemValue: { type: Number, required: true },
	},
	methods: {
		onBack () {
			this.$emit('on-settings-back');
		},
	},
	components: {
		SessionSoundsSettings,
	},
});
