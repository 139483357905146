
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import { CHECKOUT_ADD_FUNDS, CHECKOUT_ADD_FUNDS_TEAM } from '@run-diffusion/shared';
import { MyBalanceMixin, TeamUserMixin } from '@/mixins';
import PrivateBalancePricingTableDialog from '@/components/PrivateBalancePricingTableDialog.vue';

export default Vue.extend({
	name: 'AddBalanceFundsButton',
	mixins: [
		MyBalanceMixin,
		TeamUserMixin,
	],
	props: {
		type: {
			type: String,
			required: true,
			validator: (val: string) => ['private', 'team'].includes(val),
		},
	},
	data () {
		return {
			balanceTooHighDialogOpen: false,
			privateBalancePricingTableDialogOpen: false,

		};
	},
	computed: {
		...mapState([
			'authUser',
		]),
		isPrivateBtn () {
			return this.type === 'private';
		},
		isTeamBtn () {
			return this.type === 'team';
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'updateLoader',
		]),
		async onAddFundsClick () {
			if (!this.type) {
				return;
			}
			if (this.isPrivateBtn && this.computedUserBalance > 10000) {
				this.balanceTooHighDialogOpen = true;
				return;
			}
			if (this.isTeamBtn && this.computedUserBalance > 100000) {
				this.balanceTooHighDialogOpen = true;
				return;
			}
			// if (this.isPrivateBtn) {
			// 	this.privateBalancePricingTableDialogOpen = true;
			// 	return;
			// }
			await this.doAddFunds();
		},
		async doAddFunds () {
			const routeData = this.$router.resolve({
				name: 'GoToPayment',
				query: {
					checkoutAction: this.isTeamBtn ? CHECKOUT_ADD_FUNDS_TEAM : CHECKOUT_ADD_FUNDS,
				},
			});
			window.open(routeData.href, '_blank');
			this.$emit('on-add-funds-click');
		},
	},
	components: {
		PrivateBalancePricingTableDialog,
		ConfirmDialog,
	},
});
