import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('dialog-or-bottom-sheet',{attrs:{"fullscreen":"","max-width":"calc(100vw - 20px)","min-width":"calc(100vw - 20px)","max-height":"calc(100vh - 70px)","min-height":"calc(100vh - 70px)","nudge-bottom":70,"nudge-left":1190,"scrollable":_vm.$vuetify.breakpoint.smAndUp,"value":_vm.runnitState.inpaintingEditorOpen,"overlay-opacity":"0.8"},on:{"input":_vm.onDialogOpenStateChange},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [_vm._t("trigger",null,{"on":on})]}},{key:"content",fn:function(){return [_c(VCard,{staticClass:"inpainting-editor"},[_c(VCardTitle,{staticClass:"dialog-nav-section"},[_c('complex-background',{attrs:{"include-left-blobs":""}}),_c('div',{staticClass:"dialog-nav-section-title"},[_c(VIcon,{staticStyle:{"font-size":"30px","margin":"0 10px 0 0"}},[_vm._v("mdi-draw")]),_c('span',[_vm._v("Inpainting Editor")])],1),_c('base-button',{staticStyle:{"margin":"0 -5px 0 0"},attrs:{"icon":"","text":"","large":""},on:{"click":_vm.onClose}},[_c(VIcon,{staticStyle:{"color":"var(--blue-grey-200)"}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"inpainting-editor-content"},[_c('div',{staticClass:"inpainting-editor-canvas",style:({
							margin: _vm.$vuetify.breakpoint.smAndDown ? '15px' : '30px 30px 30px 30px',
						})},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"100%","height":"100%","position":"absolute","top":"0","left":"0"}},[_c('img',{staticStyle:{"border-radius":"8px"},attrs:{"width":_vm.canvasWidth,"height":_vm.canvasHeight,"src":_vm.runnitState.inpaintingBackgroundImageUrl}})]),_c('canvas',{staticStyle:{"border-radius":"8px","position":"absolute","top":"0","left":"0","border":"2px solid var(--dark-purple-900)"},style:({
								width: _vm.canvasWidth,
								height: _vm.canvasHeight,
							}),attrs:{"id":"brush-canvas"}})]),_c('div',{staticClass:"inpainting-editor-actions",style:({
							width: _vm.$vuetify.breakpoint.xsOnly ? '100%' :
								_vm.$vuetify.breakpoint.lgAndUp ? '700px' : '500px',
							bottom: _vm.$vuetify.breakpoint.xsOnly ? '0' : '30px',
							left: _vm.$vuetify.breakpoint.xsOnly ? '-12px' :
								_vm.$vuetify.breakpoint.lgAndUp ? 'calc(50% - 362px)' : 'calc(50% - 262px)'
						})},[_c('div',{staticClass:"inpainting-editor-actions-top"},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"flex":"3"}},[_c('base-slider',{staticStyle:{"min-width":"200px","margin":"0 5px 0 10px"},attrs:{"condensed-incrementer":"","max":80,"min":1,"hide-details":"","value-class":"brush-size","incrementer-padding":"0","number-min-width":"min-content"},model:{value:(_vm.brushSize),callback:function ($$v) {_vm.brushSize=$$v},expression:"brushSize"}})],1),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"flex":"1"}},[_c('glass-button',{staticClass:"mr-2",attrs:{"small":""},on:{"click":_vm.onStepBack}},[_c(VIcon,{staticStyle:{"margin":"0 0 0 1px","font-size":"16px"}},[_vm._v(" mdi-arrow-left-top-bold ")])],1),_c('glass-button',{staticClass:"mr-2",attrs:{"small":""},on:{"click":_vm.onDeleteInpaintingMask}},[_c(VIcon,{staticStyle:{"margin":"0 0 0 1px","font-size":"16px"}},[_vm._v(" mdi-trash-can ")])],1)],1)]),_c('div',{staticClass:"inpainting-editor-actions-bottom"},[_c('base-button',{staticStyle:{"min-width":"90px"},attrs:{"secondary":"","small":"","disabled":_vm.runnitState.inpaintingEditorSaving,"loading":_vm.runnitState.inpaintingEditorSaving},on:{"click":_vm.onClose}},[_vm._v(" Cancel ")]),_c('base-button',{staticStyle:{"min-width":"90px"},attrs:{"super-primary":"","small":"","disabled":_vm.runnitState.inpaintingEditorSaving,"loading":_vm.runnitState.inpaintingEditorSaving},on:{"click":_vm.handleDone}},[_vm._v(" Submit ")])],1)])])],1)]},proxy:true}],null,true)}),_c('img',{ref:"imageRef",staticStyle:{"visibility":"hidden"},attrs:{"src":_vm.runnitState.inpaintingBackgroundImageUrl},on:{"load":_vm.imageLoaded}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }