
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { getHardwareHourlyPrice } from '@run-diffusion/shared';
import CircleIcon from '@/components/base/CircleIcon.vue';
import { SELECTED_STORAGE_TYPE } from '@/constants/constants';
import { PRIVATE_STORAGE_CAP_BYTES, SHARED_STORAGE_CAP_BYTES } from '@/components/StorageCapCard/utils';

export default Vue.extend({
	name: 'FullSelectionPreviewCard',
	props: {
		disabled: { type: Boolean, default: false },
		appOffer: { type: Object, default: null },
		hardwareOffer: { type: Object, default: null },
		softwareOffer: { type: Object, default: null },
		selectedStorageType: {
			type: String,
			validator: (val: string) => [SELECTED_STORAGE_TYPE.PRIVATE, SELECTED_STORAGE_TYPE.TEAM].includes(val),
			required: true,
		},
		workspaceId: { type: String, default: null },
		hideSessionType: { type: Boolean, default: null },
	},
	data () {
		return {
			SELECTED_STORAGE_TYPE,
			SHARED_STORAGE_CAP_BYTES,
			PRIVATE_STORAGE_CAP_BYTES,
		};
	},
	computed: {
		...mapState([
			'team',
			'user',
			'workspacesMap',
		]),
		...mapGetters([
			'clubOfferBenefits',
		]),
		selectedStorageTypeHtml () {
			if (this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM) {
				return `<div>Team Session - <span class="gradient-text" style="font-family: var(--nasa)">${this.team.name}</span></div>`;
			}
			return `<div>Personal Session${this.clubOfferBenefits.isCc ? ` - <span class="gradient-text">Creator's Club</span>` : ''}</div>`;
		},
	},
	methods: {
		getHardwareHourlyPrice,
	},
	components: {
		CircleIcon,
	},
});
