
import Vue from 'vue';
import { ReleaseStatus } from '@run-diffusion/shared';
import { RELEASE_STATUS } from '@/constants/enums';

export default Vue.extend({
	name: 'SoftwareOfferReleaseStatusChip',
	props: {
		softwareOffer: { type: Object, required: true },
	},
	methods: {
		getReleaseStatusDisplayHtml (releaseStatus: ReleaseStatus) {
			switch (releaseStatus) {
				case RELEASE_STATUS.CURRENT:
					return '<span style="font-size: 14px">⚓️</span>️&nbsp;️&nbsp;<b style="font-size: 16px">Current</b>';
				case RELEASE_STATUS.OLD:
					return '<span style="font-size: 14px">⏳</span>️&nbsp;️&nbsp;<b style="font-size: 16px">Old</b>';
				case RELEASE_STATUS.BETA:
					return '<span style="font-size: 14px">⚠️</span>&nbsp;️&nbsp;<b style="font-size: 16px">Beta</b>';
				default:
					return releaseStatus;
			}
		},
		getReleaseStatusColor (releaseStatus: ReleaseStatus) {
			switch (releaseStatus) {
				case RELEASE_STATUS.CURRENT:
					return 'green';
				case RELEASE_STATUS.OLD:
					return 'grey';
				case RELEASE_STATUS.BETA:
					return 'purple';
				default:
					return releaseStatus;
			}
		},
	},
});
