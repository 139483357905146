
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { AppOffer, HardwareOffer, SoftwareOffer, Workshop, WorkshopSession } from '@run-diffusion/shared';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import BackButton from '@/components/base/BackButton.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import WorkshopSessionCard from '@/views/Workshops/WorkshopPage/WorkshopSessionCard.vue';
import AddEditWorkshopSession from '@/views/Workshops/WorkshopPage/AddEditWorkshopSession/AddEditWorkshopSession.vue';
import ViewWorkshopSessionQueueItems from '@/views/Workshops/WorkshopPage/ViewWorkshopSessionQueueItems.vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'WorkshopPage',
	data () {
		return {
			addEditWorkshopSessionConfig: {
				open: false,
				editMode: false,
				workshopSession: null,
			},
			viewWorkshopSessionQueueItemsConfig: {
				open: false,
				workshopSession: null,
			},
			hasLiveSessionMap: {},
		};
	},
	computed: {
		...mapState([
			'workshopsMap',
			'loadingWorkshopSessions',
			'workshopSessions',
			'appOffersMap',
			'hardwareOffersMap',
			'softwareOffersMap',
		]),
		workshop () {
			return this.workshopsMap[this.$route.params.workshopId] || null;
		},
		viewWorkshopSessionQueueItemsHasSettingsIssue () {
			const { workshopSession } = this.viewWorkshopSessionQueueItemsConfig;
			const appOffer: AppOffer = (workshopSession && this.appOffersMap[workshopSession.apps[0]]) || null;
			const softwareOffer: SoftwareOffer = (workshopSession && this.softwareOffersMap[workshopSession.software]) || null;
			const hardwareOffer: HardwareOffer = (workshopSession && this.hardwareOffersMap[workshopSession.hardware]) || null;
			return !!(
				!appOffer ||
				!softwareOffer ||
				!hardwareOffer
			);
		},
	},
	watch: {
		workshop: {
			immediate: true,
			handler (newVal: Workshop, oldVal: Workshop) {
				const newWorkshopId: string = this._get(newVal, 'id') || null;
				const oldWorkshopId: string = this._get(oldVal, 'id') || null;
				if (newWorkshopId !== oldWorkshopId) {
					if (newWorkshopId) {
						this.bindWorkshopSessions(newWorkshopId);
					} else {
						this.unbindWorkshopSessions();
					}
				}
			},
		},
	},
	methods: {
		...mapActions([
			'bindWorkshopSessions',
			'unbindWorkshopSessions',
		]),
		onHasLiveSession (workshopSession: WorkshopSession, hasLiveSession: boolean) {
			this.hasLiveSessionMap = {
				...this.hasLiveSessionMap,
				[workshopSession.id]: hasLiveSession,
			};
		},
		openAddWorkshopSessionDrawer () {
			this.addEditWorkshopSessionConfig = {
				...this.addEditWorkshopSessionConfig,
				open: true,
				editMode: false,
				workshopSession: null,
			};
		},
		onAddEditInput (open: boolean) {
			this.addEditWorkshopSessionConfig = {
				...this.addEditWorkshopSessionConfig,
				open: !!open,
			};
		},
		onViewWorkshopSessionQueueItemsOpen (workshopSession: WorkshopSession) {
			this.viewWorkshopSessionQueueItemsConfig = {
				open: true,
				workshopSession,
			};
		},
		onEditWorkshopSession (workshopSession: WorkshopSession) {
			this.addEditWorkshopSessionConfig = {
				...this.addEditWorkshopSessionConfig,
				open: true,
				editMode: true,
				workshopSession: workshopSession || null,
			};
		},
		goToTeamWorkshopsPage () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.WORKSHOPS });
		},
	},
	components: {
		ViewWorkshopSessionQueueItems,
		EmptyState,
		LoadingSVG,
		BackButton,
		AddEditWorkshopSession,
		WorkshopSessionCard,
	},
});
