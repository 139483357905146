
import Vue from 'vue';
import SessionTimer from '@/components/Toolbar/SessionTimer/SessionTimer.vue';
import SessionTimerBtns from '@/components/Toolbar/SessionTimer/SessionTimerBtns.vue';
import {
	BOOTING_SESSION_STATES,
	PAST_SESSION_STATES,
	READY_SESSION_STATES,
	STOPPING_SESSION_STATES,
} from '@/constants/constants';
import { SESSION_STATE } from '@/constants/enums';

export default Vue.extend({
	name: 'MultipleActiveSessionTimerWrapper',
	props: {
		waitForStoppingSession: { type: Boolean, default: true },
		hideToggleShowCounter: { type: Boolean, default: false },
		hideAdminStats: { type: Boolean, default: false },
		session: { type: Object, required: true },
	},
	data () {
		return {
			SESSION_STATE,
			BOOTING_SESSION_STATES,
			STOPPING_SESSION_STATES,
			READY_SESSION_STATES,
			PAST_SESSION_STATES,
		};
	},
	components: {
		SessionTimerBtns,
		SessionTimer,
	},
});
