
import Vue from 'vue';
import _truncate from 'lodash/truncate';
import { TeamUserMixin } from '@/mixins';
import AdminWorkspaceStats from '@/components/AdminStats/AdminWorkspaceStats.vue';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'WorkspaceCard',
	mixins: [
		TeamUserMixin,
	],
	props: {
		workspace: { type: Object, default: null },
		selected: { type: Boolean, default: false },
		selectionMode: { type: Boolean, default: false },
	},
	methods: {
		_truncate,
		handleClick () {
			if (this.selectionMode) {
				if (this.workspace) {
					this.$emit('on-select', this.workspace);
				} else {
					this.$emit('on-select', null);
				}
			}
		},
	},
	computed: {
		...mapState([
			'isAdminProxyingUser',
			'user',
		]),
	},
	components: {
		AdminWorkspaceStats,
	}
});
