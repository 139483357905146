
import Vue from 'vue';
import _toNumber from 'lodash/toNumber';
import _toString from 'lodash/toString';
import _isNil from 'lodash/isNil';
import _trim from 'lodash/trim';
import { isValidNumber } from '@run-diffusion/shared';

const MAX_NUM_STR: string = '9999999999999999999999999';
export default Vue.extend({
	name: 'NumberField',
	props: {
		inputType: { type: String, default: 'number' },
		rules: { type: Array, default: () => [] },
		required: { type: Boolean, default: false },
		allowZero: { type: Boolean, default: false },
		value: { type: Number, default: null },
		defaultValue: { type: Number, default: null },
		maxlength: {
			type: Number,
			validator: (val: number) => val <= MAX_NUM_STR.length,
			default: 6,
		},
		allowDecimal: { type: Boolean, default: false },
	},
	data () {
		return {
			MAX_NUM_STR,
			localStrVal: null,
		};
	},
	computed: {
		computedRules () {
			return [
				v => !this.required || (_toNumber(v) === 0 && this.allowZero) || !!_toNumber(v) || 'Number is required',
				...this.rules,
			];
		},
	},
	watch: {
		value: {
			immediate: true,
			handler (newVal: number) {
				this.localStrVal = _isNil(newVal) ? null : _toString(newVal);
			},
		},
	},
	methods: {
		isValidNumber,
		onKeyPress (ev) {
			const { key } = ev;
			if (['-', ',', 'e'].includes(key)) {
				// No negative numbers or commas or e
				ev.preventDefault();
			}
			if (!this.allowDecimal && key === '.') {
				// No decimals
				ev.preventDefault();
			}
		},
		onInput (strVal: string) {
			const prevVal: string = this.localStrVal;
			this.localStrVal = strVal;

			const isTooLong: boolean = _trim(this.localStrVal).length > this.maxlength;
			const validNum: boolean = isValidNumber(this.localStrVal);
			if (!this.localStrVal || (!isTooLong && validNum)) {
				try {
					const convertedVal: number = this.convertAmountEmitted(strVal);
					this.$emit('input', convertedVal);
				} catch (e) {
					this.$emit('input', this.defaultValue);
				}
			} else {
				// undo the input
				this.$nextTick(() => {
					this.localStrVal = prevVal;
				});
			}
		},
		convertAmountEmitted (val: string | number): number {
			if (val === 0) return 0;
			let result: number = !_isNil(val) && (val as string).length ? _toNumber(val) : null;
			if (result) {
				result = Math.abs(result);
				if (!this.allowDecimal) {
					result = Math.floor(result);
				}
			}
			return result;
		},
		onBlur (ev) {
			if (!isValidNumber(ev.target.value)) {
				this.$emit('input', this.defaultValue);
			}
		},
	},
});
