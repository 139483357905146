
import Vue from 'vue';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import AdminUserStatsCard from '@/components/AdminStats/AdminUserStats/AdminUserStatsCard.vue';

export default Vue.extend({
	name: 'AdminUserStats',
	props: {
		user: { type: Object, required: true },
		hasActivator: { type: Boolean, default: false },
	},
	components: {
		AdminUserStatsCard,
		MenuOrBottomSheet,
	},
});
