
import Vue from 'vue';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import ComplexBackgroundSettingMenu from '@/components/designElements/ComplexBackgroundSettingMenus.vue';

export default Vue.extend({
	name: 'RunnitMenuOrBottomSheet',
	props: {
		value: { type: Boolean, default: false },
		title: { type: String, default: null },
		icon: { type: String, default: null },
		maxWidth: { type: [String, Number], default: 320 },
		minWidth: { type: [String, Number], default: 300 },
		maxHeight: { type: [String, Number], default: 600 },
		minHeight: { type: [String, Number], default: 600 },
		nudgeBottom: { type: Number, default: 0 },
		nudgeLeft: { type: Number, default: 0 },
		bottomSheetOnSmall: { type: Boolean, default: false },
		bottomSheetOnMedium: { type: Boolean, default: false },
		isBottomSheet: { type: Boolean, default: false },
	},
	data () {
		return {};
	},
	computed: {
		menuBreakPoint () {
			let breakPoint = this.$vuetify.breakpoint.smAndUp;

			if (this.bottomSheetOnSmall && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.mdAndUp;
			}

			if (this.bottomSheetOnMedium && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.lgAndUp;
			}

			if (this.isBottomSheet) {
				breakPoint = null;
			}

			return breakPoint;
		},
		bottomSheetBreakPoint () {
			let breakPoint = this.$vuetify.breakpoint.xsOnly;

			if (this.bottomSheetOnSmall && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.smAndDown;
			}

			if (this.bottomSheetOnMedium && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.mdAndDown;
			}

			if (this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.smAndUp;
			}

			return breakPoint;
		},
	},
	methods: {
		onClose () {
			this.$emit('on-close');
		},
	},
	components: {
		MenuOrBottomSheet,
		ComplexBackgroundSettingMenu,
	},
});
