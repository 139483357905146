import {
	get as _get,
	isArray as _isArray,
	isNil as _isNil,
} from 'lodash';

export const initiateFileDownload: Function = (dataContent: string = '', downloadedFileName: string = '', dataType: string = 'text/csv'): void => {
	if (dataContent && downloadedFileName) {
		const fileBlob = new Blob([dataContent], { type: dataType + ';charset=utf-8;' });
		if ((navigator as any).msSaveBlob) {
			// This works for IE 10+
			(navigator as any).msSaveBlob(fileBlob, downloadedFileName);
		} else {
			const downloadLink: HTMLAnchorElement = document.createElement('a');
			if (downloadLink.download !== undefined) {
				// Checks - feature detection (Browsers support HTML5 download property)
				const blobUrl: string = URL.createObjectURL(fileBlob);
				downloadLink.setAttribute('href', blobUrl);
				downloadLink.setAttribute('download', downloadedFileName);
				downloadLink.style.visibility = 'hidden';
				document.body.appendChild(downloadLink);
				downloadLink.click();
				document.body.removeChild(downloadLink);
			}
		}
	}
};

export const downloadCsvFromArray: Function = (downloadedFileName: string, entries: any[]): void => {
	if (!entries || !entries.length) {
		return;
	}

	const cleanInput: Function = (v: any) => {
		let cleaned: string = '';
		if (!_isNil(v)) {
			cleaned = `${v}`
				.replace(/"/gm, '\'')
				.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm, ' ');
			if (cleaned.includes(',')) {
				cleaned = `"${cleaned}"`;
			}
		}
		return cleaned;
	};

	let columnNames: any[] = [];
	const columnsIndex: Record<string, any> = {};

	// Gather column names
	entries.forEach((entry: any) => {
		const currentEntryColumns = Object.keys(entry);
		currentEntryColumns.forEach(colName => {
			columnsIndex[colName] = true;
		});
	});
	columnNames = Object.keys(columnsIndex);
	const columnNamesRow = columnNames.map(colName => cleanInput(colName)).join(',');

	const contentRows = [columnNamesRow];
	entries.forEach((entry) => {
		let arrayValuesIteration = true;
		let currentArrayIndex = 0;

		while (arrayValuesIteration) {
			let hasNextArrayValue = false;
			const currentContentRow = columnNames.map(
				(column) => {
					const item = entry[column];
					if (_isArray(item)) {
						if (item.length > currentArrayIndex + 1) {
							hasNextArrayValue = true;
						}
						return cleanInput(_get(item, `[${currentArrayIndex}]`));
					} else if (currentArrayIndex === 0) {
						return cleanInput(item);
					} else {
						return '';
					}
				},
			).join(',');
			contentRows.push(currentContentRow);

			arrayValuesIteration = hasNextArrayValue;
			currentArrayIndex++;
		}
	});

	const csvOutput: string = contentRows.join('\r\n');
	initiateFileDownload(csvOutput, downloadedFileName, 'text/csv');
};
