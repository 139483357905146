import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"value":_vm.value,"max-width":"550","scrollable":""},on:{"input":_vm.onInput}},[_c('dialog-content',{attrs:{"title":"Add Funds to Your Balance (US Dollars)","content-styles":"max-height: 65vh","content-wrapper-class":"px-1 pt-6 d-flex"},on:{"on-cancel":function($event){return _vm.onInput(false)}}},[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"80px"},attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-family":"var(--nasa)"},style:({
						fontSize: _vm.$vuetify.breakpoint.xsOnly ? '38px' : '50px',
					})},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm._f("centsToDollars")(_vm.itemsMap[_vm.selectedPriceId].amt)))+" ")]),_c('base-button',{staticClass:"ml-2",style:({
						top: _vm.$vuetify.breakpoint.xsOnly ? '5px' : '9px',
					}),attrs:{"small":"","text":""},on:{"click":function($event){_vm.priceChipsVisible = !_vm.priceChipsVisible}}},[(_vm.priceChipsVisible)?_c('b',{staticStyle:{"font-size":"11px","color":"var(--grey-400)"}},[_vm._v("Close")]):_c('b',{staticStyle:{"font-size":"11px","color":"var(--primary-blue)"}},[_vm._v("Change")]),(_vm.priceChipsVisible)?_c(VIcon,{staticStyle:{"color":"var(--grey-400)"},attrs:{"right":""}},[_vm._v("mdi-chevron-up")]):_vm._e()],1)],1),_c(VExpandTransition,[(_vm.priceChipsVisible)?[(0)?_c(VCol,{staticClass:"mt-3 d-flex justify-center",attrs:{"cols":"12"}},[_c(VIcon,{staticStyle:{"color":"var(--grey-500)"},attrs:{"small":"","left":""}},[_vm._v("mdi-information-outline")]),_c('span',{staticStyle:{"color":"var(--grey-500)"}},[_vm._v("If you have a coupon code, make sure to select at least \""+_vm._s(_vm._f("currency")(_vm._f("centsToDollars")(_vm.itemsMap[_vm.minCouponPriceId].amt)))+"\"")])],1):_vm._e(),_c(VCol,{staticClass:"mt-8 d-flex justify-center flex-wrap",attrs:{"cols":"12"}},_vm._l((_vm.items),function(item){return _c(VChip,{key:item.priceId,staticClass:"mx-3 my-2 d-flex justify-center",staticStyle:{"min-width":"80px"},attrs:{"outlined":"","color":_vm.selectedPriceId === item.priceId ? 'primary' : 'var(--grey-400)'},on:{"click":function($event){return _vm.onSelectPriceId(item.priceId)}}},[_c('b',[_vm._v(_vm._s(_vm._f("currency")(_vm._f("centsToDollars")(item.amt))))])])}),1)]:_vm._e()],2),_c(VCol,{staticStyle:{"margin-top":"120px"},attrs:{"cols":"12"}})],1),_c('actions-island',{style:({
				position: 'absolute',
				transform: 'translateX(-50%)',
				left: '50%',
			}),attrs:{"width":_vm.$vuetify.breakpoint.xsOnly ? '80%' : '96%',"height":"70px","bottom":"20px"}},[_c('div',{staticClass:"full-width full-height d-flex justify-center align-center"},[_c('base-button',{attrs:{"large":""},on:{"click":_vm.onGoToPaymentClick}},[_vm._v(" Go to Payment ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }