import Vue from 'vue';
import {
	SHOW_TEXT_FIELD_COUNTER_AT,
	TEXT_FIELD_MAX_LENGTH,
	TEXT_FIELD_RULE,
} from '@/constants/constants';
import _trim from 'lodash/trim';
import _isNil from 'lodash/isNil';

export const RunnitDynamicFieldMixin = Vue.extend({
	props: {
		field: { type: Object, required: true },
	},
	data () {
		return {
			SHOW_TEXT_FIELD_COUNTER_AT,
			TEXT_FIELD_MAX_LENGTH,
			rules: {
				requiredTextField: [
					TEXT_FIELD_RULE,
					v => !!_trim(v) || 'Required',
				],
				optionalTextField: [
					TEXT_FIELD_RULE,
				],
				requiredNumberField: [
					v => !_isNil(v) || 'Required',
				],
				optionalNumberField: [],
				requiredSelectField: [
					v => !_isNil(v) || 'Required',
				],
				optionalSelectField: [],
				requiredRadioGroup: [
					v => !_isNil(v) || 'Required',
				],
				optionalRadioGroup: [],
				requiredFileInput: [
					v => !!v || 'File selection required ',
				],
				optionalFileInput: [],
				requiredInpaintingInput: [
					v => !!v || 'Mask is required',
				],
			},
		};
	},
});
