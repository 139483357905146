
import Vue from 'vue';
import { mapState } from 'vuex';
import { ClubOffer, User } from '@run-diffusion/shared';
import BaseToggleButton from '@/components/base/BaseToggleButton.vue';
import { getPercentOffFigure } from '@/views/Runnits/pages/RunnitsSubscriptionsPage/utils';

export default Vue.extend({
	name: 'IsMonthlySwitch',
	props: {
		value: { type: Boolean, required: true },
		visibleClubOffers: { type: Array, default: () => [] },
	},
	computed: {
		...mapState([
			'user',
		]),
		percentOffText () {
			let minPercentOff: number = null;
			let maxPercentOff: number = null;
			(this.visibleClubOffers || []).forEach((clubOffer: ClubOffer) => {
				if (!clubOffer.stripe) return;
				const curr: number = getPercentOffFigure(clubOffer.stripe.monthlyPriceAmount, clubOffer.stripe.yearlyPriceAmount);
				if (!minPercentOff || curr < minPercentOff) minPercentOff = curr;
				if (!maxPercentOff || curr > maxPercentOff) maxPercentOff = curr;
			});
			if (maxPercentOff && maxPercentOff !== minPercentOff) {
				return `(up to ${maxPercentOff}% off)`;
			}
			if (maxPercentOff && maxPercentOff === minPercentOff) {
				return `(${maxPercentOff}% off)`;
			}
			return null;
		},
	},
	watch: {
		user: {
			immediate: true,
			handler (newVal: User, oldVal: User) {
				/*
				Init current switch value
				 */
				const newIsAnnual: boolean = !!this._get(newVal, 'clubInfo.isAnnual');
				const oldIsAnnual: boolean = !!this._get(oldVal, 'clubInfo.isAnnual');
				const newClubOffer: ClubOffer = this._get(newVal, 'clubOffer') || null;
				const oldClubOffer: ClubOffer = this._get(oldVal, 'clubOffer') || null;
				const newClubOfferDefined: boolean = !!(newClubOffer && typeof newClubOffer !== 'string');
				const oldClubOfferDefined: boolean = !!(oldClubOffer && typeof oldClubOffer !== 'string');
				if (
					newClubOfferDefined &&
					(
						newClubOfferDefined !== oldClubOfferDefined ||
						newIsAnnual !== oldIsAnnual
					) &&
					!newClubOffer.isFreemium &&
					newIsAnnual !== !this.value
				) {
					this.$nextTick(() => {
						this.onInput(!newIsAnnual);
					});
				}
			},
		},
	},
	methods: {
		onInput (val: boolean) {
			this.$emit('input', !!val);
		},
	},
	components: {
		BaseToggleButton,
	},
});
