<template>
	<div
		:class="{
			'full-width d-flex justify-center': makeTriggerContentCentered,
		}"
	>
		<template v-if="menuBreakPoint">
			<v-dialog
				:content-class="dialogAlwaysFullHeight ? 'dialog-or-bottom-sheet-dialog-content-full-height' : 'dialog-or-bottom-sheet-dialog-content'"
				:overlay-opacity="overlayOpacity"
				:max-width="maxWidth"
				:eager="eager"
				:disabled="disabled"
				:transition="transition"
				:value="computedIsOpen"
				:open-on-focus="openOnFocus"
				:close-on-click="closeOnClick"
				:close-delay="closeDelay"
				:scrollable="scrollable"
				:fullscreen="fullscreen"
				@input="setValue"
			>
				<template v-slot:activator="{ on }">
					<slot name="trigger" v-on="on" :on="on"></slot>
				</template>
				<slot style="height: 100vh" name="content" v-bind="{ close, open, isOpen: computedIsOpen, isOpenButOverrideHidden }"></slot>
			</v-dialog>
		</template>
		<template v-if="bottomSheetBreakPoint">
			<v-bottom-sheet
				:eager="eager"
				:disabled="disabled"
				:value="computedIsOpen"
				:scrollable="scrollable"
				:persistent="bottomSheetPersistent"
				:content-class="dialogAlwaysFullHeight ? 'dialog-or-bottom-sheet-bottom-sheet-content-full-height' : 'dialog-or-bottom-sheet-bottom-sheet-content'"
				@input="setValue"
			>
				<template v-slot:activator="{ on }">
					<slot name="trigger" v-on="on" :on="on"></slot>
				</template>
				<slot style="height: 100vh" name="content" v-bind="{ close, open, isOpen: computedIsOpen, isOpenButOverrideHidden }"></slot>
			</v-bottom-sheet>
		</template>
	</div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'DialogOrBottomSheet',
	props: {
		// shared props
		value: { type: Boolean, default: false },
		overrideHidden: { type: Boolean, default: false },
		eager: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		makeTriggerContentCentered: { type: Boolean, default: false },
		scrollable: { type: Boolean, default: false },
		// Dialog props
		dialogAlwaysFullHeight: { type: Boolean, default: false },
		transition: { type: String, default: 'fade-transition' },
		closeOnContentClick: { type: Boolean, default: false },
		closeOnClick: { type: Boolean, default: true },
		openOnFocus: { type: Boolean, default: false },
		closeDelay: { type: Number, default: 0 },
		overlayOpacity: { default: () => null },
		maxWidth: { default: () => null },
		fullscreen: { type: Boolean, default: false },
		// bottom sheet props
		// scrollable: { type: Boolean, default: false },
		bottomSheetPersistent: { type: Boolean, default: false },
		bottomSheetOnSmall: { type: Boolean, default: false },
		bottomSheetOnMedium: { type: Boolean, default: false },
		isBottomSheet: { type: Boolean, default: false },
	},
	data() {
		return {
			isOpen: this.value,
		};
	},
	watch: {
		value(val) {
			this.setValue(val);
		},
	},
	computed: {
		menuBreakPoint() {
			let breakPoint = this.$vuetify.breakpoint.smAndUp;

			if (this.bottomSheetOnSmall && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.mdAndUp;
			}

			if (this.bottomSheetOnMedium && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.lgAndUp;
			}

			if (this.isBottomSheet) {
				breakPoint = null;
			}

			return breakPoint;
		},
		bottomSheetBreakPoint() {
			let breakPoint = this.$vuetify.breakpoint.xsOnly;

			if (this.bottomSheetOnSmall && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.smAndDown;
			}

			if (this.bottomSheetOnMedium && !this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.mdAndDown;
			}

			if (this.isBottomSheet) {
				breakPoint = this.$vuetify.breakpoint.smAndUp;
			}

			return breakPoint;
		},
		computedIsOpen() {
			return !!(
				this.isOpen &&
				!this.overrideHidden
			);
		},
		isOpenButOverrideHidden() {
			return !!(
				this.isOpen &&
				this.overrideHidden
			);
		},
	},
	methods: {
		close() {
			this.setValue(false);
		},
		open() {
			this.setValue(true);
		},
		setValue(val) {
			if (val !== this.isOpen) {
				this.$emit('input', val);
			}
			this.isOpen = val;
		},
	},
});
</script>

<style lang="scss" scoped>
.dialog-or-bottom-sheet-dialog-content {
	border-radius: var(--global-border-radius) !important;
}

.dialog-or-bottom-sheet-dialog-content-full-height {
	border-radius: var(--global-border-radius) !important;
	height: 100vh;
}

.dialog-or-bottom-sheet-bottom-sheet-content {
	border-top-right-radius: var(--global-border-radius) !important;
	border-top-left-radius: var(--global-border-radius) !important;
}

.dialog-or-bottom-sheet-bottom-sheet-content-full-height {
	border-top-right-radius: var(--global-border-radius) !important;
	border-top-left-radius: var(--global-border-radius) !important;
	height: 100vh;
}
</style>
