
import Vue from 'vue';
import { mapState } from 'vuex';
import { db } from '@/firebase';
import WorkshopSessionCostPreview from '@/views/Workshops/WorkshopPage/AddEditWorkshopSession/WorkshopSessionCostPreview.vue';
import MultipleActiveSessionTimerWrapper from '@/components/MultipleActiveSessionTimerWrapper.vue';
import {
	Session,
	WorkshopSessionQueueItem,
	getHardwareHourlyPrice,
} from '@run-diffusion/shared';
import { get$bindFirestoreOptions } from '@/mixins';

export default Vue.extend({
	name: 'ViewWorkshopSessionQueueItem',
	props: {
		item: { type: Object, required: true },
		workshopSession: { type: Object, default: null },
		isStartingSessionsMap: { type: Object, default: () => ({}) },
		startSessionDisabled: { type: Boolean, default: false },
		isStartingAllSessions: { type: Boolean, default: false },
	},
	data () {
		return {
			session: null,
			sessionLoading: false,
		};
	},
	computed: {
		...mapState([
			'team',
			'hardwareOffersMap',
		]),
	},
	watch: {
		session: {
			immediate: true,
			handler (newVal: Session, oldVal: Session) {
				if (newVal && newVal !== oldVal) {
					this.$nextTick(() => {
						this.$emit('on-session-fetched', newVal);
					});
				}
			},
		},
		item: {
			immediate: true,
			async handler (newVal: WorkshopSessionQueueItem, oldVal: WorkshopSessionQueueItem) {
				const newSessionId: string = this._get(newVal, 'sessionId') || null;
				const oldSessionId: string = this._get(oldVal, 'sessionId') || null;
				if (newSessionId && newSessionId !== oldSessionId) {
					try {
						this.sessionLoading = true;
						const sessionRef = db.collection('sessions').doc(newSessionId);
						const any = await this.$bind(
							'session',
							sessionRef,
							get$bindFirestoreOptions(),
						);
					} catch (e) {
						console.error(e);
					} finally {
						this.sessionLoading = false;
					}
				}
			},
		},
	},
	methods: {
		getHardwareHourlyPrice,
		setOpen (val: boolean) {
			if (val !== this.value) {
				this.$emit('input', !!val);
			}
		},
		closeDrawer () {
			this.setOpen(false);
			this.$emit('on-close');
		},
		getReadyMillisData (readyMillis: number): { hrs: number, mins: number } {
			const readyMins: number = Math.ceil((readyMillis || 0) / 1000 / 60);
			const hrs: number = Math.floor(readyMins / 60);
			const mins: number = readyMins % 60;
			return {
				hrs,
				mins,
			};
		},
		getScheduledStopMinsData (scheduledStopMins: number): { hrs: number, mins: number } {
			const hrs: number = Math.floor(scheduledStopMins / 60);
			const mins: number = scheduledStopMins % 60;
			return {
				hrs,
				mins,
			};
		},
	},
	components: {
		MultipleActiveSessionTimerWrapper,
		WorkshopSessionCostPreview,
	},
});
