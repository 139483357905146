import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._g(_vm._b({staticClass:"d-flex flex-row full-width",style:({
		...(_vm.centeredIcon ?
			{ 'align-items': 'center'} :
			{ 'align-items': 'flex-start'}
		),
		..._vm.containerStyles,
	})},'div',_vm.$attrs,false),_vm.$listeners),[_vm._t("icon",function(){return [(_vm.icon)?_c(VIcon,{class:_vm.iconClasses,staticStyle:{"padding-top":"2px","line-height":"24px"},style:({
				fontSize: _vm.iconSize,
				color: _vm.iconColor,
				lineHeight: '20px',
				...(_vm.centeredIcon && {
					'padding-top': 0,
				}),
				..._vm.iconStyle,
			})},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e()]}),_c('div',{style:({
			'line-height': '24px',
			..._vm.contentContainerStyles
		})},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }