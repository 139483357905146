<template>
	<div>
		<v-switch
			:input-value="value"
			@change="$emit('input', $event)"
			inset
			style="padding: 0;"
		>
			<template v-slot:label>
				<div class="pl-2 d-flex flex-column" style="top: 10px; position: relative;">
					<div>
						Turn on the <b>API mode</b> to expose the app to external tools (e.g. Photoshop plugins like <a @click.stop="openPhotoshopPluginLink1">this one</a> and <a @click.stop="openPhotoshopPluginLink2">Stable.art</a> work with Automatic1111 UI)
					</div>
					<div class="mt-1" style="font-size: 13px; opacity: 0.7;">
						*This will add some time to your startup
					</div>
				</div>
			</template>
		</v-switch>
		<v-expand-transition>
			<div
				v-if="value"
				class="d-flex justify-start align-center"
				style="padding: 10px 0 0 60px;"
			>
				<EnabledApiSVG />
				<div class="ml-3">
					This will appear in the toolbar at the top after you launch, click it to get your <b>API URL</b>
				</div>
			</div>
		</v-expand-transition>
	</div>
</template>

<script>
import Vue from 'vue';
import EnabledApiSVG from '@/assets/EnabledApiSVG';

export default Vue.extend({
	name: 'EnableApiSettings',
	props: {
		value: { type: Boolean, default: false },
	},
	methods: {
		openPhotoshopPluginLink1 () {
			window.open('https://github.com/AbdullahAlfaraj/Auto-Photoshop-StableDiffusion-Plugin', '_blank');
		},
		openPhotoshopPluginLink2 () {
			window.open('https://github.com/isekaidev/stable.art', '_blank');
		},
	},
	components: {
		EnabledApiSVG,
	},
});
</script>
