var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"island-container glassmorphism-background",style:({
		width: _vm.width,
		height: _vm.height,
		bottom: _vm.bottom,
		...(_vm.styles && {
			..._vm.styles,
		}),
	})},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }