
import Vue from 'vue';
import DialogContent from '@/components/base/DialogContent.vue';
import {
	STRIPE_ADD_FUNDS_5_PRICE_ID,
	STRIPE_ADD_FUNDS_10_PRICE_ID,
	STRIPE_ADD_FUNDS_15_PRICE_ID,
	STRIPE_ADD_FUNDS_20_PRICE_ID,
	STRIPE_ADD_FUNDS_25_PRICE_ID,
	STRIPE_ADD_FUNDS_30_PRICE_ID,
	STRIPE_ADD_FUNDS_35_PRICE_ID,
	STRIPE_ADD_FUNDS_40_PRICE_ID,
	STRIPE_ADD_FUNDS_45_PRICE_ID,
	STRIPE_ADD_FUNDS_50_PRICE_ID,
	STRIPE_ADD_FUNDS_60_PRICE_ID,
	STRIPE_ADD_FUNDS_70_PRICE_ID,
	STRIPE_ADD_FUNDS_80_PRICE_ID,
	STRIPE_ADD_FUNDS_90_PRICE_ID,
	STRIPE_ADD_FUNDS_100_PRICE_ID,
	CHECKOUT_ADD_FUNDS,
} from '@run-diffusion/shared';
import _mapKeys from 'lodash/mapKeys';
import ActionsIsland from '@/components/ActionsIsland.vue';
import { getLastPriceId, setLastPriceId } from '@/utils/localStorage';
import _find from 'lodash/find';

export default Vue.extend({
	name: 'PrivateBalancePricingTableDialog',
	props: {
		value: { type: Boolean, default: false },
	},
	data () {
		const defaultPriceId: string = STRIPE_ADD_FUNDS_10_PRICE_ID;
		const minCouponPriceId: string = STRIPE_ADD_FUNDS_15_PRICE_ID;
		const items: { priceId: string, amt: number }[] = [
			{ priceId: STRIPE_ADD_FUNDS_5_PRICE_ID, amt: 500 },
			{ priceId: STRIPE_ADD_FUNDS_10_PRICE_ID, amt: 1000 },
			{ priceId: STRIPE_ADD_FUNDS_15_PRICE_ID, amt: 1500 },
			{ priceId: STRIPE_ADD_FUNDS_20_PRICE_ID, amt: 2000 },
			{ priceId: STRIPE_ADD_FUNDS_25_PRICE_ID, amt: 2500 },
			{ priceId: STRIPE_ADD_FUNDS_30_PRICE_ID, amt: 3000 },
			{ priceId: STRIPE_ADD_FUNDS_35_PRICE_ID, amt: 3500 },
			{ priceId: STRIPE_ADD_FUNDS_40_PRICE_ID, amt: 4000 },
			{ priceId: STRIPE_ADD_FUNDS_45_PRICE_ID, amt: 4500 },
			{ priceId: STRIPE_ADD_FUNDS_50_PRICE_ID, amt: 5000 },
			{ priceId: STRIPE_ADD_FUNDS_60_PRICE_ID, amt: 6000 },
			{ priceId: STRIPE_ADD_FUNDS_70_PRICE_ID, amt: 7000 },
			{ priceId: STRIPE_ADD_FUNDS_80_PRICE_ID, amt: 8000 },
			{ priceId: STRIPE_ADD_FUNDS_90_PRICE_ID, amt: 9000 },
			{ priceId: STRIPE_ADD_FUNDS_100_PRICE_ID, amt: 10000 },
		];

		return {
			priceChipsVisible: false,
			minCouponPriceId,
			defaultPriceId,
			selectedPriceId: defaultPriceId,
			items,
			itemsMap: _mapKeys(items, 'priceId'),
		};
	},
	created () {
		const lastPriceId: string = getLastPriceId();
		if (lastPriceId) {
			const foundItem: { priceId: string, amt: number } = _find(this.items, ({ priceId }) => lastPriceId === priceId);
			if (foundItem && foundItem.amt >= this.itemsMap[this.defaultPriceId].amt) {
				this.selectedPriceId = lastPriceId;
			}
		}
	},
	methods: {
		onInput (val: boolean) {
			this.$emit('input', !!val);
		},
		onSelectPriceId (priceId: string) {
			this.selectedPriceId = priceId || this.defaultPriceId;
		},
		onGoToPaymentClick () {
			setLastPriceId(this.selectedPriceId);
			const routeData = this.$router.resolve({
				name: 'GoToPayment',
				query: {
					checkoutAction: CHECKOUT_ADD_FUNDS,
					priceId: this.selectedPriceId,
				},
			});
			window.open(routeData.href, '_blank');
			this.onInput(false);
		},
	},
	components: {
		ActionsIsland,
		DialogContent,
	},
});
