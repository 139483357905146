
import Vue from 'vue';

export default Vue.extend({
	name: 'LimitClubAddOnSelectItem',
	props: {
		item: { type: Object, required: true },
		clubOffer: { type: Object, required: true },
		highlightAsCurrentPlanItem: { type: Boolean, default: false },
	},
});
