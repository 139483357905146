
import Vue from 'vue';
import ListItem from '@/components/base/ListItem.vue';
import WorkShops from '@/views/Workshops/Workshops.vue';
import InfoAssetBanner from '@/components/base/InfoAssetBanner.vue';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'WorkshopsList',
	props: {},
	data () {
		return {};
	},
	computed: {
		...mapState([
			'user',
			'team',
		]),
	},
	components: {
		InfoAssetBanner,
		WorkShops,
		ListItem,
	},
});
