
import Vue from 'vue';
import {
	TEXT_FIELD_RULE,
	TEXT_FIELD_MAX_LENGTH,
	SHOW_TEXT_FIELD_COUNTER_AT,
} from '@/constants/constants';
import _trim from 'lodash/trim';
import { storage } from '@/firebase';
import _truncate from 'lodash/truncate';
import { AppOfferResource } from '@run-diffusion/shared';
import { APP_OFFER_RESOURCE_TYPE } from '@/constants/enums';

export default Vue.extend({
	name: 'AppOfferResourcesField',
	props: {
		value: { type: Array, default: () => [] },
		disabled: { type: Boolean, default: false },
	},
	data () {
		return {
			SHOW_TEXT_FIELD_COUNTER_AT,
			TEXT_FIELD_MAX_LENGTH,
			APP_OFFER_RESOURCE_TYPE,
			uploadingImgMap: {},

			rules: {
				requiredTextField: [
					TEXT_FIELD_RULE,
					v => !!_trim(v) || 'Required',
				],
			},
		};
	},
	methods: {
		_truncate,
		triggerFileInput (index: number) {
			this.$refs[`fileInput${index}`][0].click();
		},
		async uploadImage (event, index: number, resource: AppOfferResource) {
			const file = event.target.files[0];
			if (!file) return;
			this.uploadingImgMap[index] = true;

			try {
				const storageRef = storage.ref('/assets/app-offers-resources/' + file.name);
				await storageRef.put(file);
				const viewableUrl: string = await storageRef.getDownloadURL();
				resource.imgUrl = viewableUrl;
			} catch (error) {
				console.error('Error uploading image:', error);
			} finally {
				this.uploadingImgMap[index] = false;
			}
		},
		onRemoveVal (val: AppOfferResource) {
			this.$emit('input', (this.value || []).filter(v => v !== val));
		},
		onAdd () {
			this.$emit(
				'input',
				[
					...(this.value || []),
					{
						type: APP_OFFER_RESOURCE_TYPE.YOUTUBE,
						name: null,
						description: null,
						url: null,
						imgUrl: null,
					},
				]
			);
		},
		removeResourceItem (index: number) {
			if (index <= (this.value || []).length - 1 && index >= 0) {
				const item: AppOfferResource = this.value[index];
				let newArray: AppOfferResource[] = this.value.filter((val: AppOfferResource) => val !== item);
				this.$emit('input', newArray);
			}
		},
		moveResourceItem (fromIndex: number, toIndex: number) {
			if (toIndex <= (this.value || []).length - 1 && toIndex >= 0) {
				const item: AppOfferResource = this.value[fromIndex];
				let newArray: AppOfferResource[] = this.value.filter((val: AppOfferResource) => val !== item);
				newArray.splice(toIndex, 0, item);
				this.$emit('input', newArray);
			}
		},
	},
});
