
import Vue from 'vue';
import ListItem from '@/components/base/ListItem.vue';

export default Vue.extend({
	name: 'InfoMessageSmall',
	props: {
		icon: { type: String, default: 'mdi-information-outline' },
		superPrimary: { type: Boolean, default: false },
		containerStyles: {
			type: Object,
			default: () => ({
				padding: '8px',
			}),
		},
		innerContainerStyles: {
			type: Object,
			default: () => ({
				padding: '15px',
			}),
		},
	},
	components: {
		ListItem,
	},
});
