
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import _trim from 'lodash/trim';
import _omit from 'lodash/omit';
import _keys from 'lodash/keys';
import _values from 'lodash/values';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import CivitaiChip from '@/components/ModelSelect/CivitaiChip.vue';
import {
	asyncForEach,
	CivitaiModel,
	CivitaiModelVersion,
	SessionBackgroundTask,
	DOWNLOAD_MODEL_SERVICE,
} from '@run-diffusion/shared';
import ActionsIsland from '@/components/ActionsIsland.vue';
import { getModelTypeDisplayText } from '@/utils';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { functions } from '@/firebase';
import { ModelSelectItemsState, PreloadModelState, ToolbarState } from '@/store';
import pluralize from 'pluralize';
import { SESSION_BACKGROUND_TASK_STATUS, SESSION_BACKGROUND_TASK_TYPE } from '@/constants/enums';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import ListItem from '@/components/base/ListItem.vue';

export default Vue.extend({
	name: 'ModelSelect',
	props: {
		value: { type: Array, default: () => [] },
		disabled: { type: Boolean, default: false },
		textFieldClass: { type: [String, Object], default: '' },
	},
	data () {
		return {
			dialogOpen: false,
			valueStateMap: {},
			searchError: null,
			loadingSearched: false,
			lastSearchedTerm: '',
			search: null,
			showImages: true,
			downloading: false,
			awaitingSessionBackgroundTaskIds: [],
			forceOpenNotificationsTimeout: null,

			downloadingNotAllowedDialogOpen: false,
			inProgressModelDownloadTasks: [],

			downloadingLimitationsDialogReadOnce: false,
			downloadingLimitationsDialogOpen: false,
		};
	},
	computed: {
		...mapState([
			'preloadModel',
			'realTimeMoment',
			'modelSelectItems',
			'user',
			'toolbar',
			'currSessionBackgroundTasks',
		]),
		...mapGetters([
			'clubOfferBenefits',
		]),
		// selectedSoftwareOfferCivitaiBaseVersionText () {
		// 	if (!this.selectedSoftwareOffer) {
		// 		return null;
		// 	}
		// 	if (this.selectedSoftwareOffer.software.startsWith('SD1-')) {
		// 		return 'SD 1';
		// 	}
		// 	if (this.selectedSoftwareOffer.software.startsWith('SD2-')) {
		// 		return 'SD 2';
		// 	}
		// 	return null;
		// },
		downloadingAllowed () {
			if (!this.inProgressModelDownloadTasks.length) return true;

			const LIMIT: number = this.isMultiple ? 3 : 1;

			let hasTooMany: boolean = false;
			let currNumDownloading: number = 0;
			this.inProgressModelDownloadTasks.some((sessionBackgroundTask: SessionBackgroundTask) => {
				if (this.realTimeMoment.clone().subtract(3, 'minutes').isBefore(sessionBackgroundTask.updatedAt.toMillis())) {
					// Stuck IN_PROGRESS for less than 3 minutes, consider it actively downloading
					currNumDownloading++;
					if (currNumDownloading >= LIMIT) {
						hasTooMany = true;
					}
				}
				return hasTooMany;
			});
			return !hasTooMany;
		},
		isMultiple () {
			return false;
		},
		showSearchButton () {
			return !!_trim(this.search);
		},
		selectedLength () {
			return _keys(this.valueStateMap).length;
		},
		numSelectedValueText () {
			if (this.selectedLength === 1) {
				const selectedKeys: string[] = _keys(this.valueStateMap);
				return `${this.valueStateMap[selectedKeys[0]].model.name} (${this.valueStateMap[selectedKeys[0]].modelVersion.name})`;
			}
			return this.selectedLength ? `${this.selectedLength} selected` : null;
		},
		numSelectedTitleText () {
			return `${this.selectedLength} selected`;
		},
		currResults () {
			return this.modelSelectItems.civitai;
		},
	},
	destroyed () {
		if (this.forceOpenNotificationsTimeout) clearTimeout(this.forceOpenNotificationsTimeout);
	},
	watch: {
		preloadModel: {
			immediate: true,
			handler (newVal: PreloadModelState, oldVal: PreloadModelState) {
				if (newVal !== oldVal) {
					this.autoOpenFromNewPreloadModel(newVal, this.toolbar);
				}
			},
		},
		toolbar: {
			immediate: true,
			handler (newVal: ToolbarState, oldVal: ToolbarState) {
				if (newVal !== oldVal) {
					this.autoOpenFromNewPreloadModel(this.preloadModel, newVal);
				}
			},
		},
		modelSelectItems: {
			immediate: true,
			handler (newVal: ModelSelectItemsState, oldVal: ModelSelectItemsState) {
				if (newVal !== oldVal) {
					if (newVal.civitai.length === 1) {
						const firstModel: CivitaiModel = newVal.civitai[0];
						const firstModelVersion: CivitaiModelVersion = this._get(firstModel, 'modelVersions[0]');

						// Default selection to first model - latest modelVersion
						if (firstModelVersion && firstModel) {
							this.selectModelVersion(firstModelVersion, firstModel);
						}
					}
				}
			},
		},
		value: {
			immediate: true,
			handler (newVal: { model: CivitaiModel, modelVersion: CivitaiModelVersion }[]) {
				this.valueStateMap = {};
				(newVal || []).forEach(({ model, modelVersion }) => {
					this.valueStateMap[modelVersion.id] = {
						model,
						modelVersion,
					};
				});
			},
		},
		currSessionBackgroundTasks: {
			immediate: true,
			handler (newVal: SessionBackgroundTask[], oldVal: SessionBackgroundTask[]) {
				if (newVal !== oldVal) {
					this.attemptOpenNotifications(newVal, true);

					// Aggregate inProgress model download tasks
					this.inProgressModelDownloadTasks = newVal.filter((sessionBackgroundTask: SessionBackgroundTask) => (
						sessionBackgroundTask.type === SESSION_BACKGROUND_TASK_TYPE.DOWNLOAD_MODEL &&
						sessionBackgroundTask.status === SESSION_BACKGROUND_TASK_STATUS.IN_PROGRESS
					));
				}
			},
		},
		// async dialogOpen (newVal) {
		// 	if (newVal && !this.lastSearchedTerm && !this.search) {
		// 		this.search = ' ';
		// 		await this.doSearch();
		// 	}
		// },
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'updateToolbar',
			'loadModelSelectItems',
			'updatePreloadModel',
		]),
		pluralize,
		getModelTypeDisplayText,
		filterImages (images) {
			return (images || []).filter(({ nsfw }) => !['Mature', 'X', 'Soft'].includes(nsfw));
		},
		autoOpenFromNewPreloadModel (preloadModel: PreloadModelState, toolbar: ToolbarState) {
			if (
				this._get(preloadModel, 'civitai.model') &&
				toolbar.session &&
				(
					this._get(toolbar.session, 'preloadModel.modelId') !== preloadModel.civitai.model.id ||
					this._get(toolbar.session, 'preloadModel.modelVersionId') !== preloadModel.civitai.modelVersion.id
				) &&
				this.$route.name === 'Launch'
			) {
				this.onOpenDialog();
				this.search = this._get(preloadModel, 'civitai.model.name') || null;
				this.updatePreloadModel({ providerKey: 'civitai', result: null });
				if (this.search) {
					this.doSearch();
				}
			}
		},
		onOpenDialog () {
			if (!this.disabled) {
				this.dialogOpen = true;
				setTimeout(() => {
					if (this.$refs.searchTextField) {
						this.$refs.searchTextField.focus();
					}
				}, 500);
			}
		},
		onEmitValueAndClose () {
			this.dialogOpen = false;
			this.$emit('input', _values(this.valueStateMap));
		},
		async doSearch () {
			this.searchError = null;
			this.lastSearchedTerm = _trim(this.search);
			if (!this.lastSearchedTerm) {
				return;
			}

			if (!this.isMultiple) {
				this.valueStateMap = {};
			}

			this.loadingSearched = true;
			const {
				civitaiSuccess,
			} = await this.loadModelSelectItems(this.lastSearchedTerm, this.showImages);
			if (!civitaiSuccess) {
				this.searchError = 'Civitai search is currently not working. Please try again later or you can reach out to us in Discord in the #bugs channel to report an issue.';
			}
			this.loadingSearched = false;
		},
		isModelVersionAllowed (modelVersion: CivitaiModelVersion) {
			return !!(
				!this.downloading &&
				modelVersion &&
				modelVersion.baseModel
				// this.selectedSoftwareOffer &&
				// (
				// 	(
				// 		modelVersion.baseModel.startsWith('SD 1.') &&
				// 		this.selectedSoftwareOffer.software.startsWith('SD1-')
				// 	) ||
				// 	(
				// 		modelVersion.baseModel.startsWith('SD 2.') &&
				// 		this.selectedSoftwareOffer.software.startsWith('SD2-')
				// 	)
				// )
			);
		},
		selectModelVersion (modelVersion: CivitaiModelVersion, model: CivitaiModel) {
			if (this.isModelVersionAllowed(modelVersion)) {
				this.valueStateMap = {
					...(this.isMultiple ? this.valueStateMap : {}),
					[modelVersion.id]: {
						model,
						modelVersion,
					},
				};
			}
		},
		deselectModelVersion (modelVersion: CivitaiModelVersion) {
			this.valueStateMap = _omit(this.valueStateMap, modelVersion.id);
		},
		onShowImages (showImages) {
			this.showImages = showImages;
		},
		deselectAllModels () {
			this.valueStateMap = {};
		},
		attemptOpenNotifications (sessionBackgroundTasks: SessionBackgroundTask[], shouldAwaitSessionBackgroundTasks: boolean) {
			if (
				this.downloading &&
				(
					!shouldAwaitSessionBackgroundTasks ||
					(
						this.awaitingSessionBackgroundTaskIds.length &&
						sessionBackgroundTasks.some(({ id }) => this.awaitingSessionBackgroundTaskIds.includes(id))
					)
				)
			) {
				if (this.forceOpenNotificationsTimeout) clearTimeout(this.forceOpenNotificationsTimeout);
				this.awaitingSessionBackgroundTaskIds = [];
				this.downloading = false;
				this.valueStateMap = {};
				this.openNotifications();
			}
		},
		openNotifications () {
			this.updateToolbar({
				notificationsOpen: true,
			});
			this.onEmitValueAndClose();
		},
		onDownloadClick () {
			if (
				!(
					this.toolbar.session &&
					this.toolbar.session.teamId
				) &&
				!this.clubOfferBenefits.isCc &&
				!this.downloadingLimitationsDialogReadOnce
			) {
				this.downloadingLimitationsDialogOpen = true;
				this.downloadingLimitationsDialogReadOnce = true;
			} else {
				this.downloadSelectedModels();
			}
		},
		async downloadSelectedModels () {
			this.downloadingLimitationsDialogOpen = false;

			if (!this.selectedLength || this.downloading) {
				return;
			}

			if (!this.downloadingAllowed) {
				this.downloadingNotAllowedDialogOpen = true;
				return;
			}

			try {
				const selectedModels = _values(this.valueStateMap);
				this.downloading = true;

				await asyncForEach(selectedModels, async ({ model, modelVersion }) => {
					const onError: Function = (e, message: string) => {
						console.error('Error downloading model: ', e, message);
					};

					const functionRef = functions.httpsCallable('serverBuddy');
					const { success, message, data } = (await functionRef({
						sessionId: this.toolbar.session.id,
						endpoint: DOWNLOAD_MODEL_SERVICE,
						params: {
							model_id: model.id,
							model_version_id: modelVersion.id,
						},
					})).data;
					if (!success) {
						onError(new Error('serverBuddy not successful'), message);
					}
					if (data.task_id) {
						this.awaitingSessionBackgroundTaskIds.push(data.task_id);
					}
				});

				this.attemptOpenNotifications(this.currSessionBackgroundTasks, true);
				this.forceOpenNotificationsTimeout = setTimeout(() => {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.WARN,
						message: 'Downloading took too long to get started, if you don\'t see a "notification" appear for this model, try again later',
						show: true,
						timeout: 30000,
					});
					this.attemptOpenNotifications(this.currSessionBackgroundTasks, false);
				}, 20000);
			} catch (e) {
				console.error('Error downloading models: ', e);

				this.downloading = false;
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error with model download',
					show: true,
				});
			}
		},
	},
	components: {
		ListItem,
		ConfirmDialog,
		ActionsIsland,
		CivitaiChip,
		EmptyState,
		LoadingSVG,
	},
});
