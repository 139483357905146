import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column full-width"},[_c('div',{staticStyle:{"margin":"20px 0 45px 0","font-size":"20px"}},[_vm._v(" Now that you're up and running, here's some information on how you're going to use your new server in conjunction with this page, and why you have a File Browser open and available. ")]),_c(VSheet,{staticClass:"full-width pa-8",attrs:{"outlined":""}},[_c('list-item',{staticStyle:{"width":"100%"},attrs:{"icon":"mdi-check-circle","container-styles":{
				marginBottom: 0,
			}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{staticStyle:{"border-radius":"12px"},attrs:{"width":_vm.$vuetify.breakpoint.smAndUp ? '100px' : '75px',"src":require("./assets/file-browser-icon.png")}})]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"section-title"},[_vm._v(" File Browser ")]),_c('div',[_vm._v(" You have the "),_c('b',[_vm._v("File Browser")]),_vm._v(" on the right to help with a couple things. ")]),_c('div',{staticClass:"mt-6"},[_c('list-item',{attrs:{"icon":"mdi-check-circle"}},[_vm._v(" Allows you to transfer files from here into your launched server by uploading them into a folder in the File Browser on the right. "),_c('br'),_c('br'),_vm._v(" You then login to "),_c('b',[_vm._v("app.rundiffusion.com")]),_vm._v(" on the server, and download them from the File Browser. ")])],1)])]},proxy:true}])})],1),_c(VSheet,{staticClass:"full-width pa-8 mt-10",attrs:{"outlined":""}},[_c('list-item',{staticStyle:{"width":"100%"},attrs:{"icon":"mdi-check-circle","container-styles":{
				marginBottom: 0,
			}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{staticStyle:{"border-radius":"12px"},attrs:{"width":_vm.$vuetify.breakpoint.smAndUp ? '100px' : '75px',"src":require("./assets/pcoip-client-logo.png")}})]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"section-title"},[_vm._v(" PCoIP Client ")]),_c('div',[_vm._v(" This is the client that you downloaded that allows you to connect to the server we launched for you. "),_c('br'),_c('br'),_vm._v(" Go to the "),_c('span',{staticStyle:{"color":"var(--primary-blue)","cursor":"pointer"},on:{"click":_vm.goToDownload}},[_vm._v("Download")]),_vm._v(" tab if you don't have the client to connect to the launched server yet. ")])])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }