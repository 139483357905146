
import Vue from 'vue';
import { mapState } from 'vuex';
import ListItem from '@/components/base/ListItem.vue';

export default Vue.extend({
	name: 'FileBrowserNewTabBtn',
	props: {
		isListItem: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'toolbar',
		]),
	},
	methods: {
		onClick () {
			window.open(this.toolbar.session.serverUrlFileBrowser, '_blank').focus();
		},
	},
	components: {
		ListItem,
	},
});
