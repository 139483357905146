import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pt-10 pb-12",staticStyle:{"background":"var(--dark-purple-900)"}},[_c(VRow,[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","md":"8","offset-md":"2","lg":"6","offset-lg":"3"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"run-diffusion-logo-container"},[_c('img',{staticClass:"logo",attrs:{"alt":"Logo","height":"60px","src":"https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Flogos%2Frd-logo-white.png?alt=media&token=2d643871-2ef7-4c67-b8c6-c6472fbe4a4a"}})]):_vm._e(),(_vm.loadingLobbyWorkshop || !_vm.lobbyWorkshop)?_c('div',{staticClass:"d-flex flex-column full-width full-height justify-center align-center",staticStyle:{"height":"70vh"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"#575757","size":"110"}}),_c('div',{staticClass:"mt-8",staticStyle:{"color":"var(--grey-500)","font-size":"17px"}},[_vm._v(" Loading workshop... ")])],1):_c('div',{staticClass:"d-flex flex-column align-center full-width justify-center",staticStyle:{"height":"70vh","margin":"10px 0 0 0"}},[_c('div',{staticClass:"lobby-bg-gradient-blur-wrapper",style:({
						filter: _vm.$vuetify.breakpoint.lgAndUp ? 'blur(130px)' : 'blur(100px)',
					})},[_c('div',{staticClass:"lobby-bg-gradient-blur-circle-4"}),_c('div',{staticClass:"lobby-bg-gradient-blur-circle-3"}),_c('div',{staticClass:"lobby-bg-gradient-blur-circle-2"}),_c('div',{staticClass:"lobby-bg-gradient-blur-circle-1-4"}),_c('div',{staticClass:"lobby-bg-gradient-blur-circle-1-3"}),_c('div',{staticClass:"lobby-bg-gradient-blur-circle-1-2"}),_c('div',{staticClass:"lobby-bg-gradient-blur-circle-1"})]),_c('div',{staticClass:"workshop-lobby-outer-container",style:({
						margin: _vm.$vuetify.breakpoint.mdAndUp ? '0' : '100px 0 0 0',
					})},[_c('div',{staticClass:"workshop-lobby-title-container"},[(_vm.isPreviewMode)?_c('info-message-small',{staticClass:"mb-6 pa-2",attrs:{"super-primary":""}},[_vm._v(" You are viewing this page in preview mode. "),_c('br'),_c('a',{staticClass:"light-blue--text",on:{"click":_vm.closeCurrWindow}},[_vm._v("Click here")]),_vm._v(" to close this tab. ")]):_vm._e(),_c('span',{staticClass:"workshop-lobby-title"},[_vm._v("Waiting for Workshop to start...")])],1),_c('div',{staticClass:"workshop-lobby-container tw-shadow-2xl",style:({
							maxWidth: _vm.$vuetify.breakpoint.mdAndUp ? '100%' : '600px',
						})},[_c('div',{staticClass:"workshop-lobby-inner-container",style:({
								padding: _vm.$vuetify.breakpoint.xsOnly ? '30px' : '50px',
							})},[_c('div',{staticClass:"workshop-session-name tw-shadow-md",style:({
									fontSize: _vm.$vuetify.breakpoint.mdAndUp ? '37px' : '30px',
								})},[_vm._v(" "+_vm._s(_vm.lobbyWorkshop.name)+" ")]),(_vm.user)?[_c('div',[_c('LoadingSVG',{staticClass:"my-3",attrs:{"height":110,"width":110}})],1),_c('div',{staticClass:"contact-details"},[_vm._v(" "+_vm._s(_vm.lobbyWorkshop.contactName)+" ")]),_c('div',{staticClass:"contact-details"},[_vm._v(" "+_vm._s(_vm.lobbyWorkshop.contactEmail)+" ")])]:[_c('div',{staticClass:"welcome-title gradient-text"},[_vm._v(" Welcome! ")]),_c('div',{staticClass:"welcome-message"},[_vm._v(" You'll first need to login or create an account ")]),_c('base-button',{staticClass:"mt-5 py-6",attrs:{"call-to-action":"","large":""},on:{"click":_vm.onLoginRedirectClick}},[_c('b',[_vm._v("Login / Create Account")])])]],2)]),(_vm.timeLeftData && _vm.timeLeftData.isNow)?_c('div',{staticClass:"workshop-lobby-timer-container",style:({
							maxWidth: _vm.$vuetify.breakpoint.mdAndUp ? '100%' : '600px',
						})},[_c('div',{staticClass:"timer-box-is-now"},[_vm._v(" The workshop is scheduled for "),_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.workshopStartAtMoment,'MMM D, YYYY [@] hh:mm a')))]),_vm._v(", but the host has not started it yet. Their contact information is displayed above. ")])]):(_vm.timeLeftData)?_c('div',{staticClass:"workshop-lobby-timer-container"},[_c('div',{staticClass:"timer-box"},[_vm._v(" "+_vm._s(_vm._get(_vm.timeLeftData, 'hours'))+" ")]),_c('b',{staticClass:"mx-3",staticStyle:{"font-size":"25px"}},[_vm._v(":")]),_c('div',{staticClass:"timer-box"},[_vm._v(" "+_vm._s(_vm._get(_vm.timeLeftData, 'minutes'))+" ")]),_c('b',{staticClass:"mx-3",staticStyle:{"font-size":"25px"}},[_vm._v(":")]),_c('div',{staticClass:"timer-box"},[_vm._v(" "+_vm._s(_vm._get(_vm.timeLeftData, 'seconds'))+" ")])]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"d-flex justify-center align-center full-width",staticStyle:{"margin":"40px 0 0 0","z-index":"10","opacity":"0.8"}},[_c('img',{staticClass:"logo",attrs:{"alt":"Logo","height":"60px","src":"https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Flogos%2Frd-logo-white.png?alt=media&token=2d643871-2ef7-4c67-b8c6-c6472fbe4a4a"}})]):_vm._e()])]),(_vm.user)?_c('div',{staticClass:"run-diffusion-username-container",style:({
					right: _vm.$vuetify.breakpoint.xsOnly ? 'inherit' : '20px',
					left: _vm.$vuetify.breakpoint.xsOnly ? '25px' : 'none',
				})},[_c('div',{staticStyle:{"color":"var(--grey-600)","font-size":"14px"}},[_vm._v(" Logged in ")]),_c('b',[_vm._v(_vm._s(_vm.user.email))])]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }