
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'LogsPane',
	props: {
		showSbudAdminLog: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'user',
			'toolbar',
			'isAdminProxyingUser',
		]),
		logViewerUrl () {
			const sbudSecret: string = this._get(this.toolbar, 'sbudServices.secrets.sbud') || null;
			if (this.user.isAdmin) {
				return `${this.toolbar.session.serverUrlSBUD}/log_view${!this.showSbudAdminLog ? '?app=true' : ''}`;
			} else if (this.isAdminProxyingUser && sbudSecret && this.showSbudAdminLog) {
				return `${this.toolbar.session.serverUrlSBUD}/log_view?app=${sbudSecret}`;
			}
			return `${this.toolbar.session.serverUrlSBUD}/log_view`;
		},
	},
});
