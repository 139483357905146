<template>
	<div
		v-if="!comfyOnboardingDismissed"
		:style="{
			position: 'absolute',
			top: '10px',
			left: '60px',
		}"
		class="pa-6"
	>
		<v-card
			style="padding: 20px; height: 100%;"
			:style="{
				minWidth: $vuetify.breakpoint.xsOnly ? '300px' : '500px',
				minHeight: $vuetify.breakpoint.xsOnly ? '300px' : '500px',
			}"
			class="elevation-10"
		>
			<div class="d-flex justify-center flex-column align-center full-height">
				<div class="d-flex justify-center align-center my-6">
					<v-icon class="gradient-text" style="font-size: 80px; margin: 0 0 0 0;">mdi-graph-outline</v-icon>
				</div>
				<v-card-subtitle
					v-if="$vuetify.breakpoint.mdAndUp"
					style="font-size: 21px; text-align: center; line-height: 30px;"
				>
					Want to get started with
					<br />ComfyUI quickly?
				</v-card-subtitle>
				<v-card-text class="py-7">
					<div class="d-flex justify-center">
						<base-button
							large
							class="px-8"
							@click="openComfyOnboardingPage"
						>
							<slot name="default">
								Drag & Drop Workflows
								<v-icon right small>mdi-open-in-new</v-icon>
							</slot>
						</base-button>
					</div>
				</v-card-text>
				<v-card-actions>
					<div class="d-flex flex-column justify-center full-width">
						<div class="d-flex justify-center full-width">
							<v-checkbox
								v-model="shouldSaveComfyOnboardingDismiss"
								hide-details
								label="Don't show this again"
								color="primary"
								class="mb-4"
							/>
						</div>
						<base-button
							@click="dismissComfyOnboardingPopup"
							secondary
						>
							Dismiss
						</base-button>
					</div>
				</v-card-actions>
			</div>
		</v-card>
	</div>
</template>

<script>
import { setComfyOnboardingDismissed } from '@/utils/localStorage';

export default {
	name: 'ComfyOnboarding',
	data () {
		return {
			comfyOnboardingDismissed: false,
			shouldSaveComfyOnboardingDismiss: false,
		};
	},
	methods: {
		dismissComfyOnboardingPopup () {
			if (this.shouldSaveComfyOnboardingDismiss) {
				setComfyOnboardingDismissed();
			}
			this.comfyOnboardingDismissed = true;
		},
		openComfyOnboardingPage () {
			window.open('https://rundiffusion.com/comfyui-workflows', '_blank');
		},
	},
};
</script>

<style scoped>

</style>
