
import Vue from 'vue';

export default Vue.extend({
	name: 'RunnitSettingHelperBtn',
	props: {
		minWidth: { type: String, default: 'auto' },
		selected: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		tooltip: { type: String, default: null },
		tooltipTop: { type: Boolean, default: true },
		tooltipDelay: { type: Number, default: 500 },
	},
	data () {
		return {};
	},
});
