
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import ListItem from '@/components/base/ListItem.vue';

export default Vue.extend({
	name: 'FileBrowserBtn',
	props: {
		isListItem: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'toolbar',
		]),
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
		onClick () {
			this.updateToolbar({
				fileBrowserOpen: !this.toolbar.fileBrowserOpen,
			});
		},
	},
	components: {
		ListItem,
	},
});
