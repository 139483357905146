
import Vue from 'vue';
import {
	READABLE_RUNNIT_NODE_DEF_TAG_TYPE,
	RUNNIT_NODE_DEF_TAG_TYPE,
	RUNNIT_NODE_DEF_TAG_TYPE_ICON,
} from '@/constants/enums';
import { RunnitNodeDefTagType, } from '@run-diffusion/shared';
import { RUNNITS_OWNER_SELECTION } from './constants';
import { mapState } from 'vuex';
import RunnitCarousel from './RunnitCarousel.vue';

export default Vue.extend({
	name: 'RunnitTagTypeTabs',
	props: {
		value: { type: String, default: null },
	},
	data () {
		return {
		};
	},
	watch: {
		items: {
			immediate: true,
			handler (newVal) {
				if (newVal.length) {
					this.initializeTab(newVal[0]);
				}
			}
		}
	},
	computed: {
		...mapState([
			'runnitState',
			'team',
		]),
		items () {
			return Object.values(RUNNIT_NODE_DEF_TAG_TYPE).filter((tagType: RunnitNodeDefTagType) => {
				const excludedTypes = [RUNNIT_NODE_DEF_TAG_TYPE.HOME_PAGE, RUNNIT_NODE_DEF_TAG_TYPE.INDUSTRY];
				if (this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER || !this.team) {
					excludedTypes.push(RUNNIT_NODE_DEF_TAG_TYPE.TEAM);
				}
				return !excludedTypes.includes(tagType);
			}).map((t: RunnitNodeDefTagType, i: number) => {
				return {
					id: i,
					label: READABLE_RUNNIT_NODE_DEF_TAG_TYPE[t],
					tagType: t,
					icon: RUNNIT_NODE_DEF_TAG_TYPE_ICON[t]
				}
			})
		},
	},
	methods: {
		initializeTab (inputItem) {
			let tagType = this.value || inputItem.tagType;
			if (!tagType && this.items.length) {
				tagType = this.items[0].tagType;
			}
			this.$emit('on-select', tagType)
		},
		handleTabChange (category) {
			this.$emit('on-select', category)
		}
	},
	components: {
		RunnitCarousel
	},
});
