var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
		'island-container': _vm.detached,
		'island-container-fixed-bottom': !_vm.detached
	},style:({
		...(_vm.detached && {
			bottom: _vm.$vuetify.breakpoint.smAndDown ? '10px' : '20px',
		}),
		...(_vm.styles && {
			..._vm.styles,
		}),
		...(_vm.width && {
			width: _vm.width,
		}),
		...(_vm.height && {
			height: _vm.height,
		}),
	})},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }