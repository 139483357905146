<template>
	<div class="d-flex flex-column full-width">
		<div style="margin: 20px 0 45px 0; font-size: 20px;">
			Now that you're up and running, here's some information on how you're going to use your new server in conjunction with this page, and why you have a File Browser open and available.
		</div>
		<v-sheet
			outlined
			class="full-width pa-8"
		>
			<list-item
				icon="mdi-check-circle"
				style="width: 100%;"
					:container-styles="{
					marginBottom: 0,
				}"
			>
				<template v-slot:icon>
					<img style="border-radius: 12px" :width="$vuetify.breakpoint.smAndUp ? '100px' : '75px'" src="./assets/file-browser-icon.png">
				</template>
				<template v-slot:default>
					<div class="content-container">
						<div class="section-title">
							File Browser
						</div>
						<div>
							You have the <b>File Browser</b> on the right to help with a couple things.
						</div>
						<div class="mt-6">
							<list-item icon="mdi-check-circle">
								Allows you to transfer files from here into your launched server by uploading them into a folder in the File Browser on the right.
								<br /><br />
								You then login to <b>app.rundiffusion.com</b> on the server, and download them from the File Browser.
							</list-item>
						</div>
					</div>
				</template>
			</list-item>
		</v-sheet>
		<v-sheet
			outlined
			class="full-width pa-8 mt-10"
		>
			<list-item
				icon="mdi-check-circle"
				style="width: 100%;"
				:container-styles="{
					marginBottom: 0,
				}"
			>
				<template v-slot:icon>
					<img style="border-radius: 12px" :width="$vuetify.breakpoint.smAndUp ? '100px' : '75px'" src="./assets/pcoip-client-logo.png">
				</template>
				<template v-slot:default>
					<div class="content-container">
						<div class="section-title">
							PCoIP Client
						</div>
						<div>
							This is the client that you downloaded that allows you to connect to the server we launched for you.
							<br /><br />
							Go to the <span style="color: var(--primary-blue); cursor: pointer;" @click="goToDownload">Download</span> tab if you don't have the client to connect to the launched server yet.
						</div>
					</div>
				</template>
			</list-item>
		</v-sheet>
	</div>
</template>

<script>
import Vue from 'vue';
import ListItem from '../../base/ListItem';

export default Vue.extend({
	name: 'DreamBoothLaunched',
	methods: {
		goToDownload () {
			this.$emit('go-to-download');
		},
	},
	components: {
		ListItem,
	},
});
</script>

<style scoped>
	.section-title {
		font-size: 22px;
		margin: 0 0 20px 0;
		font-weight: bold;
		color: var(--grey-700);
	}
	.content-container {
		padding: 0 0 0 30px;
		display: flex;
		flex-direction: column;
	}
</style>
