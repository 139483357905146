import { Route } from 'vue-router/types/router';
import { User } from '@run-diffusion/shared';
import firebase from 'firebase/app';

export const shouldRedirectToLogin: Function = (route: Route, user: User): boolean => {
	return !!(
		route &&
		!user &&
		!route.meta.isLoginPage
	);
};

export const shouldRedirectToHome: Function = (route: Route, user: User): boolean => {
	return !!(
		route &&
		user &&
		route.meta.isInternalAdminPage &&
		!user.isAdmin
	);
};

export const shouldRedirectToUserNotActivated: Function = (route: Route, user: User): boolean => {
	return !!(
		route &&
		user &&
		(
			route.meta.isSessionPage ||
			route.meta.preventLaunchSession ||
			route.meta.isInternalAdminPage
		) &&
		!user.activatedAt
	);
};

export const shouldRedirectFromUserNotActivated: Function = (route: Route, user: User): boolean => {
	return !!(
		route &&
		user &&
		route.name === 'UserNotActivated' &&
		user.activatedAt
	);
};

export const shouldRedirectToEmailNotVerified: Function = (route: Route, authUser: firebase.User): boolean => {
	return !!(
		route &&
		authUser &&
		route.meta.needsEmailVerification &&
		!authUser.emailVerified
	);
};

export const shouldRedirectFromEmailNotVerified: Function = (route: Route, authUser: firebase.User): boolean => {
	return !!(
		route &&
		authUser &&
		route.name === 'EmailVerification' &&
		authUser.emailVerified
	);
};
