
import Vue from 'vue';

export default Vue.extend({
	props: {
		iconName: {
			type: String,
			default: 'Runnits Logo Mark',
		},
		width: {
			type: [Number, String],
			default: 663,
		},
		height: {
			type: [Number, String],
			default: 481,
		},
		viewBox: {
			type: String,
			default: '0 0 663 481',
		},
		fill: {
			type: String,
			default: 'white',
		},
	},
});
