
import Vue from 'vue';
import ClipboardCopy from '@/components/ClipboardCopy.vue';

export default Vue.extend({
	name: 'WorkshopSessionLinkDialog',
	props: {
		workshop: { type: Object, default: null },
		workshopSession: { type: Object, default: null },
	},
	data () {
		return {
			dialogOpen: false,
			linkCopied: false,
		};
	},
	watch: {
		dialogOpen: {
			immediate: true,
			handler (newVal: boolean) {
				if (newVal) {
					this.linkCopied = false;
				}
			},
		},
	},
	computed: {
		workshopSessionInviteUrl () {
			const routeData = this.$router.resolve({
				name: 'WorkshopWaitingLobby',
				params: {
					workshopId: this.workshop.id,
					workshopSessionId: this.workshopSession.id,
				},
			});
			return `${location.origin}${routeData.href}`;
		},
		previewWaitingLobbyLinkHref () {
			const routeData = this.$router.resolve({
				name: 'WorkshopWaitingLobby',
				params: {
					workshopId: this.workshop.id,
					workshopSessionId: this.workshopSession.id,
				},
				query: {
					preview: '1',
				},
			});
			return routeData.href;
		},
	},
	methods: {
		onCancel () {
			this.dialogOpen = false;
		},
	},
	components: {
		ClipboardCopy,
	},
});
