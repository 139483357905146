<template>
	<div class="d-flex flex-column full-width">
		<slot name="intro"></slot>
		<instructions-step
			color="#6f86ff"
			step-number="1"
			class="mt-6"
		>
			<div>
				You will need to first download and install the <b>Teradici PCoIPClient</b> in order to connect to the server. <b>Teradici PCoIPClient</b> is a remote-desktop client that will allow you a virtual window into your server.
			</div>
			<div class="mt-6">
				<a href="https://docs.teradici.com/find/product/software-and-mobile-clients" target="_blank">Go download Teradici PCoIPClient</a>
			</div>
			<div class="d-flex mt-8 full-width">
				<img style="border-radius: 12px" :width="$vuetify.breakpoint.smAndUp ? '450px' : '350px'" src="./assets/download-teradici-step-1.png">
			</div>
		</instructions-step>
		<instructions-step
			color="#ff7586"
			step-number="2"
			class="mt-10"
		>
			<div>
				Find and Run the <b>PCoIPClient</b>
			</div>
			<div class="d-flex mt-8 full-width">
				<img style="border-radius: 12px" src="./assets/download-teradici-PCoIPClient-icon.png">
			</div>
		</instructions-step>
		<instructions-step
			color="#b372ce"
			step-number="3"
			class="mt-10"
		>
			<div>
				You will need to allow the accessibility settings to be able to use the app correctly
			</div>
			<div class="d-flex mt-8 full-width">
				<img style="border-radius: 12px" :width="$vuetify.breakpoint.smAndUp ? '450px' : '350px'" src="./assets/download-teradici-step-3.png">
			</div>
		</instructions-step>
		<instructions-step
			color="#6f86ff"
			step-number="4"
			class="mt-10 mb-4"
		>
			<div>
				Grant the <b>PCoIPClient</b> access in order to connect to the server correctly.
			</div>
			<div class="d-flex mt-8 full-width">
				<img style="border-radius: 12px" :width="$vuetify.breakpoint.smAndUp ? '450px' : '350px'" src="./assets/download-teradici-step-4.png">
			</div>
		</instructions-step>
	</div>
</template>

<script>
import Vue from 'vue';
import InstructionsStep from '@/components/InstructionsStep';

export default Vue.extend({
	name: 'DreamBoothPreLaunch',
	data () {
		return {
			stepsCompleteConfirm: false,
		};
	},
	components: {
		InstructionsStep,
	},
});
</script>

<style scoped lang="scss">
	.content-container {
		padding: 0 0 0 26px;
		display: flex;
		flex-direction: column;
	}
</style>
