
import Vue from 'vue';
import ListItem from '@/components/base/ListItem.vue';
import BackButton from '@/components/base/BackButton.vue';
import StackLayers from '@/components/base/StackLayers.vue';
import EditTeamDialog from '@/views/Team/EditTeamDialog.vue';
import { mapState } from 'vuex';
import { TeamUserMixin } from '@/mixins';
import TeamPicker from '@/components/TeamPicker.vue';
import _isEmpty from 'lodash/isEmpty';

export default Vue.extend({
	name: 'TeamPlanSignup',
	mixins: [
		TeamUserMixin,
	],
	data () {
		return {
			editTeamDialogOpen: false,
		};
	},
	computed: {
		...mapState([
			'team',
			'user',
		]),
	},
	methods: {
		_isEmpty,
		handleSignUp () {
			this.editTeamDialogOpen = true;
		},
	},
	components: {
		TeamPicker,
		EditTeamDialog,
		StackLayers,
		BackButton,
		ListItem,
	},
});
