import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticStyle:{"position":"relative"}},[_c(VExpandTransition,[(hover && _vm.hasOverflow && !_vm.omitTopArrow && !_vm.atStart)?_c('div',{staticClass:"transition-fast-in-fast-out hovering-arrow",staticStyle:{"top":"16px"}},[_c(VIcon,{staticClass:"white--text",staticStyle:{"font-size":"18px"}},[_vm._v("mdi-arrow-up")])],1):_vm._e()]),_c('div',{ref:"scrollContainer",staticClass:"scrollable",class:{
				'scroll-border-bottom': !_vm.omitBottomArrow && !_vm.removeShadow && (!hover || !_vm.hasOverflow || _vm.atEnd),
				'scroll-border-top': !_vm.omitTopArrow && !_vm.removeShadow && (!hover || !_vm.hasOverflow || _vm.atStart),
				'hovering-inset-bottom-shadow-only': _vm.showHoveringBottomShadow(hover) && !_vm.showHoveringTopShadow(hover),
				'hovering-inset-top-shadow-only': !_vm.showHoveringBottomShadow(hover) && _vm.showHoveringTopShadow(hover),
				'hovering-inset-bottom-and-top-shadow': _vm.showHoveringBottomShadow(hover) && _vm.showHoveringTopShadow(hover),
			},style:({
				borderBottom: _vm.showHoveringBottomShadow(hover) && !_vm.showHoveringTopShadow(hover) ? '1px solid rgba(46, 59, 80, 0.58)' : 'none',
				borderTop: !_vm.showHoveringBottomShadow(hover) && _vm.showHoveringTopShadow(hover) ? '1px solid rgba(46, 59, 80, 0.58)' : 'none',
			}),attrs:{"id":_vm.scrollRootId},on:{"mouseover":_vm.onScrollContainerMouseOver}},[_vm._t("scrollable")],2),_c(VExpandTransition,[(hover && _vm.hasOverflow && !_vm.omitBottomArrow && !_vm.atEnd)?_c('div',{staticClass:"transition-fast-in-fast-out hovering-arrow",staticStyle:{"bottom":"0"}},[_c(VIcon,{staticClass:"white--text",staticStyle:{"font-size":"18px"}},[_vm._v("mdi-arrow-down")])],1):_vm._e()])],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }