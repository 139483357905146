// Last exported 01/08/2024
export const localReportData =[{
	"teamName": "Playtika - BB",
	"email": "adamm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.045",
	"createdAt": "2023-12-13 00:50:00.536000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "adamm@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.07",
	"createdAt": "2023-12-06 08:04:32.086000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "adamm@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.678",
	"createdAt": "2023-12-06 09:42:44.227000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "adamm@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.013",
	"createdAt": "2023-12-06 10:26:43.463000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.823",
	"createdAt": "2024-02-02 09:25:04.387000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.089",
	"createdAt": "2024-02-01 09:16:50.935000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.017",
	"createdAt": "2024-02-05 08:48:50.509000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.549",
	"createdAt": "2024-02-05 10:25:10.795000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.164",
	"createdAt": "2024-02-05 12:37:56.616000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.142",
	"createdAt": "2024-02-05 14:09:16.021000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.162",
	"createdAt": "2024-02-06 10:31:51.694000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.333",
	"createdAt": "2024-02-15 08:55:48.026000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.765",
	"createdAt": "2024-02-29 09:55:39.912000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.366",
	"createdAt": "2024-02-29 15:04:15.506000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.062",
	"createdAt": "2024-03-01 12:45:22.792000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.315",
	"createdAt": "2024-03-04 12:53:14.490000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.17",
	"createdAt": "2024-03-04 13:31:46.475000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.798",
	"createdAt": "2024-03-04 14:23:05.701000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.232",
	"createdAt": "2024-03-18 11:10:40.944000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.213",
	"createdAt": "2024-03-19 11:28:11.679000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.542",
	"createdAt": "2024-03-21 14:24:16.725000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.147",
	"createdAt": "2024-03-25 09:23:44.593000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.327",
	"createdAt": "2024-03-26 09:14:39.804000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.247",
	"createdAt": "2024-03-27 10:05:08.961000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.452",
	"createdAt": "2024-03-29 10:17:28.039000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.453",
	"createdAt": "2024-04-02 09:11:23.820000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.161",
	"createdAt": "2024-04-04 11:17:21.158000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "5.127",
	"createdAt": "2024-04-08 09:02:24.379000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.24",
	"createdAt": "2024-04-09 07:48:01.487000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.183",
	"createdAt": "2024-04-10 07:46:54.660000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.107",
	"createdAt": "2024-04-17 13:21:06.634000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.811",
	"createdAt": "2024-04-18 08:50:49.217000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.794",
	"createdAt": "2024-04-30 08:21:13.563000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.281",
	"createdAt": "2024-04-30 11:54:20.468000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.348",
	"createdAt": "2024-05-08 13:11:08.218000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.403",
	"createdAt": "2024-05-15 09:15:00.855000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.574",
	"createdAt": "2024-05-15 10:48:36.390000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.407",
	"createdAt": "2024-05-15 12:38:42.533000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.195",
	"createdAt": "2024-05-16 10:36:20.349000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.14",
	"createdAt": "2024-05-16 10:49:47.076000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.194",
	"createdAt": "2024-05-16 14:37:22.272000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "aleksandrar@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.878",
	"createdAt": "2024-05-17 07:23:47.500000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-01-08 09:27:05.001000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.009",
	"createdAt": "2024-01-08 12:16:26.826000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-01-08 14:05:36.726000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.003",
	"createdAt": "2024-01-11 13:00:59.811000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.024",
	"createdAt": "2024-01-24 14:25:48.377000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-24 14:29:46.270000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.831",
	"createdAt": "2024-01-25 08:57:10.835000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.808",
	"createdAt": "2024-01-25 10:05:31.392000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.017",
	"createdAt": "2024-01-25 10:57:06.349000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.311",
	"createdAt": "2024-01-30 11:49:23.147000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-01-30 12:10:18.517000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.0",
	"createdAt": "2024-02-06 08:41:07.845000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.863",
	"createdAt": "2024-02-07 12:47:55.936000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.015",
	"createdAt": "2024-02-07 13:42:11.503000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.791",
	"createdAt": "2024-02-15 13:00:59.151000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.425",
	"createdAt": "2024-02-16 07:43:18.155000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.762",
	"createdAt": "2024-02-28 13:58:38.742000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.633",
	"createdAt": "2024-03-05 09:41:17.271000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.063",
	"createdAt": "2024-03-05 11:50:46.576000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.36",
	"createdAt": "2024-03-05 11:57:00.671000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.473",
	"createdAt": "2024-03-05 12:20:47.408000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.208",
	"createdAt": "2024-03-05 15:21:32.185000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.085",
	"createdAt": "2024-03-06 08:13:02.773000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.84",
	"createdAt": "2024-03-07 13:17:50.308000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.69",
	"createdAt": "2024-03-11 14:10:11.359000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.032",
	"createdAt": "2024-03-12 12:38:19.200000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.848",
	"createdAt": "2024-03-14 11:56:52.226000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.165",
	"createdAt": "2024-03-28 08:13:51.225000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.082",
	"createdAt": "2024-04-11 09:13:14.206000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.183",
	"createdAt": "2024-04-12 08:23:42.776000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.75",
	"createdAt": "2024-04-12 08:37:09.674000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alenad@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.413",
	"createdAt": "2024-04-26 14:30:06.846000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.068",
	"createdAt": "2024-02-02 09:24:56.856000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.928",
	"createdAt": "2024-02-04 09:41:27.536000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.309",
	"createdAt": "2024-02-04 10:39:20.546000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.491",
	"createdAt": "2024-02-05 08:40:00.988000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.189",
	"createdAt": "2024-02-05 10:26:05.840000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.9",
	"createdAt": "2024-02-07 15:10:32.865000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.959",
	"createdAt": "2024-02-09 12:18:28.417000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.99",
	"createdAt": "2024-02-13 12:14:11.138000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.484",
	"createdAt": "2024-02-16 14:59:34.976000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.763",
	"createdAt": "2024-02-17 08:49:38.648000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.393",
	"createdAt": "2024-02-21 07:33:55.714000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.955",
	"createdAt": "2024-03-08 08:29:54.692000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.011",
	"createdAt": "2024-04-22 13:19:30.763000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.262",
	"createdAt": "2024-04-25 08:36:36.830000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.95",
	"createdAt": "2024-04-25 11:13:40.997000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.655",
	"createdAt": "2024-04-25 14:42:44.375000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.795",
	"createdAt": "2024-05-02 09:43:07.886000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.638",
	"createdAt": "2024-05-03 08:37:01.689000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.535",
	"createdAt": "2024-05-03 14:02:10.054000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.632",
	"createdAt": "2024-05-05 09:52:21.174000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.465",
	"createdAt": "2024-05-06 10:16:00.343000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.073",
	"createdAt": "2024-01-04 11:40:03.412000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.072",
	"createdAt": "2024-01-04 16:41:01.961000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.435",
	"createdAt": "2024-01-11 08:09:59.020000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.969",
	"createdAt": "2024-01-12 13:09:40.052000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.63",
	"createdAt": "2024-01-12 14:44:04.398000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-01-15 17:17:59.698000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.141",
	"createdAt": "2024-01-25 10:05:45.961000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.725",
	"createdAt": "2024-01-25 10:46:37.713000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.15",
	"createdAt": "2024-01-25 11:35:11.975000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-01-25 13:49:31.090000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.005",
	"createdAt": "2024-01-25 15:26:32.426000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-26 07:46:56.172000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.187",
	"createdAt": "2024-01-26 11:25:50.305000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.97",
	"createdAt": "2024-01-26 14:07:01.619000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.188",
	"createdAt": "2024-01-26 15:11:58.041000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.309",
	"createdAt": "2024-01-26 15:31:48.065000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.014",
	"createdAt": "2024-01-26 17:13:32.404000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.867",
	"createdAt": "2024-01-26 17:21:21.626000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.867",
	"createdAt": "2024-01-27 11:08:56.137000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.651",
	"createdAt": "2024-02-01 09:16:11.628000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-01 11:09:10.020000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.645",
	"createdAt": "2024-02-02 14:09:20.766000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.111",
	"createdAt": "2024-02-02 16:08:41.942000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.824",
	"createdAt": "2024-02-06 12:56:41.091000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.133",
	"createdAt": "2024-02-06 16:30:29.134000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-02-07 08:32:36.024000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.051",
	"createdAt": "2024-02-09 13:59:56.676000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.036",
	"createdAt": "2024-02-09 14:06:12.644000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-13 11:33:38.063000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.014",
	"createdAt": "2024-02-13 17:05:12.664000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-16 14:37:09.222000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.312",
	"createdAt": "2024-02-23 14:39:40.559000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.537",
	"createdAt": "2024-03-01 13:56:40.371000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.139",
	"createdAt": "2024-04-10 07:19:50.229000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.564",
	"createdAt": "2024-04-10 09:19:48.496000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.374",
	"createdAt": "2024-04-12 10:27:50.746000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.236",
	"createdAt": "2024-04-19 08:47:13.540000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.467",
	"createdAt": "2024-04-19 13:00:52.044000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.255",
	"createdAt": "2024-05-08 09:43:19.613000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.536",
	"createdAt": "2024-05-08 11:30:37.710000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.245",
	"createdAt": "2024-05-20 14:51:10.078000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.161",
	"createdAt": "2024-05-29 11:35:32.717000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.208",
	"createdAt": "2024-02-02 13:51:34.429000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.202",
	"createdAt": "2024-05-20 14:37:33.952000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "KOYA-001",
	"hardware": "MD-001",
	"sumHours": "0.021",
	"createdAt": "2024-02-01 09:57:51.198000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "KOYA-001",
	"hardware": "MD-001",
	"sumHours": "0.205",
	"createdAt": "2024-02-01 10:02:49.764000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alexeyma@playtika.com",
	"app": "MKYO-001",
	"hardware": "MD-001",
	"sumHours": "0.012",
	"createdAt": "2024-02-02 14:07:22.175000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.045",
	"createdAt": "2024-01-04 11:39:32.257000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.258",
	"createdAt": "2024-01-09 09:02:13.359000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.29",
	"createdAt": "2024-01-09 09:31:22.102000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.132",
	"createdAt": "2024-01-09 10:09:24.601000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.146",
	"createdAt": "2024-01-09 10:45:46.730000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.163",
	"createdAt": "2024-01-09 10:58:51.458000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.561",
	"createdAt": "2024-01-09 11:50:23.425000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.61",
	"createdAt": "2024-01-09 12:30:21.714000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.184",
	"createdAt": "2024-01-09 15:53:14.371000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.091",
	"createdAt": "2024-01-10 11:27:20.872000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.589",
	"createdAt": "2024-01-10 11:50:43.685000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.134",
	"createdAt": "2024-01-10 12:38:40.190000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.581",
	"createdAt": "2024-01-10 13:58:07.248000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.756",
	"createdAt": "2024-01-10 15:04:33.950000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.366",
	"createdAt": "2024-01-15 11:20:45.152000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.416",
	"createdAt": "2024-01-15 12:14:57.388000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.16",
	"createdAt": "2024-01-15 13:05:15.169000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.286",
	"createdAt": "2024-01-15 13:40:40.706000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.28",
	"createdAt": "2024-01-16 09:13:26.466000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.606",
	"createdAt": "2024-01-17 09:55:51.016000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.365",
	"createdAt": "2024-01-17 10:59:18.785000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.072",
	"createdAt": "2024-01-17 12:53:58.671000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.578",
	"createdAt": "2024-01-18 09:49:10.437000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.118",
	"createdAt": "2024-01-18 12:03:40.114000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.368",
	"createdAt": "2024-01-18 14:43:56.675000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.599",
	"createdAt": "2024-01-25 15:35:38.160000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.258",
	"createdAt": "2024-01-26 09:30:34.065000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.132",
	"createdAt": "2024-01-26 11:09:19.548000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.471",
	"createdAt": "2024-01-26 13:50:29.037000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.243",
	"createdAt": "2024-01-26 14:39:08.503000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.326",
	"createdAt": "2024-01-26 19:54:00.612000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.541",
	"createdAt": "2024-01-29 12:08:31.679000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.588",
	"createdAt": "2024-01-29 14:22:48.241000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.228",
	"createdAt": "2024-01-30 11:06:33.264000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.535",
	"createdAt": "2024-01-30 12:18:05.271000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.626",
	"createdAt": "2024-01-30 16:51:08.984000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.546",
	"createdAt": "2024-01-31 12:59:35.057000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.319",
	"createdAt": "2024-01-31 13:36:56.049000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.343",
	"createdAt": "2024-02-01 08:24:47.330000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.35",
	"createdAt": "2024-02-01 09:49:50.863000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.846",
	"createdAt": "2024-02-01 14:09:44.287000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.296",
	"createdAt": "2024-02-02 09:40:44.847000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.153",
	"createdAt": "2024-02-02 11:58:19.036000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.262",
	"createdAt": "2024-02-05 14:36:51.584000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.682",
	"createdAt": "2024-02-05 19:28:18.521000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.069",
	"createdAt": "2024-02-06 10:41:44.747000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.222",
	"createdAt": "2024-02-10 15:10:09.529000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.339",
	"createdAt": "2024-02-13 20:44:16.436000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.481",
	"createdAt": "2024-02-15 12:32:26.729000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.151",
	"createdAt": "2024-02-16 17:32:16.290000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.397",
	"createdAt": "2024-02-20 09:02:22.114000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.986",
	"createdAt": "2024-02-21 10:15:47.141000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.15",
	"createdAt": "2024-02-22 08:17:03.553000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.522",
	"createdAt": "2024-02-23 10:51:02.827000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.764",
	"createdAt": "2024-02-29 19:45:45.151000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.542",
	"createdAt": "2024-03-04 20:38:57.192000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.273",
	"createdAt": "2024-04-02 14:18:04.703000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.623",
	"createdAt": "2024-04-03 08:18:30.856000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.343",
	"createdAt": "2024-04-15 13:13:20.702000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.364",
	"createdAt": "2024-04-16 15:21:00.138000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.013",
	"createdAt": "2024-04-18 12:47:24.450000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.623",
	"createdAt": "2024-04-19 14:28:38.109000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.198",
	"createdAt": "2024-04-22 09:36:41.199000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.557",
	"createdAt": "2024-04-22 11:21:09.516000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.741",
	"createdAt": "2024-04-24 12:35:33.749000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.296",
	"createdAt": "2024-05-07 18:34:31.532000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.095",
	"createdAt": "2024-05-08 09:52:11.583000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.393",
	"createdAt": "2024-05-08 11:03:15.522000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.208",
	"createdAt": "2024-05-08 11:31:30.352000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.097",
	"createdAt": "2024-05-08 15:29:57.395000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.221",
	"createdAt": "2024-05-14 10:10:29.803000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.0",
	"createdAt": "2024-05-14 19:52:37.925000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.772",
	"createdAt": "2024-05-15 13:09:27.697000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-15 15:45:24.056000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.725",
	"createdAt": "2024-05-20 17:03:40.764000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "alinaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.58",
	"createdAt": "2024-05-28 15:15:41.835000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anastasiiakh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.255",
	"createdAt": "2024-05-14 10:10:27.694000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anastasiiakh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.792",
	"createdAt": "2024-05-14 11:01:45.513000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anastasiiakh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.025",
	"createdAt": "2024-05-14 11:51:15.178000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anastasiiakh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.746",
	"createdAt": "2024-05-15 08:39:26.852000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anastasiiakh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-21 14:15:49.307000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anastasiiakh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-28 15:05:58.159000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.858",
	"createdAt": "2024-01-24 15:13:55.801000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.033",
	"createdAt": "2024-01-25 10:06:49.951000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.057",
	"createdAt": "2024-01-25 10:11:28.332000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-01-25 15:35:04.623000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.855",
	"createdAt": "2024-01-26 14:15:23.134000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.378",
	"createdAt": "2024-01-26 15:31:19.629000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.895",
	"createdAt": "2024-02-02 13:15:12.766000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.445",
	"createdAt": "2024-02-02 14:20:09.312000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.353",
	"createdAt": "2024-02-02 20:32:24.161000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.255",
	"createdAt": "2024-02-05 12:07:21.875000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.008",
	"createdAt": "2024-02-06 08:54:36.733000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.883",
	"createdAt": "2024-02-08 12:20:50.259000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.29",
	"createdAt": "2024-02-08 13:38:14.699000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.501",
	"createdAt": "2024-02-09 12:30:40.840000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.334",
	"createdAt": "2024-02-09 14:36:58.492000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.498",
	"createdAt": "2024-02-12 19:50:12.541000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.191",
	"createdAt": "2024-02-14 12:16:42.498000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.56",
	"createdAt": "2024-02-14 12:30:53.174000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.014",
	"createdAt": "2024-02-16 09:03:22.470000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.35",
	"createdAt": "2024-02-16 09:06:35.590000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.248",
	"createdAt": "2024-02-16 12:03:04.836000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.484",
	"createdAt": "2024-02-16 12:51:49.396000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.399",
	"createdAt": "2024-02-20 08:24:31.998000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.544",
	"createdAt": "2024-02-20 09:42:34.047000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.003",
	"createdAt": "2024-02-20 12:59:23.615000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.455",
	"createdAt": "2024-02-20 13:59:51.288000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.422",
	"createdAt": "2024-02-20 15:44:49.925000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.756",
	"createdAt": "2024-02-20 16:48:20.123000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.319",
	"createdAt": "2024-02-21 11:12:48.431000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.252",
	"createdAt": "2024-02-21 11:36:36.236000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.18",
	"createdAt": "2024-02-21 13:37:13.644000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-02-21 13:58:01.464000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.26",
	"createdAt": "2024-02-21 20:16:19.797000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.068",
	"createdAt": "2024-02-22 08:36:40.257000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.25",
	"createdAt": "2024-02-22 10:05:37.033000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.58",
	"createdAt": "2024-02-22 13:54:55.100000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.926",
	"createdAt": "2024-02-22 15:20:01.353000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.55",
	"createdAt": "2024-02-22 17:43:06.074000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.444",
	"createdAt": "2024-02-22 21:01:00.640000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.836",
	"createdAt": "2024-02-22 21:51:29.966000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.592",
	"createdAt": "2024-02-23 08:29:42.763000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.733",
	"createdAt": "2024-02-23 09:43:47.768000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.891",
	"createdAt": "2024-02-23 12:29:38.839000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.09",
	"createdAt": "2024-02-23 13:32:23.421000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.549",
	"createdAt": "2024-02-23 13:58:36.491000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.64",
	"createdAt": "2024-02-23 16:51:25.643000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.705",
	"createdAt": "2024-02-26 14:58:12.479000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.247",
	"createdAt": "2024-02-26 20:53:43.327000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.673",
	"createdAt": "2024-02-27 09:42:33.879000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.65",
	"createdAt": "2024-02-27 10:54:24.131000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.417",
	"createdAt": "2024-02-27 12:54:07.081000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.573",
	"createdAt": "2024-02-28 13:49:19.040000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.006",
	"createdAt": "2024-02-28 14:56:39.389000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.306",
	"createdAt": "2024-02-29 07:57:39.753000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.406",
	"createdAt": "2024-02-29 14:38:07.285000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.133",
	"createdAt": "2024-02-29 15:39:23.982000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.234",
	"createdAt": "2024-03-04 12:30:05.653000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.297",
	"createdAt": "2024-03-04 13:26:56.700000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.488",
	"createdAt": "2024-03-05 13:38:46.033000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.476",
	"createdAt": "2024-03-05 16:17:52.757000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.115",
	"createdAt": "2024-03-05 20:20:03.371000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.132",
	"createdAt": "2024-03-06 13:09:17.346000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.117",
	"createdAt": "2024-03-07 15:55:58.766000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.274",
	"createdAt": "2024-03-08 14:50:36.416000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.459",
	"createdAt": "2024-03-08 16:01:48.693000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.642",
	"createdAt": "2024-03-09 11:56:38.692000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.122",
	"createdAt": "2024-03-12 15:37:43.934000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.142",
	"createdAt": "2024-03-13 12:16:21.322000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.346",
	"createdAt": "2024-03-13 14:19:56.567000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.679",
	"createdAt": "2024-03-13 15:55:54.930000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.158",
	"createdAt": "2024-03-14 09:31:52.925000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.693",
	"createdAt": "2024-03-19 08:16:18.104000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.529",
	"createdAt": "2024-03-19 09:16:20.778000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.26",
	"createdAt": "2024-03-19 12:57:25.489000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.505",
	"createdAt": "2024-03-22 12:36:17.409000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.986",
	"createdAt": "2024-03-22 14:01:46.183000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.811",
	"createdAt": "2024-03-22 15:08:19.739000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.017",
	"createdAt": "2024-03-22 16:00:42.401000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.594",
	"createdAt": "2024-03-25 12:58:40.532000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.32",
	"createdAt": "2024-03-26 14:16:21.298000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.457",
	"createdAt": "2024-03-26 14:56:09.386000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.182",
	"createdAt": "2024-04-02 18:14:50.773000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.025",
	"createdAt": "2024-04-02 18:49:45.194000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.181",
	"createdAt": "2024-04-02 18:59:11.149000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.523",
	"createdAt": "2024-04-05 18:23:22.426000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.028",
	"createdAt": "2024-04-08 13:57:33.812000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.27",
	"createdAt": "2024-04-08 14:01:11.053000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.538",
	"createdAt": "2024-04-17 12:02:07.297000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.03",
	"createdAt": "2024-04-18 09:31:17.050000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-04-18 17:54:25.585000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.45",
	"createdAt": "2024-04-18 19:38:47.070000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.722",
	"createdAt": "2024-04-19 13:49:30.037000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.101",
	"createdAt": "2024-04-22 08:19:02.835000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.768",
	"createdAt": "2024-04-22 15:59:07.236000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.689",
	"createdAt": "2024-04-22 18:43:23.726000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.354",
	"createdAt": "2024-04-22 20:43:08.927000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.365",
	"createdAt": "2024-04-23 12:36:03.575000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.317",
	"createdAt": "2024-04-23 13:38:11.311000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.153",
	"createdAt": "2024-04-25 17:23:56.596000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.454",
	"createdAt": "2024-05-16 19:20:11.850000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.268",
	"createdAt": "2024-05-16 20:12:00.672000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.722",
	"createdAt": "2024-05-19 17:59:42.416000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.665",
	"createdAt": "2024-05-23 08:37:26.817000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.383",
	"createdAt": "2024-05-28 08:00:23.304000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.335",
	"createdAt": "2024-05-29 11:02:33.432000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.741",
	"createdAt": "2024-02-02 09:24:45.358000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.518",
	"createdAt": "2024-02-04 16:24:30.904000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.346",
	"createdAt": "2024-02-14 14:31:34.602000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.084",
	"createdAt": "2024-02-14 15:33:48.242000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.656",
	"createdAt": "2024-02-14 15:46:57.312000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.8",
	"createdAt": "2024-02-14 16:37:59.396000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.94",
	"createdAt": "2024-02-14 18:27:52.927000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.016",
	"createdAt": "2024-02-15 12:38:21.468000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.632",
	"createdAt": "2024-02-26 11:05:01.138000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.678",
	"createdAt": "2024-02-26 14:28:01.403000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.016",
	"createdAt": "2024-02-29 20:43:48.639000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.786",
	"createdAt": "2024-03-01 01:38:07.526000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.009",
	"createdAt": "2024-03-01 02:27:20.666000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.031",
	"createdAt": "2024-03-01 02:30:36.895000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.058",
	"createdAt": "2024-03-01 02:35:45.887000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.714",
	"createdAt": "2024-03-01 16:13:40.255000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.829",
	"createdAt": "2024-03-02 03:01:38.156000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.223",
	"createdAt": "2024-03-03 00:59:42.039000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.89",
	"createdAt": "2024-03-03 23:21:11.566000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.141",
	"createdAt": "2024-03-04 13:29:59.793000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.307",
	"createdAt": "2024-03-05 00:00:33.703000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.054",
	"createdAt": "2024-03-05 16:38:19.079000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.439",
	"createdAt": "2024-03-05 21:28:35.890000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.297",
	"createdAt": "2024-03-06 10:04:14.108000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.095",
	"createdAt": "2024-03-06 10:55:45.955000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.898",
	"createdAt": "2024-03-09 17:26:55.854000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.662",
	"createdAt": "2024-03-10 15:16:45.831000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.851",
	"createdAt": "2024-03-10 23:41:42.021000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.326",
	"createdAt": "2024-03-12 11:41:21.621000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.411",
	"createdAt": "2024-03-28 15:38:27.794000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.735",
	"createdAt": "2024-03-29 11:47:56.957000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.02",
	"createdAt": "2024-04-03 17:22:45.259000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.859",
	"createdAt": "2024-05-01 16:12:33.364000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.017",
	"createdAt": "2024-05-28 09:57:40.516000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.214",
	"createdAt": "2024-02-02 18:45:16.831000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.991",
	"createdAt": "2024-02-02 20:01:17.326000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.974",
	"createdAt": "2024-02-05 15:16:01.818000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.01",
	"createdAt": "2024-02-14 14:27:57.221000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.063",
	"createdAt": "2024-02-15 12:55:33.983000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.738",
	"createdAt": "2024-02-15 16:14:00.199000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.977",
	"createdAt": "2024-02-22 13:39:16.768000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.023",
	"createdAt": "2024-02-26 15:32:51.517000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.898",
	"createdAt": "2024-02-26 15:37:13.998000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.336",
	"createdAt": "2024-02-26 21:33:19.734000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.877",
	"createdAt": "2024-03-06 12:31:33.408000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.663",
	"createdAt": "2024-03-07 15:20:25.960000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.294",
	"createdAt": "2024-03-07 17:42:01.483000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.451",
	"createdAt": "2024-03-12 12:25:33.414000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.006",
	"createdAt": "2024-03-12 16:56:30.893000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.133",
	"createdAt": "2024-03-14 15:11:59.562000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.097",
	"createdAt": "2024-03-07 18:19:35.983000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.314",
	"createdAt": "2024-03-07 18:48:28.136000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.154",
	"createdAt": "2024-03-07 19:10:36.405000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.603",
	"createdAt": "2024-03-04 01:17:01.701000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "andreyz@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.383",
	"createdAt": "2024-03-04 02:55:41.880000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "antonc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.961",
	"createdAt": "2024-01-26 15:09:47.697000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "antonc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.341",
	"createdAt": "2024-01-29 10:47:07.901000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-18 12:49:44.647000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.005",
	"createdAt": "2024-02-18 16:22:57.104000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-19 09:41:06.434000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-19 12:04:39.750000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-23 10:03:49.866000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-02-23 16:29:31.752000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-02-23 16:43:09.549000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-02-26 14:15:29.936000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.93",
	"createdAt": "2024-02-26 14:23:05.817000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-26 16:31:59.082000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-27 12:42:56.782000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.171",
	"createdAt": "2024-02-27 15:37:32.596000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-02-28 13:54:51.661000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-28 16:56:36.258000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.568",
	"createdAt": "2024-02-28 19:04:13.307000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-29 10:23:04.101000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.348",
	"createdAt": "2024-02-29 13:58:21.794000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.135",
	"createdAt": "2024-03-01 14:09:17.019000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.086",
	"createdAt": "2024-03-05 09:19:04.872000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-05 14:00:42.765000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.381",
	"createdAt": "2024-03-06 17:54:25.087000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.261",
	"createdAt": "2024-03-11 19:35:55.068000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.297",
	"createdAt": "2024-03-12 14:24:57.313000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.668",
	"createdAt": "2024-03-13 12:19:03.032000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-13 19:56:32.437000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-14 08:26:09.657000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.008",
	"createdAt": "2024-03-14 11:34:47.983000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-15 08:59:23.598000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-17 12:04:39.979000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.007",
	"createdAt": "2024-03-17 14:12:48.477000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.126",
	"createdAt": "2024-03-18 10:21:43.238000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.927",
	"createdAt": "2024-03-18 11:32:57.863000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-18 13:30:51.294000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-18 16:30:53.679000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-03-19 09:17:25.620000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.265",
	"createdAt": "2024-03-19 13:38:46.702000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-03-19 15:56:25.374000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-19 18:28:39.844000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.13",
	"createdAt": "2024-03-19 18:31:47.004000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.013",
	"createdAt": "2024-03-20 09:34:20.644000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.012",
	"createdAt": "2024-03-20 15:03:22.475000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.101",
	"createdAt": "2024-03-20 19:18:51.323000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.029",
	"createdAt": "2024-03-20 20:27:02.868000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.738",
	"createdAt": "2024-03-20 20:32:08.873000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.1",
	"createdAt": "2024-03-21 08:59:40.094000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-21 09:07:48.479000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.32",
	"createdAt": "2024-03-21 09:23:35.208000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.865",
	"createdAt": "2024-03-21 12:48:58.233000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-22 08:56:22.113000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.346",
	"createdAt": "2024-03-22 11:23:43.307000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.004",
	"createdAt": "2024-03-22 11:46:30.552000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.974",
	"createdAt": "2024-03-22 16:12:45.064000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.109",
	"createdAt": "2024-03-27 13:14:58.842000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.009",
	"createdAt": "2024-03-27 13:23:36.398000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-02 15:42:59.587000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-02 17:52:10.466000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-03 08:17:49.748000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.011",
	"createdAt": "2024-04-03 08:23:54.158000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.003",
	"createdAt": "2024-04-04 08:44:56.354000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-04 15:47:38.517000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.009",
	"createdAt": "2024-04-05 09:37:35.139000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.026",
	"createdAt": "2024-04-05 15:53:29.225000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-05 15:57:12.169000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.016",
	"createdAt": "2024-04-22 08:49:35.961000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-22 12:30:33.990000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.273",
	"createdAt": "2024-04-22 12:44:31.676000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.624",
	"createdAt": "2024-04-22 14:05:51.522000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.469",
	"createdAt": "2024-04-22 14:45:13.004000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-23 09:33:04.285000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-23 11:38:29.124000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.783",
	"createdAt": "2024-04-23 14:19:12.930000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.048",
	"createdAt": "2024-04-23 16:09:40.875000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-23 16:15:53.452000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-24 07:56:30.306000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.01",
	"createdAt": "2024-04-24 11:56:48.785000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-25 08:25:08.525000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.001",
	"createdAt": "2024-04-25 12:05:04.344000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.01",
	"createdAt": "2024-04-26 08:36:54.557000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-26 14:45:53.478000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.38",
	"createdAt": "2024-04-29 09:23:24.190000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-29 13:11:21.440000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-29 17:42:10.033000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-30 10:12:10.294000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-30 19:59:06.779000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-01 09:01:00.269000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "8.016",
	"createdAt": "2024-05-01 11:32:27.982000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-02 11:11:05.769000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.011",
	"createdAt": "2024-05-03 08:28:32.908000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-06 10:24:40.926000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.013",
	"createdAt": "2024-05-08 09:43:15.458000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.008",
	"createdAt": "2024-05-08 13:49:56.577000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-13 10:52:45.883000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.008",
	"createdAt": "2024-05-13 16:27:05.964000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-13 18:19:00.970000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-14 08:42:08.624000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.623",
	"createdAt": "2024-05-14 14:33:29.340000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.013",
	"createdAt": "2024-05-14 15:04:02.206000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.068",
	"createdAt": "2024-05-14 15:06:43.773000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.013",
	"createdAt": "2024-05-14 15:09:47.571000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.029",
	"createdAt": "2024-05-14 15:12:48.541000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.177",
	"createdAt": "2024-05-14 15:16:01.508000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.316",
	"createdAt": "2024-05-14 15:28:06.447000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.206",
	"createdAt": "2024-05-14 15:49:09.591000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.073",
	"createdAt": "2024-05-14 17:09:43.010000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.461",
	"createdAt": "2024-05-15 08:31:12.444000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.197",
	"createdAt": "2024-05-15 09:00:52.499000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.017",
	"createdAt": "2024-05-15 13:14:23.877000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.07",
	"createdAt": "2024-05-15 14:31:44.112000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.004",
	"createdAt": "2024-05-15 14:45:21.144000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.003",
	"createdAt": "2024-05-15 17:02:04.083000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.839",
	"createdAt": "2024-05-16 09:10:45.921000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.144",
	"createdAt": "2024-05-16 11:48:53.229000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-16 11:59:27.717000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-16 14:11:48.635000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-16 18:55:14.516000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.581",
	"createdAt": "2024-05-17 07:53:26.406000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-17 11:57:14.327000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-17 13:54:40.109000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-17 14:20:33.292000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-20 08:48:48.103000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.441",
	"createdAt": "2024-05-20 10:55:59.953000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.01",
	"createdAt": "2024-05-21 09:34:32.307000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-21 15:41:38.243000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-21 15:43:42.471000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.755",
	"createdAt": "2024-05-21 15:46:35.665000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.001",
	"createdAt": "2024-05-22 07:41:08.712000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.995",
	"createdAt": "2024-05-22 12:51:29.992000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-23 08:57:52.025000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-23 09:15:51.234000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.066",
	"createdAt": "2024-05-23 12:54:23.099000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-23 13:00:05.750000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-24 10:02:29.529000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-27 10:29:53.805000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-27 13:27:23.547000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-27 15:47:19.117000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-28 07:39:10.151000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-28 10:23:01.239000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-28 13:10:16.510000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-28 15:13:08.256000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-30 14:19:22.961000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-17 16:32:48.307000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.021",
	"createdAt": "2024-02-17 16:33:37.384000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.195",
	"createdAt": "2024-02-17 16:43:35.240000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.038",
	"createdAt": "2024-02-17 17:44:25.741000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "daryasa@playtika.com",
	"app": "FCUS-001",
	"hardware": "LG-001",
	"sumHours": "0.055",
	"createdAt": "2024-04-22 08:43:46.211000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "dashas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.818",
	"createdAt": "2024-03-05 13:07:44.828000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.368",
	"createdAt": "2024-03-11 11:48:54.242000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.359",
	"createdAt": "2024-03-11 13:40:46.092000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-11 15:45:51.202000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.39",
	"createdAt": "2024-03-12 09:20:25.514000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.294",
	"createdAt": "2024-03-12 12:53:57.378000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.734",
	"createdAt": "2024-03-12 15:22:29.316000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.389",
	"createdAt": "2024-03-13 14:35:55.114000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.695",
	"createdAt": "2024-03-14 14:45:24.392000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.125",
	"createdAt": "2024-03-14 17:14:17.593000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.112",
	"createdAt": "2024-03-17 07:26:40.510000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.456",
	"createdAt": "2024-03-17 20:21:34.532000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.119",
	"createdAt": "2024-03-18 09:28:00.281000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.692",
	"createdAt": "2024-03-19 09:11:27.025000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.695",
	"createdAt": "2024-03-19 10:24:11.263000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.926",
	"createdAt": "2024-03-19 12:24:34.263000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-19 15:38:06.335000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.431",
	"createdAt": "2024-03-19 15:41:35.956000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.262",
	"createdAt": "2024-03-19 16:19:53.029000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.372",
	"createdAt": "2024-03-19 16:38:59.181000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.263",
	"createdAt": "2024-03-19 22:27:57.733000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.383",
	"createdAt": "2024-03-21 10:17:54.093000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.436",
	"createdAt": "2024-03-21 17:12:20.435000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.338",
	"createdAt": "2024-03-21 17:42:05.379000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.361",
	"createdAt": "2024-03-21 18:45:10.751000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.409",
	"createdAt": "2024-03-22 12:52:22.199000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.352",
	"createdAt": "2024-03-23 20:03:26.678000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.305",
	"createdAt": "2024-03-29 18:17:11.253000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.015",
	"createdAt": "2024-03-29 22:17:23.929000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.453",
	"createdAt": "2024-04-06 20:22:49.023000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.275",
	"createdAt": "2024-04-07 17:26:13.653000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-12 15:27:20.371000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.592",
	"createdAt": "2024-04-14 18:46:57.426000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.193",
	"createdAt": "2024-04-15 18:03:23.362000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.275",
	"createdAt": "2024-04-22 16:53:21.577000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.253",
	"createdAt": "2024-04-22 17:19:06.333000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.524",
	"createdAt": "2024-04-22 17:37:55.169000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.442",
	"createdAt": "2024-04-23 06:33:45.879000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.318",
	"createdAt": "2024-04-23 11:46:32.911000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.821",
	"createdAt": "2024-04-23 13:08:54.354000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.963",
	"createdAt": "2024-04-25 09:13:14.470000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.186",
	"createdAt": "2024-04-25 13:35:22.938000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.681",
	"createdAt": "2024-04-26 20:24:17.589000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.325",
	"createdAt": "2024-04-29 07:19:19.209000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.01",
	"createdAt": "2024-04-29 07:40:37.347000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-30 09:11:22.023000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.014",
	"createdAt": "2024-04-30 11:14:12.734000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.289",
	"createdAt": "2024-04-30 15:34:17.937000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.659",
	"createdAt": "2024-05-02 09:13:41.005000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.523",
	"createdAt": "2024-05-02 10:01:14.099000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.713",
	"createdAt": "2024-05-02 12:00:24.116000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.166",
	"createdAt": "2024-05-02 14:23:59.027000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.282",
	"createdAt": "2024-05-06 12:30:29.190000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.443",
	"createdAt": "2024-05-07 10:47:05.996000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.776",
	"createdAt": "2024-05-08 10:35:57.056000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.829",
	"createdAt": "2024-05-09 14:29:00.970000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.24",
	"createdAt": "2024-05-09 16:02:59.672000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.786",
	"createdAt": "2024-05-10 09:11:41.717000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.439",
	"createdAt": "2024-05-10 16:15:16.916000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.057",
	"createdAt": "2024-05-13 08:52:56.630000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.481",
	"createdAt": "2024-05-15 15:35:30.568000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.025",
	"createdAt": "2024-05-21 16:13:54.920000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.784",
	"createdAt": "2024-05-22 08:20:03.110000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.754",
	"createdAt": "2024-05-27 14:42:58.612000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-27 15:34:06.611000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.119",
	"createdAt": "2024-05-28 16:36:15.364000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.315",
	"createdAt": "2024-05-28 17:16:25.881000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.562",
	"createdAt": "2024-05-30 07:55:38.584000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "ENFG-001",
	"hardware": "MD-001",
	"sumHours": "0.009",
	"createdAt": "2024-03-13 14:33:58.504000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eduardka@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.041",
	"createdAt": "2024-03-19 22:24:07.423000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.887",
	"createdAt": "2024-02-06 14:17:23.323000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.968",
	"createdAt": "2024-02-07 09:56:57.152000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.812",
	"createdAt": "2024-02-16 09:13:33.659000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.981",
	"createdAt": "2024-02-26 09:22:29.895000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.283",
	"createdAt": "2024-02-26 13:49:27.217000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.979",
	"createdAt": "2024-03-01 18:29:33.044000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.942",
	"createdAt": "2024-03-06 11:43:18.313000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.948",
	"createdAt": "2024-03-07 07:28:19.081000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.431",
	"createdAt": "2024-03-07 13:31:22.071000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.817",
	"createdAt": "2024-03-11 10:43:43.046000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.988",
	"createdAt": "2024-03-28 09:03:02.553000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.981",
	"createdAt": "2024-04-01 11:37:47.127000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.754",
	"createdAt": "2024-04-01 13:30:55.350000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.986",
	"createdAt": "2024-04-04 07:04:08.512000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.643",
	"createdAt": "2024-04-11 14:05:15.417000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.481",
	"createdAt": "2024-04-17 09:24:47.005000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.494",
	"createdAt": "2024-04-18 13:13:09.350000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.252",
	"createdAt": "2024-04-18 14:21:24.934000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.987",
	"createdAt": "2024-04-19 06:22:00.656000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.183",
	"createdAt": "2024-04-30 14:33:03.993000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.254",
	"createdAt": "2024-05-03 16:40:57.840000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.988",
	"createdAt": "2024-05-05 15:04:08.697000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.226",
	"createdAt": "2024-05-14 13:33:38.948000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.971",
	"createdAt": "2024-05-27 13:59:13.721000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.74",
	"createdAt": "2024-05-28 14:41:03.786000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.209",
	"createdAt": "2024-05-29 13:30:32.151000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.437",
	"createdAt": "2024-05-29 18:43:43.346000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.981",
	"createdAt": "2024-05-30 13:31:07.554000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "elenam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.494",
	"createdAt": "2024-05-30 18:57:08.100000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.004",
	"createdAt": "2024-02-02 09:23:13.525000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.178",
	"createdAt": "2024-02-02 14:37:40.299000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.88",
	"createdAt": "2024-02-04 15:59:33.263000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.946",
	"createdAt": "2024-02-04 17:10:36.447000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-04 21:00:09.480000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.071",
	"createdAt": "2024-02-06 06:48:16.584000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.253",
	"createdAt": "2024-02-06 06:56:08.793000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.09",
	"createdAt": "2024-02-07 11:08:05.243000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.284",
	"createdAt": "2024-02-08 16:50:27.296000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-09 07:16:31.527000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-11 18:54:30.903000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-11 20:27:19.462000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.017",
	"createdAt": "2024-02-13 07:39:22.370000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-02-13 10:03:11.251000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.156",
	"createdAt": "2024-02-13 13:32:33.196000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.365",
	"createdAt": "2024-02-13 13:43:37.803000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.544",
	"createdAt": "2024-02-13 14:24:28.219000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.171",
	"createdAt": "2024-02-13 21:18:14.591000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.136",
	"createdAt": "2024-02-16 08:47:36.505000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.233",
	"createdAt": "2024-02-20 07:16:48.806000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "5.014",
	"createdAt": "2024-02-07 11:16:59.776000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.861",
	"createdAt": "2024-02-16 09:51:45.193000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.037",
	"createdAt": "2024-02-20 07:33:03.849000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.597",
	"createdAt": "2024-02-22 13:28:08.579000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.37",
	"createdAt": "2024-02-20 07:54:27.260000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.468",
	"createdAt": "2024-02-13 23:32:49.445000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-15 19:41:47.039000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-15 21:48:30.788000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.715",
	"createdAt": "2024-02-15 23:54:45.998000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.211",
	"createdAt": "2024-02-16 08:32:32.489000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.48",
	"createdAt": "2024-02-16 09:08:03.691000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.869",
	"createdAt": "2024-02-17 21:58:20.187000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.01",
	"createdAt": "2024-02-18 03:28:37.540000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.692",
	"createdAt": "2024-02-18 21:03:54.234000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.002",
	"createdAt": "2024-02-18 22:47:17.993000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.384",
	"createdAt": "2024-02-19 08:32:37.900000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.776",
	"createdAt": "2024-02-19 09:21:02.937000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-19 11:20:26.031000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.169",
	"createdAt": "2024-02-20 06:43:05.272000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.321",
	"createdAt": "2024-02-20 06:55:20.611000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.196",
	"createdAt": "2024-02-20 07:40:19.930000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.289",
	"createdAt": "2024-02-20 09:27:29.895000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-20 09:46:30.839000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-02-20 12:19:19.568000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.896",
	"createdAt": "2024-02-20 23:14:05.626000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.062",
	"createdAt": "2024-02-21 07:24:43.637000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.037",
	"createdAt": "2024-02-21 07:30:51.758000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.061",
	"createdAt": "2024-02-21 15:08:29.496000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.946",
	"createdAt": "2024-02-21 22:28:05.558000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.038",
	"createdAt": "2024-02-22 13:23:54.621000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.412",
	"createdAt": "2024-02-23 00:57:23.103000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.012",
	"createdAt": "2024-02-23 07:22:31.868000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.002",
	"createdAt": "2024-02-26 06:46:14.365000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.88",
	"createdAt": "2024-02-26 11:59:21.243000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.002",
	"createdAt": "2024-02-27 07:38:47.242000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.992",
	"createdAt": "2024-02-29 06:17:39.559000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.011",
	"createdAt": "2024-02-29 07:35:21.831000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.016",
	"createdAt": "2024-02-29 22:39:49.508000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.005",
	"createdAt": "2024-03-01 07:56:38.927000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.179",
	"createdAt": "2024-03-01 16:55:03.920000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.009",
	"createdAt": "2024-03-01 19:06:30.905000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.001",
	"createdAt": "2024-03-02 19:11:13.079000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-02 23:18:21.778000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.51",
	"createdAt": "2024-03-03 01:27:37.411000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.288",
	"createdAt": "2024-03-03 13:54:43.845000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-03 22:05:28.877000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.803",
	"createdAt": "2024-03-04 01:48:13.820000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.391",
	"createdAt": "2024-03-04 13:29:00.168000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.004",
	"createdAt": "2024-03-05 11:29:42.728000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.472",
	"createdAt": "2024-03-06 12:41:35.268000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-06 18:37:07.688000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-06 21:07:53.565000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.363",
	"createdAt": "2024-03-07 00:07:47.915000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-07 22:09:41.025000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.597",
	"createdAt": "2024-03-10 10:38:33.457000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.011",
	"createdAt": "2024-03-10 12:21:28.712000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.799",
	"createdAt": "2024-03-10 16:42:22.339000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-10 18:18:44.053000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.812",
	"createdAt": "2024-03-11 08:45:39.553000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.153",
	"createdAt": "2024-03-11 13:53:01.952000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.444",
	"createdAt": "2024-03-13 09:28:06.197000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.809",
	"createdAt": "2024-03-13 09:58:32.644000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.061",
	"createdAt": "2024-03-14 00:43:02.537000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.961",
	"createdAt": "2024-03-14 02:14:09.620000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-18 08:58:52.252000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-18 13:51:01.989000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.017",
	"createdAt": "2024-03-22 12:34:41.651000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.25",
	"createdAt": "2024-03-25 08:18:50.322000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-25 13:57:18.324000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.305",
	"createdAt": "2024-03-26 07:34:24.565000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.002",
	"createdAt": "2024-03-26 07:56:24.547000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-27 08:31:07.894000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.01",
	"createdAt": "2024-03-27 11:23:26.337000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.009",
	"createdAt": "2024-03-28 21:51:15.947000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.309",
	"createdAt": "2024-03-29 09:11:33.876000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.14",
	"createdAt": "2024-03-29 12:09:03.546000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.003",
	"createdAt": "2024-03-29 12:25:31.556000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.189",
	"createdAt": "2024-04-01 08:18:08.882000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.631",
	"createdAt": "2024-04-01 08:45:03.521000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.017",
	"createdAt": "2024-04-01 11:50:01.251000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.843",
	"createdAt": "2024-04-01 19:41:01.936000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-02 10:34:11.682000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.078",
	"createdAt": "2024-04-03 10:19:17.677000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.752",
	"createdAt": "2024-04-03 22:47:12.337000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.241",
	"createdAt": "2024-04-04 20:43:03.674000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.306",
	"createdAt": "2024-04-05 12:18:24.352000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.007",
	"createdAt": "2024-04-07 16:34:22.906000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.013",
	"createdAt": "2024-04-07 23:42:22.008000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-08 19:38:45.378000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.022",
	"createdAt": "2024-04-08 22:07:38.665000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.594",
	"createdAt": "2024-04-08 22:11:43.421000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-09 10:11:28.308000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-10 20:18:19.561000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.011",
	"createdAt": "2024-04-10 23:14:03.063000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.532",
	"createdAt": "2024-04-11 00:17:28.383000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.269",
	"createdAt": "2024-04-11 09:36:41.939000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.006",
	"createdAt": "2024-04-11 09:54:49.554000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-12 11:36:58.606000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-18 13:20:43.978000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.15",
	"createdAt": "2024-04-18 15:37:10.072000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-19 09:43:02.309000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.284",
	"createdAt": "2024-04-22 13:04:46.527000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-30 10:29:37.737000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.342",
	"createdAt": "2024-05-06 07:31:26.842000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-08 05:40:30.946000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-08 10:12:44.176000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.68",
	"createdAt": "2024-05-08 12:50:26.539000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.172",
	"createdAt": "2024-05-08 13:34:41.288000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-08 13:47:47.207000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.014",
	"createdAt": "2024-05-08 19:14:32.126000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.004",
	"createdAt": "2024-05-08 23:27:56.924000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.069",
	"createdAt": "2024-05-10 05:17:10.092000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.015",
	"createdAt": "2024-05-10 05:23:16.908000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.515",
	"createdAt": "2024-05-10 12:05:02.953000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.575",
	"createdAt": "2024-05-10 14:43:22.742000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.215",
	"createdAt": "2024-05-12 20:01:21.322000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-13 00:19:11.968000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.017",
	"createdAt": "2024-05-13 10:06:45.671000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.01",
	"createdAt": "2024-05-14 06:01:52.153000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-14 11:37:24.436000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.162",
	"createdAt": "2024-05-14 16:45:37.201000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-14 18:16:05.362000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-14 21:34:12.760000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-17 06:54:26.452000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.434",
	"createdAt": "2024-05-20 00:33:35.597000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.531",
	"createdAt": "2024-05-20 06:59:42.380000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.014",
	"createdAt": "2024-05-20 13:01:16.650000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.409",
	"createdAt": "2024-05-20 17:31:58.442000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "8.346",
	"createdAt": "2024-05-21 06:46:02.167000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.002",
	"createdAt": "2024-05-21 15:28:51.422000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.001",
	"createdAt": "2024-05-22 08:17:31.593000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.101",
	"createdAt": "2024-05-22 13:47:23.969000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.523",
	"createdAt": "2024-05-23 05:49:09.517000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-23 07:34:31.127000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-23 14:34:25.776000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.003",
	"createdAt": "2024-05-26 19:26:14.347000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-26 22:28:13.375000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.27",
	"createdAt": "2024-05-27 06:11:32.717000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.007",
	"createdAt": "2024-05-27 07:30:15.917000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.23",
	"createdAt": "2024-05-27 13:58:22.805000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.723",
	"createdAt": "2024-05-27 22:13:13.330000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.009",
	"createdAt": "2024-05-28 06:20:41.103000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.788",
	"createdAt": "2024-05-28 10:10:33.074000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.011",
	"createdAt": "2024-05-29 06:59:55.657000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-29 11:09:35.441000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.727",
	"createdAt": "2024-05-29 13:31:29.408000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "9.015",
	"createdAt": "2024-05-30 06:43:27.279000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.001",
	"createdAt": "2024-05-30 19:13:14.289000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.002",
	"createdAt": "2024-05-31 06:46:35.889000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "evgeniyb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-06-01 10:18:32.593000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_pavelt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.682",
	"createdAt": "2024-01-26 15:09:47.437000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_pavelt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.642",
	"createdAt": "2024-02-19 14:50:13.399000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_pavelt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.003",
	"createdAt": "2024-03-22 10:42:24.282000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_pavelt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.017",
	"createdAt": "2024-03-26 13:48:35.143000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_pavelt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.119",
	"createdAt": "2024-03-26 13:52:07.059000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_pavelt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.181",
	"createdAt": "2024-04-04 14:26:05.809000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_pavelt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.37",
	"createdAt": "2024-04-17 10:30:17.311000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_pavelt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.735",
	"createdAt": "2024-05-09 14:21:00.149000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_pavelt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.295",
	"createdAt": "2024-05-14 13:08:26.710000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.013",
	"createdAt": "2024-01-26 15:39:00.877000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.375",
	"createdAt": "2024-01-29 07:35:10.998000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.752",
	"createdAt": "2024-01-29 13:55:29.886000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.781",
	"createdAt": "2024-01-31 09:35:38.026000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.576",
	"createdAt": "2024-01-31 11:09:35.755000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.88",
	"createdAt": "2024-01-31 12:32:50.145000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.398",
	"createdAt": "2024-01-31 13:32:06.857000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.434",
	"createdAt": "2024-01-31 14:39:15.205000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.418",
	"createdAt": "2024-02-01 09:35:09.220000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.647",
	"createdAt": "2024-02-01 11:09:03.080000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-06 07:41:04.505000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.018",
	"createdAt": "2024-02-07 13:33:12.780000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.497",
	"createdAt": "2024-02-08 07:18:04.422000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.905",
	"createdAt": "2024-02-09 10:36:03.868000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-12 06:58:53.909000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.087",
	"createdAt": "2024-02-12 09:02:32.672000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.402",
	"createdAt": "2024-02-13 08:26:54.013000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.007",
	"createdAt": "2024-02-13 12:58:56.627000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.463",
	"createdAt": "2024-02-14 06:22:37.330000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.223",
	"createdAt": "2024-02-14 07:28:18.033000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "5.458",
	"createdAt": "2024-02-14 09:23:16.564000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.202",
	"createdAt": "2024-02-15 06:44:21.093000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.496",
	"createdAt": "2024-02-15 09:38:28.807000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.014",
	"createdAt": "2024-02-15 10:27:39.416000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.336",
	"createdAt": "2024-02-15 14:22:51.511000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.996",
	"createdAt": "2024-02-16 06:35:26.361000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.455",
	"createdAt": "2024-02-16 09:10:07.938000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.4",
	"createdAt": "2024-02-16 10:16:16.116000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.722",
	"createdAt": "2024-02-16 11:35:19.935000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.699",
	"createdAt": "2024-02-16 12:56:06.789000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.657",
	"createdAt": "2024-02-19 06:06:11.733000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.137",
	"createdAt": "2024-02-19 10:13:28.700000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.981",
	"createdAt": "2024-02-19 11:34:57.836000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.076",
	"createdAt": "2024-02-19 13:13:44.047000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-02-20 06:11:22.017000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.14",
	"createdAt": "2024-02-20 08:26:03.631000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.535",
	"createdAt": "2024-02-20 10:44:43.671000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.269",
	"createdAt": "2024-02-20 13:26:33.157000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-02-21 05:52:53.075000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.97",
	"createdAt": "2024-02-21 10:48:46.484000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.529",
	"createdAt": "2024-02-22 06:04:01.183000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.532",
	"createdAt": "2024-02-22 10:49:44.817000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.017",
	"createdAt": "2024-02-22 13:01:40.250000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-02-23 06:16:17.494000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.094",
	"createdAt": "2024-02-23 10:17:00.045000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.029",
	"createdAt": "2024-02-23 11:54:23.987000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.731",
	"createdAt": "2024-02-23 13:36:16.675000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.975",
	"createdAt": "2024-02-26 05:54:38.276000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.598",
	"createdAt": "2024-02-27 05:48:27.677000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.161",
	"createdAt": "2024-02-27 07:42:20.316000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.026",
	"createdAt": "2024-02-27 13:08:28.659000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.308",
	"createdAt": "2024-02-28 13:50:02.414000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.608",
	"createdAt": "2024-02-28 14:13:44.067000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.355",
	"createdAt": "2024-02-29 06:48:42.152000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-02-29 11:12:23.824000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-29 13:57:12.197000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.714",
	"createdAt": "2024-03-01 06:49:45.811000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.764",
	"createdAt": "2024-03-01 10:59:44.178000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.008",
	"createdAt": "2024-03-02 07:53:00.177000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.026",
	"createdAt": "2024-03-04 06:14:22.172000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-03-04 09:31:08.232000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.007",
	"createdAt": "2024-03-04 11:37:21.563000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.836",
	"createdAt": "2024-03-05 06:34:36.966000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.937",
	"createdAt": "2024-03-05 10:38:23.227000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.696",
	"createdAt": "2024-03-06 07:57:35.246000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.995",
	"createdAt": "2024-03-06 09:29:41.030000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.828",
	"createdAt": "2024-03-06 12:15:52.319000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.187",
	"createdAt": "2024-03-06 14:18:12.336000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.321",
	"createdAt": "2024-03-07 11:10:19.375000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "ext_vladislavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.765",
	"createdAt": "2024-03-07 12:34:38.207000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.971",
	"createdAt": "2023-12-04 06:36:20.522000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.04",
	"createdAt": "2023-12-06 10:27:31.412000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.016",
	"createdAt": "2023-12-06 10:38:38.410000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.085",
	"createdAt": "2023-12-07 08:32:40.211000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.586",
	"createdAt": "2024-05-08 09:06:56.193000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.792",
	"createdAt": "2024-05-08 10:46:42.898000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-12 07:15:31.512000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-28 13:53:13.915000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.075",
	"createdAt": "2024-03-25 09:11:05.046000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-26 09:36:39.331000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.955",
	"createdAt": "2024-03-26 10:13:02.301000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.394",
	"createdAt": "2024-03-27 07:40:29.008000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.579",
	"createdAt": "2024-03-27 12:14:30.008000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.617",
	"createdAt": "2024-03-27 13:58:18.973000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-04 12:37:39.961000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.677",
	"createdAt": "2024-05-12 09:26:56.153000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.676",
	"createdAt": "2024-05-12 12:46:09.247000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.442",
	"createdAt": "2024-05-15 12:42:58.988000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-19 13:03:50.412000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.226",
	"createdAt": "2024-05-21 08:00:12.483000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.615",
	"createdAt": "2024-05-21 13:40:36.006000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-28 09:26:54.624000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.164",
	"createdAt": "2024-05-29 07:38:07.303000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "hadarp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.411",
	"createdAt": "2024-05-29 10:51:00.102000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "idanye@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.047",
	"createdAt": "2023-12-12 16:50:22.105000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "idanye@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.374",
	"createdAt": "2024-03-24 11:55:06.840000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.884",
	"createdAt": "2024-02-01 09:01:41.676000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.713",
	"createdAt": "2024-02-15 09:04:22.470000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.995",
	"createdAt": "2024-02-15 12:34:07.429000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-15 13:57:20.778000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.004",
	"createdAt": "2024-02-16 10:28:18.649000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-20 10:54:55.308000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-22 11:34:32.121000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.014",
	"createdAt": "2024-02-23 10:18:54.506000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.943",
	"createdAt": "2024-02-25 13:57:55.862000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-27 10:02:57.101000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.689",
	"createdAt": "2024-02-27 15:18:52.211000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-29 09:31:47.677000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.94",
	"createdAt": "2024-02-29 15:16:05.569000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.443",
	"createdAt": "2024-03-11 11:05:16.026000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.701",
	"createdAt": "2024-03-12 08:33:04.879000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.679",
	"createdAt": "2024-03-12 15:27:02.710000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.244",
	"createdAt": "2024-03-29 15:21:48.365000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.447",
	"createdAt": "2024-04-10 13:49:49.087000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.701",
	"createdAt": "2024-04-16 13:13:41.692000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.213",
	"createdAt": "2024-04-19 09:47:15.626000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "igorbon@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.395",
	"createdAt": "2024-05-13 14:08:45.731000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.004",
	"createdAt": "2024-02-02 09:24:51.676000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.873",
	"createdAt": "2024-02-04 18:55:58.421000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.225",
	"createdAt": "2024-02-09 13:04:14.731000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.033",
	"createdAt": "2024-04-03 15:31:36.909000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.029",
	"createdAt": "2024-04-03 15:37:50.307000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.155",
	"createdAt": "2024-02-01 08:27:28.254000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-01 08:43:29.235000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.471",
	"createdAt": "2024-02-01 14:50:58.783000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.398",
	"createdAt": "2024-02-01 20:54:15.774000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.49",
	"createdAt": "2024-02-13 16:22:12.505000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.705",
	"createdAt": "2024-02-15 13:10:44.557000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.331",
	"createdAt": "2024-02-16 09:26:40.097000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.61",
	"createdAt": "2024-02-16 09:49:49.837000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.843",
	"createdAt": "2024-02-16 10:30:52.013000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.375",
	"createdAt": "2024-02-22 10:13:33.053000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.048",
	"createdAt": "2024-02-22 10:38:48.732000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.078",
	"createdAt": "2024-02-22 10:56:17.368000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.275",
	"createdAt": "2024-02-22 13:20:59.927000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.702",
	"createdAt": "2024-02-22 13:39:56.189000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.138",
	"createdAt": "2024-03-04 13:12:40.085000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-08 13:49:28.255000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.451",
	"createdAt": "2024-03-14 16:43:10.744000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-19 13:19:05.346000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.231",
	"createdAt": "2024-03-19 13:43:47.310000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.177",
	"createdAt": "2024-03-19 14:00:07.771000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.327",
	"createdAt": "2024-03-19 15:13:03.408000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.21",
	"createdAt": "2024-03-20 13:15:39.835000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.427",
	"createdAt": "2024-03-20 13:30:45.120000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.324",
	"createdAt": "2024-03-26 11:20:17.219000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.292",
	"createdAt": "2024-04-02 14:55:09.438000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.599",
	"createdAt": "2024-04-02 15:15:05.507000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.613",
	"createdAt": "2024-04-03 14:39:13.775000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.019",
	"createdAt": "2024-04-03 15:18:57.334000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.029",
	"createdAt": "2024-04-03 15:22:24.636000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.022",
	"createdAt": "2024-04-03 15:26:53.926000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.988",
	"createdAt": "2024-04-04 07:20:51.969000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.883",
	"createdAt": "2024-04-09 12:57:09.490000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.75",
	"createdAt": "2024-04-10 09:02:30.463000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.243",
	"createdAt": "2024-04-10 09:53:07.877000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.249",
	"createdAt": "2024-04-16 13:02:52.085000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.541",
	"createdAt": "2024-04-16 15:23:31.008000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.708",
	"createdAt": "2024-04-17 10:11:22.271000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.587",
	"createdAt": "2024-04-17 11:41:48.466000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.23",
	"createdAt": "2024-04-18 15:44:02.333000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.494",
	"createdAt": "2024-04-19 17:57:40.347000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.628",
	"createdAt": "2024-05-14 14:55:11.562000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.021",
	"createdAt": "2024-05-14 15:35:01.793000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.061",
	"createdAt": "2024-05-14 15:40:10.648000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.246",
	"createdAt": "2024-05-15 11:13:26.842000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.962",
	"createdAt": "2024-05-15 12:08:42.034000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.453",
	"createdAt": "2024-05-16 09:33:35.323000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.022",
	"createdAt": "2024-04-03 15:42:02.307000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "irynaku@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.167",
	"createdAt": "2024-04-03 17:26:49.997000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "karalinaz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.023",
	"createdAt": "2024-01-16 10:57:06.005000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "karalinaz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.826",
	"createdAt": "2024-01-29 17:20:11.066000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "karalinaz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.261",
	"createdAt": "2024-02-21 11:04:17.617000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-01-09 08:32:37.790000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.007",
	"createdAt": "2024-01-16 10:07:26.813000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.052",
	"createdAt": "2024-01-16 11:12:12.959000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.893",
	"createdAt": "2024-02-02 10:59:18.417000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.425",
	"createdAt": "2024-02-06 08:01:03.832000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.211",
	"createdAt": "2024-02-06 10:46:32.548000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.842",
	"createdAt": "2024-02-06 12:21:41.871000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.933",
	"createdAt": "2024-02-06 13:58:35.040000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.516",
	"createdAt": "2024-02-07 08:38:27.796000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.077",
	"createdAt": "2024-02-07 10:45:19.117000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.695",
	"createdAt": "2024-02-07 10:53:19.107000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.566",
	"createdAt": "2024-02-07 12:32:11.100000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.878",
	"createdAt": "2024-02-08 08:14:29.020000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.477",
	"createdAt": "2024-02-10 16:24:14.540000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.726",
	"createdAt": "2024-02-13 09:53:22.166000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.871",
	"createdAt": "2024-02-13 11:18:04.297000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.67",
	"createdAt": "2024-02-13 13:35:59.151000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.549",
	"createdAt": "2024-02-14 08:00:10.497000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-14 11:42:12.602000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.341",
	"createdAt": "2024-02-14 14:23:29.594000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.568",
	"createdAt": "2024-02-27 08:58:56.831000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.024",
	"createdAt": "2024-02-27 12:01:41.740000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.817",
	"createdAt": "2024-03-01 11:41:30.515000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-05 08:27:17.526000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.647",
	"createdAt": "2024-03-06 06:05:23.652000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.018",
	"createdAt": "2024-03-09 08:36:07.656000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.551",
	"createdAt": "2024-03-14 09:01:50.191000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.184",
	"createdAt": "2024-03-26 05:46:19.502000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.106",
	"createdAt": "2024-04-09 12:05:35.549000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.034",
	"createdAt": "2024-04-16 15:25:26.773000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.921",
	"createdAt": "2024-04-17 09:21:37.276000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.71",
	"createdAt": "2024-04-19 08:35:30.037000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.494",
	"createdAt": "2024-04-19 10:38:38.031000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.559",
	"createdAt": "2024-04-23 07:31:11.853000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.586",
	"createdAt": "2024-04-23 09:11:26.925000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "katsiarynas@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.009",
	"createdAt": "2024-02-13 13:33:18.899000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.313",
	"createdAt": "2024-04-04 11:47:21.429000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.812",
	"createdAt": "2024-04-04 12:11:29.360000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.696",
	"createdAt": "2024-04-05 08:13:57.206000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.101",
	"createdAt": "2024-04-05 08:58:17.112000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.207",
	"createdAt": "2024-04-05 09:13:37.827000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.017",
	"createdAt": "2024-04-05 13:56:05.008000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.136",
	"createdAt": "2024-04-05 15:03:21.313000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.19",
	"createdAt": "2024-04-11 13:57:11.183000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.904",
	"createdAt": "2024-04-11 14:21:46.495000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.717",
	"createdAt": "2024-04-12 07:25:21.843000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.43",
	"createdAt": "2024-04-12 08:46:35.105000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.365",
	"createdAt": "2024-04-15 14:33:50.680000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.539",
	"createdAt": "2024-04-18 13:45:22.883000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.427",
	"createdAt": "2024-04-18 14:55:31.919000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.849",
	"createdAt": "2024-04-19 10:12:18.172000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.112",
	"createdAt": "2024-04-19 12:01:46.073000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.064",
	"createdAt": "2024-04-22 07:46:39.515000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.297",
	"createdAt": "2024-04-22 08:28:20.481000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.883",
	"createdAt": "2024-04-22 08:57:45.631000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.476",
	"createdAt": "2024-04-22 09:52:59.855000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.096",
	"createdAt": "2024-04-22 10:51:09.845000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.555",
	"createdAt": "2024-04-22 11:52:52.911000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.049",
	"createdAt": "2024-04-22 12:29:21.821000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.26",
	"createdAt": "2024-04-22 12:41:23.878000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.05",
	"createdAt": "2024-04-22 12:47:19.742000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.718",
	"createdAt": "2024-04-24 12:13:39.620000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.752",
	"createdAt": "2024-04-25 10:15:16.208000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.836",
	"createdAt": "2024-04-25 11:57:27.834000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.288",
	"createdAt": "2024-04-25 13:39:22.391000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.582",
	"createdAt": "2024-04-25 13:58:38.138000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.053",
	"createdAt": "2024-04-25 15:11:47.976000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.521",
	"createdAt": "2024-04-26 14:24:26.483000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.841",
	"createdAt": "2024-04-26 16:00:11.422000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.585",
	"createdAt": "2024-04-29 07:41:46.639000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.228",
	"createdAt": "2024-04-29 09:24:02.805000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.342",
	"createdAt": "2024-04-29 12:22:58.933000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.103",
	"createdAt": "2024-04-30 08:27:26.556000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.308",
	"createdAt": "2024-05-06 11:34:57.482000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.946",
	"createdAt": "2024-05-06 13:01:11.101000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.355",
	"createdAt": "2024-05-07 14:55:58.864000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.261",
	"createdAt": "2024-05-08 12:37:37.496000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.292",
	"createdAt": "2024-05-29 08:19:33.907000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsinat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.179",
	"createdAt": "2024-05-29 14:13:22.677000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.015",
	"createdAt": "2024-01-26 15:37:03.170000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.084",
	"createdAt": "2024-01-26 15:49:41.901000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.443",
	"createdAt": "2024-01-29 07:06:14.378000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-02 14:32:57.490000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.862",
	"createdAt": "2024-02-02 14:42:13.779000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.191",
	"createdAt": "2024-02-08 09:58:16.943000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.189",
	"createdAt": "2024-02-08 11:54:29.028000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.601",
	"createdAt": "2024-02-09 09:26:43.337000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.199",
	"createdAt": "2024-02-09 12:28:21.343000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.5",
	"createdAt": "2024-02-13 14:06:30.648000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.16",
	"createdAt": "2024-02-14 12:05:11.546000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.849",
	"createdAt": "2024-02-16 11:41:52.162000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.996",
	"createdAt": "2024-02-19 12:33:50.208000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.892",
	"createdAt": "2024-02-20 09:15:50.517000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.789",
	"createdAt": "2024-02-20 14:09:10.367000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.901",
	"createdAt": "2024-02-21 08:15:22.035000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.677",
	"createdAt": "2024-02-21 09:54:55.835000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.432",
	"createdAt": "2024-02-21 12:14:34.969000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-02-22 08:04:44.724000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.08",
	"createdAt": "2024-02-22 10:32:08.058000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.356",
	"createdAt": "2024-02-22 12:11:13.727000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-02-23 09:54:16.488000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.422",
	"createdAt": "2024-02-23 12:24:49.376000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.22",
	"createdAt": "2024-02-26 09:17:31.526000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-26 11:17:41.192000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.521",
	"createdAt": "2024-02-27 13:22:00.751000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.692",
	"createdAt": "2024-02-28 13:52:20.821000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.671",
	"createdAt": "2024-02-29 12:35:17.492000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.652",
	"createdAt": "2024-03-01 13:27:54.477000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.036",
	"createdAt": "2024-03-04 12:26:44.685000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.44",
	"createdAt": "2024-03-05 14:46:42.056000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "krystsynat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.444",
	"createdAt": "2024-03-06 09:31:39.858000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.004",
	"createdAt": "2024-01-16 10:55:05.555000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.771",
	"createdAt": "2024-01-16 11:11:55.093000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.828",
	"createdAt": "2024-02-12 15:21:37.203000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.175",
	"createdAt": "2024-02-13 09:50:06.611000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.353",
	"createdAt": "2024-02-13 14:06:22.082000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.535",
	"createdAt": "2024-02-14 11:22:23.083000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.748",
	"createdAt": "2024-02-15 15:01:04.137000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.357",
	"createdAt": "2024-02-16 14:21:48.222000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.968",
	"createdAt": "2024-02-19 07:12:21.334000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.243",
	"createdAt": "2024-02-19 10:14:47.032000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.001",
	"createdAt": "2024-02-20 08:43:11.483000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.762",
	"createdAt": "2024-02-20 14:10:15.068000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.91",
	"createdAt": "2024-02-22 08:32:41.350000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.167",
	"createdAt": "2024-02-22 12:31:37.149000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-02-22 13:52:59.725000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.471",
	"createdAt": "2024-02-22 14:15:55.394000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.194",
	"createdAt": "2024-02-27 15:03:52.360000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.895",
	"createdAt": "2024-02-29 07:09:32.968000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.204",
	"createdAt": "2024-03-01 07:43:17.642000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.489",
	"createdAt": "2024-03-04 08:43:31.417000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.882",
	"createdAt": "2024-03-05 10:14:26.396000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyabo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.384",
	"createdAt": "2024-03-05 15:36:52.220000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.016",
	"createdAt": "2024-01-04 11:46:55.329000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.545",
	"createdAt": "2024-02-02 09:21:51.926000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.584",
	"createdAt": "2024-02-13 14:30:43.515000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.54",
	"createdAt": "2024-02-14 14:02:04.324000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-02-15 06:18:48.240000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.566",
	"createdAt": "2024-02-15 08:23:57.044000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.962",
	"createdAt": "2024-02-15 10:23:57.799000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.215",
	"createdAt": "2024-02-16 07:25:47.224000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.263",
	"createdAt": "2024-02-19 09:42:51.893000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.006",
	"createdAt": "2024-02-20 15:20:35.545000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.181",
	"createdAt": "2024-02-23 13:50:20.681000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.578",
	"createdAt": "2024-02-26 06:50:48.566000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.625",
	"createdAt": "2024-03-04 11:07:04.948000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.52",
	"createdAt": "2024-03-04 13:09:33.497000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.378",
	"createdAt": "2024-03-05 16:09:48.678000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.595",
	"createdAt": "2024-03-05 16:34:41.255000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.143",
	"createdAt": "2024-03-06 14:27:21.356000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.456",
	"createdAt": "2024-03-06 14:49:34.505000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.754",
	"createdAt": "2024-03-25 06:28:08.509000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.014",
	"createdAt": "2024-03-26 12:27:03.671000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.006",
	"createdAt": "2024-03-28 15:29:45.225000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.003",
	"createdAt": "2024-04-17 11:39:05.319000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.136",
	"createdAt": "2024-04-17 16:05:25.782000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.915",
	"createdAt": "2024-05-09 08:47:22.001000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-23 08:51:36.613000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "kseniyapa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.001",
	"createdAt": "2024-04-05 14:39:33.471000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "lizas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.305",
	"createdAt": "2024-02-02 09:36:14.591000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "lizas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-13 14:34:20.273000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "lizas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-02-23 11:50:14.337000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "lizas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.238",
	"createdAt": "2024-02-27 14:22:46.079000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "lizas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-04-05 16:36:36.030000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.43",
	"createdAt": "2024-05-02 08:23:23.841000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "marinama@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.631",
	"createdAt": "2024-02-04 00:24:34.942000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "marinama@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.456",
	"createdAt": "2024-02-11 19:25:59.970000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "marinama@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.187",
	"createdAt": "2024-02-11 20:55:18.189000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "marinama@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.577",
	"createdAt": "2024-02-11 21:08:01.633000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "marinama@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.853",
	"createdAt": "2024-02-25 22:10:38.467000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "marinama@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.083",
	"createdAt": "2024-04-03 10:04:01.109000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "marinama@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.121",
	"createdAt": "2024-04-03 10:12:13.194000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "marinama@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.929",
	"createdAt": "2024-02-03 23:26:17.536000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "marinama@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.329",
	"createdAt": "2024-04-03 10:22:08.766000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-16 15:06:35.688000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-01-17 13:45:12.256000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.996",
	"createdAt": "2024-01-23 18:46:14.851000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.949",
	"createdAt": "2024-01-24 07:14:34.886000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.578",
	"createdAt": "2024-01-24 14:11:01.723000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.063",
	"createdAt": "2024-02-07 10:07:37.819000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.001",
	"createdAt": "2024-02-07 10:16:54.678000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.004",
	"createdAt": "2024-02-07 12:42:40.669000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.957",
	"createdAt": "2024-02-08 07:34:37.996000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.011",
	"createdAt": "2024-02-08 15:48:16.413000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.8",
	"createdAt": "2024-02-12 14:46:44.385000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.558",
	"createdAt": "2024-02-14 15:16:07.090000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.599",
	"createdAt": "2024-02-15 07:32:26.856000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.039",
	"createdAt": "2024-02-16 08:25:37.791000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.022",
	"createdAt": "2024-02-16 08:30:47.329000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.87",
	"createdAt": "2024-02-16 08:34:47.226000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.359",
	"createdAt": "2024-02-17 11:21:55.291000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.427",
	"createdAt": "2024-02-20 14:01:38.234000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.312",
	"createdAt": "2024-02-21 08:34:14.857000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.194",
	"createdAt": "2024-02-21 13:11:43.364000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.493",
	"createdAt": "2024-02-22 10:27:31.913000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.068",
	"createdAt": "2024-02-22 13:06:56.361000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.646",
	"createdAt": "2024-02-23 08:51:17.788000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.612",
	"createdAt": "2024-02-23 12:02:25.341000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.812",
	"createdAt": "2024-02-26 10:21:21.693000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.2",
	"createdAt": "2024-02-26 13:00:15.295000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.22",
	"createdAt": "2024-02-27 08:25:01.453000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.462",
	"createdAt": "2024-02-29 06:19:18.308000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.223",
	"createdAt": "2024-03-01 09:53:03.823000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.488",
	"createdAt": "2024-03-01 10:46:22.423000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.346",
	"createdAt": "2024-03-01 12:57:37.375000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.478",
	"createdAt": "2024-03-02 22:47:44.238000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.493",
	"createdAt": "2024-03-04 09:35:09.921000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.494",
	"createdAt": "2024-03-04 12:58:07.206000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.478",
	"createdAt": "2024-03-05 07:31:30.876000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.011",
	"createdAt": "2024-03-05 10:17:31.068000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.584",
	"createdAt": "2024-03-05 13:43:23.743000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.723",
	"createdAt": "2024-03-06 20:14:04.574000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.349",
	"createdAt": "2024-03-06 21:35:58.609000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.567",
	"createdAt": "2024-03-12 08:47:53.267000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.242",
	"createdAt": "2024-03-13 11:54:01.364000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.812",
	"createdAt": "2024-03-13 14:42:33.514000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.421",
	"createdAt": "2024-03-14 09:50:42.196000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.386",
	"createdAt": "2024-03-14 13:01:26.306000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.638",
	"createdAt": "2024-03-18 08:30:10.825000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.496",
	"createdAt": "2024-03-18 12:06:09.973000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.368",
	"createdAt": "2024-03-19 10:07:32.282000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.015",
	"createdAt": "2024-03-21 09:02:34.784000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.035",
	"createdAt": "2024-03-21 09:12:06.566000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.603",
	"createdAt": "2024-03-21 09:22:24.669000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.192",
	"createdAt": "2024-03-21 12:22:40.414000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.483",
	"createdAt": "2024-03-22 08:25:03.158000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.279",
	"createdAt": "2024-03-22 13:07:33.116000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.453",
	"createdAt": "2024-03-25 07:36:40.002000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.383",
	"createdAt": "2024-03-25 09:44:22.823000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.373",
	"createdAt": "2024-03-25 13:29:50.491000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.532",
	"createdAt": "2024-03-26 07:12:31.630000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.251",
	"createdAt": "2024-03-26 12:11:15.734000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.298",
	"createdAt": "2024-03-27 07:32:03.505000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.289",
	"createdAt": "2024-03-27 11:58:27.649000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.438",
	"createdAt": "2024-03-28 08:46:11.699000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.241",
	"createdAt": "2024-03-28 12:10:43.504000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.365",
	"createdAt": "2024-03-29 08:49:22.354000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.359",
	"createdAt": "2024-04-01 09:24:04.481000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.339",
	"createdAt": "2024-04-01 11:03:16.962000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.53",
	"createdAt": "2024-04-01 12:08:02.212000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.511",
	"createdAt": "2024-04-01 21:26:25.019000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.654",
	"createdAt": "2024-04-02 08:51:37.673000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.368",
	"createdAt": "2024-04-03 07:29:37.139000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.225",
	"createdAt": "2024-04-03 13:16:33.956000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.174",
	"createdAt": "2024-04-03 13:53:28.992000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.938",
	"createdAt": "2024-04-04 08:28:01.820000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.357",
	"createdAt": "2024-04-05 07:29:55.408000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.531",
	"createdAt": "2024-04-05 11:45:02.626000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.645",
	"createdAt": "2024-04-08 12:58:04.379000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.369",
	"createdAt": "2024-04-09 07:30:16.876000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.411",
	"createdAt": "2024-04-09 11:58:11.500000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.936",
	"createdAt": "2024-04-10 07:20:45.070000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.243",
	"createdAt": "2024-04-10 13:54:55.508000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.032",
	"createdAt": "2024-04-11 13:33:16.117000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.18",
	"createdAt": "2024-04-12 10:01:37.143000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.09",
	"createdAt": "2024-04-15 07:44:29.483000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.373",
	"createdAt": "2024-04-15 08:08:57.075000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.336",
	"createdAt": "2024-04-15 09:52:28.815000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.4",
	"createdAt": "2024-04-17 09:14:02.798000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.473",
	"createdAt": "2024-04-18 12:21:16.048000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.437",
	"createdAt": "2024-04-19 13:03:24.600000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.845",
	"createdAt": "2024-04-22 09:35:33.931000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.041",
	"createdAt": "2024-04-23 07:53:38.319000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.107",
	"createdAt": "2024-04-23 08:07:15.031000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.278",
	"createdAt": "2024-04-23 08:30:14.080000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.66",
	"createdAt": "2024-04-23 09:13:39.281000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.523",
	"createdAt": "2024-04-23 10:59:19.704000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.335",
	"createdAt": "2024-04-23 14:12:33.033000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.1",
	"createdAt": "2024-04-25 09:18:54.965000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.46",
	"createdAt": "2024-04-25 12:28:56.829000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.888",
	"createdAt": "2024-04-29 10:14:41.475000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.738",
	"createdAt": "2024-04-29 13:01:07.455000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.775",
	"createdAt": "2024-05-01 11:56:58.368000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.3",
	"createdAt": "2024-05-02 07:45:24.785000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.191",
	"createdAt": "2024-05-02 10:01:30.040000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.447",
	"createdAt": "2024-05-07 10:23:15.096000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.193",
	"createdAt": "2024-05-07 12:03:10.155000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.625",
	"createdAt": "2024-05-08 06:25:42.354000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.979",
	"createdAt": "2024-05-08 08:08:29.659000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.594",
	"createdAt": "2024-05-08 13:46:25.629000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.756",
	"createdAt": "2024-05-10 13:17:06.615000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.721",
	"createdAt": "2024-05-12 20:26:21.898000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.956",
	"createdAt": "2024-05-13 12:00:10.305000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.545",
	"createdAt": "2024-05-14 08:54:03.259000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-05-15 06:47:46.668000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.168",
	"createdAt": "2024-05-15 09:32:59.216000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.428",
	"createdAt": "2024-05-16 06:58:16.634000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.374",
	"createdAt": "2024-05-16 11:00:53.619000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.184",
	"createdAt": "2024-05-17 08:03:07.481000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.388",
	"createdAt": "2024-05-17 12:56:34.820000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.622",
	"createdAt": "2024-05-20 07:39:32.130000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.65",
	"createdAt": "2024-05-20 14:05:09.473000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.727",
	"createdAt": "2024-05-21 06:15:51.734000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.177",
	"createdAt": "2024-05-22 13:18:06.519000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.472",
	"createdAt": "2024-05-22 16:20:18.722000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.677",
	"createdAt": "2024-05-26 16:44:26.482000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.847",
	"createdAt": "2024-05-27 11:32:53.215000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.247",
	"createdAt": "2024-05-28 04:09:40.630000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.52",
	"createdAt": "2024-05-28 09:51:44.875000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.325",
	"createdAt": "2024-05-29 06:47:28.091000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.742",
	"createdAt": "2024-05-29 11:48:31.360000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.583",
	"createdAt": "2024-05-30 08:00:43.401000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.495",
	"createdAt": "2024-05-30 10:38:54.280000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.46",
	"createdAt": "2024-05-31 05:11:57.529000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.579",
	"createdAt": "2024-05-31 12:29:28.012000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.972",
	"createdAt": "2024-06-02 09:44:23.289000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.015",
	"createdAt": "2024-01-10 13:17:10.920000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-01-10 13:21:22.289000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.007",
	"createdAt": "2024-01-25 12:40:20.599000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.228",
	"createdAt": "2024-01-30 12:37:20.890000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.837",
	"createdAt": "2024-02-04 12:32:07.296000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-02-22 12:05:55.441000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.551",
	"createdAt": "2024-02-28 13:32:36.530000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.015",
	"createdAt": "2024-03-04 12:31:44.258000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.013",
	"createdAt": "2024-03-04 15:03:01.772000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-06 14:44:48.671000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-11 14:27:03.306000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.007",
	"createdAt": "2024-03-24 14:30:18.099000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-26 09:45:29.233000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-04-30 08:52:47.351000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-12 11:45:02.231000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.353",
	"createdAt": "2024-05-22 13:11:56.126000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-05-22 13:40:41.122000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.843",
	"createdAt": "2024-05-23 11:31:18.028000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-28 09:28:50.086000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-05-30 12:46:55.700000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.838",
	"createdAt": "2024-01-07 08:31:21.752000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-01-11 08:35:15.714000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-01-11 13:07:54.324000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.014",
	"createdAt": "2024-01-15 08:53:26.754000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.172",
	"createdAt": "2024-01-16 10:03:57.027000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.094",
	"createdAt": "2024-01-14 16:35:25.706000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.038",
	"createdAt": "2024-01-25 12:28:49.215000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "michalh@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.026",
	"createdAt": "2024-03-06 14:41:53.313000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.017",
	"createdAt": "2024-05-22 10:08:54.122000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.996",
	"createdAt": "2024-01-26 15:34:54.492000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.944",
	"createdAt": "2024-01-26 21:54:45.642000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.904",
	"createdAt": "2024-02-02 11:00:02.308000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.253",
	"createdAt": "2024-02-02 15:42:41.296000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.559",
	"createdAt": "2024-02-02 16:00:32.428000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.582",
	"createdAt": "2024-02-02 21:45:15.967000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-02-07 17:28:23.695000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.265",
	"createdAt": "2024-02-08 16:03:12.553000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.709",
	"createdAt": "2024-02-16 11:23:43.777000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-16 14:51:55.519000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.853",
	"createdAt": "2024-02-16 22:17:29.669000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-19 11:24:32.949000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.275",
	"createdAt": "2024-02-19 13:44:21.646000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.892",
	"createdAt": "2024-02-21 12:33:11.215000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.333",
	"createdAt": "2024-02-21 14:35:06.214000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.511",
	"createdAt": "2024-02-21 16:16:30.970000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.617",
	"createdAt": "2024-02-22 10:46:45.847000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.489",
	"createdAt": "2024-02-22 21:22:31.256000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.429",
	"createdAt": "2024-02-23 13:22:33.618000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.249",
	"createdAt": "2024-02-23 14:17:06.579000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.614",
	"createdAt": "2024-02-26 21:39:02.413000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.299",
	"createdAt": "2024-02-28 14:09:31.299000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.446",
	"createdAt": "2024-02-28 16:51:58.019000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.343",
	"createdAt": "2024-02-29 14:01:35.391000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.271",
	"createdAt": "2024-03-01 14:53:05.777000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.046",
	"createdAt": "2024-03-11 12:59:39.416000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-12 15:52:30.143000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.04",
	"createdAt": "2024-03-13 21:28:32.605000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.218",
	"createdAt": "2024-03-18 15:36:43.551000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.057",
	"createdAt": "2024-03-18 22:15:40.116000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.421",
	"createdAt": "2024-04-02 21:26:09.027000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.809",
	"createdAt": "2024-04-03 07:12:57.768000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-04-03 15:51:57.595000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.026",
	"createdAt": "2024-04-04 12:44:24.423000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.61",
	"createdAt": "2024-04-05 14:23:26.686000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-08 14:15:09.477000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.472",
	"createdAt": "2024-04-08 21:30:27.419000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.759",
	"createdAt": "2024-04-10 15:45:54.824000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-11 07:47:14.388000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.823",
	"createdAt": "2024-04-15 20:54:04.957000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.37",
	"createdAt": "2024-04-16 13:09:22.815000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-04-17 12:52:33.126000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.014",
	"createdAt": "2024-04-18 09:17:10.191000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.647",
	"createdAt": "2024-04-19 09:56:51.547000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.533",
	"createdAt": "2024-04-25 10:20:36.611000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.168",
	"createdAt": "2024-05-02 08:13:13.474000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.977",
	"createdAt": "2024-05-02 08:25:12.291000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.837",
	"createdAt": "2024-05-08 13:45:17.023000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.018",
	"createdAt": "2024-02-20 13:49:39.725000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.216",
	"createdAt": "2024-02-20 13:55:26.406000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.084",
	"createdAt": "2024-03-11 08:52:22.409000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.214",
	"createdAt": "2024-03-29 14:59:23.558000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.582",
	"createdAt": "2024-04-10 10:28:33.186000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-10 11:13:18.361000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.235",
	"createdAt": "2024-04-10 15:21:06.414000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.788",
	"createdAt": "2024-04-11 08:27:20.182000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.286",
	"createdAt": "2024-04-11 13:10:36.578000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.637",
	"createdAt": "2024-04-15 10:39:47.850000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.449",
	"createdAt": "2024-04-17 09:50:49.472000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.036",
	"createdAt": "2024-01-04 00:42:35.644000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nadzeyam@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.225",
	"createdAt": "2024-01-04 11:11:42.240000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliala@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.977",
	"createdAt": "2024-02-02 09:25:08.589000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliala@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.033",
	"createdAt": "2024-02-20 19:05:31.778000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliala@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.767",
	"createdAt": "2024-02-20 19:10:29.542000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliala@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.365",
	"createdAt": "2024-02-27 14:53:26.094000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliala@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.462",
	"createdAt": "2024-03-26 20:22:00.053000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.4",
	"createdAt": "2024-03-26 15:09:26.234000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.23",
	"createdAt": "2024-03-26 20:30:55.138000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.97",
	"createdAt": "2024-03-27 15:28:37.849000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.47",
	"createdAt": "2024-03-28 09:04:50.300000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.518",
	"createdAt": "2024-03-28 09:35:04.331000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.011",
	"createdAt": "2024-03-28 10:09:28.789000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-03-28 13:45:56.617000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.644",
	"createdAt": "2024-03-28 16:08:14.766000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.97",
	"createdAt": "2024-03-29 11:18:48.203000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.11",
	"createdAt": "2024-03-29 16:09:17.681000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.897",
	"createdAt": "2024-04-01 19:17:09.280000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-04-02 14:53:49.822000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.04",
	"createdAt": "2024-04-02 18:52:54.442000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.737",
	"createdAt": "2024-04-02 19:45:23.179000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.013",
	"createdAt": "2024-04-03 22:24:15.308000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.417",
	"createdAt": "2024-04-04 12:00:35.835000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.549",
	"createdAt": "2024-04-05 14:47:01.816000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.91",
	"createdAt": "2024-04-15 08:52:28.987000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.008",
	"createdAt": "2024-04-15 12:22:28.279000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-04-16 10:51:23.568000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.001",
	"createdAt": "2024-04-17 14:16:52.359000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.836",
	"createdAt": "2024-04-17 14:24:40.420000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.489",
	"createdAt": "2024-04-18 08:24:10.984000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.969",
	"createdAt": "2024-04-18 15:02:54.988000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.136",
	"createdAt": "2024-04-18 21:22:20.329000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-04-19 11:43:02.826000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.921",
	"createdAt": "2024-04-23 11:38:37.811000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.995",
	"createdAt": "2024-04-25 07:11:44.144000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.919",
	"createdAt": "2024-05-01 11:25:54.315000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.105",
	"createdAt": "2024-05-02 09:43:59.175000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.087",
	"createdAt": "2024-05-05 21:29:35.900000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.004",
	"createdAt": "2024-05-10 08:17:33.390000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.132",
	"createdAt": "2024-05-13 13:56:53.015000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.596",
	"createdAt": "2024-05-22 08:50:52.369000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.345",
	"createdAt": "2024-05-27 21:05:47.534000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.575",
	"createdAt": "2024-05-28 14:58:06.076000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.63",
	"createdAt": "2024-05-29 10:12:11.565000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.298",
	"createdAt": "2024-05-29 14:31:20.479000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "nataliao@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.994",
	"createdAt": "2024-05-30 09:52:05.641000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-14 12:30:40.094000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.469",
	"createdAt": "2024-03-12 08:45:36.306000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.942",
	"createdAt": "2024-03-20 09:06:21.562000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.958",
	"createdAt": "2024-03-20 13:18:08.599000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.604",
	"createdAt": "2024-03-20 13:37:38.457000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.062",
	"createdAt": "2024-03-20 14:30:43.509000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.157",
	"createdAt": "2024-03-24 13:40:23.072000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.003",
	"createdAt": "2024-03-26 12:55:07.117000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.24",
	"createdAt": "2024-03-24 13:40:57.008000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.01",
	"createdAt": "2024-03-24 14:18:22.248000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-04 15:28:02.275000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-04 15:59:25.841000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.031",
	"createdAt": "2024-02-19 09:49:09.241000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.02",
	"createdAt": "2024-02-01 11:03:43.667000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.587",
	"createdAt": "2024-02-01 11:07:46.038000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.434",
	"createdAt": "2024-02-01 11:52:39.745000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.935",
	"createdAt": "2024-02-02 06:57:09.178000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.703",
	"createdAt": "2024-02-02 11:46:59.331000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.42",
	"createdAt": "2024-02-06 08:53:30.423000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.637",
	"createdAt": "2024-02-07 13:10:43.144000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.082",
	"createdAt": "2024-02-08 08:58:22.722000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.805",
	"createdAt": "2024-02-08 10:06:16.336000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.571",
	"createdAt": "2024-02-08 11:01:50.187000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.434",
	"createdAt": "2024-02-08 11:38:35.696000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-02-08 13:09:58.610000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.816",
	"createdAt": "2024-02-08 14:13:26.780000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.854",
	"createdAt": "2024-02-08 15:07:16.891000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.981",
	"createdAt": "2024-02-08 17:01:02.688000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.491",
	"createdAt": "2024-02-09 15:07:45.799000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.758",
	"createdAt": "2024-02-12 07:09:58.720000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.837",
	"createdAt": "2024-02-12 14:00:51.873000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.026",
	"createdAt": "2024-02-14 13:00:40.651000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.123",
	"createdAt": "2024-02-14 14:04:27.319000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.929",
	"createdAt": "2024-02-14 14:28:59.276000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.835",
	"createdAt": "2024-02-15 09:26:37.849000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "5.006",
	"createdAt": "2024-02-16 10:29:08.072000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.969",
	"createdAt": "2024-02-20 11:39:52.679000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "7.505",
	"createdAt": "2024-02-22 09:14:54.829000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.253",
	"createdAt": "2024-02-23 08:37:13.140000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.359",
	"createdAt": "2024-02-23 10:54:53.214000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.836",
	"createdAt": "2024-02-23 11:21:22.539000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.628",
	"createdAt": "2024-02-26 07:08:37.359000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.836",
	"createdAt": "2024-02-26 09:15:20.405000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.835",
	"createdAt": "2024-02-27 10:46:52.401000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-27 11:59:04.693000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.836",
	"createdAt": "2024-03-29 12:16:57.978000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.008",
	"createdAt": "2024-05-31 06:38:16.938000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.211",
	"createdAt": "2024-02-16 08:59:26.209000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.148",
	"createdAt": "2024-02-16 12:32:31.490000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.348",
	"createdAt": "2024-02-01 09:02:59.311000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.497",
	"createdAt": "2024-02-02 09:24:46.206000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.64",
	"createdAt": "2024-02-02 15:09:56.059000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.747",
	"createdAt": "2024-02-05 09:02:09.104000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.475",
	"createdAt": "2024-02-05 12:15:52.017000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.392",
	"createdAt": "2024-02-06 09:46:41.229000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.251",
	"createdAt": "2024-02-09 10:55:21.703000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.012",
	"createdAt": "2024-02-13 14:17:14.118000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.153",
	"createdAt": "2024-02-13 14:22:36.818000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.249",
	"createdAt": "2024-02-16 08:15:03.825000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.086",
	"createdAt": "2024-02-16 08:42:53.617000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.98",
	"createdAt": "2024-03-01 11:09:40.378000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.24",
	"createdAt": "2024-03-01 13:30:27.050000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.704",
	"createdAt": "2024-03-04 09:11:17.731000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-03-04 12:43:24.528000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svetlanasa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.072",
	"createdAt": "2024-03-04 13:58:12.915000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.919",
	"createdAt": "2024-02-02 09:24:43.292000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.385",
	"createdAt": "2024-02-06 15:28:29.244000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.011",
	"createdAt": "2024-02-11 17:27:26.166000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.279",
	"createdAt": "2024-02-11 18:55:53.233000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.772",
	"createdAt": "2024-02-27 13:43:45.233000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.997",
	"createdAt": "2024-02-29 09:08:09.240000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.017",
	"createdAt": "2024-03-11 06:25:07.483000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.091",
	"createdAt": "2024-03-12 13:41:05.578000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.064",
	"createdAt": "2024-03-13 14:38:41.068000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.304",
	"createdAt": "2024-03-14 10:42:07.950000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.941",
	"createdAt": "2024-03-26 17:02:11.723000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.366",
	"createdAt": "2024-03-28 08:56:19.277000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.312",
	"createdAt": "2024-03-28 10:32:10.995000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.914",
	"createdAt": "2024-04-04 08:45:55.712000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.36",
	"createdAt": "2024-04-04 13:49:03.591000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-04-04 16:02:56.903000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-05 07:28:11.959000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-05 09:44:59.946000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.169",
	"createdAt": "2024-04-16 10:44:46.707000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.351",
	"createdAt": "2024-04-22 18:55:25.408000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.691",
	"createdAt": "2024-04-23 14:11:58.391000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.282",
	"createdAt": "2024-04-24 09:28:26.132000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-25 13:45:09.148000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.189",
	"createdAt": "2024-05-01 10:36:28.746000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-01 11:47:59.253000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.405",
	"createdAt": "2024-05-06 10:04:42.084000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.551",
	"createdAt": "2024-05-06 11:39:21.198000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-09 08:46:49.913000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.196",
	"createdAt": "2024-05-10 09:22:52.230000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.722",
	"createdAt": "2024-05-10 14:49:00.700000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-14 11:34:31.132000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.447",
	"createdAt": "2024-05-16 13:02:24.100000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.56",
	"createdAt": "2024-05-17 13:40:01.713000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.537",
	"createdAt": "2024-05-22 13:00:53.252000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.94",
	"createdAt": "2024-05-27 09:00:56.506000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.508",
	"createdAt": "2024-05-27 10:58:34.327000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.027",
	"createdAt": "2024-05-28 09:42:27.778000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.369",
	"createdAt": "2024-05-28 10:58:58.405000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.25",
	"createdAt": "2024-05-29 09:16:48.602000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.01",
	"createdAt": "2024-01-04 10:48:30.609000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.972",
	"createdAt": "2024-01-06 16:39:54.851000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.198",
	"createdAt": "2024-01-28 19:53:14.992000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.762",
	"createdAt": "2024-01-29 12:19:24.749000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.205",
	"createdAt": "2024-01-31 13:40:05.003000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.765",
	"createdAt": "2024-01-31 18:10:02.454000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.271",
	"createdAt": "2024-02-01 08:19:43.607000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.762",
	"createdAt": "2024-02-01 11:50:42.644000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-01 12:59:18.129000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-04 11:49:56.335000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-02-05 07:34:06.620000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.157",
	"createdAt": "2024-02-05 07:54:02.585000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.329",
	"createdAt": "2024-02-05 14:01:16.805000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.779",
	"createdAt": "2024-02-13 13:27:25.057000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-13 16:13:47.117000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.257",
	"createdAt": "2024-02-14 15:51:19.289000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.329",
	"createdAt": "2024-02-15 16:56:58.810000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "FCUS-001",
	"hardware": "LG-001",
	"sumHours": "0.148",
	"createdAt": "2024-03-28 08:46:09.563000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "svitlanak@playtika.com",
	"app": "FCUS-001",
	"hardware": "LG-001",
	"sumHours": "1.009",
	"createdAt": "2024-05-09 12:26:13.659000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "tatyanase@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.017",
	"createdAt": "2024-03-26 07:44:35.206000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "tatyanase@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.064",
	"createdAt": "2024-04-01 10:43:33.910000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "tatyanase@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.389",
	"createdAt": "2024-04-01 13:05:23.722000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "tatyanase@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-30 14:45:39.821000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "tatyanase@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.133",
	"createdAt": "2024-05-27 14:15:40.858000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-26 15:09:48.373000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.714",
	"createdAt": "2024-02-11 19:58:51.463000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.486",
	"createdAt": "2024-02-11 20:44:19.471000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.488",
	"createdAt": "2024-02-13 09:38:16.887000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.197",
	"createdAt": "2024-02-13 11:46:22.169000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.624",
	"createdAt": "2024-02-14 15:25:09.180000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.09",
	"createdAt": "2024-02-15 09:34:16.683000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.418",
	"createdAt": "2024-02-15 12:46:45.790000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.303",
	"createdAt": "2024-02-15 14:22:36.674000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.802",
	"createdAt": "2024-02-15 14:45:29.305000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.648",
	"createdAt": "2024-02-15 16:15:11.232000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.999",
	"createdAt": "2024-02-16 08:39:34.395000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.082",
	"createdAt": "2024-02-16 13:10:39.457000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.726",
	"createdAt": "2024-02-16 14:33:37.973000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.073",
	"createdAt": "2024-02-16 15:56:28.770000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.862",
	"createdAt": "2024-02-19 09:08:58.237000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.935",
	"createdAt": "2024-02-19 14:07:06.623000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.093",
	"createdAt": "2024-02-20 10:39:37.512000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.533",
	"createdAt": "2024-02-20 13:48:24.185000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.364",
	"createdAt": "2024-02-20 16:19:47.238000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.624",
	"createdAt": "2024-02-21 09:03:18.452000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.999",
	"createdAt": "2024-02-21 13:26:09.566000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.693",
	"createdAt": "2024-02-22 08:36:36.812000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.444",
	"createdAt": "2024-02-22 10:26:34.899000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.412",
	"createdAt": "2024-02-22 12:31:37.556000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.627",
	"createdAt": "2024-02-22 14:36:47.429000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.68",
	"createdAt": "2024-02-23 14:00:11.815000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.066",
	"createdAt": "2024-02-26 15:52:14.404000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.502",
	"createdAt": "2024-02-27 12:36:24.273000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.807",
	"createdAt": "2024-02-27 14:09:12.280000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.524",
	"createdAt": "2024-02-27 15:12:30.577000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.994",
	"createdAt": "2024-02-28 16:51:40.845000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.233",
	"createdAt": "2024-02-29 09:52:59.761000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.501",
	"createdAt": "2024-02-29 12:14:43.003000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.155",
	"createdAt": "2024-03-01 13:39:08.368000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.024",
	"createdAt": "2024-03-04 13:01:30.306000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.698",
	"createdAt": "2024-03-04 13:12:34.234000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.605",
	"createdAt": "2024-03-04 14:09:33.397000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.167",
	"createdAt": "2024-03-04 14:48:14.339000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.657",
	"createdAt": "2024-03-04 15:00:23.991000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.295",
	"createdAt": "2024-03-04 16:33:20.643000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.378",
	"createdAt": "2024-03-05 08:02:32.084000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.827",
	"createdAt": "2024-03-05 08:48:07.824000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.634",
	"createdAt": "2024-03-05 09:55:37.000000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.188",
	"createdAt": "2024-03-05 10:35:30.229000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.535",
	"createdAt": "2024-03-05 12:04:36.125000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.096",
	"createdAt": "2024-03-05 12:45:20.983000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.625",
	"createdAt": "2024-03-05 12:58:33.481000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.486",
	"createdAt": "2024-03-05 13:57:28.623000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.478",
	"createdAt": "2024-03-05 14:46:32.020000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.232",
	"createdAt": "2024-03-06 09:37:02.525000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.274",
	"createdAt": "2024-03-07 12:49:28.964000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.319",
	"createdAt": "2024-03-07 15:42:11.571000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.547",
	"createdAt": "2024-03-08 10:02:39.277000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.396",
	"createdAt": "2024-03-11 14:49:12.856000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.362",
	"createdAt": "2024-03-12 15:33:07.813000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.141",
	"createdAt": "2024-03-12 16:08:59.183000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.393",
	"createdAt": "2024-03-13 10:23:38.044000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.093",
	"createdAt": "2024-03-14 17:48:45.005000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.621",
	"createdAt": "2024-03-18 09:58:07.436000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.135",
	"createdAt": "2024-03-22 12:20:01.770000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.237",
	"createdAt": "2024-03-22 13:43:09.368000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.134",
	"createdAt": "2024-03-28 09:56:34.970000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.311",
	"createdAt": "2024-03-28 11:07:44.193000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.592",
	"createdAt": "2024-04-12 11:53:44.002000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.364",
	"createdAt": "2024-04-12 13:57:21.908000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.695",
	"createdAt": "2024-04-15 11:32:21.869000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.45",
	"createdAt": "2024-04-15 13:34:40.209000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.222",
	"createdAt": "2024-04-19 09:41:37.981000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.356",
	"createdAt": "2024-04-19 11:39:14.648000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.321",
	"createdAt": "2024-04-19 12:09:48.000000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.788",
	"createdAt": "2024-04-29 12:59:00.934000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.068",
	"createdAt": "2024-04-29 14:10:37.931000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.119",
	"createdAt": "2024-04-29 16:33:24.194000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.602",
	"createdAt": "2024-04-29 17:24:44.376000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "vladimira@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-02 08:13:38.884000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volhak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.161",
	"createdAt": "2024-01-04 11:46:53.122000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volhak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.972",
	"createdAt": "2024-01-08 09:27:21.026000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volhak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.32",
	"createdAt": "2024-02-02 09:18:18.537000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.013",
	"createdAt": "2024-01-16 11:06:28.614000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.007",
	"createdAt": "2024-02-08 16:08:15.027000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.134",
	"createdAt": "2024-02-13 15:33:55.269000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.986",
	"createdAt": "2024-02-14 09:32:31.823000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.742",
	"createdAt": "2024-02-20 09:17:43.547000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.21",
	"createdAt": "2024-02-21 15:22:41.703000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.266",
	"createdAt": "2024-02-26 11:20:07.246000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.146",
	"createdAt": "2024-02-27 14:41:11.938000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.823",
	"createdAt": "2024-02-28 13:56:26.173000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.032",
	"createdAt": "2024-03-04 12:26:27.680000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.902",
	"createdAt": "2024-03-04 12:36:28.241000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.261",
	"createdAt": "2024-03-04 14:37:55.276000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.423",
	"createdAt": "2024-03-05 07:40:55.512000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.633",
	"createdAt": "2024-03-05 14:46:00.448000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.117",
	"createdAt": "2024-03-11 06:10:42.143000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.514",
	"createdAt": "2024-03-11 11:32:03.257000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.198",
	"createdAt": "2024-03-11 13:22:51.611000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.361",
	"createdAt": "2024-03-12 14:32:43.258000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.681",
	"createdAt": "2024-03-13 19:51:31.334000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.334",
	"createdAt": "2024-03-18 17:23:35.425000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.504",
	"createdAt": "2024-03-19 14:51:02.722000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.407",
	"createdAt": "2024-03-19 15:56:17.779000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.42",
	"createdAt": "2024-03-20 18:33:29.924000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.491",
	"createdAt": "2024-03-22 06:53:02.834000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.326",
	"createdAt": "2024-03-22 11:33:50.037000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.169",
	"createdAt": "2024-03-26 14:51:23.153000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.539",
	"createdAt": "2024-03-27 10:06:49.525000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.085",
	"createdAt": "2024-03-27 12:56:59.542000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.071",
	"createdAt": "2024-03-27 13:21:58.406000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.323",
	"createdAt": "2024-03-28 08:09:50.682000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.224",
	"createdAt": "2024-03-29 13:32:24.525000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.352",
	"createdAt": "2024-03-29 19:35:59.994000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.859",
	"createdAt": "2024-04-01 14:35:55.778000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.234",
	"createdAt": "2024-04-01 15:48:16.796000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.172",
	"createdAt": "2024-04-02 08:21:46.166000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.35",
	"createdAt": "2024-04-05 16:40:23.926000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.153",
	"createdAt": "2024-04-10 18:16:16.462000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.614",
	"createdAt": "2024-04-11 10:03:19.529000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.482",
	"createdAt": "2024-04-12 07:53:09.773000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.188",
	"createdAt": "2024-04-18 08:04:11.732000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.903",
	"createdAt": "2024-04-23 17:19:48.550000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-24 18:58:52.069000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.366",
	"createdAt": "2024-04-25 06:56:18.241000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.166",
	"createdAt": "2024-04-25 08:09:05.214000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.131",
	"createdAt": "2024-04-25 13:05:49.682000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.446",
	"createdAt": "2024-04-26 11:05:18.864000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.489",
	"createdAt": "2024-04-26 16:32:55.228000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.419",
	"createdAt": "2024-04-29 13:33:25.807000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.337",
	"createdAt": "2024-04-29 16:44:01.978000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.305",
	"createdAt": "2024-05-16 11:01:00.879000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.339",
	"createdAt": "2024-05-17 06:19:55.454000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.101",
	"createdAt": "2024-05-17 10:29:56.880000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.313",
	"createdAt": "2024-05-17 15:19:17.368000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.488",
	"createdAt": "2024-05-20 07:29:38.162000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.244",
	"createdAt": "2024-05-21 13:49:34.346000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.684",
	"createdAt": "2024-05-21 14:15:37.123000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.142",
	"createdAt": "2024-05-21 16:33:54.711000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.185",
	"createdAt": "2024-05-22 13:50:25.313000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.374",
	"createdAt": "2024-05-23 11:33:48.107000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.239",
	"createdAt": "2024-05-23 13:38:45.129000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.646",
	"createdAt": "2024-05-23 20:21:53.813000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.656",
	"createdAt": "2024-05-25 21:39:51.425000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.231",
	"createdAt": "2024-05-27 17:18:04.380000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.718",
	"createdAt": "2024-05-28 08:42:18.354000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.216",
	"createdAt": "2024-05-29 09:09:55.109000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.624",
	"createdAt": "2024-05-29 11:18:19.165000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.139",
	"createdAt": "2024-05-29 12:36:42.910000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.252",
	"createdAt": "2024-05-29 15:44:04.690000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.254",
	"createdAt": "2024-05-29 16:16:55.808000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.258",
	"createdAt": "2024-05-30 07:29:51.271000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.264",
	"createdAt": "2024-05-30 12:07:15.876000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "volham@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.251",
	"createdAt": "2024-05-30 12:28:14.727000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.275",
	"createdAt": "2024-03-20 09:24:18.815000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-20 13:04:42.675000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.514",
	"createdAt": "2024-03-20 13:35:19.463000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.998",
	"createdAt": "2024-03-20 17:22:40.782000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.709",
	"createdAt": "2024-03-20 19:51:48.016000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.645",
	"createdAt": "2024-03-21 10:27:26.933000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.807",
	"createdAt": "2024-03-21 12:19:03.927000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.024",
	"createdAt": "2024-03-21 14:20:44.217000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.993",
	"createdAt": "2024-03-24 07:42:07.544000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-24 10:58:21.381000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-24 12:55:40.338000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.954",
	"createdAt": "2024-03-25 08:30:05.276000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.188",
	"createdAt": "2024-03-25 09:39:53.659000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-25 14:54:14.243000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-03-26 08:11:00.200000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-26 11:46:59.513000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-03-26 13:25:36.655000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-26 15:17:11.652000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-27 08:46:54.124000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-27 11:19:59.686000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-28 12:52:30.832000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-31 09:23:32.076000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-31 10:24:11.941000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-03-31 12:41:04.582000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-31 12:44:20.845000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-31 15:54:52.111000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-01 10:54:10.069000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-04-01 13:20:24.094000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-01 15:41:22.198000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-02 06:14:11.491000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-02 08:18:11.118000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-02 16:57:45.959000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-02 19:08:46.740000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.002",
	"createdAt": "2024-04-03 09:18:52.825000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-03 14:07:17.839000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-04 09:46:14.822000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.017",
	"createdAt": "2024-04-04 13:07:16.440000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.009",
	"createdAt": "2024-04-04 13:46:02.375000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.431",
	"createdAt": "2024-04-04 18:48:09.203000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-07 03:53:53.924000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-07 08:23:43.425000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-07 11:21:58.041000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-07 15:13:20.326000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-08 04:05:31.126000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-08 05:05:27.380000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-08 07:30:03.581000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-08 12:07:47.593000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-08 15:25:58.625000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-09 07:35:25.700000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-09 09:41:49.598000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-09 10:56:03.083000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-09 14:34:09.313000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-09 18:08:53.517000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.017",
	"createdAt": "2024-04-10 05:28:52.787000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-10 08:19:04.639000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-10 12:25:23.925000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-10 15:47:18.491000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.017",
	"createdAt": "2024-04-11 07:32:23.403000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-11 11:55:15.396000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-14 18:38:00.654000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-15 07:27:11.070000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-15 12:47:43.785000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-16 10:25:07.455000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-16 11:54:16.897000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-04-16 14:24:47.900000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.269",
	"createdAt": "2024-04-17 07:42:43.464000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.851",
	"createdAt": "2024-04-17 09:36:23.197000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-17 11:29:27.864000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.043",
	"createdAt": "2024-04-18 12:34:48.460000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-18 12:39:16.011000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-18 12:47:12.607000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-18 13:33:43.042000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-18 15:43:38.665000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-21 13:03:40.921000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-21 17:15:11.893000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.066",
	"createdAt": "2024-04-23 12:40:34.797000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-24 08:15:49.887000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-24 11:55:04.229000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-24 15:03:52.418000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-25 06:27:46.344000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-25 09:02:28.500000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-25 12:14:55.169000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-25 14:03:35.255000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-30 09:19:41.732000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.006",
	"createdAt": "2024-05-01 13:35:47.405000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-02 09:45:54.256000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-02 12:54:40.431000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-02 15:39:43.795000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-02 20:09:18.309000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-02 20:27:29.461000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-07 06:16:30.577000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-07 13:48:21.721000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-08 11:30:32.549000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-09 08:09:54.763000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-09 12:29:59.345000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-09 13:33:26.367000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-10 08:14:02.637000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-12 07:22:23.494000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-12 11:33:11.743000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-12 20:34:35.178000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-13 07:02:23.652000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-15 07:05:31.843000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-15 14:19:33.832000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-16 08:21:14.500000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-16 18:55:01.459000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-16 21:18:26.116000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-19 07:07:03.043000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-19 14:28:50.949000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-19 16:50:13.228000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-21 04:44:21.254000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.002",
	"createdAt": "2024-05-21 06:47:34.608000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-21 09:12:43.968000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-21 09:18:31.064000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-22 08:14:35.949000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.045",
	"createdAt": "2024-05-22 12:53:32.575000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-22 13:02:09.834000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-22 13:28:33.055000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-22 14:41:08.373000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-22 14:52:53.507000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.017",
	"createdAt": "2024-05-23 02:47:22.387000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-23 10:40:04.726000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-27 07:27:51.365000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-27 13:32:23.116000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-27 15:21:59.859000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-28 11:14:47.180000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-29 14:37:00.084000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-29 16:00:05.476000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.014",
	"createdAt": "2024-05-30 12:53:25.695000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.01",
	"createdAt": "2023-12-20 11:00:28.912000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.003",
	"createdAt": "2023-12-20 11:04:12.805000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "4.011",
	"createdAt": "2024-01-29 11:01:26.786000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-01 13:38:52.595000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "FCFZ-001",
	"hardware": "LG-002",
	"sumHours": "2.004",
	"createdAt": "2024-04-23 12:47:38.311000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "FCFZ-001",
	"hardware": "LG-002",
	"sumHours": "2.009",
	"createdAt": "2024-04-23 13:26:36.390000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.098",
	"createdAt": "2024-05-22 12:51:35.754000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "1.532",
	"createdAt": "2024-04-17 08:01:23.934000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.063",
	"createdAt": "2024-04-23 11:30:17.981000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.001",
	"createdAt": "2024-04-23 11:36:56.815000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yanash@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.955",
	"createdAt": "2024-04-23 11:41:49.236000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.352",
	"createdAt": "2024-01-26 15:09:47.429000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.08",
	"createdAt": "2024-01-26 15:34:09.103000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.057",
	"createdAt": "2024-02-01 18:04:20.793000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.382",
	"createdAt": "2024-02-01 19:10:55.833000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.223",
	"createdAt": "2024-02-02 11:51:13.804000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.55",
	"createdAt": "2024-02-04 16:05:30.191000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-09 09:31:33.465000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.928",
	"createdAt": "2024-02-09 12:49:20.034000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.574",
	"createdAt": "2024-02-19 14:15:34.310000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.091",
	"createdAt": "2024-02-20 16:12:26.592000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.28",
	"createdAt": "2024-03-01 14:58:35.381000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.898",
	"createdAt": "2024-04-09 14:49:47.770000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.762",
	"createdAt": "2024-04-10 07:46:03.856000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-22 08:11:19.602000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.413",
	"createdAt": "2024-04-30 09:02:50.329000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-30 09:39:24.905000 UTC"
}, {
	"teamName": "Playtika - BB",
	"email": "yuliyakal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.344",
	"createdAt": "2024-05-02 14:52:23.676000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "agatah@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.691",
	"createdAt": "2024-01-22 13:20:10.885000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "agatah@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.562",
	"createdAt": "2024-05-06 08:14:09.859000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "almogb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-01-22 13:35:41.095000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "arynam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-22 13:19:37.191000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "arynam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-01-22 13:47:33.154000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "arynam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-01-22 14:30:39.633000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "arynam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.01",
	"createdAt": "2024-03-06 14:48:24.224000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "arynam@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-05-06 08:13:04.927000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "aviorn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-22 13:23:05.349000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "dzmitrya@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.513",
	"createdAt": "2024-02-01 10:14:08.116000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "dzmitrya@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-06 08:11:50.646000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "dzmitrya@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-01-22 13:41:28.365000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "dzmitrya@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-31 09:17:00.783000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "dzmitrya@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-01-31 12:18:34.930000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "liorra@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-01-22 13:41:58.452000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "liorra@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-01-22 13:52:28.345000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "liorra@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-03 12:32:11.456000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "liorra@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.566",
	"createdAt": "2024-03-03 14:43:19.355000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "liorra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-22 13:36:19.130000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-30 08:01:53.506000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-30 14:13:37.799000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.712",
	"createdAt": "2024-05-02 09:32:29.253000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-05 19:53:08.423000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-06 07:06:45.791000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.0",
	"createdAt": "2024-05-06 08:07:50.391000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "mariasz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-22 13:19:30.990000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "mariasz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-03 14:47:10.597000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "mariasz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.261",
	"createdAt": "2024-04-03 20:24:02.899000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "melodyn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-01-22 13:19:34.331000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "mikih@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.818",
	"createdAt": "2024-05-06 12:27:51.273000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "natalliap@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.894",
	"createdAt": "2024-01-22 13:19:31.391000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "nirl@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-22 13:19:38.266000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "nirl@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-02-29 12:07:43.219000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "nirl@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-02 15:04:57.179000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "ronya@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.441",
	"createdAt": "2024-05-06 11:31:43.820000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.041",
	"createdAt": "2024-01-22 13:19:29.963000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-01-25 11:54:44.765000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.416",
	"createdAt": "2024-01-25 11:55:10.135000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.543",
	"createdAt": "2024-01-26 14:02:15.355000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.313",
	"createdAt": "2024-01-26 14:54:16.941000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.39",
	"createdAt": "2024-02-13 11:51:37.428000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.023",
	"createdAt": "2024-02-15 11:36:00.112000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.745",
	"createdAt": "2024-04-02 08:37:06.125000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.506",
	"createdAt": "2024-04-02 12:08:44.791000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.541",
	"createdAt": "2024-04-02 13:53:34.647000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.418",
	"createdAt": "2024-04-03 12:56:32.999000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.1",
	"createdAt": "2024-04-03 13:26:28.564000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.051",
	"createdAt": "2024-04-03 14:44:00.840000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.199",
	"createdAt": "2024-04-05 11:10:42.717000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.124",
	"createdAt": "2024-04-05 11:26:56.672000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.713",
	"createdAt": "2024-04-05 11:47:08.929000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.768",
	"createdAt": "2024-04-24 10:02:24.303000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.091",
	"createdAt": "2024-04-24 13:09:51.418000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.725",
	"createdAt": "2024-04-25 08:23:44.118000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.808",
	"createdAt": "2024-05-06 08:07:10.071000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.157",
	"createdAt": "2024-05-07 08:49:08.282000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.135",
	"createdAt": "2024-05-21 11:35:37.154000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.288",
	"createdAt": "2024-05-21 13:09:12.906000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.045",
	"createdAt": "2024-03-13 13:58:07.881000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "sebastianb@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.027",
	"createdAt": "2024-01-26 14:51:11.136000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "shaharo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-06 08:09:54.910000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "shaharo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.934",
	"createdAt": "2024-05-06 11:31:24.510000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "shaharo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.549",
	"createdAt": "2024-05-07 14:02:05.226000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "shirant@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.451",
	"createdAt": "2024-05-06 11:30:06.269000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "shirant@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.391",
	"createdAt": "2024-05-07 10:33:44.340000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.964",
	"createdAt": "2024-01-09 13:45:29.424000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.619",
	"createdAt": "2024-01-15 10:51:39.033000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.62",
	"createdAt": "2024-01-22 12:10:33.831000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.043",
	"createdAt": "2024-01-22 14:50:18.330000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.325",
	"createdAt": "2024-01-29 14:31:20.729000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-22 13:19:42.335000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.467",
	"createdAt": "2024-02-13 11:42:52.877000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.003",
	"createdAt": "2024-02-15 11:40:25.334000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.341",
	"createdAt": "2024-02-15 12:48:00.117000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-20 08:54:05.592000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.55",
	"createdAt": "2024-02-20 08:58:40.963000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-20 10:21:50.173000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.516",
	"createdAt": "2024-02-20 12:58:45.236000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.439",
	"createdAt": "2024-02-21 10:44:25.144000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-02-26 12:45:19.475000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.026",
	"createdAt": "2024-02-27 09:44:24.794000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.008",
	"createdAt": "2024-02-27 09:50:26.776000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.303",
	"createdAt": "2024-03-06 14:27:28.332000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.994",
	"createdAt": "2024-03-13 12:20:12.459000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-14 10:22:54.683000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-03-18 07:52:27.053000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.017",
	"createdAt": "2024-04-12 05:32:48.498000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-04-23 08:34:20.026000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-06 08:07:21.522000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-10 08:12:28.149000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-12 08:00:11.831000 UTC"
}, {
	"teamName": "Playtika - BF",
	"email": "tomaszj@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-18 07:56:28.505000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-01-18 08:38:12.978000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-01-19 08:13:58.249000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.042",
	"createdAt": "2024-01-19 15:48:21.870000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.554",
	"createdAt": "2024-01-19 15:53:38.611000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.497",
	"createdAt": "2024-01-20 07:20:35.341000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.504",
	"createdAt": "2024-01-21 08:58:44.605000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.762",
	"createdAt": "2024-01-21 10:01:10.476000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.611",
	"createdAt": "2024-01-21 12:30:44.391000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.273",
	"createdAt": "2024-01-21 15:22:59.428000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.4",
	"createdAt": "2024-01-21 20:44:32.485000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.313",
	"createdAt": "2024-01-22 07:53:35.778000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.047",
	"createdAt": "2024-01-24 16:25:17.486000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.459",
	"createdAt": "2024-01-24 16:33:33.890000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-01-25 14:08:24.566000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.031",
	"createdAt": "2024-01-25 15:19:38.168000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.957",
	"createdAt": "2024-01-26 12:16:33.401000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.865",
	"createdAt": "2024-01-27 13:06:31.585000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.257",
	"createdAt": "2024-01-29 08:37:40.005000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.155",
	"createdAt": "2024-01-29 08:58:19.703000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.059",
	"createdAt": "2024-01-29 09:28:38.120000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.371",
	"createdAt": "2024-02-04 07:41:22.990000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.295",
	"createdAt": "2024-02-04 13:07:40.016000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.168",
	"createdAt": "2024-02-04 16:38:13.752000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.237",
	"createdAt": "2024-02-07 07:59:16.815000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.462",
	"createdAt": "2024-02-07 09:07:06.750000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.284",
	"createdAt": "2024-02-07 12:42:50.630000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.994",
	"createdAt": "2024-02-12 19:41:13.678000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.63",
	"createdAt": "2024-02-13 12:08:16.589000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.094",
	"createdAt": "2024-02-13 13:55:32.375000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.969",
	"createdAt": "2024-02-13 14:30:12.477000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.177",
	"createdAt": "2024-02-13 22:05:10.054000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.072",
	"createdAt": "2024-02-14 08:38:30.260000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.145",
	"createdAt": "2024-02-14 10:28:56.445000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.676",
	"createdAt": "2024-02-15 13:11:09.166000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.27",
	"createdAt": "2024-02-19 08:55:03.820000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-02-19 15:42:41.440000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.401",
	"createdAt": "2024-02-20 07:24:24.948000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.005",
	"createdAt": "2024-03-08 12:47:50.198000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.667",
	"createdAt": "2024-03-08 13:00:46.181000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.541",
	"createdAt": "2024-03-13 13:44:35.852000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.17",
	"createdAt": "2024-03-13 20:37:17.205000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.565",
	"createdAt": "2024-03-14 19:03:39.555000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.22",
	"createdAt": "2024-03-18 08:26:15.745000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.379",
	"createdAt": "2024-03-18 10:09:03.552000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.097",
	"createdAt": "2024-03-24 17:24:22.448000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.869",
	"createdAt": "2024-03-25 05:31:06.774000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.151",
	"createdAt": "2024-03-25 08:30:41.273000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.177",
	"createdAt": "2024-04-02 08:39:00.429000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.357",
	"createdAt": "2024-04-03 07:38:19.466000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.137",
	"createdAt": "2024-04-03 08:02:36.120000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.477",
	"createdAt": "2024-04-04 07:58:05.705000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.927",
	"createdAt": "2024-04-08 13:01:49.261000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-04-10 08:41:10.552000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.829",
	"createdAt": "2024-04-16 14:04:42.779000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.687",
	"createdAt": "2024-04-18 11:43:03.786000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.332",
	"createdAt": "2024-04-25 10:54:51.751000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.057",
	"createdAt": "2024-05-20 08:52:27.053000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "5.069",
	"createdAt": "2024-05-22 07:44:27.547000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-22 13:57:03.015000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "7.0",
	"createdAt": "2024-05-23 06:41:10.712000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.52",
	"createdAt": "2024-05-23 14:03:46.144000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.132",
	"createdAt": "2024-05-25 06:14:57.887000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-25 16:15:12.829000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.06",
	"createdAt": "2024-05-25 16:15:19.490000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.599",
	"createdAt": "2024-05-26 07:14:23.862000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.398",
	"createdAt": "2024-05-26 19:58:33.346000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.078",
	"createdAt": "2024-05-27 08:09:15.117000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "6.168",
	"createdAt": "2024-05-27 08:15:49.510000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.014",
	"createdAt": "2024-05-27 14:28:10.504000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-28 08:41:04.095000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "alexf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.901",
	"createdAt": "2024-05-28 10:48:33.264000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2023-12-06 11:09:54.748000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.857",
	"createdAt": "2024-01-08 08:22:29.905000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.87",
	"createdAt": "2024-01-17 14:56:21.245000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.198",
	"createdAt": "2024-01-18 14:07:02.999000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-13 09:27:17.662000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-13 11:53:02.813000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-02-13 14:08:45.368000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-02-13 16:48:24.553000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.005",
	"createdAt": "2024-02-15 11:04:30.144000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.153",
	"createdAt": "2024-02-18 07:37:25.820000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.003",
	"createdAt": "2023-12-06 08:05:51.908000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.075",
	"createdAt": "2023-12-06 08:09:07.948000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "aviadf@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.12",
	"createdAt": "2023-12-06 10:00:34.440000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "dotans@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-08 08:23:13.971000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "dotans@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.01",
	"createdAt": "2024-02-11 10:44:36.458000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "dotans@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.01",
	"createdAt": "2024-02-12 07:46:36.438000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "dotans@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-02-12 12:34:43.159000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "dotans@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.015",
	"createdAt": "2024-03-03 11:03:48.225000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.004",
	"createdAt": "2024-01-08 08:07:17.377000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-01-08 08:20:24.683000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-01-15 13:16:17.455000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-22 15:56:57.192000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.013",
	"createdAt": "2024-01-23 07:59:21.274000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-01-23 12:04:56.298000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.002",
	"createdAt": "2024-01-23 15:15:28.514000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.001",
	"createdAt": "2024-02-08 09:44:00.986000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.372",
	"createdAt": "2024-02-08 14:20:15.496000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-08 19:45:02.702000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "7.004",
	"createdAt": "2024-02-10 16:02:46.871000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.006",
	"createdAt": "2024-02-11 07:08:19.274000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.004",
	"createdAt": "2024-02-11 12:58:42.810000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.001",
	"createdAt": "2024-02-12 07:07:28.624000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-02-12 11:53:17.203000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-12 16:15:21.760000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-12 16:18:10.500000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.013",
	"createdAt": "2024-02-13 06:15:02.123000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-13 13:10:11.540000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.006",
	"createdAt": "2024-02-13 14:14:04.282000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-02-14 07:30:53.075000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "8.012",
	"createdAt": "2024-02-14 10:52:31.450000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-14 17:11:52.299000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-15 08:25:08.009000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-18 12:09:38.177000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-28 12:34:32.697000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-28 13:46:32.051000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-03 11:04:41.017000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-03 11:07:19.881000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-03-03 13:21:40.997000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-03-03 15:26:29.419000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "8.01",
	"createdAt": "2024-03-04 08:46:07.345000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.011",
	"createdAt": "2024-03-04 14:20:29.678000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.002",
	"createdAt": "2024-03-18 12:45:08.348000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-18 15:17:58.458000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-19 09:10:12.550000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.014",
	"createdAt": "2024-03-26 13:32:24.890000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-03-27 11:57:07.762000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-28 13:11:58.497000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-31 07:57:28.210000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.015",
	"createdAt": "2024-03-31 10:30:26.319000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.01",
	"createdAt": "2024-03-31 12:52:45.615000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-01 07:55:40.073000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.011",
	"createdAt": "2024-04-01 09:07:06.546000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-01 14:55:41.783000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "8.013",
	"createdAt": "2024-04-02 08:59:36.147000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.012",
	"createdAt": "2024-04-03 07:40:03.321000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.004",
	"createdAt": "2024-04-03 11:57:13.259000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.003",
	"createdAt": "2024-04-03 12:45:07.933000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.014",
	"createdAt": "2024-04-04 08:56:28.961000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.002",
	"createdAt": "2024-04-04 13:00:54.272000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-07 07:14:44.111000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "8.006",
	"createdAt": "2024-04-07 09:20:01.257000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.011",
	"createdAt": "2024-04-09 10:14:18.018000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-04-11 11:00:43.847000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-15 11:47:09.336000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "8.013",
	"createdAt": "2024-04-17 06:51:44.794000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-05 07:35:30.700000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "9.005",
	"createdAt": "2024-05-12 07:17:14.491000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-15 07:21:36.088000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.004",
	"createdAt": "2024-05-29 07:13:08.052000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.172",
	"createdAt": "2024-05-29 13:08:32.245000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.009",
	"createdAt": "2024-05-29 13:11:31.045000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "9.253",
	"createdAt": "2024-05-30 06:35:52.160000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.905",
	"createdAt": "2024-05-30 13:20:24.394000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "2.015",
	"createdAt": "2024-02-28 12:46:04.332000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.077",
	"createdAt": "2024-04-11 11:42:15.196000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "ellave@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-11 11:50:30.945000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.192",
	"createdAt": "2023-12-04 09:11:49.228000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.396",
	"createdAt": "2023-12-28 09:31:10.911000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.024",
	"createdAt": "2023-12-28 13:01:32.687000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.071",
	"createdAt": "2023-12-28 13:14:24.412000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.827",
	"createdAt": "2023-12-31 10:56:09.576000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.395",
	"createdAt": "2024-01-07 13:37:47.061000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.843",
	"createdAt": "2024-03-25 10:27:36.355000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.017",
	"createdAt": "2024-04-07 08:33:07.410000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "galr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.733",
	"createdAt": "2024-01-08 08:28:46.758000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "galr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.393",
	"createdAt": "2024-01-08 09:15:06.570000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "galr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.099",
	"createdAt": "2023-12-06 08:05:06.258000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "galr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.534",
	"createdAt": "2023-12-06 09:00:10.604000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "galr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2023-12-06 09:45:28.181000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "galr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.011",
	"createdAt": "2023-12-13 01:22:50.788000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.075",
	"createdAt": "2024-01-08 08:21:01.027000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.795",
	"createdAt": "2024-01-08 08:27:41.148000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-08 13:53:23.233000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-01-09 08:38:14.247000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.348",
	"createdAt": "2024-01-09 11:56:09.705000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.084",
	"createdAt": "2024-01-10 10:11:33.245000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-01-29 14:43:42.477000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-02-06 10:04:43.310000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-06 14:04:31.272000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-13 10:21:15.145000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-13 12:35:02.648000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-02-15 06:11:10.585000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-26 08:21:42.303000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-13 10:28:00.571000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2023-12-06 10:00:55.519000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.005",
	"createdAt": "2023-12-06 10:05:55.324000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2023-12-06 13:13:24.794000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "irenan@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "4.01",
	"createdAt": "2023-12-12 10:27:28.235000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lisas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-08 08:23:56.084000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.189",
	"createdAt": "2024-04-04 11:51:27.478000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.707",
	"createdAt": "2024-04-07 12:12:56.746000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-08 07:53:51.679000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-10 12:34:57.062000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-15 06:58:10.376000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.802",
	"createdAt": "2024-04-15 11:10:46.281000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-16 10:34:22.579000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.022",
	"createdAt": "2024-04-17 08:05:23.673000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.275",
	"createdAt": "2024-04-17 08:17:59.013000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-02 13:43:54.772000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-28 07:49:20.903000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-30 08:09:34.485000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-30 12:24:57.685000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-01-07 14:01:19.334000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "nemoe@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-01-08 08:22:58.149000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "nemoe@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.007",
	"createdAt": "2024-01-23 13:58:27.718000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "nemoe@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-01-30 08:46:57.061000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "nirsh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.011",
	"createdAt": "2024-01-15 15:04:24.252000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "nirsh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.013",
	"createdAt": "2024-01-16 12:05:07.908000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shaio@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.007",
	"createdAt": "2024-04-10 15:04:28.943000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shaio@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.004",
	"createdAt": "2024-04-10 15:06:21.449000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-12 08:49:10.866000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2023-12-27 15:22:31.943000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.662",
	"createdAt": "2023-12-28 08:02:51.653000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.065",
	"createdAt": "2023-12-28 08:45:06.010000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.052",
	"createdAt": "2023-12-28 08:52:56.680000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.069",
	"createdAt": "2023-12-28 10:17:04.999000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.191",
	"createdAt": "2023-12-28 15:33:18.800000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.017",
	"createdAt": "2023-12-28 16:04:42.247000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.075",
	"createdAt": "2023-12-28 21:45:04.329000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.164",
	"createdAt": "2024-01-01 16:08:22.780000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.161",
	"createdAt": "2024-01-02 12:30:25.375000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-07 12:34:30.145000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.007",
	"createdAt": "2024-01-07 12:43:51.244000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.008",
	"createdAt": "2024-01-07 13:03:36.394000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.223",
	"createdAt": "2024-01-07 13:19:33.770000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.787",
	"createdAt": "2024-01-07 14:20:03.608000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.351",
	"createdAt": "2024-01-07 14:40:25.852000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.285",
	"createdAt": "2024-01-07 15:13:02.491000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.381",
	"createdAt": "2024-01-08 12:34:06.198000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.654",
	"createdAt": "2024-01-10 12:07:33.458000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.884",
	"createdAt": "2024-01-15 13:16:29.593000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.704",
	"createdAt": "2024-01-15 15:01:05.139000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-01-18 08:07:21.999000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.646",
	"createdAt": "2024-01-23 08:50:52.888000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.423",
	"createdAt": "2024-01-23 13:05:27.688000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.659",
	"createdAt": "2024-01-31 12:27:30.655000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-04 15:01:13.291000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.436",
	"createdAt": "2024-02-08 09:17:53.931000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-02-14 13:02:00.453000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.66",
	"createdAt": "2023-12-27 11:28:26.729000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.145",
	"createdAt": "2024-01-07 12:47:38.130000 UTC"
}, {
	"teamName": "Playtika - CC",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "1.012",
	"createdAt": "2024-01-23 09:32:07.780000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.011",
	"createdAt": "2024-03-05 17:13:12.663000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.248",
	"createdAt": "2024-03-20 13:07:19.554000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-25 14:18:08.439000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.005",
	"createdAt": "2024-03-25 17:11:00.067000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-03-27 12:14:35.019000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-04 09:55:42.416000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-04 12:05:46.854000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.995",
	"createdAt": "2024-04-04 15:07:54.758000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-05 10:12:47.460000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.011",
	"createdAt": "2024-04-05 13:58:44.790000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.303",
	"createdAt": "2024-04-05 20:18:00.116000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-10 12:43:05.991000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.969",
	"createdAt": "2024-04-11 13:17:53.050000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.009",
	"createdAt": "2024-05-07 14:36:01.312000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-14 10:52:16.125000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.017",
	"createdAt": "2024-05-14 13:51:26.554000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-16 08:32:07.313000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-27 14:52:55.271000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-30 10:36:08.443000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "anastasiia.kulyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-31 12:50:19.826000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "efis@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-28 13:37:04.852000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "efis@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-29 07:48:21.243000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "efis@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-29 16:12:52.271000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-05 12:37:50.165000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-06 12:08:09.825000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-06 12:09:02.616000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-14 13:04:18.021000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-18 13:39:09.406000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-27 12:03:37.776000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-21 11:20:58.752000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-30 12:32:47.619000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-21 10:16:48.172000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-21 14:42:26.649000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-22 13:05:04.233000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-27 07:38:19.984000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-29 08:05:58.569000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "eladha@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-29 10:59:50.804000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2024-03-20 13:08:39.987000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-27 12:26:33.941000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-01 16:02:45.461000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-02 09:26:22.583000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-02 16:42:55.939000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-03 10:30:25.607000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-04 06:41:43.555000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-05 13:38:52.728000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-11 14:34:06.242000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-30 10:20:32.978000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-02 07:35:34.564000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-02 10:01:50.771000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-03 12:15:57.492000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-07 08:26:39.709000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-07 11:45:10.172000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-07 16:37:56.783000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-09 10:53:23.904000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-09 13:41:27.497000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "4.011",
	"createdAt": "2024-05-21 06:52:32.414000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-21 14:07:55.803000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-21 19:03:34.956000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "4.005",
	"createdAt": "2024-05-22 08:06:45.494000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-22 13:22:09.682000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-22 15:38:20.426000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-23 07:44:53.894000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "4.014",
	"createdAt": "2024-05-23 11:11:17.315000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "evgeny.suetov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-27 09:51:03.892000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ext_gabib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-28 13:40:04.856000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ext_gabib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-06-02 11:03:39.187000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "gita.roter@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-28 13:37:02.958000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "gita.roter@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-29 07:58:31.355000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "gita.roter@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-29 11:11:04.519000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "gita.roter@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-30 08:31:07.909000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "gita.roter@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-05-30 11:37:03.199000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "gita.roter@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-30 13:24:24.474000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "gita.roter@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.01",
	"createdAt": "2024-06-02 04:58:31.988000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "gita.roter@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-06-02 10:04:41.123000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ihor.maksymenok@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.304",
	"createdAt": "2024-03-05 13:43:40.433000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ihor.maksymenok@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.157",
	"createdAt": "2024-03-06 12:20:15.018000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ihor.maksymenok@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-13 12:05:49.067000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ihor.maksymenok@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.435",
	"createdAt": "2024-03-13 12:06:13.214000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-15 11:21:03.642000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.0",
	"createdAt": "2024-05-19 09:35:41.205000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-19 12:01:41.112000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.008",
	"createdAt": "2024-05-20 07:23:43.800000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-20 13:02:45.974000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-27 11:27:33.270000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-28 08:22:27.701000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "6.012",
	"createdAt": "2024-05-28 10:27:38.594000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-29 09:11:45.029000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-29 11:38:37.702000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-29 15:41:37.161000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-29 16:23:57.418000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lior.shuster@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.002",
	"createdAt": "2024-05-30 07:36:50.939000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-05 12:39:08.464000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.887",
	"createdAt": "2024-03-05 15:11:35.868000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.939",
	"createdAt": "2024-03-06 12:06:37.123000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.379",
	"createdAt": "2024-03-12 09:06:49.215000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.428",
	"createdAt": "2024-03-13 12:35:19.499000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.47",
	"createdAt": "2024-04-02 13:14:08.306000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.383",
	"createdAt": "2024-04-04 07:29:18.834000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.981",
	"createdAt": "2024-04-04 09:53:31.083000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-05 07:40:27.818000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.288",
	"createdAt": "2024-04-05 14:26:22.612000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.213",
	"createdAt": "2024-04-10 12:01:26.115000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.822",
	"createdAt": "2024-04-10 13:24:54.467000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.685",
	"createdAt": "2024-04-17 10:57:40.422000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.896",
	"createdAt": "2024-04-19 11:26:47.300000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.404",
	"createdAt": "2024-04-21 14:04:31.209000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.117",
	"createdAt": "2024-04-23 07:53:37.534000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.774",
	"createdAt": "2024-04-23 08:12:15.080000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.066",
	"createdAt": "2024-04-23 10:18:50.996000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.9",
	"createdAt": "2024-04-23 15:40:30.774000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.264",
	"createdAt": "2024-04-24 06:48:48.703000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.855",
	"createdAt": "2024-04-24 11:41:53.770000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.313",
	"createdAt": "2024-04-24 15:48:06.212000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.041",
	"createdAt": "2024-04-25 07:42:53.108000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.425",
	"createdAt": "2024-04-25 11:45:36.872000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.263",
	"createdAt": "2024-04-29 08:02:24.366000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.371",
	"createdAt": "2024-04-29 17:20:22.289000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.042",
	"createdAt": "2024-04-30 08:12:02.672000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.471",
	"createdAt": "2024-04-30 14:01:49.349000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.808",
	"createdAt": "2024-05-17 09:14:30.669000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.544",
	"createdAt": "2024-05-20 17:09:59.669000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.717",
	"createdAt": "2024-05-21 13:30:54.413000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.304",
	"createdAt": "2024-05-21 15:09:40.382000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.134",
	"createdAt": "2024-05-22 15:05:26.381000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.31",
	"createdAt": "2024-05-27 06:58:45.197000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.561",
	"createdAt": "2024-05-28 17:02:44.377000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.336",
	"createdAt": "2024-05-28 18:23:11.283000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.008",
	"createdAt": "2024-05-29 11:00:52.447000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.243",
	"createdAt": "2024-05-31 14:00:48.090000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.015",
	"createdAt": "2024-06-02 11:45:40.919000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.48",
	"createdAt": "2024-03-14 13:16:48.909000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.093",
	"createdAt": "2024-03-20 12:50:00.261000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-27 12:14:07.045000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.975",
	"createdAt": "2024-03-27 15:20:32.917000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.284",
	"createdAt": "2024-03-29 09:18:05.351000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.807",
	"createdAt": "2024-05-20 18:41:58.793000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.236",
	"createdAt": "2024-05-20 19:32:25.509000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.838",
	"createdAt": "2024-03-31 19:58:28.205000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "liudmyla.kashchuk@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.022",
	"createdAt": "2024-05-31 15:22:12.050000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-10 13:20:58.264000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.016",
	"createdAt": "2024-03-12 08:45:25.236000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.381",
	"createdAt": "2024-04-17 11:37:42.515000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.193",
	"createdAt": "2024-05-02 10:03:30.907000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-02 11:18:44.062000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.344",
	"createdAt": "2024-05-06 09:47:29.184000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-09 07:07:01.252000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.466",
	"createdAt": "2024-05-21 08:51:52.628000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-21 10:43:33.339000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-28 13:04:30.335000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "manoela.orsi@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-05 12:37:32.635000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "manoela.orsi@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.14",
	"createdAt": "2024-03-06 12:27:44.999000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "manoela.orsi@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.031",
	"createdAt": "2024-03-13 08:49:18.445000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "manoela.orsi@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.509",
	"createdAt": "2024-03-18 11:30:56.252000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "manoela.orsi@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.002",
	"createdAt": "2024-04-07 11:15:55.874000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "manoela.orsi@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.007",
	"createdAt": "2024-04-16 07:07:37.622000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "manoela.orsi@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.066",
	"createdAt": "2024-05-01 08:20:46.710000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "manoela.orsi@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.009",
	"createdAt": "2024-05-06 11:21:59.053000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.506",
	"createdAt": "2024-03-05 14:38:12.336000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-08 10:30:14.552000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-08 10:51:47.326000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-08 13:11:11.174000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-09 08:41:34.523000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-10 13:20:46.011000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-11 13:32:22.528000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-15 12:09:25.262000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-15 15:25:09.820000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-16 08:31:43.131000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-16 10:43:05.147000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-04-17 11:06:27.505000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-18 14:07:02.154000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-19 10:38:05.070000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-23 10:46:58.334000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-23 13:07:12.106000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-23 15:18:34.853000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-24 07:01:17.610000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-04-24 10:53:47.046000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-26 10:55:42.186000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-26 13:19:17.628000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.017",
	"createdAt": "2024-04-29 09:24:45.408000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-29 16:40:03.984000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-02 08:24:22.479000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-02 15:01:35.482000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-07 10:27:48.413000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "maryna.matiash@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-15 12:35:06.149000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.995",
	"createdAt": "2024-02-20 13:54:10.138000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "mikhail.manachyn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.004",
	"createdAt": "2024-03-05 12:37:34.286000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "mikhail.manachyn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.167",
	"createdAt": "2024-03-06 11:22:58.526000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "mikhail.manachyn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-06 12:06:29.977000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "mikhail.manachyn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-13 12:06:28.537000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "mikhail.manachyn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-20 13:07:50.443000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "mikhail.manachyn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-26 09:01:35.080000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "morad.daniel@playtika.com",
	"app": "ACFT-001",
	"hardware": "MD-001",
	"sumHours": "0.135",
	"createdAt": "2024-05-28 15:02:40.599000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "morad.daniel@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.384",
	"createdAt": "2024-05-28 13:37:14.365000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "morad.daniel@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.083",
	"createdAt": "2024-06-02 11:27:40.323000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-11 09:14:03.430000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.006",
	"createdAt": "2024-03-13 08:37:42.981000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-03-14 10:18:25.678000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "5.669",
	"createdAt": "2024-04-02 07:15:22.608000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.982",
	"createdAt": "2024-04-04 11:27:40.166000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-05 07:01:30.743000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-08 11:42:23.373000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.013",
	"createdAt": "2024-04-09 07:31:06.763000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.012",
	"createdAt": "2024-04-10 06:54:57.615000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.814",
	"createdAt": "2024-04-11 07:41:24.442000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-12 07:27:21.880000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-12 11:00:59.976000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-15 06:09:16.456000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-15 08:12:48.781000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-15 11:36:36.738000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-16 06:41:34.138000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-17 07:54:49.645000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-17 11:07:46.485000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.01",
	"createdAt": "2024-04-18 08:20:33.880000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-18 12:42:34.616000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.013",
	"createdAt": "2024-04-19 07:09:50.281000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-19 11:14:21.699000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-22 11:26:15.658000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.836",
	"createdAt": "2024-04-23 07:36:18.483000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-23 12:34:25.483000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-24 07:37:16.978000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.009",
	"createdAt": "2024-04-24 11:08:37.144000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.008",
	"createdAt": "2024-04-25 08:52:18.184000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-26 04:24:56.209000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.002",
	"createdAt": "2024-04-26 09:23:59.966000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-29 08:18:08.773000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-29 13:53:58.304000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-30 08:09:31.419000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.69",
	"createdAt": "2024-04-30 14:17:54.079000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-02 07:36:01.495000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-07 07:16:08.874000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.014",
	"createdAt": "2024-05-07 09:32:16.700000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.004",
	"createdAt": "2024-05-10 06:41:58.232000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-13 07:46:15.909000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "8.01",
	"createdAt": "2024-05-15 07:17:35.268000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-16 07:42:50.637000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-16 11:30:00.082000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-17 06:51:50.230000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-20 07:27:15.549000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-20 11:48:22.159000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.025",
	"createdAt": "2024-05-21 11:52:42.115000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-21 13:19:36.404000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-22 09:07:43.610000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.508",
	"createdAt": "2024-05-22 12:51:21.575000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.464",
	"createdAt": "2024-05-22 13:27:34.216000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.004",
	"createdAt": "2024-05-23 09:54:56.798000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-27 08:18:33.599000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-27 13:21:46.260000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-28 10:05:59.829000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.015",
	"createdAt": "2024-05-28 12:29:39.583000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.001",
	"createdAt": "2024-05-29 11:25:29.201000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-31 07:12:47.840000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-31 09:50:14.126000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "oleksandra.pulinets@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.026",
	"createdAt": "2024-04-25 08:48:49.600000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ransh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-28 16:20:36.527000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ransh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-28 19:23:41.425000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ransh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-29 08:09:02.020000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ransh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-29 11:11:31.070000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ransh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-30 08:21:07.410000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "ransh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-30 11:19:56.785000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sergey.biliavsky@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-05 13:19:58.232000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sergey.biliavsky@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-13 12:07:12.924000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.673",
	"createdAt": "2024-03-10 13:17:48.550000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.566",
	"createdAt": "2024-03-11 09:48:20.241000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.075",
	"createdAt": "2024-03-04 09:39:51.160000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.097",
	"createdAt": "2024-03-04 11:03:51.917000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.008",
	"createdAt": "2024-03-05 11:02:51.694000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.02",
	"createdAt": "2024-05-01 13:34:34.073000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.695",
	"createdAt": "2024-05-01 13:38:15.504000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.342",
	"createdAt": "2024-03-11 10:05:10.480000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.857",
	"createdAt": "2024-03-11 11:54:59.993000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shayel.ben@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-28 13:36:18.952000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shayel.ben@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.007",
	"createdAt": "2024-05-29 06:53:38.207000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shayel.ben@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-29 11:06:10.518000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shayel.ben@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-29 13:22:10.562000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shayel.ben@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-30 06:56:16.901000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shayel.ben@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-30 09:00:46.694000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.132",
	"createdAt": "2024-02-20 14:13:24.416000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.24",
	"createdAt": "2024-02-20 14:36:56.632000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.241",
	"createdAt": "2024-02-21 08:18:59.429000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-21 13:47:24.468000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-22 09:19:54.152000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.403",
	"createdAt": "2024-02-22 12:34:26.972000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.834",
	"createdAt": "2024-02-22 15:02:40.073000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.039",
	"createdAt": "2024-02-25 07:35:50.091000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.893",
	"createdAt": "2024-02-25 08:41:33.035000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.186",
	"createdAt": "2024-02-26 12:33:14.157000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.995",
	"createdAt": "2024-02-28 14:07:54.999000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.399",
	"createdAt": "2024-03-05 12:07:48.889000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.153",
	"createdAt": "2024-02-19 14:15:04.227000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-02-20 10:22:24.597000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.103",
	"createdAt": "2024-02-20 13:04:43.502000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shlomiv@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.488",
	"createdAt": "2024-05-28 13:40:49.934000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shlomiv@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.003",
	"createdAt": "2024-05-29 11:12:21.184000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shlomiv@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-05-30 07:37:42.067000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shlomiv@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.006",
	"createdAt": "2024-05-30 10:05:07.191000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "shlomiv@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "1.014",
	"createdAt": "2024-05-30 09:30:56.346000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.29",
	"createdAt": "2024-05-02 07:05:32.470000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-05 13:21:17.690000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-06 05:42:09.373000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.003",
	"createdAt": "2024-05-06 09:05:07.925000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.008",
	"createdAt": "2024-05-06 10:07:51.345000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.011",
	"createdAt": "2024-05-06 11:49:42.649000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.811",
	"createdAt": "2024-05-06 13:02:57.332000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-07 14:13:28.690000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.588",
	"createdAt": "2024-05-07 16:49:50.741000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.512",
	"createdAt": "2024-05-08 11:21:34.611000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.505",
	"createdAt": "2024-05-09 06:23:05.158000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.251",
	"createdAt": "2024-05-09 07:32:02.602000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-09 08:08:20.898000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.502",
	"createdAt": "2024-05-09 10:11:39.873000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.206",
	"createdAt": "2024-05-09 12:29:46.690000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.261",
	"createdAt": "2024-05-09 13:24:24.772000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.494",
	"createdAt": "2024-05-09 16:28:38.910000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-12 08:38:25.379000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.014",
	"createdAt": "2024-05-12 11:32:29.307000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.512",
	"createdAt": "2024-05-12 12:06:56.714000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.511",
	"createdAt": "2024-05-13 05:17:05.399000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.514",
	"createdAt": "2024-05-15 07:09:28.801000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.763",
	"createdAt": "2024-05-15 11:15:07.382000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.254",
	"createdAt": "2024-05-15 12:18:01.034000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.514",
	"createdAt": "2024-05-15 16:30:04.391000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.51",
	"createdAt": "2024-05-16 07:43:34.510000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.265",
	"createdAt": "2024-05-16 09:43:32.974000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-05-16 11:38:31.866000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.261",
	"createdAt": "2024-05-19 08:33:34.971000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-19 09:10:23.624000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-19 11:12:27.708000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.505",
	"createdAt": "2024-05-19 13:25:40.166000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.25",
	"createdAt": "2024-05-20 06:53:55.299000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.015",
	"createdAt": "2024-05-20 09:01:25.279000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.257",
	"createdAt": "2024-05-20 10:24:50.250000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.75",
	"createdAt": "2024-05-20 11:55:15.608000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-20 12:43:17.200000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-21 07:13:18.940000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.013",
	"createdAt": "2024-05-21 09:25:29.300000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.508",
	"createdAt": "2024-05-21 10:37:47.257000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.004",
	"createdAt": "2024-05-21 12:12:44.736000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.494",
	"createdAt": "2024-05-21 13:35:28.999000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.994",
	"createdAt": "2024-05-21 18:00:41.952000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.427",
	"createdAt": "2024-05-22 07:38:02.928000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.004",
	"createdAt": "2024-05-22 09:03:04.955000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-23 07:17:58.341000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-23 11:23:02.951000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.011",
	"createdAt": "2024-05-27 07:28:11.588000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-05-27 08:52:17.565000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.015",
	"createdAt": "2024-05-27 10:54:34.453000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.001",
	"createdAt": "2024-05-27 12:20:15.620000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.006",
	"createdAt": "2024-05-28 08:22:46.891000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.743",
	"createdAt": "2024-05-28 13:24:16.390000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.003",
	"createdAt": "2024-05-29 11:33:48.121000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.411",
	"createdAt": "2024-05-30 12:50:46.029000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sivan.habari@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-06-02 08:51:41.555000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sophie.haver@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-05-28 13:38:57.715000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sophie.haver@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-29 07:11:46.562000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sophie.haver@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-29 09:15:04.450000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sophie.haver@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-29 12:20:12.659000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sophie.haver@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-05-30 11:20:11.955000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sophie.haver@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.015",
	"createdAt": "2024-05-30 13:01:11.864000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "sophie.haver@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.255",
	"createdAt": "2024-05-28 13:36:18.845000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.005",
	"createdAt": "2024-03-05 12:37:32.841000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.344",
	"createdAt": "2024-03-05 20:57:24.091000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.248",
	"createdAt": "2024-03-06 12:07:07.915000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-06 14:37:55.413000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.89",
	"createdAt": "2024-03-12 14:37:36.654000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.805",
	"createdAt": "2024-03-12 16:17:07.779000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.818",
	"createdAt": "2024-03-13 09:19:54.388000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.869",
	"createdAt": "2024-03-13 11:09:56.237000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-14 12:34:46.660000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.519",
	"createdAt": "2024-03-20 12:31:40.904000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.09",
	"createdAt": "2024-03-20 13:09:24.131000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.561",
	"createdAt": "2024-03-22 10:53:52.170000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.016",
	"createdAt": "2024-03-25 09:41:16.340000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.968",
	"createdAt": "2024-03-25 14:54:36.721000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.014",
	"createdAt": "2024-03-26 09:12:34.757000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.675",
	"createdAt": "2024-03-26 12:18:09.086000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.761",
	"createdAt": "2024-03-27 11:57:26.113000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.187",
	"createdAt": "2024-03-29 09:21:19.274000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.83",
	"createdAt": "2024-03-29 16:33:58.627000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.508",
	"createdAt": "2024-04-01 08:41:28.063000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.853",
	"createdAt": "2024-04-01 11:17:35.385000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.837",
	"createdAt": "2024-04-01 14:35:24.699000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-01 17:29:55.239000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.234",
	"createdAt": "2024-04-02 07:48:12.880000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-03 07:31:47.659000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-03 10:18:44.940000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-03 14:26:19.860000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.41",
	"createdAt": "2024-04-05 08:45:22.022000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.007",
	"createdAt": "2024-04-05 12:57:49.180000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.97",
	"createdAt": "2024-04-06 07:23:32.245000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.002",
	"createdAt": "2024-04-07 14:44:46.533000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "8.003",
	"createdAt": "2024-04-08 06:59:05.173000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.979",
	"createdAt": "2024-04-09 16:15:19.485000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.761",
	"createdAt": "2024-04-09 17:16:52.698000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.315",
	"createdAt": "2024-04-09 20:10:33.476000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.771",
	"createdAt": "2024-04-10 08:18:27.564000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.991",
	"createdAt": "2024-04-10 12:43:04.834000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.64",
	"createdAt": "2024-04-11 07:58:54.924000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-11 09:56:35.171000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-11 14:46:36.563000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.137",
	"createdAt": "2024-04-11 19:22:42.966000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-12 07:57:01.169000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-15 07:38:45.598000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.5",
	"createdAt": "2024-04-15 09:44:58.830000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-04-15 13:28:03.010000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.836",
	"createdAt": "2024-04-16 08:04:43.509000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-16 10:03:10.177000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.016",
	"createdAt": "2024-04-16 14:19:32.902000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.011",
	"createdAt": "2024-04-17 09:36:53.360000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.095",
	"createdAt": "2024-04-17 14:04:21.229000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.741",
	"createdAt": "2024-04-18 07:21:01.538000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.624",
	"createdAt": "2024-04-18 08:07:08.720000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.757",
	"createdAt": "2024-04-18 21:25:41.537000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.923",
	"createdAt": "2024-04-19 15:38:33.265000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.609",
	"createdAt": "2024-04-22 07:48:32.906000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "7.639",
	"createdAt": "2024-04-23 07:16:05.293000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "7.712",
	"createdAt": "2024-04-24 07:24:14.555000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.01",
	"createdAt": "2024-04-25 08:22:48.123000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-26 07:44:47.837000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.124",
	"createdAt": "2024-04-29 11:22:57.378000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "13.274",
	"createdAt": "2024-04-30 18:05:30.979000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.934",
	"createdAt": "2024-05-01 09:00:29.067000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.736",
	"createdAt": "2024-05-02 07:18:40.897000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.775",
	"createdAt": "2024-05-02 08:09:51.788000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.542",
	"createdAt": "2024-05-02 09:58:41.406000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.904",
	"createdAt": "2024-05-02 17:51:43.132000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-03 11:37:05.386000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.54",
	"createdAt": "2024-05-04 09:42:34.476000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.262",
	"createdAt": "2024-05-04 10:23:07.169000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.288",
	"createdAt": "2024-05-04 14:35:14.504000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.378",
	"createdAt": "2024-05-05 17:44:21.669000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.161",
	"createdAt": "2024-05-05 19:36:51.643000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.251",
	"createdAt": "2024-05-06 09:10:24.337000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-07 07:36:28.698000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.338",
	"createdAt": "2024-05-07 10:32:44.321000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.913",
	"createdAt": "2024-05-07 11:06:23.471000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.97",
	"createdAt": "2024-05-07 14:29:19.156000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.422",
	"createdAt": "2024-05-07 17:21:16.668000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.01",
	"createdAt": "2024-05-09 07:14:07.154000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.551",
	"createdAt": "2024-05-09 13:18:56.439000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.369",
	"createdAt": "2024-05-09 16:23:52.789000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-10 08:36:23.793000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.084",
	"createdAt": "2024-05-10 11:08:45.705000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.773",
	"createdAt": "2024-05-10 13:20:44.388000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.176",
	"createdAt": "2024-05-13 08:42:53.265000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.725",
	"createdAt": "2024-05-13 16:38:31.027000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-13 19:59:55.685000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.837",
	"createdAt": "2024-05-14 07:39:01.341000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.466",
	"createdAt": "2024-05-15 08:45:41.572000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.837",
	"createdAt": "2024-05-15 16:39:35.432000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.003",
	"createdAt": "2024-05-16 07:44:23.902000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.593",
	"createdAt": "2024-05-17 16:32:42.851000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.114",
	"createdAt": "2024-05-18 09:33:13.113000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.318",
	"createdAt": "2024-05-20 13:29:13.110000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.27",
	"createdAt": "2024-05-20 18:40:05.760000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.757",
	"createdAt": "2024-05-21 07:44:17.262000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.57",
	"createdAt": "2024-05-21 13:03:50.328000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "7.002",
	"createdAt": "2024-05-22 08:03:14.326000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.906",
	"createdAt": "2024-05-22 15:35:35.601000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.686",
	"createdAt": "2024-05-23 07:36:56.144000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.893",
	"createdAt": "2024-05-23 09:04:12.551000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.763",
	"createdAt": "2024-05-23 15:48:08.009000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.577",
	"createdAt": "2024-05-23 20:49:21.439000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.263",
	"createdAt": "2024-05-24 15:30:02.849000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.753",
	"createdAt": "2024-05-26 19:41:11.688000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.693",
	"createdAt": "2024-05-27 07:34:36.440000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-27 16:25:06.363000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.353",
	"createdAt": "2024-05-27 19:10:56.032000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.871",
	"createdAt": "2024-05-28 13:29:11.109000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.075",
	"createdAt": "2024-05-28 18:13:58.114000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.868",
	"createdAt": "2024-05-29 07:47:12.501000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.157",
	"createdAt": "2024-05-30 08:04:20.694000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-30 12:08:53.260000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-31 08:12:09.332000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-31 15:31:15.149000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "stepan.burda@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "1.161",
	"createdAt": "2024-04-10 11:07:05.404000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "tom.hahn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-28 13:38:30.789000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "tom.hahn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-06-02 09:14:04.403000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "tom.hahn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-06-02 11:20:12.067000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "veronica.chichen@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.709",
	"createdAt": "2024-05-28 13:37:02.039000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "veronica.chichen@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-29 12:46:30.130000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "veronica.chichen@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-30 07:32:36.440000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "veronica.chichen@playtika.com",
	"app": "FCUS-001",
	"hardware": "SM-001",
	"sumHours": "0.049",
	"createdAt": "2024-05-21 11:45:09.497000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "veronica.chichen@playtika.com",
	"app": "FCUS-001",
	"hardware": "SM-001",
	"sumHours": "0.166",
	"createdAt": "2024-05-23 11:52:12.120000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.987",
	"createdAt": "2024-03-05 12:37:32.279000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "5.989",
	"createdAt": "2024-03-13 10:15:46.530000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.836",
	"createdAt": "2024-03-14 13:00:10.281000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.16",
	"createdAt": "2024-03-20 12:54:38.763000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.284",
	"createdAt": "2024-03-20 13:07:47.243000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.792",
	"createdAt": "2024-03-20 13:27:50.426000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.33",
	"createdAt": "2024-03-26 13:45:12.701000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-27 12:01:00.852000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.864",
	"createdAt": "2024-04-01 09:07:03.075000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-01 11:28:46.894000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-04 13:15:29.318000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-05 10:12:47.538000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-08 12:21:11.495000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.163",
	"createdAt": "2024-04-08 14:33:40.804000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-04-09 14:34:47.203000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-04-10 08:52:32.307000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.333",
	"createdAt": "2024-04-10 11:08:54.514000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.387",
	"createdAt": "2024-04-11 12:49:18.988000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-12 08:50:05.845000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-12 12:26:41.062000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-15 11:47:51.733000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.669",
	"createdAt": "2024-04-17 08:55:10.944000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.006",
	"createdAt": "2024-04-17 09:38:07.292000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.007",
	"createdAt": "2024-04-17 10:28:50.872000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.663",
	"createdAt": "2024-04-17 18:43:06.630000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.952",
	"createdAt": "2024-04-18 08:18:48.152000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.326",
	"createdAt": "2024-04-18 12:52:28.066000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.895",
	"createdAt": "2024-04-19 09:54:42.990000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-22 10:59:31.693000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.217",
	"createdAt": "2024-04-22 13:56:23.940000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.17",
	"createdAt": "2024-04-23 10:33:33.579000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.576",
	"createdAt": "2024-04-23 10:52:38.523000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-24 07:26:54.235000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.666",
	"createdAt": "2024-04-25 09:02:43.634000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.286",
	"createdAt": "2024-04-26 07:52:16.075000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.843",
	"createdAt": "2024-04-30 09:47:42.533000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-02 13:54:33.010000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.136",
	"createdAt": "2024-05-05 19:04:34.391000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.542",
	"createdAt": "2024-05-13 12:29:23.276000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.069",
	"createdAt": "2024-05-14 14:10:11.945000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.722",
	"createdAt": "2024-05-15 07:25:50.325000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.524",
	"createdAt": "2024-05-16 07:52:56.173000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.129",
	"createdAt": "2024-05-16 10:08:54.341000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.521",
	"createdAt": "2024-05-16 10:26:10.488000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.618",
	"createdAt": "2024-05-20 10:39:34.609000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.009",
	"createdAt": "2024-05-21 08:44:54.822000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-27 11:27:08.537000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-28 11:19:22.735000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.619",
	"createdAt": "2024-05-29 07:43:08.518000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.946",
	"createdAt": "2024-05-29 11:52:46.441000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-30 08:13:24.301000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.017",
	"createdAt": "2024-05-30 11:57:44.347000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.826",
	"createdAt": "2024-05-30 23:03:19.972000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.108",
	"createdAt": "2024-05-31 10:13:43.462000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "vkryzhanivskyi@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.003",
	"createdAt": "2024-04-10 11:06:22.314000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yaroslav.ivashin@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.528",
	"createdAt": "2024-03-05 12:38:23.491000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yaroslav.ivashin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.165",
	"createdAt": "2024-03-14 13:20:20.745000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-05-08 15:38:51.832000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.615",
	"createdAt": "2024-05-09 06:53:32.455000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.629",
	"createdAt": "2024-05-09 08:45:23.003000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.917",
	"createdAt": "2024-05-12 07:14:27.491000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-12 12:31:02.085000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-12 14:40:11.365000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.716",
	"createdAt": "2024-05-19 13:57:49.348000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-19 14:43:40.806000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.192",
	"createdAt": "2024-05-20 06:48:12.346000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.52",
	"createdAt": "2024-05-20 07:21:05.496000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-21 13:31:04.496000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.279",
	"createdAt": "2024-05-21 15:38:39.422000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.04",
	"createdAt": "2024-05-22 06:56:53.336000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-23 06:39:12.524000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.407",
	"createdAt": "2024-05-23 07:20:00.981000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-23 08:53:36.259000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-23 12:25:43.295000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-27 07:48:44.150000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-27 12:35:56.434000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-27 13:35:18.886000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.014",
	"createdAt": "2024-05-28 12:48:20.421000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.858",
	"createdAt": "2024-05-29 06:32:35.274000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-30 06:56:32.641000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-30 12:00:44.369000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yotam.rudin@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-06-02 07:17:50.530000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-03-05 12:56:19.468000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-03-05 13:09:55.967000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-06 12:07:51.321000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.912",
	"createdAt": "2024-03-06 12:08:20.187000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-03-06 12:09:31.203000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.001",
	"createdAt": "2024-03-13 12:01:51.956000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.986",
	"createdAt": "2024-03-13 12:04:06.472000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.175",
	"createdAt": "2024-03-14 12:32:37.249000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.012",
	"createdAt": "2024-03-14 13:00:07.153000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.109",
	"createdAt": "2024-03-20 12:56:13.267000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.001",
	"createdAt": "2024-03-20 13:07:56.409000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.605",
	"createdAt": "2024-03-29 16:09:41.638000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.788",
	"createdAt": "2024-03-29 16:49:16.165000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.246",
	"createdAt": "2024-04-01 08:49:23.518000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.171",
	"createdAt": "2024-04-01 10:09:27.828000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.271",
	"createdAt": "2024-04-01 10:22:06.400000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.996",
	"createdAt": "2024-04-01 13:22:48.849000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.416",
	"createdAt": "2024-04-05 12:38:35.545000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.241",
	"createdAt": "2024-04-08 06:20:19.506000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.913",
	"createdAt": "2024-04-17 11:06:22.838000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.473",
	"createdAt": "2024-04-23 13:37:27.897000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.165",
	"createdAt": "2024-04-24 07:48:45.437000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.092",
	"createdAt": "2024-04-25 15:10:31.946000 UTC"
}, {
	"teamName": "Playtika - HOF",
	"email": "yuliia.uhrak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.121",
	"createdAt": "2024-04-26 16:26:16.187000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "deanr@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-19 12:32:01.337000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "elag@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.856",
	"createdAt": "2024-05-19 12:32:50.098000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "elag@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-19 13:28:51.105000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "idanh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-19 12:32:24.821000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "idanh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-20 07:40:40.692000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "idanh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.511",
	"createdAt": "2024-05-20 12:35:26.521000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "idanh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-05-21 07:40:21.646000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "idanh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-29 09:29:36.461000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "idanh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-29 11:35:29.370000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.522",
	"createdAt": "2024-05-16 11:39:47.306000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-16 12:12:50.338000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.059",
	"createdAt": "2024-05-19 12:32:03.615000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-19 12:37:26.758000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "maorm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-19 12:32:01.169000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "maorm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-05-22 13:12:39.536000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "maorm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-05-22 14:42:13.287000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "oril@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-19 12:32:09.761000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "oril@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-20 10:58:11.702000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "oril@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-21 06:44:01.233000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "oril@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-05-21 11:15:20.050000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "oril@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.015",
	"createdAt": "2024-05-21 12:58:12.386000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "shohame@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-19 12:32:11.157000 UTC"
}, {
	"teamName": "Playtika - Innplay",
	"email": "taliag@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-19 12:32:40.609000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.275",
	"createdAt": "2024-04-12 09:31:27.673000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.481",
	"createdAt": "2024-04-20 13:19:25.900000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.247",
	"createdAt": "2024-04-22 08:24:35.361000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.005",
	"createdAt": "2024-04-24 11:03:53.069000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.343",
	"createdAt": "2024-05-02 08:35:06.111000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.438",
	"createdAt": "2024-01-31 11:29:32.214000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.97",
	"createdAt": "2024-01-31 13:05:29.572000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.813",
	"createdAt": "2024-01-31 14:19:30.739000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.549",
	"createdAt": "2024-02-05 17:16:45.726000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.67",
	"createdAt": "2024-02-06 09:46:47.126000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.316",
	"createdAt": "2024-02-28 12:09:12.717000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.111",
	"createdAt": "2024-02-28 12:50:06.895000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.006",
	"createdAt": "2024-03-06 12:06:26.366000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.95",
	"createdAt": "2024-03-13 11:59:33.742000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.566",
	"createdAt": "2024-03-18 15:02:15.604000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.753",
	"createdAt": "2024-03-20 13:04:54.432000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.294",
	"createdAt": "2024-03-27 15:58:06.872000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.337",
	"createdAt": "2024-03-28 09:23:18.779000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.574",
	"createdAt": "2024-04-02 07:34:08.776000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.292",
	"createdAt": "2024-04-02 15:32:18.141000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.608",
	"createdAt": "2024-04-03 11:02:55.940000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.04",
	"createdAt": "2024-04-10 09:33:33.876000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.011",
	"createdAt": "2024-05-22 13:30:07.212000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.007",
	"createdAt": "2024-04-18 10:06:57.481000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.099",
	"createdAt": "2024-05-14 07:52:46.424000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.087",
	"createdAt": "2024-05-16 13:13:04.684000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-28 09:15:19.881000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.095",
	"createdAt": "2024-04-10 12:10:09.155000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.402",
	"createdAt": "2024-03-01 13:10:55.851000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.064",
	"createdAt": "2024-04-10 11:06:43.716000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.933",
	"createdAt": "2024-04-10 11:12:24.865000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "1.503",
	"createdAt": "2024-04-10 14:28:19.677000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "1.544",
	"createdAt": "2024-04-11 08:27:53.143000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.613",
	"createdAt": "2024-04-05 10:21:32.764000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.277",
	"createdAt": "2024-04-08 15:21:04.176000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.433",
	"createdAt": "2024-04-08 15:58:17.698000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.295",
	"createdAt": "2024-04-10 08:16:14.441000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.161",
	"createdAt": "2024-04-10 09:38:03.323000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.04",
	"createdAt": "2024-04-10 09:49:25.646000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.287",
	"createdAt": "2024-04-10 10:47:05.456000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "akravchenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.475",
	"createdAt": "2024-04-04 13:37:08.098000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.317",
	"createdAt": "2024-01-23 10:40:23.414000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.51",
	"createdAt": "2024-01-23 09:02:33.099000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.456",
	"createdAt": "2024-01-23 12:34:00.453000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.015",
	"createdAt": "2024-01-24 10:14:11.195000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.273",
	"createdAt": "2024-01-24 14:29:56.123000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.014",
	"createdAt": "2024-01-26 13:59:17.974000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.973",
	"createdAt": "2024-01-29 09:06:29.766000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.014",
	"createdAt": "2024-01-30 10:06:45.461000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.013",
	"createdAt": "2024-02-05 15:07:50.513000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.995",
	"createdAt": "2024-02-07 09:52:56.605000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.012",
	"createdAt": "2024-02-08 08:58:09.250000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.012",
	"createdAt": "2024-02-08 10:21:42.963000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.007",
	"createdAt": "2024-02-14 12:58:03.788000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.0",
	"createdAt": "2024-02-14 15:12:18.240000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.005",
	"createdAt": "2024-02-19 13:10:11.671000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.011",
	"createdAt": "2024-02-20 13:07:33.324000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.006",
	"createdAt": "2024-02-27 09:48:51.892000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.012",
	"createdAt": "2024-03-04 08:01:22.784000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "3.015",
	"createdAt": "2024-03-05 11:38:15.847000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.005",
	"createdAt": "2024-03-07 11:41:04.674000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.004",
	"createdAt": "2024-03-25 07:57:33.518000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.006",
	"createdAt": "2024-04-18 10:22:54.698000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.01",
	"createdAt": "2024-04-22 08:59:37.076000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.013",
	"createdAt": "2024-04-23 12:03:17.957000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.008",
	"createdAt": "2024-04-25 08:12:49.712000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.008",
	"createdAt": "2024-05-06 14:32:22.383000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.001",
	"createdAt": "2024-05-07 07:57:06.260000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.013",
	"createdAt": "2024-05-07 15:49:47.472000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-08 11:27:44.340000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-13 14:12:50.491000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.011",
	"createdAt": "2024-05-16 08:02:20.704000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.008",
	"createdAt": "2024-05-28 14:02:41.670000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.003",
	"createdAt": "2024-05-10 14:50:18.987000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "alexeyd@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-13 14:18:50.390000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "almogb@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-01 13:40:35.903000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "almogb@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-01 13:59:00.537000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "amitge@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.075",
	"createdAt": "2024-05-01 13:40:00.998000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "amitge@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-01 13:46:58.649000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "anat@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.782",
	"createdAt": "2024-02-28 11:55:48.429000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "andrey.lisovoy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.27",
	"createdAt": "2023-11-28 20:53:02.321000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "andrey.lisovoy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.509",
	"createdAt": "2023-12-01 08:26:59.897000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "andrey.lisovoy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2023-12-05 12:26:27.416000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "andrey.lisovoy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.5",
	"createdAt": "2023-12-05 12:26:53.946000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "annast@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-01-29 12:28:55.494000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "annast@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-01-31 08:31:23.860000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "annast@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.317",
	"createdAt": "2024-01-31 11:06:59.439000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "annast@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.089",
	"createdAt": "2024-01-31 11:29:29.685000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "annast@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.43",
	"createdAt": "2024-01-31 11:37:08.286000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "annast@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.787",
	"createdAt": "2024-01-31 13:21:45.211000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "annast@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.09",
	"createdAt": "2024-01-31 14:20:48.579000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "aparkhomenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.432",
	"createdAt": "2024-01-31 11:29:23.758000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "aparkhomenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.083",
	"createdAt": "2024-01-31 13:04:26.530000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "aparkhomenko@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.414",
	"createdAt": "2024-03-01 13:11:09.901000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "aparkhomenko@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.01",
	"createdAt": "2024-05-22 10:09:22.557000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "arnonr@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.213",
	"createdAt": "2024-05-01 13:48:49.252000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "artemz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.446",
	"createdAt": "2024-01-31 11:29:26.682000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "artemz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.64",
	"createdAt": "2024-01-31 13:22:57.651000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "artemz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.395",
	"createdAt": "2024-01-31 14:15:50.751000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "artemz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.461",
	"createdAt": "2024-01-31 14:42:03.930000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "artemz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.801",
	"createdAt": "2024-04-18 11:38:13.716000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "artemz@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.008",
	"createdAt": "2024-03-01 13:10:27.608000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "artemz@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.422",
	"createdAt": "2024-03-01 14:13:48.678000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "asafp@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-13 08:26:03.483000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "asafp@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-01-29 07:48:33.900000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "asafp@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.441",
	"createdAt": "2024-01-31 11:29:22.519000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "asafp@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.996",
	"createdAt": "2024-01-31 12:27:02.619000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "asafp@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.523",
	"createdAt": "2024-01-31 13:30:44.289000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "asafp@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.317",
	"createdAt": "2024-01-31 14:21:59.471000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "asafp@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.738",
	"createdAt": "2024-03-12 12:26:17.004000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "asafp@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.114",
	"createdAt": "2024-03-13 08:14:39.840000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "azhuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.196",
	"createdAt": "2024-01-31 13:02:15.804000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "azhuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.419",
	"createdAt": "2024-01-31 11:30:22.347000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "david.cohen@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-01-29 11:11:44.358000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "david.cohen@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.364",
	"createdAt": "2024-01-31 11:33:50.540000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "diazt@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.644",
	"createdAt": "2024-03-19 17:59:40.318000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "diazt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.197",
	"createdAt": "2024-01-31 11:29:27.057000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "diazt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.184",
	"createdAt": "2024-01-31 11:44:40.773000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "diazt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.101",
	"createdAt": "2024-01-31 13:03:28.314000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "diazt@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.157",
	"createdAt": "2024-03-19 17:47:38.445000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshapovalov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.439",
	"createdAt": "2024-01-31 11:29:28.833000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshapovalov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.758",
	"createdAt": "2024-01-31 13:17:59.794000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshapovalov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.017",
	"createdAt": "2024-01-31 14:21:08.749000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshapovalov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.202",
	"createdAt": "2024-05-22 09:58:39.050000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshapovalov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.26",
	"createdAt": "2024-05-28 09:58:41.251000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshapovalov@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.206",
	"createdAt": "2024-03-01 13:25:48.223000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "ACFT-001",
	"hardware": "SM-001",
	"sumHours": "0.115",
	"createdAt": "2024-02-15 16:06:30.137000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "ADUI-001",
	"hardware": "SM-001",
	"sumHours": "0.599",
	"createdAt": "2024-02-15 16:15:36.398000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "ADUI-001",
	"hardware": "SM-001",
	"sumHours": "0.408",
	"createdAt": "2024-03-11 12:50:07.357000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "ADUI-001",
	"hardware": "SM-001",
	"sumHours": "1.943",
	"createdAt": "2024-03-12 15:20:20.940000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.01",
	"createdAt": "2024-03-26 13:47:50.247000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.382",
	"createdAt": "2024-01-31 11:32:35.734000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.221",
	"createdAt": "2024-01-31 13:01:57.103000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.44",
	"createdAt": "2024-02-16 08:42:46.968000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.994",
	"createdAt": "2024-02-16 11:44:55.801000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-16 15:25:03.348000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.961",
	"createdAt": "2024-02-27 10:12:19.230000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-29 12:04:34.696000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.738",
	"createdAt": "2024-03-05 14:09:24.031000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.798",
	"createdAt": "2024-03-06 09:04:57.241000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.931",
	"createdAt": "2024-03-13 09:25:41.756000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.836",
	"createdAt": "2024-03-18 12:16:09.015000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-27 14:21:26.109000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.891",
	"createdAt": "2024-03-28 08:49:50.206000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-28 09:45:27.709000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-09 11:50:24.329000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-10 13:55:58.171000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-04-16 13:03:20.253000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2024-02-29 10:01:31.461000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.221",
	"createdAt": "2024-03-29 10:11:14.461000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.635",
	"createdAt": "2024-04-02 15:22:33.510000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.013",
	"createdAt": "2024-04-09 09:34:27.799000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.771",
	"createdAt": "2024-03-01 13:09:25.155000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.97",
	"createdAt": "2024-03-05 15:16:51.634000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.562",
	"createdAt": "2024-03-18 14:03:25.862000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.96",
	"createdAt": "2024-03-18 15:42:47.310000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.0",
	"createdAt": "2024-02-16 10:40:37.721000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "ENFG-001",
	"hardware": "SM-001",
	"sumHours": "0.05",
	"createdAt": "2024-03-19 16:56:18.835000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "0.093",
	"createdAt": "2024-03-19 16:12:43.913000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.731",
	"createdAt": "2024-02-12 11:06:09.055000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "FCUS-001",
	"hardware": "SM-001",
	"sumHours": "0.328",
	"createdAt": "2024-04-02 16:03:39.145000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "FCUS-001",
	"hardware": "SM-001",
	"sumHours": "0.477",
	"createdAt": "2024-04-17 09:29:22.857000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dshchelkanov@playtika.com",
	"app": "MKYO-001",
	"hardware": "SM-001",
	"sumHours": "0.05",
	"createdAt": "2024-03-19 16:51:51.590000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "dvirb@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.084",
	"createdAt": "2023-12-07 10:04:44.737000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.185",
	"createdAt": "2023-12-31 15:54:37.751000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.016",
	"createdAt": "2024-01-01 08:53:49.927000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.012",
	"createdAt": "2024-01-01 13:41:09.802000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-01 15:49:11.371000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.655",
	"createdAt": "2024-01-07 10:05:43.590000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.069",
	"createdAt": "2024-02-20 12:29:38.587000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.721",
	"createdAt": "2024-03-11 13:09:10.553000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.0",
	"createdAt": "2024-03-31 13:12:17.392000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.016",
	"createdAt": "2023-11-29 09:36:12.841000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.122",
	"createdAt": "2023-11-29 13:48:57.716000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2023-12-05 11:14:53.990000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.005",
	"createdAt": "2023-11-26 10:05:21.742000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.011",
	"createdAt": "2023-11-26 12:46:29.038000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.006",
	"createdAt": "2023-11-26 15:45:57.680000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.011",
	"createdAt": "2023-11-26 20:23:22.445000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.007",
	"createdAt": "2023-11-27 06:51:17.604000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.014",
	"createdAt": "2023-11-28 15:18:24.137000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.704",
	"createdAt": "2023-11-29 07:51:06.019000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.664",
	"createdAt": "2023-11-29 12:51:44.513000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.075",
	"createdAt": "2023-12-04 10:44:17.042000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.08",
	"createdAt": "2023-11-29 13:40:23.717000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.021",
	"createdAt": "2023-12-07 08:50:32.055000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.001",
	"createdAt": "2023-12-13 12:45:08.861000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2023-12-14 14:40:46.899000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.332",
	"createdAt": "2023-12-19 07:34:38.692000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.411",
	"createdAt": "2023-12-23 10:55:39.480000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.922",
	"createdAt": "2023-12-24 07:16:07.067000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2023-12-24 08:14:42.300000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.995",
	"createdAt": "2023-12-24 11:04:36.687000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2023-12-25 13:04:00.254000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.269",
	"createdAt": "2023-12-25 19:56:06.632000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.235",
	"createdAt": "2023-12-26 08:32:31.716000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.654",
	"createdAt": "2023-12-27 13:40:19.865000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.752",
	"createdAt": "2023-12-28 12:12:52.391000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.118",
	"createdAt": "2024-01-01 11:41:48.564000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-07 14:41:05.154000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.251",
	"createdAt": "2024-01-09 06:24:13.040000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.836",
	"createdAt": "2024-01-11 12:21:21.766000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.321",
	"createdAt": "2024-01-15 12:10:36.996000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-16 10:54:35.227000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.394",
	"createdAt": "2024-01-18 12:35:47.185000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.916",
	"createdAt": "2024-01-18 13:55:01.405000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.935",
	"createdAt": "2024-02-12 08:45:42.653000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-02-12 10:46:34.524000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-02-13 09:04:58.257000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-15 07:51:29.811000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-02-15 12:36:29.561000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.585",
	"createdAt": "2024-02-15 15:12:04.819000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.179",
	"createdAt": "2024-02-17 10:08:01.139000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.117",
	"createdAt": "2024-02-20 12:29:59.440000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-20 15:54:15.174000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.83",
	"createdAt": "2024-02-22 09:50:54.458000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-02-25 12:39:31.391000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-26 07:41:22.149000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.174",
	"createdAt": "2024-02-28 14:48:03.429000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.586",
	"createdAt": "2024-03-03 08:58:10.142000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.461",
	"createdAt": "2024-03-03 12:29:33.078000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.34",
	"createdAt": "2024-03-03 12:59:41.608000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-11 08:35:55.892000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-14 09:47:49.516000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.17",
	"createdAt": "2024-03-19 09:42:57.367000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.226",
	"createdAt": "2024-03-21 10:56:04.235000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.829",
	"createdAt": "2024-03-26 10:38:15.881000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.601",
	"createdAt": "2024-04-01 07:11:54.798000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-01 09:03:53.811000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.212",
	"createdAt": "2024-04-09 06:37:26.043000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-09 12:43:09.288000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.436",
	"createdAt": "2024-04-10 11:30:01.048000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-14 09:21:00.463000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-15 12:51:46.745000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-17 13:47:19.045000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.404",
	"createdAt": "2024-04-18 06:43:59.457000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-02 13:15:14.448000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.314",
	"createdAt": "2024-05-05 11:36:34.554000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.026",
	"createdAt": "2024-05-07 13:54:48.391000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.091",
	"createdAt": "2024-05-09 08:56:55.994000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-28 07:42:54.861000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.609",
	"createdAt": "2024-05-29 13:59:45.008000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.31",
	"createdAt": "2024-03-19 07:42:42.257000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-19 12:06:19.112000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-20 07:27:47.941000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.292",
	"createdAt": "2024-03-20 14:06:54.301000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.429",
	"createdAt": "2024-05-01 13:39:16.626000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "FRGE-001",
	"hardware": "XL-001",
	"sumHours": "0.343",
	"createdAt": "2024-03-06 12:19:04.382000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "KOYA-001",
	"hardware": "SM-001",
	"sumHours": "0.132",
	"createdAt": "2023-11-26 19:56:49.755000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "eyalr@playtika.com",
	"app": "KOYA-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2023-12-26 08:48:56.304000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-05 14:51:59.684000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-13 12:59:41.961000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-26 20:24:20.540000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galba@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-01 13:49:51.624000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galba@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.072",
	"createdAt": "2024-05-01 13:43:30.041000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.25",
	"createdAt": "2023-12-07 13:15:37.640000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.057",
	"createdAt": "2023-12-07 16:44:47.559000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.057",
	"createdAt": "2023-11-30 10:04:15.415000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.04",
	"createdAt": "2023-12-10 12:09:26.269000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.94",
	"createdAt": "2023-12-10 12:35:13.168000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.196",
	"createdAt": "2023-12-14 13:37:03.924000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.016",
	"createdAt": "2024-01-11 12:28:59.414000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-07 13:39:43.256000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.505",
	"createdAt": "2024-02-28 14:16:56.625000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-28 14:49:46.600000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-03 09:37:31.197000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.009",
	"createdAt": "2024-03-03 12:02:25.907000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-03 20:11:45.369000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.718",
	"createdAt": "2024-03-04 06:48:58.755000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.288",
	"createdAt": "2024-03-04 09:12:06.371000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.015",
	"createdAt": "2024-03-04 12:18:05.533000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "galih@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.319",
	"createdAt": "2024-03-04 20:58:11.052000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "hadasz@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.781",
	"createdAt": "2024-03-13 08:25:41.380000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "hadasz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.342",
	"createdAt": "2024-01-31 11:35:07.375000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "hadasz@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.03",
	"createdAt": "2024-03-12 12:40:34.422000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.167",
	"createdAt": "2023-11-28 18:28:58.768000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.08",
	"createdAt": "2023-12-12 18:40:18.216000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.089",
	"createdAt": "2023-12-12 21:04:25.415000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.376",
	"createdAt": "2023-11-28 15:50:04.857000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.72",
	"createdAt": "2023-11-28 16:52:30.906000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.06",
	"createdAt": "2023-11-28 17:37:15.249000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.065",
	"createdAt": "2023-11-28 17:41:48.420000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.252",
	"createdAt": "2023-11-28 17:46:36.235000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.462",
	"createdAt": "2023-11-28 18:02:36.092000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.147",
	"createdAt": "2023-11-28 18:31:17.116000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.205",
	"createdAt": "2023-11-28 20:38:05.294000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "idanye@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.014",
	"createdAt": "2023-11-29 19:03:49.421000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "igorci@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.434",
	"createdAt": "2024-01-31 11:29:38.808000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "igorci@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.615",
	"createdAt": "2024-01-31 13:21:21.986000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "igorci@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-01-31 14:17:01.617000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "igorci@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.007",
	"createdAt": "2024-03-05 12:06:34.965000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "igorci@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.222",
	"createdAt": "2024-03-05 13:12:48.273000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-29 07:58:37.430000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.441",
	"createdAt": "2024-01-31 11:30:23.082000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.925",
	"createdAt": "2024-01-31 13:03:49.209000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.84",
	"createdAt": "2024-01-31 14:18:03.512000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.177",
	"createdAt": "2024-02-08 14:24:25.863000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-02-08 14:37:37.991000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.767",
	"createdAt": "2024-03-06 12:16:40.594000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.342",
	"createdAt": "2024-03-13 08:52:51.875000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.655",
	"createdAt": "2024-03-13 09:35:41.458000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.717",
	"createdAt": "2024-03-14 09:48:53.701000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.633",
	"createdAt": "2024-05-01 11:36:29.052000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.056",
	"createdAt": "2024-05-22 09:14:57.907000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "itzikc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.837",
	"createdAt": "2024-06-02 11:19:27.706000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-01-11 15:10:35.059000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.621",
	"createdAt": "2024-01-15 16:12:50.094000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.969",
	"createdAt": "2024-01-16 14:42:41.802000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.938",
	"createdAt": "2024-01-16 15:52:48.547000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.0",
	"createdAt": "2024-01-17 10:16:38.245000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.195",
	"createdAt": "2024-01-17 12:11:10.644000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.623",
	"createdAt": "2024-01-17 13:26:46.712000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.085",
	"createdAt": "2024-01-18 15:22:58.144000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.107",
	"createdAt": "2024-01-18 16:32:11.842000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.836",
	"createdAt": "2024-01-22 10:36:16.152000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.003",
	"createdAt": "2024-01-22 15:04:36.977000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.771",
	"createdAt": "2024-01-23 09:28:25.373000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.839",
	"createdAt": "2024-01-26 14:02:09.574000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.955",
	"createdAt": "2024-01-31 13:02:58.847000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-01-31 14:20:18.447000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.837",
	"createdAt": "2024-02-08 09:07:16.999000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.015",
	"createdAt": "2024-02-14 12:11:38.213000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-02-14 12:38:00.995000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-02-20 14:09:59.644000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.004",
	"createdAt": "2024-02-22 12:06:48.798000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.012",
	"createdAt": "2024-03-01 13:10:31.061000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.013",
	"createdAt": "2024-03-04 15:15:15.924000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.879",
	"createdAt": "2024-03-06 12:06:52.930000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-03-06 13:27:49.898000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-11 14:15:19.107000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-03-13 12:10:20.392000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.013",
	"createdAt": "2024-03-13 12:42:59.640000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.795",
	"createdAt": "2024-03-13 12:52:26.048000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.008",
	"createdAt": "2024-03-22 09:29:27.528000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-03-22 12:26:12.525000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.18",
	"createdAt": "2024-03-22 16:25:10.618000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-03-28 14:01:18.030000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-29 09:39:35.570000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-03-29 13:51:45.970000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.001",
	"createdAt": "2024-04-02 14:33:45.182000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.008",
	"createdAt": "2024-04-03 11:20:02.248000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.0",
	"createdAt": "2024-04-03 11:22:28.142000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.907",
	"createdAt": "2024-04-11 09:04:59.085000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-11 11:11:19.401000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-04-16 07:31:03.468000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.003",
	"createdAt": "2024-04-18 09:25:26.717000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-04-24 11:04:08.158000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.3",
	"createdAt": "2024-04-24 12:22:46.462000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.513",
	"createdAt": "2024-04-24 13:46:20.959000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.097",
	"createdAt": "2024-05-16 13:09:44.394000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.003",
	"createdAt": "2024-05-22 13:53:00.114000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-28 09:26:05.885000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-28 12:32:49.380000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.005",
	"createdAt": "2024-01-11 12:32:50.008000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.004",
	"createdAt": "2024-03-01 13:14:07.709000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-01 13:22:29.206000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.274",
	"createdAt": "2024-03-01 13:33:04.888000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "kiorhanska@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.774",
	"createdAt": "2024-03-01 13:51:49.168000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lenaf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.005",
	"createdAt": "2024-01-29 23:24:58.534000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lenaf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.762",
	"createdAt": "2024-01-31 11:10:12.525000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lenaf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-31 13:03:35.553000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lenaf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-12 07:26:36.418000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lenaf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-13 08:06:05.391000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lenaf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.587",
	"createdAt": "2024-03-13 08:37:11.070000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lenaf@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-03-13 09:38:09.464000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "leonidr@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.016",
	"createdAt": "2023-12-12 21:18:02.080000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "leonidr@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.064",
	"createdAt": "2023-12-20 14:42:43.080000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "leonidr@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-01-10 09:52:38.485000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "leonidr@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.093",
	"createdAt": "2024-03-26 10:35:06.513000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "leonidr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.053",
	"createdAt": "2023-11-30 10:47:35.674000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "leonidr@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.337",
	"createdAt": "2024-03-24 13:41:17.964000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.436",
	"createdAt": "2024-01-31 11:29:54.062000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.041",
	"createdAt": "2024-01-31 12:42:06.761000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.183",
	"createdAt": "2024-01-31 12:49:28.544000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.868",
	"createdAt": "2024-01-31 14:19:00.021000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-03-05 09:53:57.297000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.002",
	"createdAt": "2024-03-05 11:23:49.019000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.998",
	"createdAt": "2024-03-06 06:16:30.118000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.398",
	"createdAt": "2024-03-07 07:08:52.851000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.001",
	"createdAt": "2024-03-07 08:01:23.080000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-28 14:35:24.362000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.316",
	"createdAt": "2024-04-01 06:17:21.787000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-04-01 06:53:28.825000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.501",
	"createdAt": "2024-04-01 13:02:50.007000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.48",
	"createdAt": "2024-04-02 08:36:44.096000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.516",
	"createdAt": "2024-04-03 09:40:11.399000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.368",
	"createdAt": "2024-04-12 08:59:29.364000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.197",
	"createdAt": "2024-04-12 12:02:11.035000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.503",
	"createdAt": "2024-05-03 10:36:59.372000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.129",
	"createdAt": "2024-05-03 11:36:48.300000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2024-05-14 14:21:24.368000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-28 09:25:03.528000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.404",
	"createdAt": "2024-03-01 13:13:12.326000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.093",
	"createdAt": "2024-04-12 08:20:42.154000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-04-12 12:19:25.443000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-28 10:35:02.723000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.206",
	"createdAt": "2024-05-22 13:53:38.100000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.486",
	"createdAt": "2024-04-12 08:28:20.088000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lhondaruk@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.997",
	"createdAt": "2024-04-12 10:55:39.701000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "liorra@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-01 13:40:40.215000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "liorra@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-01 14:22:53.327000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-01-18 08:58:43.965000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.01",
	"createdAt": "2024-01-18 14:23:15.498000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.011",
	"createdAt": "2024-02-01 14:03:51.330000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.016",
	"createdAt": "2024-02-04 09:25:10.400000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-04 11:50:19.160000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.011",
	"createdAt": "2024-02-08 12:14:25.847000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-02-08 13:47:20.158000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.994",
	"createdAt": "2024-02-20 11:35:16.016000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-22 12:41:55.312000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-02-22 15:10:06.657000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-25 15:10:50.379000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-29 13:22:50.769000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-06 12:17:59.552000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.176",
	"createdAt": "2024-03-10 13:07:14.038000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-12 15:51:36.211000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-14 15:44:27.636000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.188",
	"createdAt": "2024-03-18 14:20:04.695000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-25 10:37:36.754000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-25 12:51:21.515000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.16",
	"createdAt": "2024-03-26 10:21:14.234000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-26 10:56:24.840000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.441",
	"createdAt": "2024-04-04 12:05:09.931000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-04 14:20:00.377000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.031",
	"createdAt": "2024-04-07 08:37:27.309000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-07 13:58:39.155000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-09 10:29:11.674000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.994",
	"createdAt": "2024-04-09 14:18:32.983000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-16 11:19:37.433000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.371",
	"createdAt": "2024-04-17 08:20:10.350000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.055",
	"createdAt": "2024-04-24 12:55:57.877000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.663",
	"createdAt": "2024-05-05 13:44:28.309000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.154",
	"createdAt": "2024-05-08 13:13:56.970000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.018",
	"createdAt": "2024-05-08 13:25:26.345000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.005",
	"createdAt": "2024-05-08 13:29:06.824000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-16 11:53:10.422000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-21 09:21:43.841000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.308",
	"createdAt": "2024-02-28 11:33:20.547000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.694",
	"createdAt": "2024-02-28 14:46:20.490000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.482",
	"createdAt": "2024-02-28 17:27:22.102000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "maryiate@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.25",
	"createdAt": "2024-02-28 20:39:07.410000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "matana@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-29 13:45:19.288000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mehrinissos@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.048",
	"createdAt": "2024-02-21 10:10:10.997000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mehrinissos@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.329",
	"createdAt": "2024-02-23 13:41:31.606000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mehrinissos@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.016",
	"createdAt": "2024-02-23 14:05:14.831000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mehrinissos@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.137",
	"createdAt": "2024-02-27 13:37:56.853000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mehrinissos@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.057",
	"createdAt": "2024-03-01 13:10:12.898000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mehrinissos@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.734",
	"createdAt": "2024-03-06 12:16:01.947000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mehrinissos@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.029",
	"createdAt": "2024-03-12 09:53:51.895000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mehrinissos@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.719",
	"createdAt": "2024-03-01 13:16:18.904000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mehrinissos@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.714",
	"createdAt": "2024-03-01 14:03:53.818000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.002",
	"createdAt": "2023-12-31 13:18:02.561000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.006",
	"createdAt": "2024-01-10 14:01:43.503000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.0",
	"createdAt": "2024-01-24 06:25:23.337000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.06",
	"createdAt": "2024-01-25 18:06:48.966000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.504",
	"createdAt": "2024-01-26 06:12:03.896000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-02-13 13:08:07.571000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-02-14 12:10:11.885000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.013",
	"createdAt": "2024-02-14 20:22:06.255000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.015",
	"createdAt": "2024-02-15 08:25:10.965000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.016",
	"createdAt": "2024-02-15 09:30:16.038000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.985",
	"createdAt": "2024-02-17 08:48:45.669000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michaelko@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-02-17 16:17:51.628000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.003",
	"createdAt": "2024-01-22 12:57:38.766000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-01-23 09:01:12.465000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.012",
	"createdAt": "2024-01-28 12:57:07.681000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-01-30 13:53:45.285000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-31 10:31:27.281000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-02-04 08:43:14.653000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.11",
	"createdAt": "2024-02-04 12:22:52.462000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-10 13:27:13.646000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-19 12:07:51.623000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-27 14:10:41.494000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-08 12:37:36.861000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.006",
	"createdAt": "2024-04-10 12:30:12.356000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.66",
	"createdAt": "2024-01-15 14:29:21.395000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-01 13:40:00.316000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "FCUS-001",
	"hardware": "SM-001",
	"sumHours": "0.048",
	"createdAt": "2024-01-18 13:32:11.707000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "michalh@playtika.com",
	"app": "INVK-001",
	"hardware": "SM-001",
	"sumHours": "0.552",
	"createdAt": "2024-01-18 13:47:59.258000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.432",
	"createdAt": "2024-01-31 11:29:31.729000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.095",
	"createdAt": "2024-01-31 13:08:00.333000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.378",
	"createdAt": "2024-02-01 10:29:50.612000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.83",
	"createdAt": "2024-03-01 17:07:49.565000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.448",
	"createdAt": "2024-03-04 09:35:27.673000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.969",
	"createdAt": "2024-03-04 10:22:00.886000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "6.193",
	"createdAt": "2024-03-04 12:16:01.034000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.613",
	"createdAt": "2024-03-05 09:30:46.081000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.33",
	"createdAt": "2024-03-05 12:57:15.435000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.514",
	"createdAt": "2024-03-06 09:44:04.615000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.782",
	"createdAt": "2024-03-06 12:12:07.428000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.183",
	"createdAt": "2024-03-06 13:01:53.264000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.012",
	"createdAt": "2024-03-06 13:15:04.514000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.221",
	"createdAt": "2024-03-13 12:20:05.805000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.861",
	"createdAt": "2024-04-01 11:27:17.491000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.555",
	"createdAt": "2024-04-01 12:28:34.132000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.268",
	"createdAt": "2024-04-01 14:15:19.545000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.868",
	"createdAt": "2024-04-01 16:31:14.829000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-02 08:49:39.305000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.298",
	"createdAt": "2024-04-18 11:46:20.156000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mle@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.378",
	"createdAt": "2024-03-01 13:12:15.238000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mongolmish@gmail.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.271",
	"createdAt": "2024-01-30 20:46:59.270000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.689",
	"createdAt": "2024-01-31 11:29:29.035000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.904",
	"createdAt": "2024-01-31 13:04:57.421000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.738",
	"createdAt": "2024-01-31 14:23:40.156000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.116",
	"createdAt": "2024-02-05 17:04:52.046000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.124",
	"createdAt": "2024-04-24 11:18:40.935000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.184",
	"createdAt": "2024-05-13 11:10:39.392000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.264",
	"createdAt": "2024-05-22 09:32:08.102000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.26",
	"createdAt": "2024-05-28 11:55:34.177000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.011",
	"createdAt": "2024-03-01 13:10:40.454000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.253",
	"createdAt": "2024-03-01 14:14:09.669000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.089",
	"createdAt": "2024-03-01 14:32:13.834000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "mstepanenko@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.512",
	"createdAt": "2024-03-12 14:04:35.929000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "nadzeyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.033",
	"createdAt": "2024-02-28 13:02:36.187000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "naori@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.452",
	"createdAt": "2024-01-31 11:28:52.216000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "naori@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.662",
	"createdAt": "2024-01-31 13:18:14.491000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "noage@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.009",
	"createdAt": "2024-03-05 12:37:37.186000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "noamn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.007",
	"createdAt": "2024-01-31 11:30:04.536000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "noamn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-01-31 13:17:29.960000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "noamn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-01-31 13:49:51.657000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "odedn@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.295",
	"createdAt": "2024-05-01 13:47:25.877000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-02-19 08:47:53.856000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.445",
	"createdAt": "2024-02-20 08:54:56.416000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-21 11:45:28.052000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.473",
	"createdAt": "2024-02-28 08:20:15.666000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-28 08:55:20.499000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-04 14:44:17.885000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.276",
	"createdAt": "2024-03-04 14:50:34.778000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.649",
	"createdAt": "2024-03-07 08:35:58.618000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.128",
	"createdAt": "2024-03-11 09:32:12.633000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.056",
	"createdAt": "2024-03-13 08:50:38.628000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.036",
	"createdAt": "2024-03-13 08:56:16.027000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.293",
	"createdAt": "2024-03-13 13:34:05.907000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.284",
	"createdAt": "2024-03-14 07:27:05.898000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.224",
	"createdAt": "2024-03-14 09:10:08.028000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.178",
	"createdAt": "2024-04-02 06:48:27.185000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.094",
	"createdAt": "2024-04-07 08:37:02.083000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-07 13:52:22.163000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-07 13:56:40.325000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.694",
	"createdAt": "2024-04-08 06:52:04.833000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.431",
	"createdAt": "2024-04-08 11:53:47.786000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.275",
	"createdAt": "2024-04-09 07:40:56.214000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.01",
	"createdAt": "2024-04-10 08:43:13.816000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.863",
	"createdAt": "2024-04-11 07:22:28.111000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-14 07:03:21.651000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.456",
	"createdAt": "2024-04-15 07:15:00.421000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.042",
	"createdAt": "2024-04-16 15:07:02.335000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.031",
	"createdAt": "2024-05-05 09:56:15.833000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.61",
	"createdAt": "2024-05-06 07:41:52.555000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "ofrib@playtika.com",
	"app": "INVK-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-02-15 12:35:12.362000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "ADUI-001",
	"hardware": "SM-001",
	"sumHours": "1.792",
	"createdAt": "2024-03-02 18:23:18.621000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "ADUI-001",
	"hardware": "SM-001",
	"sumHours": "0.09",
	"createdAt": "2024-03-02 20:12:42.770000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.247",
	"createdAt": "2024-01-31 12:01:30.577000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-01-31 13:13:04.085000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.004",
	"createdAt": "2024-01-31 14:18:45.510000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "2.013",
	"createdAt": "2024-03-01 13:13:12.089000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.078",
	"createdAt": "2024-03-02 16:29:39.630000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.527",
	"createdAt": "2024-03-02 16:49:17.669000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.224",
	"createdAt": "2024-03-02 20:19:19.899000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.908",
	"createdAt": "2024-03-02 21:35:32.527000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.703",
	"createdAt": "2024-03-02 22:33:06.346000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omarushchak@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.382",
	"createdAt": "2024-04-08 08:44:20.953000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.683",
	"createdAt": "2024-03-11 13:13:38.734000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.365",
	"createdAt": "2024-03-12 13:37:46.665000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.576",
	"createdAt": "2024-03-12 14:10:18.174000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.097",
	"createdAt": "2024-03-13 08:49:32.505000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.643",
	"createdAt": "2024-03-13 10:21:52.743000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.472",
	"createdAt": "2024-03-13 12:04:47.998000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-13 12:36:36.783000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.337",
	"createdAt": "2024-03-13 12:37:00.754000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.41",
	"createdAt": "2024-03-18 14:13:14.252000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.444",
	"createdAt": "2024-03-19 07:23:30.426000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.151",
	"createdAt": "2024-03-19 13:16:59.412000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.744",
	"createdAt": "2024-03-20 09:50:22.997000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-03-24 14:49:34.916000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.95",
	"createdAt": "2024-03-24 14:49:47.427000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-26 11:28:48.940000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.753",
	"createdAt": "2024-03-26 13:57:28.769000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.702",
	"createdAt": "2024-04-25 12:11:30.702000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.037",
	"createdAt": "2024-04-28 13:09:46.908000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.733",
	"createdAt": "2024-04-28 13:14:02.434000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.531",
	"createdAt": "2024-04-28 14:22:38.475000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.421",
	"createdAt": "2024-04-29 15:09:01.869000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.611",
	"createdAt": "2024-04-29 19:07:11.164000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.286",
	"createdAt": "2024-05-02 10:51:26.283000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.006",
	"createdAt": "2024-05-29 07:20:09.112000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.697",
	"createdAt": "2024-05-29 11:02:00.442000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "0.284",
	"createdAt": "2024-03-24 13:10:05.346000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "omeri@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.673",
	"createdAt": "2024-04-29 13:57:09.570000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.421",
	"createdAt": "2024-01-31 11:30:52.197000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.72",
	"createdAt": "2024-01-31 13:25:34.309000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.355",
	"createdAt": "2024-02-06 09:11:03.136000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.656",
	"createdAt": "2024-03-21 10:35:16.728000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.499",
	"createdAt": "2024-05-15 14:46:52.053000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.985",
	"createdAt": "2024-05-16 07:59:18.378000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.993",
	"createdAt": "2024-05-16 11:26:20.072000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.483",
	"createdAt": "2024-05-16 14:37:51.255000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.883",
	"createdAt": "2024-05-17 09:02:06.995000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.218",
	"createdAt": "2024-05-28 09:25:09.722000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.014",
	"createdAt": "2024-03-01 13:10:55.530000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.088",
	"createdAt": "2024-05-22 13:56:16.540000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pkupriyanov@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.065",
	"createdAt": "2024-05-22 15:16:27.691000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pmijalski@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.402",
	"createdAt": "2024-01-31 11:31:09.246000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pmijalski@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.77",
	"createdAt": "2024-01-31 13:13:52.821000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pmijalski@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.878",
	"createdAt": "2024-01-31 14:23:42.832000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pmijalski@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-04-18 11:45:34.820000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pmijalski@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-04-18 16:39:10.478000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pmijalski@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.872",
	"createdAt": "2024-04-24 14:31:16.027000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pmijalski@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-25 11:57:16.062000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pmijalski@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.246",
	"createdAt": "2024-05-22 10:08:50.371000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "pmijalski@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-05 12:06:18.213000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "rnovytskyi@playtika.com",
	"app": "ACFT-001",
	"hardware": "SM-001",
	"sumHours": "0.152",
	"createdAt": "2024-03-01 16:29:04.575000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "rnovytskyi@playtika.com",
	"app": "ACFT-001",
	"hardware": "SM-001",
	"sumHours": "0.178",
	"createdAt": "2024-03-06 16:04:46.799000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "rnovytskyi@playtika.com",
	"app": "ADUI-001",
	"hardware": "SM-001",
	"sumHours": "0.114",
	"createdAt": "2024-03-01 16:18:41.053000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "rnovytskyi@playtika.com",
	"app": "ADUI-001",
	"hardware": "SM-001",
	"sumHours": "0.369",
	"createdAt": "2024-03-06 13:29:43.224000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "rnovytskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.438",
	"createdAt": "2024-01-31 11:29:30.173000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "rnovytskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.938",
	"createdAt": "2024-01-31 13:43:11.045000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "rnovytskyi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.006",
	"createdAt": "2024-03-01 13:13:34.143000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "rnovytskyi@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.509",
	"createdAt": "2024-03-01 13:16:45.844000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "rnovytskyi@playtika.com",
	"app": "OOBA-001",
	"hardware": "MD-001",
	"sumHours": "0.21",
	"createdAt": "2024-03-01 16:41:31.631000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.185",
	"createdAt": "2023-11-29 09:37:32.011000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2023-12-06 09:44:17.530000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.422",
	"createdAt": "2023-12-19 07:23:54.713000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.591",
	"createdAt": "2023-12-19 08:03:38.159000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.336",
	"createdAt": "2023-12-19 11:41:37.336000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.507",
	"createdAt": "2023-12-19 14:55:44.985000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.001",
	"createdAt": "2023-12-20 10:31:26.841000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.781",
	"createdAt": "2024-01-01 07:53:14.865000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-01 09:01:34.344000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.494",
	"createdAt": "2024-01-01 13:59:27.853000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-01 14:32:33.964000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-01-01 16:36:18.283000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.897",
	"createdAt": "2024-01-02 11:01:48.597000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.014",
	"createdAt": "2024-01-02 13:27:08.600000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.899",
	"createdAt": "2024-01-02 18:57:02.774000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.005",
	"createdAt": "2024-01-03 12:03:48.468000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.821",
	"createdAt": "2024-01-03 14:22:33.820000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.003",
	"createdAt": "2024-01-07 11:45:58.706000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.679",
	"createdAt": "2024-01-07 14:52:35.163000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.047",
	"createdAt": "2024-01-07 18:46:58.463000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.105",
	"createdAt": "2024-01-07 18:52:05.429000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.01",
	"createdAt": "2024-01-07 19:07:33.247000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.825",
	"createdAt": "2024-01-07 19:11:19.077000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.277",
	"createdAt": "2024-01-08 08:05:27.673000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.441",
	"createdAt": "2024-01-31 11:29:19.415000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.011",
	"createdAt": "2024-01-31 11:58:44.445000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.869",
	"createdAt": "2024-01-31 13:08:29.142000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-01-31 14:23:14.902000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.007",
	"createdAt": "2024-02-14 10:24:34.537000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.34",
	"createdAt": "2024-02-14 15:08:22.484000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.717",
	"createdAt": "2024-02-19 13:56:16.440000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.01",
	"createdAt": "2024-02-20 12:23:50.105000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-02-21 09:13:12.277000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.881",
	"createdAt": "2024-02-22 11:01:10.868000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.325",
	"createdAt": "2024-02-22 14:33:39.386000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-25 08:34:18.043000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.232",
	"createdAt": "2024-02-25 13:59:38.666000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.01",
	"createdAt": "2024-02-25 14:47:29.085000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.255",
	"createdAt": "2024-02-26 08:08:12.682000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.58",
	"createdAt": "2024-02-26 12:40:20.207000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-27 19:38:39.464000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.465",
	"createdAt": "2024-02-28 08:50:51.227000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.575",
	"createdAt": "2024-02-28 12:03:44.068000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.792",
	"createdAt": "2024-02-28 12:41:17.553000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.004",
	"createdAt": "2024-03-03 08:22:53.363000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.343",
	"createdAt": "2024-03-03 13:34:16.853000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.059",
	"createdAt": "2024-03-03 13:57:07.671000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-03 15:33:50.699000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.303",
	"createdAt": "2024-03-06 11:45:58.870000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.014",
	"createdAt": "2024-03-07 17:52:31.169000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.011",
	"createdAt": "2024-03-10 10:17:19.966000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.001",
	"createdAt": "2024-03-10 10:25:52.064000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.135",
	"createdAt": "2024-03-10 13:07:13.219000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.091",
	"createdAt": "2024-03-10 14:59:27.094000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.733",
	"createdAt": "2024-03-13 08:11:44.162000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.677",
	"createdAt": "2024-03-13 10:05:33.124000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.662",
	"createdAt": "2024-03-13 11:54:14.837000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.002",
	"createdAt": "2024-03-13 14:10:51.252000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.006",
	"createdAt": "2024-03-14 09:45:58.462000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-14 12:51:46.947000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.867",
	"createdAt": "2024-03-27 12:14:28.209000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.001",
	"createdAt": "2024-03-27 14:56:03.043000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.004",
	"createdAt": "2024-04-03 08:48:40.251000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-03 10:50:27.401000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.012",
	"createdAt": "2024-04-04 15:19:15.052000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-09 10:34:33.764000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-14 13:08:22.805000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.389",
	"createdAt": "2024-04-14 15:43:23.797000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-15 06:56:23.602000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.51",
	"createdAt": "2024-04-15 09:32:38.143000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-15 11:31:05.188000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-16 15:30:21.861000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.255",
	"createdAt": "2024-04-17 06:53:20.031000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.009",
	"createdAt": "2024-04-17 10:58:06.085000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.103",
	"createdAt": "2024-04-17 17:49:14.042000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.005",
	"createdAt": "2024-04-18 09:16:00.347000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-24 10:37:48.645000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.008",
	"createdAt": "2024-04-24 16:32:50.857000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.433",
	"createdAt": "2024-04-30 11:27:42.413000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.262",
	"createdAt": "2024-05-01 10:55:56.928000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.34",
	"createdAt": "2024-05-05 12:23:54.492000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.002",
	"createdAt": "2024-05-08 10:52:30.845000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.011",
	"createdAt": "2024-05-08 12:34:21.354000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.175",
	"createdAt": "2024-05-08 12:55:36.209000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.115",
	"createdAt": "2024-05-08 13:08:20.261000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.492",
	"createdAt": "2024-05-08 14:05:48.135000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-09 13:53:13.024000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-21 18:40:35.427000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.013",
	"createdAt": "2024-05-22 06:56:49.698000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.764",
	"createdAt": "2024-05-22 08:58:32.059000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.015",
	"createdAt": "2024-05-22 13:04:05.682000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-23 15:25:58.872000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-28 13:36:54.173000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.248",
	"createdAt": "2024-05-29 10:26:45.357000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-30 07:39:44.339000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.004",
	"createdAt": "2024-05-30 09:45:21.476000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.614",
	"createdAt": "2023-11-29 09:50:06.791000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.344",
	"createdAt": "2023-11-29 10:29:54.004000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.004",
	"createdAt": "2023-11-29 10:52:40.982000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.116",
	"createdAt": "2023-11-29 12:23:32.123000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.109",
	"createdAt": "2023-11-29 12:33:06.529000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.014",
	"createdAt": "2023-12-05 11:02:10.063000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.542",
	"createdAt": "2023-12-05 12:15:30.936000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.785",
	"createdAt": "2023-12-05 13:50:10.038000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.29",
	"createdAt": "2023-12-05 14:44:57.834000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.014",
	"createdAt": "2023-12-05 15:25:35.274000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2023-12-05 18:08:22.205000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.003",
	"createdAt": "2023-12-05 20:21:08.974000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.25",
	"createdAt": "2023-12-05 21:50:19.971000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.012",
	"createdAt": "2023-12-06 14:31:27.200000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.001",
	"createdAt": "2023-12-06 15:36:17.084000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.827",
	"createdAt": "2023-12-13 12:03:57.931000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2023-12-16 18:12:50.433000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.056",
	"createdAt": "2023-12-16 20:48:29.569000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.925",
	"createdAt": "2023-12-18 13:27:01.778000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.404",
	"createdAt": "2023-12-18 16:10:56.323000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.259",
	"createdAt": "2023-12-19 06:22:40.334000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.513",
	"createdAt": "2023-12-19 06:46:48.235000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.256",
	"createdAt": "2023-12-20 13:56:38.207000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.651",
	"createdAt": "2023-12-24 10:03:19.552000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2023-12-24 10:44:50.231000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.325",
	"createdAt": "2023-12-24 12:47:32.153000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.473",
	"createdAt": "2023-12-24 13:19:14.222000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.717",
	"createdAt": "2023-12-25 08:20:27.414000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.05",
	"createdAt": "2023-12-25 12:30:25.235000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.055",
	"createdAt": "2023-12-25 12:36:05.667000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.079",
	"createdAt": "2023-12-25 12:41:44.815000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.185",
	"createdAt": "2023-12-25 12:50:27.328000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.169",
	"createdAt": "2023-12-25 14:36:14.014000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.156",
	"createdAt": "2023-12-26 08:14:11.810000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.142",
	"createdAt": "2023-12-26 10:09:36.179000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.989",
	"createdAt": "2023-12-26 12:20:05.793000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2023-12-26 16:21:00.742000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.751",
	"createdAt": "2023-12-26 18:56:32.410000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2023-12-27 08:11:55.265000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.099",
	"createdAt": "2023-12-27 11:23:14.952000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.016",
	"createdAt": "2023-12-27 11:31:21.358000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.715",
	"createdAt": "2023-12-31 08:04:11.516000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.003",
	"createdAt": "2023-12-31 09:51:33.629000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2023-12-31 13:43:49.658000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.082",
	"createdAt": "2024-01-07 10:45:10.320000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.163",
	"createdAt": "2024-01-07 11:02:59.824000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-01-08 12:27:57.105000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.831",
	"createdAt": "2024-01-09 14:31:58.778000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.716",
	"createdAt": "2024-01-10 09:44:27.039000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.039",
	"createdAt": "2024-01-10 11:53:18.240000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.996",
	"createdAt": "2024-01-10 11:59:54.858000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.519",
	"createdAt": "2024-01-11 10:05:07.731000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.823",
	"createdAt": "2024-01-11 12:34:13.764000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.616",
	"createdAt": "2024-01-15 07:49:53.068000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-01-15 10:22:23.462000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.671",
	"createdAt": "2024-01-15 14:08:08.035000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.463",
	"createdAt": "2024-01-15 15:19:24.341000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "5.005",
	"createdAt": "2024-01-16 08:09:33.832000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.255",
	"createdAt": "2024-01-17 11:48:35.280000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.295",
	"createdAt": "2024-01-17 14:07:40.335000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.978",
	"createdAt": "2024-01-17 14:33:07.935000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.266",
	"createdAt": "2024-01-17 16:23:29.136000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.578",
	"createdAt": "2024-01-22 07:37:56.805000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.168",
	"createdAt": "2024-01-22 09:05:13.372000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.0",
	"createdAt": "2024-01-22 11:17:30.786000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.73",
	"createdAt": "2024-01-23 12:42:27.825000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-01-24 12:21:35.907000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-01-28 11:10:12.111000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.4",
	"createdAt": "2024-01-28 14:26:17.770000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-01-29 13:03:59.700000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-29 15:22:44.474000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.789",
	"createdAt": "2024-01-30 13:00:57.830000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.657",
	"createdAt": "2024-01-30 17:04:43.634000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.805",
	"createdAt": "2024-01-30 17:54:59.452000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.656",
	"createdAt": "2024-01-31 07:57:15.085000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.809",
	"createdAt": "2024-01-31 09:21:06.696000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-04 07:18:08.350000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.627",
	"createdAt": "2024-03-04 09:29:07.050000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.021",
	"createdAt": "2024-03-04 11:11:38.474000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-03-04 12:23:27.885000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.254",
	"createdAt": "2024-03-18 16:55:23.565000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.968",
	"createdAt": "2024-03-19 11:49:55.453000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.278",
	"createdAt": "2024-05-08 13:16:47.719000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.539",
	"createdAt": "2024-05-12 11:12:56.954000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.329",
	"createdAt": "2024-05-12 14:01:51.338000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-15 10:42:54.177000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.952",
	"createdAt": "2024-05-19 08:35:01.303000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-19 12:31:49.772000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.456",
	"createdAt": "2024-05-21 10:10:24.084000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.263",
	"createdAt": "2023-11-28 14:47:47.173000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.332",
	"createdAt": "2023-11-28 15:28:43.706000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.757",
	"createdAt": "2023-11-29 08:05:49.758000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.262",
	"createdAt": "2023-12-04 10:42:19.290000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.831",
	"createdAt": "2024-02-22 21:47:06.246000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.027",
	"createdAt": "2024-01-03 14:18:40.945000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.007",
	"createdAt": "2024-03-11 14:16:46.269000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.147",
	"createdAt": "2024-04-10 09:30:27.198000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.023",
	"createdAt": "2024-05-01 14:22:12.745000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.854",
	"createdAt": "2024-05-01 14:26:17.183000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.491",
	"createdAt": "2024-05-08 12:36:38.570000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.102",
	"createdAt": "2024-05-08 13:08:17.020000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.535",
	"createdAt": "2023-12-31 08:14:54.459000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "EZSD-001",
	"hardware": "MD-001",
	"sumHours": "1.0",
	"createdAt": "2023-12-11 09:19:44.213000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "EZSD-001",
	"hardware": "MD-001",
	"sumHours": "0.281",
	"createdAt": "2023-12-11 12:17:59.123000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "2.053",
	"createdAt": "2023-12-20 14:14:46.809000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.855",
	"createdAt": "2023-12-27 17:17:24.851000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-01-03 12:39:37.208000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.013",
	"createdAt": "2024-01-17 15:36:43.033000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.051",
	"createdAt": "2023-12-25 12:48:24.006000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.08",
	"createdAt": "2023-12-26 14:09:07.059000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.18",
	"createdAt": "2024-01-01 12:15:10.481000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.82",
	"createdAt": "2024-02-28 12:40:44.256000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.652",
	"createdAt": "2024-04-03 17:45:13.590000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.967",
	"createdAt": "2024-04-10 09:18:48.624000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "1.005",
	"createdAt": "2024-04-25 13:29:40.186000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.157",
	"createdAt": "2024-02-26 14:26:14.817000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "1.014",
	"createdAt": "2024-02-26 15:28:45.074000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.223",
	"createdAt": "2024-03-11 12:50:10.097000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "1.014",
	"createdAt": "2024-03-31 08:31:47.780000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.082",
	"createdAt": "2024-04-03 10:50:47.313000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "1.01",
	"createdAt": "2024-04-03 16:02:00.314000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.092",
	"createdAt": "2024-04-03 18:25:39.307000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "1.014",
	"createdAt": "2024-04-03 18:32:46.767000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.124",
	"createdAt": "2024-04-10 07:39:36.559000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "1.015",
	"createdAt": "2024-04-10 07:49:54.476000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.114",
	"createdAt": "2024-04-10 09:32:59.573000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "4.005",
	"createdAt": "2024-04-10 10:04:16.085000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.769",
	"createdAt": "2024-04-11 18:02:13.033000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.404",
	"createdAt": "2024-04-11 18:51:08.486000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.169",
	"createdAt": "2024-04-18 09:02:32.207000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-03 06:33:50.708000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-30 10:14:16.661000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "SUPR-001",
	"hardware": "LG-001",
	"sumHours": "1.001",
	"createdAt": "2024-04-24 10:39:41.355000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "SUPR-001",
	"hardware": "LG-001",
	"sumHours": "0.169",
	"createdAt": "2024-05-21 18:26:35.736000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shaio@playtika.com",
	"app": "SUPR-001",
	"hardware": "LG-001",
	"sumHours": "0.047",
	"createdAt": "2024-05-30 10:08:48.738000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.167",
	"createdAt": "2024-02-20 14:24:10.911000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.926",
	"createdAt": "2024-02-21 08:38:11.532000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.869",
	"createdAt": "2024-02-29 10:01:46.650000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2023-12-28 08:11:22.791000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.473",
	"createdAt": "2023-12-28 15:00:54.029000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.006",
	"createdAt": "2024-01-01 13:54:26.236000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.514",
	"createdAt": "2024-01-01 15:14:22.283000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-04 15:34:19.995000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.334",
	"createdAt": "2024-01-15 08:43:34.727000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.01",
	"createdAt": "2024-01-15 14:17:44.014000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.025",
	"createdAt": "2024-01-16 09:41:29.950000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.792",
	"createdAt": "2024-01-16 11:28:02.942000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.387",
	"createdAt": "2024-01-16 18:33:15.663000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.558",
	"createdAt": "2024-01-17 12:09:17.389000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-24 12:51:11.357000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-01-29 14:53:57.308000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.433",
	"createdAt": "2024-01-30 12:45:26.942000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-31 09:29:25.671000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-01-31 12:06:03.259000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.421",
	"createdAt": "2024-02-05 08:25:31.266000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-05 10:53:16.166000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.017",
	"createdAt": "2024-02-05 13:02:15.800000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-02-06 10:49:13.977000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-02-07 09:35:03.664000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-07 12:11:15.679000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.077",
	"createdAt": "2024-02-07 14:40:57.857000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.285",
	"createdAt": "2024-02-08 08:23:20.534000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.008",
	"createdAt": "2024-02-08 12:21:12.678000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.627",
	"createdAt": "2024-02-14 10:00:35.163000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-02-18 10:32:45.741000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.806",
	"createdAt": "2024-02-19 13:28:09.600000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.473",
	"createdAt": "2024-04-11 11:28:50.747000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.079",
	"createdAt": "2023-12-27 07:23:12.082000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2023-12-27 10:10:59.169000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.022",
	"createdAt": "2023-12-26 15:56:51.012000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.497",
	"createdAt": "2023-12-28 14:29:46.877000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.239",
	"createdAt": "2024-01-01 14:58:43.988000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.408",
	"createdAt": "2024-01-09 14:46:02.096000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.034",
	"createdAt": "2024-01-09 15:13:03.826000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.138",
	"createdAt": "2024-01-16 09:29:08.844000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.287",
	"createdAt": "2024-02-25 08:40:32.356000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.03",
	"createdAt": "2024-04-11 11:25:41.350000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "sivank@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.199",
	"createdAt": "2024-01-03 14:39:30.290000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "sivank@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-10 10:25:35.142000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.0",
	"createdAt": "2024-03-28 08:35:54.066000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.011",
	"createdAt": "2024-03-28 09:20:44.960000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.215",
	"createdAt": "2024-03-29 16:58:32.035000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.426",
	"createdAt": "2024-04-04 18:50:59.949000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.798",
	"createdAt": "2024-04-06 06:40:10.749000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.594",
	"createdAt": "2024-04-07 11:09:23.675000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-07 13:01:58.280000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "vfedorenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.401",
	"createdAt": "2024-01-31 11:31:01.089000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "vfedorenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.007",
	"createdAt": "2024-01-31 13:46:21.583000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "vstetsiuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.071",
	"createdAt": "2024-03-12 01:27:33.273000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "vstetsiuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.476",
	"createdAt": "2024-04-12 09:15:00.136000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "vstetsiuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-16 13:09:20.272000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "vstetsiuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.338",
	"createdAt": "2024-05-16 13:09:32.518000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "vstetsiuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-28 12:38:54.098000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "vstetsiuk@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.018",
	"createdAt": "2024-03-01 12:56:16.571000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "vstetsiuk@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.002",
	"createdAt": "2024-03-01 13:09:27.869000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "vstetsiuk@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.014",
	"createdAt": "2024-03-05 12:06:41.909000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "vstetsiuk@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-001",
	"sumHours": "4.005",
	"createdAt": "2024-01-31 12:07:34.559000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "yaelge@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.431",
	"createdAt": "2024-03-13 08:25:52.225000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "yaelge@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-29 09:08:23.034000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "yaelge@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.45",
	"createdAt": "2024-01-31 11:29:27.985000 UTC"
}, {
	"teamName": "Playtika - Marketing",
	"email": "yaelge@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-12 09:39:37.529000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-06 11:02:39.975000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.025",
	"createdAt": "2024-05-06 16:13:20.115000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-08 08:00:46.357000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.492",
	"createdAt": "2024-05-06 18:15:23.638000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "3.006",
	"createdAt": "2024-05-06 18:47:34.648000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.024",
	"createdAt": "2024-05-06 21:52:59.112000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-07 17:49:13.332000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-08 07:41:04.598000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-09 04:50:11.744000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.036",
	"createdAt": "2024-05-19 17:11:47.498000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-19 17:55:42.015000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "amirp@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-19 18:12:29.453000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "daniels@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-27 15:49:38.581000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "daniels@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.014",
	"createdAt": "2024-05-06 11:03:24.132000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "daniels@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-07 09:05:23.268000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "daniels@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-07 09:06:17.414000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "daniels@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.376",
	"createdAt": "2024-05-08 08:03:57.600000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "daniels@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-08 10:37:08.986000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "daniels@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.735",
	"createdAt": "2024-05-12 09:59:28.925000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "daniels@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-15 08:02:38.118000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "daniels@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.182",
	"createdAt": "2024-05-20 10:09:55.734000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "daniels@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.037",
	"createdAt": "2024-05-20 11:41:05.402000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "daniels@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.407",
	"createdAt": "2024-05-30 07:57:09.390000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.558",
	"createdAt": "2024-02-19 09:15:19.293000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-02-19 11:03:05.056000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-20 14:51:09.449000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-02-25 14:33:36.757000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.84",
	"createdAt": "2024-02-26 12:04:04.600000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.497",
	"createdAt": "2024-02-27 14:14:11.068000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.57",
	"createdAt": "2024-02-28 13:59:17.514000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.179",
	"createdAt": "2024-02-28 14:52:55.992000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.969",
	"createdAt": "2024-02-29 12:11:54.674000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.477",
	"createdAt": "2024-03-05 10:49:33.973000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.245",
	"createdAt": "2024-03-14 13:31:06.625000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.258",
	"createdAt": "2024-03-18 11:41:18.433000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.754",
	"createdAt": "2024-03-18 15:43:02.036000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-19 13:43:43.403000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-21 13:10:15.904000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-04 10:47:56.233000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-15 10:19:22.002000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-08 08:18:55.555000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.547",
	"createdAt": "2023-12-27 12:21:22.795000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-01 09:09:44.195000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-01-01 13:10:15.997000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-02 09:27:28.460000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-04 14:04:13.501000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.747",
	"createdAt": "2024-01-07 08:55:01.521000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.126",
	"createdAt": "2024-01-07 14:23:59.343000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.764",
	"createdAt": "2024-01-08 14:13:14.258000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-01-10 12:05:34.841000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.181",
	"createdAt": "2024-01-11 17:07:24.228000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.053",
	"createdAt": "2024-01-15 11:04:42.345000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-01-24 12:47:06.635000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-28 14:26:20.533000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.061",
	"createdAt": "2024-01-31 12:18:31.908000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.686",
	"createdAt": "2024-01-31 12:26:16.072000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-01-31 14:10:14.939000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.976",
	"createdAt": "2024-02-04 11:51:11.871000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.32",
	"createdAt": "2024-02-05 13:35:24.728000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.563",
	"createdAt": "2024-02-07 14:19:44.558000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-11 12:06:24.877000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-13 13:58:21.202000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.012",
	"createdAt": "2024-02-14 09:53:25.917000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.011",
	"createdAt": "2024-02-14 12:27:57.763000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.033",
	"createdAt": "2024-02-14 12:31:00.833000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-02-14 12:35:56.052000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.039",
	"createdAt": "2024-02-10 14:29:24.211000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.624",
	"createdAt": "2024-02-13 17:07:28.798000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.75",
	"createdAt": "2024-02-14 11:11:07.783000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.546",
	"createdAt": "2024-03-02 13:40:44.106000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-05 11:20:38.501000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-12 14:01:28.855000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.08",
	"createdAt": "2024-03-13 15:12:36.326000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.384",
	"createdAt": "2024-03-14 13:00:15.849000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-14 13:52:55.073000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.846",
	"createdAt": "2024-03-15 19:05:22.544000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-17 14:36:57.844000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.713",
	"createdAt": "2024-03-18 12:00:54.739000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-19 10:43:40.324000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-23 13:52:53.455000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.241",
	"createdAt": "2024-03-25 10:32:38.323000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.202",
	"createdAt": "2024-03-25 11:45:23.637000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.589",
	"createdAt": "2024-03-25 12:00:57.658000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.568",
	"createdAt": "2024-03-25 12:45:27.203000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.843",
	"createdAt": "2024-03-25 15:30:30.374000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.158",
	"createdAt": "2024-03-26 10:32:15.938000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.23",
	"createdAt": "2024-03-26 12:09:51.457000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.966",
	"createdAt": "2024-03-26 12:28:09.641000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-27 10:47:31.083000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-27 14:43:26.181000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.71",
	"createdAt": "2024-04-08 14:15:18.387000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-10 11:37:27.517000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.581",
	"createdAt": "2024-04-15 08:32:01.124000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.532",
	"createdAt": "2024-04-23 08:46:05.210000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.966",
	"createdAt": "2024-04-24 11:13:28.198000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.276",
	"createdAt": "2024-04-25 12:53:53.371000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.627",
	"createdAt": "2024-04-25 13:13:08.729000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "3.695",
	"createdAt": "2024-04-30 09:56:49.715000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.607",
	"createdAt": "2024-05-02 13:36:59.496000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.633",
	"createdAt": "2024-05-08 08:17:00.479000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.371",
	"createdAt": "2024-05-12 08:35:34.208000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.108",
	"createdAt": "2024-05-12 12:39:41.954000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.193",
	"createdAt": "2024-05-18 07:05:27.442000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-18 13:49:01.065000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-28 13:54:25.158000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.704",
	"createdAt": "2024-01-28 12:41:30.682000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.998",
	"createdAt": "2024-02-01 14:27:29.616000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.59",
	"createdAt": "2024-02-04 10:13:25.885000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.853",
	"createdAt": "2024-02-05 10:06:23.986000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "deanal@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-06 14:54:25.281000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "gilas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-06 11:06:44.096000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "gilas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-08 08:01:08.832000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "gilas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.075",
	"createdAt": "2024-05-21 08:27:48.600000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "gilas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.006",
	"createdAt": "2024-05-21 08:36:35.742000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "gilas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-05-21 10:38:05.168000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "gilas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.993",
	"createdAt": "2024-05-23 09:16:29.438000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "gilas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.0",
	"createdAt": "2024-05-29 09:56:01.040000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ilyat@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.242",
	"createdAt": "2024-03-05 11:04:20.246000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ilyat@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-03-05 11:22:06.941000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ionatana@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.595",
	"createdAt": "2024-05-06 12:23:20.371000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ionatana@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.7",
	"createdAt": "2024-05-08 08:36:18.460000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "kirillbu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.172",
	"createdAt": "2024-03-06 13:58:29.044000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lilias@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2023-12-27 17:55:25.145000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lilias@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-03-05 10:59:33.381000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lilias@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.06",
	"createdAt": "2024-03-05 11:15:39.687000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lilias@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-18 07:01:34.780000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lilias@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-04 08:11:14.769000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lilias@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.001",
	"createdAt": "2023-12-27 12:19:38.326000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lilias@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.013",
	"createdAt": "2024-03-05 11:22:13.203000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.17",
	"createdAt": "2024-03-14 10:02:29.254000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-20 10:26:59.244000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.415",
	"createdAt": "2024-03-21 08:46:17.061000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.725",
	"createdAt": "2024-04-01 10:21:39.601000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.382",
	"createdAt": "2024-04-01 11:14:25.105000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.398",
	"createdAt": "2024-04-01 14:28:24.540000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.306",
	"createdAt": "2024-04-24 08:16:31.152000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-06 11:32:42.274000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-08 07:56:27.100000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-20 07:21:04.910000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.378",
	"createdAt": "2024-05-21 16:08:22.538000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.669",
	"createdAt": "2024-05-22 15:21:18.645000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.59",
	"createdAt": "2024-05-27 08:01:00.904000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-30 10:08:25.302000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "liorla@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.714",
	"createdAt": "2024-05-30 14:17:55.813000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.647",
	"createdAt": "2024-03-18 13:39:10.934000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.016",
	"createdAt": "2024-03-19 08:26:28.707000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-24 14:22:25.728000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.28",
	"createdAt": "2024-03-26 10:35:57.897000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-31 09:40:29.333000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.212",
	"createdAt": "2024-04-04 14:01:28.495000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-07 08:44:12.492000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.111",
	"createdAt": "2024-04-17 09:37:02.432000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.394",
	"createdAt": "2024-04-17 12:15:03.799000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.993",
	"createdAt": "2024-05-05 11:24:34.251000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-06 11:10:07.237000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-30 12:32:04.519000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2023-12-26 17:52:30.217000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.004",
	"createdAt": "2023-12-26 20:23:32.614000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2023-12-27 09:51:23.785000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "michalh@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.018",
	"createdAt": "2023-12-26 20:21:08.328000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-28 12:07:58.568000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-28 14:22:22.115000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-31 09:07:00.561000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.327",
	"createdAt": "2024-03-31 11:15:44.188000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-31 13:21:53.807000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.295",
	"createdAt": "2024-04-01 06:40:10.436000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-01 07:07:56.251000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.986",
	"createdAt": "2024-04-01 11:14:23.745000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-04-01 13:37:07.378000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-02 07:23:21.705000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.121",
	"createdAt": "2024-04-03 13:32:57.344000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.015",
	"createdAt": "2024-04-07 08:33:50.215000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.884",
	"createdAt": "2024-04-07 12:20:25.045000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.161",
	"createdAt": "2024-04-08 07:54:25.599000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.241",
	"createdAt": "2024-04-08 10:19:17.938000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.091",
	"createdAt": "2024-04-08 11:42:16.107000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.316",
	"createdAt": "2024-04-08 14:00:46.229000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.61",
	"createdAt": "2024-04-11 09:24:49.953000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.552",
	"createdAt": "2024-04-11 10:25:52.082000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.202",
	"createdAt": "2024-04-11 12:53:37.394000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.083",
	"createdAt": "2024-04-14 17:32:19.218000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.038",
	"createdAt": "2024-04-15 10:51:19.128000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.466",
	"createdAt": "2024-04-15 13:08:45.511000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.998",
	"createdAt": "2024-04-17 08:00:14.214000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.803",
	"createdAt": "2024-04-21 12:16:38.168000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-21 13:20:43.206000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.169",
	"createdAt": "2024-05-04 18:01:02.962000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.064",
	"createdAt": "2024-05-06 11:50:54.314000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.342",
	"createdAt": "2024-05-08 07:58:36.724000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.981",
	"createdAt": "2024-05-09 10:57:01.396000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.213",
	"createdAt": "2024-05-09 13:28:13.930000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.562",
	"createdAt": "2024-05-12 08:32:54.867000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.664",
	"createdAt": "2024-05-19 09:55:30.481000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.961",
	"createdAt": "2024-05-19 10:54:22.080000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-19 18:58:17.037000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.467",
	"createdAt": "2024-05-20 06:50:00.856000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.735",
	"createdAt": "2024-05-20 08:25:26.058000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.383",
	"createdAt": "2024-05-20 09:14:41.459000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.461",
	"createdAt": "2024-05-20 12:13:30.288000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.367",
	"createdAt": "2024-05-21 07:43:01.192000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.181",
	"createdAt": "2024-05-21 15:25:59.249000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.053",
	"createdAt": "2024-05-27 09:54:18.711000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.13",
	"createdAt": "2024-05-29 07:39:01.619000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.794",
	"createdAt": "2024-06-02 12:30:48.251000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.055",
	"createdAt": "2023-12-27 12:21:23.929000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.07",
	"createdAt": "2023-12-27 12:28:21.554000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.245",
	"createdAt": "2023-12-27 12:46:02.104000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2023-12-27 13:08:37.394000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.394",
	"createdAt": "2024-01-10 12:04:54.609000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.219",
	"createdAt": "2024-01-15 10:54:53.150000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.881",
	"createdAt": "2024-01-23 17:19:45.424000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.007",
	"createdAt": "2024-01-24 12:15:32.842000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.729",
	"createdAt": "2024-01-29 15:24:32.250000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.639",
	"createdAt": "2024-02-07 14:13:22.220000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.363",
	"createdAt": "2024-02-11 10:05:10.047000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.195",
	"createdAt": "2024-02-11 11:12:22.635000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.121",
	"createdAt": "2024-02-11 11:26:31.653000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.007",
	"createdAt": "2024-02-11 11:35:51.532000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.069",
	"createdAt": "2024-02-11 11:38:58.008000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.488",
	"createdAt": "2024-02-12 13:52:38.113000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.736",
	"createdAt": "2024-02-19 10:43:57.843000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.606",
	"createdAt": "2024-02-19 12:08:49.850000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.469",
	"createdAt": "2024-02-19 14:38:40.753000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.462",
	"createdAt": "2024-02-25 11:35:46.943000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-26 11:23:17.068000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.728",
	"createdAt": "2024-02-29 08:16:40.205000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-29 19:34:40.963000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-05 10:58:50.089000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.395",
	"createdAt": "2024-03-10 11:43:13.948000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-10 14:28:49.489000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-11 12:55:49.759000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.856",
	"createdAt": "2024-03-11 15:11:36.914000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-11 16:05:01.581000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-12 08:22:40.516000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-03-14 08:46:04.851000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.295",
	"createdAt": "2024-03-14 10:50:02.732000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-18 11:58:19.106000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.011",
	"createdAt": "2024-03-19 09:44:29.867000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-19 10:42:49.200000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-19 13:35:02.110000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-20 10:27:06.041000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-21 08:26:41.330000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-24 14:45:33.577000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-03-25 09:11:07.209000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-25 13:53:17.839000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-26 08:38:21.874000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.009",
	"createdAt": "2024-03-26 12:11:57.799000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.383",
	"createdAt": "2024-03-26 12:38:06.936000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-27 13:37:45.695000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-27 15:57:17.241000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.223",
	"createdAt": "2024-03-28 12:02:47.508000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.006",
	"createdAt": "2024-04-14 17:40:39.787000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.017",
	"createdAt": "2024-05-06 08:07:19.335000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.062",
	"createdAt": "2024-02-19 10:38:40.150000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "moranda@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.456",
	"createdAt": "2024-03-20 09:57:52.297000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.118",
	"createdAt": "2024-04-23 12:59:49.656000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.35",
	"createdAt": "2024-04-26 21:20:04.375000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.517",
	"createdAt": "2024-04-27 09:27:35.594000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.142",
	"createdAt": "2024-04-30 14:12:31.108000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.892",
	"createdAt": "2024-05-06 11:03:23.857000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.875",
	"createdAt": "2024-05-08 08:01:01.458000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.003",
	"createdAt": "2024-05-15 12:48:44.181000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.558",
	"createdAt": "2024-05-20 08:33:57.456000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.002",
	"createdAt": "2024-05-27 12:04:05.923000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.654",
	"createdAt": "2024-04-21 14:08:19.751000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.392",
	"createdAt": "2024-04-23 12:33:54.072000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.674",
	"createdAt": "2024-04-23 13:08:49.422000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.601",
	"createdAt": "2024-04-24 07:54:17.588000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.554",
	"createdAt": "2024-04-24 14:19:01.339000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.262",
	"createdAt": "2024-04-25 11:52:34.594000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.335",
	"createdAt": "2024-04-30 14:22:48.208000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.497",
	"createdAt": "2024-04-30 15:40:14.382000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavg@playtika.com",
	"app": "FCUS-001",
	"hardware": "LG-001",
	"sumHours": "0.086",
	"createdAt": "2024-03-25 09:21:26.001000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavn@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.01",
	"createdAt": "2024-03-25 10:16:54.617000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.496",
	"createdAt": "2024-01-08 13:59:32.043000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-06 11:28:03.297000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.205",
	"createdAt": "2024-05-08 08:13:10.271000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.507",
	"createdAt": "2024-05-08 11:02:28.929000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.666",
	"createdAt": "2024-05-08 11:37:19.249000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.192",
	"createdAt": "2024-05-09 13:37:22.010000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.103",
	"createdAt": "2024-05-16 09:18:15.512000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "nadavn@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.239",
	"createdAt": "2024-05-20 12:19:57.765000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "orav@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-04 08:21:48.821000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "orav@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-08 12:51:28.057000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "orav@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-25 11:36:30.664000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "orav@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.016",
	"createdAt": "2024-05-06 10:38:22.304000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "orav@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.015",
	"createdAt": "2024-05-08 08:11:07.243000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "orav@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.32",
	"createdAt": "2024-05-09 08:49:03.994000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "orav@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.53",
	"createdAt": "2024-05-09 11:45:44.359000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "orav@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-09 15:45:43.568000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "orav@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.233",
	"createdAt": "2024-05-23 08:57:35.484000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "orav@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-28 08:52:36.890000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "orav@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.107",
	"createdAt": "2024-05-29 10:50:17.458000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-05 10:29:44.167000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-05 13:30:05.130000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-11 12:03:12.053000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-02-14 09:24:59.354000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-14 11:53:10.439000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-15 08:50:05.924000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-22 12:03:27.496000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-10 08:46:42.135000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-03 08:51:59.950000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-04-03 11:03:18.175000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-04-03 13:28:59.372000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-04 08:43:31.089000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-06 10:34:30.120000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-08 08:01:44.814000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-08 10:47:13.467000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-08 12:59:27.457000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ort@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-16 09:33:35.247000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-01-15 09:17:35.406000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.412",
	"createdAt": "2024-01-18 10:19:48.035000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.047",
	"createdAt": "2024-01-23 14:28:37.702000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-24 10:30:21.765000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.116",
	"createdAt": "2024-03-26 13:13:52.934000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.107",
	"createdAt": "2024-03-26 13:55:48.147000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2023-12-27 12:20:45.622000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.15",
	"createdAt": "2023-12-28 20:05:48.595000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.171",
	"createdAt": "2023-12-31 07:23:28.329000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.912",
	"createdAt": "2024-01-01 08:45:14.364000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-05 10:59:14.646000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.264",
	"createdAt": "2024-04-09 16:58:08.156000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.048",
	"createdAt": "2024-04-09 17:28:25.128000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-07 12:27:32.765000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "ransa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.246",
	"createdAt": "2024-05-12 08:33:19.010000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "revitals@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-06 11:28:40.504000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "revitals@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.01",
	"createdAt": "2024-05-08 08:13:37.527000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "revitals@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.365",
	"createdAt": "2024-05-08 08:16:01.591000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "revitals@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.673",
	"createdAt": "2024-05-08 08:40:04.239000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "revitals@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.756",
	"createdAt": "2024-05-08 11:08:12.998000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "revitals@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.589",
	"createdAt": "2024-05-09 09:16:07.770000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "revitals@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.961",
	"createdAt": "2024-05-21 14:53:59.696000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "romd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-06 11:29:27.998000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.502",
	"createdAt": "2024-05-05 12:46:33.360000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-06 11:27:59.979000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.009",
	"createdAt": "2024-05-08 08:12:47.610000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.636",
	"createdAt": "2024-05-08 09:20:19.892000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirb@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-06 11:31:04.169000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirb@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-04-24 11:42:15.792000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.255",
	"createdAt": "2024-02-22 12:05:06.234000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.04",
	"createdAt": "2024-02-22 12:22:39.458000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.203",
	"createdAt": "2024-02-28 13:52:39.065000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.906",
	"createdAt": "2023-12-27 12:20:48.591000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2023-12-28 11:24:12.067000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.512",
	"createdAt": "2023-12-28 18:23:09.675000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.166",
	"createdAt": "2023-12-28 21:32:36.994000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.657",
	"createdAt": "2024-01-07 14:30:38.047000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.003",
	"createdAt": "2024-01-07 14:44:32.571000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.21",
	"createdAt": "2024-01-08 10:36:38.815000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.893",
	"createdAt": "2024-01-29 12:28:34.329000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.735",
	"createdAt": "2024-01-31 13:10:21.817000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.003",
	"createdAt": "2024-01-31 14:23:51.922000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.096",
	"createdAt": "2024-02-14 12:29:53.084000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.23",
	"createdAt": "2024-02-14 12:41:24.918000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.397",
	"createdAt": "2024-02-19 09:52:29.078000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.559",
	"createdAt": "2023-12-26 17:23:50.276000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.21",
	"createdAt": "2023-12-27 07:08:46.883000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.405",
	"createdAt": "2023-12-27 07:58:12.917000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.103",
	"createdAt": "2023-12-27 12:11:26.016000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.031",
	"createdAt": "2023-12-26 15:52:30.069000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "1.009",
	"createdAt": "2023-12-26 16:00:12.624000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.043",
	"createdAt": "2023-12-26 17:02:37.205000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.224",
	"createdAt": "2023-12-26 17:06:59.834000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.037",
	"createdAt": "2023-12-27 07:54:30.347000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.168",
	"createdAt": "2024-02-19 09:36:50.593000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.321",
	"createdAt": "2024-02-19 13:07:17.522000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yonatanma@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.005",
	"createdAt": "2023-12-27 12:20:58.002000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yonatanma@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.391",
	"createdAt": "2024-03-13 14:54:21.033000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.015",
	"createdAt": "2024-03-04 10:26:04.900000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.299",
	"createdAt": "2024-03-05 11:02:39.383000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-05 15:22:20.888000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.009",
	"createdAt": "2024-03-06 11:25:33.678000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.014",
	"createdAt": "2024-03-07 11:42:15.216000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.012",
	"createdAt": "2024-03-08 11:20:22.111000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.002",
	"createdAt": "2024-03-11 13:24:43.772000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.01",
	"createdAt": "2024-03-12 11:32:40.388000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-12 15:19:44.681000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-14 13:18:44.044000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-18 14:46:27.129000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.004",
	"createdAt": "2024-03-19 11:25:45.543000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-20 15:14:53.890000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-03-21 11:01:07.049000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-22 13:40:37.439000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-11 11:44:24.867000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-19 12:45:57.157000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-22 11:23:49.812000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-30 14:25:28.634000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-09 17:03:14.893000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-16 13:03:15.515000 UTC"
}, {
	"teamName": "Playtika - SGH",
	"email": "yuryhr@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.002",
	"createdAt": "2024-03-05 11:22:59.831000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2023-12-19 08:32:44.103000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2023-12-19 08:59:17.042000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2023-12-19 09:07:01.094000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2023-12-27 14:56:12.708000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-01 09:02:45.448000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-01-03 10:08:53.183000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.969",
	"createdAt": "2024-01-03 12:11:16.434000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-08 12:39:39.315000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.172",
	"createdAt": "2024-01-22 10:21:55.821000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-01-24 12:00:47.634000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-01-24 12:25:42.254000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.583",
	"createdAt": "2024-01-25 13:42:21.819000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.204",
	"createdAt": "2024-01-28 09:41:42.265000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-01-28 11:40:06.737000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.846",
	"createdAt": "2024-01-28 15:02:25.918000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.731",
	"createdAt": "2024-01-29 13:15:41.582000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.67",
	"createdAt": "2024-01-31 12:04:39.317000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.402",
	"createdAt": "2024-02-06 12:16:28.448000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-02-07 11:03:08.717000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-02-08 08:54:33.359000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-08 11:22:57.112000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.21",
	"createdAt": "2024-02-11 10:02:32.423000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-02-11 14:44:56.184000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-12 14:47:16.288000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.428",
	"createdAt": "2024-02-14 12:03:44.121000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.017",
	"createdAt": "2024-02-14 12:31:42.144000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-22 12:07:04.010000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.254",
	"createdAt": "2024-02-29 08:33:12.909000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.029",
	"createdAt": "2024-02-29 13:49:44.521000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-02-29 13:53:45.408000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.0",
	"createdAt": "2024-03-04 22:46:01.695000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.274",
	"createdAt": "2024-03-11 08:35:19.058000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-03-12 12:01:28.830000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-14 10:40:41.705000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.218",
	"createdAt": "2024-03-29 16:55:05.759000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-29 18:39:10.231000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-31 13:47:31.100000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-31 15:52:47.559000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.956",
	"createdAt": "2024-04-03 14:02:19.631000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.994",
	"createdAt": "2024-04-04 07:42:36.860000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-04 09:46:05.766000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-10 07:02:39.032000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-21 17:30:04.499000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-24 11:15:52.775000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.57",
	"createdAt": "2024-04-25 10:39:19.627000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.026",
	"createdAt": "2024-04-30 12:57:58.053000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.969",
	"createdAt": "2023-11-29 10:05:48.854000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.022",
	"createdAt": "2023-11-30 12:59:46.550000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.133",
	"createdAt": "2023-12-06 08:07:26.423000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2023-12-06 09:43:09.303000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.017",
	"createdAt": "2023-12-13 01:09:11.881000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.586",
	"createdAt": "2023-12-19 07:54:50.941000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.011",
	"createdAt": "2024-05-01 08:44:46.153000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-02 09:22:21.408000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.235",
	"createdAt": "2024-05-06 10:50:21.879000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.489",
	"createdAt": "2024-05-06 11:06:08.034000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-08 11:07:44.172000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.017",
	"createdAt": "2024-05-27 08:48:33.963000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-27 13:36:52.837000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.033",
	"createdAt": "2024-06-02 09:53:06.320000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.385",
	"createdAt": "2024-06-02 11:56:34.198000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "albertineb@playtika.com",
	"app": "FRGE-001",
	"hardware": "XL-001",
	"sumHours": "2.0",
	"createdAt": "2024-04-10 11:06:42.818000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitge@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-08 18:39:57.109000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2023-12-06 09:13:42.647000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2023-12-06 09:53:59.082000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.021",
	"createdAt": "2023-12-06 16:56:26.254000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.183",
	"createdAt": "2023-12-12 10:18:10.860000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.389",
	"createdAt": "2023-12-13 06:13:51.220000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.184",
	"createdAt": "2023-12-13 07:13:17.297000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.245",
	"createdAt": "2023-12-14 07:44:02.767000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.394",
	"createdAt": "2023-12-17 12:30:28.770000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.492",
	"createdAt": "2023-12-18 06:21:55.108000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.413",
	"createdAt": "2023-12-19 08:36:51.766000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.964",
	"createdAt": "2023-12-19 09:13:08.573000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.381",
	"createdAt": "2023-12-24 16:15:47.921000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.004",
	"createdAt": "2023-12-26 10:04:04.720000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.046",
	"createdAt": "2023-12-27 12:57:13.885000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.732",
	"createdAt": "2023-12-27 13:08:13.386000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.551",
	"createdAt": "2024-01-28 16:10:55.509000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.136",
	"createdAt": "2024-01-29 07:54:27.996000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.251",
	"createdAt": "2024-01-29 10:39:45.387000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.492",
	"createdAt": "2024-01-29 11:35:18.841000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.383",
	"createdAt": "2024-01-30 14:09:19.753000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.622",
	"createdAt": "2024-01-31 07:16:47.056000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.395",
	"createdAt": "2024-01-31 12:05:23.449000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.08",
	"createdAt": "2024-01-31 15:28:22.800000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.802",
	"createdAt": "2024-02-01 09:51:36.155000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.349",
	"createdAt": "2024-02-01 11:18:35.329000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.389",
	"createdAt": "2024-02-01 12:30:39.589000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.363",
	"createdAt": "2024-02-01 14:30:42.800000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.105",
	"createdAt": "2024-03-12 16:31:48.001000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.005",
	"createdAt": "2024-05-12 12:24:57.156000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.244",
	"createdAt": "2024-05-12 13:38:55.889000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "amitst@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.824",
	"createdAt": "2024-05-13 10:47:07.113000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "bkhlobystov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.036",
	"createdAt": "2024-04-10 11:07:00.023000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "bkhlobystov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.299",
	"createdAt": "2024-04-24 11:06:38.490000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "bkhlobystov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.246",
	"createdAt": "2024-03-27 13:52:50.126000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "bkhlobystov@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.469",
	"createdAt": "2024-04-10 11:12:48.214000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "bkhlobystov@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.027",
	"createdAt": "2024-04-10 11:45:45.951000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "bkhlobystov@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.393",
	"createdAt": "2024-04-10 11:49:02.249000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "bkhlobystov@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.684",
	"createdAt": "2024-05-15 11:05:52.104000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2023-12-06 11:05:33.104000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.873",
	"createdAt": "2023-12-12 10:21:50.823000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.037",
	"createdAt": "2024-01-29 10:27:47.175000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.28",
	"createdAt": "2024-01-29 12:05:30.591000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-01-29 15:38:28.583000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.016",
	"createdAt": "2024-01-31 08:26:05.106000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.425",
	"createdAt": "2024-02-01 07:50:41.905000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.314",
	"createdAt": "2024-02-01 09:17:59.957000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.463",
	"createdAt": "2024-02-04 10:32:17.254000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.678",
	"createdAt": "2024-02-05 07:07:48.122000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.424",
	"createdAt": "2024-02-05 08:47:56.469000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.01",
	"createdAt": "2024-02-07 09:38:21.770000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.388",
	"createdAt": "2024-02-07 13:29:51.935000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.93",
	"createdAt": "2024-02-08 09:09:42.951000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.957",
	"createdAt": "2024-02-08 15:25:24.163000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.34",
	"createdAt": "2024-02-12 13:02:47.252000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "7.604",
	"createdAt": "2024-02-14 09:26:46.748000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.009",
	"createdAt": "2024-02-15 07:43:21.435000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.771",
	"createdAt": "2024-02-15 13:43:13.260000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.074",
	"createdAt": "2024-02-18 17:21:07.139000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.017",
	"createdAt": "2024-02-18 19:28:24.728000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.527",
	"createdAt": "2024-02-19 15:18:47.829000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.062",
	"createdAt": "2024-02-22 08:43:14.846000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.018",
	"createdAt": "2024-02-22 08:50:31.546000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-22 08:54:43.684000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.091",
	"createdAt": "2024-02-22 16:07:27.347000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.341",
	"createdAt": "2024-02-23 17:24:27.695000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.777",
	"createdAt": "2024-02-24 15:33:35.350000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "8.544",
	"createdAt": "2024-03-07 07:34:26.910000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.009",
	"createdAt": "2024-03-11 12:37:20.177000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.785",
	"createdAt": "2024-03-11 16:58:34.073000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.332",
	"createdAt": "2024-03-12 10:23:54.093000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.006",
	"createdAt": "2024-03-13 07:54:58.813000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "8.126",
	"createdAt": "2024-03-14 08:20:53.652000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.013",
	"createdAt": "2024-03-19 08:42:30.237000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.433",
	"createdAt": "2024-03-19 11:58:46.934000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.117",
	"createdAt": "2024-03-20 08:17:35.409000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.581",
	"createdAt": "2024-03-20 13:26:45.002000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.403",
	"createdAt": "2024-03-20 14:05:06.099000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.73",
	"createdAt": "2024-03-20 14:41:37.333000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.947",
	"createdAt": "2024-03-21 10:31:47.316000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.215",
	"createdAt": "2024-03-21 16:29:14.300000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.918",
	"createdAt": "2024-03-21 16:44:28.019000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.828",
	"createdAt": "2024-03-24 12:29:01.728000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.999",
	"createdAt": "2024-03-24 13:23:41.587000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.656",
	"createdAt": "2024-04-02 13:55:51.788000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.749",
	"createdAt": "2024-04-03 08:57:33.818000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.818",
	"createdAt": "2024-04-03 11:47:05.148000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.223",
	"createdAt": "2024-04-10 06:16:40.377000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.359",
	"createdAt": "2024-04-10 12:59:48.482000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.266",
	"createdAt": "2024-04-17 11:20:20.882000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.837",
	"createdAt": "2024-04-18 08:52:41.365000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.406",
	"createdAt": "2024-04-25 07:20:05.362000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.298",
	"createdAt": "2024-05-02 14:03:39.510000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.157",
	"createdAt": "2024-05-06 14:42:03.799000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.05",
	"createdAt": "2024-05-06 15:17:02.588000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.414",
	"createdAt": "2024-05-07 17:11:25.726000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "5.013",
	"createdAt": "2024-05-16 07:43:31.360000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.822",
	"createdAt": "2024-05-19 06:11:09.247000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-19 09:02:09.955000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.338",
	"createdAt": "2024-05-19 11:06:27.313000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.321",
	"createdAt": "2024-05-19 12:01:57.711000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.591",
	"createdAt": "2024-05-20 06:32:00.926000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.016",
	"createdAt": "2024-05-22 08:11:30.603000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.465",
	"createdAt": "2024-05-22 14:26:15.931000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.397",
	"createdAt": "2024-05-23 06:09:40.322000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.103",
	"createdAt": "2024-05-27 08:48:04.590000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.258",
	"createdAt": "2024-05-27 11:57:12.338000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.351",
	"createdAt": "2024-05-28 08:53:23.577000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.093",
	"createdAt": "2024-05-28 11:22:52.994000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "9.532",
	"createdAt": "2024-05-29 06:24:41.748000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-01-29 10:31:54.828000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.003",
	"createdAt": "2024-02-27 13:49:25.243000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.176",
	"createdAt": "2024-02-27 17:31:49.845000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.091",
	"createdAt": "2024-05-27 12:27:25.025000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.018",
	"createdAt": "2023-12-06 07:59:22.413000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.259",
	"createdAt": "2023-12-06 09:45:37.672000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2023-12-06 16:38:08.818000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.372",
	"createdAt": "2023-12-11 15:36:46.021000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2023-12-11 16:01:15.707000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.962",
	"createdAt": "2023-12-14 10:08:03.494000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.139",
	"createdAt": "2023-12-17 10:22:20.357000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.008",
	"createdAt": "2023-12-17 11:36:06.517000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.492",
	"createdAt": "2023-12-18 11:11:37.457000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.658",
	"createdAt": "2023-12-18 12:55:57.917000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.027",
	"createdAt": "2023-12-19 07:45:49.583000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.672",
	"createdAt": "2023-12-19 07:51:47.676000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.893",
	"createdAt": "2023-12-19 10:34:38.057000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.268",
	"createdAt": "2023-12-20 13:13:26.986000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.003",
	"createdAt": "2023-12-20 14:42:30.989000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.797",
	"createdAt": "2023-12-25 12:13:57.316000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.0",
	"createdAt": "2023-12-25 18:04:12.836000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.005",
	"createdAt": "2024-01-02 09:57:08.076000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.517",
	"createdAt": "2024-01-02 14:08:18.213000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-03 17:48:50.544000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "7.231",
	"createdAt": "2024-01-04 09:19:46.325000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.015",
	"createdAt": "2024-01-08 10:24:19.467000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.642",
	"createdAt": "2024-01-08 13:33:05.060000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.304",
	"createdAt": "2024-01-10 09:12:49.538000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-10 12:01:55.830000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.767",
	"createdAt": "2024-01-10 14:35:54.205000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.657",
	"createdAt": "2024-01-11 08:18:47.284000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.831",
	"createdAt": "2024-01-11 14:37:54.214000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.49",
	"createdAt": "2024-01-14 10:31:54.752000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-01-15 09:02:16.991000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.889",
	"createdAt": "2024-01-15 12:01:27.086000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "8.742",
	"createdAt": "2024-01-17 08:09:00.243000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.32",
	"createdAt": "2024-01-18 07:49:07.723000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.364",
	"createdAt": "2024-01-18 10:10:41.942000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.859",
	"createdAt": "2024-01-18 14:35:46.507000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "8.991",
	"createdAt": "2024-01-22 08:02:34.233000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "8.014",
	"createdAt": "2024-01-23 07:16:44.904000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.011",
	"createdAt": "2024-01-24 07:45:41.015000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.308",
	"createdAt": "2024-01-24 14:32:36.992000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.007",
	"createdAt": "2024-01-25 07:27:55.012000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.663",
	"createdAt": "2024-01-25 09:58:18.293000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.589",
	"createdAt": "2024-01-25 14:08:30.735000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.914",
	"createdAt": "2024-01-28 09:17:54.213000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.313",
	"createdAt": "2024-01-28 10:15:01.843000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.784",
	"createdAt": "2024-01-28 10:36:52.244000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.195",
	"createdAt": "2024-01-28 15:25:45.430000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.716",
	"createdAt": "2024-01-29 07:46:28.045000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.17",
	"createdAt": "2024-01-29 08:32:05.802000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.443",
	"createdAt": "2024-01-29 08:58:22.677000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.276",
	"createdAt": "2024-01-31 11:49:57.799000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.748",
	"createdAt": "2024-02-01 14:05:46.530000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.328",
	"createdAt": "2024-02-04 06:48:08.316000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.008",
	"createdAt": "2024-02-04 07:10:22.763000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.004",
	"createdAt": "2024-02-12 08:40:01.721000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.709",
	"createdAt": "2024-02-18 19:38:51.507000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.006",
	"createdAt": "2024-02-19 11:55:38.284000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.01",
	"createdAt": "2024-02-25 13:47:54.415000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.002",
	"createdAt": "2024-02-26 07:39:12.179000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.309",
	"createdAt": "2024-02-26 11:17:46.605000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.806",
	"createdAt": "2024-02-29 06:58:25.994000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.026",
	"createdAt": "2024-03-04 15:02:35.967000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.645",
	"createdAt": "2024-03-18 14:35:46.902000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.169",
	"createdAt": "2024-03-25 09:44:57.439000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.014",
	"createdAt": "2024-03-25 14:10:52.531000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-25 17:26:44.018000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.673",
	"createdAt": "2024-03-26 11:11:06.216000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.015",
	"createdAt": "2024-03-27 14:00:37.629000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.679",
	"createdAt": "2024-03-27 17:44:52.427000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.288",
	"createdAt": "2024-03-28 08:03:59.075000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.873",
	"createdAt": "2024-03-28 14:41:58.882000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "8.572",
	"createdAt": "2024-03-31 08:27:44.795000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.891",
	"createdAt": "2024-04-01 06:29:21.013000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.528",
	"createdAt": "2024-04-01 10:54:10.663000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.126",
	"createdAt": "2024-04-02 07:45:02.181000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "8.144",
	"createdAt": "2024-04-04 06:41:07.395000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.014",
	"createdAt": "2024-04-07 06:37:07.003000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.418",
	"createdAt": "2024-04-07 14:28:26.285000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.01",
	"createdAt": "2024-04-08 06:33:57.750000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.011",
	"createdAt": "2024-04-08 12:06:12.285000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.407",
	"createdAt": "2024-04-09 16:04:07.400000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.009",
	"createdAt": "2024-04-11 06:37:44.999000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.234",
	"createdAt": "2024-04-12 13:04:24.301000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.008",
	"createdAt": "2024-04-14 09:04:22.922000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.124",
	"createdAt": "2024-04-14 12:25:49.345000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.493",
	"createdAt": "2024-04-15 15:40:15.141000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "7.399",
	"createdAt": "2024-04-16 11:50:03.174000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.052",
	"createdAt": "2024-04-17 09:14:19.831000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.362",
	"createdAt": "2024-04-21 08:48:11.022000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.03",
	"createdAt": "2024-04-21 17:57:15.947000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.494",
	"createdAt": "2024-04-24 07:28:10.490000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.017",
	"createdAt": "2024-04-25 13:49:27.949000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "9.706",
	"createdAt": "2024-04-30 06:34:35.424000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.016",
	"createdAt": "2024-05-01 06:40:12.806000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.513",
	"createdAt": "2024-05-01 10:04:39.665000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.105",
	"createdAt": "2024-05-01 14:05:37.087000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.162",
	"createdAt": "2024-05-02 07:45:38.521000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.957",
	"createdAt": "2024-05-02 12:57:47.841000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.581",
	"createdAt": "2024-05-05 06:30:15.265000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.017",
	"createdAt": "2024-05-06 06:49:13.747000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.05",
	"createdAt": "2024-05-06 14:37:27.592000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.674",
	"createdAt": "2024-05-08 09:43:26.750000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.162",
	"createdAt": "2024-05-08 11:25:48.968000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.025",
	"createdAt": "2024-05-08 14:34:47.184000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "9.632",
	"createdAt": "2024-05-09 06:36:39.397000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "7.706",
	"createdAt": "2024-05-12 06:49:38.134000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.862",
	"createdAt": "2024-05-15 12:52:23.661000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.341",
	"createdAt": "2024-05-01 13:40:01.361000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.059",
	"createdAt": "2024-05-01 14:14:11.462000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "LG-002",
	"sumHours": "3.0",
	"createdAt": "2024-05-15 06:48:46.340000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "LG-002",
	"sumHours": "2.878",
	"createdAt": "2024-05-15 09:58:19.319000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "LG-002",
	"sumHours": "1.429",
	"createdAt": "2024-05-15 10:04:35.183000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "LG-002",
	"sumHours": "6.061",
	"createdAt": "2024-05-16 07:07:54.090000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "LG-002",
	"sumHours": "0.037",
	"createdAt": "2024-05-19 09:03:09.951000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "LG-002",
	"sumHours": "0.071",
	"createdAt": "2024-05-19 09:07:01.060000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "LG-002",
	"sumHours": "2.015",
	"createdAt": "2024-05-19 09:49:17.272000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "LG-002",
	"sumHours": "0.101",
	"createdAt": "2024-05-20 08:32:49.114000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "0.557",
	"createdAt": "2024-05-19 09:12:35.497000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "3.004",
	"createdAt": "2024-05-19 12:22:49.503000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "1.678",
	"createdAt": "2024-05-20 06:44:07.036000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "2.338",
	"createdAt": "2024-05-20 08:47:18.757000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "3.002",
	"createdAt": "2024-05-22 08:20:56.777000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "0.021",
	"createdAt": "2024-05-23 06:31:58.867000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "0.968",
	"createdAt": "2024-05-23 09:15:30.705000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "0.078",
	"createdAt": "2024-05-27 12:08:18.095000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "3.327",
	"createdAt": "2024-05-27 12:14:24.841000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "8.962",
	"createdAt": "2024-05-29 07:05:15.531000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.124",
	"createdAt": "2024-04-10 11:44:20.646000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "3.047",
	"createdAt": "2024-04-15 10:33:58.349000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "3.05",
	"createdAt": "2024-04-16 08:42:34.506000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.163",
	"createdAt": "2024-04-18 08:39:56.362000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "4.107",
	"createdAt": "2024-04-11 12:01:28.428000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "daniellas@playtika.com",
	"app": "FRGE-001",
	"hardware": "XL-001",
	"sumHours": "0.093",
	"createdAt": "2024-04-10 11:36:48.872000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.012",
	"createdAt": "2023-12-06 09:41:42.028000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.015",
	"createdAt": "2023-12-06 10:47:09.495000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2023-12-19 08:14:55.625000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.411",
	"createdAt": "2023-12-19 08:40:48.029000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.109",
	"createdAt": "2023-12-20 14:22:07.931000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.0",
	"createdAt": "2023-12-20 14:38:20.532000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.01",
	"createdAt": "2024-01-03 12:15:56.279000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.007",
	"createdAt": "2024-01-04 10:28:05.950000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.009",
	"createdAt": "2024-01-10 12:13:34.664000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.006",
	"createdAt": "2024-01-17 12:03:51.239000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.014",
	"createdAt": "2024-01-17 12:17:48.339000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2024-01-24 12:32:45.481000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.011",
	"createdAt": "2024-01-31 12:06:35.599000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2024-02-02 09:18:06.542000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.216",
	"createdAt": "2024-02-14 12:15:11.315000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-14 12:31:01.422000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.003",
	"createdAt": "2024-02-28 12:06:58.783000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.014",
	"createdAt": "2024-03-06 12:02:14.617000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.009",
	"createdAt": "2024-03-13 12:00:38.668000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-14 11:28:05.649000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.103",
	"createdAt": "2024-03-20 12:57:46.815000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.014",
	"createdAt": "2024-03-20 13:08:17.542000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dgagaro@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-03 11:00:05.723000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dshchelkanov@playtika.com",
	"app": "ADUI-001",
	"hardware": "SM-001",
	"sumHours": "0.052",
	"createdAt": "2024-05-21 12:28:26.692000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.183",
	"createdAt": "2024-05-22 10:12:14.607000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-28 08:55:41.472000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "dshchelkanov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-29 10:39:14.631000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.074",
	"createdAt": "2024-01-03 12:13:19.806000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.256",
	"createdAt": "2024-02-22 16:34:38.860000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.055",
	"createdAt": "2024-02-28 15:19:40.747000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.011",
	"createdAt": "2024-03-12 08:45:30.975000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.715",
	"createdAt": "2024-01-10 12:07:31.038000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.247",
	"createdAt": "2024-02-29 10:38:40.046000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.253",
	"createdAt": "2023-12-11 14:58:10.732000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.011",
	"createdAt": "2024-01-29 09:24:32.757000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2023-12-11 15:16:16.928000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2023-12-12 13:39:48.933000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.007",
	"createdAt": "2023-12-13 12:02:45.841000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.008",
	"createdAt": "2023-12-19 08:21:54.502000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.013",
	"createdAt": "2023-12-25 10:56:14.637000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.087",
	"createdAt": "2023-12-25 12:03:18.977000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.876",
	"createdAt": "2023-12-25 13:00:33.281000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.512",
	"createdAt": "2023-12-25 15:32:21.759000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.09",
	"createdAt": "2023-12-27 13:29:37.295000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.556",
	"createdAt": "2023-12-27 14:17:54.376000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.671",
	"createdAt": "2023-12-28 09:09:04.650000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.249",
	"createdAt": "2023-12-28 11:01:45.885000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.023",
	"createdAt": "2023-12-28 14:43:31.462000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.67",
	"createdAt": "2024-01-02 10:06:08.814000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.387",
	"createdAt": "2024-01-04 09:45:00.265000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.351",
	"createdAt": "2024-01-04 11:12:44.835000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.244",
	"createdAt": "2024-01-04 11:43:52.934000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.465",
	"createdAt": "2024-01-04 13:12:49.422000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.48",
	"createdAt": "2024-01-08 14:07:39.357000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.163",
	"createdAt": "2024-01-10 12:53:22.591000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.293",
	"createdAt": "2024-01-11 10:01:07.733000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.696",
	"createdAt": "2024-01-11 10:23:51.292000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.244",
	"createdAt": "2024-01-11 13:08:24.554000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.346",
	"createdAt": "2024-01-15 10:33:19.331000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.352",
	"createdAt": "2024-01-15 11:01:04.783000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.391",
	"createdAt": "2024-01-23 08:40:16.603000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.92",
	"createdAt": "2024-01-24 12:40:36.674000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.958",
	"createdAt": "2024-01-25 10:22:04.981000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.455",
	"createdAt": "2024-01-30 15:49:36.654000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.964",
	"createdAt": "2024-02-19 08:11:40.272000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.8",
	"createdAt": "2024-02-20 11:35:39.991000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.011",
	"createdAt": "2024-02-20 12:26:04.307000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-02-20 13:12:58.004000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.41",
	"createdAt": "2024-02-22 12:05:29.432000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.01",
	"createdAt": "2024-02-22 15:23:42.533000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.086",
	"createdAt": "2024-02-28 15:25:03.531000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.449",
	"createdAt": "2024-03-03 08:07:50.669000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.329",
	"createdAt": "2024-03-04 11:59:29.694000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.735",
	"createdAt": "2024-03-04 12:21:01.285000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.436",
	"createdAt": "2024-03-11 08:25:44.647000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-03-12 10:32:37.870000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.006",
	"createdAt": "2024-03-13 08:32:36.464000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.32",
	"createdAt": "2024-03-14 14:04:30.831000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.033",
	"createdAt": "2024-03-14 14:29:25.072000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.04",
	"createdAt": "2024-03-14 14:35:21.800000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.466",
	"createdAt": "2024-03-19 12:47:29.095000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.921",
	"createdAt": "2024-03-27 15:13:54.551000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.915",
	"createdAt": "2024-03-28 08:20:51.217000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.335",
	"createdAt": "2024-03-28 09:37:10.502000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.104",
	"createdAt": "2024-03-28 13:19:37.085000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.004",
	"createdAt": "2024-04-01 06:54:42.928000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.049",
	"createdAt": "2024-04-01 09:48:56.692000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.969",
	"createdAt": "2024-04-01 11:09:36.637000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.471",
	"createdAt": "2024-04-01 13:31:05.130000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.545",
	"createdAt": "2024-04-07 09:31:18.212000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.146",
	"createdAt": "2024-04-21 10:49:49.035000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.017",
	"createdAt": "2024-05-05 10:47:12.589000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.016",
	"createdAt": "2024-05-05 12:10:05.076000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.001",
	"createdAt": "2024-05-06 06:50:45.354000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.384",
	"createdAt": "2024-05-09 09:01:36.272000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2023-12-03 14:34:57.206000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.678",
	"createdAt": "2023-12-18 13:31:45.961000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.354",
	"createdAt": "2023-12-19 06:11:07.488000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.134",
	"createdAt": "2024-01-01 11:52:02.764000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.063",
	"createdAt": "2024-01-02 12:26:29.581000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.005",
	"createdAt": "2024-01-07 13:01:54.276000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-01-24 09:03:45.820000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.089",
	"createdAt": "2024-01-25 10:04:31.704000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.362",
	"createdAt": "2024-01-25 11:22:27.039000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-25 12:56:50.228000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-01-31 12:37:18.879000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.462",
	"createdAt": "2024-02-01 13:10:04.115000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.0",
	"createdAt": "2024-02-08 07:39:48.422000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.133",
	"createdAt": "2024-02-08 10:00:42.159000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.403",
	"createdAt": "2024-02-12 08:16:45.540000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.936",
	"createdAt": "2024-03-03 07:59:40.108000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.776",
	"createdAt": "2024-03-12 13:11:26.398000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-07 13:58:45.211000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-08 06:51:11.995000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.385",
	"createdAt": "2024-05-08 13:26:34.414000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "eyalr@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.053",
	"createdAt": "2024-05-09 08:52:19.793000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-07 08:43:12.084000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.57",
	"createdAt": "2024-04-09 11:15:54.381000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-10 10:53:21.975000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.011",
	"createdAt": "2024-05-01 13:37:14.210000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.006",
	"createdAt": "2024-02-28 13:58:12.447000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.402",
	"createdAt": "2024-02-28 15:12:18.549000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.101",
	"createdAt": "2024-02-29 10:58:00.273000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.374",
	"createdAt": "2023-12-06 09:34:56.184000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.969",
	"createdAt": "2023-12-19 08:04:53.051000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.716",
	"createdAt": "2023-12-19 10:17:35.030000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.012",
	"createdAt": "2024-01-03 10:35:00.553000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.077",
	"createdAt": "2024-01-03 12:27:08.604000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.012",
	"createdAt": "2024-01-03 12:39:56.819000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.924",
	"createdAt": "2024-01-10 12:03:03.129000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.0",
	"createdAt": "2024-02-02 08:50:41.382000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.573",
	"createdAt": "2024-02-02 10:01:36.675000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.542",
	"createdAt": "2024-02-12 11:50:38.372000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.391",
	"createdAt": "2024-02-14 09:10:17.666000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.506",
	"createdAt": "2024-02-14 12:08:24.976000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.869",
	"createdAt": "2024-02-28 12:03:57.314000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.282",
	"createdAt": "2024-03-06 11:51:32.689000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.845",
	"createdAt": "2024-03-13 12:06:25.298000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.243",
	"createdAt": "2024-03-20 13:33:54.717000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.461",
	"createdAt": "2024-03-26 15:10:28.278000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.629",
	"createdAt": "2024-04-04 13:08:32.142000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "gkuchinov@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.016",
	"createdAt": "2024-04-24 11:16:00.217000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "guykl@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-29 12:33:10.076000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "guykl@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.613",
	"createdAt": "2024-04-07 09:33:52.760000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "guykl@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "1.007",
	"createdAt": "2024-04-10 11:13:17.718000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "guykl@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.06",
	"createdAt": "2024-04-10 11:06:58.303000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.351",
	"createdAt": "2024-01-01 10:45:01.133000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.283",
	"createdAt": "2024-01-02 20:12:08.656000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.207",
	"createdAt": "2024-01-03 10:07:33.142000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.425",
	"createdAt": "2024-01-03 12:19:41.583000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.857",
	"createdAt": "2024-01-03 14:18:08.748000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-01-03 18:16:52.984000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-03 18:35:20.799000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.268",
	"createdAt": "2024-01-08 09:14:15.377000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.3",
	"createdAt": "2024-01-10 14:27:13.847000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.425",
	"createdAt": "2024-01-10 15:16:40.707000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.085",
	"createdAt": "2024-01-11 13:19:40.004000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.006",
	"createdAt": "2024-01-11 18:34:12.966000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-01-14 11:28:06.281000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-15 13:18:53.393000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.371",
	"createdAt": "2024-01-15 15:22:31.308000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.113",
	"createdAt": "2024-01-15 17:03:08.418000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.834",
	"createdAt": "2024-01-16 07:18:35.383000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.341",
	"createdAt": "2024-01-16 08:14:11.861000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.29",
	"createdAt": "2024-01-17 13:46:02.192000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.274",
	"createdAt": "2024-01-17 14:19:35.708000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.019",
	"createdAt": "2024-01-18 08:38:17.917000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.752",
	"createdAt": "2024-01-18 09:32:20.674000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.719",
	"createdAt": "2024-01-18 12:40:51.003000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.799",
	"createdAt": "2024-01-18 14:37:58.901000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.726",
	"createdAt": "2024-03-14 08:10:49.523000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.402",
	"createdAt": "2024-03-14 12:49:25.266000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.746",
	"createdAt": "2024-03-14 13:34:29.312000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.372",
	"createdAt": "2024-03-16 14:48:49.557000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.461",
	"createdAt": "2024-03-16 16:13:22.595000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-18 13:08:54.945000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.541",
	"createdAt": "2024-03-19 10:24:01.271000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.472",
	"createdAt": "2024-03-20 10:25:31.941000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.393",
	"createdAt": "2024-03-27 15:54:00.827000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.991",
	"createdAt": "2024-03-27 20:17:24.615000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.037",
	"createdAt": "2024-03-28 07:27:17.140000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-28 07:50:00.384000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-31 14:11:46.786000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.536",
	"createdAt": "2024-03-31 14:17:49.138000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.177",
	"createdAt": "2024-04-01 11:32:29.168000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-01 14:24:30.481000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.996",
	"createdAt": "2024-04-02 06:55:36.169000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.375",
	"createdAt": "2024-04-03 12:19:13.815000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-04 12:12:14.787000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.533",
	"createdAt": "2024-04-06 14:21:51.901000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.862",
	"createdAt": "2024-04-07 08:46:16.797000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.868",
	"createdAt": "2024-04-07 12:22:50.422000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-07 14:22:37.266000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.96",
	"createdAt": "2024-04-18 08:28:54.603000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-04-18 10:27:58.875000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.022",
	"createdAt": "2024-04-21 12:59:01.042000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-01 08:09:38.432000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.477",
	"createdAt": "2024-05-07 13:37:14.088000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.16",
	"createdAt": "2024-05-08 07:42:00.306000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.786",
	"createdAt": "2024-05-08 12:01:14.969000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.966",
	"createdAt": "2024-05-08 18:29:47.810000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-16 07:37:47.428000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.844",
	"createdAt": "2023-11-29 10:07:48.822000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.013",
	"createdAt": "2023-12-04 08:49:08.432000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.06",
	"createdAt": "2023-12-04 08:50:31.559000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.011",
	"createdAt": "2023-12-06 08:03:41.736000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.053",
	"createdAt": "2023-12-06 08:05:46.120000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.421",
	"createdAt": "2023-12-06 09:38:54.764000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2023-12-19 07:53:46.615000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.014",
	"createdAt": "2023-12-19 10:00:14.303000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "henlu@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.607",
	"createdAt": "2024-01-01 09:05:03.166000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idanye@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.256",
	"createdAt": "2023-12-17 14:30:14.091000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idanye@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.435",
	"createdAt": "2024-03-31 10:56:15.143000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.35",
	"createdAt": "2024-01-24 16:57:21.569000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.677",
	"createdAt": "2024-01-25 10:40:26.395000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.387",
	"createdAt": "2024-01-28 17:42:38.426000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.627",
	"createdAt": "2024-01-31 12:21:03.356000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.442",
	"createdAt": "2024-02-01 10:40:30.596000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.429",
	"createdAt": "2024-02-12 08:44:13.570000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-19 13:49:39.389000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-20 07:28:39.394000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.2",
	"createdAt": "2024-03-10 08:19:54.111000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-10 12:31:50.460000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-10 19:50:31.065000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.265",
	"createdAt": "2024-03-12 06:01:43.995000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-12 18:57:57.238000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.055",
	"createdAt": "2024-03-13 13:37:32.155000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.24",
	"createdAt": "2024-03-18 13:36:49.320000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.007",
	"createdAt": "2024-03-21 08:55:13.305000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.34",
	"createdAt": "2024-03-21 10:09:13.518000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.846",
	"createdAt": "2024-03-25 17:23:01.217000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.956",
	"createdAt": "2024-04-01 08:19:54.554000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.291",
	"createdAt": "2024-04-02 07:30:28.549000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.601",
	"createdAt": "2024-04-03 07:54:01.182000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.179",
	"createdAt": "2024-04-04 09:08:36.635000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.563",
	"createdAt": "2024-04-04 12:04:51.011000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.048",
	"createdAt": "2024-04-04 13:40:35.320000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.109",
	"createdAt": "2024-04-04 13:53:14.301000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.174",
	"createdAt": "2024-04-04 14:02:29.581000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.346",
	"createdAt": "2024-04-07 07:47:36.415000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-08 06:33:35.899000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.237",
	"createdAt": "2024-04-11 07:31:57.453000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.914",
	"createdAt": "2024-04-24 10:19:54.244000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.002",
	"createdAt": "2024-01-22 08:22:26.125000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "idos@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.209",
	"createdAt": "2024-01-23 08:38:34.081000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.41",
	"createdAt": "2024-01-23 08:40:25.770000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.23",
	"createdAt": "2024-01-28 19:56:36.274000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.382",
	"createdAt": "2024-02-26 07:51:11.449000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.509",
	"createdAt": "2024-02-26 08:20:49.972000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.371",
	"createdAt": "2024-02-28 19:39:20.743000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.106",
	"createdAt": "2024-02-28 22:36:48.234000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.565",
	"createdAt": "2024-03-04 06:52:42.869000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.001",
	"createdAt": "2024-03-05 03:54:53.663000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.926",
	"createdAt": "2024-03-13 12:46:53.411000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.035",
	"createdAt": "2024-03-13 14:08:25.456000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-13 21:22:42.804000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.202",
	"createdAt": "2024-03-13 21:52:16.449000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.589",
	"createdAt": "2024-03-18 15:27:17.099000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.601",
	"createdAt": "2024-03-19 20:39:06.373000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.584",
	"createdAt": "2024-03-21 22:06:27.714000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.09",
	"createdAt": "2024-03-27 08:32:07.559000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-03-27 10:51:38.482000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.415",
	"createdAt": "2024-03-27 13:02:40.630000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.45",
	"createdAt": "2024-03-27 20:03:05.849000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.827",
	"createdAt": "2024-04-03 06:58:22.684000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.206",
	"createdAt": "2024-05-08 11:08:06.005000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-08 11:22:01.951000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.8",
	"createdAt": "2024-05-21 11:09:45.910000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.501",
	"createdAt": "2024-05-22 20:42:46.238000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.078",
	"createdAt": "2024-05-27 08:56:06.131000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.343",
	"createdAt": "2024-05-27 18:25:53.354000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.531",
	"createdAt": "2024-05-27 20:04:37.312000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.852",
	"createdAt": "2024-04-10 11:13:58.565000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "liads@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.07",
	"createdAt": "2024-04-10 11:05:19.851000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.781",
	"createdAt": "2024-04-03 17:05:11.365000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.056",
	"createdAt": "2024-04-04 08:42:34.359000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.123",
	"createdAt": "2024-04-04 08:49:11.685000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-17 11:06:26.593000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.518",
	"createdAt": "2024-05-01 08:35:45.094000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.57",
	"createdAt": "2024-05-08 10:25:28.154000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-12 16:30:32.684000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-15 09:53:18.069000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-15 14:02:03.125000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.556",
	"createdAt": "2024-03-20 19:30:34.185000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.014",
	"createdAt": "2024-03-27 08:41:33.106000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.911",
	"createdAt": "2024-03-27 12:39:58.339000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.043",
	"createdAt": "2024-04-05 16:17:11.663000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.272",
	"createdAt": "2024-04-06 16:21:05.884000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-16 12:54:37.234000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.046",
	"createdAt": "2024-04-12 18:13:50.767000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michaelsa@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "1.003",
	"createdAt": "2024-04-12 14:52:25.578000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.011",
	"createdAt": "2024-05-21 09:50:57.685000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.008",
	"createdAt": "2024-05-28 13:39:25.988000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.844",
	"createdAt": "2024-02-20 12:30:06.687000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ninako@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.262",
	"createdAt": "2024-03-18 11:49:53.347000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ninako@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-27 10:07:00.661000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ninako@playtika.com",
	"app": "FCUS-001",
	"hardware": "SM-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-27 10:08:10.226000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2023-12-06 08:13:27.901000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2023-12-06 09:49:15.059000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2023-12-06 10:00:07.079000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2023-12-19 08:12:35.048000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2023-12-19 10:17:37.993000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-03 12:04:50.209000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-10 12:04:17.245000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-17 12:00:58.236000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-24 12:26:27.059000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-01-31 11:56:24.679000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-31 11:58:28.712000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.002",
	"createdAt": "2024-02-02 08:52:50.251000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-14 12:00:42.752000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.161",
	"createdAt": "2024-03-06 11:49:11.555000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-13 11:45:55.241000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.815",
	"createdAt": "2024-03-13 12:11:58.178000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.09",
	"createdAt": "2024-03-20 12:57:10.960000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.327",
	"createdAt": "2024-03-20 13:12:40.238000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.509",
	"createdAt": "2024-03-20 13:41:22.182000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.488",
	"createdAt": "2024-03-26 15:04:34.583000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.931",
	"createdAt": "2024-03-26 15:36:42.021000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.384",
	"createdAt": "2024-03-27 12:19:35.271000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.247",
	"createdAt": "2024-03-27 12:44:55.625000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.904",
	"createdAt": "2024-05-15 10:45:40.797000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.22",
	"createdAt": "2024-05-20 06:56:42.532000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-29 10:38:31.830000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-002",
	"sumHours": "0.058",
	"createdAt": "2024-03-20 13:36:16.111000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "FCUS-001",
	"hardware": "SM-001",
	"sumHours": "0.091",
	"createdAt": "2024-03-20 13:05:35.355000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "FCUS-001",
	"hardware": "SM-001",
	"sumHours": "0.083",
	"createdAt": "2024-05-20 06:49:50.857000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.86",
	"createdAt": "2024-04-10 11:13:09.967000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "1.383",
	"createdAt": "2024-02-28 11:27:47.677000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "nzuyeva@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.015",
	"createdAt": "2024-04-10 11:09:08.565000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "oferkl@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.056",
	"createdAt": "2024-01-07 09:57:36.775000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "oferkl@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-29 08:05:15.321000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "oferkl@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-01-29 11:48:06.338000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "oferkl@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.011",
	"createdAt": "2024-03-19 16:08:51.812000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.135",
	"createdAt": "2024-03-06 13:15:30.381000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.181",
	"createdAt": "2024-03-14 11:42:44.817000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.107",
	"createdAt": "2024-03-20 13:47:31.965000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.071",
	"createdAt": "2024-03-20 13:57:24.095000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.152",
	"createdAt": "2024-03-29 11:19:44.414000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.199",
	"createdAt": "2024-04-09 13:22:26.287000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.109",
	"createdAt": "2024-04-22 07:59:42.402000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.734",
	"createdAt": "2024-04-22 11:12:27.325000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.158",
	"createdAt": "2024-05-03 08:18:56.224000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.098",
	"createdAt": "2024-05-10 06:08:49.828000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.146",
	"createdAt": "2024-05-13 08:02:47.051000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.007",
	"createdAt": "2024-05-21 09:03:52.019000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.798",
	"createdAt": "2024-05-22 06:09:11.336000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.549",
	"createdAt": "2024-01-03 12:18:08.815000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-04 08:46:11.827000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.449",
	"createdAt": "2024-01-04 11:01:02.707000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.696",
	"createdAt": "2024-01-05 09:29:27.668000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.905",
	"createdAt": "2024-01-05 10:24:22.608000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.383",
	"createdAt": "2024-01-05 12:44:26.918000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.311",
	"createdAt": "2024-01-08 08:55:27.375000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-08 09:30:07.400000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.842",
	"createdAt": "2024-01-08 11:35:46.107000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.683",
	"createdAt": "2024-01-08 14:17:28.512000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.444",
	"createdAt": "2024-01-09 10:26:37.186000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.596",
	"createdAt": "2024-01-09 13:44:06.468000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.442",
	"createdAt": "2024-01-10 10:58:06.809000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.02",
	"createdAt": "2024-01-10 12:04:29.395000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.016",
	"createdAt": "2024-01-10 13:20:10.966000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.272",
	"createdAt": "2024-01-11 08:49:52.920000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.969",
	"createdAt": "2024-01-11 10:15:51.334000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.548",
	"createdAt": "2024-01-11 13:17:29.109000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.536",
	"createdAt": "2024-01-12 07:21:04.474000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.177",
	"createdAt": "2024-01-12 11:35:31.604000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.702",
	"createdAt": "2024-01-15 10:32:15.190000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.041",
	"createdAt": "2024-01-16 13:07:01.520000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.292",
	"createdAt": "2024-01-17 11:54:49.998000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.093",
	"createdAt": "2024-01-22 09:13:41.100000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.206",
	"createdAt": "2024-01-22 10:23:15.724000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.704",
	"createdAt": "2024-01-30 08:14:44.334000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.103",
	"createdAt": "2024-02-02 09:20:02.125000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.067",
	"createdAt": "2024-02-02 09:28:38.654000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.217",
	"createdAt": "2024-02-02 10:36:22.467000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.544",
	"createdAt": "2024-02-02 11:08:10.434000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.875",
	"createdAt": "2024-02-05 11:33:37.691000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.919",
	"createdAt": "2024-02-06 13:28:53.750000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.002",
	"createdAt": "2024-02-07 11:00:06.669000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.381",
	"createdAt": "2024-02-07 12:34:40.163000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.352",
	"createdAt": "2024-02-09 09:11:42.782000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.852",
	"createdAt": "2024-02-12 12:36:34.857000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-14 09:39:34.736000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "3.284",
	"createdAt": "2024-02-19 11:36:32.903000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.383",
	"createdAt": "2024-02-20 09:14:20.312000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.794",
	"createdAt": "2024-02-20 10:03:44.915000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.411",
	"createdAt": "2024-02-28 12:07:34.221000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-29 08:58:24.782000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.948",
	"createdAt": "2024-03-06 12:01:44.826000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.056",
	"createdAt": "2024-04-10 11:06:35.179000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "ohumeniuk@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.464",
	"createdAt": "2024-04-10 11:12:10.235000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.158",
	"createdAt": "2024-03-28 12:20:02.547000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.869",
	"createdAt": "2023-11-29 10:06:25.911000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.082",
	"createdAt": "2023-12-06 04:55:58.924000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.019",
	"createdAt": "2023-12-06 08:02:55.063000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.563",
	"createdAt": "2023-12-06 09:36:38.370000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.011",
	"createdAt": "2024-02-22 12:05:32.542000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.311",
	"createdAt": "2024-03-20 05:19:19.873000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.475",
	"createdAt": "2024-03-20 13:05:35.105000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.548",
	"createdAt": "2024-03-20 13:39:19.142000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.136",
	"createdAt": "2024-03-27 12:03:30.424000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.553",
	"createdAt": "2024-03-27 12:14:34.333000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.622",
	"createdAt": "2024-03-28 12:32:15.431000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.037",
	"createdAt": "2024-04-11 05:21:42.842000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.159",
	"createdAt": "2024-05-03 10:57:19.038000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "1.432",
	"createdAt": "2024-03-01 13:09:47.866000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "FCUS-001",
	"hardware": "SM-001",
	"sumHours": "0.998",
	"createdAt": "2024-04-12 07:00:30.443000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.262",
	"createdAt": "2024-04-11 05:48:32.572000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "1.75",
	"createdAt": "2024-04-11 06:06:15.328000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.077",
	"createdAt": "2024-04-11 07:53:44.813000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.306",
	"createdAt": "2024-04-11 05:27:28.965000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.152",
	"createdAt": "2024-04-12 05:55:35.260000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.071",
	"createdAt": "2024-04-12 06:07:28.815000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.252",
	"createdAt": "2024-04-12 06:13:53.603000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sgetun@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.395",
	"createdAt": "2024-04-12 12:16:26.545000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.007",
	"createdAt": "2024-02-25 14:53:08.220000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.073",
	"createdAt": "2023-12-25 11:12:11.176000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.343",
	"createdAt": "2024-05-20 13:55:15.792000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.636",
	"createdAt": "2024-05-21 11:12:57.287000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-21 12:53:01.156000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.793",
	"createdAt": "2024-05-30 07:06:32.331000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-03-25 14:56:28.802000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.335",
	"createdAt": "2024-03-25 17:00:18.568000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.822",
	"createdAt": "2024-03-25 19:59:35.104000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.105",
	"createdAt": "2024-03-26 08:38:43.872000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "sonyan@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.009",
	"createdAt": "2024-03-27 12:17:33.356000 UTC"
}, {
	"teamName": "Playtika - SM",
	"email": "tomerr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-13 13:17:37.834000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "amitge@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-11 08:31:44.806000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "amitge@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-26 04:11:58.590000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "amitge@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-31 08:39:24.415000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "amitge@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-01 07:21:10.255000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "amitge@playtika.com",
	"app": "FCUS-001",
	"hardware": "SM-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-25 19:21:41.061000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "amitge@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-14 08:48:49.380000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.249",
	"createdAt": "2024-03-25 10:09:45.821000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "galba@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.05",
	"createdAt": "2024-04-07 08:37:32.951000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "idanye@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.004",
	"createdAt": "2023-12-13 01:33:26.677000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2023-11-29 10:23:07.122000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.009",
	"createdAt": "2023-11-29 10:27:46.157000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.004",
	"createdAt": "2023-12-06 07:54:05.220000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.985",
	"createdAt": "2024-01-10 12:08:46.276000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.405",
	"createdAt": "2024-01-11 09:54:22.206000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.084",
	"createdAt": "2024-01-11 12:15:48.382000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "kiorhanska@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.012",
	"createdAt": "2024-01-11 12:22:12.701000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "vstetsiuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.0",
	"createdAt": "2023-12-06 08:18:58.348000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "vstetsiuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.011",
	"createdAt": "2023-12-06 09:06:43.943000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "vstetsiuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.087",
	"createdAt": "2023-12-26 12:52:46.871000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "vstetsiuk@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-001",
	"sumHours": "0.058",
	"createdAt": "2023-12-08 10:30:34.049000 UTC"
}, {
	"teamName": "Playtika - Sandbox",
	"email": "vstetsiuk@playtika.com",
	"app": "FCFZ-001",
	"hardware": "MD-001",
	"sumHours": "0.505",
	"createdAt": "2023-12-08 12:03:24.287000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akovalchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-16 15:56:52.628000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akovalchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.335",
	"createdAt": "2024-04-16 17:45:32.503000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akovalchuk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.24",
	"createdAt": "2024-04-16 21:36:51.117000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-17 16:01:31.916000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-17 16:06:14.628000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.175",
	"createdAt": "2024-04-19 08:28:47.807000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-04-19 14:17:34.689000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.138",
	"createdAt": "2024-04-19 14:42:30.492000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-04-22 15:16:11.168000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-22 15:23:56.468000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-22 15:33:14.645000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-04-23 08:13:08.282000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-23 12:31:13.349000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-29 13:05:25.636000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-05-14 13:47:27.718000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-05-15 11:38:16.416000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.014",
	"createdAt": "2024-05-17 09:10:16.182000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-17 09:15:11.586000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.002",
	"createdAt": "2024-05-30 09:19:52.907000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "akuzmina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.001",
	"createdAt": "2024-05-31 14:17:06.446000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "amitge@playtika.com",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "4.012",
	"createdAt": "2024-04-18 12:47:17.346000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "angelica.urbanczyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.017",
	"createdAt": "2024-04-16 15:29:43.802000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "angelica.urbanczyk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-16 15:56:09.275000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "biryna@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.755",
	"createdAt": "2024-04-19 08:43:19.191000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "biryna@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.216",
	"createdAt": "2024-05-17 15:26:06.847000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "biryna@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-20 08:04:19.086000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "biryna@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.994",
	"createdAt": "2024-04-16 15:56:27.773000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "biryna@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.194",
	"createdAt": "2024-04-19 08:29:27.170000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "dkernosenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.852",
	"createdAt": "2024-04-16 15:30:54.474000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "dkernosenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-16 17:27:09.250000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "jared.hann@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-16 15:30:59.154000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "javier.guzman@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.995",
	"createdAt": "2024-04-16 15:31:14.984000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "javier.guzman@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.231",
	"createdAt": "2024-04-18 20:06:59.870000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.114",
	"createdAt": "2024-04-14 13:11:41.131000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-14 14:21:04.056000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.337",
	"createdAt": "2024-05-28 13:17:02.774000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "oivanova@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.052",
	"createdAt": "2024-04-16 15:30:34.730000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "oivanova@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.961",
	"createdAt": "2024-04-16 15:47:21.811000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "rnezenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-27 13:24:29.286000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "rnezenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-01 12:16:39.967000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "rnezenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-02 08:14:08.061000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "rnezenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-02 11:55:30.864000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "rnezenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-03 09:17:30.019000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "rnezenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-13 07:04:19.947000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "rnezenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-14 15:04:35.374000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "rnezenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-19 08:43:51.071000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "rnezenko@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-26 08:28:56.067000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.112",
	"createdAt": "2024-04-11 11:06:17.171000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-11 12:00:25.818000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.856",
	"createdAt": "2024-04-16 15:02:18.926000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.246",
	"createdAt": "2024-05-02 15:08:27.092000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "usanchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.398",
	"createdAt": "2024-04-16 15:30:36.648000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "usanchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.571",
	"createdAt": "2024-04-18 08:54:19.176000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "usanchenko@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.793",
	"createdAt": "2024-04-19 08:28:46.035000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "vbortnik@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.502",
	"createdAt": "2024-04-16 15:30:26.326000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "vbortnik@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.235",
	"createdAt": "2024-04-16 17:03:37.892000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "vbortnik@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.072",
	"createdAt": "2024-04-17 14:46:56.220000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "vbortnik@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.92",
	"createdAt": "2024-04-19 08:28:44.414000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "vbortnik@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.435",
	"createdAt": "2024-05-02 15:13:57.878000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "wei.xu@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.557",
	"createdAt": "2024-04-21 07:48:31.206000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "wei.xu@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.014",
	"createdAt": "2024-04-21 09:28:43.185000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "wei.xu@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.474",
	"createdAt": "2024-05-01 05:15:40.592000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "wei.xu@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.187",
	"createdAt": "2024-05-01 06:46:55.868000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.315",
	"createdAt": "2024-04-19 09:32:35.898000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.131",
	"createdAt": "2024-04-19 11:15:35.643000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.531",
	"createdAt": "2024-04-20 09:01:56.030000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.983",
	"createdAt": "2024-04-21 17:38:41.469000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.631",
	"createdAt": "2024-04-24 09:32:28.738000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "7.686",
	"createdAt": "2024-04-26 07:56:11.338000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.805",
	"createdAt": "2024-04-27 14:48:05.589000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.75",
	"createdAt": "2024-04-28 16:03:45.583000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.807",
	"createdAt": "2024-04-30 10:27:32.730000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.684",
	"createdAt": "2024-05-03 06:59:08.150000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.856",
	"createdAt": "2024-05-18 09:32:14.868000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.234",
	"createdAt": "2024-05-20 07:48:36.037000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-17 15:42:00.253000 UTC"
}, {
	"teamName": "Playtika - Slots Central",
	"email": "ychekina@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.037",
	"createdAt": "2024-04-19 08:27:20.691000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "annap@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.001",
	"createdAt": "2024-04-25 09:53:07.834000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "annap@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-02 11:12:53.746000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "anyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.975",
	"createdAt": "2024-02-19 14:13:22.352000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "anyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.21",
	"createdAt": "2024-02-20 09:57:01.852000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "anyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-21 10:34:38.834000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "anyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-02-29 13:20:26.399000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "anyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.912",
	"createdAt": "2024-03-04 13:19:01.971000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "anyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.304",
	"createdAt": "2024-03-05 13:08:37.308000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "anyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.426",
	"createdAt": "2024-03-05 20:18:28.870000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "anyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.01",
	"createdAt": "2024-04-03 11:05:41.405000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "anyak@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-21 08:28:32.418000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "arkadiar@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.662",
	"createdAt": "2024-01-28 08:52:45.271000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "arkadiar@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.013",
	"createdAt": "2024-02-01 10:24:52.277000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.017",
	"createdAt": "2024-01-03 10:01:56.857000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-03 14:56:42.500000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-04 09:18:53.306000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.981",
	"createdAt": "2024-01-04 11:24:36.361000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.033",
	"createdAt": "2024-01-04 13:07:42.212000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.111",
	"createdAt": "2024-01-04 13:12:51.022000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.095",
	"createdAt": "2024-01-04 13:46:48.673000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.083",
	"createdAt": "2024-01-04 14:06:07.663000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.726",
	"createdAt": "2024-01-07 11:02:42.348000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.665",
	"createdAt": "2024-01-08 07:12:21.531000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.986",
	"createdAt": "2024-01-09 08:58:40.096000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.24",
	"createdAt": "2024-01-09 10:10:30.237000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.411",
	"createdAt": "2024-01-09 17:23:32.178000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.059",
	"createdAt": "2024-01-10 08:32:46.448000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.198",
	"createdAt": "2024-01-10 08:38:49.942000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-10 09:57:03.991000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.059",
	"createdAt": "2024-01-10 12:20:36.204000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.465",
	"createdAt": "2024-01-10 12:27:23.779000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.549",
	"createdAt": "2024-01-10 14:00:16.086000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.147",
	"createdAt": "2024-01-10 14:36:13.440000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.856",
	"createdAt": "2024-01-03 18:33:22.959000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.97",
	"createdAt": "2024-01-03 19:45:12.980000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.546",
	"createdAt": "2024-01-04 13:21:40.256000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.09",
	"createdAt": "2024-01-04 13:26:29.124000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.377",
	"createdAt": "2024-01-04 15:15:35.701000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.016",
	"createdAt": "2024-01-07 09:03:11.665000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.662",
	"createdAt": "2024-01-07 09:08:49.508000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.316",
	"createdAt": "2024-01-02 10:52:11.571000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "4.001",
	"createdAt": "2024-01-02 13:13:29.819000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.001",
	"createdAt": "2024-01-03 07:49:18.572000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.945",
	"createdAt": "2024-01-10 14:47:11.263000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.916",
	"createdAt": "2024-01-11 09:30:46.310000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.09",
	"createdAt": "2024-01-11 11:00:18.261000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-01-11 11:16:22.325000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.473",
	"createdAt": "2024-01-11 13:28:11.246000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.209",
	"createdAt": "2024-01-14 08:19:16.888000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-14 09:34:07.646000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.652",
	"createdAt": "2024-01-14 11:49:14.748000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.728",
	"createdAt": "2024-01-14 12:36:41.120000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.025",
	"createdAt": "2024-01-14 13:22:14.435000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.035",
	"createdAt": "2024-01-14 13:26:58.928000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.368",
	"createdAt": "2024-01-14 13:31:06.634000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.029",
	"createdAt": "2024-01-14 13:55:01.065000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.014",
	"createdAt": "2024-01-14 13:58:24.216000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.016",
	"createdAt": "2024-01-14 14:01:34.322000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "5.004",
	"createdAt": "2024-01-15 11:02:08.187000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.993",
	"createdAt": "2024-01-16 08:57:05.160000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-01-16 13:01:25.053000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-17 17:02:55.234000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.038",
	"createdAt": "2024-01-18 09:33:48.286000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.347",
	"createdAt": "2024-01-18 09:39:15.827000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-18 12:13:17.931000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.133",
	"createdAt": "2024-01-18 17:49:16.662000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.008",
	"createdAt": "2024-01-23 07:12:03.889000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-01-23 11:22:50.950000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-01-23 17:06:01.801000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.969",
	"createdAt": "2024-01-24 09:01:41.382000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-01-24 13:49:07.779000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-25 12:29:59.189000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.003",
	"createdAt": "2024-01-28 07:58:23.797000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.848",
	"createdAt": "2024-01-28 15:29:39.982000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.297",
	"createdAt": "2024-01-28 17:39:41.430000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-28 18:05:29.077000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-28 20:10:22.057000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-01-29 08:28:49.395000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.095",
	"createdAt": "2024-01-29 11:06:09.663000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-29 11:55:40.260000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.617",
	"createdAt": "2024-01-30 12:36:05.348000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.001",
	"createdAt": "2024-01-31 07:00:14.349000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-31 11:32:59.620000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.005",
	"createdAt": "2024-01-31 13:56:29.072000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-01-31 18:34:46.932000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.011",
	"createdAt": "2024-02-01 11:04:34.099000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-02-04 09:44:33.511000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.009",
	"createdAt": "2024-02-04 12:47:08.889000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-02-05 10:34:21.517000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-08 06:53:42.364000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-02-08 08:58:18.409000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.906",
	"createdAt": "2024-02-11 12:30:35.299000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.004",
	"createdAt": "2024-02-14 09:03:45.292000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-18 08:54:01.052000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.007",
	"createdAt": "2024-02-18 12:42:53.754000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.006",
	"createdAt": "2024-02-19 10:13:10.130000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-20 11:34:38.468000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.363",
	"createdAt": "2024-02-25 12:45:47.796000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-02-25 13:09:38.882000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-02-25 13:13:05.846000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.116",
	"createdAt": "2024-03-04 10:00:49.570000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.142",
	"createdAt": "2024-03-04 10:09:54.585000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.524",
	"createdAt": "2024-03-04 10:22:01.476000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.905",
	"createdAt": "2024-03-17 17:58:57.553000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.065",
	"createdAt": "2024-03-19 09:03:47.143000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.095",
	"createdAt": "2024-03-19 09:32:45.184000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.039",
	"createdAt": "2024-03-21 11:22:19.812000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.402",
	"createdAt": "2024-03-24 07:21:31.090000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.912",
	"createdAt": "2024-03-24 18:31:28.031000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.235",
	"createdAt": "2024-03-27 12:39:25.693000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-28 10:00:26.961000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.725",
	"createdAt": "2024-04-10 08:07:44.802000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-10 12:36:07.815000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-14 07:16:44.548000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.815",
	"createdAt": "2024-04-15 08:09:15.160000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.084",
	"createdAt": "2024-05-15 14:46:59.314000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "7.011",
	"createdAt": "2024-05-16 08:28:35.078000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.859",
	"createdAt": "2024-05-19 07:43:53.103000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.004",
	"createdAt": "2024-05-27 11:30:23.471000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.55",
	"createdAt": "2024-01-17 19:20:52.087000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-14 19:24:35.172000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-02-20 15:36:16.256000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-28 14:15:15.860000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "7.354",
	"createdAt": "2024-02-29 06:46:41.567000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.888",
	"createdAt": "2024-03-03 09:11:33.750000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-03 11:10:42.919000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.006",
	"createdAt": "2024-03-03 13:14:58.708000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.432",
	"createdAt": "2024-03-04 07:32:11.979000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.003",
	"createdAt": "2024-03-04 10:55:06.265000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.08",
	"createdAt": "2024-03-04 14:57:16.063000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.034",
	"createdAt": "2024-03-04 15:04:15.696000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.188",
	"createdAt": "2024-03-04 15:08:48.465000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-05 10:11:38.258000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "7.006",
	"createdAt": "2024-03-06 10:13:48.119000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-07 10:40:13.748000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "5.003",
	"createdAt": "2024-03-07 12:44:01.499000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.694",
	"createdAt": "2024-03-10 08:55:32.454000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.048",
	"createdAt": "2024-03-11 08:19:30.887000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.164",
	"createdAt": "2024-03-11 08:41:26.581000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.032",
	"createdAt": "2024-03-11 10:53:12.983000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.317",
	"createdAt": "2024-03-11 13:23:41.774000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-11 14:44:54.847000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.082",
	"createdAt": "2024-03-12 09:33:47.186000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-12 10:41:50.941000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-13 09:00:45.904000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-13 11:36:10.370000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-13 13:47:22.945000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.324",
	"createdAt": "2024-03-17 18:55:07.081000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-18 08:12:22.560000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-18 10:56:05.516000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.319",
	"createdAt": "2024-03-18 14:09:55.816000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-18 14:53:09.851000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.326",
	"createdAt": "2024-03-19 09:10:33.382000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.001",
	"createdAt": "2024-03-19 09:40:02.991000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.215",
	"createdAt": "2024-03-21 11:07:17.692000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.214",
	"createdAt": "2024-03-21 11:28:42.311000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-21 12:46:26.510000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "5.763",
	"createdAt": "2024-03-24 07:48:09.209000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.326",
	"createdAt": "2024-03-24 18:02:53.443000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.071",
	"createdAt": "2024-03-24 18:24:58.917000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.869",
	"createdAt": "2024-03-24 18:33:12.942000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "6.008",
	"createdAt": "2024-03-26 09:22:34.606000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.679",
	"createdAt": "2024-03-27 09:56:02.622000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.499",
	"createdAt": "2024-03-27 14:24:36.176000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-27 17:07:07.772000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.002",
	"createdAt": "2024-03-28 09:59:13.838000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.987",
	"createdAt": "2024-03-31 07:36:52.185000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.165",
	"createdAt": "2024-03-31 10:39:02.730000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.851",
	"createdAt": "2024-03-31 10:52:10.110000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-31 12:52:21.647000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.362",
	"createdAt": "2024-04-01 08:01:53.549000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-01 08:27:08.565000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.01",
	"createdAt": "2024-04-01 11:40:31.182000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-03 08:49:14.632000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-03 13:09:51.684000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-04 07:11:17.480000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-04 10:37:33.890000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-04 12:54:53.175000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.802",
	"createdAt": "2024-04-07 13:09:44.933000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-09 08:05:56.554000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.736",
	"createdAt": "2024-04-10 08:06:44.091000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-10 10:17:11.286000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.383",
	"createdAt": "2024-04-10 12:34:47.192000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-11 07:36:03.266000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.699",
	"createdAt": "2024-04-11 10:21:06.230000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.008",
	"createdAt": "2024-04-14 07:16:19.964000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "7.014",
	"createdAt": "2024-04-15 08:07:03.222000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.012",
	"createdAt": "2024-04-16 09:21:56.267000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-16 16:39:26.600000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.033",
	"createdAt": "2024-04-17 06:13:19.601000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.0",
	"createdAt": "2024-04-17 06:25:10.840000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.009",
	"createdAt": "2024-04-17 10:28:47.152000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "10.005",
	"createdAt": "2024-04-18 07:07:20.794000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "5.001",
	"createdAt": "2024-04-30 08:17:15.128000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.216",
	"createdAt": "2024-04-30 16:45:22.490000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-01 07:03:41.603000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "3.22",
	"createdAt": "2024-05-01 08:11:29.003000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "6.466",
	"createdAt": "2024-05-01 12:53:18.079000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.372",
	"createdAt": "2024-05-02 06:41:42.774000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-02 08:09:27.152000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.313",
	"createdAt": "2024-05-02 11:33:40.163000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.085",
	"createdAt": "2024-05-02 12:54:48.820000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.547",
	"createdAt": "2024-05-02 13:49:40.870000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-03 11:02:21.469000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.061",
	"createdAt": "2024-05-05 06:52:30.818000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.73",
	"createdAt": "2024-05-05 09:50:57.204000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.153",
	"createdAt": "2024-05-05 11:07:52.711000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "3.062",
	"createdAt": "2024-05-05 11:20:53.718000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "6.886",
	"createdAt": "2024-05-06 06:28:00.545000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "3.498",
	"createdAt": "2024-05-07 08:28:52.019000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-05-08 04:52:00.453000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-08 07:58:51.121000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-08 13:16:23.194000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-09 04:18:47.544000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "5.495",
	"createdAt": "2024-05-09 08:30:58.792000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.517",
	"createdAt": "2024-05-12 08:58:03.691000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "7.007",
	"createdAt": "2024-05-12 10:33:22.038000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.772",
	"createdAt": "2024-05-13 06:40:33.537000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.016",
	"createdAt": "2024-05-13 10:47:41.477000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-14 05:15:11.521000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-14 17:21:37.128000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-14 17:48:51.041000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "8.586",
	"createdAt": "2024-05-15 06:06:59.447000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "7.014",
	"createdAt": "2024-05-16 08:24:40.170000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.015",
	"createdAt": "2024-05-19 07:43:41.439000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.001",
	"createdAt": "2024-05-19 12:15:46.619000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.003",
	"createdAt": "2024-05-19 16:38:26.371000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "8.286",
	"createdAt": "2024-05-20 05:50:29.140000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.005",
	"createdAt": "2024-05-20 15:30:10.116000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-21 05:15:28.965000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-21 07:58:14.392000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-05-23 10:56:15.807000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.385",
	"createdAt": "2024-05-26 16:43:13.707000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.912",
	"createdAt": "2024-05-27 09:17:21.151000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "4.001",
	"createdAt": "2024-05-28 08:47:26.767000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "7.012",
	"createdAt": "2024-05-29 08:13:39.638000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.415",
	"createdAt": "2024-03-11 12:57:33.390000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.027",
	"createdAt": "2024-05-02 11:29:33.048000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "boazg@playtika.com",
	"app": "FRGE-001",
	"hardware": "SM-001",
	"sumHours": "0.01",
	"createdAt": "2024-05-05 10:38:21.552000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.017",
	"createdAt": "2024-01-02 14:16:42.596000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.271",
	"createdAt": "2024-02-28 10:28:19.125000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-02-29 09:42:41.248000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.101",
	"createdAt": "2024-02-29 09:48:38.794000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.856",
	"createdAt": "2024-02-29 10:06:41.331000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.237",
	"createdAt": "2024-03-03 07:24:22.332000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.047",
	"createdAt": "2024-03-03 07:40:34.300000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.175",
	"createdAt": "2024-03-03 07:45:05.877000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.0",
	"createdAt": "2024-03-03 07:58:22.773000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.049",
	"createdAt": "2024-03-04 09:04:32.864000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.033",
	"createdAt": "2024-03-04 10:09:13.480000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.035",
	"createdAt": "2024-03-04 10:18:11.354000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.032",
	"createdAt": "2024-03-04 10:21:39.223000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.285",
	"createdAt": "2024-03-04 10:25:23.780000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-05 08:22:47.132000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-05 10:51:29.899000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-05 13:01:40.832000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-27 08:13:05.411000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.014",
	"createdAt": "2024-03-27 10:26:30.998000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.121",
	"createdAt": "2024-03-28 09:57:59.918000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.177",
	"createdAt": "2024-03-28 11:08:27.188000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-28 11:25:02.195000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eadenw@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.652",
	"createdAt": "2024-01-02 13:35:18.018000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "efratk@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-01-02 13:45:18.506000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.792",
	"createdAt": "2024-01-03 12:41:38.514000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.225",
	"createdAt": "2024-01-14 13:18:36.876000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.043",
	"createdAt": "2024-01-14 13:04:38.498000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.015",
	"createdAt": "2024-01-30 15:00:50.811000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.176",
	"createdAt": "2023-12-26 10:46:38.468000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.97",
	"createdAt": "2023-12-26 11:00:15.140000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.147",
	"createdAt": "2023-12-26 12:29:34.238000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.844",
	"createdAt": "2023-12-26 12:55:16.151000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.184",
	"createdAt": "2023-12-26 13:55:46.484000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.75",
	"createdAt": "2023-12-27 11:09:04.059000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.013",
	"createdAt": "2023-12-28 13:08:58.751000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.03",
	"createdAt": "2023-12-28 13:30:12.189000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.017",
	"createdAt": "2023-12-28 14:47:16.749000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.119",
	"createdAt": "2024-01-01 08:44:47.509000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.032",
	"createdAt": "2024-01-01 12:25:56.264000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.092",
	"createdAt": "2024-01-01 13:09:17.396000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.037",
	"createdAt": "2024-01-01 13:44:43.798000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.016",
	"createdAt": "2024-01-01 13:49:06.880000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.162",
	"createdAt": "2024-01-01 15:37:56.172000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.075",
	"createdAt": "2024-01-02 09:09:39.568000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.933",
	"createdAt": "2024-01-02 13:31:35.069000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.0",
	"createdAt": "2024-01-07 15:43:35.218000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.541",
	"createdAt": "2024-01-08 12:19:42.125000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.04",
	"createdAt": "2024-01-11 12:47:26.794000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.01",
	"createdAt": "2024-01-16 10:39:43.931000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.066",
	"createdAt": "2024-01-24 15:19:35.334000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.563",
	"createdAt": "2024-01-30 15:13:43.886000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.25",
	"createdAt": "2024-02-19 14:10:56.493000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.249",
	"createdAt": "2024-02-19 14:37:34.729000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.321",
	"createdAt": "2024-03-03 08:49:15.296000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-18 10:27:22.509000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "eladco@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.735",
	"createdAt": "2024-05-06 13:12:10.410000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "idanye@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.039",
	"createdAt": "2023-12-13 01:50:13.467000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-02 13:40:57.359000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-02 17:01:26.765000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.015",
	"createdAt": "2024-01-08 13:00:00.332000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.015",
	"createdAt": "2024-01-08 14:21:02.737000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-08 14:21:45.367000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-09 08:49:18.205000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-17 12:37:39.657000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2024-01-17 14:50:00.465000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.174",
	"createdAt": "2024-01-18 11:08:32.578000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-18 11:28:37.544000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2024-01-18 16:00:53.366000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.009",
	"createdAt": "2024-01-25 14:29:40.111000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.003",
	"createdAt": "2024-01-31 13:09:05.330000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.001",
	"createdAt": "2024-02-08 15:54:00.508000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.01",
	"createdAt": "2024-02-11 08:50:40.270000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.003",
	"createdAt": "2024-02-18 08:52:56.416000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.011",
	"createdAt": "2024-02-18 09:55:50.920000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.013",
	"createdAt": "2024-02-18 11:32:24.955000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-21 10:48:58.053000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.007",
	"createdAt": "2024-02-26 10:43:37.264000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.971",
	"createdAt": "2024-02-26 14:14:45.476000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.0",
	"createdAt": "2024-02-26 15:14:55.538000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.013",
	"createdAt": "2024-02-27 18:32:42.941000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "3.015",
	"createdAt": "2024-02-28 10:27:03.315000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.009",
	"createdAt": "2024-02-29 11:04:33.682000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.003",
	"createdAt": "2024-03-06 14:24:17.541000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2024-03-12 13:55:03.677000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.009",
	"createdAt": "2024-03-13 14:44:31.216000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-28 10:28:51.686000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-28 12:38:34.528000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-28 13:05:21.540000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-03 08:55:19.421000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.007",
	"createdAt": "2024-04-03 14:44:04.611000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.005",
	"createdAt": "2024-04-09 08:21:03.980000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-09 10:17:00.702000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-09 18:07:12.618000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-10 10:18:47.323000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-10 12:43:43.811000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.013",
	"createdAt": "2024-04-11 10:57:13.590000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-25 16:47:35.992000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-07 16:38:49.715000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-09 11:54:09.799000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "ilanab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2024-05-19 10:39:06.983000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "itaymi@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-02 09:41:55.800000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "itaymi@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-02 13:35:28.069000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "johnnyf@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.25",
	"createdAt": "2024-03-03 08:57:21.725000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "johnnyf@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-03 09:15:58.270000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "linoys@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.008",
	"createdAt": "2024-02-28 12:10:16.611000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "linoys@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "9.015",
	"createdAt": "2024-02-29 08:44:02.106000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "linoys@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-03 08:40:06.411000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "linoys@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "8.014",
	"createdAt": "2024-03-03 08:40:39.741000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "linoys@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "8.011",
	"createdAt": "2024-03-04 08:27:45.521000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "linoys@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-01-29 12:00:17.061000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "linoys@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-02-28 10:29:21.078000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "linoys@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.522",
	"createdAt": "2024-02-28 10:30:06.293000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "linoys@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.0",
	"createdAt": "2024-01-03 13:49:35.995000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-22 10:00:44.235000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-05-22 13:42:11.388000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.765",
	"createdAt": "2024-01-02 09:14:55.640000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-01-02 13:35:19.764000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-01-25 13:12:29.204000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-01-28 13:40:18.963000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "6.013",
	"createdAt": "2024-01-29 11:10:59.127000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-01-31 13:00:43.118000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-31 15:26:51.035000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-01 10:49:52.543000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-02-04 09:44:52.751000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-04 12:33:25.325000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-02-07 14:41:08.049000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.005",
	"createdAt": "2024-02-11 07:20:11.976000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.008",
	"createdAt": "2024-02-11 13:38:00.526000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-13 09:29:08.593000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.006",
	"createdAt": "2024-02-13 11:42:38.451000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.008",
	"createdAt": "2024-02-19 10:43:05.523000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-20 08:44:54.708000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.011",
	"createdAt": "2024-02-20 21:02:33.554000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-02-21 10:07:55.953000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.003",
	"createdAt": "2024-02-22 12:41:15.517000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-04 10:36:12.254000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-03-04 13:02:25.976000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-18 13:23:59.306000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-19 13:14:47.153000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-20 11:45:33.329000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-31 11:22:04.471000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-01 08:03:30.935000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-01 10:32:15.528000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-03 10:15:52.813000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-04 10:02:29.421000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-07 09:25:09.580000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.014",
	"createdAt": "2024-04-25 10:21:41.398000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.006",
	"createdAt": "2024-04-30 19:24:14.441000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.006",
	"createdAt": "2024-05-01 12:29:18.098000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "mashaa@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.0",
	"createdAt": "2024-05-01 17:01:33.507000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "michaelsa@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.013",
	"createdAt": "2024-03-20 09:06:43.732000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "omriav@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.017",
	"createdAt": "2024-05-09 14:25:18.524000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "rachellets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-01-02 09:15:34.050000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "rachellets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.503",
	"createdAt": "2024-01-02 13:35:40.288000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "rachellets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.995",
	"createdAt": "2024-01-03 10:39:53.411000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "rachellets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-10 12:33:40.828000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "rachellets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-29 11:58:11.233000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "rachellets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-01-29 15:46:06.564000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "rachellets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-30 08:16:15.066000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "rachellets@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "6.016",
	"createdAt": "2024-01-30 10:39:03.631000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "rachellets@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "1.017",
	"createdAt": "2024-02-19 14:07:49.852000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.011",
	"createdAt": "2024-05-09 09:26:50.001000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.275",
	"createdAt": "2024-05-09 20:03:03.500000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.42",
	"createdAt": "2024-05-09 20:27:40.779000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-10 15:55:12.049000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-12 07:35:30.767000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.001",
	"createdAt": "2024-05-12 10:13:26.625000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-12 14:30:33.326000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.552",
	"createdAt": "2024-05-12 16:35:22.894000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.474",
	"createdAt": "2024-05-12 18:10:48.216000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.458",
	"createdAt": "2024-05-14 15:50:26.711000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-15 06:33:17.004000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "3.92",
	"createdAt": "2024-05-15 08:35:16.358000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-15 12:32:43.218000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.015",
	"createdAt": "2024-05-16 06:31:37.741000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.397",
	"createdAt": "2024-05-16 11:19:25.121000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-17 07:06:23.093000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.01",
	"createdAt": "2024-05-20 09:45:02.562000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.406",
	"createdAt": "2024-05-21 08:49:44.147000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-21 09:15:37.984000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.995",
	"createdAt": "2024-05-22 06:46:40.327000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.924",
	"createdAt": "2024-05-22 15:50:12.082000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sefig@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "4.008",
	"createdAt": "2024-05-29 09:15:07.730000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.201",
	"createdAt": "2024-02-18 10:52:01.400000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.033",
	"createdAt": "2024-02-29 13:01:29.380000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.023",
	"createdAt": "2024-02-29 13:06:59.989000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.234",
	"createdAt": "2024-03-03 08:42:52.796000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.112",
	"createdAt": "2024-03-03 08:59:03.584000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-03-03 09:53:24.667000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.321",
	"createdAt": "2024-03-03 13:18:20.020000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.603",
	"createdAt": "2024-03-04 11:41:28.000000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.701",
	"createdAt": "2024-03-04 13:20:31.500000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-06 09:18:04.504000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.122",
	"createdAt": "2024-03-07 15:53:55.948000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.49",
	"createdAt": "2024-03-07 16:06:32.334000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-03-07 16:40:21.385000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.008",
	"createdAt": "2024-03-08 06:45:43.214000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.968",
	"createdAt": "2024-03-08 06:49:01.332000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.862",
	"createdAt": "2024-03-14 09:49:02.453000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.184",
	"createdAt": "2024-03-18 08:44:28.053000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.596",
	"createdAt": "2024-03-18 09:59:30.607000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.163",
	"createdAt": "2024-03-20 12:06:23.279000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.004",
	"createdAt": "2024-03-20 12:25:49.228000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.007",
	"createdAt": "2024-03-20 13:45:53.395000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.214",
	"createdAt": "2024-03-21 08:47:54.256000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.561",
	"createdAt": "2024-03-21 09:44:17.641000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.835",
	"createdAt": "2024-03-21 10:19:21.822000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.192",
	"createdAt": "2024-03-26 09:48:11.597000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.532",
	"createdAt": "2024-03-28 12:20:04.617000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.001",
	"createdAt": "2024-03-31 07:49:42.408000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.836",
	"createdAt": "2024-04-01 08:49:15.555000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.032",
	"createdAt": "2024-04-02 09:06:45.062000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.105",
	"createdAt": "2024-04-02 10:45:17.353000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.147",
	"createdAt": "2024-04-03 13:28:26.139000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.056",
	"createdAt": "2024-04-04 09:35:53.734000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.031",
	"createdAt": "2024-04-14 10:47:48.192000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.011",
	"createdAt": "2024-04-21 11:07:38.709000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-04-24 08:54:30.521000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.167",
	"createdAt": "2024-04-24 13:10:49.273000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.835",
	"createdAt": "2024-05-06 08:15:47.804000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.007",
	"createdAt": "2024-05-08 08:25:13.559000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.15",
	"createdAt": "2024-05-08 15:59:15.627000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.014",
	"createdAt": "2024-05-08 18:28:40.423000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.996",
	"createdAt": "2024-05-09 07:10:01.162000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.573",
	"createdAt": "2024-05-09 08:34:13.411000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-09 14:44:59.700000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.517",
	"createdAt": "2024-05-09 18:51:50.319000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.656",
	"createdAt": "2024-05-11 15:39:31.648000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.009",
	"createdAt": "2024-05-12 06:57:59.464000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-12 11:09:29.013000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.016",
	"createdAt": "2024-05-12 13:22:40.693000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "7.003",
	"createdAt": "2024-05-15 06:52:59.731000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.004",
	"createdAt": "2024-05-16 06:56:03.438000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.353",
	"createdAt": "2024-05-16 11:17:37.381000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.001",
	"createdAt": "2024-05-16 18:59:24.197000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.009",
	"createdAt": "2024-05-19 09:30:19.836000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.235",
	"createdAt": "2024-05-19 14:03:34.836000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.307",
	"createdAt": "2024-05-20 13:07:29.623000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.33",
	"createdAt": "2024-05-22 14:43:06.816000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.008",
	"createdAt": "2024-05-29 06:51:16.293000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.098",
	"createdAt": "2024-02-18 10:42:29.556000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.244",
	"createdAt": "2024-02-29 12:41:58.985000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "sharone@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.729",
	"createdAt": "2024-03-03 09:07:38.860000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "5.017",
	"createdAt": "2023-12-28 11:54:58.321000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2023-12-28 12:13:08.829000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.005",
	"createdAt": "2023-12-29 19:57:38.163000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2023-12-30 08:11:40.962000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.009",
	"createdAt": "2023-12-30 10:18:32.120000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.004",
	"createdAt": "2023-12-30 14:18:19.200000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "5.016",
	"createdAt": "2023-12-31 10:23:27.727000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.004",
	"createdAt": "2024-01-01 08:34:52.598000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "4.003",
	"createdAt": "2024-01-01 12:40:06.782000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-02 09:51:09.851000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "4.01",
	"createdAt": "2024-01-02 11:58:55.828000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "3.011",
	"createdAt": "2024-01-03 09:29:57.100000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "3.006",
	"createdAt": "2024-01-03 13:43:07.965000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-07 11:33:13.155000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-13 10:04:50.569000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.013",
	"createdAt": "2024-01-13 14:17:11.271000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.011",
	"createdAt": "2024-01-17 12:04:41.716000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-20 17:51:09.732000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-21 10:07:24.448000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.566",
	"createdAt": "2024-01-23 12:44:14.674000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.013",
	"createdAt": "2024-01-23 14:21:14.058000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-24 12:16:39.759000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.009",
	"createdAt": "2024-01-31 12:21:19.559000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.433",
	"createdAt": "2024-02-14 12:21:37.816000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "talra@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.01",
	"createdAt": "2024-02-14 12:49:30.793000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "tanyai@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.356",
	"createdAt": "2024-01-02 14:04:54.534000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "tanyai@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.013",
	"createdAt": "2024-01-04 14:54:45.746000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "tanyai@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.103",
	"createdAt": "2024-02-04 10:03:38.431000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "tanyai@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.175",
	"createdAt": "2024-02-11 14:35:23.359000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.166",
	"createdAt": "2024-01-04 15:33:54.083000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.292",
	"createdAt": "2024-01-04 18:15:58.167000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.038",
	"createdAt": "2024-01-07 12:05:25.866000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.636",
	"createdAt": "2024-01-07 13:13:33.513000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.677",
	"createdAt": "2024-01-08 12:51:22.139000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.221",
	"createdAt": "2024-01-09 17:11:03.892000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.293",
	"createdAt": "2024-01-17 07:55:44.991000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.541",
	"createdAt": "2024-01-29 12:03:59.799000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.627",
	"createdAt": "2024-01-30 08:12:11.669000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.572",
	"createdAt": "2024-01-30 18:06:24.893000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.803",
	"createdAt": "2024-02-05 10:14:02.903000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.004",
	"createdAt": "2024-02-05 12:28:27.716000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.358",
	"createdAt": "2024-02-08 08:45:53.463000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.272",
	"createdAt": "2024-02-08 10:31:13.286000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-02-08 12:49:42.097000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.825",
	"createdAt": "2024-02-08 14:59:55.287000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.003",
	"createdAt": "2024-02-15 07:55:24.714000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.926",
	"createdAt": "2024-02-15 12:23:20.484000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.051",
	"createdAt": "2024-02-19 14:11:49.826000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.877",
	"createdAt": "2024-02-21 15:59:19.004000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.142",
	"createdAt": "2024-02-22 11:01:23.281000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.683",
	"createdAt": "2024-02-22 13:14:06.151000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-02-25 07:53:02.214000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.375",
	"createdAt": "2024-02-25 10:09:41.569000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.082",
	"createdAt": "2024-02-26 12:33:36.089000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.592",
	"createdAt": "2024-02-26 18:16:01.214000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "4.003",
	"createdAt": "2024-02-28 10:39:11.132000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.89",
	"createdAt": "2024-02-28 16:13:16.853000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.173",
	"createdAt": "2024-02-29 09:03:00.145000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.044",
	"createdAt": "2024-02-29 15:33:43.582000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.402",
	"createdAt": "2024-03-03 07:40:12.696000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.013",
	"createdAt": "2024-03-03 12:19:47.346000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-07 16:05:30.722000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.015",
	"createdAt": "2024-03-10 09:44:24.173000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.157",
	"createdAt": "2024-03-11 09:43:05.884000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.074",
	"createdAt": "2024-03-13 12:01:19.321000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.306",
	"createdAt": "2024-04-18 14:55:19.058000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.472",
	"createdAt": "2024-05-09 09:31:38.931000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.432",
	"createdAt": "2024-05-29 06:45:54.000000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-02 13:35:16.054000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.006",
	"createdAt": "2024-01-02 13:35:35.741000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.568",
	"createdAt": "2024-01-04 14:23:18.305000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.738",
	"createdAt": "2024-01-02 09:10:22.009000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.089",
	"createdAt": "2024-01-04 18:04:51.083000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.701",
	"createdAt": "2024-05-16 12:30:53.646000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.608",
	"createdAt": "2024-05-19 13:44:01.538000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.481",
	"createdAt": "2024-05-21 16:10:55.592000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.865",
	"createdAt": "2024-05-22 06:29:11.329000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "1.002",
	"createdAt": "2024-05-23 06:50:43.908000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "2.1",
	"createdAt": "2024-05-23 16:21:22.552000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.245",
	"createdAt": "2024-01-30 14:31:51.441000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.371",
	"createdAt": "2024-02-18 15:28:47.279000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "1.015",
	"createdAt": "2024-02-19 11:54:05.086000 UTC"
}, {
	"teamName": "Playtika - United",
	"email": "yevgenym@playtika.com",
	"app": "SUPR-001",
	"hardware": "LG-001",
	"sumHours": "0.371",
	"createdAt": "2024-05-09 14:41:32.913000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "alinabab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.205",
	"createdAt": "2024-01-14 13:20:13.986000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "alinabab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.869",
	"createdAt": "2024-01-15 08:35:57.028000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "alinabab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.002",
	"createdAt": "2024-01-02 14:42:31.013000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "alinabab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.466",
	"createdAt": "2024-01-03 14:42:57.531000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "danielgo@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.571",
	"createdAt": "2024-01-15 09:03:50.504000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "danielgo@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "3.007",
	"createdAt": "2024-03-03 13:07:52.211000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "danielgo@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.005",
	"createdAt": "2024-03-07 14:54:04.264000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "hilagr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.509",
	"createdAt": "2024-01-03 14:39:38.439000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "hilagr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.017",
	"createdAt": "2024-01-10 12:09:30.147000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "hilagr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.808",
	"createdAt": "2024-01-14 12:43:35.009000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "hilagr@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.877",
	"createdAt": "2024-01-15 08:35:58.543000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "idange@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-03 16:28:56.638000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "idoi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-01-03 14:42:11.948000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "idoi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-01-03 15:05:03.783000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "idoi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-04 12:14:28.130000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "idoi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-01-08 10:48:54.142000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "idoi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-10 13:44:43.335000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "idoi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-15 08:35:24.641000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "idoi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-01-24 13:37:00.216000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "idoi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-14 08:31:13.165000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "idoi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-14 10:43:37.728000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "idoi@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-02-14 12:28:18.749000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "itamarb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.003",
	"createdAt": "2024-01-03 14:12:44.330000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "itamarb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.015",
	"createdAt": "2024-01-24 13:36:26.482000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "itamarb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.014",
	"createdAt": "2024-01-29 16:10:14.446000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "itamarb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.012",
	"createdAt": "2024-02-15 12:11:45.636000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "itamarb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.002",
	"createdAt": "2024-02-19 14:16:27.352000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "itamarb@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-20 13:52:52.850000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.92",
	"createdAt": "2024-01-03 15:16:28.134000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-15 08:36:56.595000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.124",
	"createdAt": "2024-01-16 13:38:18.213000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-01-16 18:00:38.665000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "4.013",
	"createdAt": "2024-01-17 09:34:20.279000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.685",
	"createdAt": "2024-01-17 13:38:14.458000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.51",
	"createdAt": "2024-01-18 09:22:03.253000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.391",
	"createdAt": "2024-01-18 10:19:02.593000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-01-24 13:30:43.021000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-01-31 12:06:44.648000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.244",
	"createdAt": "2024-02-05 11:50:56.526000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.725",
	"createdAt": "2024-04-25 10:08:51.265000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.006",
	"createdAt": "2024-01-03 14:33:36.481000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.199",
	"createdAt": "2024-01-03 14:39:21.050000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "leahm@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.112",
	"createdAt": "2024-01-03 15:01:11.461000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.425",
	"createdAt": "2024-04-04 13:33:57.800000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.121",
	"createdAt": "2024-05-02 08:42:02.085000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.006",
	"createdAt": "2024-05-02 08:51:07.589000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.069",
	"createdAt": "2024-05-02 08:53:01.635000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.012",
	"createdAt": "2024-05-05 08:41:09.401000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.008",
	"createdAt": "2024-05-22 07:25:03.544000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "margalitd@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.011",
	"createdAt": "2024-05-07 10:16:24.019000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.014",
	"createdAt": "2024-03-12 08:46:19.755000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "natashamo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-01-03 15:23:11.585000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "natashamo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.185",
	"createdAt": "2024-01-10 12:09:43.653000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "natashamo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-14 12:42:37.261000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "natashamo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.376",
	"createdAt": "2024-01-14 13:09:35.608000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "natashamo@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.915",
	"createdAt": "2024-01-15 08:35:31.842000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "natashamo@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.017",
	"createdAt": "2024-01-02 14:52:05.085000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "natashamo@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.639",
	"createdAt": "2024-01-03 14:42:48.811000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "netage@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.48",
	"createdAt": "2024-01-03 14:43:17.802000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "netage@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-01-04 06:39:20.623000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "netage@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.049",
	"createdAt": "2024-01-04 06:43:55.994000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "netage@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.478",
	"createdAt": "2024-01-04 06:51:52.682000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "netage@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.752",
	"createdAt": "2024-01-10 12:06:51.498000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "netage@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.064",
	"createdAt": "2024-01-10 12:55:43.045000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "netage@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.824",
	"createdAt": "2024-01-10 13:59:50.167000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "netage@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.912",
	"createdAt": "2024-01-14 12:42:12.013000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "netage@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-01-15 08:49:14.767000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "netage@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.645",
	"createdAt": "2024-01-15 08:51:59.057000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "netage@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.232",
	"createdAt": "2024-01-17 15:16:26.741000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.626",
	"createdAt": "2024-01-15 12:20:42.699000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-01-15 13:55:20.120000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.337",
	"createdAt": "2024-01-16 12:07:17.541000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.564",
	"createdAt": "2024-01-16 14:59:10.784000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "3.053",
	"createdAt": "2024-01-17 11:35:21.076000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.021",
	"createdAt": "2024-01-17 14:40:48.937000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.63",
	"createdAt": "2024-01-17 14:46:23.876000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.451",
	"createdAt": "2024-01-18 08:40:00.685000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.837",
	"createdAt": "2024-01-18 09:14:42.744000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.923",
	"createdAt": "2024-01-18 11:42:01.727000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.81",
	"createdAt": "2024-01-18 12:44:35.488000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.122",
	"createdAt": "2024-01-22 12:53:14.535000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.977",
	"createdAt": "2024-01-22 14:13:22.741000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.691",
	"createdAt": "2024-01-23 12:43:20.959000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.97",
	"createdAt": "2024-01-24 08:54:58.531000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.995",
	"createdAt": "2024-01-24 12:32:52.289000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.534",
	"createdAt": "2024-01-25 12:25:38.122000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.014",
	"createdAt": "2024-01-28 09:06:09.928000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.77",
	"createdAt": "2024-01-28 13:51:09.089000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.35",
	"createdAt": "2024-01-28 14:51:43.878000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.017",
	"createdAt": "2024-01-29 15:12:50.861000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.538",
	"createdAt": "2024-01-31 10:08:44.636000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.95",
	"createdAt": "2024-01-31 12:10:32.567000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.944",
	"createdAt": "2024-02-01 09:29:35.604000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.093",
	"createdAt": "2024-02-02 08:48:00.895000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.273",
	"createdAt": "2024-02-02 12:09:56.979000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.292",
	"createdAt": "2024-02-03 17:07:14.010000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.194",
	"createdAt": "2024-02-03 17:27:37.506000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.417",
	"createdAt": "2024-02-03 21:01:47.367000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.536",
	"createdAt": "2024-02-05 09:02:53.335000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-02-05 09:44:48.583000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.755",
	"createdAt": "2024-02-05 12:10:39.242000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.724",
	"createdAt": "2024-02-05 15:45:32.974000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.027",
	"createdAt": "2024-02-11 09:05:46.716000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.992",
	"createdAt": "2024-02-11 13:39:26.322000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.928",
	"createdAt": "2024-02-13 11:57:34.857000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.575",
	"createdAt": "2024-02-14 09:22:48.338000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.312",
	"createdAt": "2024-02-14 12:09:43.496000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.549",
	"createdAt": "2024-02-14 12:31:22.849000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.608",
	"createdAt": "2024-02-15 07:34:59.241000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.07",
	"createdAt": "2024-02-17 10:12:39.298000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.215",
	"createdAt": "2024-02-20 13:43:58.249000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.601",
	"createdAt": "2024-02-22 14:24:14.663000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.128",
	"createdAt": "2024-02-25 20:28:30.660000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.954",
	"createdAt": "2024-02-26 14:50:06.176000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-02-27 08:27:12.788000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.78",
	"createdAt": "2024-03-01 11:43:19.172000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.032",
	"createdAt": "2024-03-03 10:00:34.026000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.378",
	"createdAt": "2024-03-03 12:04:56.540000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.449",
	"createdAt": "2024-03-03 14:46:51.199000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.575",
	"createdAt": "2024-03-04 15:51:29.575000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.721",
	"createdAt": "2024-03-05 12:15:14.260000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.133",
	"createdAt": "2024-03-05 20:12:01.010000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.248",
	"createdAt": "2024-03-05 20:23:20.088000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.006",
	"createdAt": "2024-03-06 09:52:43.161000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.015",
	"createdAt": "2024-03-06 15:04:53.381000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.677",
	"createdAt": "2024-03-06 17:37:06.901000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.84",
	"createdAt": "2024-03-07 07:28:58.108000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.654",
	"createdAt": "2024-03-07 10:17:31.358000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.056",
	"createdAt": "2024-03-13 11:59:59.491000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-03-18 19:30:06.900000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.415",
	"createdAt": "2024-03-20 12:40:51.598000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.235",
	"createdAt": "2024-03-20 13:08:10.261000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.084",
	"createdAt": "2024-03-20 15:21:43.228000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.26",
	"createdAt": "2024-03-21 07:47:41.852000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.703",
	"createdAt": "2024-03-21 12:04:12.897000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.545",
	"createdAt": "2024-03-21 15:17:32.465000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.476",
	"createdAt": "2024-03-24 08:25:09.527000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-03-25 13:12:05.577000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.619",
	"createdAt": "2024-03-25 14:34:05.270000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.171",
	"createdAt": "2024-03-26 07:21:55.863000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-03-26 14:11:43.014000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.337",
	"createdAt": "2024-03-27 11:52:23.532000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.799",
	"createdAt": "2024-03-27 12:14:43.647000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.339",
	"createdAt": "2024-03-31 09:45:56.474000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.977",
	"createdAt": "2024-03-31 10:09:11.212000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.227",
	"createdAt": "2024-03-31 20:18:03.743000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.836",
	"createdAt": "2024-04-03 10:45:35.097000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.295",
	"createdAt": "2024-04-07 19:09:32.299000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.028",
	"createdAt": "2024-04-09 12:36:27.375000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.371",
	"createdAt": "2024-04-09 12:42:30.503000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.499",
	"createdAt": "2024-04-10 07:29:14.459000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.223",
	"createdAt": "2024-04-14 19:09:34.129000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.26",
	"createdAt": "2024-04-14 19:29:06.404000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.747",
	"createdAt": "2024-04-17 11:06:50.007000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-04-17 19:51:13.995000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.016",
	"createdAt": "2024-04-20 20:02:10.852000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.386",
	"createdAt": "2024-04-21 11:57:27.770000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.631",
	"createdAt": "2024-04-22 05:52:35.562000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.638",
	"createdAt": "2024-05-05 09:22:26.131000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.749",
	"createdAt": "2024-05-05 10:34:50.410000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-06 14:10:07.659000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-05-16 12:20:25.898000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.439",
	"createdAt": "2024-05-19 08:39:00.926000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.702",
	"createdAt": "2024-05-24 08:38:38.536000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.025",
	"createdAt": "2024-05-27 07:53:46.960000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-05-27 19:39:39.814000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.327",
	"createdAt": "2024-05-28 07:44:42.347000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-29 12:16:07.222000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.023",
	"createdAt": "2024-05-29 14:18:42.544000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-05-30 12:35:25.288000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.085",
	"createdAt": "2024-06-01 19:58:35.509000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.952",
	"createdAt": "2024-06-02 08:00:41.299000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.651",
	"createdAt": "2024-01-03 14:33:30.073000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.976",
	"createdAt": "2024-01-04 07:37:36.261000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.011",
	"createdAt": "2024-01-07 12:03:54.828000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.373",
	"createdAt": "2024-01-07 12:10:30.401000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "3.001",
	"createdAt": "2024-01-08 09:08:14.214000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.601",
	"createdAt": "2024-01-10 13:42:54.369000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.883",
	"createdAt": "2024-01-10 15:33:28.368000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.683",
	"createdAt": "2024-01-11 07:47:09.162000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.436",
	"createdAt": "2024-01-11 11:33:04.369000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.319",
	"createdAt": "2024-01-14 10:54:19.922000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.528",
	"createdAt": "2024-01-14 12:07:21.169000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.929",
	"createdAt": "2024-01-15 08:35:07.464000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.054",
	"createdAt": "2024-01-15 09:44:40.868000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.989",
	"createdAt": "2024-01-15 09:51:28.117000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.317",
	"createdAt": "2024-01-15 11:58:59.979000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "2.67",
	"createdAt": "2024-04-07 08:31:54.736000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.118",
	"createdAt": "2024-04-17 10:57:24.880000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.436",
	"createdAt": "2024-04-10 11:12:03.092000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.273",
	"createdAt": "2024-04-10 11:40:19.482000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.298",
	"createdAt": "2024-04-12 12:50:33.085000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "2.005",
	"createdAt": "2024-04-12 13:11:54.826000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.687",
	"createdAt": "2024-04-14 07:08:38.909000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.273",
	"createdAt": "2024-04-17 09:21:03.324000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-04-18 12:25:51.462000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.053",
	"createdAt": "2024-04-07 07:47:37.401000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.465",
	"createdAt": "2024-04-07 08:00:10.394000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "patricka@playtika.com",
	"app": "FRGE-001",
	"hardware": "MD-001",
	"sumHours": "0.065",
	"createdAt": "2024-04-10 11:06:00.071000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.326",
	"createdAt": "2024-03-12 09:21:26.553000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shaio@playtika.com",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.016",
	"createdAt": "2024-03-12 14:00:07.061000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.207",
	"createdAt": "2024-04-15 08:38:33.311000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.893",
	"createdAt": "2024-04-15 11:15:09.084000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.755",
	"createdAt": "2024-04-16 05:34:23.445000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.193",
	"createdAt": "2024-04-16 07:46:16.567000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.776",
	"createdAt": "2024-04-17 09:01:16.888000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.81",
	"createdAt": "2024-04-27 19:14:33.917000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.039",
	"createdAt": "2024-04-30 06:48:03.042000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.355",
	"createdAt": "2024-05-02 06:00:17.090000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.037",
	"createdAt": "2024-05-02 09:22:47.156000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-02 11:08:23.833000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.758",
	"createdAt": "2024-05-02 11:34:31.245000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.289",
	"createdAt": "2024-05-02 12:23:25.049000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.245",
	"createdAt": "2024-05-02 13:31:19.822000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.905",
	"createdAt": "2024-05-06 07:24:20.780000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.875",
	"createdAt": "2024-05-06 08:29:54.339000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.053",
	"createdAt": "2024-05-06 09:25:30.672000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.149",
	"createdAt": "2024-05-06 11:02:06.677000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.557",
	"createdAt": "2024-05-12 09:53:04.351000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-12 11:05:26.515000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.649",
	"createdAt": "2024-05-16 11:56:07.124000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.591",
	"createdAt": "2024-05-19 07:25:52.818000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.419",
	"createdAt": "2024-05-19 08:03:09.069000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.418",
	"createdAt": "2024-05-19 08:43:10.935000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.41",
	"createdAt": "2024-05-21 09:58:02.668000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.88",
	"createdAt": "2024-05-21 11:01:03.917000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-05-23 10:30:52.450000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.748",
	"createdAt": "2024-05-26 08:02:32.069000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.639",
	"createdAt": "2024-05-26 08:49:51.080000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-26 12:22:30.232000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.514",
	"createdAt": "2024-05-29 07:08:58.664000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.159",
	"createdAt": "2024-05-29 07:45:55.650000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.391",
	"createdAt": "2024-05-29 09:41:17.767000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.322",
	"createdAt": "2024-06-02 06:36:10.033000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.008",
	"createdAt": "2024-04-02 06:57:08.071000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.807",
	"createdAt": "2024-04-02 07:16:07.572000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.007",
	"createdAt": "2024-04-02 09:09:41.789000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.12",
	"createdAt": "2024-04-02 09:40:10.274000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-02 11:02:22.284000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.54",
	"createdAt": "2024-04-09 11:20:14.333000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "1.784",
	"createdAt": "2024-04-10 07:39:48.185000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.352",
	"createdAt": "2024-04-10 10:17:00.522000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.31",
	"createdAt": "2024-04-14 06:34:16.160000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.363",
	"createdAt": "2024-04-14 10:42:57.888000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.51",
	"createdAt": "2024-04-15 06:58:46.953000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shayliy@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.351",
	"createdAt": "2024-04-15 08:14:21.231000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.761",
	"createdAt": "2023-12-28 11:29:30.624000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2023-12-28 12:58:35.865000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2023-12-28 15:45:44.150000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.219",
	"createdAt": "2024-01-01 15:52:41.418000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.003",
	"createdAt": "2024-01-03 14:42:17.508000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-10 12:04:24.731000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.442",
	"createdAt": "2024-01-15 09:07:48.461000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-01-15 13:02:07.946000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.195",
	"createdAt": "2024-01-16 09:28:13.142000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.208",
	"createdAt": "2024-01-16 17:21:35.667000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.976",
	"createdAt": "2024-01-17 12:45:30.634000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.354",
	"createdAt": "2024-01-17 15:00:14.749000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-01-24 09:26:23.049000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.022",
	"createdAt": "2024-01-29 14:25:03.505000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.013",
	"createdAt": "2024-02-14 12:37:40.783000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "1.004",
	"createdAt": "2023-12-28 12:18:19.767000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.074",
	"createdAt": "2024-01-16 17:15:57.865000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.715",
	"createdAt": "2024-04-01 09:26:03.443000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.427",
	"createdAt": "2024-04-02 07:38:24.257000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-03 08:20:02.043000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.213",
	"createdAt": "2024-04-09 12:51:34.732000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.687",
	"createdAt": "2024-04-14 14:03:24.496000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.682",
	"createdAt": "2024-04-15 11:19:24.867000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.083",
	"createdAt": "2024-05-06 11:59:01.357000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.001",
	"createdAt": "2024-05-06 13:05:46.176000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-01-03 14:42:31.130000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-01-07 11:06:57.060000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.005",
	"createdAt": "2024-01-07 12:30:53.105000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.887",
	"createdAt": "2024-01-15 08:30:23.875000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.19",
	"createdAt": "2024-01-15 10:48:12.594000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.004",
	"createdAt": "2024-03-07 06:56:54.078000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.003",
	"createdAt": "2024-03-12 13:30:01.567000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.924",
	"createdAt": "2024-03-13 08:52:06.746000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.433",
	"createdAt": "2024-03-13 14:14:44.053000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.014",
	"createdAt": "2024-03-19 17:37:21.251000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.03",
	"createdAt": "2024-03-21 09:06:00.216000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.017",
	"createdAt": "2024-03-21 09:15:57.972000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-03-31 12:08:37.296000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "srulikt@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.3",
	"createdAt": "2024-04-01 08:04:48.434000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.754",
	"createdAt": "2024-01-14 12:46:13.014000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.837",
	"createdAt": "2024-01-15 08:36:11.900000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.12",
	"createdAt": "2024-01-15 11:29:57.117000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.179",
	"createdAt": "2024-02-13 11:39:22.937000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.153",
	"createdAt": "2024-02-14 08:29:26.962000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.227",
	"createdAt": "2024-02-14 09:10:22.192000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.59",
	"createdAt": "2024-02-14 09:36:58.909000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.013",
	"createdAt": "2024-02-14 10:14:37.248000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.513",
	"createdAt": "2024-02-14 11:56:24.462000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-02-14 14:32:16.709000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.706",
	"createdAt": "2024-02-21 21:40:09.244000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.0",
	"createdAt": "2024-02-22 13:29:11.723000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-28 11:09:12.899000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.902",
	"createdAt": "2024-03-13 12:05:15.076000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.157",
	"createdAt": "2024-03-19 12:29:39.477000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.101",
	"createdAt": "2024-03-19 12:40:56.970000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.238",
	"createdAt": "2024-03-19 13:08:02.180000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.098",
	"createdAt": "2024-03-19 16:25:13.252000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.076",
	"createdAt": "2024-03-20 12:57:49.213000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.209",
	"createdAt": "2024-03-20 13:04:53.273000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.452",
	"createdAt": "2024-05-06 09:15:55.275000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.006",
	"createdAt": "2024-01-02 14:43:20.086000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "tanyab@playtika.com",
	"app": "FCUS-001",
	"hardware": "MD-001",
	"sumHours": "0.125",
	"createdAt": "2024-02-14 09:27:17.185000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "yoava@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.041",
	"createdAt": "2024-01-02 14:33:26.735000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "yoava@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.618",
	"createdAt": "2024-01-03 14:45:34.174000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "yoava@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.631",
	"createdAt": "2024-01-14 12:46:23.282000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "yoava@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-02-11 11:11:22.911000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "yoava@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.085",
	"createdAt": "2024-02-19 14:08:59.298000 UTC"
}, {
	"teamName": "Playtika - WSOP",
	"email": "yoava@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.838",
	"createdAt": "2024-04-02 10:50:51.060000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "astrid.castle@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.973",
	"createdAt": "2024-04-17 08:24:31.447000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "astrid.castle@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.689",
	"createdAt": "2024-04-17 19:15:58.283000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "astrid.castle@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.01",
	"createdAt": "2024-04-18 10:35:48.149000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "astrid.castle@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.355",
	"createdAt": "2024-04-18 10:38:45.133000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "astrid.castle@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.753",
	"createdAt": "2024-04-18 11:02:11.327000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "astrid.castle@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.105",
	"createdAt": "2024-04-25 10:40:17.774000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "astrid.castle@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.659",
	"createdAt": "2024-04-25 10:50:27.652000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.001",
	"createdAt": "2024-03-13 12:09:25.565000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.005",
	"createdAt": "2024-03-13 14:57:27.129000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.284",
	"createdAt": "2024-03-20 13:07:57.014000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.896",
	"createdAt": "2024-03-20 13:27:19.866000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.835",
	"createdAt": "2024-04-07 20:22:04.345000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.822",
	"createdAt": "2024-04-17 11:07:45.480000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-04-24 10:59:36.896000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-05-29 11:01:31.219000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.004",
	"createdAt": "2024-04-04 10:58:51.621000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.993",
	"createdAt": "2024-04-04 17:46:48.289000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.932",
	"createdAt": "2024-04-07 16:45:30.353000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.897",
	"createdAt": "2024-04-11 10:39:39.930000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.003",
	"createdAt": "2024-04-12 20:42:39.026000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.949",
	"createdAt": "2024-04-14 14:54:49.046000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-18 10:32:21.917000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.479",
	"createdAt": "2024-04-18 18:43:32.802000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "bavo.vandeneynde@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.341",
	"createdAt": "2024-04-25 10:32:41.794000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "daniel.djanie@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.006",
	"createdAt": "2024-04-17 09:05:34.468000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "daria.brodskaia@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.693",
	"createdAt": "2024-03-13 14:59:07.832000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "daria.brodskaia@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.676",
	"createdAt": "2024-02-28 09:30:05.419000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "daria.brodskaia@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.005",
	"createdAt": "2024-03-13 14:56:00.170000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "daria.brodskaia@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.003",
	"createdAt": "2024-04-04 11:03:04.518000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "daria.brodskaia@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.81",
	"createdAt": "2024-04-11 10:40:38.593000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "daria.brodskaia@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.685",
	"createdAt": "2024-04-18 10:43:33.946000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "daria.brodskaia@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.872",
	"createdAt": "2024-04-25 10:34:59.905000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.013",
	"createdAt": "2024-03-13 14:57:45.431000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.001",
	"createdAt": "2024-02-28 09:43:54.356000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-12 15:23:54.004000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-03-12 17:29:22.074000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.012",
	"createdAt": "2024-03-13 14:51:31.585000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-03-13 14:56:32.025000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.003",
	"createdAt": "2024-04-11 11:00:10.468000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.004",
	"createdAt": "2024-04-11 16:32:50.252000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.007",
	"createdAt": "2024-04-12 10:17:39.536000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.014",
	"createdAt": "2024-04-18 10:36:31.066000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-19 15:53:52.629000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.009",
	"createdAt": "2024-05-13 17:22:28.653000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.0",
	"createdAt": "2024-05-14 11:22:24.923000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.01",
	"createdAt": "2024-05-15 08:40:28.192000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-05-15 12:29:43.799000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "3.002",
	"createdAt": "2024-05-22 08:35:48.828000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.717",
	"createdAt": "2024-05-22 12:42:52.310000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "emma.bravo@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "4.014",
	"createdAt": "2024-05-22 13:42:08.858000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "felix.king@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.009",
	"createdAt": "2024-02-28 09:30:05.014000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "flavia.scuderi@wooga.net",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.358",
	"createdAt": "2024-05-14 12:54:03.651000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "flavia.scuderi@wooga.net",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.05",
	"createdAt": "2024-05-14 12:46:01.229000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "flavia.scuderi@wooga.net",
	"app": "CMFY-001",
	"hardware": "XL-001",
	"sumHours": "0.715",
	"createdAt": "2024-05-14 11:28:43.396000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "flavia.scuderi@wooga.net",
	"app": "FCUS-001",
	"hardware": "SM-001",
	"sumHours": "0.129",
	"createdAt": "2024-05-14 12:15:45.218000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "helene.list@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.855",
	"createdAt": "2024-04-17 08:24:35.359000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "idanye@playtika.com",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.305",
	"createdAt": "2024-03-24 11:32:42.458000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.679",
	"createdAt": "2024-02-28 09:30:04.093000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.471",
	"createdAt": "2024-02-28 12:04:15.450000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.237",
	"createdAt": "2024-02-28 12:35:41.785000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.515",
	"createdAt": "2024-02-29 13:16:36.251000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.748",
	"createdAt": "2024-03-12 21:11:21.199000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.011",
	"createdAt": "2024-03-13 12:04:38.314000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.006",
	"createdAt": "2024-03-13 14:37:56.766000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.679",
	"createdAt": "2024-03-17 17:05:10.900000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.435",
	"createdAt": "2024-03-26 07:31:49.760000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.243",
	"createdAt": "2024-03-26 09:34:44.666000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.032",
	"createdAt": "2024-03-27 11:57:10.361000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.176",
	"createdAt": "2024-03-27 12:02:37.984000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.191",
	"createdAt": "2024-03-27 12:17:56.110000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.416",
	"createdAt": "2024-03-27 12:32:30.413000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.735",
	"createdAt": "2024-03-27 18:05:30.521000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.736",
	"createdAt": "2024-03-27 19:10:03.227000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "6.835",
	"createdAt": "2024-04-01 14:45:16.333000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.476",
	"createdAt": "2024-04-02 06:45:10.296000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.505",
	"createdAt": "2024-04-03 18:00:43.602000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.756",
	"createdAt": "2024-04-03 18:45:30.663000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.66",
	"createdAt": "2024-04-03 20:50:54.569000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.265",
	"createdAt": "2024-04-12 11:49:01.717000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.197",
	"createdAt": "2024-04-17 06:24:07.882000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.013",
	"createdAt": "2024-04-17 11:06:26.556000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.994",
	"createdAt": "2024-04-17 17:51:35.237000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.676",
	"createdAt": "2024-04-21 15:08:59.807000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.221",
	"createdAt": "2024-04-22 19:38:16.298000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.004",
	"createdAt": "2024-04-24 11:00:42.531000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.206",
	"createdAt": "2024-04-24 19:13:32.887000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.516",
	"createdAt": "2024-05-08 11:07:47.441000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.21",
	"createdAt": "2024-05-15 11:06:21.625000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.07",
	"createdAt": "2024-05-29 11:00:25.896000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.752",
	"createdAt": "2024-04-04 11:07:58.180000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.748",
	"createdAt": "2024-04-11 08:06:05.687000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.51",
	"createdAt": "2024-04-11 10:58:36.112000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.101",
	"createdAt": "2024-04-18 10:39:14.706000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.229",
	"createdAt": "2024-04-24 18:32:33.081000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.014",
	"createdAt": "2024-04-25 10:32:32.872000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.158",
	"createdAt": "2024-05-12 12:43:46.507000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.142",
	"createdAt": "2024-05-20 16:59:32.472000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.549",
	"createdAt": "2024-05-20 17:11:07.161000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.961",
	"createdAt": "2024-05-20 17:49:40.939000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.447",
	"createdAt": "2024-05-20 19:06:45.559000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.162",
	"createdAt": "2024-05-22 07:30:38.597000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jasmin.wirthgen@wooga.net",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "0.049",
	"createdAt": "2024-04-17 19:53:54.819000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.018",
	"createdAt": "2024-04-11 10:59:41.634000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.012",
	"createdAt": "2024-04-17 08:19:57.234000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.045",
	"createdAt": "2024-04-17 09:01:48.280000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.004",
	"createdAt": "2024-04-22 13:53:12.181000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.367",
	"createdAt": "2024-05-21 13:32:57.804000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.003",
	"createdAt": "2024-05-21 14:00:49.794000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.769",
	"createdAt": "2024-04-11 11:03:22.609000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.629",
	"createdAt": "2024-04-11 15:12:37.055000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.561",
	"createdAt": "2024-04-12 13:38:12.239000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.295",
	"createdAt": "2024-04-15 13:29:30.502000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.553",
	"createdAt": "2024-04-16 07:50:27.715000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.128",
	"createdAt": "2024-05-08 13:17:22.046000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.291",
	"createdAt": "2024-05-08 14:27:20.456000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.397",
	"createdAt": "2024-05-21 12:43:53.485000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.04",
	"createdAt": "2024-05-21 13:36:43.819000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.06",
	"createdAt": "2024-05-21 13:54:00.753000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "jessy.mais@wooga.net",
	"app": "MKYO-001",
	"hardware": "SM-001",
	"sumHours": "0.086",
	"createdAt": "2024-04-22 13:46:18.854000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "joerg.warda@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.013",
	"createdAt": "2024-04-15 09:41:14.847000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "joerg.warda@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.01",
	"createdAt": "2024-04-16 11:27:06.401000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "joerg.warda@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.694",
	"createdAt": "2024-04-25 10:41:30.893000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "joerg.warda@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.532",
	"createdAt": "2024-04-25 11:25:38.298000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "joerg.warda@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.632",
	"createdAt": "2024-04-25 11:59:56.879000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "konstantin.dimitrov@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.969",
	"createdAt": "2024-04-17 08:24:29.564000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lars.pfeffer@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.167",
	"createdAt": "2024-02-28 09:30:03.851000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lars.pfeffer@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.57",
	"createdAt": "2024-02-28 09:42:20.171000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lars.pfeffer@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.65",
	"createdAt": "2024-02-28 12:00:33.849000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lars.pfeffer@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.663",
	"createdAt": "2024-03-13 14:56:46.664000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lars.pfeffer@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.922",
	"createdAt": "2024-04-03 08:48:41.213000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lars.pfeffer@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.009",
	"createdAt": "2024-04-11 10:59:37.985000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.007",
	"createdAt": "2024-04-18 12:35:12.871000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.002",
	"createdAt": "2024-04-18 16:22:30.910000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.001",
	"createdAt": "2024-04-18 20:10:22.028000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.006",
	"createdAt": "2024-05-03 15:18:13.353000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.004",
	"createdAt": "2024-05-26 15:35:30.232000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.003",
	"createdAt": "2024-05-26 16:37:28.120000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "3.007",
	"createdAt": "2024-05-27 08:18:48.156000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-17 08:24:29.077000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.005",
	"createdAt": "2024-04-18 10:18:42.624000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.01",
	"createdAt": "2024-05-10 16:20:13.146000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.114",
	"createdAt": "2024-04-25 10:41:25.382000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.01",
	"createdAt": "2024-04-25 10:50:20.838000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.009",
	"createdAt": "2024-04-25 12:24:43.528000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.013",
	"createdAt": "2024-04-25 14:49:07.296000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "leonardo.schmidt@wooga.net",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.011",
	"createdAt": "2024-05-21 14:40:20.613000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-15 13:00:51.427000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.014",
	"createdAt": "2024-04-16 20:39:18.958000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.027",
	"createdAt": "2024-04-17 08:08:53.582000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "0.008",
	"createdAt": "2024-04-17 08:12:34.572000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "2.0",
	"createdAt": "2024-04-17 08:15:15.686000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "lizay@playtika.com",
	"app": "AUTO-001",
	"hardware": "XL-001",
	"sumHours": "1.766",
	"createdAt": "2024-04-17 09:45:59.263000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "marina.mosolova@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-17 08:24:29.661000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "marina.mosolova@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.66",
	"createdAt": "2024-04-18 08:17:32.244000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "marina.mosolova@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-04-18 10:39:43.786000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "michalh@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.002",
	"createdAt": "2024-04-02 08:21:27.470000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.515",
	"createdAt": "2024-05-05 15:03:35.636000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.046",
	"createdAt": "2024-05-05 17:19:36.570000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.489",
	"createdAt": "2024-05-05 17:41:03.770000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.1",
	"createdAt": "2024-05-05 18:13:15.305000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.745",
	"createdAt": "2024-04-11 08:05:38.441000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.67",
	"createdAt": "2024-04-11 10:54:05.085000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.004",
	"createdAt": "2024-04-18 10:37:34.744000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.364",
	"createdAt": "2024-04-18 14:30:10.019000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.143",
	"createdAt": "2024-04-18 15:47:31.512000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.136",
	"createdAt": "2024-05-02 14:25:29.813000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.389",
	"createdAt": "2024-05-02 14:35:54.076000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.197",
	"createdAt": "2024-05-14 11:53:23.725000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.012",
	"createdAt": "2024-05-14 12:07:32.029000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.olszewska@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.005",
	"createdAt": "2024-05-14 12:10:56.808000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.patkiewicz@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.71",
	"createdAt": "2024-04-17 08:24:32.456000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.patkiewicz@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.704",
	"createdAt": "2024-04-17 15:44:57.766000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.patkiewicz@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.49",
	"createdAt": "2024-04-19 10:05:10.227000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.patkiewicz@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.991",
	"createdAt": "2024-05-03 17:00:20.784000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "natalia.patkiewicz@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-05-03 18:20:37.064000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "prashant.pal@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.975",
	"createdAt": "2024-03-13 14:51:02.855000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "prashant.pal@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.049",
	"createdAt": "2024-04-03 09:18:49.055000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "prashant.pal@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.74",
	"createdAt": "2024-04-17 08:24:36.617000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "prashant.pal@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.311",
	"createdAt": "2024-05-15 09:08:52.563000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "ricarda.schlomach@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.99",
	"createdAt": "2024-04-17 08:24:58.969000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "ricarda.schlomach@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.64",
	"createdAt": "2024-04-17 11:02:53.782000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "ricarda.schlomach@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.015",
	"createdAt": "2024-04-18 10:34:35.625000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "ricarda.schlomach@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.924",
	"createdAt": "2024-04-25 10:33:01.751000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "robert.larsson@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-02-28 09:43:03.411000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "robert.larsson@wooga.net",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "0.017",
	"createdAt": "2024-02-28 12:51:08.778000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "robert.larsson@wooga.net",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.12",
	"createdAt": "2024-04-25 10:41:06.631000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "roy.grossman@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.0",
	"createdAt": "2024-02-29 08:38:41.466000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "seamus.flanagan@wooga.net",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.465",
	"createdAt": "2024-03-12 08:04:03.594000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "seamus.flanagan@wooga.net",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.448",
	"createdAt": "2024-03-12 09:00:28.724000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "seamus.flanagan@wooga.net",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.821",
	"createdAt": "2024-03-12 15:12:13.724000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "seamus.flanagan@wooga.net",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.695",
	"createdAt": "2024-03-13 12:10:37.925000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "seamus.flanagan@wooga.net",
	"app": "CMFY-001",
	"hardware": "SM-001",
	"sumHours": "0.634",
	"createdAt": "2024-04-25 10:42:45.972000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.006",
	"createdAt": "2024-04-14 13:13:59.607000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shaio@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.381",
	"createdAt": "2024-04-14 15:18:58.412000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-02-21 10:37:42.236000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-02-25 09:41:45.864000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.299",
	"createdAt": "2024-02-25 17:01:34.143000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.802",
	"createdAt": "2024-02-26 08:11:38.025000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-02-28 09:01:54.039000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.01",
	"createdAt": "2024-03-31 19:43:39.210000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.567",
	"createdAt": "2024-04-09 10:35:10.603000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.011",
	"createdAt": "2024-04-11 11:05:54.222000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shirc@playtika.com",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.338",
	"createdAt": "2024-05-21 15:01:21.634000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "1.007",
	"createdAt": "2024-04-08 13:07:25.767000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "shirc@playtika.com",
	"app": "FILE-001",
	"hardware": "CPU-001",
	"sumHours": "1.001",
	"createdAt": "2024-04-11 12:13:53.035000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "sita.duken@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.015",
	"createdAt": "2024-02-28 09:43:51.687000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "sita.duken@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "1.004",
	"createdAt": "2024-03-13 12:05:49.924000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "sita.duken@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.023",
	"createdAt": "2024-04-11 10:59:17.773000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "sita.duken@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "2.009",
	"createdAt": "2024-04-17 08:23:06.090000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "sita.duken@wooga.net",
	"app": "CMFY-001",
	"hardware": "MD-001",
	"sumHours": "1.007",
	"createdAt": "2024-04-11 11:03:38.430000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.016",
	"createdAt": "2024-02-28 09:42:39.867000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.225",
	"createdAt": "2024-02-28 12:24:36.564000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.134",
	"createdAt": "2024-03-05 13:18:12.067000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-06 12:06:41.561000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.733",
	"createdAt": "2024-03-06 14:45:04.134000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.168",
	"createdAt": "2024-03-07 09:49:31.508000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.115",
	"createdAt": "2024-03-07 10:01:52.731000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.003",
	"createdAt": "2024-03-07 13:31:37.998000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.208",
	"createdAt": "2024-03-12 14:56:53.136000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.093",
	"createdAt": "2024-03-12 15:44:59.300000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.007",
	"createdAt": "2024-03-13 12:04:05.036000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.808",
	"createdAt": "2024-03-13 14:57:26.595000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.704",
	"createdAt": "2024-03-17 09:00:13.801000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.421",
	"createdAt": "2024-03-20 15:47:40.051000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.301",
	"createdAt": "2024-03-21 14:06:38.364000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "2.008",
	"createdAt": "2024-03-21 14:33:49.766000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.425",
	"createdAt": "2024-03-21 15:00:09.269000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.008",
	"createdAt": "2024-03-31 09:32:16.908000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.009",
	"createdAt": "2024-04-02 12:53:30.084000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.074",
	"createdAt": "2024-04-05 17:12:44.039000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.179",
	"createdAt": "2024-04-17 08:20:09.264000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.005",
	"createdAt": "2024-04-24 13:41:57.765000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.348",
	"createdAt": "2024-02-26 16:09:58.362000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.291",
	"createdAt": "2024-02-27 10:36:09.003000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "AUTO-001",
	"hardware": "SM-001",
	"sumHours": "0.012",
	"createdAt": "2024-02-27 14:13:22.465000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.181",
	"createdAt": "2024-03-07 15:08:01.171000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.129",
	"createdAt": "2024-03-11 15:04:12.040000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.206",
	"createdAt": "2024-03-11 15:15:45.520000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.076",
	"createdAt": "2024-03-11 15:59:43.255000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.041",
	"createdAt": "2024-03-12 15:54:56.116000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.847",
	"createdAt": "2024-03-18 09:03:10.967000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.083",
	"createdAt": "2024-03-23 16:06:21.856000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.528",
	"createdAt": "2024-03-24 14:52:25.344000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.317",
	"createdAt": "2024-03-25 14:31:46.234000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.522",
	"createdAt": "2024-03-26 12:19:39.377000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.219",
	"createdAt": "2024-03-26 15:10:16.748000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.09",
	"createdAt": "2024-03-26 17:05:00.851000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.198",
	"createdAt": "2024-03-27 07:13:56.101000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.371",
	"createdAt": "2024-03-27 09:36:39.812000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.851",
	"createdAt": "2024-03-27 10:02:10.901000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.435",
	"createdAt": "2024-03-27 10:56:45.026000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.006",
	"createdAt": "2024-03-27 14:23:52.102000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.006",
	"createdAt": "2024-03-28 09:11:40.500000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.486",
	"createdAt": "2024-03-31 09:36:09.373000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.903",
	"createdAt": "2024-03-31 11:14:58.974000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.312",
	"createdAt": "2024-04-03 11:12:41.788000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.2",
	"createdAt": "2024-04-03 14:34:50.275000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.668",
	"createdAt": "2024-04-04 08:50:49.091000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.0",
	"createdAt": "2024-04-04 10:58:52.228000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.839",
	"createdAt": "2024-04-11 08:29:21.246000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.027",
	"createdAt": "2024-04-11 11:02:39.115000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.569",
	"createdAt": "2024-04-17 13:50:13.274000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.528",
	"createdAt": "2024-04-18 06:22:37.429000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "2.004",
	"createdAt": "2024-04-18 07:28:54.263000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.342",
	"createdAt": "2024-04-18 10:38:00.053000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.278",
	"createdAt": "2024-04-19 12:06:41.113000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.683",
	"createdAt": "2024-04-24 12:41:08.620000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.473",
	"createdAt": "2024-04-24 13:43:55.112000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.001",
	"createdAt": "2024-04-24 16:51:10.891000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "5.266",
	"createdAt": "2024-04-25 06:13:14.871000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.032",
	"createdAt": "2024-04-27 05:18:12.965000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.419",
	"createdAt": "2024-04-30 10:13:24.868000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.1",
	"createdAt": "2024-04-30 12:49:57.005000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.818",
	"createdAt": "2024-05-01 07:09:27.491000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.315",
	"createdAt": "2024-05-01 09:09:25.496000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.54",
	"createdAt": "2024-05-01 11:03:43.907000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.122",
	"createdAt": "2024-05-01 12:07:52.773000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.081",
	"createdAt": "2024-05-01 12:18:00.304000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.076",
	"createdAt": "2024-05-01 12:24:55.582000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.113",
	"createdAt": "2024-05-01 12:34:59.291000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "4.003",
	"createdAt": "2024-05-10 07:31:28.796000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.601",
	"createdAt": "2024-05-10 09:31:22.031000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.819",
	"createdAt": "2024-05-11 05:58:45.071000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "steffi.schuetze@wooga.net",
	"app": "FCUS-001",
	"hardware": "LG-001",
	"sumHours": "0.105",
	"createdAt": "2024-03-07 09:41:49.639000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "1.003",
	"createdAt": "2024-03-13 12:08:02.722000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.862",
	"createdAt": "2024-03-13 14:57:28.367000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.239",
	"createdAt": "2024-03-27 11:58:37.862000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.765",
	"createdAt": "2024-03-27 12:14:57.423000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "AUTO-001",
	"hardware": "LG-001",
	"sumHours": "0.528",
	"createdAt": "2024-03-27 13:05:00.753000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "AUTO-001",
	"hardware": "MD-001",
	"sumHours": "0.539",
	"createdAt": "2024-04-17 11:06:26.959000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.899",
	"createdAt": "2024-04-04 11:02:44.716000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.902",
	"createdAt": "2024-04-11 10:38:49.854000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.676",
	"createdAt": "2024-04-18 10:34:57.955000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "0.796",
	"createdAt": "2024-04-18 11:19:09.221000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "CMFY-001",
	"hardware": "LG-001",
	"sumHours": "1.157",
	"createdAt": "2024-04-25 10:40:32.513000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.085",
	"createdAt": "2024-04-10 11:05:29.576000 UTC"
}, {
	"teamName": "Playtika - Wooga",
	"email": "stephan.pohl@wooga.net",
	"app": "FRGE-001",
	"hardware": "LG-001",
	"sumHours": "0.957",
	"createdAt": "2024-04-10 11:12:35.426000 UTC"
}];
