import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRating } from 'vuetify/lib/components/VRating';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VSheet,{staticClass:"app-offer-outer-container",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"app-offer-inner-container",class:{
			'flex-row': _vm.$vuetify.breakpoint.smAndUp,
			'flex-column': _vm.$vuetify.breakpoint.xsOnly,
		}},[_c('div',{staticClass:"d-flex flex-column justify-space-between full-width pa-6",staticStyle:{"flex":"50","background":"var(--grey-850)"}},[_c('div',{staticClass:"d-flex flex-column pr-2"},[_c('div',{staticClass:"app-offer-card-title"},[_c('span',[_vm._v(_vm._s(_vm.appOffer.label))]),(_vm.appOffer.isBeta)?_c(VTooltip,{attrs:{"top":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"gradient-chip-secondary ml-2",staticStyle:{"line-height":"20px","height":"20px","font-size":"18px"}},'div',attrs,false),on),[_vm._v("beta")])]}}],null,false,4192899405)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(_vm._s(_vm.APP_OFFER_IS_BETA_MESSAGE))])]):_vm._e()],1),_c('div',{staticClass:"app-offer-card-description"},[(_vm.appOffer.descriptionHtml)?_c('div',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(_vm.appOffer.descriptionHtml)}}):_vm._e()])]),_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:({
					handler: _vm.intersectHandler,
					threshold: 1,
				}),expression:"{\n\t\t\t\t\thandler: intersectHandler,\n\t\t\t\t\tthreshold: 1,\n\t\t\t\t}"}],staticClass:"mt-4 px-12 d-flex align-center justify-center"},[_vm._t("actions")],2)]),_c('div',{staticClass:"pa-5 overflow-hidden app-offer-right-side"},[_c('div',{staticClass:"bg-gradient-blur-wrapper"},[_c('div',{staticClass:"bg-gradient-blur-circle-4"}),_c('div',{staticClass:"bg-gradient-blur-circle-3"}),_c('div',{staticClass:"bg-gradient-blur-circle-2"}),_c('div',{staticClass:"bg-gradient-blur-circle-1"})]),_c('div',{staticClass:"d-flex flex-column justify-start",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"d-flex full-width justify-space-between flex-wrap mb-3"},[(_vm.appOffer.popularity)?_c('div',{staticClass:"d-flex justify-start align-start",staticStyle:{"position":"relative","top":"4px"}},[_c('span',{staticClass:"ml-1 mr-3",staticStyle:{"font-size":"14px","color":"var(--grey-600)"}},[_vm._v(" Popularity ")]),_c('div',{staticStyle:{"position":"relative"}},[_c(VRating,{staticStyle:{"position":"relative","top":"-8px"},attrs:{"readonly":"","half-increments":"","color":"primary","background-color":"grey darken-2","length":"5","size":"20","value":_vm.appOffer.popularity}})],1)]):_vm._e(),(_vm._get(_vm.appOffer, 'resources.length'))?_c('div',{staticClass:"mb-3",staticStyle:{"position":"relative","top":"-4px"}},[_c('base-button',{attrs:{"secondary":""},on:{"click":_vm.openResources}},[_c(VIcon,{staticStyle:{"margin-left":"3px"},attrs:{"small":"","left":""}},[_vm._v("mdi-book-open-page-variant-outline")]),_vm._v(" Tutorials ")],1)],1):_vm._e()]),_c('app-offer-carousel',{staticClass:"mb-6",attrs:{"carousel-urls":_vm.appOffer.carouselUrls,"img-style":{ borderRadius: '8px' }}}),(_vm.appOffer.tags && _vm.appOffer.tags.length)?_c('div',{staticClass:"d-flex align-center flex-wrap"},_vm._l((_vm.appOffer.tags),function(tag){return _c(VChip,{key:tag,staticClass:"mr-1 mb-1",attrs:{"outlined":"","small":""}},[_vm._v(" "+_vm._s(tag)+" ")])}),1):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }