import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.selectedTeam)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"filters-row"},[_c('predefined-date-range-options',{attrs:{"today-date":_vm.dateOfLastLocalReportData},model:{value:(_vm.selectedPredefinedDateRangeOption),callback:function ($$v) {_vm.selectedPredefinedDateRangeOption=$$v},expression:"selectedPredefinedDateRangeOption"}}),_c('div',{staticClass:"report-date-container"},[_c('div',{staticClass:"report-date-label"},[_vm._v(" Report Last Ran ")]),_c('div',{staticClass:"report-date"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.dateOfLastLocalReportData,'MMM D, YYYY'))+" ")])])],1),(_vm.selectedPredefinedDateRangeOption)?_c('div',{staticClass:"team-metrics-outer-container",style:({
				margin: _vm.selectedCompany ? '15px 0 90px 0' : '15px 0 0 0',
			})},[_c('div',{staticClass:"team-metrics"},[_c('div',{staticClass:"team-metrics-sessions-section-title"},[_vm._v(" "+_vm._s(_vm.selectedCompany ? 'All ' : '')+"Team"+_vm._s(_vm.selectedCompany ? 's' : '')+" Session Stats ("+_vm._s(_vm.selectedTeam.name)+") ")]),(!_vm.selectedCompany)?_c('div',{staticClass:"team-metrics-sessions"},_vm._l((_vm.localAppOffers),function(appOffer){return _c('div',{key:appOffer.app},[((
								!!appOffer.publishedAt &&
								!!_vm.sumHoursByTeamAndAppResult[`${_vm.selectedTeam.name}-${appOffer.app}`]
							))?_c('div',{staticClass:"team-metrics-key-value"},[_c('div',{staticClass:"team-metrics-key"},[_c('div',[_vm._v(" "+_vm._s(appOffer.shortLabel)+" ")]),(_vm.sumHoursByTeamAndAppResult[`${_vm.selectedTeam.name}-${appOffer.app}`])?_c('div',{staticClass:"team-metrics-key-total"},[_vm._v(" "+_vm._s(_vm._f("twoDecimals")(_vm.sumHoursByTeamAndAppResult[`${_vm.selectedTeam.name}-${appOffer.app}`]))+" hrs ")]):_vm._e()]),_vm._l((_vm.hardwareOffers),function(hardwareOffer){return _c('div',{key:hardwareOffer.hardware},[(_vm.determineShowingHardware(hardwareOffer, appOffer))?_c('div',{staticClass:"team-metrics-value"},[_c('div',{staticClass:"team-metrics-value-label"},[_vm._v(" "+_vm._s(hardwareOffer.label)+" ")]),(_vm.sumHoursByTeamAppHardwareResult[`${_vm.selectedTeam.name}-${appOffer.app}-${hardwareOffer.hardware}`])?_c('span',[_vm._v(" "+_vm._s(_vm._f("twoDecimals")(_vm.sumHoursByTeamAppHardwareResult[`${_vm.selectedTeam.name}-${appOffer.app}-${hardwareOffer.hardware}`]))+" hrs ")]):_c('span',{staticStyle:{"font-style":"italic","font-size":"13px","color":"var(--grey-750)"}},[_vm._v(" 0 hrs ")])]):_vm._e()])})],2):_vm._e()])}),0):_c('div',{staticClass:"team-metrics-sessions"},_vm._l((_vm.localAppOffers),function(appOffer){return _c('div',{key:appOffer.app},[((
								!!appOffer.publishedAt &&
								!!_vm.sumHoursByAppResult[appOffer.app]
							))?_c('div',{staticClass:"team-metrics-key-value"},[_c('div',{staticClass:"team-metrics-key"},[_c('div',[_vm._v(" "+_vm._s(appOffer.shortLabel)+" ")]),(_vm.sumHoursByAppResult[appOffer.app])?_c('div',{staticClass:"team-metrics-key-total"},[_vm._v(" "+_vm._s(_vm._f("twoDecimals")(_vm.sumHoursByAppResult[appOffer.app]))+" hrs ")]):_vm._e()]),_vm._l((_vm.hardwareOffers),function(hardwareOffer){return _c('div',{key:hardwareOffer.hardware},[(_vm.determineShowingHardware(hardwareOffer, appOffer))?_c('div',{staticClass:"team-metrics-value"},[_c('div',{staticClass:"team-metrics-value-label"},[_vm._v(" "+_vm._s(hardwareOffer.label)+" ")]),(_vm.sumHoursByAppAndHardwareResult[`${appOffer.app}-${hardwareOffer.hardware}`])?_c('span',[_vm._v(" "+_vm._s(_vm._f("twoDecimals")(_vm.sumHoursByAppAndHardwareResult[`${appOffer.app}-${hardwareOffer.hardware}`]))+" hrs ")]):_c('span',{staticStyle:{"font-style":"italic","font-size":"13px","color":"var(--grey-750)"}},[_vm._v(" 0 hrs ")])]):_vm._e()])})],2):_vm._e()])}),0)])]):_vm._e()]),(!_vm.selectedCompany && _vm.selectedPredefinedDateRangeOption)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"users-container"},_vm._l((_vm.teamUsersMap[_vm.selectedTeam.name]),function(userMetrics,key){return _c('div',{key:key,staticClass:"user-card"},[_c('div',{staticClass:"user-title-container"},[_c('div',{staticClass:"email-icon"},[_c(VIcon,{staticClass:"gradient-text",staticStyle:{"font-size":"30px"}},[_vm._v("mdi-account-circle")])],1),_c('div',{staticClass:"user-email"},[_vm._v(" "+_vm._s(userMetrics.email)+" ")])]),_c('div',{staticClass:"team-metrics-sessions"},_vm._l((_vm.localAppOffers),function(appOffer){return _c('div',{key:appOffer.app},[((
								!!appOffer.publishedAt &&
								!!_vm.sumHoursByTeamUserAppResult[`${_vm.selectedTeam.name}-${userMetrics.email}-${appOffer.app}`]
							))?_c('div',{staticClass:"team-metrics-key-value"},[_c('div',{staticClass:"team-metrics-key"},[_c('div',[_vm._v(" "+_vm._s(appOffer.shortLabel)+" ")]),(_vm.sumHoursByTeamUserAppResult[`${_vm.selectedTeam.name}-${userMetrics.email}-${appOffer.app}`])?_c('div',{staticClass:"team-metrics-key-total"},[_vm._v(" "+_vm._s(_vm._f("twoDecimals")(_vm.sumHoursByTeamUserAppResult[`${_vm.selectedTeam.name}-${userMetrics.email}-${appOffer.app}`]))+" hrs ")]):_vm._e()]),_vm._l((_vm.hardwareOffers),function(hardwareOffer){return _c('div',{key:hardwareOffer.hardware},[(_vm.determineShowingHardware(hardwareOffer, appOffer))?_c('div',{staticClass:"team-metrics-value"},[_c('div',{staticClass:"team-metrics-value-label"},[_vm._v(" "+_vm._s(hardwareOffer.label)+" ")]),(_vm.sumHoursByTeamUserAppHardwareResult[`${_vm.selectedTeam.name}-${userMetrics.email}-${appOffer.app}-${hardwareOffer.hardware}`])?_c('span',[_vm._v(" "+_vm._s(_vm._f("twoDecimals")(_vm.sumHoursByTeamUserAppHardwareResult[`${_vm.selectedTeam.name}-${userMetrics.email}-${appOffer.app}-${hardwareOffer.hardware}`]))+" hrs ")]):_c('span',{staticStyle:{"font-style":"italic","font-size":"13px","color":"var(--grey-750)"}},[_vm._v(" 0 hrs ")])]):_vm._e()])})],2):_vm._e()])}),0)])}),0)]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }