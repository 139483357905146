
import Vue from 'vue';
import _keys from 'lodash/keys';
import ClipboardCopy from '@/components/ClipboardCopy.vue';

export default Vue.extend({
	name: 'AdminUserStatsCard',
	props: {
		user: { type: Object, required: true },
	},
	computed: {
		numTeams () {
			return _keys(this.user.teamIds || {}).length;
		},
		hasBottomRowSlot () {
			return !!this.$slots.bottomRow || !!this.$scopedSlots.bottomRow;
		},
	},
	components: {
		ClipboardCopy,
	},
});
