import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-12 pb-12"},[_c(VRow,_vm._l((_vm.items),function(item){return _c(VCol,{key:item.value,attrs:{"cols":"12"}},[(item.value === _vm.SELECTED_STORAGE_TYPE.TEAM && !_vm.team && !_vm._isEmpty(_vm.user.teamIds))?_c('team-picker',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('storage-type-card',{attrs:{"label":item.label,"icon":item.icon,"description-html":item.descriptionHtml},scopedSlots:_vm._u([{key:"bottomRightContainer",fn:function(){return [_c('span',{staticStyle:{"color":"var(--grey-700)","font-family":"'nasalization'"}},[_vm._v(" Click to select team ")])]},proxy:true}],null,true)})],1)]}}],null,true)}):(item.value === _vm.SELECTED_STORAGE_TYPE.TEAM && (!_vm.team || _vm.team.isOnboarding || !_vm.team.activatedAt))?_c('border-select-card',{attrs:{"value":false,"icon":item.icon},on:{"on-click":_vm.doUpsellToTeamPlan},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(item.label)+" ")]},proxy:true},{key:"subTitle",fn:function({ hover }){return [_c('border-card-upsell',{attrs:{"hover":hover,"upsell-type":_vm.UPSELL_TYPE.TEAM,"container-styles":{
							left: '-40px',
							margin: '30px 0 10px 0',
							...(_vm.$vuetify.breakpoint.xsOnly && {
								width: '119%',
							}),
							...(_vm.$vuetify.breakpoint.smAndUp && {
								width: '106%',
							}),
						}}})]}}],null,true)}):_c('storage-type-card',{attrs:{"label":item.label,"icon":item.icon,"description-html":item.descriptionHtml,"selected":!!(_vm.selectedStorageType && _vm.selectedStorageType === item.value)},on:{"on-select":function($event){return _vm.$emit('on-storage-type-select', item.value)}},scopedSlots:_vm._u([(item.value === _vm.SELECTED_STORAGE_TYPE.TEAM && !_vm._isEmpty(_vm.user.teamIds))?{key:"bottomRightContainer",fn:function(){return [_c('div',[_c('span',{class:{
								'gradient-text': _vm.selectedStorageType === _vm.SELECTED_STORAGE_TYPE.TEAM,
							},staticStyle:{"font-family":"var(--nasa)"},style:({
								...(_vm.selectedStorageType !== _vm.SELECTED_STORAGE_TYPE.TEAM && {
									color: 'var(--grey-700)',
								}),
							})},[_vm._v(" "+_vm._s(_vm._f("truncateMiddle")(_vm.team.name,25))+" ")]),_c('team-picker',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Change ")])]}}],null,true)})],1)]},proxy:true}:null],null,true)})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }