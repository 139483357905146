/**
 * For when you need to reference the names outside this file
 */
export const ROUTER: {
	APP_METRICS_REPORT: string
	IMAGE_GENERATIONS_REPORT: string
	IMAGE_AUDIT_REPORT: string
	USER_USAGE_REPORT: string
	TEAM_MEMBERS: string
	SPACES: string
	WORKSHOPS: string
	RUNNITS: string
	RUNNITS_APP: string
	RUNNITS_HOME: string
	RUNNITS_LIBRARY: string
	RUNNITS_PRIVATE: string
	RUNNITS_SHARED: string
	RUNNITS_ALL_TOOLS: string
	RUNNITS_BOARD: string
	RUNNITS_SUBSCRIPTIONS: string
} = {
	APP_METRICS_REPORT: 'AppMetricsReport',
	IMAGE_GENERATIONS_REPORT: 'ImageGenerationsReport',
	IMAGE_AUDIT_REPORT: 'ImageAuditReport',
	USER_USAGE_REPORT: 'UserUsageReport',
	TEAM_MEMBERS: 'TeamMembers',
	SPACES: 'Spaces',
	WORKSHOPS: 'Workshops',
	RUNNITS: 'Runnits',
	RUNNITS_APP: 'RunnitsApp',
	RUNNITS_HOME: 'RunnitsHome',
	RUNNITS_LIBRARY: 'RunnitsLibrary',
	RUNNITS_PRIVATE: 'RunnitsPrivate',
	RUNNITS_SHARED: 'RunnitsShared',
	RUNNITS_ALL_TOOLS: 'RunnitsAllTools',
	RUNNITS_BOARD: 'RunnitsBoard',
	RUNNITS_SUBSCRIPTIONS: 'RunnitsSubscriptions',
};
