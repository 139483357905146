
import Vue from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';
import DialogContent from '@/components/base/DialogContent.vue';
import { STATUS_SERVICE } from '@run-diffusion/shared';
import { mapActions, mapState } from 'vuex';
import ActionsIsland from '@/components/ActionsIsland.vue';
import { functions } from '@/firebase';
import { SNACKBAR_STATUS } from '@/constants/constants';

export default Vue.extend({
	name: 'StatusButton',
	props: {
		disabled: { type: Boolean, default: false },
	},
	data () {
		return {
			dialogOpen: false,
			statusLoading: false,

			statusData: null,
		};
	},
	computed: {
		...mapState([
			'toolbar',
		]),
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		onCloseDialog () {
			this.dialogOpen = false;
		},
		onOpenDialogClick () {
			this.dialogOpen = true;
		},
		async onStatusClick () {
			const onError: Function = (e, message: string) => {
				console.error('Error getting session status data: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: message || 'Error getting session status data',
					show: true,
				});
			};

			try {
				this.statusLoading = true;

				const functionRef = functions
					.httpsCallable('serverBuddy');
				const { success, message, data } = (await functionRef({
					sessionId: this.toolbar.session.id,
					endpoint: STATUS_SERVICE,
					params: {},
				})).data;
				if (!success) {
					onError(new Error('serverBuddy not successful'), message);
				} else {
					this.statusData = data || null;
				}
			} catch (e) {
				onError(e, null);
			} finally {
				this.statusLoading = false;
			}
		},
	},
	components: {
		ActionsIsland,
		BaseButton,
		DialogContent,
	},
});
