
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';

const DEFAULT_TIMEOUT = 5000;
const ERROR_TIMEOUT = 30000;
export default Vue.extend({
	computed: {
		...mapState([
			'snackbar',
		]),
		isError () {
			return this.snackbar.status === SNACKBAR_STATUS.ERROR;
		},
		isWarning () {
			return this.snackbar.status === SNACKBAR_STATUS.WARN;
		},
		timeoutTime () {
			return this.snackbar.timeout || (this.isError && ERROR_TIMEOUT) || DEFAULT_TIMEOUT;
		},
		contentClass () {
			let contentClass = SNACKBAR_STATUS.SUCCESS;
			const { status } = this.snackbar;
			switch (status) {
				case SNACKBAR_STATUS.SUCCESS:
				case SNACKBAR_STATUS.ERROR:
				case SNACKBAR_STATUS.INFO:
				case SNACKBAR_STATUS.WARN:
					contentClass = 'white--text';
					break;
				default:
					contentClass = 'success';
					break;
			}

			return contentClass;
		},
		color () {
			let color = SNACKBAR_STATUS.SUCCESS;
			const { status } = this.snackbar;
			switch (status) {
				case SNACKBAR_STATUS.SUCCESS:
					color = 'success';
					break;
				case SNACKBAR_STATUS.ERROR:
					color = 'error';
					break;
				case SNACKBAR_STATUS.INFO:
					color = 'primary';
					break;
				case SNACKBAR_STATUS.WARN:
					color = 'warning';
					break;
				default:
					color = 'success';
					break;
			}

			return color;
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		closeSnackbar () {
			const { status, message, snackbarStyle } = this.snackbar;
			this.updateSnackbar({
				status,
				message,
				show: false,
				timeout: null,
				snackbarStyle,
			});
		},
	},
});
