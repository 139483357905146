
import Vue from 'vue';

export default Vue.extend({
	name: 'RunnitModelSelection',
	props: {
		selectedCheckpoint: { type: Object, default: null },
	},
	data () {
		return {
			NUMBER_IMAGES: 8,
		};
	},
	methods: {
		onSelection (index: number) {
			this.$emit('input', index);
		},
		onWidthChange (width: number) {
			this.$emit('on-width-change', width);
		},
		onHeightChange (height: number) {
			this.$emit('on-height-change', height);
		},
	},
	components: {
	},
});
