import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"full-width"},[_c(VRow,[_c('div',{staticClass:"full-width mb-4 px-3"},[_c('info-asset-banner',{staticStyle:{"margin":"50px 20px"},attrs:{"info-flex":_vm.$vuetify.breakpoint.lgAndUp ? 9 : 9,"asset-flex":_vm.$vuetify.breakpoint.lgAndUp ? 4 : 2},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('div',{staticStyle:{"font-size":"20px"}},[_c('b',[_vm._v("Spaces")]),_vm._v(" are the ideal way to work, each with its own "),_c('span',{staticClass:"gradient-text"},[_vm._v("storage volume")]),_vm._v(". Within a Space, you can store separated: ")]),_c('div',{staticClass:"mt-6",class:{
							'ml-3': _vm.$vuetify.breakpoint.smAndUp,
						},staticStyle:{"font-size":"17px"}},[_c('list-item',{attrs:{"icon":"mdi-check-circle"}},[_vm._v("Generated Images")]),_c('list-item',{attrs:{"icon":"mdi-check-circle"}},[_vm._v("Models/Loras/Embeddings")])],1)]},proxy:true},{key:"asset",fn:function(){return [_c('div',{class:{
							'px-8': _vm.$vuetify.breakpoint.smAndDown,
							'pl-12 mr-12': _vm.$vuetify.breakpoint.mdAndUp,
						}},[_c('img',{attrs:{"alt":"Spaces","width":(
								_vm.$vuetify.breakpoint.smAndUp
									? _vm.$vuetify.breakpoint.xlOnly
										? '350px'
										: '250px'
									: '140px'
							),"src":require("../Workspaces/assets/designer-room.png")}})])]},proxy:true}])})],1)]),(_vm.team && _vm.user)?_c('workspaces',{staticClass:"mb-12"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }