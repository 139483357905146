
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import {
	SHOW_TEXT_FIELD_COUNTER_AT,
	SNACKBAR_STATUS,
	TEXT_FIELD_MAX_LENGTH,
	TEXT_FIELD_RULE,
} from '@/constants/constants';
import { RELEASE_STATUS } from '@/constants/enums';
import BackButton from '@/components/base/BackButton.vue';
import { db } from '@/firebase';
import { AppKey, AppOffer, SoftwareOffer } from '@run-diffusion/shared';
import _find from 'lodash/find';
import _trim from 'lodash/trim';
import _values from 'lodash/values';
import _keys from 'lodash/keys';
import StringArrayField from '@/components/StringArrayField.vue';
import HtmlField from '@/components/HtmlField.vue';
import ActionsIsland from '@/components/ActionsIsland.vue';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import SoftwareOfferCard from '@/views/Sessions/SoftwareOffersList/SoftwareOfferCard.vue';
import SoftwareOfferReleaseStatusChip from '@/components/SoftwareOfferReleaseStatusChip.vue';

export default Vue.extend({
	name: 'AdminEditSoftwareOffers',
	data () {
		return {
			SHOW_TEXT_FIELD_COUNTER_AT,
			TEXT_FIELD_MAX_LENGTH,
			TEXT_FIELD_RULE,
			RELEASE_STATUS,

			createNewSoftwareOfferKey: null,
			localSoftwareOffers: [],
			hasMovedSoftwareOfferItem: false,
			editSoftwareOffer: null,
			expandedSoftwareOffer: null,
			orderedAppKeys: [],

			isCreatingNew: false,
			duplicateSoftwareOffer: false,

			saving: false,
			formValid: false,
			rules: {
				requiredTextField: [
					TEXT_FIELD_RULE,
					v => !!_trim(v) || 'Required',
				],
				required: [
					v => !!_trim(v) || 'Required',
				],
				requiredArray: [
					v => !!v.length || 'Required',
				],
			},
		};
	},
	computed: {
		...mapState([
			'user',
			'softwareOffers',
			'appOffers',
		]),
	},
	watch: {
		appOffers: {
			immediate: true,
			handler (newVal: AppOffer[], oldVal: AppOffer[]) {
				if (newVal !== oldVal) {
					this.orderedAppKeys = (newVal || []).map(({ app }) => app).sort();
				}
			},
		},
		softwareOffers: {
			immediate: true,
			handler (newVal: SoftwareOffer[], oldVal: SoftwareOffer[]) {
				if (newVal !== oldVal) {
					this.localSoftwareOffers = (newVal || []).map(this.copyLocalSoftwareOffer);
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		_values,
		_trim,
		goBack () {
			if (this.hasRouteHistory) {
				this.$router.back();
			} else {
				const hasCurrentSessionId: boolean = !!(this._get(this.toolbar, 'session.id'));

				if (hasCurrentSessionId) {
					this.routerPush(this.$route, this.$router, { name: 'Launch', params: { sessionId: this._get(this.toolbar, 'session.id') } });
				} else {
					this.routerPush(this.$route, this.$router, { name: 'Sessions' });
				}
			}
		},
		onCreateNewClick (softwareOffer: SoftwareOffer) {
			this.isCreatingNew = true;
			this.duplicateSoftwareOffer = softwareOffer || null;
			this.createNewSoftwareOfferKey = softwareOffer ? softwareOffer.software : null;
		},
		onEditSoftwareOfferCancel () {
			if (this.editSoftwareOffer) {
				const valueSoftwareOffer: SoftwareOffer = _find(
					this.value || [],
					({ id }) => id === this.editSoftwareOffer.id,
				) || null;
				if (valueSoftwareOffer) {
					this.localSoftwareOffers = this.localSoftwareOffers.map((localSoftwareOffer: SoftwareOffer) => {
						if (localSoftwareOffer.id !== this.editSoftwareOffer.id) {
							return localSoftwareOffer;
						}
						return this.copyLocalSoftwareOffer(valueSoftwareOffer);
					});
				}

				this.editSoftwareOffer = null;
			}
		},
		copyLocalSoftwareOffer (softwareOffer: SoftwareOffer) {
			return {
				id: softwareOffer.id,
				publishedAt: softwareOffer.publishedAt ? new Date(softwareOffer.publishedAt.toMillis()) : null,
				isTeam: !!softwareOffer.isTeam,
				clubs: (softwareOffer.clubs || []).sort(),
				software: softwareOffer.software,
				bootVolume: softwareOffer.bootVolume,
				releaseStatus: softwareOffer.releaseStatus,
				label: softwareOffer.label,
				shortLabel: softwareOffer.shortLabel,
				sortOrder: softwareOffer.sortOrder,
				apps: (softwareOffer.apps || []).sort(),
				extensions: (softwareOffer.extensions || []).sort(),
				descriptionHtml: softwareOffer.descriptionHtml || null,
				descriptionHtmlAppOverride: {
					...softwareOffer.descriptionHtmlAppOverride,
				},
			};
		},
		moveSoftwareOfferItem (fromIndex: number, toIndex: number) {
			if (toIndex <= (this.localSoftwareOffers || []).length - 1 && toIndex >= 0) {
				const item: SoftwareOffer = this.localSoftwareOffers[fromIndex];
				let newArray: SoftwareOffer[] = this.localSoftwareOffers.filter((val: SoftwareOffer) => val !== item);
				newArray.splice(toIndex, 0, item);
				this.localSoftwareOffers = newArray;
				this.hasMovedSoftwareOfferItem = true;
			}
		},
		async onCreateNew (closeCallback: Function) {
			const newSoftwareKey: string = _trim(this.createNewSoftwareOfferKey).toUpperCase();
			if (this.saving || !newSoftwareKey) return;

			try {
				this.saving = true;
				const softwareOfferRef = db
					.doc(`softwareOffers/${newSoftwareKey}`);

				// Make sure key is free
				if ((await softwareOfferRef.get()).exists) {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: `The software key "${newSoftwareKey}" is already being used`,
						show: true,
					});
				} else {
					await softwareOfferRef.set({
						software: newSoftwareKey,
						publishedAt: null,
						sortOrder: this.localSoftwareOffers.length,
						...(this.duplicateSoftwareOffer ? {
							isTeam: !!this.duplicateSoftwareOffer.isTeam,
							clubs: this.duplicateSoftwareOffer.clubs || [],
							bootVolume: this.duplicateSoftwareOffer.bootVolume,
							releaseStatus: this.duplicateSoftwareOffer.releaseStatus,
							label: `${this.duplicateSoftwareOffer.label} (duplicated)`,
							shortLabel: this.duplicateSoftwareOffer.shortLabel,
							apps: this.duplicateSoftwareOffer.apps || [],
							extensions: this.duplicateSoftwareOffer.extensions || [],
							descriptionHtml: this.duplicateSoftwareOffer.descriptionHtml,
							descriptionHtmlAppOverride: this.duplicateSoftwareOffer.descriptionHtmlAppOverride || {},
						} : {
							label: '',
							shortLabel: '',
							descriptionHtml: '',
						}),
					});

					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! Software offer created.`,
						show: true,
					});
					this.createNewSoftwareOfferKey = null;
				}
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error creating software offer`,
					show: true,
				});
			} finally {
				if (closeCallback) closeCallback();
				this.saving = false;
			}
		},
		async onSaveSoftwareOffersSortOrder () {
			if (this.saving) return;
			try {
				this.saving = true;
				await Promise.all(this.localSoftwareOffers.map(async (softwareOffer: SoftwareOffer, index: number) => {
					const softwareOfferRef = db
						.doc(`softwareOffers/${softwareOffer.id}`);
					await softwareOfferRef.update({
						sortOrder: index,
					});
				}));
				this.hasMovedSoftwareOfferItem = false;
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Sort order saved.`,
					show: true,
				});
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error saving sort order',
					show: true,
				});
			} finally {
				this.saving = false;
			}
		},
		async onSave () {
			if (!this.editSoftwareOffer || this.saving) return;
			if (!this.$refs.form.validate()) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Form invalid, check fields',
					show: true,
				});
				return;
			}

			try {
				this.saving = true;
				const softwareOfferRef = db
					.doc(`softwareOffers/${this.editSoftwareOffer.id}`);
				await softwareOfferRef.update({
					publishedAt: this.editSoftwareOffer.publishedAt,
					isTeam: !!this.editSoftwareOffer.isTeam,
					clubs: this.editSoftwareOffer.clubs.map((v: string) => _trim(v).toUpperCase()),
					bootVolume: _trim(this.editSoftwareOffer.bootVolume),
					releaseStatus: _trim(this.editSoftwareOffer.releaseStatus),
					label: _trim(this.editSoftwareOffer.label),
					shortLabel: _trim(this.editSoftwareOffer.shortLabel),
					apps: this.editSoftwareOffer.apps.map((v: string) => _trim(v).toUpperCase()),
					extensions: this.editSoftwareOffer.extensions.map((v: string) => _trim(v).toUpperCase()),
					descriptionHtml: _trim(this.editSoftwareOffer.descriptionHtml),
					descriptionHtmlAppOverride: _keys(this.editSoftwareOffer.descriptionHtmlAppOverride).reduce((map: Record<AppKey, string>, app: AppKey) => {
						const descriptionHtml: string = _trim(this.editSoftwareOffer.descriptionHtmlAppOverride[app]);
						return {
							...map,
							...(descriptionHtml && {
								[app.toUpperCase()]: descriptionHtml,
							}),
						};
					}, {} as Record<AppKey, string>),
				});

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! ${this.editSoftwareOffer.label} saved.`,
					show: true,
				});
				this.editSoftwareOffer = null;
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error saving software offer ${this.editSoftwareOffer.label}`,
					show: true,
				});
			} finally {
				this.saving = false;
			}
		},
	},
	components: {
		SoftwareOfferReleaseStatusChip,
		SoftwareOfferCard,
		ConfirmDialog,
		ActionsIsland,
		HtmlField,
		StringArrayField,
		BackButton,
	},
});
