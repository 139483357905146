import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-background",class:{
		'glass-background': _vm.usingInsideDialog,
		'no-glass-background': !_vm.usingInsideDialog,
		'mobile-container': _vm.$vuetify.breakpoint.smAndDown,
		'non-mobile-container': _vm.$vuetify.breakpoint.mdAndUp,
	},style:({
		height: _vm.$vuetify.breakpoint.mdAndUp ? 'calc(100vh + 500px)' : 'calc(100vh + 1100px)',
	})},[_c(VRow,[_c(VCol,[_c('div',{staticStyle:{"width":"100%","position":"relative"}},[_c('base-button',{staticStyle:{"position":"absolute","right":"0","top":"0"},attrs:{"icon":"","secondary":""},on:{"click":_vm.onClose}},[_c(VIcon,{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1),(_vm._get(_vm.resourceListTypes.youtubeVideos, 'length'))?_c('div',{staticClass:"section-container",style:({
					marginTop: _vm.$vuetify.breakpoint.xsOnly ? '30px' : '0',
				})},[_c('div',{staticClass:"section-title"},[_vm._v(" Youtube Tutorials ")]),_c('div',{staticClass:"resources-container"},_vm._l((_vm.resourceListTypes.youtubeVideos),function(resource,index){return _c('resource-tile',{key:index,attrs:{"resource":resource}})}),1)]):_vm._e(),(_vm._get(_vm.resourceListTypes.links, 'length'))?_c('div',{staticClass:"section-container"},[_c('div',{staticClass:"section-title"},[_vm._v(" Link Tutorials ")]),_c('div',{staticClass:"resources-container"},_vm._l((_vm.resourceListTypes.links),function(resource,index){return _c('resource-tile',{key:index,attrs:{"resource":resource}})}),1)]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }