
import Vue from 'vue';
import _get from 'lodash/get';
import _keys from 'lodash/keys';
import moment from 'moment-timezone';
import { ToolbarState } from '@/store';
import { mapActions, mapState } from 'vuex';
import { APP_KEY, SESSION_STATE } from '@/constants/enums';
import { APP_BAR_HEIGHT } from '@/components/Toolbar/constants';
import { getComfyOnboardingDismissed } from '@/utils/localStorage';
import { AppKey, Session, SessionState } from '@run-diffusion/shared';
import {
	BOOTING_SESSION_STATES,
	READY_SESSION_STATES,
	STOPPING_SESSION_STATES,
} from '@/constants/constants';
import ComfyOnboarding from '@/views/Launch/ComfyOnboarding.vue';
import BootingSessionScreen from '@/views/Launch/BootingSessionScreen.vue';
import WorkstationUi from '@/views/Launch/WorkstationUi/WorkstationUi.vue';
import StoppingSessionDialog from '@/views/Launch/StoppingSessionDialog.vue';

export default Vue.extend({
	name: 'Launch',
	metaInfo () {
		return {
			title: this._get(this.toolbar, 'session.displayName') || 'Launched Session',
		};
	},
	data () {
		return {
			RELOAD_FILE_BROWSER_BTN_WIDTH: '100px',
			IFRAME_TRANSITION: 'width 300ms linear',
			UI_BROWSER_IFRAME_ID: 'launch-ui-iframe',
			FILE_BROWSER_IFRAME_ID: 'launch-file-browser-iframe',

			onPollSbudServicesTimeout: null,
			numPolledSbudServices: 0,
		};
	},
	computed: {
		...mapState([
			'user',
			'team',
			'toolbar',
		]),
		allowFileBrowserSidePanel () {
			return !!(
				this.toolbar.session &&
				!this.toolbar.session.apps.every((app: AppKey) => app === APP_KEY.FILE_001)
			);
		},
		isWorkstationUi () {
			return !!(
				this.toolbar.session &&
				this.toolbar.session.apps.some((app: AppKey) => app === APP_KEY.LINX_001)
			);
		},
		isComfyUi () {
			return !!(
				this.toolbar.session &&
				this.toolbar.session.apps.some((app: AppKey) => app === APP_KEY.CMFY_001)
			);
		},
		fileBrowserWidth () {
			return this.isWorkstationUi ? '50%' : '380px';
		},
		showBootingSessionScreen () {
			return !!(
				this.toolbar.session &&
				BOOTING_SESSION_STATES.includes(this.toolbar.session.state)
			);
		},
		showStoppingSessionScreen () {
			return !!(
				this.toolbar.session &&
				STOPPING_SESSION_STATES.includes(this.toolbar.session.state)
			);
		},

		/*
		File browser computeds
		 */
		serverUrlFileBrowser () {
			if (this.toolbar.session) {
				return this.toolbar.session.serverUrlFileBrowser;
			}
			return null;
		},
		isFileBrowserOpen () {
			return !!(
				this.allowFileBrowserSidePanel &&
				this.toolbar.session &&
				this.isUiOpen &&
				this.serverUrlFileBrowser &&
				this.toolbar.fileBrowserOpen
			);
		},
		fileBrowserIframeStyle () {
			return {
				transition: this.IFRAME_TRANSITION,
				width: this.isFileBrowserOpen ? this.fileBrowserWidth : '0',
				height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
			};
		},
		fileBrowserReloadBtnContainerStyle () {
			return {
				position: 'absolute',
				top: '16px',
				right: `calc((${this.fileBrowserWidth} / 2) - (${this.RELOAD_FILE_BROWSER_BTN_WIDTH} / 2))`,
				width: this.RELOAD_FILE_BROWSER_BTN_WIDTH,
			};
		},

		/*
		UI computeds
		 */

		serverUrlUi () {
			if (this.toolbar.session) {
				return this.toolbar.session.serverUrlUi;
			}
			return null;
		},
		isUiOpen () {
			return !!(
				this.toolbar.session &&
				(
					this.serverUrlUi ||
					this.isWorkstationUi
				)
			);
		},
		uiStyle () {
			return {
				transition: this.IFRAME_TRANSITION,
				...(!this.isWorkstationUi && {
					width: this.isFileBrowserOpen ? `calc(100vw - ${this.fileBrowserWidth})` : '100vw',
					height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
					...(this.toolbar.sessionBarOpen && {
						marginLeft: '55px',
					}),
				}),
			};
		},
	},
	created () {
		window.addEventListener('beforeunload', this.onBeforeUnloadListener);
	},
	destroyed () {
		window.removeEventListener('beforeunload', this.onBeforeUnloadListener);
		if (this.onPollSbudServicesTimeout) clearTimeout(this.onPollSbudServicesTimeout);
	},
	watch: {
		'$store.state.triggers.reloadSessionUiIframeTrigger': {
			immediate: true,
			handler (newVal: number, oldVal: number) {
				if (newVal && newVal !== oldVal) {
					this.reloadIframe(this.UI_BROWSER_IFRAME_ID);
				}
			},
		},
		toolbar: {
			immediate: true,
			handler (newVal: ToolbarState, oldVal: ToolbarState) {
				const oldSessionId: string = this._get(oldVal, 'session.id') || null;
				const newSessionId: string = this._get(newVal, 'session.id') || null;
				const oldSession: Session = this._get(oldVal, 'session') || null;
				const newSession: Session = this._get(newVal, 'session') || null;

				// Reload iframe on new Session
				if (oldSessionId && newSessionId && oldSessionId !== newSessionId) {
					this.$nextTick(() => {
						this.reloadIframe(this.UI_BROWSER_IFRAME_ID);
						this.reloadIframe(this.FILE_BROWSER_IFRAME_ID);
					});
				}

				// Fetch the SBUD services
				const fromStates: SessionState[] = BOOTING_SESSION_STATES.filter((state: SessionState) => state !== SESSION_STATE.BOOT);
				const toStates: SessionState[] = [
					SESSION_STATE.BOOT,
					...READY_SESSION_STATES,
				];
				if (
					newSessionId &&
					(!oldSession || fromStates.includes(oldSession.state)) &&
					newSession &&
					toStates.includes(newSession.state)
				) {
					this.onPollSbudServices(newSession);
				}
			},
		},
	},
	methods: {
		...mapActions([
			'fetchSbudServices',
		]),
		getComfyOnboardingDismissed,
		initIframeRecordings () {
			console.log('TESTING: initIframeRecordings fired'); // Debugging log
			const iframe: HTMLIFrameElement = document.getElementById(this.UI_BROWSER_IFRAME_ID) as HTMLIFrameElement;
			console.log({ iframe }); // Debugging log
			if (iframe) {
				const userData = {
					email: this.user.email,
					id: this.user.id,
					createdAt: moment(this.user.createdAt.toMillis()).toISOString(),
					creatorsClub: this.user.club ? 'Yes' : 'No',
					discordUsername: this.user.discordUser ? this.user.discordUser.username : null,
					region: this.user.region,
					uuid: this.user.uuid,
					trial: this.user.trial ? 'Yes' : 'No',
					numTeams: _keys(this.user.teamIds || {}).length,
				};

				const sessionData = {
					id: _get(this.toolbar, 'session.id'),
					software: _get(this.toolbar, 'session.software'),
					hardware: _get(this.toolbar, 'session.hardware'),
					app: _get(this.toolbar, 'session.apps[0]'),
					serverName: _get(this.toolbar, 'session.serverName'),
				};

				const teamData = {
					id: this.team.id,
					name: this.team.name,
					region: this.team.region,
					isActive: this.team.isActive ? 'Yes' : 'No',
					numAdmins: _keys(this.team.adminIds || {}).length,
					numCreators: _keys(this.team.creatorIds || {}).length,
				};

				// Microsoft Clarity backend script creation
				const scriptToInject = (userData, sessionData, teamData) => {
					(function (c, l, a, r, i, t, y) {
						c[a] =
							c[a] ||
							function () {
								(c[a].q = c[a].q || []).push(arguments);
							};
						t = l.createElement(r);
						t.async = 1;
						t.src = 'https://www.clarity.ms/tag/' + i;
						y = l.getElementsByTagName(r)[0];
						y.parentNode.insertBefore(t, y);
						console.log('rd-clarity.js loaded', clarity);
					})(window, document, 'clarity', 'script', 'mfjtgzclt9');

					// @ts-ignore
					const { clarity } = window;
					if (clarity) {
						// User data
						clarity('identify', userData.email, userData.id);
						clarity('set', 'createdAt', userData.createdAt);
						clarity('set', 'creatorsClub', userData.creatorsClub);
						clarity('set', 'discordUsername', userData.discordUsername);
						clarity('set', 'region', userData.region);
						clarity('set', 'storageUuid', userData.uuid);
						clarity('set', 'isTrial', userData.trial);
						clarity('set', 'numTeams', userData.numTeams);

						// Session data
						clarity('set', 'sessionId', sessionData.id);
						clarity('set', 'sessionSoftware', sessionData.software);
						clarity('set', 'sessionHardware', sessionData.hardware);
						clarity('set', 'sessionApp', sessionData.app);
						clarity('set', 'sessionServerName', sessionData.serverName);

						// Team data
						if (this.team) {
							clarity('set', 'teamId', teamData.id);
							clarity('set', 'teamName', teamData.name);
							clarity('set', 'teamRegion', teamData.region);
							clarity('set', 'teamIsActive', teamData.isActive);
							clarity('set', 'teamNumAdmins', teamData.numAdmins);
							clarity('set', 'teamNumCreators', teamData.numCreators);
						}
					}
				};

				const scriptContent = `(${scriptToInject.toString()})(${JSON.stringify(userData)}, ${JSON.stringify(sessionData)}, ${JSON.stringify(teamData)});`;

				console.log({
					scriptContent,
				});

				// Backend script creation
				const sendScriptToIframe = () => {
					console.log('Sending script to iframe'); // Debugging log
					iframe.contentWindow.postMessage({ type: 'inject-script', content: scriptContent }, '*');
				};

				const checkIframeReady = () => {
					try {
						const iframeWindow = iframe.contentWindow;
						if (iframeWindow) {
							console.log('Iframe window is ready'); // Debugging log
							sendScriptToIframe();
						} else {
							console.log('Iframe not fully ready, retrying...'); // Debugging log
							setTimeout(checkIframeReady, 100);
						}
					} catch (e) {
						console.log('Error while checking iframe readiness, retrying...', e); // Debugging log
						setTimeout(checkIframeReady, 100);
					}
				};

				iframe.onload = function () {
					console.log('Iframe onload event triggered'); // Debugging log
					checkIframeReady();
				};
			}
		},
		async onPollSbudServices(session: Session) {
			if (session.state === SESSION_STATE.READY) this.numPolledSbudServices++;
			if (this.onPollSbudServicesTimeout) clearTimeout(this.onPollSbudServicesTimeout);

			if (session.id === this.toolbar.sbudServices.sessionId && !this.toolbar.sbudServicesLoading) {
				// Done
				this.numPolledSbudServices = 0;
				this.initIframeRecordings();
				return;
			}
			if (session.id !== this.toolbar.sbudServices.sessionId && !this.toolbar.sbudServicesLoading) {
				// Fetch
				const success: boolean = await this.fetchSbudServices(session.id);
				if (success) return;
			}
			// Retry
			this.onPollSbudServicesTimeout = setTimeout(() => {
				if (this.numPolledSbudServices >= 50) {
					if (confirm('Cannot load "Server Manager" for this session. Please refresh your page to try again. If this issue persists, reach out to support.')) {
						window.location.reload();
					} else if (this.onPollSbudServicesTimeout) {
						clearTimeout(this.onPollSbudServicesTimeout);
					}
				} else {
					this.onPollSbudServices(session);
				}
			}, 5000);
		},
		reloadIframe (iframeId) {
			if (!iframeId) return;
			const el: HTMLIFrameElement = document.getElementById(iframeId) as HTMLIFrameElement;
			if (el) {
				// Get element by id again to set the src from a new HTMLElement
				el.src = (document.getElementById(iframeId) as HTMLIFrameElement).src;
			}
		},
		onBeforeUnloadListener (ev: BeforeUnloadEvent) {
			if (this.toolbar.session) {
				const confirmationMessage: string = "Its gonna stay up boy";

				(ev || (window as any).event).returnValue = confirmationMessage; //Gecko + IE
				return confirmationMessage;										 //Webkit, Safari, Chrome
			}
		},
	},
	components: {
		ComfyOnboarding,
		WorkstationUi,
		BootingSessionScreen,
		StoppingSessionDialog,
	},
});
