
import Vue from 'vue';

export default Vue.extend({
	props: {
		iconName: {
			type: String,
			default: 'Discord Logo',
		},
		width: {
			type: [Number, String],
			default: 512,
		},
		height: {
			type: [Number, String],
			default: 512,
		},
		viewBox: {
			type: String,
			default: '0 0 512 512',
		},
		fill: {
			type: String,
			default: '#6f86ff',
		},
	},
});
