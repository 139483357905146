
import Vue from 'vue';

export default Vue.extend({
	props: {
		iconName: {
			type: String,
			default: 'Diagonal Stacked Squares',
		},
		width: {
			type: [Number, String],
			default: 130,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		viewBox: {
			type: String,
			default: '0 0 24 24',
		},
		fill: {
			type: String,
			default: 'none',
		},
	},
});
