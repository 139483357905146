<template>
	<div></div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
	name: 'BaseDrawerSCSS',
});
</script>

<style lang="scss">

</style>
