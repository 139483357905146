
import Vue from 'vue';

export default Vue.extend({
	name: 'PurpleChip',
	props: {
		styles: { type: [Object, String], default: null },
	},
	data () {
		return {};
	},
});
