
import Vue from 'vue';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import { functions } from '@/firebase';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { mapActions } from 'vuex';

export default Vue.extend({
	name: 'DiscordCallback',
	async mounted () {
		const { hash } = this.$route;
		if (!hash) {
			this.closeCurrWindow();
			return;
		}

		// hash looks like: "#token_type=Bearer&access_token=abcdefg1234567890&expires_in=604800&scope=identify"
		const routeData = this.$router.resolve(window.location.hostname + '?' + hash.substring(1));
		if (
			!routeData.route.query.token_type ||
			!routeData.route.query.access_token ||
			!routeData.route.query.expires_in ||
			!routeData.route.query.scope
		) {
			this.closeCurrWindow();
			return;
		}

		try {
			const functionRef = functions
				.httpsCallable('connectDiscordUser');
			const { data } = await functionRef({
				discordAccessToken: routeData.route.query.access_token,
			});
			if (data.success) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'Success!',
					show: true,
				});
				setTimeout(() => {
					this.closeCurrWindow();
				}, 3000);
			} else {
				this.onConnectDiscordError(null);
			}
		} catch (e) {
			console.error(e);
			this.onConnectDiscordError(e);
			this.updateSnackbar({
				status: SNACKBAR_STATUS.ERROR,
				message: 'Error loading payment account portal, please reach out to report issues by clicking the support button in our top toolbar',
				show: true,
			});
		}
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		closeCurrWindow () {
			window.close();
		},
		onConnectDiscordError (e) {
			if (e) {
				console.error(e);
			}
			this.updateSnackbar({
				status: SNACKBAR_STATUS.ERROR,
				message: 'Error authenticating your Discord account, please reach out to report issues by clicking the support button in our top toolbar',
				show: true,
				timeout: 8000,
			});
			setTimeout(() => {
				this.closeCurrWindow();
			}, 8000);
		},
	},
	components: {
		LoadingSVG,
	},
});
