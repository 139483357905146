"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentUserLimitClubAddOn = exports.getCurrentUserTokenClubAddOn = exports.CLUB_TOKEN_ADD_ON_INFO = exports.CLUB_LIMIT_ADD_ON_INFO = exports.CLUB_OFFER_PLAN_INFO = void 0;
const lodash_1 = require("lodash");
exports.CLUB_OFFER_PLAN_INFO = {
    ['CC-001']: {
        club: 'CC-001',
        productId: 'prod_MzTJ7reVi7aBLZ',
    },
    ['CC-002']: {
        club: 'CC-002',
        productId: 'prod_QzlQdITQKVCeeW',
    },
    ['FREE-001']: {
        club: 'FREE-001',
        productId: null,
    },
    ['RUNHOB-001']: {
        club: 'RUNHOB-001',
        productId: 'prod_Ql30D9oNv9lphX',
    },
    ['RUNPRO-001']: {
        club: 'RUNPRO-001',
        productId: 'prod_QkrQyBFiY1B3G6',
    },
};
exports.CLUB_LIMIT_ADD_ON_INFO = {
    ['LIMITFREE-001']: {
        id: 'LIMITFREE-001',
        productId: null,
    },
    ['LIMITRUNHOB-001']: {
        id: 'LIMITRUNHOB-001',
        productId: 'prod_QmeXgXme6DG2sj',
    },
    ['LIMITRUNPRO-001']: {
        id: 'LIMITRUNPRO-001',
        productId: 'prod_QmeWIPlcQ48ux3',
    },
    ['LIMITRUNPRO-002']: {
        id: 'LIMITRUNPRO-002',
        productId: 'prod_QlBkIGC6nW9DJW',
    },
};
exports.CLUB_TOKEN_ADD_ON_INFO = {
    ['TOKENFREE-001']: {
        id: 'TOKENFREE-001',
        productId: null,
    },
    ['TOKENRUNHOB-001']: {
        id: 'TOKENRUNHOB-001',
        productId: 'prod_QmeggHBQdw6r6S',
    },
    ['TOKENRUNPRO-001']: {
        id: 'TOKENRUNPRO-001',
        productId: 'prod_QlC5jlJcgKY5Ea',
    },
    ['TOKENRUNPRO-002']: {
        id: 'TOKENRUNPRO-002',
        productId: 'prod_Qmed1rj5GWyWrS',
    },
};
const getCurrentUserTokenClubAddOn = (user, tokenClubAddOns, shouldDefault) => {
    if (!tokenClubAddOns || !tokenClubAddOns.length)
        return null;
    let result = null;
    if (user && user.clubInfo && user.clubInfo.tokenId) {
        // Current subscription's add-on value
        result = (0, lodash_1.find)(tokenClubAddOns, ['id', user.clubInfo.tokenId]) || null;
    }
    if (shouldDefault && !result) {
        // Default add-on value
        result = (0, lodash_1.find)(tokenClubAddOns, ['isDefault', true]) || null;
    }
    if (shouldDefault && !result) {
        // First add-on value
        // Given the parameter `tokenClubAddOns` comes in already sorted by `sortOrder` property
        result = (0, lodash_1.get)(tokenClubAddOns, '[0]') || null;
    }
    return result;
};
exports.getCurrentUserTokenClubAddOn = getCurrentUserTokenClubAddOn;
const getCurrentUserLimitClubAddOn = (user, limitClubAddOns, shouldDefault) => {
    if (!limitClubAddOns || !limitClubAddOns.length)
        return null;
    let result = null;
    if (user && user.clubInfo && user.clubInfo.limitId) {
        // Current subscription's add-on value
        result = (0, lodash_1.find)(limitClubAddOns, ['id', user.clubInfo.limitId]) || null;
    }
    if (shouldDefault && !result) {
        // Default add-on value
        result = (0, lodash_1.find)(limitClubAddOns, ['isDefault', true]) || null;
    }
    if (shouldDefault && !result) {
        // First add-on value
        // Given the parameter `limitClubAddOns` comes in already sorted by `sortOrder` property
        result = (0, lodash_1.get)(limitClubAddOns, '[0]') || null;
    }
    return result;
};
exports.getCurrentUserLimitClubAddOn = getCurrentUserLimitClubAddOn;
