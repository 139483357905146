import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-4",staticStyle:{"position":"relative","border-radius":"var(--global-border-radius)","min-height":"170px","border":"1px solid var(--grey-800)"},style:(_vm.containerStyles)},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"overflow":"hidden"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticStyle:{"flex":"30"}},[(_vm.isTeamUpsell)?_c('img',{staticStyle:{"position":"relative","bottom":"-45px","left":"30px","max-width":"100%"},attrs:{"alt":"Team Plan","src":require("../assets/team.png")}}):(_vm.isCreatorsClubUpsell)?_c('img',{staticStyle:{"position":"relative","bottom":"-55px","left":"30px","max-width":"100%"},attrs:{"alt":"Creator's Club","src":require("../assets/art-book.png")}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-flex flex-column justify-space-between",class:{
				'pl-6': _vm.$vuetify.breakpoint.xsOnly,
				'pl-12': _vm.$vuetify.breakpoint.smAndUp,
			},staticStyle:{"flex":"70"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"padding":"15px 0"}},[_c('div',{staticClass:"mb-5 mt-3",class:{
						'gradient-text': _vm.hover,
					},staticStyle:{"line-height":"32px","font-size":"30px","font-weight":"bold"}},[(_vm.isTeamUpsell)?_c('span',[_vm._v(_vm._s(_vm.teamTitle))]):(_vm.isCreatorsClubUpsell)?_c('span',[_vm._v(_vm._s(_vm.creatorsClubTitle))]):_vm._e()]),(_vm.isTeamUpsell)?_c('div',{class:{
						'ml-4': _vm.$vuetify.breakpoint.smAndUp,
					}},[_c('list-item',{attrs:{"icon":"mdi-check-circle","container-styles":{
							marginBottom: '5px',
						}}},[_c('b',{class:{ 'gradient-text': _vm.emphasizeRecurringBalance }},[_vm._v("$20 per month")]),_vm._v(" of promotional balance awarded ")]),_c('list-item',{attrs:{"icon":"mdi-check-circle","container-styles":{
							marginBottom: '5px',
						}}},[_c('b',[_vm._v("2+")]),_vm._v(" users ")]),_c('list-item',{attrs:{"icon":"mdi-check-circle","container-styles":{
							marginBottom: '5px',
						}}},[_c('b',[_vm._v("Shared")]),_vm._v(" private storage between team members ")]),_c('list-item',{attrs:{"icon":"mdi-check-circle","container-styles":{
							marginBottom: '5px',
						}}},[_c('b',[_vm._v("Discount")]),_vm._v(" on Large size hardware ")]),_c('list-item',{attrs:{"icon":"mdi-check-circle","container-styles":{
							marginBottom: '5px',
						}}},[_vm._v(" Access to "),_c('b',[_vm._v("MAX")]),_vm._v(" size hardware ")]),_c('list-item',{attrs:{"icon":"mdi-check-circle","container-styles":{
							marginBottom: 0,
						}}},[_vm._v(" And more... ")])],1):(_vm.isCreatorsClubUpsell)?_c('div',{class:{
						'ml-4': _vm.$vuetify.breakpoint.smAndUp,
					}},[_c('list-item',{attrs:{"icon":"mdi-check-circle","container-styles":{
							marginBottom: '5px',
						}}},[_c('b',{class:{ 'gradient-text': _vm.emphasizeRecurringBalance }},[_vm._v("$10 per month")]),_vm._v(" of promotional balance awarded ")]),_c('list-item',{attrs:{"icon":"mdi-check-circle","container-styles":{
							marginBottom: '5px',
						}}},[_c('b',[_vm._v("100GB")]),_vm._v(" personal storage just for you ")]),_c('list-item',{attrs:{"icon":"mdi-check-circle","container-styles":{
							marginBottom: '5px',
						}}},[_c('b',[_vm._v("Discount")]),_vm._v(" on Large size hardware ")]),_c('list-item',{attrs:{"icon":"mdi-check-circle","container-styles":{
							marginBottom: '5px',
						}}},[_vm._v(" Access to "),_c('b',[_vm._v("MAX")]),_vm._v(" size hardware ")]),_c('list-item',{attrs:{"icon":"mdi-check-circle","container-styles":{
							marginBottom: 0,
						}}},[_vm._v(" And more... ")])],1):_vm._e()]),_c('div',{staticClass:"d-flex justify-end mr-5"},[_c('base-button',{staticClass:"mt-3 mb-4",attrs:{"call-to-action":""},on:{"click":function($event){return _vm.$emit('on-learn-more-click')}}},[_vm._v(" Learn More "),(_vm.isTeamUpsell)?_c(VIcon,{staticClass:"ml-1",staticStyle:{"font-size":"18px"}},[_vm._v("mdi-open-in-new")]):_vm._e()],1)],1)])]),(_vm.team && _vm.isTeamUpsell)?_c('div',{staticClass:"d-flex justify-end align-center py-3 pr-3"},[_c('span',{staticStyle:{"color":"var(--grey-600)","font-family":"'nasalization'"}},[_vm._v(" "+_vm._s(_vm._f("truncateMiddle")(_vm.team.name,25))+" ")]),_c('team-picker',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Change ")])]}}],null,false,2507131751)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }