
import Vue from 'vue';

export default Vue.extend({
	name: 'TeamCard',
	props: {
		team: { type: Object, default: null },
		company: { type: Object, default: null },
		selected: { type: Boolean, default: false },
		selectionMode: { type: Boolean, default: false },
	},
	methods: {
		handleClick () {
			if (this.selectionMode) {
				if (this.team) {
					this.$emit('on-select', this.team);
				} else {
					this.$emit('on-select', null);
				}
			}
		},
	},
});
