import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('runnit-dialog',{attrs:{"title":"Models","icon":"mdi-image-multiple-outline","value":_vm.value,"bodyHeight":_vm.bodyHeight,"max-width":_vm.maxWidth,"persistent":_vm.persistent},on:{"input":_vm.setOpen,"click:outside":function($event){return _vm.setOpen(false)}}},[_c('div',{staticStyle:{"width":"100%","overflow-y":"auto","display":"flex","flex-wrap":"wrap","border":"none"},style:({
                'max-height': _vm.bodyHeight,
            }),attrs:{"id":`models-dialog-container-${_vm.elIdSuffix}`}},[(_vm.loading)?_c('div',{staticClass:"full-width"},[_c('loading-state')],1):(!_vm.loading && !_vm.models.length)?_c('div',{staticClass:"full-width"}):_c('div',[_c('transition-group',{staticClass:"transition-group-flex-container",staticStyle:{"gap":"20px"},attrs:{"name":"slide-fade","tag":"div"}},_vm._l((_vm.models),function(model,index){return _c(VCard,{key:model.id,staticStyle:{"min-height":"100px","min-width":"100px","cursor":"pointer"},attrs:{"id":`models-dialog-node-${_vm.elIdSuffix}-${index}`},on:{"click":function($event){return _vm.onModelSelect(model)}}},[_c(VCardTitle,[_c('div',[_vm._v(_vm._s(model.name))])]),_c(VCardText,[_c('img',{staticClass:"runnit-image",style:({
                                    height: '100%',
                                    width: '100%',
                                    maxHeight: `${_vm.THUMB_SIZE}px`,
                                    maxWidth: `${_vm.THUMB_SIZE}px`,
                                }),attrs:{"slot":"first","loading":"eager","src":model.img,"alt":model.description},slot:"first"})])],1)}),1),(_vm.showLoadMore)?_c('image-gallery-load-more-btns-row',{attrs:{"load-more-loading":_vm.loadingMore,"load-more-disabled":_vm.loadingMore},on:{"on-load-more-click":function($event){return _vm.$emit('on-load-more', true)},"on-scroll-top-click":_vm.onScrollTop}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }