import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.user && _vm.user.isAdmin)?_c(VContainer,{staticClass:"pt-10 pb-12"},[_c(VRow,[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between align-center mt-6"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c('back-button',{on:{"click":_vm.goBack}}),_c('div',{staticClass:"pl-6 full-width d-flex align-center justify-space-between"},[_c('div',{staticClass:"page-title"},[_vm._v(" Sessions Admin Dashboard ")]),_c('div',{staticClass:"d-flex align-center"},[_c(VBtnToggle,{attrs:{"rounded":"","color":"primary"},model:{value:(_vm.dashboardTypeVal),callback:function ($$v) {_vm.dashboardTypeVal=$$v},expression:"dashboardTypeVal"}},[_c(VBtn,{attrs:{"value":_vm.REGULAR_DASHBOARD_TYPE}},[_c('b',[_vm._v("Regular")])]),_c(VBtn,{attrs:{"value":_vm.BOT_CLUSTER_DASHBOARD_TYPE}},[_c('b',[_vm._v("Discord Cluster")])])],1)],1)])],1)])],1)],1)])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(!_vm.dashboardTypeVal)?_c('empty-state',{attrs:{"icon":"mdi-server-security","message":"Select dashboard type above"}}):_vm._e(),(_vm.dashboardTypeVal === _vm.REGULAR_DASHBOARD_TYPE)?_c('regular-sessions-dashboard'):(_vm.dashboardTypeVal === _vm.BOT_CLUSTER_DASHBOARD_TYPE)?_c('discord-cluster-sessions-dashboard'):_vm._e(),_c('div',{staticClass:"my-12 py-12"})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }