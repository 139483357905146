<template>
	<div
		class="drawer-settings-row"
		:style="containerStyles"
	>
		<slot name="default">
			<div
				class="drawer-settings-label"
				:style="{
					margin: hasDetails ? '0 15px 30px 0' : '0 15px 0 0',
					...(labelMarginTop && {
						marginTop: labelMarginTop,
					}),
				}"
			>
				<slot name="label"></slot>
			</div>
			<div class="drawer-settings-setting">
				<slot name="setting"></slot>
			</div>
		</slot>
	</div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'DrawerSettingsRow',
	props: {
		hasDetails: { type: Boolean, default: false },
		containerStyles: { type: [String, Object], default: null },
		labelMarginTop: { type: String, default: null },
	},
});
</script>

<style scoped lang="scss">
.drawer-settings {
	&-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 25px 0;
	}
	&-label {
		flex: 1;
		color: var(--grey-550);
		font-size: 15px;
		font-weight: bold;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;
	}
	&-setting {
		flex: 1;
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
</style>
