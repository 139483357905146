
import Vue from 'vue';

export default Vue.extend({
	name: 'ActionsIsland',
	props: {
		width: { type: String, default: '96.5%' },
		height: { type: String, default: '60px' },
		bottom: { type: String, default: '20px' },
		styles: { type: Object, default: null },
	},
});
