
import Vue from 'vue';
import ListItem from '@/components/base/ListItem.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import DialogContent from '@/components/base/DialogContent.vue';
import { RESTART_SERVICE } from '@run-diffusion/shared';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import ActionsIsland from '@/components/ActionsIsland.vue';
import { functions } from '@/firebase';
import { AwaitingServerUrlUi200Mixin } from '@/mixins';

export default Vue.extend({
	name: 'RestartButton',
	mixins: [
		AwaitingServerUrlUi200Mixin,
	],
	data () {
		return {
			dialogOpen: false,
			restartLoading: false,
		};
	},
	computed: {
		...mapState([
			'toolbar',
			'appOffers',
		]),
		appName () {
			const selectedApp = (this.appOffers || [])
				.filter(
					({ app }) => {
						return this._get(this.toolbar, 'session.apps[0]') === app;
					}
				);

			return this._get(selectedApp, '[0].shortLabel', '');
		},
		isAuto1111 () {
			const selectedApp = (this.appOffers || [])
				.filter(
					({ app }) => {
						return this._get(this.toolbar, 'session.apps[0]') === app;
					}
				);

			return this._get(selectedApp, '[0].app', '').startsWith('AUTO-');
		},
		isVlad () {
			const selectedApp = (this.appOffers || [])
				.filter(
					({ app }) => {
						return this._get(this.toolbar, 'session.apps[0]') === app;
					}
				);

			return this._get(selectedApp, '[0].app', '').startsWith('VLAD-');
		},
	},
	watch: {
		startAwaitingServerUrlUi200State: {
			immediate: true,
			handler (newVal: string, oldVal: string) {
				if (!newVal && oldVal) {
					this.restartLoading = false;
					this.onCloseDialog();
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		onCloseDialog () {
			this.dialogOpen = false;
		},
		onOpenDialogClick () {
			this.dialogOpen = true;
		},
		async onRestartClick () {
			const onError: Function = (e, message: string) => {
				console.error('Error restarting application: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: message || 'Error restarting application',
					show: true,
				});
			};

			try {
				this.restartLoading = true;

				const serviceName: string = this._get(this.toolbar, 'session.apps[0]').split('-')[0];

				const functionRef = functions
					.httpsCallable('serverBuddy');
				// Don't await this on purpose
				functionRef({
					sessionId: this.toolbar.session.id,
					endpoint: RESTART_SERVICE,
					params: {
						service_name: `rd_${serviceName}`,
					},
				});
				this.$emit('on-restart-initialized');
				this.updateSnackbar({
					status: SNACKBAR_STATUS.INFO,
					message: 'Restart command sent to session! Please wait...',
					show: true,
					timeout: 10000,
				});
				this.startAwaitingServerUrlUi200Loop(this.AWAITING_NON_200);
			} catch (e) {
				onError(e, null);
				this.restartLoading = false;
				this.onCloseDialog();
			}
		},
	},
	components: {
		ActionsIsland,
		ListItem,
		BaseButton,
		DialogContent,
	},
});
