
import Vue from 'vue';
import ComplexBackgroundXS from '@/components/designElements/ComplexBackgroundXS.vue';

export default Vue.extend({
	name: 'BaseToggle',
	props: {
		value: { type: Boolean, default: false },
	},
	data () {
		return {
			isOn: this.value,
		};
	},
	watch: {
		value (newValue) {
			this.isOn = newValue;
		},
	},
	methods: {
		toggle () {
			this.isOn = !this.isOn;
			this.$emit('input', this.isOn);
		},
	},
	components: {
		ComplexBackgroundXS,
	},
});
