
import Vue from 'vue';
import pluralize from 'pluralize';
import TokensSVG from '@/assets/TokensSVG.vue';

export default Vue.extend({
	name: 'TokenClubAddOnSelectItem',
	props: {
		item: { type: Object, required: true },
		clubOffer: { type: Object, required: true },
		highlightAsCurrentPlanItem: { type: Boolean, default: false },
	},
	computed: {
		label () {
			const tokensStr: string = pluralize('Token', this.item.tokens);
			if (this.clubOffer.benefits && this.clubOffer.benefits.monthlyRunnitTokens) {
				return `${tokensStr} per month`;
			}
			if (this.clubOffer.benefits && this.clubOffer.benefits.dailyRunnitTokens) {
				return `${tokensStr} per day`;
			}
			return tokensStr;
		},
	},
	components: {
		TokensSVG,
	},
});
