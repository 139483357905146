
import Vue from 'vue';

export default Vue.extend({
	name: 'AppOfferCarousel',
	props: {
		carouselUrls: { type: Array, default: null },
		imgStyle: { type: Object, default: null },
	},
	data () {
		return {
			dialogOpen: false,
		};
	},
});
