
import Vue from 'vue';
import OfferingCard from '@/components/OfferingCard.vue';
import ComplexBackgroundBig from '@/components/designElements/ComplexBackgroundBig.vue';
import { ROUTER } from '@/router/constants';
import { RUNNIT_NODE_DEF_TAG_TYPE } from '@/constants/enums';
import WhiteTransparentChip from '@/components/WhiteTransparentChip.vue';
import { RunnitNodeDef, RunnitNodeDefTag } from '@run-diffusion/shared';
import { mapState } from 'vuex';
import _shuffle from 'lodash/shuffle';

export default Vue.extend({
	name: 'RunnitsPromoCard',
	props: {
		showButton: { type: Boolean, default: false },
		showAnimation: { type: Boolean, default: false },
	},
	data () {
		return {
			numGridItems: 16,
			featuredTools: [],
		};
	},
	watch: {
		loadingPublicRunnitNodeDefs: {
			immediate: true,
			handler (newVal) {
				if (newVal === false && !this.featuredTools.length) {
					this.setFeaturedTools();
				}
			}
		}
	},
	computed: {
		...mapState([
			'publicRunnitNodeDefs',
			'loadingPublicRunnitNodeDefs',
			'publicRunnitNodeDefTags',
		]),
		computedTransform () {
			let rotateX = 53;
			let rotateY = 356;
			let rotateZ = 31;
			let scale = 0.9;
			let translateY = -220;
			let translateX = 50;

			if (this.$vuetify.breakpoint.xsOnly) {
				rotateZ = 44;
				scale = 0.35;
				translateY = -1570;
				translateX = 438;
			} else if (this.$vuetify.breakpoint.smOnly) {
				rotateZ = 40;
				scale = 0.45;
				translateY = -970;
				translateX = 260;
			} else if (this.$vuetify.breakpoint.mdOnly) {
				rotateZ = 41;
				scale = 0.65;
				translateY = -468;
				translateX = 150;
			} else if (this.$vuetify.breakpoint.xlOnly) {
				rotateZ = 21;
				scale = 1.1;
				translateY = -120;
				translateX = -100;
			}

			return `rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg) scale(${scale}) translateY(${translateY}px) translateX(${translateX}px)`;
		},
	},
	methods: {
		goToRunnitsHome () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_HOME });
		},
		setFeaturedTools (): RunnitNodeDef[] {
			const featuredTag = this.publicRunnitNodeDefTags.find((tag: RunnitNodeDefTag) => tag.type === RUNNIT_NODE_DEF_TAG_TYPE.HOME_PAGE && tag.label === 'Featured')
			if (!featuredTag) {
				this.featuredTools = [];
				return;
			}

			const featuredTools = (this.publicRunnitNodeDefs || []).filter((tool: RunnitNodeDef) => this._get(tool, `tags[${featuredTag.id}]`));
			const shuffledTools = _shuffle(featuredTools);
			this.featuredTools = shuffledTools.slice(0, 3);
		}
	},
	components: {
		WhiteTransparentChip,
		OfferingCard,
		ComplexBackgroundBig,
	},
});
