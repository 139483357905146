import _isNil from 'lodash/isNil';
import _isFunction from 'lodash/isFunction';
import _isNumber from 'lodash/isNumber';
import _isObject from 'lodash/isObject';
import moment, { Moment, MomentInput } from 'moment-timezone';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

export const HIGHEST_MINUTES: number = (24 * 60) - 1;
export const TIME_PICKER_FORMAT = 'HH:mm';
export const ISO_DATE_PICKER_FORMAT = 'YYYY-MM-DD';
export const VIEW_DATE_PICKER_FORMAT = 'MM/DD/YYYY';
export const VIEW_DATE_PICKER_NO_YEAR_FORMAT = 'MM/DD';

export const AM_PM: Record<string, string> = {
	AM: 'AM',
	PM: 'PM',
};

/**
	getUtcMoment: returns a Moment instance
 */
export const getUtcMoment: Function = (momentInput: MomentInput = null, format: string = null) => {
	if (format) {
		if (momentInput) {
			return moment.utc(momentInput, format);
		}
		return moment.utc(new Date(), format);
	}

	if (momentInput) {
		return moment.utc(momentInput);
	}
	return moment.utc(new Date());
};
export const timeInMinutesToTimeInputs: Function = (timeInMinutes: number, useAllMinuteSelection: boolean) => {
	if (_isNil(timeInMinutes)) return {};

	const remainderMinutes: number = timeInMinutes % 60;
	let hours12: number = (timeInMinutes - remainderMinutes) / 60;
	const hours24: number = hours12;
	let amPm: string = AM_PM.AM;
	if (hours12 >= 12) {
		amPm = AM_PM.PM;
	}
	if (hours12 > 12) {
		hours12 -= 12;
	} else if (hours12 === 0) {
		hours12 = 12;
	}

	const oneDigitHours: string = `${hours12}`;
	let twoDigitHours: string = `${hours12}`;
	if (twoDigitHours.length < 2) {
		twoDigitHours = `0${twoDigitHours}`;
	}
	const minutes: number = useAllMinuteSelection ? remainderMinutes : remainderMinutes - (remainderMinutes % 5);
	let twoDigitMinutes: string = `${minutes}`;
	if (twoDigitMinutes.length < 2) {
		twoDigitMinutes = `0${twoDigitMinutes}`;
	}

	return {
		hours24,
		minutes,
		oneDigitHours,
		twoDigitHours,
		twoDigitMinutes,
		amPm,
	};
};

export const timeInputsToTimeInMinutes: Function = (oneOrTwoDigitHours: string, twoDigitMinutes: string, amPm: string): number => {
	if (!oneOrTwoDigitHours || !twoDigitMinutes || !amPm) return null;
	let hours: number = parseInt(oneOrTwoDigitHours, 10);
	if (amPm === AM_PM.AM && hours === 12) hours = 0;
	else if (amPm === AM_PM.PM && hours < 12) hours += 12;
	const hoursInMinutes: number = hours * 60;
	const minutes: number = parseInt(twoDigitMinutes, 10);
	return Math.min(hoursInMinutes + minutes, HIGHEST_MINUTES);
};
export const isoDateStringToViewDateString = isoDateString => {
	if (!isoDateString) return null;
	const [YYYY = '', MM = '', DD = ''] = isoDateString.split('T')[0].split('-');
	return `${MM}/${DD}/${YYYY}`;
};
export const isoDateStringToViewDateNoYearString = isoDateString => {
	if (!isoDateString) return null;
	const [YYYY = '', MM = '', DD = ''] = isoDateString.split('T')[0].split('-');
	return `${MM}/${DD}`;
};
export const viewDateStringToIsoDateString = viewDateString => {
	if (!viewDateString) return null;
	const [MM = '', DD = '', YYYY = ''] = viewDateString.split('/');
	return `${YYYY}-${MM}-${DD}`;
};
/**
 * getVisibleCreatedAtValue: When setting a visible created at date, build the moment object
 *
 * @param timeZone: string
 * @param momentInput: MomentInput
 * @param format: string
 * @returns string
 */
export const getVisibleCreatedAtValue: Function = (timeZone: string, momentInput: MomentInput, format: string = null): string => {
	let result: string = null;

	if (momentInput) {
		// the date-picker component sends back `T00:00:00.000Z`
		const momentValue: Moment = moment.tz(getUtcMoment(momentInput, format).format('YYYY-MM-DD'), timeZone);
		const nowMoment: Moment = moment().tz(timeZone);

		if (momentValue.isValid()) {
			result = nowMoment
				.clone()
				.year(momentValue.year())
				.month(momentValue.month())
				.date(momentValue.date())
				.hour(12) // set to mid-day of logged in device's timezone
				.startOf('hour')
				.toISOString();
		}
	}

	return result;
};

export const combineDateAndTime: Function = (dateStr: string, timeInMinutes: number): Date => {
	// Parse the date and time using moment
	const date: Moment = moment(dateStr, 'YYYY-MM-DD');
	const time: Moment = moment()
		.startOf('day')
		.add(Math.floor(timeInMinutes / 60), 'hours')
		.add(timeInMinutes % 60, 'minutes');

	// Set the time part on the date object
	date.hour(time.hours());
	date.minute(time.minutes());
	date.second(0);
	date.millisecond(0);

	// Return the combined date and time as a JavaScript Date object
	return date.toDate();
};

export interface DateTimeInputValues {
	dateStr: string
	timeInMinutes: number
}
export const convertMomentToDateTimeInputValues: Function = (moment: Moment): DateTimeInputValues => {
	// Format the date and time using moment.js
	const dateStr = moment.format('YYYY-MM-DD');
	const timeInMinutes = moment.hours() * 60 + moment.minutes();

	// Return the formatted date and time strings
	return { dateStr, timeInMinutes };
}

export const mapFirebaseTimestampToDate: Function = (val: any): Timestamp | any => {
	const timestampVal: Timestamp = val as Timestamp;
	if (
		timestampVal &&
		_isObject(timestampVal) &&
		_isFunction(timestampVal.toMillis) &&
		_isFunction(timestampVal.toDate) &&
		_isNumber(timestampVal.toMillis()) &&
		_isNumber(timestampVal.seconds) &&
		_isNumber(timestampVal.nanoseconds)
	) {
		return timestampVal.toDate();
	}
	return val;
};
