import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pb-7 mt-8 pt-4 d-flex flex-column align-center justify-center"},[_c('base-button',{staticClass:"mr-2",staticStyle:{"min-width":"225px"},attrs:{"loading":_vm.loadMoreLoading,"disabled":_vm.loadMoreDisabled,"rounded":"","secondary-primary":""},on:{"click":function($event){return _vm.$emit('on-load-more-click')}}},[_vm._v(" Load More ")]),_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('base-button',{staticClass:"mt-3",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$emit('on-scroll-top-click')}}},[_c('span',{staticStyle:{"transition":"var(--transition-default)"},style:({
					fontSize: hover ? '14px' : '12px',
					color: hover ? 'white' : 'var(--grey-400)',
				})},[_vm._v(" Scroll Top ")]),_c(VIcon,{staticStyle:{"transition":"var(--transition-default)"},style:({
					fontSize: hover ? '22px' : '18px',
					color: hover ? 'white' : 'var(--grey-400)',
				})},[_vm._v(" mdi-arrow-up-thin ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }