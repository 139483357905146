
import Vue from 'vue';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';

export default Vue.extend({
	name: 'DocumentReadyDialog',
	props: {
		value: { type: Boolean, default: false },
	},
	components: {
		ConfirmDialog,
	},
});
