
import Vue from 'vue';
import _isEmpty from 'lodash/isEmpty';
import { db } from '@/firebase';
import { mapActions } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import ClipboardCopy from '@/components/ClipboardCopy.vue';
import DialogContent from '@/components/base/DialogContent.vue';
import { RunnitNode } from '@run-diffusion/shared';
import PurpleChip from '@/components/base/PurpleChip.vue';

export default Vue.extend({
	name: 'RunnitNodeFieldSettingsInternalEditor',
	props: {
		value: { type: Boolean, default: false },
		maxWidth: { type: [Number, String], default: '1300px' },
		selectedNode: { type: Object, default: null },
	},
	data () {
		const EDITOR_PAGES = {
			NODE: 'NODE',
			PRIVATE_NODE: 'PRIVATE_NODE',
		};

		return {
			EDITOR_PAGES,
			open: false,

			existingJSONFields: null,
			newJSONFields: null,
			existingJSONStaticFields: null,
			newJSONStaticFields: null,
			updatingJSON: false,

			privateExistingJSONFields: null,
			privateNewJSONFields: null,
			privateExistingJSONStaticFields: null,
			privateNewJSONStaticFields: null,
			privateExistingJSONLoading: false,

			editorPage: EDITOR_PAGES.NODE,
		};
	},
	watch: {
		value: {
			immediate: true,
			handler (newVal: boolean) {
				this.open = !!newVal;
			},
		},
		selectedNode: {
			immediate: true,
			handler (newVal: RunnitNode) {
				this.existingJSONFields = JSON.stringify(newVal?.fields, null, 2);
				this.existingJSONStaticFields = JSON.stringify(newVal?.staticFields, null, 2);
			},
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
		onNewJSONFieldsInput (newJSONFields: string) {
			this.newJSONFields = newJSONFields;
		},
		async onSaveNewJSONFields () {
			try {
				this.updatingJSON = true;
				const runnitNodeRef = db.doc(`runnits/${this.selectedNode.runnitId}/runnitNodes/${this.selectedNode.id}`);
				const parsedNewJSON = JSON.parse(this.newJSONFields);

				await runnitNodeRef.update({
					fields: parsedNewJSON,
				});

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! New fields JSON saved, please refresh the page to see changes`,
					show: true,
				});
				this.existingJSONFields = JSON.stringify(parsedNewJSON, null, 2);
				this.onCancel();
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error! Problem with saving new fields JSON, please try again`,
					show: true,
				});
			} finally {
				this.updatingJSON = false;
			}
		},
		onNewJSONStaticFieldsInput (newJSONStaticFields: string) {
			this.newJSONStaticFields = newJSONStaticFields;
		},
		async onSaveNewJSONStaticFields () {
			try {
				this.updatingJSON = true;
				const runnitNodeRef = db.doc(`runnits/${this.selectedNode.runnitId}/nunnitNodes/${this.selectedNode.id}`);
				const parsedNewJSON = JSON.parse(this.newJSONStaticFields);

				await runnitNodeRef.update({
					staticFields: parsedNewJSON,
				});

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! New static fields JSON saved, please refresh the page to see changes`,
					show: true,
				});
				this.existingJSONStaticFields = JSON.stringify(parsedNewJSON, null, 2);
				this.onCancel();
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error! Problem with saving new static fields JSON, please try again`,
					show: true,
				});
			} finally {
				this.updatingJSON = false;
			}
		},
		onPrivateNewJSONFieldsInput (newJSONFields: string) {
			this.privateNewJSONFields = newJSONFields;
		},
		async onSavePrivateNewJSONFields () {
			try {
				this.updatingJSON = true;
				const runnitPrivateNodeRef = db.doc(`runnits/${this.selectedNode.runnitId}/runnitPrivateNodes/${this.selectedNode.id}`);
				const parsedNewJSON = JSON.parse(this.privateNewJSONFields);

				await runnitPrivateNodeRef.update({
					fieldValues: parsedNewJSON,
				});

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! New private fields JSON saved, please refresh the page to see changes`,
					show: true,
				});
				this.privateExistingJSONFields = JSON.stringify(parsedNewJSON, null, 2);
				this.onCancel();
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error! Problem with saving new private fields JSON, please try again`,
					show: true,
				});
			} finally {
				this.updatingJSON = false;
			}
		},
		onPrivateNewJSONStaticFieldsInput (newJSONStaticFields: string) {
			this.privateNewJSONStaticFields = newJSONStaticFields;
		},
		async onSavePrivateNewJSONStaticFields () {
			try {
				this.updatingJSON = true;
				const runnitPrivateNodeRef = db.doc(`runnits/${this.selectedNode.runnitId}/runnitPrivateNodes/${this.selectedNode.id}`);
				const parsedNewJSON = JSON.parse(this.privateNewJSONStaticFields);

				await runnitPrivateNodeRef.update({
					staticFieldValues: parsedNewJSON,
				});

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! New private static fields JSON saved, please refresh the page to see changes`,
					show: true,
				});
				this.privateExistingJSONStaticFields = JSON.stringify(parsedNewJSON, null, 2);
				this.onCancel();
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error! Problem with saving new private static fields JSON, please try again`,
					show: true,
				});
			} finally {
				this.updatingJSON = false;
			}
		},
		async loadPrivateNodeSettings () {
			try {
				this.privateExistingJSONLoading = true;
				const runnitPrivateNodeRef = db.doc(`runnits/${this.selectedNode.runnitId}/runnitPrivateNodes/${this.selectedNode.id}`);
				const runnitPrivateNode = (await runnitPrivateNodeRef.get()).data();

				if (!_isEmpty(runnitPrivateNode.fieldValues)) {
					this.privateExistingJSONFields = JSON.stringify(runnitPrivateNode.fieldValues, null, 2);
				} else {
					this.privateExistingJSONFields = '{}';
				}

				if (!_isEmpty(runnitPrivateNode.staticFieldValues)) {
					this.privateExistingJSONStaticFields = JSON.stringify(runnitPrivateNode.staticFieldValues, null, 2);
				} else {
					this.privateExistingJSONStaticFields = '{}';
				}

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Existing private fields & static fields JSON loaded`,
					show: true,
				});
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error! Problem with loading existing private fields & static fields JSON, please try again`,
					show: true,
				});
			} finally {
				this.privateExistingJSONLoading = false;
			}
		},
	},
	components: {
		PurpleChip,
		ClipboardCopy,
		DialogContent,
	},
});
