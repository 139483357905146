
import Vue from 'vue';

export default Vue.extend({
	name: 'BackButton',
	props: {
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		elevation: { type: Number, default: 0 },
		secondary: { type: Boolean, default: false },
		text: { type: Boolean, default: false },
		tooltipText: { type: String, default: 'Back' },
		callToAction: { type: Boolean, default: false },
		backgroundColor: { type: String, default: 'var(--grey-800)' },
		cssClass: { type: [String, Object], default: 'px-4 font-weight-bold' },
	},
	computed: {
		isPrimary () {
			return (
				!this.disabled &&
				!this.secondary &&
				!this.callToAction &&
				!this.text
			);
		},
		isSecondary () {
			return (
				this.secondary &&
				!this.callToAction &&
				!this.text
			);
		},
		btnColor () {
			let color = '';

			if (this.secondary) {
				color = '#b0bec5';
			} else if (this.callToAction) {
				color = '#6f86ff';
			}

			return color;
		},
	},
});
