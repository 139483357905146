
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';

export default Vue.extend({
	name: 'FailedSessionMessageDialogs',
	computed: {
		...mapState([
			'toolbar',
		]),
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
	},
	components: {
		ConfirmDialog,
	},
});
