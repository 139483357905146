
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import CreatorsClubPromoBanner from '@/components/PromoBanners/CreatorsClubPromoBanner.vue';

export default Vue.extend({
	name: 'UpsellCreatorsClubDialog',
	props: {
		value: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		maxWidth: { type: Number, default: 900 },
	},
	computed: {
		...mapState([
			'upsellDialog',
		]),
	},
	methods: {
		...mapActions([
			'setUpsellDialog',
		]),
		onDialogInput (val: boolean) {
			if (!!val !== this.value) {
				this.$emit('input', !!val);
			}
			if (!val) {
				this.setUpsellDialog({});
			}
		},
	},
	components: {
		CreatorsClubPromoBanner,
	},
});
