<template>
	<v-dialog
		v-model="dialogOpen"
		max-width="550"
	>
		<template v-slot:activator="{ on: dialogOn }">
			<v-tooltip
				bottom
				open-delay="500"
			>
				<template v-slot:activator="{ on: tooltipOn }">
					<div v-on="{ ...dialogOn, ...tooltipOn }" style="display: block; cursor: pointer;" class="mr-2">
						<EnabledApiSVG />
					</div>
				</template>
				<div>
					API Enabled
				</div>
			</v-tooltip>
		</template>
		<v-card>
			<v-card-title class="px-12 py-7 d-flex justify-space-between">
				<span class="dialog-title">
					API Enabled
				</span>
				<base-button
					secondary
					icon
					small
					@click="onCancel"
				>
					<v-icon small>mdi-close</v-icon>
				</base-button>
			</v-card-title>
			<div class="inset-divider"></div>
			<v-card-text style="height: 250px;">
				<div class="px-12 d-flex flex-column full-height justify-center align-center">
					<div
						class="d-flex justify-center align-center"
						style="width: 470px; height: 150px;"
					>
						<div class="d-flex flex-column align-center">
							<div style="text-align: center; font-size: 16px; color: var(--grey-600);" class="mb-6">
								Use the link below to get access to the running API:
							</div>
							<div
								class="mb-7"
								style="flex-wrap: wrap; max-width: 470px; font-size: 18px; text-align: center; font-family: monospace;"
							>
								{{ _get(toolbar, 'session.serverUrlApi') || _get(toolbar, 'session.serverUrlUi') || 'Not found (email us at support@rundiffusion.com)' }}
							</div>
							<clipboard-copy
								v-if="(
									toolbar &&
									toolbar.session &&
									toolbar.session.serverUrlUi
								)"
								:value="toolbar.session.serverUrlUi"
								tooltip-text=""
							>
								<template v-slot:activator="{ on, attrs }">
									<base-button
										v-on="on"
										v-bind="attrs"
										call-to-action
										class="ml-4 mb-0 px-7"
									>
										<b>Copy</b>
									</base-button>
								</template>
							</clipboard-copy>
						</div>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import ClipboardCopy from '@/components/ClipboardCopy';
import EnabledApiSVG from '@/assets/EnabledApiSVG';

export default Vue.extend({
	name: 'EnabledApiAnimation',
	data () {
		return {
			dialogOpen: false,
		};
	},
	computed: {
		...mapState([
			'toolbar',
		]),
	},
	methods: {
		onCancel () {
			this.dialogOpen = false;
		},
	},
	components: {
		EnabledApiSVG,
		ClipboardCopy,
	},
});
</script>
