
import Vue from 'vue';
import { ROUTER } from '@/router/constants';
import { mapState } from 'vuex';
import { Company, Team } from '@run-diffusion/shared';
import { REPORTS } from '@/constants/constants';
import TeamPicker from '@/components/TeamPicker.vue';
import BackButton from '@/components/base/BackButton.vue';
import PageTopNavigation from '@/components/base/PageTopNavigation.vue';

export default Vue.extend({
	name: 'TeamReports',
	props: {},
	data () {
		return {
			REPORTS,
			companiesList: [],

			selectedTeam: null,
			selectedCompany: null,

			allTeamsSelectedObject: {
				name: 'All Teams',
			},
		};
	},
	computed: {
		...mapState([
			'user',
			'team',
		]),
		tabsData () {
			// TODO: remove this once the APP_METRICS_REPORT is inside metabase and usable by everyone
			const isPlaytikaTeam: boolean = (
				(this._get(this.selectedTeam, 'name') || '').includes('Playtika') ||
				(this._get(this.selectedTeam, 'name') || '').includes('All Teams')
			) || false;
			return [
				{ name: 'Generations', route: ROUTER.IMAGE_GENERATIONS_REPORT },
				{ name: 'Image Audit', route: ROUTER.IMAGE_AUDIT_REPORT },
				{ name: 'User Usage', route: ROUTER.USER_USAGE_REPORT },
				...(isPlaytikaTeam ? [
					{ name: 'Apps Usage', route: ROUTER.APP_METRICS_REPORT }
				] : []),
			];
		},
	},
	watch: {
		team: {
			immediate: true,
			handler (newVal: Team, oldVal: Team) {
				if (newVal !== oldVal) {
					if (newVal) {
						this.selectedTeam = newVal;
					}
				}
			},
		},
	},
	methods: {
		goHome () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.TEAM_MEMBERS });
		},
		onCompanySelect (company: Company) {
			this.selectedCompany = company;
			this.selectedTeam = this.allTeamsSelectedObject;
		},
	},
	components: {
		TeamPicker,
		BackButton,
		PageTopNavigation,
	},
});
