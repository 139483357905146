
import Vue from 'vue';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import { mapActions } from 'vuex';
import { db } from '@/firebase';
import { SNACKBAR_STATUS } from '@/constants/constants';
import ClipboardCopy from '@/components/ClipboardCopy.vue';
import { get$bindFirestoreOptions } from '@/mixins';

export default Vue.extend({
	name: 'AdminSoftwareOfferStats',
	props: {
		softwareOffer: { type: Object, default: null },
	},
	data () {
		return {
			bootVolumeValueLoading: false,
			bootVolumeValue: null,
		};
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		async loadBootVolumeValue () {
			try {
				this.bootVolumeValueLoading = true;
				const bootVolumeValueRef = db.collection('bootVolumes')
					.doc(this.softwareOffer.bootVolume);
				await this.$bind(
					'bootVolumeValue',
					bootVolumeValueRef,
					get$bindFirestoreOptions(),
				);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading bootVolumeValue',
					show: true,
				});
			} finally {
				this.bootVolumeValueLoading = false;
			}
		},
	},
	components: {
		ClipboardCopy,
		MenuOrBottomSheet,
	},
});
