
import Vue from 'vue';

export default Vue.extend({
	name: 'GreyButton',
	data() {
		return {};
	},
	methods: {},
});
