<template>
	<div
		class="warning-message mb-8 mx-8 tw-shadow-2xl"
		style="margin: -20px 0 0 0; border: 1px solid var(--grey-900);"
	>
		<div style="font-size: 24px; font-weight: bold; margin: 0 0 15px 0;">
			{{ title }}
		</div>
		<div>
			<slot name="default"></slot>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'InfoMessage',
	props: {
		title: { type: String, required: true },
	},
});
</script>
