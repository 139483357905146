
import Vue from 'vue';
import { TeamUserMixin } from '@/mixins';

export default Vue.extend({
	name: 'WorkshopCard',
	mixins: [
		TeamUserMixin,
	],
	props: {
		workshop: { type: Object, default: null },
		selected: { type: Boolean, default: false },
		selectionMode: { type: Boolean, default: false },
	},
	methods: {
		handleClick () {
			if (this.selectionMode) {
				if (this.workshop) {
					this.$emit('on-select', this.workshop);
				} else {
					this.$emit('on-select', null);
				}
			}
		},
		openWorkshop () {
			this.routerPush(this.$route, this.$router, {
				name: 'Workshop',
				params: {
					workshopId: this.workshop.id,
				},
			});
		},
	},
});
