import { render, staticRenderFns } from "./RunnitMenuOrBottomSheet.vue?vue&type=template&id=71b64a91&scoped=true"
import script from "./RunnitMenuOrBottomSheet.vue?vue&type=script&lang=ts"
export * from "./RunnitMenuOrBottomSheet.vue?vue&type=script&lang=ts"
import style0 from "./RunnitMenuOrBottomSheet.vue?vue&type=style&index=0&id=71b64a91&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b64a91",
  null
  
)

export default component.exports