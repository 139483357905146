
import Vue from 'vue';
import LogRocket from 'logrocket';
import FingerprintJs from '@fingerprintjs/fingerprintjs';
import firebase from 'firebase/app';
import moment from 'moment-timezone';
import { APP_KEY } from '@/constants/enums';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
	SNACKBAR_STATUS,
	READY_SESSION_STATES,
	NEW_USER_PROMO_END_DATE,
	NEW_USER_PROMO_START_DATE,
	BOOTING_SESSION_STATES,
} from '@/constants/constants';
import {
	AppKey,
	StableDiffusionModel,
	User,
	Team,
	Fingerprint,
} from '@run-diffusion/shared';
import _get from 'lodash/get';
import _keys from 'lodash/keys';
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';
import {
	shouldRedirectFromUserNotActivated,
	shouldRedirectToEmailNotVerified,
	shouldRedirectToUserNotActivated,
} from '@/router/utils';
import {
	getTeamId,
	getProxyAdminToken,
	getCivitaiModelVersionId,
	setRunnitsOwnerSelection,
} from '@/utils/localStorage';
import {
	LaunchedSessionTimerMixin,
	VuexFirestoreBindingsMixin,
} from '@/mixins';
import { db, functions } from '@/firebase';
import { logRocketUserIds } from '@/utils/logRocketUserIds';
import { getGoogleAnalyticsClientId } from '@/utils/googleAnalytics';
import Toolbar from '@/components/Toolbar/Toolbar.vue';
import Resources from '@/views/Resources/Resources.vue';
import GlobalLoader from '@/components/GlobalLoader.vue';
import GlobalSCSS from '@/components/Scss/GlobalSCSS.vue';
import RunnitsSCSS from '@/components/Scss/RunnitsSCSS.vue';
import GlobalSnackbar from '@/components/GlobalSnackbar.vue';
import SettingsBtn from '@/components/Toolbar/SettingsBtn.vue';
import DrawerDivider from '@/components/base/DrawerDivider.vue';
import SessionBar from '@/components/SessionBar/SessionBar.vue';
import BaseDrawerSCSS from '@/components/Scss/BaseDrawerSCSS.vue';
import BalanceCard from '@/components/BalanceCard/BalanceCard.vue';
import UnsupportedDialog from '@/components/UnsupportedDialog.vue';
import TimeExpiringDialog from '@/components/TimeExpiringDialog.vue';
import AccountMenuBtn from '@/components/Toolbar/AccountMenuBtn.vue';
import FileBrowserBtn from '@/components/Toolbar/FileBrowserBtn.vue';
import NotificationsBtn from '@/components/Toolbar/NotificationsBtn.vue';
import UserAccount from '@/components/Toolbar/UserAccount/UserAccount.vue';
import StorageCapCard from '@/components/StorageCapCard/StorageCapCard.vue';
import ServerBuddy from '@/components/SessionBar/ServerBuddy/ServerBuddy.vue';
import FileBrowserNewTabBtn from '@/components/Toolbar/FileBrowserNewTabBtn.vue';
import GetSupportBtn from '@/components/Toolbar/GetSupportBtn/GetSupportBtn.vue';
import UpsellCreatorsClubDialog from '@/components/UpsellCreatorsClubDialog.vue';
import UpsellRunnitsPlanDialog from '@/components/UpsellRunnitsPlanDialog.vue';
import MultipleSessions from '@/views/Sessions/CurrSessions/MultipleSessions.vue';
import AdminUserStats from '@/components/AdminStats/AdminUserStats/AdminUserStats.vue';
import NewUserLoginPromoDialog from '@/components/PromoBanners/NewUserLoginPromoDialog.vue';
import NotificationsContainer from '@/components/NotificationsContainer/NotificationsContainer.vue';
import RunnitSideNav from '@/views/Runnits/RunnitSideNav/RunnitSideNav.vue';
import { APP_BAR_HEIGHT } from '@/components/Toolbar/constants';
import ComplexBackgroundMobileToolbar from '@/components/designElements/ComplexBackgroundMobileToolbar.vue';
import ConfigureRunnit from './views/Runnits/ConfigureRunnit.vue';
import { LoginQueryParamsDataState } from './store';
import RunnitsHorizontalLogoSVG from '@/assets/RunnitsHorizontalLogoSVG.vue';
import { RUNNITS_OWNER_SELECTION } from './views/Runnits/constants';
import UnlockFreemium from '@/components/UnlockFreemium.vue';

declare global {
	interface Window {
		fpr: any; // FirstPromoter global
	}
}

export default Vue.extend({
	name: 'App',
	mixins: [
		VuexFirestoreBindingsMixin,
		LaunchedSessionTimerMixin,
	],
	data () {
		return {
			APP_BAR_HEIGHT,
			loggingOut: false,
			timeExpiringDialog: {
				open: false,
				minutesLeft: 0,
			},

			secondInterval: null,
			setHiddenFreshChatWidgetInterval: null,

			menuResetTrigger: 0,
			isEmailVerificationSent: false,
			shouldNewUserPromoOpen: false,
			debouncedIncrementScreenResizeTrigger: () => { },
			addingToTeam: false,
		};
	},
	async created () {
		this.debouncedIncrementScreenResizeTrigger = _debounce(this.incrementScreenResizeTrigger, 100);
		window.addEventListener('resize', this.debouncedIncrementScreenResizeTrigger);
		this.$autoSoundMachine.init();
		this.initSecondInterval();
		this.initSetHiddenFreshChatWidgetInterval();

		if (!this.toolbar.session) {
			this.updateToolbar({
				sessionBarOpen: false,
			});
		}

		// Refresh the existing token if not doing an auto SSO login
		if (!this.loginQueryParamsData.autoLoginSsoProvider) {
			firebase.auth().onIdTokenChanged((authUser: firebase.User) => {
				if (authUser && !authUser.emailVerified && !this.isEmailVerificationSent) {
					authUser.sendEmailVerification();
					this.isEmailVerificationSent = true;
				}
				if (authUser) {
					this.setAuthUser(authUser);
				} else {
					this.setAuthUser(null);
				}
			});
		}

		this.bindAuthUserDependentState(this.authUser, null, this.user);
		this.onUserChangeBinding(this.user, null);
		this.bindStableDiffusionModels();
		this.loadCivitaiPreloadModel();
	},
	destroyed () {
		window.removeEventListener('resize', this.debouncedIncrementScreenResizeTrigger);
	},
	computed: {
		...mapGetters([
			'isLoggedIn',
			'needsUnlockFreemium',
		]),
		...mapState([
			'isAdminProxyingUser',
			'loginQueryParamsData',
			'user',
			'team',
			'appOffersMap',
			'toolbar',
			'authUser',
			'stableDiffusionModels',
			'runnitState',
		]),
		isRouteWithNoTopBar () {
			return this.$route.meta.noTopBar;
		},
		isRouteWithNoTopBarPadding () {
			return this.$route.meta.noTopBarPadding;
		},
		isNotGoToPaymentRoute () {
			return this.$route.name !== 'GoToPayment';
		},
		newUserPromotionIsLive () {
			const startDate = new Date(NEW_USER_PROMO_START_DATE);
			const endDate = new Date(NEW_USER_PROMO_END_DATE);
			const currentTime = new Date();

			return !!(
				currentTime >= startDate &&
				currentTime <= endDate
			);
		},
		usingBraveBrowser () {
			// Check for Brave browser
			if (this._get(navigator, 'brave') && this._get(navigator, 'brave.isBrave.name') === 'isBrave') {
				return true;
			}

			// If none of the unsupported browsers were detected, return true
			return false;
		},
		allowFileBrowserSidePanel () {
			return !!(
				this.isSessionLaunched &&
				!this.toolbar.session.apps.every((app: AppKey) => app === APP_KEY.FILE_001)
			);
		},
		shouldShowUnsupportedDialog () {
			return !!(
				this.isLoggedIn &&
				this.usingBraveBrowser
			);
		},
		isLoginPage () {
			return this.$route.meta.isLoginPage;
		},
		isLoginRequired () {
			return !!(
				this.isLoginPage &&
				(
					!this.isLoggedIn ||
					this.$route.meta.forcedLoginRequired
				)
			);
		},
		isSessionBootingOrLaunched () {
			return !!(
				this.toolbar.session &&
				(
					READY_SESSION_STATES.includes(this.toolbar.session.state) ||
					BOOTING_SESSION_STATES.includes(this.toolbar.session.state)
				) &&
				this.$route.name === 'Launch'
			);
		},
		isSessionLaunched () {
			return !!(
				this.toolbar.session &&
				READY_SESSION_STATES.includes(this.toolbar.session.state) &&
				this.$route.name === 'Launch'
			);
		},
	},
	watch: {
		'$route': {
			immediate: true,
			handler (newVal, oldVal) {
				if (
					!this.$autoSoundMachine.isInitialized() &&
					!this.sessionSoundsOff &&
					newVal.name === 'Launch' &&
					(
						!oldVal ||
						oldVal.name !== 'Launch'
					)
				) {
					// Pop confirm dialog over all the iframes so that our $autoSoundMachine can get a click listener to init itself
					this.autoSoundMachineNeedsInit = true;
				}

				if (_get(newVal, 'meta.pageColor')) {
					this.$vuetify.theme.themes.dark.background = newVal.meta.pageColor;
				} else {
					this.$vuetify.theme.themes.dark.background = '#121212';
				}

				if (_get(newVal, 'meta.isRunnitsSideNavPage') && this.$vuetify.breakpoint.smAndUp) {
					this.updateToolbar({
						runnitsSideNavOpen: true,
					});
				} else {
					this.updateToolbar({
						runnitsSideNavOpen: false,
					});
				}
			},
		},
		isSessionLaunched (newVal, oldVal) {
			if (newVal !== oldVal && this.$vuetify.breakpoint.smAndUp) {
				this.updateToolbar({
					fileBrowserOpen: true,
					sessionBarOpen: true,
				});
			}

			if (!newVal) {
				this.updateToolbar({
					sessionBarOpen: false,
				});
			}
		},
		authUser (newVal: firebase.User, oldVal: firebase.User) {
			this.bindAuthUserDependentState(newVal, oldVal, this.user);
			if (shouldRedirectToEmailNotVerified(this.$route, newVal)) {
				this.routerReplace(this.$route, this.$router, {
					name: 'EmailVerification',
					query: {
						...(this.$route.fullPath !== '/' && {
							redirect: this.$route.fullPath,
						}),
					},
				});
			}
		},
		team (newVal: Team, oldVal: Team) {
			this.onTeamChangeBinding(newVal, oldVal);
		},
		loginQueryParamsData: {
			immediate: true,
			async handler (newVal: LoginQueryParamsDataState, oldVal: LoginQueryParamsDataState) {
				if (this.user && newVal !== oldVal && newVal && newVal.teamId && newVal.inviteCode) {
					await this.connectTeamToTeamMemberSignup();
				}
			},
		},
		async user (newVal: User, oldVal: User) {
			if (newVal && !oldVal) {
				this.doDeviceReport();
			}

			this.onUserChangeBinding(newVal, oldVal);
			if (shouldRedirectFromUserNotActivated(this.$route, newVal)) {
				this.routerReplace(this.$route, this.$router, {
					name: 'Sessions',
				});
			} else if (shouldRedirectToUserNotActivated(this.$route, newVal)) {
				this.routerReplace(this.$route, this.$router, {
					name: 'UserNotActivated',
				});
			}

			// loginQueryParamsData checks
			if (!oldVal && newVal && this.loginQueryParamsData && this.loginQueryParamsData.teamId && this.loginQueryParamsData.inviteCode) {
				await this.connectTeamToTeamMemberSignup();
			}

			// Set GoogleAnalytics clientId
			if (newVal && !newVal.googleAnalyticsClientId) {
				const clientReferenceId: string = await getGoogleAnalyticsClientId(this.$gtag);
				const userRef = db.doc(`users/${newVal.id}`);
				await userRef
					.update({
						googleAnalyticsClientId: clientReferenceId,
					});
			}

			// Set New User Promotion
			if ((this._get(this.user, 'trial') && this.newUserPromotionIsLive)) {
				this.shouldNewUserPromoOpen = true;
			}

			// Record LogRocket session only if they are a CC member
			if (logRocketUserIds.includes(this._get(this.user, 'id'))) {
				LogRocket.init('1a65lt/rundiffusion', {
					mergeIframes: true,
					childDomains: ['*'],
				});

				LogRocket.identify(this._get(this.user, 'email'), {
					email: this._get(this.user, 'email'),

					// Add your own custom user variables here:
					uuid: this._get(this.user, 'uuid'),
					trial: this._get(this.user, 'trial'),
					isDiscordLinked: !!this._get(this.user, 'discord'),
					newUserPromoAmt: !!this._get(this.user, 'newUserPromoAmt'),
					hasTeam: !!this._get(this.user, 'teamIds'),
				});
			}
		},
		countDownTimerVal (newVal: string, oldVal: string) {
			if (
				newVal &&
				newVal !== oldVal &&
				this.toolbar &&
				this.toolbar.session &&
				READY_SESSION_STATES.includes(this.toolbar.session.state)
			) {
				if (
					newVal === this.TEN_MIN_TIME_VAL &&
					this.toolbar.session.scheduledStopMins !== 10
				) {
					this.timeExpiringDialog = {
						open: true,
						minutesLeft: 10,
					};
				} else if (newVal === this.TWO_MIN_TIME_VAL) {
					this.timeExpiringDialog = {
						open: true,
						minutesLeft: 2,
					};
				} else if (newVal === this.THIRTY_SEC_TIME_VAL) {
					this.$autoSoundMachine.playSessionEndsInSeconds(false, 30000);
				}
			}
		},
		stableDiffusionModels (newVal: StableDiffusionModel[]) {
			if (newVal && newVal.length) {
				const modelVersionId: string = getCivitaiModelVersionId();
				if (modelVersionId) {
					const foundModel: StableDiffusionModel = newVal
						.find((sdm: StableDiffusionModel) => sdm.modelVersionId === modelVersionId) || null;
					this.setStableDiffusionModel(foundModel);
				}
			}
		},
	},
	methods: {
		...mapActions([
			'tickRealTimeMoment',
			'setAdminUserProxyTokens',
			'updateLoader',
			'bindUser',
			'bindUserBalanceAccount',
			'bindStripeCustomer',
			'bindStripeTeamsCustomer',
			'unbindStripeTeamsCustomer',
			'bindCurrSessions',
			'bindTeam',
			'unbindTeam',
			'bindWorkspaces',
			'unbindWorkspaces',
			'bindWorkshops',
			'unbindWorkshops',
			'bindTeamBalanceAccount',
			'unbindTeamBalanceAccount',
			'bindAppOffers',
			'bindHardwareOffers',
			'bindSoftwareOffers',
			'bindExtensionOffers',
			'bindStableDiffusionModels',
			'loadCivitaiPreloadModel',
			'setStableDiffusionModel',
			'setAuthUser',
			'updateLoginQueryParamsData',
			'setDeviceReport',
			'updateSnackbar',
			'updateToolbar',
			'closeAllDrawers',
			'incrementScreenResizeTrigger',
			'updateRunnitState',
		]),
		initSecondInterval () {
			if (this.secondInterval) {
				clearInterval(this.secondInterval);
			}
			this.secondInterval = setInterval(() => {
				this.tickRealTimeMoment();
			}, 1000);
		},
		initDefaultFreshChatActivatorBtn () {
			const result: boolean[] = [];
			const { fcWidget } = (window as any);
			const fcAnimatingEls: Element[] = Array.from(document.getElementsByTagName('freshchat-widget'));
			const fcFrameWrapperEl: HTMLElement = document.getElementById('fc_frame');

			if (fcWidget) {
				fcWidget.setConfig({ headerProperty: { hideChatButton: true } });
				result.push(true);
			} else result.push(false);
			if (fcAnimatingEls.length) {
				fcAnimatingEls.forEach((fcAnimatingEl: HTMLElement) => {
					fcAnimatingEl.style.display = 'none';
					result.push(true);
				});
			} else result.push(false);
			if (fcFrameWrapperEl) {
				fcFrameWrapperEl.style.display = 'none';
				result.push(true);
			} else result.push(false);

			if (fcFrameWrapperEl && !fcAnimatingEls.length) {
				// Set the frame wrapper back to visible at the end of the FreshChat init animation
				fcFrameWrapperEl.style.display = 'block';
			}
			return result;
		},
		initSetHiddenFreshChatWidgetInterval () {
			this.setHiddenFreshChatWidgetInterval = setInterval(() => {
				const [...foundEls] = this.initDefaultFreshChatActivatorBtn();
				if (foundEls.every((f: boolean) => f) && this.setHiddenFreshChatWidgetInterval) {
					clearInterval(this.setHiddenFreshChatWidgetInterval);
				}
			}, 250);
			setTimeout(() => {
				if (this.setHiddenFreshChatWidgetInterval) clearInterval(this.setHiddenFreshChatWidgetInterval);
			}, 30000);
		},
		bindAuthUserDependentState (newAuthUser: firebase.User, oldAuthUser: firebase.User, user: User) {
			const newUid = (newAuthUser && newAuthUser.uid) || null;
			const oldUid = (oldAuthUser && oldAuthUser.uid) || null;
			if (
				newUid &&
				(
					newUid !== oldUid ||
					!user
				)
			) {
				this.bindUser(newAuthUser);
			}
		},
		onTeamChangeBinding (newTeam: Team, oldTeam: Team) {
			const newId = (newTeam && newTeam.id) || null;
			const oldId = (oldTeam && oldTeam.id) || null;

			if (
				newId &&
				oldId &&
				newId !== oldId
			) {
				// Since we already bound these on initial fetch of User, only re-bind on change of Team
				this.bindAppOffers(this.user);
				this.bindHardwareOffers(this.user);
				this.bindSoftwareOffers(this.user);
				this.bindExtensionOffers(this.user);
			}

			if (
				newId &&
				newId !== oldId
			) {
				// FreshChat
				const { fcWidget } = (window as any);
				if (!this.isAdminProxyingUser && fcWidget) {
					this.initDefaultFreshChatActivatorBtn();
					fcWidget.user.setProperties({
						cf_teamId: newTeam.id,
						cf_teamName: newTeam.name,
						cf_teamRegion: newTeam.region,
						cf_teamIsActive: newTeam.isActive ? 'Yes' : 'No',
						cf_teamNumAdmins: _keys(newTeam.adminIds || {}).length,
						cf_teamNumCreators: _keys(newTeam.creatorIds || {}).length,
					});
				}

				// Microsoft Clarity
				const { clarity } = (window as any);
				if (clarity) {
					clarity('set', 'teamId', newTeam.id);
					clarity('set', 'teamName', newTeam.name);
					clarity('set', 'teamRegion', newTeam.region);
					clarity('set', 'teamIsActive', newTeam.isActive ? 'Yes' : 'No');
					clarity('set', 'teamNumAdmins', _keys(newTeam.adminIds || {}).length);
					clarity('set', 'teamNumCreators', _keys(newTeam.creatorIds || {}).length);
				}
			}
		},
		onUserChangeBinding (newUser: User, oldUser: User) {
			const newId = (newUser && newUser.id) || null;
			const oldId = (oldUser && oldUser.id) || null;

			if (
				newId &&
				newId !== oldId
			) {
				this.bindUserBalanceAccount(newUser);
				this.bindStripeCustomer(newUser);
				this.bindCurrSessions(newUser);
				this.bindAppOffers(newUser);
				this.bindHardwareOffers(newUser);
				this.bindSoftwareOffers(newUser);
				this.bindExtensionOffers(newUser);

				// FreshChat
				const { fcWidget } = (window as any);
				if (!this.isAdminProxyingUser && fcWidget) {
					this.initDefaultFreshChatActivatorBtn();
					fcWidget.setExternalId(newUser.id);
					fcWidget.user.setEmail(newUser.email);
					fcWidget.user.setFirstName(newUser.email);
					fcWidget.user.setProperties({
						cf_last_visited: moment().toISOString(),
						created_at: moment(newUser.createdAt.toMillis()).toISOString(),
						cf_creatorsClub: newUser.club && newUser.club.startsWith('CC-') ? 'Yes' : 'No',
						cf_club: newUser.club,
						cf_discordUsername: newUser.discordUser ? newUser.discordUser.username : null,
						cf_region: newUser.region,
						cf_storageUuid: newUser.uuid,
						cf_isTrial: newUser.trial ? 'Yes' : 'No',
						cf_numTeams: _keys(newUser.teamIds || {}).length,
					});
				}

				// Microsoft Clarity
				const { clarity } = (window as any);
				if (clarity) {
					clarity('identify', newUser.email, newUser.id);
					clarity('set', 'createdAt', moment(newUser.createdAt.toMillis()).toISOString());
					clarity('set', 'creatorsClub', newUser.club && newUser.club.startsWith('CC-') ? 'Yes' : 'No');
					clarity('set', 'club', newUser.club);
					clarity('set', 'discordUsername', newUser.discordUser ? newUser.discordUser.username : null);
					clarity('set', 'region', newUser.region);
					clarity('set', 'storageUuid', newUser.uuid);
					clarity('set', 'isTrial', newUser.trial ? 'Yes' : 'No');
					clarity('set', 'isAdmin', newUser.isAdmin);
					clarity('set', 'testingNewTagAdded', true);
					clarity('set', 'numTeams', _keys(newUser.teamIds || {}).length);
					console.log({ isAdmin: newUser.isAdmin });
				}
			}
			if (newId) {
				this.handleTeamBindingFromUserChange(newUser);
			}
		},
		handleTeamBindingFromUserChange (user: User) {
			const lastSelectedTeamId: string = getTeamId();
			const hasOnlyOneTeam: boolean = _keys(user.teamIds || {}).length === 1;

			if (
				user &&
				hasOnlyOneTeam
			) {
				if (!this.runnitState.runnitsOwnerSelection) {
					const runnitsOwnerSelection = RUNNITS_OWNER_SELECTION.TEAM;
					setRunnitsOwnerSelection(runnitsOwnerSelection);
					this.updateRunnitState({
						runnitsOwnerSelection,
					});
				}
				const oneTeamId: string = _keys(user.teamIds)[0];
				if (this.team && this.team.id === oneTeamId) {
					return; // Leave the Team binding as it is
				}

				this.bindTeam(oneTeamId);
				this.bindStripeTeamsCustomer(oneTeamId);
				this.bindTeamBalanceAccount(oneTeamId);
				this.bindWorkspaces(oneTeamId);
				this.bindWorkshops(oneTeamId);
			} else if (
				user &&
				!_isEmpty(user.teamIds)
			) {
				if (!this.runnitState.runnitsOwnerSelection) {
					const runnitsOwnerSelection = RUNNITS_OWNER_SELECTION.TEAM;
					setRunnitsOwnerSelection(runnitsOwnerSelection);
					this.updateRunnitState({
						runnitsOwnerSelection,
					});
				}
				if (lastSelectedTeamId && user.teamIds[lastSelectedTeamId]) {
					if (this.team && this.team.id === lastSelectedTeamId) {
						return; // Leave the Team binding as it is
					}

					this.bindTeam(lastSelectedTeamId);
					this.bindStripeTeamsCustomer(lastSelectedTeamId);
					this.bindTeamBalanceAccount(lastSelectedTeamId);
					this.bindWorkspaces(lastSelectedTeamId);
					this.bindWorkshops(lastSelectedTeamId);
				} else {
					const randomTeamId = Object.keys(user.teamIds)[0];
					this.bindTeam(randomTeamId);
					this.bindStripeTeamsCustomer(randomTeamId);
					this.bindTeamBalanceAccount(randomTeamId);
					this.bindWorkspaces(randomTeamId);
					this.bindWorkshops(randomTeamId);
				}
			} else {
				if (!this.runnitState.runnitsOwnerSelection) {
					const runnitsOwnerSelection = RUNNITS_OWNER_SELECTION.USER;
					setRunnitsOwnerSelection(runnitsOwnerSelection);
					this.updateRunnitState({
						runnitsOwnerSelection,
					});
				}
				this.unbindTeam();
				this.unbindStripeTeamsCustomer();
				this.unbindTeamBalanceAccount();
				this.unbindWorkspaces();
				this.unbindWorkshops();
			}
		},
		async onExitProxy () {
			try {
				if (this.isAdminProxyingUser) {
					const proxyAdminToken: string = getProxyAdminToken();
					if (!proxyAdminToken) return;
					const userCredential: firebase.auth.UserCredential = await firebase.auth().signInWithCustomToken(proxyAdminToken);
					this.setAdminUserProxyTokens({ proxyAdminToken: null, proxyAdminIdToken: null });
					const routeData = this.$router.resolve({ name: 'AdminUsersManagement' });
					window.open(routeData.href, '_self');
				}
			} catch (e) {
				console.error(e);
				this.logout(false);
			}
		},
		async connectTeamToTeamMemberSignup () {
			if (this.addingToTeam) return;
			const onError: Function = (e) => {
				console.error('Error finishing connecting the team member to the team: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Problem when connecting you to your team, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
					timeout: 30000,
				});
			};

			try {
				this.addingToTeam = true;
				this.updateLoader({
					show: true,
					message: 'Accepting team invitation, please wait...',
				});
				const teamId: string = this._get(this.loginQueryParamsData, 'teamId', null);
				const inviteCode: string = this._get(this.loginQueryParamsData, 'inviteCode', null);
				const functionRef = functions
					.httpsCallable('finishTeamMemberSignup');
				const { success, teamName } = (await functionRef({
					teamId,
					inviteCode,
					teamMemberEmail: this.user.email,
				})).data;

				if (!success) {
					onError(new Error('finishTeamMemberSignup did not return success'));
				} else {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! Team member invitation accepted.`,
						show: true,
					});
					this.bindTeam(teamId);
					this.bindStripeTeamsCustomer(teamId);
					this.bindTeamBalanceAccount(teamId);
					this.bindWorkspaces(teamId);
					this.bindWorkshops(teamId);
				}
			} catch (e) {
				onError(e);
			} finally {
				this.addingToTeam = false;
				this.updateLoader({
					show: false,
				});
				this.updateLoginQueryParamsData({
					teamId: null,
					inviteCode: null,
				});
			}
		},
		onCloseAllDrawers () {
			this.closeAllDrawers();
			this.menuResetTrigger = this.menuResetTrigger + 1;
		},
		closeLogoutDialog () {
			this.updateToolbar({
				logoutDialogOpen: false,
				needReauthenticateDialogOpen: false,
			});
		},
		async logout (doLocationReload: boolean) {
			this.loggingOut = true;
			try {
				this.setAdminUserProxyTokens({ proxyAdminToken: null, proxyAdminIdToken: null });
				await firebase.auth().signOut();

				// Hard reload the window.location, this will clear & unbind all vuex state
				if (doLocationReload) {
					console.log('Reloading window: 3'); // TODO remove after bug fixed
					window.location.reload();
				} else {
					console.log('Reloading window: 4'); // TODO remove after bug fixed
					window.location.assign(`${window.location.origin}/login?${encodeURIComponent(this.$route.fullPath)}`);
				}

				// Don't set this.loggingOut to true so the button can remain in the loading state
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Uh oh! Error logging out, try again.',
					show: true,
				});
				this.loggingOut = false;
			}
		},
		async doDeviceReport () {
			if (this.isAdminProxyingUser) return;

			try {
				// Fingerprint JS
				let fingerprint: Fingerprint = null;
				try {
					const fingerprintAgent = await FingerprintJs.load();
					fingerprint = await fingerprintAgent.get() || null;
					if (fingerprint) {
						fingerprint = {
							// Strip off "components" field, because firebase wouldn't let us save it in the db
							visitorId: fingerprint.visitorId,
							confidence: fingerprint.confidence,
							version: fingerprint.version,
						};
					}
				} catch (e) {
					console.error(e);
				}

				// Capture sign in data
				let ip: string = null;
				try {
					const functionRef = functions
						.httpsCallable('captureSignInData');
					const { success, ip: _ip } = (await functionRef({
						fingerprint,
					})).data;
					if (success && _ip) ip = _ip;
				} catch (e) {
					console.error(e);
				}

				// Set to store
				this.setDeviceReport({
					fingerprint,
					ip: ip,
				});
			} catch (e) {
				console.error(e);
			}
		},
		closeAccountMenu () {
			this.updateToolbar({
				accountMenuOpen: false,
			});
		},
		onResourcesDialogClose () {
			this.updateToolbar({
				resourcesDialog: {
					open: false,
					appOffer: null,
				},
			});
		},
		openRunnitSideNav () {
			this.updateToolbar({
				runnitsSideNavOpen: true,
			});
		},
	},
	components: {
		UnlockFreemium,
		RunnitsHorizontalLogoSVG,
		RunnitSideNav,
		Resources,
		NotificationsContainer,
		NotificationsBtn,
		FileBrowserBtn,
		ServerBuddy,
		SessionBar,
		AdminUserStats,
		UnsupportedDialog,
		UpsellCreatorsClubDialog,
		UpsellRunnitsPlanDialog,
		AccountMenuBtn,
		GetSupportBtn,
		SettingsBtn,
		FileBrowserNewTabBtn,
		GlobalSCSS,
		RunnitsSCSS,
		BaseDrawerSCSS,
		NewUserLoginPromoDialog,
		StorageCapCard,
		MultipleSessions,
		TimeExpiringDialog,
		BalanceCard,
		DrawerDivider,
		GlobalSnackbar,
		GlobalLoader,
		UserAccount,
		Toolbar,
		ComplexBackgroundMobileToolbar,
		ConfigureRunnit,
	},
});
