import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"full-width"},[_c('menu-or-bottom-sheet',{attrs:{"bottom":"","nudge-bottom":25,"disabled":_vm.disabled},on:{"input":_vm.onMenuOrBottomSheetInput},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [_c(VTextField,_vm._g({class:{
					required: _vm.required,
					'clickable-v-input': !_vm.disabled,
				},attrs:{"id":"record-opt-in","autocomplete":"off","label":_vm.label,"disabled":_vm.disabled,"value":_vm.selectedTime,"color":"primary","readonly":"","required":_vm.required,"rules":_vm.required ? _vm.rules.required : [],"error":!!_vm.errorMessage,"error-messages":_vm.errorMessage,"hint":_vm.hint,"persistent-hint":!!_vm.hint,"prepend-icon":_vm.prependIcon}},on))]}},{key:"content",fn:function({ close }){return [_c('div',{staticClass:"time-picker-select-content-container global-glass-background-nb",style:({
					'border-top-left-radius': _vm.$vuetify.breakpoint.xsOnly ? 'var(--global-border-radius)' : '0',
					'border-top-right-radius': _vm.$vuetify.breakpoint.xsOnly ? 'var(--global-border-radius)' : '0',
					'min-height': _vm.$vuetify.breakpoint.xsOnly ? '50vh' : null,
				})},[_c('div',{staticClass:"full-height d-flex flex-column justify-space-between"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticStyle:{"flex":"1"}},[_c(VList,{staticClass:"px-1",staticStyle:{"background":"transparent"}},[_c(VListItem,{staticClass:"time-picker-select-item pa-0 glassmorphism-background",attrs:{"ripple":false}},[_c(VListItemContent,{staticClass:"pa-1"},[_c(VListItemTitle,{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(_vm.hour))])])],1)],1)],1),_c('scroll',{attrs:{"scroll-root-id":_vm.hourListIdScope}},[_c(VList,{staticClass:"px-1",staticStyle:{"max-height":"calc(53vh - 100px)","background":"transparent"},attrs:{"slot":"scrollable"},slot:"scrollable"},_vm._l((_vm.HOUR_ITEMS),function(item){return _c(VListItem,{key:item,staticClass:"time-picker-select-item mb-2 pa-0",class:{
											...(item === _vm.hour && {
												'white--text': item === _vm.hour,
											}),
										},style:({
											...(item === _vm.hour && {
												'background-color': 'var(--primary-blue)',
												'border-radius': '4px',
											}),
										}),attrs:{"id":_vm.getHourButtonId(item)},on:{"click":function($event){return _vm.onTimeChange(item, _vm.min, _vm.amPm)}}},[_c(VListItemContent,{staticClass:"pa-1"},[_c(VListItemTitle,{staticClass:"text-center"},[_c('strong',{class:{
														...(item === _vm.hour && {
															'white--text': item === _vm.hour,
														}),
													}},[_vm._v(_vm._s(item || '--'))])])],1)],1)}),1)],1)],1),_c('div',{staticStyle:{"flex":"1"}},[_c(VList,{staticClass:"px-1",staticStyle:{"background":"transparent"}},[_c(VListItem,{staticClass:"time-picker-select-item pa-0 glassmorphism-background",attrs:{"ripple":false}},[_c(VListItemContent,{staticClass:"pa-1"},[_c(VListItemTitle,{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(_vm.min))])])],1)],1)],1),_c('scroll',{staticStyle:{"flex":"1"},attrs:{"scroll-root-id":_vm.minuteListIdScope}},[_c(VList,{staticClass:"px-1",staticStyle:{"max-height":"calc(53vh - 100px)","background":"transparent"},attrs:{"slot":"scrollable"},slot:"scrollable"},_vm._l((_vm.minuteSelectItems),function(item){return _c(VListItem,{key:item,staticClass:"time-picker-select-item mb-2 pa-0",style:({
											...(item === _vm.min && {
												'background-color': 'var(--primary-blue)',
												'border-radius': '4px',
											}),
										}),attrs:{"id":_vm.getMinuteButtonId(item)},on:{"click":function($event){return _vm.onTimeChange(_vm.hour, item, _vm.amPm)}}},[_c(VListItemContent,{staticClass:"pa-1"},[_c(VListItemTitle,{staticClass:"text-center"},[_c('strong',{class:{
														...(item === _vm.min && {
															'white--text': item === _vm.min,
														}),
													}},[_vm._v(_vm._s(item || '--'))])])],1)],1)}),1)],1)],1),_c('div',{staticStyle:{"flex":"1"}},[_c(VList,{staticClass:"px-1",staticStyle:{"background":"transparent"}},[_c(VListItem,{staticClass:"time-picker-select-item pa-0 glassmorphism-background",on:{"click":function($event){return _vm.onTimeChange(_vm.hour, _vm.min, _vm.amPm === _vm.AM_PM.AM ? _vm.AM_PM.PM : _vm.AM_PM.AM)}}},[_c(VListItemContent,{staticClass:"pa-1"},[_c(VListItemTitle,{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(_vm.amPm))])])],1)],1)],1),_c(VList,{staticClass:"px-1",staticStyle:{"flex":"1","background":"transparent"}},[_c(VListItem,{staticClass:"time-picker-select-item mb-2 pa-0",style:({
										...(_vm.amPm === _vm.AM_PM.AM && {
											'background-color': 'var(--primary-blue)',
											'border-radius': '4px',
										}),
									}),on:{"click":function($event){return _vm.onTimeChange(_vm.hour, _vm.min, _vm.AM_PM.AM)}}},[_c(VListItemContent,{staticClass:"pa-1"},[_c(VListItemTitle,{staticClass:"text-center"},[_c('strong',{class:{
													...(_vm.amPm === _vm.AM_PM.AM && {
														'white--text': _vm.amPm === _vm.AM_PM.AM,
													}),
												}},[_vm._v(_vm._s(_vm.AM_PM.AM))])])],1)],1),_c(VListItem,{staticClass:"time-picker-select-item mb-2 pa-0",style:({
										...(_vm.amPm === _vm.AM_PM.PM && {
											'background-color': 'var(--primary-blue)',
											'border-radius': '4px',
										}),
									}),on:{"click":function($event){return _vm.onTimeChange(_vm.hour, _vm.min, _vm.AM_PM.PM)}}},[_c(VListItemContent,{staticClass:"pa-1"},[_c(VListItemTitle,{staticClass:"text-center"},[_c('strong',{class:{
													...(_vm.amPm === _vm.AM_PM.PM && {
														'white--text': _vm.amPm === _vm.AM_PM.PM,
													}),
												}},[_vm._v(_vm._s(_vm.AM_PM.PM))])])],1)],1)],1)],1)]),_c('div',{staticClass:"d-flex justify-center full-width glassmorphism-background",staticStyle:{"padding":"7px 0","margin":"10px 0 0 0","border":"1px solid var(--grey-700)","border-radius":"50px"}},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.onClearClick(close)}}},[_vm._v("Clear")]),_c('base-button',{staticClass:"white--text elevation-0 px-5",on:{"click":function($event){return _vm.onDoneClick(close)}}},[_vm._v("Select")])],1)])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }