import _isArray from 'lodash/isArray';
import _isString from 'lodash/isString';
import _remove from 'lodash/remove';
import { RunnitsOwnerSelection } from '@/store';
import { RunnitsAccessLevel } from '@run-diffusion/shared';

const CIV_MODEL_ID: string = 'CIV_MODEL_ID';
const CIV_MODEL_VERSION_ID: string = 'CIV_MODEL_VERSION_ID';
const SESSION_SOUNDS_OFF: string = 'SESSION_SOUNDS_OFF';
const SELECTED_STORAGE_TYPE: string = 'SELECTED_STORAGE_TYPE';
const TEAM_ID: string = 'TEAM_ID';
const WORKSPACE_ID: string = 'WORKSPACE_ID';
const ANNOUNCEMENT_DISMISSED: string = 'ANNOUNCEMENT_DISMISSED';
const COMFY_ONBOARDING_DISMISSED: string = 'COMFY_ONBOARDING_DISMISSED';
const PERSIST_EXTENSIONS: string = 'PERSIST_EXTENSIONS';
const LAST_PRICE_ID: string = 'LAST_PRICE_ID';
const PROXY_ADMIN_TOKEN: string = 'PROXY_ADMIN_TOKEN';
const PROXY_ADMIN_ID_TOKEN: string = 'PROXY_ADMIN_ID_TOKEN';
const LAST_SSO_PROVIDER_VAL: string = 'LAST_SSO_PROVIDER_VAL';

// RUNNITS - LOCAL STORAGE
const RUNNITS_SELECTED_IMAGE_MODE: string = 'RUNNITS_SELECTED_IMAGE_MODE';
const RUNNITS_SELECTED_COLUMNS: string = 'RUNNITS_SELECTED_COLUMNS';
const RUNNITS_OWNER_SELECTION: string = 'RUNNITS_OWNER_SELECTION';
const RUNNITS_TEAM_ACCESS_LEVEL: string = 'RUNNITS_TEAM_ACCESS_LEVEL';
const RUNNITS_INPAINTING_IS_EXPANDED: string = 'RUNNITS_INPAINTING_IS_EXPANDED';

// Helper values
const trueVal: string = '1';

// Helper functions
const lsGet = (key: string) => {
	if (window && window.localStorage && window.localStorage.getItem) {
		try {
			return window.localStorage.getItem(key);
		} catch (e) {
			// If the browser is incognito, this will error
			console.error(e);
			return null;
		}
	}
	return null;
};
const lsSet = (key: string, value: string) => {
	if (window && window.localStorage && window.localStorage.setItem) {
		try {
			window.localStorage.setItem(key, value);
		} catch (e) {
			// If the browser is incognito, this will error
			console.error(e);
		}
	}
};
const lsRemove = (key: string) => {
	if (window && window.localStorage && window.localStorage.removeItem) {
		try {
			window.localStorage.removeItem(key);
		} catch (e) {
			// If the browser is incognito, this will error
			console.error(e);
		}
	}
};

// CIV_MODEL_ID
export const setCivitaiModelId = (modelId: string): void => {
	lsSet(CIV_MODEL_ID, modelId);
};
export const getCivitaiModelId = (): string => lsGet(CIV_MODEL_ID);
export const removeCivitaiModelId = (): void => {
	lsRemove(CIV_MODEL_ID);
};

// CIV_MODEL_VERSION_ID
export const setCivitaiModelVersionId = (modelVersionId: string): void => {
	lsSet(CIV_MODEL_VERSION_ID, modelVersionId);
};
export const getCivitaiModelVersionId = (): string => lsGet(CIV_MODEL_VERSION_ID);
export const removeCivitaiModelVersionId = (): void => {
	lsRemove(CIV_MODEL_VERSION_ID);
};

// SESSION_SOUNDS_OFF
export const setSessionSoundsOff = (): void => {
	lsSet(SESSION_SOUNDS_OFF, trueVal);
};
export const getSessionSoundsOff = (): boolean => lsGet(SESSION_SOUNDS_OFF) === trueVal;
export const removeSessionSoundsOff = (): void => {
	lsRemove(SESSION_SOUNDS_OFF);
};

// SELECTED_STORAGE_TYPE
export const setSelectedStorageType = (selectedStorageType: string): void => {
	lsSet(SELECTED_STORAGE_TYPE, selectedStorageType);
};
export const getSelectedStorageType = (): string => lsGet(SELECTED_STORAGE_TYPE);
export const removeSelectedStorageType = (): void => {
	lsRemove(SELECTED_STORAGE_TYPE);
};

// TEAM_ID
export const setTeamId = (teamId: string): void => {
	lsSet(TEAM_ID, teamId);
};
export const getTeamId = (): string => lsGet(TEAM_ID);
export const removeTeamId = (): void => {
	lsRemove(TEAM_ID);
};

// WORKSPACE_ID
export const setWorkspaceId = (workspaceId: string): void => {
	lsSet(WORKSPACE_ID, workspaceId);
};
export const getWorkspaceId = (): string => lsGet(WORKSPACE_ID);
export const removeWorkspaceId = (): void => {
	lsRemove(WORKSPACE_ID);
};

// ANNOUNCEMENT_DISMISSED
export const setAnnouncementDismissed = (message: string, userId: string): void => {
	if (userId && message) {
		const existingDismissedAnnouncements: string[] = getAnnouncementsDismissed(userId);
		lsSet(
			`${ANNOUNCEMENT_DISMISSED}-${userId}`,
			JSON.stringify(
				[...existingDismissedAnnouncements, message].filter((message: string) => _isString(message)),
			),
		);
	}
};
export const getAnnouncementsDismissed = (userId: string): string[] => {
	if (userId) {
		const stringifiedDismissedAnnouncements: string = lsGet(`${ANNOUNCEMENT_DISMISSED}-${userId}`);

		try {
			const dismissedAnnouncements = JSON.parse(stringifiedDismissedAnnouncements);
			if (_isArray(dismissedAnnouncements)) {
				return dismissedAnnouncements.filter((message: string) => _isString(message));
			}
		} catch (e) {
			// continue on to remove the local storage value and return empty value;
		}
	}

	return [];
};
export const removeAnnouncementDismissed = (message: string, userId: string): void => {
	if (userId && message) {
		const existingDismissedAnnouncements: string[] = getAnnouncementsDismissed(userId);
		lsSet(
			`${ANNOUNCEMENT_DISMISSED}-${userId}`,
			JSON.stringify(
				_remove(existingDismissedAnnouncements, (msg: string) => msg === message),
			),
		);
	}
};

// COMFY_ONBOARDING_DISMISSED
export const setComfyOnboardingDismissed = (): void => {
	lsSet(COMFY_ONBOARDING_DISMISSED, trueVal);
};
export const getComfyOnboardingDismissed = (): boolean => lsGet(COMFY_ONBOARDING_DISMISSED) === trueVal;
export const removeComfyOnboardingDismissed = (): void => {
	lsRemove(COMFY_ONBOARDING_DISMISSED);
};

// PERSIST_EXTENSIONS
export const setPersistExtensions = (): void => {
	lsSet(PERSIST_EXTENSIONS, trueVal);
};
export const getPersistExtensions = (): boolean => lsGet(PERSIST_EXTENSIONS) === trueVal;
export const removePersistExtensions = (): void => {
	lsRemove(PERSIST_EXTENSIONS);
};

// LAST_PRICE_ID
export const setLastPriceId = (priceId: string): void => {
	lsSet(LAST_PRICE_ID, priceId);
};
export const getLastPriceId = (): string => lsGet(LAST_PRICE_ID);
export const removeLastPriceId = (): void => {
	lsRemove(LAST_PRICE_ID);
};

// PROXY_ADMIN_TOKEN
export const setProxyAdminToken = (token: string): void => {
	lsSet(PROXY_ADMIN_TOKEN, token);
};
export const getProxyAdminToken = (): string => lsGet(PROXY_ADMIN_TOKEN);
export const removeProxyAdminToken = (): void => {
	lsRemove(PROXY_ADMIN_TOKEN);
};

// PROXY_ADMIN_ID_TOKEN
export const setProxyAdminIdToken = (token: string): void => {
	lsSet(PROXY_ADMIN_ID_TOKEN, token);
};
export const getProxyAdminIdToken = (): string => lsGet(PROXY_ADMIN_ID_TOKEN);
export const removeProxyAdminIdToken = (): void => {
	lsRemove(PROXY_ADMIN_ID_TOKEN);
};

// LAST_SSO_PROVIDER_VAL
export const setLastSsoProviderVal = (ssoProviderVal: string): void => {
	lsSet(LAST_SSO_PROVIDER_VAL, ssoProviderVal);
};
export const getLastSsoProviderVal = (): string => lsGet(LAST_SSO_PROVIDER_VAL);
export const removeLastSsoProviderVal = (): void => {
	lsRemove(LAST_SSO_PROVIDER_VAL);
};

/**
 *
 * RUNNITS - LOCAL STORAGE
 *
 */

// RUNNITS_SELECTED_IMAGE_MODE
export const setRunnitsSelectedImageModeVal = (selectedImageMode: string): void => {
	lsSet(RUNNITS_SELECTED_IMAGE_MODE, selectedImageMode);
};
export const getRunnitsSelectedImageModeVal = (): string => lsGet(RUNNITS_SELECTED_IMAGE_MODE);
export const removeRunnitsSelectedImageModeVal = (): void => {
	lsRemove(RUNNITS_SELECTED_IMAGE_MODE);
};

// RUNNITS_SELECTED_COLUMNS
export const setRunnitsSelectedColumnsVal = (selectedColumns: number): void => {
	lsSet(RUNNITS_SELECTED_COLUMNS, selectedColumns.toString());
};
export const getRunnitsSelectedColumnsVal = (): number => Number(lsGet(RUNNITS_SELECTED_COLUMNS));
export const removeRunnitsSelectedColumnsVal = (): void => {
	lsRemove(RUNNITS_SELECTED_COLUMNS);
};

export const setRunnitsOwnerSelection = (option: RunnitsOwnerSelection): void => {
	lsSet(RUNNITS_OWNER_SELECTION, option);
};
export const getRunnitsOwnerSelection = (): RunnitsOwnerSelection => lsGet(RUNNITS_OWNER_SELECTION) as RunnitsOwnerSelection;
export const removeRunnitsOwnerSelection = (): void => {
	lsRemove(RUNNITS_OWNER_SELECTION);
};

export const setRunnitsTeamAccessLevel = (option: RunnitsAccessLevel): void => {
	lsSet(RUNNITS_TEAM_ACCESS_LEVEL, option);
};
export const getRunnitsTeamAccessLevel = (): RunnitsAccessLevel => lsGet(RUNNITS_TEAM_ACCESS_LEVEL) as RunnitsAccessLevel;
export const removeRunnitsTeamAccessLevel = (): void => {
	lsRemove(RUNNITS_TEAM_ACCESS_LEVEL);
};

