import { customAlphabet } from 'nanoid';

export const DISCORD_INVITE_LINK: string = 'https://discord.gg/y7gCC3UTzK';

export const nanoIdAlphaNum: Function = (size: number): string => {
	const nums: string = '0123456789';
	const letters: string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	return customAlphabet(nums + letters)(size);
};

export const nanoIdLowerNum: Function = (size: number): string => {
	const nums: string = '0123456789';
	const letters: string = 'abcdefghijklmnopqrstuvwxyz';
	return customAlphabet(nums + letters)(size);
};

export const getModelTypeDisplayText: Function = (type: string): string => {
	const upperType: string = (type || '').toUpperCase();
	switch (upperType) {
		case 'TEXTUALINVERSION':
			return 'Textual Inversion';
		case 'AESTHETICGRADIENT':
			return 'Aesthetic Gradient';
		case 'LORA':
			return 'LoRA';
		case 'POSES':
		case 'CHECKPOINT':
		case 'HYPERNETWORK':
		default:
			return type || '';
	}
};

export const getAuthProviderDisplayText: Function = (providerId: string, shortVersion: boolean = false): string => {
	const p: string = providerId || '';
	switch (p) {
		case 'custom':
			return shortVersion ? 'Custom' : 'Custom Login';
		case 'email':
			return shortVersion ? 'Email' : 'Email Login';
		case 'password':
			return shortVersion ? 'Password' : 'Email & Password Login';
		case 'phone':
			return shortVersion ? 'Phone' : 'Phone Login';
		case 'anonymous':
			return shortVersion ? 'Password' : 'Anonymous Login';
		case 'google.com':
			return shortVersion ? 'Google' : 'Google Login';
		case 'facebook.com':
			return shortVersion ? 'Facebook' : 'Facebook Login';
		case 'github.com':
			return shortVersion ? 'GitHub' : 'GitHub Login';
		case 'twitter.com':
			return shortVersion ? 'X.com' : 'X.com Login';
		case 'microsoft.com':
			return shortVersion ? 'Microsoft' : 'Microsoft Login';
		case 'apple.com':
			return shortVersion ? 'Apple' : 'Apple Login';
		default: {
			const samlPrefix: string = 'saml.';
			if (p.startsWith(samlPrefix)) {
				if (p.length > samlPrefix.length) {
					const samlName: string = p.split('saml.')[1];
					return shortVersion ? `SSO: ${samlName}` : `Single Sign-On: ${samlName}`;
				}
				return shortVersion ? `Single Sign-On` : `Single Sign-On`;
			}
			return p || null;
		}
	}
};
