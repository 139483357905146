
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { TeamUserMixin } from '@/mixins';
import StackLayers from '@/components/base/StackLayers.vue';
import { CHECKOUT_TEAM_PLAN_SUBSCRIPTION } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'TeamSubscriptionManagement',
	mixins: [
		TeamUserMixin,
	],
	computed: {
		...mapState([
			'team',
		]),
	},
	methods: {
		...mapActions([
			'goToStripeSubscriptionPortal',
		]),
		onManageOrReactivateClick () {
			if (this.isTeamActive) {
				this.goToStripeSubscriptionPortal(this.team);
			} else {
				const routeData = this.$router.resolve({
					name: 'GoToPayment',
					query: {
						checkoutAction: CHECKOUT_TEAM_PLAN_SUBSCRIPTION,
					},
				});
				window.open(routeData.href, '_blank');
			}
		},
	},
	components: {
		StackLayers,
	},
});
