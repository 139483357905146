
import Vue from 'vue';
import StackLayers from '@/components/base/StackLayers.vue';
import { DISCORD_INVITE_LINK } from '@/utils';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'GetSupportDialog',
	props: {
		value: { type: Boolean, default: false },
		insideRunnits: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'user',
			'isAdminProxyingUser',
		]),
	},
	methods: {
		onInput (val: boolean) {
			this.$emit('input', !!val);
		},
		openLearnSite () {
			window.open('https://learn.rundiffusion.com', '_blank').focus();
		},
		onJoinDiscordServer () {
			window.open(DISCORD_INVITE_LINK, '_blank').focus();
		},
		async onStartChatSupport () {
			if (this.isAdminProxyingUser) {
				alert('You cannot open a support ticket because you are pretending as a user');
				return;
			}
			const { fcWidget } = (window as any);
			if (fcWidget) {
				if (fcWidget.isOpen()) {
					fcWidget.close();
				} else {
					const fcFrameWrapperEl: HTMLElement = document.getElementById('fc_frame');
					if (fcFrameWrapperEl) fcFrameWrapperEl.style.display = 'block';
					fcWidget.open();
				}
			}
		},
	},
	components: {
		StackLayers,
	},
});
