
import Vue from 'vue';
import _trim from 'lodash/trim';

export default Vue.extend({
	name: 'HtmlField',
	props: {
		label: { type: String, required: true },
		value: { type: String, default: null },
		disabled: { type: Boolean, default: false },
		required: { type: Boolean, default: false },
	},
	data () {
		return {
			rules: {
				required: [
					v => !!_trim(v) || 'Required',
				],
			},
		};
	},
});
