
import Vue from 'vue';
import ActionsIsland from '@/components/ActionsIsland.vue';
import ActionsIslandFixedBottom from '@/components/ActionsIslandFixedBottom.vue';
export default Vue.extend({
	name: 'BaseDrawer',
	props: {
		value: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		permanent: { type: Boolean, default: false },
		title: { type: String, default: '', },
		showActions: { type: Boolean, default: false, },
		drawerWidth: { type: Number, default: 700, },
		fixed: { type: Boolean, default: true, },
		right: { type: Boolean, default: true, },
		closeOnRight: { type: Boolean, default: false, },
		closeIcon: { type: String, default: 'mdi-arrow-right' },
		containerStyles: { type: [String, Object], default: null },
		actionsIslandStyles: { type: Object, default: () => ({ height: '60px' }) },
		actionsIslandIsFixedBottom: { type: Boolean, default: false },
	},
	data () {
		return {
			open: false,
		};
	},
	created () {
		this.setOpen(!!this.value);
	},
	watch: {
		value (newVal: boolean) {
			this.setOpen(!!this.value);
		},
	},
	methods: {
		setOpen(val: boolean) {
			if (this.disabled) return;
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
	},
	components: {
		ActionsIsland,
		ActionsIslandFixedBottom,
	},
});
