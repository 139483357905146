<template>
	<div
		style="
			position: relative;
			border-radius: var(--global-border-radius);
			min-height: 170px;
			border: 1px solid var(--grey-800);
		"
		:style="containerStyles"
		class="pr-4"
	>
		<div class="d-flex flex-row" style="overflow: hidden;">
			<div
				v-if="$vuetify.breakpoint.smAndUp"
				style="flex: 30"
			>
				<img
					v-if="isTeamUpsell"
					alt="Team Plan"
					src="../assets/team.png"
					style="position: relative; bottom: -45px; left: 30px; max-width: 100%;"
				>
				<img
					v-else-if="isCreatorsClubUpsell"
					alt="Creator's Club"
					src="../assets/art-book.png"
					style="position: relative; bottom: -55px; left: 30px; max-width: 100%;"
				>
			</div>
			<div
				class="d-flex flex-column justify-space-between"
				style="flex: 70"
				:class="{
					'pl-6': $vuetify.breakpoint.xsOnly,
					'pl-12': $vuetify.breakpoint.smAndUp,
				}"
			>
				<div class="d-flex flex-column" style="padding: 15px 0;">
					<div
						class="mb-5 mt-3"
						style="line-height: 32px; font-size: 30px; font-weight: bold;"
						:class="{
							'gradient-text': hover,
						}"
					>
						<span v-if="isTeamUpsell">{{ teamTitle }}</span>
						<span v-else-if="isCreatorsClubUpsell">{{ creatorsClubTitle }}</span>
					</div>
					<div
						v-if="isTeamUpsell"
						:class="{
							'ml-4': $vuetify.breakpoint.smAndUp,
						}"
					>
						<list-item
							icon="mdi-check-circle"
							:container-styles="{
								marginBottom: '5px',
							}"
						>
							<b :class="{ 'gradient-text': emphasizeRecurringBalance }">$20 per month</b> of promotional balance awarded
						</list-item>
						<list-item
							icon="mdi-check-circle"
							:container-styles="{
								marginBottom: '5px',
							}"
						>
							<b>2+</b> users
						</list-item>
						<list-item
							icon="mdi-check-circle"
							:container-styles="{
								marginBottom: '5px',
							}"
						>
							<b>Shared</b> private storage between team members
						</list-item>
						<list-item
							icon="mdi-check-circle"
							:container-styles="{
								marginBottom: '5px',
							}"
						>
							<b>Discount</b> on Large size hardware
						</list-item>
						<list-item
							icon="mdi-check-circle"
							:container-styles="{
								marginBottom: '5px',
							}"
						>
							Access to <b>MAX</b> size hardware
						</list-item>
						<list-item
							icon="mdi-check-circle"
							:container-styles="{
								marginBottom: 0,
							}"
						>
							And more...
						</list-item>
					</div>
					<div
						v-else-if="isCreatorsClubUpsell"
						:class="{
							'ml-4': $vuetify.breakpoint.smAndUp,
						}"
					>
						<list-item
							icon="mdi-check-circle"
							:container-styles="{
								marginBottom: '5px',
							}"
						>
							<b :class="{ 'gradient-text': emphasizeRecurringBalance }">$10 per month</b> of promotional balance awarded
						</list-item>
						<list-item
							icon="mdi-check-circle"
							:container-styles="{
								marginBottom: '5px',
							}"
						>
							<b>100GB</b> personal storage just for you
						</list-item>
						<list-item
							icon="mdi-check-circle"
							:container-styles="{
								marginBottom: '5px',
							}"
						>
							<b>Discount</b> on Large size hardware
						</list-item>
						<list-item
							icon="mdi-check-circle"
							:container-styles="{
								marginBottom: '5px',
							}"
						>
							Access to <b>MAX</b> size hardware
						</list-item>
						<list-item
							icon="mdi-check-circle"
							:container-styles="{
								marginBottom: 0,
							}"
						>
							And more...
						</list-item>
					</div>
				</div>
				<div class="d-flex justify-end mr-5">
					<base-button
						class="mt-3 mb-4"
						call-to-action
						@click="$emit('on-learn-more-click')"
					>
						Learn More
						<v-icon v-if="isTeamUpsell" class="ml-1" style="font-size: 18px;">mdi-open-in-new</v-icon>
					</base-button>
				</div>
			</div>
		</div>
		<div
			v-if="team && isTeamUpsell"
			class="d-flex justify-end align-center py-3 pr-3"
		>
			<span style="color: var(--grey-600); font-family: 'nasalization';">
				{{ team.name | truncateMiddle(25) }}
			</span>
			<team-picker>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="attrs"
						v-on="on"
						class="ml-2"
						x-small
						text
						color="primary"
					>
						Change
					</v-btn>
				</template>
			</team-picker>
		</div>
	</div>
</template>

<script>
import ListItem from '@/components/base/ListItem.vue';
import { UPSELL_TYPE } from '@/constants/constants';
import { mapState } from 'vuex';
import TeamPicker from '@/components/TeamPicker.vue';

export default {
	name: 'BorderCardUpsell',
	props: {
		containerStyles: { type: Object, default: () => {} },
		teamTitle: { type: String, default: '*Active Team Plan Required' },
		creatorsClubTitle: { type: String, default: '*Creator\'s Club Required' },
		upsellType: { type: String, required: true },
		emphasizeRecurringBalance: { type: Boolean, default: false },
		hover: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'team',
		]),
		isTeamUpsell () {
			return this.upsellType === UPSELL_TYPE.TEAM;
		},
		isCreatorsClubUpsell () {
			return this.upsellType === UPSELL_TYPE.CREATORS_CLUB;
		},
	},
	components: {
		TeamPicker,
		ListItem,
	},
};
</script>
