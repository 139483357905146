
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { APP_OFFER_IS_BETA_MESSAGE } from '@/views/Sessions/AppOffersList/utils';
import AppOfferCarousel from '@/views/Sessions/AppOffersList/AppOfferCard/AppOfferCarousel.vue';

export default Vue.extend({
	name: 'AppOfferCard',
	props: {
		appOffer: { type: Object, required: true },
	},
	data () {
		return {
			APP_OFFER_IS_BETA_MESSAGE,
		};
	},
	computed: {
		...mapState([
			'user',
			'toolbar',
		]),
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
		intersectHandler (entries) {
			if (entries.length && entries[0].isIntersecting) {
				this.$emit('on-intersect');
			}
		},
		openResources () {
			this.updateToolbar({
				resourcesDialog: {
					open: true,
					appOffer: this.appOffer,
				},
			});
		},
	},
	components: {
		AppOfferCarousel,
	},
});
