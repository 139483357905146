import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { ToolbarState } from '@/store';
import axios, { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment-timezone';
import { functions } from '@/firebase';

export const AwaitingServerUrlUi200Mixin = Vue.extend({
	data () {
		return {
			AWAITING_NON_200: 'AWAITING_NON_200',
			AWAITING_200: 'AWAITING_200',
			startAwaitingServerUrlUi200Interval: null,
			startAwaitingServerUrlUi200Timeout: null,
			startAwaitingServerUrlUi200State: null,
			startAwaitingServerUrlUiLastStatusCode: null, // number from the http head call (200, 404, etc)
		};
	},
	computed: {
		...mapState([
			'toolbar',
		]),
	},
	watch: {
		toolbar: {
			immediate: true,
			handler (newVal: ToolbarState, oldVal: ToolbarState) {
				if (
					newVal &&
					newVal !== oldVal &&
					newVal.session &&
					newVal.session.id !== this._get(oldVal, 'session.id', null)
				) {
					this.stopAwaitingServerUrlUi200LoopInterval();
				}
			},
		},
	},
	methods: {
		...mapActions([
			'incrementReloadSessionUiIframeTrigger',
		]),
		stopAwaitingServerUrlUi200LoopInterval () {
			// Define a function to end interval and refresh UI iframe
			if (this.startAwaitingServerUrlUi200Interval) clearInterval(this.startAwaitingServerUrlUi200Interval);
			if (this.startAwaitingServerUrlUi200Timeout) clearTimeout(this.startAwaitingServerUrlUi200Timeout);

			this.startAwaitingServerUrlUi200State = null;
		},
		async startAwaitingServerUrlUi200Loop (initialState: string) {
			if (this.startAwaitingServerUrlUi200Interval) clearInterval(this.startAwaitingServerUrlUi200Interval);
			if (this.startAwaitingServerUrlUi200Timeout) clearTimeout(this.startAwaitingServerUrlUi200Timeout);

			if (
				!this.toolbar.session.serverUrlUi ||
				// Ignore FileBrowser URL cuz there isn't a way to add a CORS header in FB on the server side
				this.toolbar.session.serverUrlUi === this.toolbar.session.serverUrlFileBrowser
			) {
				return;
			}

			this.startAwaitingServerUrlUi200State = initialState || this.AWAITING_200;

			this.startAwaitingServerUrlUi200Timeout = setTimeout(() => {
				// Timeout the interval after 10 minutes
				this.stopAwaitingServerUrlUi200LoopInterval();
			}, 10 * 60 * 1000);

			// Define a function to check the URL and stop the interval if a 200 response is received from the UI
			const checkUrlStartMoment: Moment = moment();
			let hasReportedNon200UiUrl: boolean = false;
			const checkUrl: Function = async () => {
				try {
					const response: AxiosResponse = await axios.head(this.toolbar.session.serverUrlUi);
					this.startAwaitingServerUrlUiLastStatusCode = response.status;

					// Process status code from ping response
					if (
						this.startAwaitingServerUrlUi200State === this.AWAITING_200 &&
						this.startAwaitingServerUrlUiLastStatusCode === 200
					) {
						this.incrementReloadSessionUiIframeTrigger();
						this.stopAwaitingServerUrlUi200LoopInterval();
					} else if (
						this.startAwaitingServerUrlUi200State === this.AWAITING_NON_200 &&
						this.startAwaitingServerUrlUiLastStatusCode !== 200
					) {
						this.startAwaitingServerUrlUi200State = this.AWAITING_200;
					}
				} catch (e) {
					// ignored error
					if (this.startAwaitingServerUrlUi200State === this.AWAITING_NON_200) {
						this.startAwaitingServerUrlUi200State = this.AWAITING_200;
					}
				}

				// Report to staff after awaiting a 200 ping response for too long
				if (
					!hasReportedNon200UiUrl &&
					checkUrlStartMoment.isBefore(moment().subtract(2, 'minutes')) &&
					this.startAwaitingServerUrlUi200State === this.AWAITING_200 &&
					this.startAwaitingServerUrlUiLastStatusCode !== 200
				) {
					hasReportedNon200UiUrl = true;
					const functionRef = functions
						.httpsCallable('reportNon200UiUrl');
					await functionRef({
						sessionId: this.toolbar.session.id,
						statusCode: this.startAwaitingServerUrlUiLastStatusCode,
					});
				}
			};
			this.startAwaitingServerUrlUi200Interval = setInterval(checkUrl, 1000);
			await checkUrl();
		},
	},
});
