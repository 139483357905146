
import Vue from 'vue';

export default Vue.extend({
	props: {
		iconName: {
			type: String,
			default: 'Runnits Logo',
		},
		width: {
			type: [Number, String],
			default: 546,
		},
		height: {
			type: [Number, String],
			default: 376,
		},
		viewBox: {
			type: String,
			default: '0 0 546 376',
		},
		fill: {
			type: String,
			default: 'black',
		},
	},
});
