import { VueConstructor } from 'vue';
import { getSessionSoundsOff } from '@/utils/localStorage';

const SoundSilence = require('@/sounds/500-milliseconds-of-silence.mp3');
const SoundSoftwareInterfaceRemove = require('@/sounds/software-interface-remove-2576.wav');
const SoundUpliftingBellsNotification = require('@/sounds/uplifting-bells-notification-938.wav');
const SoundTickTockClockTimer = require('@/sounds/tick-tock-clock-timer-1048.wav');

export default {
	install (vue: VueConstructor) {
		let audio: HTMLAudioElement = null;
		let pauseTimeout = null;
		let bodyListener = null;
		let bodyListenerTimeout = null;

		const firstPlay: Function = (sound: string, volume: number): void => {
			if (!audio) {
				audio = new Audio(sound);
				audio.style.display = 'none';
				audio.volume = volume;
				audio.play();
			}
		};
		const subsequentPlay: Function = (sound: string, volume: number): void => {
			audio.src = sound;
			audio.volume = volume;
			audio.play();
		};
		const pause: Function = (): void => {
			if (audio) {
				audio.pause();
			}
		};

		vue.prototype.$autoSoundMachine = {
			init: (): void => {
				/*
				Should be initiated from a click user action.
				The browsers force you to initiate the audio from a user interaction (a click event).
				This registers the audio with a silent sound, then we can change the src later without a user interaction.
				 */
				bodyListener = () => {
					firstPlay(SoundSilence, 1);

					if (bodyListenerTimeout) clearTimeout(bodyListenerTimeout);
					bodyListenerTimeout = setTimeout(() => {
						document.body.removeEventListener('click', bodyListener);
					}, 100);
				};
				window.focus();
				document.body.addEventListener('click', bodyListener);
			},
			isInitialized: (): boolean => {
				return !!audio;
			},
			pause,

			/*
			Session sounds
			 */
			playSessionStart: (previewMode: boolean): void => {
				if (!previewMode && getSessionSoundsOff()) return;

				const volume: number = 0.45;
				if (!audio) {
					firstPlay(SoundUpliftingBellsNotification, volume);
				} else {
					subsequentPlay(SoundUpliftingBellsNotification, volume);
				}
			},
			playSessionEndsInMinutes: (previewMode: boolean): void => {
				if (!previewMode && getSessionSoundsOff()) return;

				const volume: number = 1;
				if (!audio) {
					firstPlay(SoundSoftwareInterfaceRemove, volume);
				} else {
					subsequentPlay(SoundSoftwareInterfaceRemove, volume);
				}
			},
			playSessionEndsInSeconds: (previewMode: boolean, pauseMillis: number): void => {
				if (!previewMode && getSessionSoundsOff()) return;

				const volume: number = 1;
				if (!audio) {
					firstPlay(SoundTickTockClockTimer, volume);
				} else {
					subsequentPlay(SoundTickTockClockTimer, volume);
				}

				if (previewMode && pauseMillis > 0) {
					if (pauseTimeout) clearTimeout(pauseTimeout);
					pauseTimeout = setTimeout(() => {
						pause();
					}, pauseMillis);
				}
			},
		};
	}
};
