
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { AppKey, AppOffer } from '@run-diffusion/shared';
import ErrorState from '@/components/states/ErrorState.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import NewUserWelcomeDialog from '@/components/PromoBanners/NewUserWelcomeDialog/NewUserWelcomeDialog.vue';
import AppOfferCard from '@/views/Sessions/AppOffersList/AppOfferCard/AppOfferCard.vue';

export default Vue.extend({
	name: 'AppOffersList',
	props: {
		selectionMode: { type: Boolean, default: false },
	},
	data () {
		return {
			loading: false, // TODO: loading state with Vuex
			hasError: false, // TODO: error state with Vuex
			intersectAppKey: null,
			canSetIntersectAppOffer: false,
			newUserWelcomeDialogOpen: false,

			resourcesDialog: {
				open: false,
				appOffer: null,
			},
		};
	},
	computed: {
		...mapState([
			'team',
			'user',
			'appOffers',
			'loadingUser',
			'loadingTeam',
		]),
		...mapGetters([
			'isNewUserTrialFlow',
		]),
	},
	watch: {
		isNewUserTrialFlow: {
			immediate: true,
			handler (newVal: boolean, oldVal: boolean) {
				if (newVal !== oldVal) {
					this.initNewUserWelcomeDialogOpen(newVal);
				}
			},
		},
		appOffers (newVal: AppOffer[], oldVal: AppOffer[]) {
			if (!this.intersectAppKey && newVal && newVal.length && !(oldVal && oldVal.length)) {
				this.intersectAppKey = newVal[0].app;
				setTimeout(() => {
					this.canSetIntersectAppOffer = true;
				}, 500);
			}
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		initNewUserWelcomeDialogOpen (isNewUserTrialFlow: boolean) {
			this.newUserWelcomeDialogOpen = !!isNewUserTrialFlow || this.newUserWelcomeDialogOpen;
		},
		scrollToAppOffer (app: AppKey) {
			this.$scrollTo(`#app-offer-${app}`, 500, {
				offset: -120,
			});
		},
		onAppOfferCardIntersect (appOffer: AppOffer) {
			if (appOffer && this.canSetIntersectAppOffer) {
				this.intersectAppKey = appOffer.app;
			}
		},
		onNewUserWelcomeDialogAppKeySelect (app: AppKey) {
			this.routeToSetupSession(app);
		},
		onAppOfferSelect (appOffer: AppOffer) {
			if (this.selectionMode) {
				this.$emit('on-app-offer-select', appOffer);
			} else {
				this.routeToSetupSession(appOffer.app);
			}
		},
		routeToSetupSession (app: AppKey) {
			if (this.$route.name === 'AnotherSession') {
				this.routerPush(this.$route, this.$router, { name: 'SetupAnotherSession', query: { app } });
			} else {
				this.routerPush(this.$route, this.$router, { name: 'SetupSession', query: { app } });
			}
		},
	},
	components: {
		AppOfferCard,
		EmptyState,
		LoadingState,
		ErrorState,
		NewUserWelcomeDialog,
	},
});
