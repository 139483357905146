
import Vue from 'vue';
import { mapState } from 'vuex';
import BackButton from '@/components/base/BackButton.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import RegularSessionsDashboard from '@/views/Admin/AdminSessionsDashboard/RegularSessionsDashboard.vue';
import DiscordClusterSessionsDashboard from '@/views/Admin/AdminSessionsDashboard/DiscordClusterSessionsDashboard.vue';

export default Vue.extend({
	name: 'AdminSessionsDashboard',
	data () {
		return {
			REGULAR_DASHBOARD_TYPE: 'reg',
			BOT_CLUSTER_DASHBOARD_TYPE: 'bot',
			dashboardTypeVal: null,
		};
	},
	computed: {
		...mapState([
			'user',
		]),
	},
	methods: {
		goBack () {
			if (this.hasRouteHistory) {
				this.$router.back();
			} else {
				const hasCurrentSessionId: boolean = !!(this._get(this.toolbar, 'session.id'));

				if (hasCurrentSessionId) {
					this.routerPush(this.$route, this.$router, { name: 'Launch', params: { sessionId: this._get(this.toolbar, 'session.id') } });
				} else {
					this.routerPush(this.$route, this.$router, { name: 'Sessions' });
				}
			}
		},
	},
	components: {
		DiscordClusterSessionsDashboard,
		RegularSessionsDashboard,
		EmptyState,
		BackButton,
	},
});
