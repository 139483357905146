import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"workshop-session-card tw-shadow-bp-md"},[_c('div',{staticClass:"d-flex justify-space-between full-width full-height"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"8"}},[_c('div',{staticClass:"d-flex flex-column justify-space-between",style:({
							padding: _vm.$vuetify.breakpoint.xsOnly ? '25px 25px 0 25px' : '25px 0 25px 25px',
						})},[_c('div',{staticClass:"workshop-session-card__title gradient-text"},[_vm._v(" "+_vm._s(_vm.workshopSession.displayName)+" ")]),_c('div',{staticClass:"d-flex mt-7"},[_c('div',{staticClass:"d-flex flex-column flex-wrap",staticStyle:{"margin":"0 45px 0 0"}},[_c('div',{staticClass:"workshop-session-card-sub-header"},[_vm._v(" App ")]),_c('div',{staticClass:"workshop-session-card-sub-value"},[(_vm.appOffer)?_c('span',[_vm._v(_vm._s(_vm.appOffer.shortLabel))]):_c('span',{staticClass:"error--text"},[_c(VIcon,{attrs:{"left":"","color":"error"}},[_vm._v("mdi-alert-outline")]),_vm._v("Edit to select")],1)])]),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"margin":"0 45px 0 0"}},[_c('div',{staticClass:"workshop-session-card-sub-header"},[_vm._v(" Hardware ")]),_c('div',{staticClass:"workshop-session-card-sub-value"},[(_vm.hardwareOffer)?_c('span',[_vm._v(_vm._s(_vm.hardwareOffer.shortLabel))]):_c('span',{staticClass:"error--text"},[_c(VIcon,{attrs:{"left":"","color":"error"}},[_vm._v("mdi-alert-outline")]),_vm._v("Edit to select")],1)])]),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"workshop-session-card-sub-header"},[_vm._v(" Software ")]),_c('div',{staticClass:"workshop-session-card-sub-value"},[(_vm.softwareOffer)?_c('span',[_vm._v(_vm._s(_vm.softwareOffer.shortLabel))]):_c('span',{staticClass:"error--text"},[_c(VIcon,{attrs:{"left":"","color":"error"}},[_vm._v("mdi-alert-outline")]),_vm._v("Edit to select")],1)])])])])]),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"d-flex flex-column justify-space-between full-height",style:({
							padding: _vm.$vuetify.breakpoint.xsOnly ? '25px' : '25px 25px 25px 0',
							background: _vm.$vuetify.breakpoint.xsOnly ? 'var(--grey-900)' : 'inherit',
							borderBottomLeftRadius: _vm.$vuetify.breakpoint.xsOnly ? 'var(--global-border-radius)' : 'inherit',
							borderBottomRightRadius: _vm.$vuetify.breakpoint.xsOnly ? 'var(--global-border-radius)' : 'inherit',
						})},[_c('div',{staticClass:"d-flex flex-column flex-end"},[_c('div',{staticClass:"workshop-session-card__actions"},[_c('workshop-session-link-dialog',{attrs:{"workshop":_vm.workshop,"workshop-session":_vm.workshopSession}}),_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('base-button',_vm._g({staticClass:"ml-2",attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.$emit('on-edit-workshop-session', _vm.workshopSession)}}},on),[_c(VIcon,{staticStyle:{"color":"var(--grey-600)"}},[_vm._v("mdi-pencil-outline")])],1)]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Edit Workshop Session")])])],1)]),_c('base-button',{attrs:{"secondary":!_vm.hasLiveSession,"super-primary":_vm.hasLiveSession},on:{"click":function($event){return _vm.$emit('on-view-workshop-session-queue-items-open', _vm.workshopSession)}}},[(_vm.hasLiveSession)?_c('span',[_vm._v("Live Session")]):_c('span',[_vm._v("Lobby")]),(_vm.hasLiveSession)?_c('div',{staticClass:"ml-3 live-session-pulse"}):_vm._e()])],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }