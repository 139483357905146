import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"team-card tw-shadow-bp-md",class:{
			'team-card-hover': _vm.selectionMode && hover,
			'tw-shadow-bp-lg team-card-selected': _vm.selectionMode && _vm.selected,
		},on:{"click":_vm.handleClick}},[_vm._t("content",function(){return [(_vm.team || _vm.company)?_c('div',{staticClass:"full-width d-flex align-start justify-space-between"},[_c('div',{staticClass:"full-width d-flex flex-column full-height justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"team-card-name",class:{
								'team-card-name-hover': _vm.selectionMode && hover,
								'gradient-text': _vm.selectionMode && _vm.selected,
							}},[(_vm.company)?_c('span',[_vm._v("All Teams")]):_c('span',[_vm._v(_vm._s(_vm.team.name))])]),_c('div',{staticClass:"team-card-date-value mt-2",class:{
								'team-card-date-value-hover': _vm.selectionMode && hover,
								'team-card-date-value-selected': _vm.selectionMode && _vm.selected,
							}},[(_vm.company)?_c('span',[_vm._v(" "+_vm._s(_vm.company.name)+" ")]):_c('span',[_vm._v(" Created: "+_vm._s(_vm._f("moment")(_vm.team.createdAt.toMillis(),'MMM D, YYYY'))+" ")])]),_c('div',{staticClass:"team-card-date-value",class:{
								'team-card-date-value-hover': _vm.selectionMode && hover,
								'team-card-date-value-selected': _vm.selectionMode && _vm.selected,
							}},[(_vm.company)?_c('span',[_vm._v(" ")]):(_vm.team.isActive)?_c('span',[_vm._v("Activated: "+_vm._s(_vm._f("moment")(_vm.team.activatedAt.toMillis(),'MMM D, YYYY')))]):_c('i',[_vm._v("Not activated")])])])]),_c('div',{staticClass:"d-flex"},[(_vm.selectionMode && _vm.selected)?_c(VIcon,{staticStyle:{"color":"var(--primary-blue)"}},[_vm._v(" mdi-check-circle ")]):(_vm.selectionMode && !_vm.selected)?_c(VIcon,{attrs:{"color":"#575757"}},[_vm._v(" mdi-circle-outline ")]):_vm._e()],1)]):_vm._e()]})],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }