import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.comfyOnboardingDismissed)?_c('div',{staticClass:"pa-6",style:({
		position: 'absolute',
		top: '10px',
		left: '60px',
	})},[_c(VCard,{staticClass:"elevation-10",staticStyle:{"padding":"20px","height":"100%"},style:({
			minWidth: _vm.$vuetify.breakpoint.xsOnly ? '300px' : '500px',
			minHeight: _vm.$vuetify.breakpoint.xsOnly ? '300px' : '500px',
		})},[_c('div',{staticClass:"d-flex justify-center flex-column align-center full-height"},[_c('div',{staticClass:"d-flex justify-center align-center my-6"},[_c(VIcon,{staticClass:"gradient-text",staticStyle:{"font-size":"80px","margin":"0 0 0 0"}},[_vm._v("mdi-graph-outline")])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCardSubtitle,{staticStyle:{"font-size":"21px","text-align":"center","line-height":"30px"}},[_vm._v(" Want to get started with "),_c('br'),_vm._v("ComfyUI quickly? ")]):_vm._e(),_c(VCardText,{staticClass:"py-7"},[_c('div',{staticClass:"d-flex justify-center"},[_c('base-button',{staticClass:"px-8",attrs:{"large":""},on:{"click":_vm.openComfyOnboardingPage}},[_vm._t("default",function(){return [_vm._v(" Drag & Drop Workflows "),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-open-in-new")])]})],2)],1)]),_c(VCardActions,[_c('div',{staticClass:"d-flex flex-column justify-center full-width"},[_c('div',{staticClass:"d-flex justify-center full-width"},[_c(VCheckbox,{staticClass:"mb-4",attrs:{"hide-details":"","label":"Don't show this again","color":"primary"},model:{value:(_vm.shouldSaveComfyOnboardingDismiss),callback:function ($$v) {_vm.shouldSaveComfyOnboardingDismiss=$$v},expression:"shouldSaveComfyOnboardingDismiss"}})],1),_c('base-button',{attrs:{"secondary":""},on:{"click":_vm.dismissComfyOnboardingPopup}},[_vm._v(" Dismiss ")])],1)])],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }