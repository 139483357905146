
import Vue from 'vue';
import _isEmpty from 'lodash/isEmpty';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
	SNACKBAR_STATUS,
} from '@/constants/constants';
import { db } from '@/firebase';
import LoginSettingsDialog from '@/components/Toolbar/UserAccount/LoginSettingsDialog.vue';
import { User } from '@run-diffusion/shared';
import SettingsWindowItem from '@/components/Toolbar/UserAccount/SettingsWindowItem.vue';
import SessionsHistoryDialog from '@/components/Toolbar/UserAccount/SessionsHistoryDialog.vue';
import PaymentsHistoryDialog from '@/components/Toolbar/UserAccount/PaymentsHistoryDialog.vue';
import DoubleConfirmDangerDialog from '@/components/base/DoubleConfirmDangerDialog.vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'UserAccount',
	props: {
		menuResetTrigger: { type: Number, default: 0 },
	},
	data() {
		return {
			windowView: 0,
			ACCOUNT_MENU_WINDOW_ITEM: 0,
			EDIT_ACCOUNT_WINDOW_ITEM: 1,
			SESSION_SETTINGS_WINDOW_ITEM: 2,

			drawerTitle: 'Edit Account',

			deletingUser: false,
			accountDeletionRequestedAt: null,

			sessionsHistoryDialogOpen: false,
			paymentsHistoryDialogOpen: false,
			changeLoginSettingsDialogOpen: false,
		};
	},
	watch: {
		user: {
			immediate: true,
			handler (newVal: User) {
				if (newVal) {
					this.accountDeletionRequestedAt = localStorage.getItem(`ACCOUNT_DELETION_REQUESTED_AT_${newVal.id}`) || null;
				}
			},
		},
		menuResetTrigger: {
			immediate: true,
			handler (newVal: number, oldVal: number) {
				if (newVal !== oldVal) {
					this.windowView = this.ACCOUNT_MENU_WINDOW_ITEM;
				}
			},
		},
		showStripeUserMembershipPortalBtn: {
			immediate: true,
			handler (newVal: boolean, oldVal: boolean) {
				if (
					newVal !== oldVal &&
					newVal &&
					this.appQueryParamsData &&
					this.appQueryParamsData.openManageMembership
				) {
					// Auto open customer portal
					this.goToStripeSubscriptionPortal(null);
					// Reset openManageMembership to false
					this.updateAppQueryParamsData({openManageMembership: false});
				}
			},
		},
	},
	computed: {
		...mapGetters([
			'showStripeUserMembershipPortalBtn',
		]),
		...mapState([
			'user',
			'team',
			'toolbar',
			'appQueryParamsData',
		]),
	},
	methods: {
		_isEmpty,
		...mapActions([
			'updateToolbar',
			'updateSnackbar',
			'updateAppQueryParamsData',
			'goToStripeSubscriptionPortal',
		]),
		async handleDeleteUserConfirm (closeAndResetCallback: Function) {
			// TODO: Need to just do all the deletion logic in a FB function and not do this request stuff (remove the "accountDeletionRequestedAt" property from state)
			this.deletingUser = true;
			const onError: Function = (e) => {
				console.error('Error requesting for user account deletion: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error deleting your account, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			};

			try {
				const deleteUserRequestRef: any = db.doc(`deleteUserRequests/${this.user.id}`);
				await deleteUserRequestRef.set({
					userId: this.user.id,
					createdAt: new Date(),
				});
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Your request has been recorded.`,
					show: true,
				});
				localStorage.setItem(`ACCOUNT_DELETION_REQUESTED_AT_${this.user.id}`, new Date().toISOString());
				this.accountDeletionRequestedAt = new Date().toISOString();
			} catch (e) {
				onError(e);
			} finally {
				this.deletingUser = false;
				if (closeAndResetCallback) {
					closeAndResetCallback();
				}
			}
		},
		openLogoutDialog () {
			this.updateToolbar({
				logoutDialogOpen: true,
			});
		},
		goToTeamRoute () {
			if (this.toolbar.session) {
				const routeData = this.$router.resolve({
					name: ROUTER.TEAM_MEMBERS,
				});
				window.open(routeData.href, '_blank');
			} else {
				this.routerPush(this.$route, this.$router, { name: ROUTER.TEAM_MEMBERS });
				this.$emit('on-drawer-close');
			}
		},
		goToAdminRoute (routeName: string) {
			this.routerPush(this.$route, this.$router, { name: routeName });
			this.$emit('on-drawer-close');
		},
		goToRetool () {
			window.open('https://rundiffusion.retool.com/apps/a867e538-cdd3-11ed-ae7f-cb1437cbeea9/Prod%20-%20RunDiffusion%20Admin%20Tools', '_blank');
		},
		goToRdDashboard () {
			window.open('https://rd-dashboard-33b4e1cfee6d.herokuapp.com/login', '_blank');
		},
	},
	components: {
		DoubleConfirmDangerDialog,
		SessionsHistoryDialog,
		PaymentsHistoryDialog,
		SettingsWindowItem,
		LoginSettingsDialog,
	},
});
