
import Vue from 'vue';
import _has from 'lodash/has';
import { mapState } from 'vuex';
import { AppOffer } from '@run-diffusion/shared';
import moment, { Moment } from 'moment-timezone';
import { DateRangeOption } from '@/components/base/types';
import { APP_KEY, HARDWARE_KEY } from '@/constants/enums';
import { localReportData } from '@/views/Reports/localReportData';
import PredefinedDateRangeOptions from '@/components/base/PredefinedDateRangeOptions.vue';

export default Vue.extend({
	name: 'AppMetricsReport',
	props: {
		selectedTeam: { type: Object, default: null },
		selectedCompany: { type: Object, default: null },
	},
	data () {
		return {
			dateOfLastLocalReportData: '2024-06-02T17:23:49.431Z',
			selectedPredefinedDateRangeOption: null,
			reportData: localReportData,
			teamUsersMap: {},
			usersMap: {},
			localAppOffers: [],
			sumHoursByHardwareResult: null,
			sumHoursByAppResult: null,
			sumHoursByAppAndHardwareResult: null,
			sumHoursByTeamAndHardwareResult: null,
			sumHoursByTeamAndAppResult: null,
			sumHoursByTeamAppHardwareResult: null,
			sumHoursByTeamUserAppHardwareResult: null,
			sumHoursByTeamUserAppResult: null,

			// Date range filters
			startDateFilter: null,
			endDateFilter: null,
		};
	},
	computed: {
		...mapState([
			'appOffers',
			'hardwareOffers',
		]),
	},
	watch: {
		appOffers: {
			immediate: true,
			handler (newVal: AppOffer[], oldVal: AppOffer[]) {
				if (newVal !== oldVal) {
					this.localAppOffers = (newVal || [])
						.filter(appOffer => ![
							APP_KEY.MANI_001,
							APP_KEY.FCUS_API_001,
						].includes(appOffer.app))
						.map(this.copyLocalAppOffer);
				}
			},
		},
		selectedPredefinedDateRangeOption: {
			immediate: true,
			handler (newVal: DateRangeOption, oldVal: DateRangeOption) {
				if (newVal !== oldVal) {
					this.onSelectDateRangeOption(newVal);
				}
			},
		},
	},
	methods: {
		_has,
		onSelectDateRangeOption (dateRangeOption: DateRangeOption) {
			if (dateRangeOption) {
				this.startDateFilter = dateRangeOption.startDate;
				this.endDateFilter = dateRangeOption.endDate;
				this.buildReportData(this.reportData);
			}
		},
		buildReportData (reportData) {
			this.teamUsersMap = this.groupByTeamAndMapUsers(reportData);
			this.sumHoursByHardwareResult = this.sumHoursByHardware(reportData);
			this.sumHoursByAppResult = this.sumHoursByApp(reportData);
			this.sumHoursByAppAndHardwareResult = this.sumHoursByAppAndHardware(reportData);
			this.sumHoursByTeamAndHardwareResult = this.sumHoursByTeamAndHardware(reportData);
			this.sumHoursByTeamAndAppResult = this.sumHoursByTeamAndApp(reportData);
			this.sumHoursByTeamAppHardwareResult = this.sumHoursByTeamAppHardware(reportData);
			this.sumHoursByTeamUserAppHardwareResult = this.sumHoursByTeamUserAppHardware(reportData);
			this.sumHoursByTeamUserAppResult = this.sumHoursByTeamUserApp(reportData);
		},
		determineShowingHardware (hardwareOffer, appOffer): boolean {
			const isNotFileBrowserOnly: boolean = !!(
				hardwareOffer.hardware === HARDWARE_KEY.CPU_001 &&
				appOffer.app !== APP_KEY.FILE_001
			);
			const isFileBrowserHardware: boolean = !!(
				hardwareOffer.hardware !== HARDWARE_KEY.CPU_001 &&
				appOffer.app === APP_KEY.FILE_001
			);
			// Add any hardware options here that should be hidden
			const isHiddenHardwareOption: boolean = hardwareOffer.hardware.includes([
				HARDWARE_KEY.LG_002,
			]);

			if (
				isNotFileBrowserOnly ||
				isFileBrowserHardware ||
				isHiddenHardwareOption
			) {
				return false;
			}

			return true;
		},
		groupByTeamAndMapUsers (data) {
			const teams = {};
			const userMap = {};

			data.forEach(item => {
				// Process for userMap
				const userEmail = item.email;
				const hardware = item.hardware;
				const sumHours = parseFloat(item.sumHours);

				if (!userMap[userEmail]) {
					userMap[userEmail] = {};
				}

				if (!userMap[userEmail][hardware]) {
					userMap[userEmail][hardware] = 0;
				}

				userMap[userEmail][hardware] += sumHours;

				// Process for teams
				if (!teams[item.teamName]) {
					teams[item.teamName] = [];
				}

				// Check if the user is already added to the team
				if (!teams[item.teamName].some(user => user.email === userEmail)) {
					teams[item.teamName].push({
						email: userEmail,
						hardwareMap: userMap[userEmail]
					});
				} else {
					// Update the hardware map for existing user
					let user = teams[item.teamName].find(user => user.email === userEmail);
					user.hardwareMap = userMap[userEmail];
				}
			});

			return teams;
		},
		isNotWithinFilteredDateRange (dateToCheck) {
			const check: Moment = moment(dateToCheck);
			const start: Moment = moment(this.startDateFilter);
			const end: Moment = moment(this.endDateFilter);

			return !check.isBetween(start, end, undefined, '[]');
		},
		sumHoursByHardware (data) {
			const result = {};

			data.forEach(item => {
				if (this.isNotWithinFilteredDateRange(item.createdAt)) {
					return;
				}

				// Convert sumHours to a float number
				const hours = parseFloat(item.sumHours);

				// Check if the hardware key exists and sum the hours
				if (result[item.hardware]) {
					result[item.hardware] += hours;
				} else {
					result[item.hardware] = hours;
				}
			});

			return result;
		},
		sumHoursByApp (data) {
			const result = {};

			data.forEach(item => {
				if (this.isNotWithinFilteredDateRange(item.createdAt)) {
					return;
				}

				// Convert sumHours to a float number
				const hours = parseFloat(item.sumHours);

				// Check if the app key exists and sum the hours
				if (result[item.app]) {
					result[item.app] += hours;
				} else {
					result[item.app] = hours;
				}
			});

			return result;
		},
		sumHoursByAppAndHardware (data) {
			const result = {};

			data.forEach(item => {
				if (this.isNotWithinFilteredDateRange(item.createdAt)) {
					return;
				}

				// Create a unique key for each app and hardware combination
				const key = `${item.app}-${item.hardware}`;

				// Convert sumHours to a float number
				const hours = parseFloat(item.sumHours);

				// Check if the composite key exists and sum the hours
				if (result[key]) {
					result[key] += hours;
				} else {
					result[key] = hours;
				}
			});

			return result;
		},
		sumHoursByTeamAndHardware (data) {
			const result = {};

			data.forEach(item => {
				if (this.isNotWithinFilteredDateRange(item.createdAt)) {
					return;
				}

				const key = `${item.teamName}-${item.hardware}`;
				const hours = parseFloat(item.sumHours);

				result[key] = (result[key] || 0) + hours;
			});

			return result;
		},
		sumHoursByTeamAndApp (data) {
			const result = {};

			data.forEach(item => {
				if (this.isNotWithinFilteredDateRange(item.createdAt)) {
					return;
				}

				const key = `${item.teamName}-${item.app}`;
				const hours = parseFloat(item.sumHours);

				result[key] = (result[key] || 0) + hours;
			});

			return result;
		},
		sumHoursByTeamAppHardware (data) {
			const result = {};

			data.forEach(item => {
				if (this.isNotWithinFilteredDateRange(item.createdAt)) {
					return;
				}

				const key = `${item.teamName}-${item.app}-${item.hardware}`;
				const hours = parseFloat(item.sumHours);

				result[key] = (result[key] || 0) + hours;
			});

			return result;
		},
		sumHoursByTeamUserAppHardware (data) {
			const result = {};

			data.forEach(item => {
				if (this.isNotWithinFilteredDateRange(item.createdAt)) {
					return;
				}

				const key = `${item.teamName}-${item.email}-${item.app}-${item.hardware}`;
				const hours = parseFloat(item.sumHours);

				result[key] = (result[key] || 0) + hours;
			});

			return result;
		},
		sumHoursByTeamUserApp (data) {
			const result = {};

			data.forEach(item => {
				if (this.isNotWithinFilteredDateRange(item.createdAt)) {
					return;
				}

				const key = `${item.teamName}-${item.email}-${item.app}`;
				const hours = parseFloat(item.sumHours);

				result[key] = (result[key] || 0) + hours;
			});

			return result;
		},
		copyLocalAppOffer (appOffer: AppOffer) {
			return {
				id: appOffer.id,
				publishedAt: appOffer.publishedAt ? new Date(appOffer.publishedAt.toMillis()) : null,
				isTeam: !!appOffer.isTeam,
				clubs: (appOffer.clubs || []).sort(),
				app: appOffer.app,
				type: appOffer.type,
				label: appOffer.label,
				shortLabel: appOffer.shortLabel,
				backendApps: (appOffer.backendApps || []).sort(),
				sortOrder: appOffer.sortOrder,
				descriptionHtml: appOffer.descriptionHtml,
				carouselUrls: appOffer.carouselUrls || [],
				resources: appOffer.resources || [],
				popularity: appOffer.popularity,
				tags: (appOffer.tags || []).sort(),
				singleOnly: !!appOffer.singleOnly,
				isBeta: !!appOffer.isBeta,
				isDev: !!appOffer.isDev,
				showApiEnabled: !!appOffer.showApiEnabled,
				showPersistExtensions: !!appOffer.showPersistExtensions,
				showStabilityAILicenseDisclaimer: !!appOffer.showStabilityAILicenseDisclaimer,
			};
		},
	},
	components: {
		PredefinedDateRangeOptions,
	},
});
