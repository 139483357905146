
import Vue from 'vue';
import BorderSelectCard from '@/components/BorderSelectCard.vue';

export default Vue.extend({
	name: 'RunnitsOwnerSelectionCard',
	props: {
		label: { type: String, required: true },
		icon: { type: String, required: true },
		descriptionHtml: { type: String, default: null },
		selected: { type: Boolean, default: false },
	},
	components: {
		BorderSelectCard,
	},
});
