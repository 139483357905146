
import Vue from 'vue';
import BigNumber from 'bignumber.js';

export default Vue.extend({
	name: 'NumberWithIncrementer',
	props: {
		value: { type: Number, default: null },
		max: { type: Number, default: null },
		min: { type: Number, default: null },
		step: { type: Number, default: null },
		disabled: { type: Boolean, default: false },
		padding: { type: String, default: '0 14px 0 12px' },
		condensed: { type: Boolean, default: false },
		numberMinWidth: { type: String, default: () => 'auto' }
	},
	computed: {
		computedMin () {
			return this.min > 0 ? this.min : 1;
		},
		computedMax () {
			return this.max > 0 ? this.max : Math.max(this.computedMin, 1);
		},
		showMaxWarning () {
			return this.computedMax > 1 && this.value > 0 && this.value === this.computedMax;
		},
	},
	methods: {
		onIncrement (isUp: boolean) {
			const stepNum: BigNumber = new BigNumber(this.step > 0 ? this.step : 1);

			let incrementedVal: BigNumber = new BigNumber(this.value || 0);
			if (isUp) incrementedVal = incrementedVal.plus(stepNum);
			else incrementedVal = incrementedVal.minus(stepNum);
			if (incrementedVal.isGreaterThan(this.computedMax) || incrementedVal.isLessThan(this.computedMin)) {
				return;
			}

			let emittedVal: number = undefined;
			if (incrementedVal.isLessThanOrEqualTo(this.computedMax) && incrementedVal.isGreaterThanOrEqualTo(this.computedMin)) {
				emittedVal = incrementedVal.toNumber();
			} else if (this.computedMin > 0) {
				emittedVal = this.computedMin;
			} else {
				emittedVal = 1;
			}
			if (emittedVal !== undefined && emittedVal !== this.value) {
				this.$emit('input', emittedVal);
			}
		},
	},
});
