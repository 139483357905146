
import Vue from 'vue';
import { mapGetters } from 'vuex';
import _uniq from 'lodash/uniq';
import moment from 'moment-timezone';
import {
	ISO_DATE_PICKER_FORMAT,
	VIEW_DATE_PICKER_FORMAT,
	VIEW_DATE_PICKER_NO_YEAR_FORMAT,
	getUtcMoment,
	isDateStringValidIso,
	isDateStringValidView,
	isoDateStringToViewDateString,
	viewDateStringToIsoDateString,
	isoDateStringToViewDateNoYearString,
} from '@/utils';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';

export default Vue.extend({
	props: {
		fieldLabel: { type: String, default: null },
		value: { type: String, default: null }, // ISO date string or array of ISO date strings
		hint: { type: String, default: null },
		outlined: { type: Boolean, default: false },
		required: { type: Boolean, default: false },
		startWithYear: { type: Boolean, default: false },
		addAdditionalRules: { type: Function, default: (isNativeDateType) => [] }, // callback that takes the isNativeDateType boolean and returns an array
		max: { type: String, default: null },
		min: { type: String, default: null },
		datePickerAllowedDates: { type: Function, default: null },
		prependIcon: { type: String, default: '' },
		dataPrivate: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		readonly: { type: Boolean, default: false },
		hideDetails: { type: Boolean, default: false },
		multiple: { type: Boolean, default: false },
		hideYear: { type: Boolean, default: false },
		dense: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		fullWidth: { type: Boolean, default: false },
		fieldStyle: { type: [Object, String], default: null },
		fieldClass: { type: Object, default: null },
	},
	data () {
		return {
			VIEW_DATE_PICKER_FORMAT,
			VIEW_DATE_PICKER_NO_YEAR_FORMAT,

			isNativeDateType: false, // The typed date native text field is type="date" or type="text"
			valueMultiple: [], // Used if not multiple mode
			date: null, // Used if not multiple mode
			showDatePicker: false,
			nowMomentDeviceTimeZone: moment(),

			// Shortcuts state
			pickerDateDblClickTimeout: null,
			pickerDateDblClickDate: null,
			pickerDateDblClickCount: 0, // For triple clicks
		};
	},
	created () {
		this.setIsNativeDateType();
		if (this.multiple) {
			this.initValueMultiple(this.value);
		} else {
			this.initValueDate(this.value);
		}
	},
	mounted () {
		this.setIsNativeDateType();
	},
	watch: {
		hideYear (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.setIsNativeDateType();
			}
		},
		showDatePicker (val) {
			if (val && this.startWithYear) {
				setTimeout(() => {
					if (this.$refs.vDatePickerRef) {
						this.$refs.vDatePickerRef.activePicker = 'YEAR';
					}
				}, 150);
			}
		},
		value (newVal, oldVal) {
			if (this.multiple) {
				this.initValueMultiple(newVal);
			} else {
				this.initValueDate(newVal);
			}
		},
	},
	computed: {
		...mapGetters([
			'app',
		]),
		dateTextFieldType () {
			if (
				this.disabled ||
				this.readonly ||
				this.hideYear ||
				this.multiple
			) {
				return 'text';
			}
			return 'date';
		},
		maxDateVal () {
			if (!this.max) return null;
			return getUtcMoment(this.max).format('YYYY-MM-DD');
		},
		minDateVal () {
			if (!this.min) return null;
			return getUtcMoment(this.min).format('YYYY-MM-DD');
		},
		computedRules () {
			const viewDateFormat = this.hideYear ? VIEW_DATE_PICKER_NO_YEAR_FORMAT : VIEW_DATE_PICKER_FORMAT;
			let formatToUse = viewDateFormat;
			if (this.isNativeDateType) {
				formatToUse = ISO_DATE_PICKER_FORMAT;
			}

			return [
				...(this.multiple && this.required ? [v => !!(v && v.length) || 'Date(s) is required'] : []),
				...(!this.multiple && this.required ? [v => !!v || 'Date is required'] : []),
				...(!this.multiple ? [v => !v || isDateStringValidView(v, formatToUse) || `Invalid date (format: ${viewDateFormat})`] : []),
				...(this.max ? [v => !v || getUtcMoment(this.maxDateVal).startOf('day').isSameOrAfter(v, 'day') || `Cannot be after ${getUtcMoment(this.maxDateVal).format(VIEW_DATE_PICKER_FORMAT)}`] : []),
				...(this.min ? [v => !v || getUtcMoment(this.minDateVal).endOf('day').isSameOrBefore(v, 'day') || `Cannot be before ${getUtcMoment(this.minDateVal).format(VIEW_DATE_PICKER_FORMAT)}`] : []),
				...(this.addAdditionalRules && this.addAdditionalRules(this.isNativeDateType) || []),
			];
		},
		isClearable () {
			return (!this.disabled && !this.readonly);
		},
		computedHint () {
			if (this.isNativeDateType || this.multiple) {
				return this.hint || null;
			}
			if (this.hideYear) return `${VIEW_DATE_PICKER_NO_YEAR_FORMAT} format${this.hint ? ` (${this.hint})` : ''}`;
			return `${VIEW_DATE_PICKER_FORMAT} format${this.hint ? ` (${this.hint})` : ''}`;
		},
		datePickerValue () {
			if (this.multiple) {
				return this.valueMultiple || [];
			}
			return isDateStringValidIso(this.date) && getUtcMoment(this.date, ISO_DATE_PICKER_FORMAT, true).year() > 999
				? this.date
				: this.nowMomentDeviceTimeZone.format(ISO_DATE_PICKER_FORMAT);
		},
		// currMask () {
		// 	if (this.multiple) {
		// 		// '## selected'
		// 		const numSelectedCharCount = `${(this.valueMultiple || []).length}`.length;
		// 		const correctNumPoundSigns = _times(numSelectedCharCount, () => '#').reduce((acc, char) => acc + char, '');
		// 		return `${correctNumPoundSigns} XXXXXXXXXXXXXXXXXXXXX`;
		// 	}
		// 	return this.hideYear ? '##/##' : '##/##/####';
		// },
		dateDisplayText () {
			if (this.multiple) {
				return `${(this.valueMultiple || []).length} selected`;
			}
			if (!this.date) {
				return '';
			}
			if (this.isNativeDateType) {
				// The date input is already in ISO string format
				return this.date;
			}
			if (isDateStringValidIso(this.date)) {
				if (this.hideYear) {
					return isoDateStringToViewDateNoYearString(this.date);
				} else {
					return isoDateStringToViewDateString(this.date);
				}
			}

			// Not done typing a valid date
			return this.date;
		},
	},
	methods: {
		setIsNativeDateType () {
			this.isNativeDateType = this._get(this.$refs.dateTextField, '$refs.input.type') === 'date';
		},
		clearDates () {
			if (this.multiple) {
				this.onMultipleDateChange(null);
			} else {
				this.onDateChange(null);
			}
		},
		initValueMultiple (dateIsoStrList) {
			this.valueMultiple = (dateIsoStrList || [])
				.filter((dateStr) => this.getDateStringFromPropsValue(dateStr))
				.map((dateStr) => this.getDateStringFromPropsValue(dateStr));
		},
		initValueDate (dateIsoStr) {
			this.date = this.getDateStringFromPropsValue(dateIsoStr);
		},
		onClickPickerDate (dateStr) {
			const clearPickerDateDblClickTimeout = () => {
				if (this.pickerDateDblClickTimeout) {
					clearTimeout(this.pickerDateDblClickTimeout);
				}
				this.pickerDateDblClickTimeout = null;
				this.pickerDateDblClickDate = null;
				this.pickerDateDblClickCount = 0;
			};

			// `@dblclick:date` wasn't working on mobile, so implementing my own double click handler
			if (
				!this.pickerDateDblClickTimeout ||
				this.pickerDateDblClickDate !== dateStr
			) {
				// single click
				clearPickerDateDblClickTimeout();
				this.pickerDateDblClickTimeout = setTimeout(() => {
					if (this.pickerDateDblClickDate === dateStr) {
						// Only clear if it is this date
						clearPickerDateDblClickTimeout();
					}
				}, 600);
				this.pickerDateDblClickDate = dateStr;
				this.pickerDateDblClickCount = 1;
			} else if (this.pickerDateDblClickCount === 1) {
				// double click
				this.pickerDateDblClickCount = 2;
				this.selectAllMonthsDays(dateStr);
			} else {
				// triple click
				clearPickerDateDblClickTimeout();
				this.selectEveryMonthsDate(dateStr);
			}
		},
		/**
		 * Given a date, it will select all Mondays in the month (if the date is a Monday). Same goes for any day of the week.
		 * @param dateStr {string}
		 */
		selectAllMonthsDays (dateStr) {
			if (dateStr && this.multiple) {
				const allDaysDateStrs = [dateStr];
				const goBackMoment = getUtcMoment(dateStr).startOf('day');
				const goForwardMoment = goBackMoment.clone();
				const currMonth = goBackMoment.month();

				// Go back within the month adding all dateStrs of the same day of the week
				while (goBackMoment.month() === currMonth) {
					allDaysDateStrs.push(goBackMoment.format(ISO_DATE_PICKER_FORMAT));
					goBackMoment.subtract(1, 'weeks');
				}
				// Go forward within the month adding all dateStrs of the same day of the week
				while (goForwardMoment.month() === currMonth) {
					allDaysDateStrs.push(goForwardMoment.format(ISO_DATE_PICKER_FORMAT));
					goForwardMoment.add(1, 'weeks');
				}

				this.onMultipleDateChange(
					_uniq([
						...this.valueMultiple,
						...allDaysDateStrs,
					]),
				);
			}
		},
		/**
		 * Given a date, it will select every date in the month. 1 - 31
		 * @param dateStr {string}
		 */
		selectEveryMonthsDate (dateStr) {
			if (dateStr && this.multiple) {
				const allDateStrs = [];
				const currMoment = getUtcMoment(dateStr).startOf('month').startOf('day');
				const currMonth = currMoment.month();

				while (currMoment.month() === currMonth) {
					allDateStrs.push(currMoment.format(ISO_DATE_PICKER_FORMAT));
					currMoment.add(1, 'days');
				}

				this.onMultipleDateChange(
					_uniq([
						...this.valueMultiple,
						...allDateStrs,
					]),
				);
			}
		},
		getDateStringFromPropsValue (dateStringValue) {
			return dateStringValue && getUtcMoment(dateStringValue).isValid() ? getUtcMoment(dateStringValue).format(ISO_DATE_PICKER_FORMAT) : null;
		},
		onMultipleDateChange (val) {
			if (!val || !val.length) {
				this.$emit('input', []);
				return;
			}
			console.log({
				val,
			});

			this.$emit(
				'input',
				[
					...(
						val
							.filter((dateStr) => isDateStringValidIso(dateStr))
							.map((dateStr) => getUtcMoment(dateStr, ISO_DATE_PICKER_FORMAT).startOf('day').toISOString())
					),
				].sort(),
			);
		},
		onDateChange (val) {
			if (val !== this.value) {
				if (!val) this.$emit('input', null);
				console.log({
					val,
					converted: getUtcMoment(val, ISO_DATE_PICKER_FORMAT).startOf('day').toISOString(),
				});

				if (isDateStringValidIso(val)) {
					this.$emit(
						'input',
						getUtcMoment(val, ISO_DATE_PICKER_FORMAT).startOf('day').toISOString(),
					);
				}
			}
		},
		onDateTextFieldChange (typedDate) {
			if (!this.multiple) {
				let typedDateIsoStr = typedDate || null;
				if (typedDate) {
					// Trim any characters above expected input length and reformat if needed
					if (this.isNativeDateType) {
						typedDateIsoStr = typedDate.substr(0, ISO_DATE_PICKER_FORMAT.length);
					} else {
						// Validations
						if (
							this.hideYear &&
							typedDate.length >= VIEW_DATE_PICKER_NO_YEAR_FORMAT.length &&
							/^[0-9][0-9]\/[0-9][0-9]/g.test(typedDate) // ##/##
						) {
							typedDateIsoStr = viewDateStringToIsoDateString(`${typedDate.substr(0, VIEW_DATE_PICKER_NO_YEAR_FORMAT.length)}/${this.nowMomentDeviceTimeZone.year()}`);
						} else if (
							typedDate.length >= VIEW_DATE_PICKER_FORMAT.length &&
							/^[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/g.test(typedDate) // ##/##/####
						) {
							typedDateIsoStr = viewDateStringToIsoDateString(typedDate.substr(0, VIEW_DATE_PICKER_FORMAT.length));
						} else {
							// Didn't pass validation
							return;
						}
					}
				}

				this.date = typedDateIsoStr;

				this.onDateChange(this.date);
			}
		},
		onDateTextFieldClick () {
			if (this.multiple && !this.disabled && !this.readonly) {
				this.showDatePicker = true;
			}
		},
		onDateTextFieldAppendClick () {
			if (this.isClearable) {
				if (this.multiple) {
					this.onMultipleDateChange(null);
				} else {
					this.onDateChange(null);
				}
			}
		},
	},
	components: {
		ConfirmDialog,
	},
});
