
import Vue from 'vue';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _findLast from 'lodash/findLast';
import _isEmpty from 'lodash/isEmpty';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
	AppKey,
	AppOffer,
	HardwareOffer,
	ReleaseStatus,
	Session,
	SessionOptions,
	SetupSessionPrefill,
	SoftwareOffer,
	Team,
	Workspace,
	getHardwareHourlyPrice,
} from '@run-diffusion/shared';
import { APP_OFFER_TYPE, APP_KEY, CLUB_KEY, RELEASE_STATUS } from '@/constants/enums';
import BalanceCard from '@/components/BalanceCard/BalanceCard.vue';
import TimeYourSessionInputs from '@/components/TimeYourSessionInputs.vue';
import SessionSoundsSettings from '@/components/SessionSoundsSettings.vue';
import HowToStopEarlyNotice from '@/components/HowToStopEarlyNotice.vue';
import EnableApiSettings from '@/components/EnableApiSettings.vue';
import StorageCapCard from '@/components/StorageCapCard/StorageCapCard.vue';
import ActionsIsland from '@/components/ActionsIsland.vue';
import { SELECTED_STORAGE_TYPE, STOPPING_SESSION_STATES } from '@/constants/constants';
import {
	getPersistExtensions,
	getProxyAdminToken,
	getSelectedStorageType,
	getWorkspaceId,
	removeWorkspaceId,
	removePersistExtensions,
	setPersistExtensions,
	setSelectedStorageType,
	setWorkspaceId,
} from '@/utils/localStorage';
import firebase from 'firebase/app';
import { db } from '@/firebase';
import { MyBalanceMixin, TeamUserMixin } from '@/mixins';
import FullSelectionPreviewCard from '@/views/Sessions/SetupSession/FullSelectionPreviewCard.vue';
import HardwareOffersList from '@/views/Sessions/HardwareOffersList/HardwareOffersList.vue';
import SoftwareOffersList from '@/views/Sessions/SoftwareOffersList/SoftwareOffersList.vue';
import SetupSessionTerms from '@/views/Sessions/SetupSession/SetupSessionTerms.vue';
import BackButton from '@/components/base/BackButton.vue';
import StorageTypesList from '@/views/Sessions/StorageTypesList/StorageTypesList.vue';
import StorageOptionsList from '@/views/Sessions/StorageOptionsList/StorageOptionsList.vue';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import WindowStepper from '@/components/WindowStepper.vue';
import InfoMessageSmall from '@/components/base/InfoMessageSmall.vue';
import { APP_OFFER_IS_BETA_MESSAGE } from '@/views/Sessions/AppOffersList/utils';
import EnablePersistExtensionsSettings from '@/components/EnablePersistExtensionsSettings.vue';
import PreloadModelPreview from '@/components/PreloadModelPreview.vue';
import UnlockFreemium from '@/components/UnlockFreemium.vue';

export default Vue.extend({
	name: 'SetupSession',
	mixins: [
		TeamUserMixin,
		MyBalanceMixin,
	],
	data () {
		let windowItemIter: number = 0;
		return {
			APP_OFFER_IS_BETA_MESSAGE,
			APP_KEY,
			CLUB_KEY,
			SELECTED_STORAGE_TYPE,
			LAUNCH_TEXT: 'LAUNCH',
			PRELOAD_MODEL_PREVIEW_MESSAGE_HTML: 'This model will download in the background while your session boots!',
			dialogOpen: false,
			purchasing: false,
			selectedScheduledStopInHrs: 0,
			selectedScheduledStopInMins: 0,
			apiEnabled: false,
			persistExtensions: false,
			selectedStorageType: SELECTED_STORAGE_TYPE.PRIVATE,
			selectedWorkspaceId: null,

			appOffer: null,
			hardwareOffer: null,
			softwareOffer: null,
			storageOption: null,

			confirmCancelDialogOpen: false,
			forceWizardFlow: false,
			shouldAttemptHydrateFromSessionPrefill: true,

			startingWindowViewForNewUserTrialFlow: 0,
			windowView: 0,
			largestWindowView: 0,
			PICK_HARDWARE_WINDOW_ITEM: windowItemIter++,
			PICK_SOFTWARE_WINDOW_ITEM: windowItemIter++,
			PICK_STORAGE_TYPE_WINDOW_ITEM: windowItemIter++,
			PICK_STORAGE_OPTION_WINDOW_ITEM: windowItemIter++,
			SESSION_SETTINGS_WINDOW_ITEM: windowItemIter++,
			PREVIEW_WINDOW_ITEM: windowItemIter++,
			LAUNCH_WINDOW_ITEM: windowItemIter++,
			WINDOW_VIEW_LENGTH: windowItemIter,
		};
	},
	created () {
		this.persistExtensions = getPersistExtensions();
		this.startingWindowViewForNewUserTrialFlow = this.SESSION_SETTINGS_WINDOW_ITEM;
	},
	mounted () {
		let appOffer = null;
		if (this.$route.query.app) {
			appOffer = this.appOffersMap[this.$route.query.app];
		}
		if (appOffer) {
			this.appOffer = appOffer;
		} else {
			this.goBackToSessionsPage(false);
		}
	},
	watch: {
		appOffer: {
			immediate: true,
			handler (newVal: AppOffer, oldVal: AppOffer) {
				if (newVal && newVal !== oldVal) {
					this.bindSetupSessionPrefill({
						authUser: this.authUser,
						appKey: newVal.app,
					});
				}
				if (newVal !== oldVal) {
					this.hydrateFromSetupSessionPrefill(newVal, this.setupSessionPrefill, this.hardwareOffers, this.softwareOffers);
					this.decideToForceWizardFlow(newVal, this.boundPropsSetupSessionPrefill, this.loadingSetupSessionPrefill, this.setupSessionPrefill);
				}
				if (newVal && newVal.id !== (oldVal && oldVal.id)) {
					if (this.isNewUserTrialFlow) {
						// Reduce number of clicks for new users
						this.windowView = this.startingWindowViewForNewUserTrialFlow;
					} else {
						this.windowView = 0;
					}
					this.largestWindowView = this.windowView;
				}
			},
		},
		setupSessionPrefill: {
			immediate: true,
			async handler (newVal: SetupSessionPrefill, oldVal: SetupSessionPrefill) {
				if (newVal !== oldVal) {
					this.hydrateFromSetupSessionPrefill(this.appOffer, newVal, this.hardwareOffers, this.softwareOffers);
					this.decideToForceWizardFlow(this.appOffer, this.boundPropsSetupSessionPrefill, this.loadingSetupSessionPrefill, newVal);
					await this.rolloverSetupSessionPrefillReleaseStatus(newVal, this.softwareOffers);
				}
			},
		},
		boundPropsSetupSessionPrefill: {
			immediate: true,
			handler (newVal: { authUser: firebase.User, appKey: AppKey }) {
				this.decideToForceWizardFlow(this.appOffer, newVal, this.loadingSetupSessionPrefill, this.setupSessionPrefill);
			},
		},
		loadingSetupSessionPrefill: {
			immediate: true,
			handler (newVal: boolean) {
				this.decideToForceWizardFlow(this.appOffer, this.boundPropsSetupSessionPrefill, newVal, this.setupSessionPrefill);
			},
		},
		hardwareOffers: {
			immediate: true,
			handler (newVal: HardwareOffer[], oldVal: HardwareOffer[]) {
				if (newVal !== oldVal) {
					this.hydrateFromSetupSessionPrefill(this.appOffer, this.setupSessionPrefill, newVal, this.softwareOffers);
				}
			},
		},
		softwareOffers: {
			immediate: true,
			async handler (newVal: SoftwareOffer[], oldVal: SoftwareOffer[]) {
				if (newVal !== oldVal) {
					this.hydrateFromSetupSessionPrefill(this.appOffer, this.setupSessionPrefill, this.hardwareOffers, newVal);
					await this.rolloverSetupSessionPrefillReleaseStatus(this.setupSessionPrefill, newVal);
				}
			},
		},
		workspaces: {
			immediate: true,
			handler (newVal: Workspace[]) {
				const lastSelectedWorkspaceId: string = this.selectedWorkspaceId;
				const localStorageWorkspaceId: string = getWorkspaceId();
				let selectedWorkspace: Workspace = null;

				if (newVal && newVal.length) {
					if (!selectedWorkspace && lastSelectedWorkspaceId) {
						selectedWorkspace = _find(newVal, ['id', lastSelectedWorkspaceId]) || null;
					}
					if (!selectedWorkspace && localStorageWorkspaceId) {
						selectedWorkspace = _find(newVal, ['id', localStorageWorkspaceId]) || null;
					}
					if (!selectedWorkspace) {
						selectedWorkspace = newVal[0];
					}

					this.selectedWorkspaceId = selectedWorkspace ? selectedWorkspace.id : null;
				}
			},
		},
		team: {
			immediate: true,
			handler (newVal: Team, oldVal: Team) {
				if (newVal !== oldVal) {
					const localStorageSelectedStorageType: string = getSelectedStorageType();
					switch (localStorageSelectedStorageType) {
						case SELECTED_STORAGE_TYPE.TEAM:
							this.selectedStorageType = newVal ? SELECTED_STORAGE_TYPE.TEAM : SELECTED_STORAGE_TYPE.PRIVATE;
							break;
						case SELECTED_STORAGE_TYPE.PRIVATE:
						default:
							this.selectedStorageType = SELECTED_STORAGE_TYPE.PRIVATE;
							break;
					}
				}
			},
		},
		forceWizardFlow: {
			immediate: false,
			handler (newVal: boolean, oldVal: boolean) {
				if (newVal !== oldVal) {
					this.dialogOpen = newVal;
				}
			},
		},
		windowView: {
			immediate: true,
			handler (newVal: number, oldVal: number) {
				this.largestWindowView = Math.max(this.largestWindowView, newVal);
				if (newVal !== oldVal) {
					this.$scrollTo('#setup-session-dialog-scroll-anchor', 500, {
						container: '#setup-session-dialog-scroll-container',
					});
				}
			},
		},
	},
	computed: {
		...mapState([
			'authUser',
			'user',
			'team',
			'workspaces',
			'preloadModel',
			'currSessions',
			'appOffersMap',
			'boundPropsSetupSessionPrefill',
			'loadingSetupSessionPrefill',
			'setupSessionPrefill',
			'hardwareOffers',
			'softwareOffers',
		]),
		...mapGetters([
			'isNewUserTrialFlow',
			'clubOfferBenefits',
			'needsUnlockFreemium',
		]),
		isWizardFlow () {
			return this.$vuetify.breakpoint.smAndDown || this.forceWizardFlow;
		},
		dialogTitle () {
			if (this.windowView === this.PICK_HARDWARE_WINDOW_ITEM) return 'Choose Hardware to Run';
			if (this.windowView === this.PICK_SOFTWARE_WINDOW_ITEM) return 'Choose Software to Run';
			if (this.windowView === this.PICK_STORAGE_TYPE_WINDOW_ITEM) return 'Choose Session Type';
			if (this.windowView === this.PICK_STORAGE_OPTION_WINDOW_ITEM) return 'Choose Your Storage Option';
			if (this.windowView === this.SESSION_SETTINGS_WINDOW_ITEM) return 'Session Settings';
			if (this.windowView === this.PREVIEW_WINDOW_ITEM) return 'Preview Session';
			if (this.windowView === this.LAUNCH_WINDOW_ITEM) return 'Launch Session';
			return 'Setup Session';
		},
		shouldShowPersistExtensionsSetting () {
			return !!(
				this.appOffer &&
				this.appOffer.showPersistExtensions &&
				(
					(this.user && this.clubOfferBenefits.isCc) ||
					this.isTeamActive
				)
			);
		},
		shouldSetCivitaiPreloadModel () {
			return !!(
				this.appOffer &&
				[
					APP_OFFER_TYPE.IMAGE_GEN,
					APP_OFFER_TYPE.IMAGE_TRAIN,
					APP_OFFER_TYPE.FILE,
				].includes(this.appOffer.type) &&
				this.preloadModel.civitai &&
				this.preloadModel.civitai.model &&
				this.preloadModel.civitai.modelVersion
			);
		},
		hardwareHourlyPrice () {
			if (!this.hardwareOffer || !this.user) return 0;
			return getHardwareHourlyPrice(this.hardwareOffer, this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM && this.team && this.team.isActive ? this.team.id : null, this.user.club);
		},
		cannotLaunchDueToSingleOnlyApp () {
			return !!(
				this.appOffer &&
				this.appOffer.singleOnly &&
				this.currSessions.some((session: Session) => (
					session.apps.includes(this.appOffer.app) &&
					!STOPPING_SESSION_STATES.includes(session.state)
				))
			);
		},
		continueDisabled () {
			return !!(
				!this.appOffer ||
				(this.windowView === this.PICK_HARDWARE_WINDOW_ITEM && !this.hardwareOffer) ||
				(this.windowView === this.PICK_SOFTWARE_WINDOW_ITEM && !this.softwareOffer) ||
				(
					this.windowView === this.SESSION_SETTINGS_WINDOW_ITEM &&
					!this.selectedScheduledStopInHrs &&
					!this.selectedScheduledStopInMins
				) ||
				(
					this.windowView === this.PICK_STORAGE_TYPE_WINDOW_ITEM &&
					this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM &&
					!this.isTeamActive
				) ||
				(
					this.windowView === this.PICK_STORAGE_OPTION_WINDOW_ITEM &&
					this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM &&
					!this.selectedWorkspaceId
				) ||
				this.purchasing
			);
		},
		launchDisabled () {
			return !!(
				!this.appOffer ||
				!this.hardwareOffer ||
				!this.softwareOffer ||
				(
					!this.selectedScheduledStopInHrs &&
					!this.selectedScheduledStopInMins
				) ||
				(
					this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM &&
					!this.isTeamActive
				) ||
				(
					this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM &&
					!this.selectedWorkspaceId
				) ||
				this.cannotLaunchDueToSingleOnlyApp ||
				this.purchasing
			);
		},
		purchaseMins () {
			if (
				this.selectedScheduledStopInHrs ||
				this.selectedScheduledStopInMins
			) {
				return (this.selectedScheduledStopInHrs * 60) + this.selectedScheduledStopInMins;
			}
			return 0;
		},
	},
	methods: {
		...mapActions([
			'purchaseSession',
			'bindSetupSessionPrefill',
		]),
		_isEmpty,
		hydrateFromSetupSessionPrefill (
			appOffer: AppOffer,
			setupSessionPrefill: SetupSessionPrefill,
			hardwareOffers: HardwareOffer[],
			softwareOffers: SoftwareOffer[],
		) {
			if (this.shouldAttemptHydrateFromSessionPrefill) {
				this.hardwareOffer = null;
				this.softwareOffer = null;

				if (appOffer) {
					const filteredHardwareOffers = hardwareOffers.filter(({ apps }) => apps.includes(appOffer.app));
					const filteredSoftwareOffers = softwareOffers.filter(({ apps }) => apps.includes(appOffer.app));
					const firstHardwareOffer: HardwareOffer = this._get(filteredHardwareOffers, '[0]') || null;
					const firstSoftwareOffer: SoftwareOffer = _findLast(filteredSoftwareOffers, ({ releaseStatus }) => releaseStatus === RELEASE_STATUS.CURRENT) || this._get(filteredSoftwareOffers, '[0]') || null;

					let foundHardwareOffer: HardwareOffer = null;
					let foundSoftwareOffer: SoftwareOffer = null;
					if (setupSessionPrefill) {
						foundHardwareOffer = (setupSessionPrefill.hardware && _find(filteredHardwareOffers, ({ hardware }) => hardware === setupSessionPrefill.hardware)) || null;
						foundSoftwareOffer = (setupSessionPrefill.software && _find(filteredSoftwareOffers, ({ software }) => software === setupSessionPrefill.software)) || null;
					}

					this.hardwareOffer = foundHardwareOffer || firstHardwareOffer;
					this.softwareOffer = foundSoftwareOffer || firstSoftwareOffer;
				}
			}
		},
		decideToForceWizardFlow (
			appOffer: AppOffer,
			boundPropsSetupSessionPrefill: { authUser: firebase.User, appKey: AppKey },
			loadingSetupSessionPrefill: boolean,
			setupSessionPrefill: SetupSessionPrefill,
		) {
			if (
				appOffer &&
				loadingSetupSessionPrefill === false && // loading state is not null and is done loading
				boundPropsSetupSessionPrefill &&
				appOffer.app === boundPropsSetupSessionPrefill.appKey &&
				!setupSessionPrefill
			) {
				// Doesn't have a setupSessionPrefill, so make them go through the wizard flow
				this.forceWizardFlow = true;
			}
		},
		async rolloverSetupSessionPrefillReleaseStatus (setupSessionPrefill: SetupSessionPrefill, softwareOffers: SoftwareOffer[]) {
			const softwareOffer: SoftwareOffer = (setupSessionPrefill && _find(softwareOffers, ['software', setupSessionPrefill.software])) || null;
			if (
				this.shouldAttemptHydrateFromSessionPrefill &&
				setupSessionPrefill
			) {
				const lockedReleaseStatus: ReleaseStatus = setupSessionPrefill.lockedReleaseStatus || RELEASE_STATUS.CURRENT;
				const lockedSoftwareOffer: SoftwareOffer = _find(softwareOffers, ['releaseStatus', lockedReleaseStatus]) || null;
				if (
					lockedSoftwareOffer &&
					(
						!softwareOffer ||
						lockedSoftwareOffer.bootVolume !== softwareOffer.bootVolume
					) &&
					(
						setupSessionPrefill.software !== lockedSoftwareOffer.software ||
						setupSessionPrefill.lockedReleaseStatus !== lockedSoftwareOffer.releaseStatus
					)
				) {
					try {
						const setupSessionPrefillRef = db
							.doc(`users/${this.user.id}/setupSessionPrefills/${setupSessionPrefill.id}`);
						await setupSessionPrefillRef.update({
							software: lockedSoftwareOffer.software,
							lockedReleaseStatus: lockedSoftwareOffer.releaseStatus,
						});
					} catch (e) {
						console.error(e);
					}
				}
			}
		},
		onWindowViewBack () {
			if (this.isNewUserTrialFlow) {
				if (this.windowView <= this.startingWindowViewForNewUserTrialFlow) {
					this.windowView = 0;
					this.goBackToSessionsPage(false);
				} else {
					this.windowView--;
				}
				return;
			}

			if (this.windowView > 0) {
				this.windowView--;
			} else {
				this.goBackToSessionsPage(false);
			}
		},
		onWindowViewForward () {
			if (this.windowView < this.LAUNCH_WINDOW_ITEM) {
				this.windowView++;
			}
		},
		goBackToSessionsPage (confirmCancelDialogOpenNewValue: boolean) {
			this.confirmCancelDialogOpen = !!confirmCancelDialogOpenNewValue;
			if (!this.confirmCancelDialogOpen) {
				if (this.$route.name === 'SetupAnotherSession') {
					this.routerPush(this.$route, this.$router, { name: 'AnotherSession', query: this.$route.query });
				} else {
					this.routerPush(this.$route, this.$router, { name: 'Sessions' });
				}
			}
		},
		tryRoutingToOtherSingleOnlyAppSession () {
			if (this.appOffer) {
				let otherSingleOnlyAppSession: Session = null;
				this.currSessions.forEach((session: Session) => {
					if (session.apps.includes(this.appOffer.app)) {
						otherSingleOnlyAppSession = session;
					}
				});
				if (otherSingleOnlyAppSession) {
					const routeData = this.$router.resolve({
						name: 'Launch',
						query: {
							sessionId: otherSingleOnlyAppSession.id,
						},
					});
					window.open(routeData.href, '_self');
					return;
				}
			}

			this.goBackToSessionsPage(false);
		},
		onPersistExtensionsInput (persistExtensions: boolean) {
			this.persistExtensions = !!persistExtensions;
			if (persistExtensions) setPersistExtensions();
			else removePersistExtensions();
		},
		onPickHardware () {
			this.windowView = this.PICK_HARDWARE_WINDOW_ITEM;
			this.dialogOpen = true;
		},
		onPickSoftware () {
			this.windowView = this.PICK_SOFTWARE_WINDOW_ITEM;
			this.dialogOpen = true;
		},
		onPickStorageType () {
			this.windowView = this.PICK_STORAGE_TYPE_WINDOW_ITEM;
			this.dialogOpen = true;
		},
		onPickStorageOption () {
			this.windowView = this.PICK_STORAGE_OPTION_WINDOW_ITEM;
			this.dialogOpen = true;
		},
		onHardwareSelect (hardwareOffer: HardwareOffer) {
			this.shouldAttemptHydrateFromSessionPrefill = false;
			this.hardwareOffer = hardwareOffer || null;

			if (this.isWizardFlow) {
				this.onWindowViewForward();
			} else {
				this.dialogOpen = false;
			}
		},
		onSoftwareSelect ({ softwareOffer, keepView }) {
			this.shouldAttemptHydrateFromSessionPrefill = false;
			this.softwareOffer = softwareOffer || null;

			if (!keepView) {
				if (this.isWizardFlow) {
					this.onWindowViewForward();
				} else {
					this.dialogOpen = false;
				}
			}
		},
		onStorageTypeSelect (storageType: string) {
			this.selectedStorageType = storageType || SELECTED_STORAGE_TYPE.PRIVATE;
			setSelectedStorageType(this.selectedStorageType);

			if (this.isWizardFlow) {
				this.onWindowViewForward();
			} else {
				this.dialogOpen = false;
			}
		},
		onWorkspaceSelect (workspace: Workspace) {
			this.selectedWorkspaceId = (workspace && workspace.id) || null;
			if (this.selectedWorkspaceId) {
				setWorkspaceId(this.selectedWorkspaceId);
			} else {
				removeWorkspaceId();
			}

			if (this.isWizardFlow) {
				this.onWindowViewForward();
			} else {
				this.dialogOpen = false;
			}
		},
		onSelectStorageOption (storageOptionValue: string) {
			if (this.isWizardFlow) {
				this.onWindowViewForward();
			} else {
				this.dialogOpen = false;
			}
		},
		async onLaunch () {
			if (this.launchDisabled) {
				return;
			}
			if (getProxyAdminToken() && !confirm(`You are proxying another user. Are you sure you want to use their account's balance to launch a session?`)) {
				return;
			}

			this.purchasing = true;
			const apps: AppKey[] = [this.appOffer.app];
			const backendApps: AppKey[] = this.appOffer.backendApps || [];
			const options: SessionOptions = {
				// extensions: [],
				// civitaiModels: [],
			};
			const { hardware } = this.hardwareOffer;
			const { software } = this.softwareOffer;

			const {
				success,
				sessionUuid,
			} = await this.purchaseSession({
				apps,
				backendApps,
				hardware,
				software,
				options,
				apiEnabled: this.apiEnabled,
				persistExtensions: !!(
					this.shouldShowPersistExtensionsSetting &&
					this.persistExtensions
				),
				preloadModel: this.shouldSetCivitaiPreloadModel ? {
					provider: 'civitai',
					modelId: this.preloadModel.civitai.model.id,
					modelVersionId: this.preloadModel.civitai.modelVersion.id,
					downloadUrl: this.preloadModel.civitai.modelVersion.downloadUrl,
				} : null,
				purchaseMins: this.purchaseMins,
				displayName: `${this.currSessions.length + 1} - Session`,
				isTeamSession: !!(this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM && this.selectedWorkspaceId),
				workspaceId: this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM ? this.selectedWorkspaceId : null,
				region: (this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM ? _get(this.team, 'region') : _get(this.user, 'region')) || null,
			});
			if (!success) {
				// Allow to try again
				this.purchasing = false;
			} else {
				this.routerReplace(this.$route, this.$router, {
					...this.$route,
					query: {
						...this.$route.query,
						...(['AnotherSession', 'SetupAnotherSession'].includes(this.$route.name) && {
							awaitSession: sessionUuid,
						}),
					},
				});
			}
		},
	},
	components: {
		UnlockFreemium,
		PreloadModelPreview,
		EnablePersistExtensionsSettings,
		InfoMessageSmall,
		WindowStepper,
		ConfirmDialog,
		LoadingSVG,
		StorageTypesList,
		StorageOptionsList,
		BackButton,
		SetupSessionTerms,
		HardwareOffersList,
		SoftwareOffersList,
		FullSelectionPreviewCard,
		ActionsIsland,
		StorageCapCard,
		EnableApiSettings,
		HowToStopEarlyNotice,
		SessionSoundsSettings,
		TimeYourSessionInputs,
		BalanceCard,
	},
});
