<template>
	<div
		:style="containerStyles"
		class="stacking-layers-container text-center"
	>
		<div
			:style="layerOneStyles"
			class="stacking-layers-container-layer-1"
		>
			<slot name="layer-1"></slot>
		</div>
		<div
			:style="layerTwoStyles"
			class="stacking-layers-container-layer-2"
		>
			<slot name="layer-2"></slot>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'StackLayers',
	props: {
		containerStyles: { type: [String, Object], default: null },
		layerOneStyles: { type: [String, Object], default: null },
		layerTwoStyles: { type: [String, Object], default: null },
	},
});
</script>

<style lang="scss" scoped>
.stacking-layers-container {
	display: grid;

	&-layer-1, &-layer-2 {
		grid-column: 1;
		grid-row: 1;
	}
}
</style>
