
import Vue from 'vue';
import { db } from '@/firebase';
import _keys from 'lodash/keys';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import { mapActions, mapState } from 'vuex';
import { Company, Team, User } from '@run-diffusion/shared';
import { SNACKBAR_STATUS } from '@/constants/constants';
import TeamCard from '@/components/TeamCard.vue';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import EmptyState from '@/components/states/EmptyState.vue';

export default Vue.extend({
	name: 'TeamPicker',
	props: {
		title: { type: String, default: 'Switch to Team Account' },
		allowCompanySelection: { type: Boolean, default: false },
		selectedCompany: { type: Object, default: null },
		allowNewTeamCreation: { type: Boolean, default: false },
		openTilSelectionIsMade: { type: Boolean, default: false },
	},
	data () {
		return {
			dialogValue: false,

			awaitingSelectionTeamId: null,
			awaitingSelectionTimeout: null,

			pageSize: 100,
			canLoadMoreTeamsList: false,
			teamsList: [],
			companiesList: [],
			loadingInitialTeamsList: false,
			loadingMoreTeamsList: false,
		};
	},
	computed: {
		...mapState([
			'team',
			'user',
		]),
		showTeamPicker () {
			if (!this.user) return false;
			const numTeams: number = _keys(this.user.teamIds || {}).length;
			return !!(
				this.user &&
				(
					numTeams > 1 ||
					(
						this.allowNewTeamCreation &&
						numTeams > 0
					)
				)
			);
		},
		dialogPersistent () {
			return !!(this.openTilSelectionIsMade && !this.team);
		},
		dialogOpen () {
			return !!(
				this.dialogValue ||
				(this.openTilSelectionIsMade && !this.team)
			);
		},
	},
	created () {
		if (this.user && this.user.id) {
			this.loadInitialTeamsList(this.dialogOpen, this.user);
		}
	},
	destroyed () {
		if (this.awaitingSelectionTimeout) clearTimeout(this.awaitingSelectionTimeout);
	},
	watch: {
		dialogOpen (newVal: boolean, oldVal: boolean) {
			if (newVal !== oldVal) {
				this.loadInitialTeamsList(newVal, this.user);
			}
		},
		user (newVal: User, oldVal: User) {
			if (newVal !== oldVal) {
				this.loadInitialTeamsList(this.dialogOpen, newVal);
			}
		},
		team (newVal: Team) {
			if (this.awaitingSelectionTeamId && newVal && newVal.id === this.awaitingSelectionTeamId) {
				if (this.awaitingSelectionTimeout) clearTimeout(this.awaitingSelectionTimeout);
				this.awaitingSelectionTeamId = null;
				this.onClose();
			}
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'bindTeam',
			'unbindTeam',
			'bindStripeTeamsCustomer',
			'unbindStripeTeamsCustomer',
			'bindTeamBalanceAccount',
			'unbindTeamBalanceAccount',
			'bindWorkspaces',
			'unbindWorkspaces',
			'bindWorkshops',
			'unbindWorkshops',
		]),
		onClose () {
			this.dialogValue = false;
		},
		onNewTeamBtnSelect () {
			this.$emit('on-new-team-btn-select');
		},
		onCompanySelect () {
			this.$emit('on-company-select', this.companiesList[0]);
			this.onClose();
		},
		async onTeamSelect (team: Team) {
			if (this.awaitingSelectionTimeout) clearTimeout(this.awaitingSelectionTimeout);

			if (team) {
				this.bindTeam(team.id);
				this.bindStripeTeamsCustomer(team.id);
				this.bindTeamBalanceAccount(team.id);
				this.bindWorkspaces(team.id);
				this.bindWorkshops(team.id);

				this.awaitingSelectionTeamId = team.id;
				this.awaitingSelectionTimeout = setTimeout(() => {
					this.awaitingSelectionTeamId = null;
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'That team took too long to load. Please try again, or reach out to report issues by clicking the support button in our top toolbar.',
						show: true,
					});
				}, 10000);
				this.$emit('on-team-select', team);
				this.$emit('on-company-select', null);
			} else {
				this.unbindTeam();
				this.unbindStripeTeamsCustomer();
				this.unbindTeamBalanceAccount();
				this.unbindWorkspaces();
				this.unbindWorkshops();
			}
		},
		async fetchTeamsList (lastTeam: Team, userTeamIds: string[]) {
			let userTeamIdsToFetch: string[] = userTeamIds || [];
			if (lastTeam) {
				let isAfterLastTeam: boolean = false;
				userTeamIdsToFetch = userTeamIdsToFetch
					.filter((teamId: string) => {
						const shouldInclude: boolean = isAfterLastTeam;
						if (teamId === lastTeam.id) isAfterLastTeam = true;
						return shouldInclude;
					});
			}
			userTeamIdsToFetch = userTeamIdsToFetch.slice(0, this.pageSize);
			if (!userTeamIdsToFetch.length) {
				return {
					success: true,
					list: [],
				};
			}

			try {
				const teamsResultList: Team[] = await Promise.all(userTeamIdsToFetch.map(async (teamId: string) => {
					const teamRef = db
						.doc(`teams/${teamId}`);
					const team = {
						...(await teamRef.get()).data(),
						id: teamRef.id,
					} as Team;
					if (team.companyId) {
						const companyRef = db
							.doc(`companies/${team.companyId}`);
						team.company = {
							...(await companyRef.get()).data(),
							id: companyRef.id,
						} as Company;
					} else {
						team.company = null;
					}

					return team;
				}));
				return {
					success: true,
					list: _sortBy(teamsResultList, 'name'),
				};
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading teams, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
				return {
					success: false,
					list: [],
				};
			}
		},
		async loadInitialTeamsList (dialogOpen: boolean, user: User) {
			if (!this.loadingInitialTeamsList && !this.loadingMoreTeamsList && dialogOpen) {
				this.loadingInitialTeamsList = true;
				this.companiesList = [];

				const userTeamIds: string[] = _keys((user && user.teamIds) || {});
				const { success, list } = await this.fetchTeamsList(null, userTeamIds);

				this.loadingInitialTeamsList = false;
				if (success) {
					this.teamsList = list;
					this.canLoadMoreTeamsList = this.teamsList.length < userTeamIds.length;

					// Aggregate uniq companies into a list
					const companies: Company[] = this.teamsList
						.map((team: Team) => team.company)
						.filter((company: Company) => company);
					this.companiesList = _uniqBy(companies, 'id');
				}
			}
		},
		async loadMoreTeamsList () {
			if (!this.loadingInitialTeamsList && !this.loadingMoreTeamsList) {
				this.loadingMoreTeamsList = true;

				const userTeamIds: string[] = _keys((this.user && this.user.teamIds) || {});
				const { success, list } = await this.fetchTeamsList(
					this.teamsList.length ? this.teamsList[this.teamsList.length - 1] : null,
					userTeamIds,
				);

				this.loadingMoreTeamsList = false;
				if (success) {
					this.teamsList = this.teamsList.concat(list);
					this.canLoadMoreTeamsList = this.teamsList.length < userTeamIds.length;
				}
			}
		},
	},
	components: {
		TeamCard,
		EmptyState,
		LoadingSVG,
	},
});
