
import Vue from 'vue';
import _trim from 'lodash/trim';
import { db, functions } from '@/firebase';
import { mapState, mapActions } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { CHECKOUT_TEAM_PLAN_SUBSCRIPTION } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'EditTeamDialog',
	props: {
		value: { type: Boolean, default: false },
		editMode: { type: Boolean, default: false },
		newTeamMode: { type: Boolean, default: false },
	},
	data() {
		return {
			// FORM
			formValid: true,
			rules: {
				required: [
					v => !!_trim(v) || 'Required',
				],
			},
			teamName: null,
			survey: {
				companyName: {
					label: 'What is your company name, if applicable?',
					value: null,
					type: 'TEXT',
				},
				whatWorkingOn: {
					label: 'How do you plan on integrating AI image generation into your projects?',
					value: null,
					type: 'TEXT',
				},
				whoMainUsers: {
					label: 'Who will be the main users within your organization?',
					value: null,
					type: 'TEXT',
				},
				supportNeeded: {
					label: 'What sort of training or support do you anticipate needing?',
					value: null,
					type: 'TEXT',
				},
			},
			saving: false,

			windowView: 0,
			CHANGE_NAME_WINDOW_ITEM: 0,
			GO_TO_PAYMENT_TAB_WINDOW_ITEM: 1,
		};
	},
	computed: {
		...mapState([
			'team',
			'user',
		]),
		showTeamSetupSurvey () {
			return !!(this.newTeamMode && !this.team);
		},
	},
	async created() {
		if (this.team && this.team.id) {
			this.initTeamName(this.team);
		}
	},
	watch: {
		async team (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.initTeamName(newVal);
			}
		},
	},
	methods: {
		...mapActions([
			'bindTeam',
			'bindStripeTeamsCustomer',
			'bindTeamBalanceAccount',
			'bindWorkspaces',
			'bindWorkshops',
			'updateSnackbar',
		]),
		setOpen (val: boolean) {
			this.$emit('input', val);
		},
		onClose () {
			this.setOpen(false);
		},
		openStripePaymentTab () {
			const routeData = this.$router.resolve({
				name: 'GoToPayment',
				query: {
					checkoutAction: CHECKOUT_TEAM_PLAN_SUBSCRIPTION,
				},
			});
			window.open(routeData.href, '_blank');
		},
		async onSave () {
			if (!this.$refs.form.validate()) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Form invalid, check fields',
					show: true,
				});
				return;
			}
			const trimmedTeamName: string = _trim(this.teamName);
			const existingTeamOnboarding: boolean = !!(
				this.team &&
				this.team.isOnboarding
			);
			if (this.newTeamMode && !existingTeamOnboarding) {
				const onError: Function = (e) => {
					console.error(e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error saving team, please reach out to report issues by clicking the support button in our top toolbar',
						show: true,
					});
				};
				try {
					this.saving = true;
					const functionRef = functions
						.httpsCallable('setupNewTeam');
					const { success, teamId } = (await functionRef({
						teamName: trimmedTeamName,
						userId: this.user.id,
						...(this.showTeamSetupSurvey && {
							survey: this.survey,
						}),
					})).data;
					if (!success) {
						onError(new Error('setupNewTeam returned success: false'));
					}

					// Update Vuex
					this.bindTeam(teamId);
					this.bindStripeTeamsCustomer(teamId);
					this.bindTeamBalanceAccount(teamId);
					this.bindWorkspaces(teamId);
					this.bindWorkshops(teamId);
					this.windowView = this.GO_TO_PAYMENT_TAB_WINDOW_ITEM;
				} catch (e) {
					onError(e);
				} finally {
					this.saving = false;
				}
			} else {
				this.saving = true;
				try {
					const teamRef = db.doc(`teams/${this.team.id}`);
					await teamRef
						.update({
							name: trimmedTeamName,
						});
					if (existingTeamOnboarding) {
						this.windowView = this.GO_TO_PAYMENT_TAB_WINDOW_ITEM;
					} else {
						this.updateSnackbar({
							status: SNACKBAR_STATUS.SUCCESS,
							message: `Success! Team name ${trimmedTeamName} saved.`,
							show: true,
						});
						this.onClose();
					}
				} catch (e) {
					console.error(e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error saving team, please reach out to report issues by clicking the support button in our top toolbar',
						show: true,
					});
				} finally {
					this.saving = false;
				}
			}
		},
		initTeamName (team) {
			if (
				team &&
				(
					(
						team.name &&
						!this.newTeamMode
					) ||
					team.isOnboarding
				)
			) {
				this.teamName = team.name;
			}
		},
	},
});
