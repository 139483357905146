
import Vue from 'vue';

export default Vue.extend({
	name: 'ComplexBackgroundMobileToolbar',
	props: {
		includeLeftBlobs: { type: Boolean, default: false },
		blur: { type: Number, default: 50 },
	},
	data () {
		return {};
	},
});
