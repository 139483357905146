
import Vue from 'vue';
import _find from 'lodash/find';
import _trim from 'lodash/trim';
import { mapActions, mapState } from 'vuex';
import { functions } from '@/firebase';
import {
	AppOffer,
	HardwareOffer,
	SoftwareOffer,
	WorkshopSession,
	Workspace,
	getHardwareHourlyPrice,
} from '@run-diffusion/shared';
import { SELECTED_STORAGE_TYPE, SNACKBAR_STATUS } from '@/constants/constants';
import { getWorkspaceId } from '@/utils/localStorage';
import BaseDrawer from '@/components/base/BaseDrawer.vue';
import DrawerSettingsRow from '@/components/base/DrawerSettingsRow.vue';
import AppOffersList from '@/views/Sessions/AppOffersList/AppOffersList.vue';
import DrawerSettingsContainer from '@/components/base/DrawerSettingsContainer.vue';
import HardwareOffersList from '@/views/Sessions/HardwareOffersList/HardwareOffersList.vue';
import SoftwareOffersList from '@/views/Sessions/SoftwareOffersList/SoftwareOffersList.vue';
import StorageOptionsList from '@/views/Sessions/StorageOptionsList/StorageOptionsList.vue';
import FullSelectionPreviewCard from '@/views/Sessions/SetupSession/FullSelectionPreviewCard.vue';
import TimeYourSessionInputs from '@/components/TimeYourSessionInputs.vue';
import WorkshopSessionCostPreview from '@/views/Workshops/WorkshopPage/AddEditWorkshopSession/WorkshopSessionCostPreview.vue';

export default Vue.extend({
	name: 'AddEditWorkshopSession',
	props: {
		workshop: { type: Object, default: null },
		workshopSession: { type: Object, default: null },
		isEditMode: { type: Boolean, default: false },
		value: { type: Boolean, default: false },
	},
	data () {
		let capacityOptions: { value: number, text: string }[] = [];
		for (let i = 1; i <= 50; i++) {
			capacityOptions.push({ value: i, text: String(i) });
		}

		return {
			SELECTED_STORAGE_TYPE,
			open: false,
			displayName: null,
			dialogOpen: false,

			appOffer: null,
			hardwareOffer: null,
			softwareOffer: null,
			storageOption: null,
			selectedWorkspaceId: null,
			selectedScheduledStopInHrs: 1,
			selectedScheduledStopInMins: 0,

			savingWorkshopSession: false,

			sessionSetupChoice: null,
			SESSION_SETUP_CHOICE: {
				APP: 'APP',
				HARDWARE: 'HARDWARE',
				SOFTWARE: 'SOFTWARE',
				WORKSPACE: 'WORKSPACE',
			},

			selectedCapacity: 50,
			capacityOptions,

			// FORM
			formValid: true,
			rules: {
				required: [
					v => !!_trim(v) || 'Required',
				],
			},
		};
	},
	computed: {
		...mapState([
			'team',
			'workspaces',
			'appOffersMap',
			'hardwareOffersMap',
			'softwareOffersMap',
		]),
		hardwareHourlyPrice () {
			if (!this.hardwareOffer) return 0;
			return getHardwareHourlyPrice(this.hardwareOffer, this.team.id, null);
		},
		scheduledStopMins () {
			if (
				this.selectedScheduledStopInHrs ||
				this.selectedScheduledStopInMins
			) {
				return (this.selectedScheduledStopInHrs * 60) + this.selectedScheduledStopInMins;
			}
			return 0;
		},
		invalidScheduledStopMins () {
			return !this.scheduledStopMins;
		},
		shouldEnableAdd () {
			return !!(
				this.displayName &&
				this.appOffer &&
				this.hardwareOffer &&
				this.softwareOffer &&
				this.selectedWorkspaceId &&
				this.formValid
			);
		},
		dialogTitle () {
			if (this.sessionSetupChoice === this.SESSION_SETUP_CHOICE.APP) return 'Choose your application';
			if (this.sessionSetupChoice === this.SESSION_SETUP_CHOICE.HARDWARE) return 'Choose the hardware to run the application on';
			if (this.sessionSetupChoice === this.SESSION_SETUP_CHOICE.SOFTWARE) return 'Choose the version of software to run';
			if (this.sessionSetupChoice === this.SESSION_SETUP_CHOICE.WORKSPACE) return 'Choose which Space';
			return 'Setup Session';
		},
	},
	watch: {
		value: {
			immediate: true,
			handler (newVal: boolean) {
				this.open = !!newVal;
				if (newVal && this.isEditMode) {
					this.initOffers();
				}
			},
		},
		workshopSession: {
			immediate: true,
			handler (newVal: WorkshopSession, oldVal: WorkshopSession) {
				if (!oldVal && this.isEditMode) {
					this.initWorkshopSession(newVal);
				} else if (oldVal && newVal) {
					this.initWorkshopSession(newVal);
				}
			},
		},
		workspaces: {
			immediate: true,
			handler (newVal: Workspace[]) {
				const lastSelectedWorkspaceId: string = this.selectedWorkspaceId;
				const localStorageWorkspaceId: string = getWorkspaceId();
				let selectedWorkspace: Workspace = null;

				if (newVal && newVal.length) {
					if (!selectedWorkspace && lastSelectedWorkspaceId) {
						selectedWorkspace = _find(newVal, ['id', lastSelectedWorkspaceId]) || null;
					}
					if (!selectedWorkspace && localStorageWorkspaceId) {
						selectedWorkspace = _find(newVal, ['id', localStorageWorkspaceId]) || null;
					}
					if (!selectedWorkspace) {
						selectedWorkspace = newVal[0];
					}

					this.selectedWorkspaceId = selectedWorkspace ? selectedWorkspace.id : null;
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		closeWorkshopSessionDrawer () {
			this.setOpen(false);
			this.resetWorkshopSessionDrawer();
			this.$emit('on-close');
		},
		resetWorkshopSessionDrawer () {
			this.displayName = null;
		},
		initWorkshopSession (workshopSession: WorkshopSession) {
			this.displayName = workshopSession.displayName || null;
			this.selectedWorkspaceId = workshopSession.workspaceId || null;

			const scheduledStopMins: number = workshopSession.scheduledStopMins || 0;
			this.selectedScheduledStopInHrs = Math.floor(scheduledStopMins / 60);
			this.selectedScheduledStopInMins = scheduledStopMins % 60;
		},
		initOffers () {
			this.appOffer = this.appOffersMap[this.workshopSession.apps[0]] || null;
			this.softwareOffer = this.softwareOffersMap[this.workshopSession.software] || null;
			this.hardwareOffer = this.hardwareOffersMap[this.workshopSession.hardware] || null;
		},
		async onCreateWorkshopSession () {
			try {
				this.savingWorkshopSession = true;
				const onError: Function = (e) => {
					console.error('Error adding a workshop session: ', e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error! Problem with adding a workshop session, please reach out to report issues by clicking the support button in our top toolbar',
						show: true,
					});
				};
				const functionRef = functions
					.httpsCallable('createWorkshopSession');

				const { success } = (await functionRef({
					createdAt: new Date(),
					displayName: this.displayName,
					workshopId: this.workshop.id,
					software: this.softwareOffer.software,
					hardware: this.hardwareOffer.hardware,
					apps: [this.appOffer.app],
					workspaceId: this.selectedWorkspaceId,
					capacity: this.selectedCapacity,
					scheduledStopMins: this.selectedScheduledStopInHrs * 60 + this.selectedScheduledStopInMins,
				})).data;

				if (!success) {
					onError(new Error('createWorkshopSession returned undefined response'));
				} else {
					await this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! ${this.displayName} Workshop added.`,
						show: true,
					});
				}

				this.$emit('on-create-success');
				this.closeWorkshopSessionDrawer();
			} catch (e) {
				console.error(e);
				await this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error adding workshop session, try again or open a ticket in Discord',
					show: true,
				});
			} finally {
				this.savingWorkshopSession = false;
			}
		},
		async onUpdateWorkshopSession () {
			try {
				this.savingWorkshopSession = true;
				const onError: Function = (e) => {
					console.error('Error updating a workshop session: ', e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error! Problem with updating a workshop session, please reach out to report issues by clicking the support button in our top toolbar',
						show: true,
					});
				};
				const functionRef = functions
					.httpsCallable('updateWorkshopSession');

				const { success } = (await functionRef({
					createdAt: new Date(),
					displayName: this.displayName,
					workshopId: this.workshop.id,
					workshopSessionId: this.workshopSession.id,
					software: this.softwareOffer.software,
					hardware: this.hardwareOffer.hardware,
					apps: [this.appOffer.app],
					workspaceId: this.selectedWorkspaceId,
					capacity: this.selectedCapacity,
					scheduledStopMins: this.selectedScheduledStopInHrs * 60 + this.selectedScheduledStopInMins,
				})).data;

				if (!success) {
					onError(new Error('updateWorkshopSession returned undefined response'));
				} else {
					await this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! ${this.displayName} Workshop Session updated.`,
						show: true,
					});
				}

				this.$emit('on-update-success');
				this.closeWorkshopSessionDrawer();
			} catch (e) {
				console.error(e);
				await this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error updating workshop session, try again or open a ticket in Discord',
					show: true,
				});
			} finally {
				this.savingWorkshopSession = false;
			}
		},
		onPickApp () {
			this.sessionSetupChoice = this.SESSION_SETUP_CHOICE.APP;
			this.dialogOpen = true;
		},
		onAppSelect (appOffer: AppOffer) {
			this.appOffer = appOffer || null;
			this.hardwareOffer = null;
			this.softwareOffer = null;

			this.dialogOpen = false;
		},
		onPickHardware () {
			this.sessionSetupChoice = this.SESSION_SETUP_CHOICE.HARDWARE;
			this.dialogOpen = true;
		},
		onHardwareSelect (hardwareOffer: HardwareOffer) {
			this.hardwareOffer = hardwareOffer || null;

			this.dialogOpen = false;
		},
		onPickSoftware () {
			this.sessionSetupChoice = this.SESSION_SETUP_CHOICE.SOFTWARE;
			this.dialogOpen = true;
		},
		onSoftwareSelect (selection: { softwareOffer: SoftwareOffer, keepView: boolean }) {
			this.softwareOffer = selection.softwareOffer || null;

			this.dialogOpen = false;
		},
		onPickWorkspace () {
			this.sessionSetupChoice = this.SESSION_SETUP_CHOICE.WORKSPACE;
			this.dialogOpen = true;
		},
		onWorkspaceSelect (workspace: Workspace) {
			this.selectedWorkspaceId = (workspace && workspace.id) || null;

			this.dialogOpen = false;
		},
		handleSelectedCapacityInput (selectedCapacity: number) {
			this.selectedCapacity = selectedCapacity;
		},
	},
	components: {
		WorkshopSessionCostPreview,
		TimeYourSessionInputs,
		AppOffersList,
		BaseDrawer,
		DrawerSettingsRow,
		StorageOptionsList,
		SoftwareOffersList,
		HardwareOffersList,
		FullSelectionPreviewCard,
		DrawerSettingsContainer,
	},
});
