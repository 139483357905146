<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		style="background: transparent; display: block;"
		:width="width"
		:height="height"
		:viewBox="viewBox"
		preserveAspectRatio="xMidYMid"
	>
		<defs><radialGradient id="ldio-m6uleey5rzm-gradient" cx="0.5" cy="0.5" fx="0" fy="0" r="2">
			<stop offset="0%" stop-color="#6f86ff"></stop>
			<stop offset="100%" stop-color="#ff7586"></stop>
		</radialGradient></defs>
		<g>
			<circle cx="50" cy="50" r="30" stroke="#b372ce" stroke-width="12" fill="none" stroke-opacity="0.7"></circle>
			<circle cx="50" cy="50" r="30" stroke="url(#ldio-m6uleey5rzm-gradient)" stroke-width="10" fill="none"></circle>
			<animateTransform attributeName="transform" type="rotate" values="0 50 50;360 50 50" times="0;1" dur="0.9615384615384615s" repeatCount="indefinite"></animateTransform>
		</g>
	</svg>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'EnabledApiSVG',
	props: {
		iconName: {
			type: String,
			default: 'Enabled API Icon',
		},
		width: {
			type: [Number, String],
			default: 35,
		},
		height: {
			type: [Number, String],
			default: 35,
		},
		viewBox: {
			type: String,
			default: '0 0 100 100',
		},
	},
});
</script>
