
import Vue from 'vue';
import { mapState } from 'vuex';
import { MyBalanceMixin } from '@/mixins';
import _isNil from 'lodash/isNil';
import TokensSVG from '@/assets/TokensSVG.vue';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';

export default Vue.extend({
	name: 'RunnitTokens',
	mixins: [
		MyBalanceMixin,
	],
	props: {
		background: { type: String, default: 'rgba(0, 0, 0, 0.6)' },
		tokenSize: { type: Number, default: 12 },
		tokens: { type: Number, default: null },
	},
	computed: {
		...mapState([
			'runnitState',
			'loadingTeamBalanceAccount',
			'loadingUserBalanceAccount',
		]),
		isRunnitTokensLoading () {
			if (!_isNil(this.tokens)) return false;

			if (!this.runnitState) return true;

			if (this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM) {
				return !!this.loadingTeamBalanceAccount;
			}

			return !!this.loadingUserBalanceAccount;
		},
		localRunnitTokens () {
			if (!_isNil(this.tokens)) return this.tokens;
			return this.runnitTokens || null;
		},
	},
	components: {
		TokensSVG,
	},
});
