import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import {
	calcRealtimeBalanceInfo,
} from '@run-diffusion/shared';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import { RUNNIT_TYPE } from '@/constants/enums';

export const MyBalanceMixin = Vue.extend({
	computed: {
		...mapState([
			'realTimeMoment',
			'user',
			'team',
			'userBalanceAccount',
			'teamBalanceAccount',
			'runnitState',
		]),
		...mapGetters([
			'clubOfferBenefits',
		]),
		userRunnitTokens () {
			if (
				this.user &&
				this.userBalanceAccount
			) {
				const { realtimeRunnitTokens } = calcRealtimeBalanceInfo(this.userBalanceAccount);
				return realtimeRunnitTokens;
			}
			return 0;
		},
		teamRunnitTokens () {
			if (
				this.team &&
				this.teamBalanceAccount
			) {
				const { realtimeRunnitTokens } = calcRealtimeBalanceInfo(this.teamBalanceAccount);
				return realtimeRunnitTokens;
			}
			return 0;
		},
		runnitTokens () {
			if (!this.runnitState) return null;

			if (this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER) {
				return this.userRunnitTokens;
			}
			if (this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM) {
				if (!this.team) return null;
				return this.teamRunnitTokens;
			}

			return null;
		},
		chooseBalanceOptionDisplay () {
			if (!this.runnitState.runnit) return null;
			if (this.runnitState.runnit && (
				this.runnitState.runnit.teamId || (
					this.runnitState.runnit.type === RUNNIT_TYPE.SINGLE_TOOL &&
					this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM
				))) {
				if (!this.team) return null;
				return {
					isUser: false,
					isTeam: true,
					label: this.team.name,
				};
			} else {
				return {
					isUser: true,
					isTeam: false,
					label: 'Personal Balance',
				};
			}
		},
		userRecurringBalance () {
			if (
				this.user &&
				this.userBalanceAccount
			) {
				const { realtimeRecurringBalance } = calcRealtimeBalanceInfo(this.userBalanceAccount);
				return realtimeRecurringBalance;
			}
			return 0;
		},
		teamRecurringBalance () {
			if (
				this.team &&
				this.teamBalanceAccount
			) {
				const { realtimeRecurringBalance } = calcRealtimeBalanceInfo(this.teamBalanceAccount);
				return realtimeRecurringBalance;
			}
			return 0;
		},
		isUserRecurringBalanceExpiring1 () {
			return !!(
				this.user &&
				this.userBalanceAccount &&
				!this.clubOfferBenefits.isCc &&
				this.userRecurringBalance &&
				this.userBalanceAccount.recurringExpiresAt1 &&
				this.realTimeMoment.isBefore(this.userBalanceAccount.recurringExpiresAt1.toMillis())
			);
		},
		isUserRecurringBalanceExpiring2 () {
			return !!(
				!this.isUserRecurringBalanceExpiring1 &&
				this.user &&
				this.userBalanceAccount &&
				!this.clubOfferBenefits.isCc &&
				this.userRecurringBalance &&
				this.userBalanceAccount.recurringExpiresAt2 &&
				this.realTimeMoment.isBefore(this.userBalanceAccount.recurringExpiresAt2.toMillis())
			);
		},
		isTeamRecurringBalanceExpiring1 () {
			return !!(
				this.team &&
				this.teamBalanceAccount &&
				!this.team.isActive &&
				this.teamRecurringBalance &&
				this.teamBalanceAccount.recurringExpiresAt1 &&
				this.realTimeMoment.isBefore(this.teamBalanceAccount.recurringExpiresAt1.toMillis())
			);
		},
		isTeamRecurringBalanceExpiring2 () {
			return !!(
				!this.isTeamRecurringBalanceExpiring1 &&
				this.team &&
				this.teamBalanceAccount &&
				!this.team.isActive &&
				this.teamRecurringBalance &&
				this.teamBalanceAccount.recurringExpiresAt2 &&
				this.realTimeMoment.isBefore(this.teamBalanceAccount.recurringExpiresAt2.toMillis())
			);
		},
		isUserRecurringBalanceActive () {
			return !!(
				this.user &&
				this.clubOfferBenefits.isCc &&
				this.userBalanceAccount
			);
		},
		isTeamRecurringBalanceActive () {
			return !!(
				this.team &&
				!this.team.isOnboarding &&
				this.team.isActive &&
				this.teamBalanceAccount
			);
		},
		computedUserBalance () {
			const { realtimeBalance } = calcRealtimeBalanceInfo(this.userBalanceAccount);
			let result: number = realtimeBalance;

			if (this.isUserRecurringBalanceActive) {
				result += this.userRecurringBalance;
			}

			return result > 0 ? result : 0;
		},
		computedTeamBalance () {
			const { realtimeBalance } = calcRealtimeBalanceInfo(this.teamBalanceAccount);
			let result: number = realtimeBalance;

			if (this.isTeamRecurringBalanceActive) {
				result += this.teamRecurringBalance;
			}

			return result > 0 ? result : 0;
		},
	},
});
