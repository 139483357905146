
import Vue from 'vue';
import _take from 'lodash/take';
import { mapState, mapActions, mapGetters } from 'vuex';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import { SELECTED_STORAGE_TYPE } from '@/constants/constants';
import { TeamUserMixin } from '@/mixins';
import { PRIVATE_STORAGE_CAP_BYTES, SHARED_STORAGE_CAP_BYTES } from '@/components/StorageCapCard/utils';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'StorageCapCard',
	mixins: [
		TeamUserMixin,
	],
	props: {
		styles: { type: [String, Object], default: null },
		selectionEnabled: { type: Boolean, default: false },
		selectedStorageType: { type: String, default: null },
	},
	data () {
		return {
			ROUTER,
			SHARED_STORAGE_CAP_BYTES,
			PRIVATE_STORAGE_CAP_BYTES,
			isDisclaimerDialogOpen: false,
		};
	},
	computed: {
		...mapState([
			'user',
			'team',
			'workspaces',
		]),
		...mapGetters([
			'clubOfferBenefits',
		]),
		firstFewWorkspaces () {
			return _take(this.workspaces, 3);
		},
		manageTeamStorageText () {
			if (this.firstFewWorkspaces.length < this.workspaces.length) return 'See more team storage';
			if (this.isTeamAdmin) return 'Manage team storage';
			return 'View team storage';
		},
		teamSelected () {
			return this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM;
		},
		shouldShowLabels () {
			return !!(
				this.team ||
				this.clubOfferBenefits.isCc
			);
		},
		isOverSharedStorage () {
			return this._get(this.user, 'sharedStorageBytes') > SHARED_STORAGE_CAP_BYTES;
		},
	},
	methods: {
		...mapActions([
			'setUpsellDialog',
		]),
		openDisclaimerDialog () {
			this.isDisclaimerDialogOpen = true;
		},
	},
	components: {
		ConfirmDialog,
	},
});
