
import Vue from 'vue';
import { VBtn, VIcon } from 'vuetify/lib';
import _debouce from 'lodash/debounce';

export default Vue.extend({
	name: 'RunnitCarousel',
	props: {
		items: { type: Array, default: () => [] },
		gap: { type: String, default: '16px' },
		includeViewAll: { type: Boolean, default: false },
		viewAllText: { type: String, default: 'View All' },
		viewAllStyles: { type: Object, default: () => { } }
	},
	data () {
		return {
			updateScrollStateDebounce: () => { },
			canScrollLeft: false,
			canScrollRight: false,
		};
	},
	mounted () {
		this.updateScrollStateDebounce = _debouce(this.updateScrollState, 100);
		this.updateScrollStateDebounce();
		window.addEventListener('resize', this.updateScrollStateDebounce);
		const wrapper = this.$refs.carouselWrapper as HTMLElement;
		if (wrapper) {
			wrapper.addEventListener('scroll', this.updateScrollStateDebounce);
		}
	},
	beforeDestroy () {
		const wrapper = this.$refs.carouselWrapper as HTMLElement;
		if (wrapper) {
			wrapper.removeEventListener('scroll', this.updateScrollStateDebounce);
		}
		window.removeEventListener('resize', this.updateScrollStateDebounce);
	},
	watch: {
		items: {
			immediate: true,
			handler () {
				this.updateScrollStateDebounce();
			}
		},
	},
	methods: {
		scrollLeft () {
			const wrapper = this.$refs.carouselWrapper as HTMLElement;
			wrapper.scrollBy({ left: -wrapper.offsetWidth, behavior: 'smooth' });
			this.updateScrollStateDebounce();
		},
		scrollRight () {
			const wrapper = this.$refs.carouselWrapper as HTMLElement;
			wrapper.scrollBy({ left: wrapper.offsetWidth, behavior: 'smooth' });
			this.updateScrollStateDebounce();
		},
		updateScrollState () {
			const wrapper = this.$refs.carouselWrapper as HTMLElement;
			if (!wrapper) return;

			this.canScrollLeft = wrapper.scrollLeft > 0;
			this.canScrollRight =
				wrapper.scrollLeft + wrapper.offsetWidth < wrapper.scrollWidth;
		},
	},
});
