
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import {
	EMAIL_REGEX,
	SHOW_TEXT_FIELD_COUNTER_AT,
	SNACKBAR_STATUS,
	TEXT_FIELD_MAX_LENGTH,
	TEXT_FIELD_RULE,
} from '@/constants/constants';
import BackButton from '@/components/base/BackButton.vue';
import { db, functions } from '@/firebase';
import firebase from 'firebase/app';
import _trim from 'lodash/trim';
import AdminUserStatsCard from '@/components/AdminStats/AdminUserStats/AdminUserStatsCard.vue';
import { get$bindFirestoreOptions } from '@/mixins';

export default Vue.extend({
	name: 'AdminUsersManagement',
	data () {
		return {
			SHOW_TEXT_FIELD_COUNTER_AT,
			TEXT_FIELD_MAX_LENGTH,

			resultUsers: [],
			isSigningInAsUser: false,
			searchEmail: null,
			loadingEmailSearch: false,
			formValid: false,
			emailRules: [
				(v: string) => !!_trim(v) || 'Email is required',
				TEXT_FIELD_RULE,
				v => EMAIL_REGEX.test(_trim(v)) || 'Incorrect email format',
			],
		};
	},
	computed: {
		...mapState([
			'user',
		]),
	},
	created () {
		const routeUserEmail: string = _trim(this.$route.query.userEmail).toLowerCase();
		if (routeUserEmail) {
			this.searchEmail = routeUserEmail;
			this.$nextTick(() => {
				this.doEmailSearch();
			});
		}
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'setAdminUserProxyTokens',
		]),
		goBack () {
			if (this.hasRouteHistory) {
				this.$router.back();
			} else {
				const hasCurrentSessionId: boolean = !!(this._get(this.toolbar, 'session.id'));

				if (hasCurrentSessionId) {
					this.routerPush(this.$route, this.$router, { name: 'Launch', params: { sessionId: this._get(this.toolbar, 'session.id') } });
				} else {
					this.routerPush(this.$route, this.$router, { name: 'Sessions' });
				}
			}
		},
		async doEmailSearch () {
			if (!this.formValid) return;
			const trimmedEmail: string = _trim(this.searchEmail).toLowerCase();
			if (!trimmedEmail) return;

			try {
				this.loadingEmailSearch = true;
				const usersRef = db.collection(`users`)
					.where('email', '==', trimmedEmail)
					.limit(1);
				await this.$bind(
					'resultUsers',
					usersRef,
					get$bindFirestoreOptions(),
				);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error searching users by email',
					show: true,
				});
			} finally {
				this.loadingEmailSearch = false;
			}
		},
		async doAdminSignInAsUser (userId: string) {
			const onError: Function = (e) => {
				console.error('Error signing in as user: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error signing in as user',
					show: true,
				});
			};

			try {
				this.isSigningInAsUser = true;

				const functionRef = functions
					.httpsCallable('adminSignInAsUser');
				const { success, proxyAdminToken, proxyToken, userEmail } = (await functionRef({
					userId,
				})).data;
				if (!success) {
					onError(new Error('adminSignInAsUser not successful'));
				} else {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! Signed in as user: ${userEmail}`,
						show: true,
					});

					// Timeout to give snackbar a while to show
					setTimeout(async () => {
						// Store admin user custom token and id token in local storage
						const proxyAdminUserCredential: firebase.auth.UserCredential = await firebase.auth().signInWithCustomToken(proxyAdminToken);
						const proxyAdminIdToken: string = await proxyAdminUserCredential.user.getIdToken();
						this.setAdminUserProxyTokens({
							proxyAdminToken,
							proxyAdminIdToken,
						});

						// Sign in to new user
						const userCredential: firebase.auth.UserCredential = await firebase.auth().signInWithCustomToken(proxyToken);
						await this.routerPush(this.$route, this.$router, { name: 'Sessions' });
						window.location.reload();
					}, 1000);
				}
			} catch (e) {
				onError(e);
			} finally {
				this.isSigningInAsUser = false;
			}
		},
	},
	components: {
		AdminUserStatsCard,
		BackButton,
	},
});
