
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import RestartButton from './RestartButton.vue';
// import DownloadFilesButton from './DownloadFilesButton.vue';
import LogsPane from '@/components/SessionBar/ServerBuddy/LogsPane.vue';
import StatusButton from '@/components/SessionBar/ServerBuddy/StatusButton.vue';
import { Session } from '@run-diffusion/shared';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { functions } from '@/firebase';
import SessionInfoCard from '@/components/SessionInfoCard.vue';

export default Vue.extend({
	name: 'ServerBuddy',
	data () {
		const URL_PROVIDER = {
			CIVITAI: 'CIVITAI',
			GOOGLE_DRIVE: 'GOOGLE_DRIVE',
			HUGGING_FACE: 'HUGGING_FACE',
			OTHER: 'OTHER',
		};
		// const civitaiPattern = /^https:\/\/civitai\.com\/api\/download\/models\/\d+$/;
		// const huggingFacePattern = /^https:\/\/huggingface\.co\/[\w-]+\/[\w-]+$/;
		// const googleDrivePattern = /^https:\/\/huggingface\.co\/[\w-]+\/[\w-]+$/;

		return {
			URL_PROVIDER,
			modelUrl: null,
			provider: URL_PROVIDER.CIVITAI,

			showSbudAdminLog: false,
			logsSending: false,
			intervalSendLogs: null, // Store the interval here so you can clear it later

			// urlRules: [
			// 	v => !!v || 'Url is required',
			// 	v => isValidUrl(v, civitaiPattern) || 'Not matching Civitai pattern, (ex: https://civitai.com/api/download/models/10)',
			// ],

			// FORM
			formValid: true,
			// rules: {
			// 	civitaiUrl: [
			// 		v => !!v || 'Url is required',
			// 		v => isValidUrl(v, civitaiPattern) || 'Not matching Civitai pattern, (ex: https://civitai.com/api/download/models/10)',
			// 	],
			// 	huggingFaceUrl: [
			// 		v => !!v || 'Url is required',
			// 		v => isValidUrl(v, huggingFacePattern) || 'Not matching HuggingFace pattern, (ex: https://huggingface.co/Envvi/Inkpunk-Diffusion)',
			// 	],
			// 	googleDriveUrl: [
			// 		v => !!v || 'Url is required',
			// 		v => isValidUrl(v, googleDrivePattern) || 'Not matching GoogleDrive pattern, (ex: https://huggingface.co/Envvi/Inkpunk-Diffusion)',
			// 	],
			// 	otherUrl: [
			// 		v => !!v || 'Url is required',
			// 		v => isValidUrl(v) || 'Not matching url pattern',
			// 	],
			// },
		};
	},
	destroyed () {
		if (this.intervalSendLogs) {
			clearInterval(this.intervalSendLogs);
		}
	},
	computed: {
		...mapState([
			'user',
			'toolbar',
			'isAdminProxyingUser',
		]),
		showRestartBtn () {
			return !!(
				this.toolbar.session &&
				this.toolbar.sbudServices.sessionId === this.toolbar.session.id &&
				this.toolbar.sbudServices.restart
			);
		},
		showLogsPane () {
			return !!(
				this.toolbar.session &&
				this.toolbar.sbudServices.sessionId === this.toolbar.session.id &&
				this.toolbar.sbudServices.getLogs
			);
		},
	},
	methods: {
		...mapActions([
			'updateToolbar',
			'updateSnackbar',
		]),
		onBackClick () {
			this.updateToolbar({
				serverBuddyOpen: !this.toolbar.serverBuddyOpen,
			});
		},
		openServerBuddyShell () {
			const sbudSecret: string = this._get(this.toolbar, 'sbudServices.secrets.sbud') || null;
			window.open(`${this.toolbar.session.serverUrlSBUD}/shell/${sbudSecret}`, '_blank');
		},
		openServerBuddyDocs () {
			window.open(`${this.toolbar.session.serverUrlSBUD}/docs`, '_blank');
		},
		startSendLogsPolling () {
			this.intervalSendLogs = setInterval(
				() => {
					if (!this.logsSending) {
						this.sendLogs(false);
					}
				},
				100000//300000, // 5 minutes
			);
		},
		async sendLogs (allowSnackbar: boolean) {
			const onError: Function = (e, message: string) => {
				console.error('Error sending logs: ', e, message);
				if (allowSnackbar) {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: message || 'Error sending logs',
						show: true,
					});
				}
			};

			try {
				this.logsSending = true;
				const functionRef = functions
					.httpsCallable('sendSessionLogs');
				const { success, message } = (await functionRef({
					sessionId: this.toolbar.session.id,
				})).data;
				if (!success) {
					onError(new Error('sendSessionLogs not successful'), message);
				} else {
					if (allowSnackbar) {
						this.updateSnackbar({
							status: SNACKBAR_STATUS.SUCCESS,
							message: 'Success! Logs sent!',
							show: true,
						});
					}
				}
			} catch (e) {
				onError(e, null);
			} finally {
				this.logsSending = false;
			}
		},
	},
	watch: {
		'toolbar.session': {
			immediate: true,
			async handler (newVal: Session, oldVal: Session) {
				if (newVal !== oldVal) {
					if (this.intervalSendLogs) {
						clearInterval(this.intervalSendLogs);
					}
					if (newVal) {
						this.startSendLogsPolling();
					}
				}
			},
		},
		async provider (newVal) {
			switch (newVal) {
				case this.URL_PROVIDER.CIVITAI:
					this.urlRules = this.rules.civitaiUrl;
					break;
				case this.URL_PROVIDER.HUGGING_FACE:
					this.urlRules = this.rules.huggingFaceUrl;
					break;
				case this.URL_PROVIDER.GOOGLE_DRIVE:
					this.urlRules = this.rules.googleDriveUrl;
					break;
				case this.URL_PROVIDER.OTHER:
					this.urlRules = this.rules.otherUrl;
					break;
				default:
					this.urlRules = this.rules.civitaiUrl;
					break;
			}

		},
	},
	components: {
		SessionInfoCard,
		LogsPane,
		StatusButton,
		RestartButton,
		// DownloadFilesButton,
	},
});
