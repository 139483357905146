
import Vue from 'vue';
import _isNil from 'lodash/isNil';
import _toString from 'lodash/toString';
import _trim from 'lodash/trim';
import BigNumber from 'bignumber.js';
import moment from 'moment-timezone';
import { MyBalanceMixin } from '@/mixins';
import InfoMessage from '@/components/InfoMessage.vue';
import { SELECTED_STORAGE_TYPE } from '@/constants/constants';

export default Vue.extend({
	name: 'TimeYourSessionInputs',
	mixins: [
		MyBalanceMixin,
	],
	props: {
		allowAnyTimeSelection: { type: Boolean, default: false },
		maxHoursLimit: { type: Number, default: 168 },
		highestDefaultValueAllowed: { type: Number, default: 2 },
		hideEstimatedTotal: { type: Boolean, default: false },
		previewMode: { type: Boolean, default: false },
		selectedStorageType: { type: String, required: true },
		insufficientBalanceMessage: { type: String, default: null },
		title: { type: String, default: null },
		purchasePrice: { type: Number, default: 0 },
		disabled: { type: Boolean, default: false },
		selectedScheduledStopInHrs: { type: Number, default: 0 },
		selectedScheduledStopInMins: { type: Number, default: 0 },
		extendingFromScheduledStopAt: { type: Object, default: null },
	},
	data () {
		return {
			scheduledStopInHrsItems: [],
			scheduledStopInMinsItems: [],
			INCREMENT_UNIT: 15,
			MAX_SUB_60_INCREMENT_UNIT: 45,

			allBalanceMaxHrs: 0,
			allBalanceMaxMins: 0,

			showRadioVal1Hr: false,
			showRadioVal2Hr: false,
			showRadioVal3Hr: false,
			showRadioValAll: false,
			showRadioValCustom: false,
			RADIO_VAL_ALL_MAX_HRS: 25, // too much to risk letting them waste so much money with one-click
			RADIO_VAL_1_HR: 1,
			RADIO_VAL_2_HR: 2,
			RADIO_VAL_3_HR: 3,
			RADIO_VAL_ALL: 4,
			RADIO_VAL_CUSTOM: 5,
			radioGroupVal: 2,

			// FORM
			formValid: true,
			rules: {
				required: [
					v => !!_trim(v) || 'Required',
				],
			},
		};
	},
	computed: {
		selectedComputedBalance () {
			return this.selectedStorageType === SELECTED_STORAGE_TYPE.TEAM
				? this.computedTeamBalance
				: this.computedUserBalance;
		},
		radioGroupHasNoOptions () {
			return !(
				this.showRadioVal1Hr ||
				this.showRadioVal2Hr ||
				this.showRadioVal3Hr ||
				this.showRadioValAll ||
				this.showRadioValCustom
			);
		},
		usingAllBalance () {
			return this.radioGroupVal === this.RADIO_VAL_ALL;
		},
		calculatedMins () {
			if (
				this.selectedScheduledStopInHrs ||
				this.selectedScheduledStopInMins
			) {
				return (this.selectedScheduledStopInHrs * 60) + this.selectedScheduledStopInMins;
			}
			return 0;
		},
		estimatedTotal () {
			if (
				(
					this.selectedScheduledStopInHrs ||
					this.selectedScheduledStopInMins
				) &&
				this.purchasePrice > 0 &&
				this.calculatedMins >= 5
			) {
				const purchaseHrsDecimal: BigNumber = new BigNumber(this.calculatedMins).div(60);
				const hrPrice: number = this.purchasePrice;
				const cost: BigNumber = purchaseHrsDecimal.times(hrPrice);
				return cost.integerValue(BigNumber.ROUND_HALF_UP).toNumber();
			}
			return 0;
		},
		newExtendedScheduledStopAtMoment () {
			if (!this.extendingFromScheduledStopAt) return null;
			return moment(this.extendingFromScheduledStopAt.toMillis())
				.add(this.calculatedMins, 'minutes')
				.toDate();
		},
	},
	created () {
		this.setRadioSelectionsState(this.selectedComputedBalance, this.purchasePrice);
		this.initScheduledStopInHrsItems(this.selectedComputedBalance, this.purchasePrice);
		this.initScheduledStopInMinsItems(this.selectedScheduledStopInHrs, this.selectedComputedBalance, this.purchasePrice);
		this.syncRadioGroupVal(this.selectedScheduledStopInHrs, this.selectedScheduledStopInMins);
	},
	watch: {
		purchasePrice (newVal: number) {
			this.setRadioSelectionsState(this.selectedComputedBalance, newVal);
			this.initScheduledStopInHrsItems(this.selectedComputedBalance, newVal);
			this.initScheduledStopInMinsItems(this.selectedScheduledStopInHrs, this.selectedComputedBalance, newVal);
		},
		selectedComputedBalance (newVal: number) {
			this.setRadioSelectionsState(newVal, this.purchasePrice);
			this.initScheduledStopInHrsItems(newVal, this.purchasePrice);
			this.initScheduledStopInMinsItems(this.selectedScheduledStopInHrs, newVal, this.purchasePrice);
		},
		selectedScheduledStopInHrs (newVal: number) {
			this.initScheduledStopInMinsItems(newVal, this.selectedComputedBalance, this.purchasePrice);
			this.syncRadioGroupVal(newVal, this.selectedScheduledStopInMins);
		},
		selectedScheduledStopInMins (newVal: number) {
			this.syncRadioGroupVal(this.selectedScheduledStopInHrs, newVal);
		},
	},
	methods: {
		onRadioGroupValChange (val) {
			this.radioGroupVal = _isNil(val) ? this.radioGroupVal : val;
			switch (this.radioGroupVal) {
				case this.RADIO_VAL_1_HR:
					this.setSelectedScheduledStopInHrs(1);
					this.setSelectedScheduledStopInMins(0);
					break;
				case this.RADIO_VAL_2_HR:
					this.setSelectedScheduledStopInHrs(2);
					this.setSelectedScheduledStopInMins(0);
					break;
				case this.RADIO_VAL_3_HR:
					this.setSelectedScheduledStopInHrs(3);
					this.setSelectedScheduledStopInMins(0);
					break;
				case this.RADIO_VAL_ALL:
					this.setSelectedScheduledStopInHrs(this.allBalanceMaxHrs);
					this.setSelectedScheduledStopInMins(this.allBalanceMaxMins);
					break;
				case this.RADIO_VAL_CUSTOM:
					// leave hrs alone
					this.setSelectedScheduledStopInMins(0);
					break;
				default:
					break;
			}
		},
		getMaxHrs (selectedComputedBalance: number, purchasePrice: number) {
			if (this.allowAnyTimeSelection) {
				return this.maxHoursLimit;
			} else if (
				purchasePrice > 0 &&
				selectedComputedBalance > 0
			) {
				const maxHrsDecimal: BigNumber = BigNumber(selectedComputedBalance).div(purchasePrice);
				const maxHrsInt: BigNumber = maxHrsDecimal.integerValue(BigNumber.ROUND_FLOOR);
				return Math.max(maxHrsInt.toNumber(), 0);
			} else if (purchasePrice === 0) {
				return 1;
			}
			return 0;
		},
		getMaxMins (
			selectedScheduledStopInHrs: number,
			selectedComputedBalance: number,
			purchasePrice: number,
			incrementUnit: number,
		) {
			if (this.allowAnyTimeSelection) {
				return this.MAX_SUB_60_INCREMENT_UNIT;
			} else if (
				(
					purchasePrice > 0 &&
					selectedComputedBalance > 0
				) ||
				purchasePrice === 0
			) {
				const maxHrsInt: number = this.getMaxHrs(selectedComputedBalance, purchasePrice);
				if (selectedScheduledStopInHrs === maxHrsInt) {
					const maxHrsDecimal: BigNumber = BigNumber(selectedComputedBalance).div(purchasePrice);
					const maxHrsDecimalRemainder: BigNumber = maxHrsDecimal.mod(1);
					const maxMinsDecimal: BigNumber = maxHrsDecimalRemainder.times(60);
					const maxMinsInt: BigNumber = maxMinsDecimal.integerValue(BigNumber.ROUND_FLOOR);
					const maxMinsNearestIncrementInt: BigNumber = maxMinsInt.div(incrementUnit).integerValue(BigNumber.ROUND_FLOOR).times(incrementUnit);
					return Math.max(maxMinsNearestIncrementInt.toNumber(), 0);
				}
				return this.MAX_SUB_60_INCREMENT_UNIT;
			}
			return 0;
		},
		setRadioSelectionsState (selectedComputedBalance: number, purchasePrice: number) {
			const maxHrs: number = this.getMaxHrs(selectedComputedBalance, purchasePrice);
			this.allBalanceMaxHrs = Math.min(maxHrs, this.RADIO_VAL_ALL_MAX_HRS);

			this.allBalanceMaxMins = this.getMaxMins(
				this.allBalanceMaxHrs,
				selectedComputedBalance,
				purchasePrice,
				1,
			);

			this.showRadioValAll = !!(
				!this.allowAnyTimeSelection &&
				this.purchasePrice > 0 &&
				this.allBalanceMaxHrs === maxHrs &&
				(
					this.allBalanceMaxHrs > 0 ||
					this.allBalanceMaxMins > 5
				)
			);

			this.showRadioValCustom = !!(
				(
					this.allBalanceMaxHrs > 0 ||
					this.allBalanceMaxMins > this.INCREMENT_UNIT
				)
			);

			const radioGroupValNeedsChange: boolean = !!(
				!this.radioGroupVal ||
				(this.radioGroupVal === this.RADIO_VAL_3_HR && this.allBalanceMaxHrs < 3) ||
				(this.radioGroupVal === this.RADIO_VAL_2_HR && this.allBalanceMaxHrs < 2) ||
				(this.radioGroupVal === this.RADIO_VAL_1_HR && this.allBalanceMaxHrs < 1) ||
				(
					this.radioGroupVal === this.RADIO_VAL_CUSTOM &&
					!this.allBalanceMaxHrs &&
					this.allBalanceMaxMins < this.INCREMENT_UNIT
				)
			);
			if (radioGroupValNeedsChange) {
				if (this.allBalanceMaxHrs >= 2) {
					this.radioGroupVal = this.RADIO_VAL_2_HR;
				} if (this.allBalanceMaxHrs >= 1) {
					this.radioGroupVal = this.RADIO_VAL_1_HR;
				} else if (this.showRadioValAll) {
					this.radioGroupVal = this.RADIO_VAL_ALL;
				} else {
					this.radioGroupVal = this.RADIO_VAL_CUSTOM;
				}
			}
		},
		initScheduledStopInHrsItems (selectedComputedBalance: number, purchasePrice: number) {
			const oldLength = this.scheduledStopInHrsItems.length;
			this.scheduledStopInHrsItems = [{ text: '0', value: 0 }];
			this.showRadioVal1Hr = false;
			this.showRadioVal2Hr = false;
			this.showRadioVal3Hr = false;

			let defaultHrsValue: number = 0;
			const maxHrsInt: number = Math.min(
				this.getMaxHrs(selectedComputedBalance, purchasePrice),
				this.maxHoursLimit,
			);

			// Fill up select items
			let currHrs: number = 0;
			while (currHrs <= maxHrsInt) {
				// Init shortcut radio group val options
				if (currHrs === 1) {
					this.showRadioVal1Hr = true;
				} else if (currHrs === 2) {
					this.showRadioVal2Hr = true;
				} else if (currHrs === 3) {
					this.showRadioVal3Hr = true;
				}

				// Process next hrs amount
				if (currHrs <= this.highestDefaultValueAllowed && currHrs > defaultHrsValue) {
					defaultHrsValue = currHrs;
				}
				if (currHrs) {
					this.scheduledStopInHrsItems.push({ text: _toString(currHrs), value: currHrs });
				}
				currHrs++;
			}

			// Default selected value
			const foundInItemList: boolean = this.scheduledStopInHrsItems.some(({ value }) => (
				value === this.selectedScheduledStopInHrs
			));
			if (
				this.usingAllBalance &&
				this.selectedScheduledStopInHrs !== this.allBalanceMaxHrs
			) {
				this.setSelectedScheduledStopInHrs(this.allBalanceMaxHrs);
			} else if (
				!this.usingAllBalance &&
				(
					oldLength !== this.scheduledStopInHrsItems.length ||
					!foundInItemList
				)
			) {
				this.setSelectedScheduledStopInHrs(defaultHrsValue);
			}
		},
		initScheduledStopInMinsItems (selectedScheduledStopInHrs: number, selectedComputedBalance: number, purchasePrice: number) {
			const oldLength = this.scheduledStopInMinsItems.length;
			this.scheduledStopInMinsItems = [{ text: '0', value: 0 }];
			const maxMinsInt: number = this.getMaxMins(
				selectedScheduledStopInHrs,
				selectedComputedBalance,
				purchasePrice,
				this.INCREMENT_UNIT,
			);

			// Fill up select items
			let currMins: number = 0;
			while (currMins <= maxMinsInt && currMins <= this.MAX_SUB_60_INCREMENT_UNIT) {
				// Process next mins amount
				if (currMins) {
					this.scheduledStopInMinsItems.push({ text: _toString(currMins), value: currMins });
				}
				currMins += this.INCREMENT_UNIT;
			}

			// Default selected value
			const foundInItemList: boolean = this.scheduledStopInMinsItems.some(({ value }) => (
				value === this.selectedScheduledStopInMins
			));
			if (
				this.usingAllBalance &&
				this.selectedScheduledStopInMins !== this.allBalanceMaxMins
			) {
				this.setSelectedScheduledStopInMins(this.allBalanceMaxMins);
			} else if (
				!this.usingAllBalance &&
				(
					oldLength !== this.scheduledStopInMinsItems.length ||
					!foundInItemList
				)
			) {
				this.setSelectedScheduledStopInMins(0);
			}
		},
		syncRadioGroupVal (selectedScheduledStopInHrs: number, selectedScheduledStopInMins: number) {
			if (this.RADIO_VAL_ALL === this.radioGroupVal) {
				// Don't auto-select these options, the "All" selection is always just selected by the user clicks
				return;
			}

			const is1HrEvenMark: boolean = selectedScheduledStopInMins === 0 && selectedScheduledStopInHrs === 1;
			const is2HrEvenMark: boolean = selectedScheduledStopInMins === 0 && selectedScheduledStopInHrs === 2;
			const is3HrEvenMark: boolean = selectedScheduledStopInMins === 0 && selectedScheduledStopInHrs === 3;
			const isOtherHrsAmount: boolean = (
				!is1HrEvenMark &&
				!is2HrEvenMark &&
				!is3HrEvenMark
			);

			if (
				is1HrEvenMark &&
				this.radioGroupVal !== this.RADIO_VAL_1_HR
			) {
				this.radioGroupVal = this.RADIO_VAL_1_HR;
			} else if (
				is2HrEvenMark &&
				this.radioGroupVal !== this.RADIO_VAL_2_HR
			) {
				this.radioGroupVal = this.RADIO_VAL_2_HR;
			} else if (
				is3HrEvenMark &&
				this.radioGroupVal !== this.RADIO_VAL_3_HR
			) {
				this.radioGroupVal = this.RADIO_VAL_3_HR;
			} else if (
				isOtherHrsAmount &&
				(selectedScheduledStopInMins % this.INCREMENT_UNIT) === 0 &&
				this.radioGroupVal !== this.RADIO_VAL_CUSTOM
			) {
				this.radioGroupVal = this.RADIO_VAL_CUSTOM;
			}
		},
		setSelectedScheduledStopInHrs (val: number) {
			const valToEmit: number = val || 0;
			if (
				this.usingAllBalance ||
				this.scheduledStopInHrsItems.some(({ value }) => value === valToEmit)
			) {
				this.$emit('selected-scheduled-stop-in-hrs-input', valToEmit);
			}
		},
		setSelectedScheduledStopInMins (val: number) {
			const valToEmit: number = val || 0;
			if (
				this.usingAllBalance ||
				this.scheduledStopInMinsItems.some(({ value }) => value === valToEmit)
			) {
				this.$emit('selected-scheduled-stop-in-mins-input', valToEmit);
			}
		},
	},
	components: {
		InfoMessage,
	},
});
