import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,[_c(VCardTitle,{staticClass:"dialog-title-container"},[_c('div',{staticClass:"dialog-title"},[_vm._v(" "+_vm._s(_vm.title)+" "),_vm._t("after-title")],2),_c('div',{staticClass:"d-flex align-center"},[_vm._t("title-actions"),_c('base-button',{attrs:{"disabled":_vm.closeDisabled,"secondary":"","icon":"","small":""},on:{"click":_vm.onCancel}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],2)]),_c(VCardText,{class:{
			'px-0': _vm.$vuetify.breakpoint.xsOnly,
		},style:(_vm.contentStyles)},[_c('div',{staticClass:"d-flex flex-column justify-space-between full-height"},[_c('div',{class:_vm.contentWrapperClassComputed},[_vm._t("default")],2),_c('div',{class:_vm.actionsWrapperClass},[_vm._t("actions")],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }