
import Vue from 'vue';
import ImageGallery from '@/components/ImageGallery/ImageGallery.vue';
import { mapActions, mapState } from 'vuex';
import RunnitDialog from '@/views/Runnits/base/RunnitDialog.vue';
import RunnitNavTabs from '@/views/Runnits/base/RunnitNavTabs.vue';
import BaseStyledMenu from '@/components/base/BaseStyledMenu.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import { IMAGE_GALLERY_DIALOG_NAV } from '@/components/ImageGallery/utils';
import {
	SELECTED_COLUMN_MENU_ITEMS,
	SELECTED_COLUMNS_MAP,
	SELECTED_IMAGE_MODE_MENU_ITEMS,
	SELECTED_IMAGE_MODE,
} from '@/views/Runnits/constants';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';

export default Vue.extend({
	name: 'ImageGalleryDialog',
	props: {
		elIdSuffix: { type: String, required: true },
		selectionModeTriggerId: { type: String },
		persistent: { type: Boolean, default: false },
		maxWidth: { type: [Number, String], default: '80%' },
		hideNav: { type: Boolean, default: false },
		navTab: { type: String, default: null },
	},
	mixins: [
		RunnitsImageSelectMixin,
	],
	data () {
		return {
			SELECTED_IMAGE_MODE,

			imageGalleryCurrNavTabId: null,
			imageGalleryNavTabs: [
				{
					id: IMAGE_GALLERY_DIALOG_NAV.GENERATIONS,
					label: 'Your Generations',
					icon: 'mdi-image-multiple-outline',
				},
				{
					id: IMAGE_GALLERY_DIALOG_NAV.UPLOADS,
					label: 'Uploads',
					icon: 'mdi-cloud-upload-outline',
				},
				// { // By default this tab isn't used for the runnit workflows so we don't want them visible
				// 	id: IMAGE_GALLERY_DIALOG_NAV.AVATARS,
				// 	label: 'Avatars',
				// 	icon: 'mdi-cloud-upload-outline',
				// },
			],
			selectedImageModeMenuItems: SELECTED_IMAGE_MODE_MENU_ITEMS,
			selectColumnsMap: SELECTED_COLUMNS_MAP,
			selectedColumnsMenuItems: SELECTED_COLUMN_MENU_ITEMS,
		};
	},
	computed: {
		...mapState([
			'runnitState',
		]),
	},
	watch: {
		navTab: {
			immediate: true,
			handler (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.imageGalleryCurrNavTabId = newVal;
				}
			}
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
		]),
		onOpenInput (open: boolean) {
			if (open !== this.runnitState.imageGalleryDialogOpen) {
				this.updateRunnitState({
					imageGalleryDialogOpen: !!open,
				});
			} else {
				this.onAbandonImageSelection();
			}
		},
		onCancel () {
			this.onOpenInput(false);
			this.onAbandonImageSelection();
		},
		onSelectionChosen () {
			this.onCancel();
		},
		onAbandonImageSelection () {
			this.exitSelectionMode(this.selectionModeTriggerId);
		},
	},
	components: {
		ImageGallery,
		RunnitDialog,
		RunnitNavTabs,
		BaseStyledMenu,
		GlassButton,
	},
});
