import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._t("activator",null,{"open":_vm.setOpen}),_c(VDialog,{attrs:{"value":_vm.open,"max-width":_vm.maxWidth,"persistent":_vm.persistent,"overlay-opacity":"0.8"},on:{"input":_vm.setOpen}},[_c(VSheet,{staticClass:"d-flex flex-column justify-center align-center pa-12"},[_c('img',{staticStyle:{"margin":"-20px 0 0 0"},attrs:{"alt":"Creator's Club Discord Channels","width":_vm.$vuetify.breakpoint.smAndUp ? '170px' : '140px',"src":require("./assets/megaphone.gif")}}),_c('div',{staticClass:"d-flex flex-column align-center my-6",staticStyle:{"max-width":"550px","color":"var(--grey-500)"}},[_c('div',{staticStyle:{"color":"var(--grey-500)","max-width":"470px","text-align":"center"},style:({
						fontSize: _vm.$vuetify.breakpoint.smAndUp ? '28px' : '18px',
					})},[_vm._v(" New User Promotion! ")]),_c('div',{staticStyle:{"color":"var(--grey-200)","max-width":"470px","text-align":"center"},style:({
						fontSize: _vm.$vuetify.breakpoint.smAndUp ? '28px' : '18px',
					})},[_c('span',{staticClass:"gradient-text"},[_vm._v("DOUBLE")]),_vm._v(" your balance up to "),_c('b',[_vm._v("$25")])]),_c('div',{staticClass:"d-flex flex-column align-center mt-10 mb-7"},[_c('list-item',{attrs:{"icon":"mdi-check-circle"}},[_vm._v("*Only valid for first payment")]),_c('list-item',{attrs:{"icon":"mdi-check-circle"}},[_vm._v("Buy $10, get $20")]),_c('list-item',{attrs:{"icon":"mdi-check-circle"}},[_vm._v("Buy $25, get $50")]),_c('list-item',{attrs:{"icon":"mdi-check-circle"}},[_vm._v("Buy $50, get $75 (only doubles up to $25)")])],1),_c('div',{staticStyle:{"font-size":"16px","color":"var(--grey-600)","max-width":"470px","text-align":"center"},style:({
						fontSize: _vm.$vuetify.breakpoint.smAndUp ? '14px' : '12px',
					})},[_c('div',[_vm._v("*This promotion runs for just "),_c('b',[_vm._v("2 days")]),_vm._v(" from")]),_c('div',[_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.promotionalStartMoment,'MMM D, YYYY [@] hh:mm a')))]),_vm._v(" to "),_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.promotionalEndMoment,'MMM D, YYYY [@] hh:mm a')))])])])]),_c('div',{staticClass:"my-7 d-flex justify-center"},[_c('base-button',{staticClass:"mr-6",attrs:{"secondary":""},on:{"click":_vm.onCancel}},[_vm._v(" Close ")]),_c('add-balance-funds-button',{attrs:{"type":"private"}},[_vm._v(" Add Double Funds ")])],1)])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }