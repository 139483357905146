"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRunnitNodeRunPrice = exports.getHardwareHourlyPrice = exports.currencyTokens = exports.tokensToThousandths = exports.thousandthsToTokens = exports.commaSeparated = exports.currency = exports.dollarsToCents = exports.centsToDollars = void 0;
const lodash_1 = require("lodash");
const numeral = require('numeral');
const bignumber_js_1 = require("bignumber.js");
const centsToDollars = (val) => {
    if (!val)
        return 0;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return 0;
    return Math.floor(num) / 100; // Cents always integer
};
exports.centsToDollars = centsToDollars;
const dollarsToCents = (val) => {
    if (!val)
        return 0;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return 0;
    return Math.floor(num * 100); // Cents always integer
};
exports.dollarsToCents = dollarsToCents;
const currency = (val, useNegSign = false) => {
    const ZERO_VAL = `$0.00`;
    if (!val)
        return ZERO_VAL;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return ZERO_VAL;
    const localeString = numeral(Math.abs(num)).format('0,0.00');
    if (num < 0 && !useNegSign)
        return `($${localeString})`;
    if (num < 0 && useNegSign)
        return `-$${localeString}`;
    return `$${localeString}`;
};
exports.currency = currency;
const commaSeparated = (val) => {
    if (!val)
        return '0';
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return '0';
    return numeral(num).format('0,0');
};
exports.commaSeparated = commaSeparated;
const thousandthsToTokens = (val) => {
    if (!val)
        return 0;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return 0;
    return Math.floor(num) / 1000; // Thousandths always integer
};
exports.thousandthsToTokens = thousandthsToTokens;
const tokensToThousandths = (val) => {
    if (!val)
        return 0;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return 0;
    return Math.floor(num * 1000); // Thousandths always integer
};
exports.tokensToThousandths = tokensToThousandths;
const currencyTokens = (val) => {
    const ZERO_VAL = '0';
    if (!val)
        return ZERO_VAL;
    const num = (0, lodash_1.toNumber)(val);
    if (!num)
        return ZERO_VAL;
    return numeral(num).format('0,0');
};
exports.currencyTokens = currencyTokens;
const getHardwareHourlyPrice = (hardwareOffer, teamId, club) => {
    if (!hardwareOffer)
        return 0;
    const price = hardwareOffer.price || 0;
    if (teamId &&
        hardwareOffer.teamDiscount &&
        hardwareOffer.teamDiscount.percent > 0 &&
        hardwareOffer.teamDiscount.percent <= 100) {
        const priceRatio = (100 - hardwareOffer.teamDiscount.percent) / 100;
        return Math.max(Math.round(price * priceRatio), 0);
    }
    if (club &&
        hardwareOffer.clubDiscounts &&
        hardwareOffer.clubDiscounts[club] &&
        hardwareOffer.clubDiscounts[club].percent > 0 &&
        hardwareOffer.clubDiscounts[club].percent <= 100) {
        const priceRatio = (100 - hardwareOffer.clubDiscounts[club].percent) / 100;
        return Math.max(Math.round(price * priceRatio), 0);
    }
    return price;
};
exports.getHardwareHourlyPrice = getHardwareHourlyPrice;
const getRunnitNodeRunPrice = (costPerResult, numResults) => {
    return (0, bignumber_js_1.default)(costPerResult || 0).times(numResults || 1).integerValue(bignumber_js_1.default.ROUND_FLOOR).toNumber();
};
exports.getRunnitNodeRunPrice = getRunnitNodeRunPrice;
