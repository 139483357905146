
import Vue from 'vue';
import GlassButton from '@/components/base/GlassButton.vue';
import StaticBenefitLineItem from '@/views/Runnits/pages/RunnitsSubscriptionsPage/StaticBenefitLineItem.vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'RunnitsSubscriptionCardComingSoon',
	props: {
		showCcOptions: { type: Boolean, required: true },
		isTeamPlaceholder: { type: Boolean, default: false },
		isEnterprisePlaceholder: { type: Boolean, default: false },
	},
	data () {
		return {
			ROUTER,
		};
	},
	computed: {
		hasAddOns () {
			return true;
		},
	},
	methods: {
		onContactSalesClick () {
			window.open('https://rundiffusion.com/contact-sales', '_blank');
		},
	},
	components: {
		StaticBenefitLineItem,
		GlassButton,
	},
});
