import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: dialogOn }){return [_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('div',_vm._g({staticClass:"mr-2",staticStyle:{"display":"block","cursor":"pointer"}},{ ...dialogOn, ...tooltipOn }),[_c('EnabledApiSVG')],1)]}}],null,true)},[_c('div',[_vm._v(" API Enabled ")])])]}}]),model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"px-12 py-7 d-flex justify-space-between"},[_c('span',{staticClass:"dialog-title"},[_vm._v(" API Enabled ")]),_c('base-button',{attrs:{"secondary":"","icon":"","small":""},on:{"click":_vm.onCancel}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"inset-divider"}),_c(VCardText,{staticStyle:{"height":"250px"}},[_c('div',{staticClass:"px-12 d-flex flex-column full-height justify-center align-center"},[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"470px","height":"150px"}},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"mb-6",staticStyle:{"text-align":"center","font-size":"16px","color":"var(--grey-600)"}},[_vm._v(" Use the link below to get access to the running API: ")]),_c('div',{staticClass:"mb-7",staticStyle:{"flex-wrap":"wrap","max-width":"470px","font-size":"18px","text-align":"center","font-family":"monospace"}},[_vm._v(" "+_vm._s(_vm._get(_vm.toolbar, 'session.serverUrlApi') || _vm._get(_vm.toolbar, 'session.serverUrlUi') || 'Not found (email us at support@rundiffusion.com)')+" ")]),((
								_vm.toolbar &&
								_vm.toolbar.session &&
								_vm.toolbar.session.serverUrlUi
							))?_c('clipboard-copy',{attrs:{"value":_vm.toolbar.session.serverUrlUi,"tooltip-text":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({staticClass:"ml-4 mb-0 px-7",attrs:{"call-to-action":""}},'base-button',attrs,false),on),[_c('b',[_vm._v("Copy")])])]}}],null,false,1975707094)}):_vm._e()],1)])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }