
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import { NODE_RUN_RESULT_SELECTION_MODE } from '@/views/Runnits/constants';
import ImageGalleryDialog from '@/components/ImageGallery/ImageGalleryDialog.vue';
import RunnitModelsDialog from '../base/RunnitModelsDialog.vue';

export default Vue.extend({
	name: 'RunnitModelSelector',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: [Number, String, Object, Array], default: null },
	},
	data () {
		return {
			NUMBER_IMAGES: 8,

			open: false,
		};
	},
	computed: {
		...mapState([
			'runnitState',
		]),
	},
	methods: {
		...mapActions([
			'updateRunnitState',
		]),
		onOpenDialog () {
			this.open = true;
		},
		onModelSelect (model) {
			console.log('onModelSelect', {model});

		},
	},
	components: {
		RunnitModelsDialog,
	},
});
