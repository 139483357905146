import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('runnit-dynamic-field-label-row',{attrs:{"field":_vm.field,"adminOnly":_vm.adminOnly}}),(_vm.field.display && _vm.field.display.select)?_c('div',{staticClass:"width-height-dropdown-container"},[((_vm._get(_vm.field, 'display.select.options') || []).length < 8)?_c(VSelect,{class:{
				required: !!_vm.field.required,
			},attrs:{"items":_vm._get(_vm.field, 'display.select.options') || [],"required":!!_vm.field.required,"placeholder":_vm.field.placeholder,"item-text":"text","item-value":"value","return-object":"","dense":"","filled":"","flat":"","solo-inverted":"","hide-details":"auto","rules":_vm.field.required ? _vm.rules.requiredSelectField : _vm.rules.optionalSelectField,"value":_vm.computedSelectFieldValue},on:{"input":_vm.onSelectFieldInput}}):_c(VAutocomplete,{class:{
				required: !!_vm.field.required,
			},attrs:{"items":_vm._get(_vm.field, 'display.select.options') || [],"required":!!_vm.field.required,"placeholder":_vm.field.placeholder,"item-text":"text","item-value":"value","return-object":"","dense":"","filled":"","flat":"","solo-inverted":"","hide-details":"auto","rules":_vm.field.required ? _vm.rules.requiredSelectField : _vm.rules.optionalSelectField,"value":_vm.computedSelectFieldValue},on:{"input":_vm.onSelectFieldInput}})],1):(_vm.field.display && _vm.field.display.sliders)?_c('div',{staticClass:"width-height-slider-container"},[_c('base-slider',{staticClass:"mb-2",attrs:{"value":_vm.computedWidthAndHeight.width,"step":_vm.DIVISIBLE_BY,"max":_vm.sliderMaxLimits.width,"min":_vm.sliderMinLimits.width,"label":"W","hide-details":"auto"},on:{"input":_vm.onSliderWidthChange}}),_c('base-slider',{attrs:{"value":_vm.computedWidthAndHeight.height,"step":_vm.DIVISIBLE_BY,"max":_vm.sliderMaxLimits.height,"min":_vm.sliderMinLimits.height,"label":"H","hide-details":"auto"},on:{"input":_vm.onSliderHeightChange}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }