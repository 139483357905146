import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VSlideYTransition,[(!_vm.isExpired && !_vm.dismissed)?_c('div',{staticClass:"announcement-banner",class:{
			'alert-type': _vm.announcement.type === _vm.ANNOUNCEMENT_TYPE.ALERT,
			'warning-type': _vm.announcement.type === _vm.ANNOUNCEMENT_TYPE.WARNING,
			'info-type': _vm.announcement.type === _vm.ANNOUNCEMENT_TYPE.INFO,
			'gradient-secondary': _vm.announcement.type === _vm.ANNOUNCEMENT_TYPE.NEWS,
		}},[_c('div',{staticClass:"d-flex pr-9",class:{
				'pl-12': _vm.toolbar.sessionBarOpen,
			}},[_c(VIcon,{staticStyle:{"color":"white","padding":"0 10px 0 14px"}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.announcement.message)+" "),(_vm.announcement.url)?_c('a',{staticClass:"mx-3",staticStyle:{"color":"white !important"},attrs:{"href":_vm.announcement.url,"target":_vm.announcement.urlBlankTarget ? '_blank' : '_self'}},[_vm._v(" Click Here ")]):_vm._e()],1),_c('div',{staticStyle:{"position":"absolute","right":"7px"}},[_c(VBtn,{attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.dismissAnnouncement(_vm.announcement)}}},[_c(VIcon,[_vm._v("mdi-close-circle")])],1)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }