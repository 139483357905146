
import Vue from 'vue';
import { db } from '@/firebase';
import { Runnit, RunnitNode } from '@run-diffusion/shared';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _keys from 'lodash/keys';
import _find from 'lodash/find';
import { mapActions, mapGetters, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import {
	RUNNIT_TOOL_SETTINGS_VALUES,
	RUNNIT_TOOL_SETTINGS_MENU_ITEMS,
} from '@/views/Runnits/constants';
import GlassButton from '@/components/base/GlassButton.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import BaseStyledMenu from '@/components/base/BaseStyledMenu.vue';
import ImageGallery from '@/components/ImageGallery/ImageGallery.vue';
import RunnitSettingsDrawer from '@/views/Runnits/RunnitSettingsDrawer.vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';
import ImageGalleryDialog from '@/components/ImageGallery/ImageGalleryDialog.vue';
import RunnitToolsLibraryDialog from '@/views/Runnits/RunnitToolsLibraryDialog.vue';
import RunnitNodeSettingsDialog from '@/views/Runnits/RunnitNodeSettingsDialog.vue';
import DoubleConfirmDangerDialog from '@/components/base/DoubleConfirmDangerDialog.vue';
import { get$bindFirestoreOptions, MyBalanceMixin, TeamUserMixin } from '@/mixins';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';
import { RUNNIT_TYPE } from '@/constants/enums';
import ActionsIslandFixedBottom from '@/components/ActionsIslandFixedBottom.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import RunnitImage from './RunnitImage.vue';

export default Vue.extend({
	name: 'RunnitBoard',
	data () {
		return {
			RUNNIT_TOOL_SETTINGS_VALUES,
			NODE_TOOLBAR_ICON_SIZE: 32,
			expandedNodesMap: {}, // Record<string, boolean>

			addingNewToolLoading: false,

			nodeSettingsDialog: {
				open: false,
				selectedNode: null,
			},

			loadingLocalRunnit: false,
			localRunnit: null,

			runnitToolSettingsMenuItems: RUNNIT_TOOL_SETTINGS_MENU_ITEMS,
			deleteToolDialog: {
				open: false,
				deleting: false,
				selectedNode: null,
			},

			runnitNodesLoading: true,

			emptyStatePlaceholders: 2,
		};
	},
	mixins: [
		RunnitsImageSelectMixin,
		TeamUserMixin,
		MyBalanceMixin,
	],
	computed: {
		...mapState([
			'user',
			'runnitState',
			'runnitNodes',
			'runnitNodesMap',
		]),
		...mapGetters([
			'runnitNodesLimitMap',
		]),
		isOverPlanNodesLimit () {
			return !!(
				this.runnitNodes.length &&
				this.runnitNodes[this.runnitNodes.length - 1] &&
				!this.runnitNodesLimitMap[this.runnitNodes[this.runnitNodes.length - 1].id]
			);
		},
		computedDescriptionTruncateLength () {
			return this.$vuetify.breakpoint.xsOnly ? 30 : 75;
		},
		canEditRunnit () {
			return this.runnitState.runnit.userId === this.user.id // you created it
				|| this.isTeamAdmin; // team admin;
		},
		isSingleTool () {
			return this._get(this.runnitState, 'runnit.type') === RUNNIT_TYPE.SINGLE_TOOL;
		},
		computedActionIslandWidth () {
			let width: string = '96.5%';

			if (this.$vuetify.breakpoint.smOnly) {
				width = '60%';
			}

			if (this.$vuetify.breakpoint.mdOnly) {
				width = '45%';
			}

			if (this.$vuetify.breakpoint.lgAndUp) {
				width = '35%';
			}

			return width;
		},
	},
	watch: {
		'$route.params.runnitId': {
			immediate: true,
			handler (newVal: string, oldVal: string) {
				if (newVal !== oldVal) {
					this.localBindRunnit(newVal);
				}
			},
		},
		localRunnit: {
			immediate: true,
			handler (newVal: Runnit, oldVal: Runnit) {
				if (newVal !== oldVal) {
					const newRunnitId: string = this._get(newVal, 'id') || null;
					const oldRunnitId: string = this._get(this.runnitState.runnit, 'id') || null;
					this.updateRunnitState({
						runnit: newVal,
						...(newRunnitId !== oldRunnitId && {
							selectedNodeId: null,
						}),
					});

					if (newVal) {
						this.expandedNodesMap = {};
						this.bindRunnitNodes({ runnitId: newVal.id });
					}
				}
			},
		},
		runnitNodesLimitMap: {
			immediate: true,
			handler (newVal: Record<string, RunnitNode>, oldVal: Record<string, RunnitNode>) {
				if (newVal !== oldVal && _isEmpty(this.expandedNodesMap)) {
					this.initExpandedNodesMap(newVal);
				}
			},
		},
		runnitNodes: {
			immediate: true,
			handler (newVal: RunnitNode[], oldVal: RunnitNode[]) {
				const newlyCreatedNodes: RunnitNode[] = oldVal && oldVal.length
					? (newVal || []).filter(({ id }) => (
						!_find(oldVal || [], ['id', id])
					))
					: [];
				if (newlyCreatedNodes.length) {
					// expand and auto select the new node, only if it was created (not also when the component initially loads)
					this.expandedNodesMap = {
						...this.expandedNodesMap,
						...newlyCreatedNodes.reduce((map: Record<string, boolean>, { id }) => ({
							...map,
							[id]: true,
						}), {}),
					};
					this.onSelectNode(newlyCreatedNodes[0].id);
				} else if (
					newVal &&
					newVal.length &&
					this.$vuetify.breakpoint.mdAndUp
				) {
					this.onSelectNode(newVal[0].id);
				} else {
					this.onSelectNode(null);
				}

				setTimeout(() => {
					this.runnitNodesLoading = false;
					if (this.runnitNodes.length > 1 && this.isSingleTool) {
						console.error('A single tool has multiple nodes')
					}
				}, 500);
			},
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'bindRunnitNodes',
			'updateRunnitState',
			'setUpsellDialog',
		]),
		async localBindRunnit (runnitId: string) {
			try {
				this.loadingLocalRunnit = true;
				await this.$bind(
					'localRunnit',
					db.doc(`runnits/${runnitId}`),
					get$bindFirestoreOptions(),
				);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading Runnit',
					show: true,
				});
			} finally {
				this.loadingLocalRunnit = false;
			}
		},
		isNodeExpanded (nodeId: string) {
			return !!(this.expandedNodesMap[nodeId]);
		},
		initExpandedNodesMap (runnitNodesLimitMap: Record<string, RunnitNode>) {
			_keys(runnitNodesLimitMap || {}).forEach((nodeId: string) => {
				if (runnitNodesLimitMap[nodeId]) this.expandedNodesMap[nodeId] = true;
			});
		},
		onSelectNode (nodeId: string) {
			this.updateRunnitState({
				selectedNodeId: nodeId,
				settingsDrawerOpen: !!nodeId,
			});
		},
		setSettingsDrawerOpen (isOpen: boolean) {
			this.updateRunnitState({
				settingsDrawerOpen: isOpen,
				...(!isOpen && {
					imageGalleryDialogOpen: false,
					promptGeneratorOpen: false,
					imageSelectOpen: false,
					inpaintingEditorOpen: false,
				}),
				...(this.runnitState.selectedNodeId && !isOpen && {
					selectedNodeId: null,
				}),
			});
		},
		toggleNodeExpanded (event: any, nodeId: string) {
			event.stopPropagation();
			this.expandedNodesMap[nodeId] = !this.expandedNodesMap[nodeId];
			this.$forceUpdate();
		},
		addAnotherNode () {
			if (this.isOverPlanNodesLimit) {
				this.setUpsellDialog({ runnitsIsNumNodesLimit: true });
				return;
			}
			this.setToolsLibraryDialogOpenState(true);
		},
		setToolsLibraryDialogOpenState (open: boolean) {
			this.updateRunnitState({
				toolsLibraryDialogOpen: open,
			});
		},
		async onSelectedSettingMenuItem (selectedMenuItem: string, selectedNode: RunnitNode) {
			if (selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.DELETE) {
				this.deleteToolDialog = {
					...this.deleteToolDialog,
					open: true,
					selectedNode,
				};
			} else if (selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.EDIT_INFO) {
				this.nodeSettingsDialog = {
					...this.nodeSettingsDialog,
					open: true,
					selectedNode,
				};
			} else if ([RUNNIT_TOOL_SETTINGS_VALUES.MOVE_UP, RUNNIT_TOOL_SETTINGS_VALUES.MOVE_DOWN].includes(selectedMenuItem)) {
				await this.moveNode(selectedMenuItem, selectedNode);
			}
		},
		onRunnitNodeSettingsClose () {
			this.nodeSettingsDialog = {
				open: false,
				selectedNode: null,
			};
		},
		onDeleteCancelReset () {
			this.deleteToolDialog = {
				open: false,
				deleting: false,
				selectedNode: null,
			};
		},
		async handleDeleteNodeDefConfirm (closeAndResetCallback: Function) {
			try {
				this.deleteToolDialog = {
					...this.deleteToolDialog,
					deleting: true,
				};
				const { id, runnitId } = this.deleteToolDialog.selectedNode;
				const runnitNodeRef = db.doc(`runnits/${runnitId}/runnitNodes/${id}`);

				await runnitNodeRef.update({
					deletedAt: new Date(),
				});
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Deleted the ${this.deleteToolDialog.selectedNode.title} tool from the Runnit`,
					show: true,
				});
			} catch (e) {
				console.error(e);

				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error! Problem deleting this tool from the Runnit, please reach out to report issues by clicking the support button in our top toolbar`,
					show: true,
				});
			} finally {
				if (closeAndResetCallback) {
					closeAndResetCallback();
				}
				this.onDeleteCancelReset();
			}
		},
		moveNode (selectedMenuItem: string, node: RunnitNode) {
			let nodeIndex: number = null;
			const nodeIdsList: string[] = (this.runnitNodes || []).map(({ id }, index: number) => {
				if (id === node.id) nodeIndex = index;
				return id;
			});

			// Can't move up the first node or move down the last node
			if (
				_isNil(nodeIndex) ||
				(
					selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.MOVE_UP &&
					nodeIndex <= 0
				) ||
				(
					selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.MOVE_DOWN &&
					nodeIndex >= this.runnitNodes.length - 1
				)
			) {
				return;
			}

			// Swap the node with the one before it or after it
			if (selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.MOVE_UP) {
				[nodeIdsList[nodeIndex - 1], nodeIdsList[nodeIndex]] = [nodeIdsList[nodeIndex], nodeIdsList[nodeIndex - 1]];
			} else if (selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.MOVE_DOWN) {
				[nodeIdsList[nodeIndex], nodeIdsList[nodeIndex + 1]] = [nodeIdsList[nodeIndex + 1], nodeIdsList[nodeIndex]];
			}

			// Set sortOrder
			return db.runTransaction(async (transaction) => {
				nodeIdsList.forEach((id: string, index: number) => {
					transaction.update(
						db.doc(`runnits/${node.runnitId}/runnitNodes/${id}`),
						{ sortOrder: index },
					);
				});
			});
		},
		determineMenuItems (node: RunnitNode, nodeIndex: string | number) {
			const onlyOneNodeExists: boolean = this.runnitNodes.length === 1;
			let menuItems: any[] = [...RUNNIT_TOOL_SETTINGS_MENU_ITEMS];
			if (onlyOneNodeExists) return menuItems;

			const nextNode: RunnitNode = this._get(this.runnitNodes, `[${+nodeIndex + 1}]`) || null;
			const isNodeInRunnitNodesLimitMap: boolean = !!this.runnitNodesLimitMap[node.id];
			const isNextNodeInRunnitNodesLimitMap: boolean = !!(nextNode && this.runnitNodesLimitMap[nextNode.id]);
			const canMoveUp: boolean = +nodeIndex > 0;
			const canMoveDown: boolean = +nodeIndex < (this.runnitNodes.length - 1);

			if (canMoveUp) {
				menuItems = menuItems.concat({
					id: 2,
					icon: 'mdi-chevron-up',
					label: 'Move Tool Up',
					description: '',
					value: RUNNIT_TOOL_SETTINGS_VALUES.MOVE_UP,
					disabled: !isNodeInRunnitNodesLimitMap,
				});
			}
			if (canMoveDown) {
				menuItems = menuItems.concat({
					id: 3,
					icon: 'mdi-chevron-down',
					label: 'Move Tool Down',
					description: '',
					value: RUNNIT_TOOL_SETTINGS_VALUES.MOVE_DOWN,
					disabled: !(isNodeInRunnitNodesLimitMap && isNextNodeInRunnitNodesLimitMap),
				});
			}
			menuItems.sort((a, b) => a.id - b.id);
			return menuItems;
		}
	},
	components: {
		LoadingState,
		RunnitNodeSettingsDialog,
		DoubleConfirmDangerDialog,
		BaseStyledMenu,
		GlassButton,
		ImageGallery,
		RunnitToolsLibraryDialog,
		EmptyState,
		ComplexBackground,
		ImageGalleryDialog,
		RunnitSettingsDrawer,
		ActionsIslandFixedBottom,
		BaseButton,
		RunnitImage,
	},
});
