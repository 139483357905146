
import Vue from 'vue';

export default Vue.extend({
	name: 'RunnitFieldHeader',
	props: {
		field: { type: Object, required: true },
	},
	data () {
		return {};
	},
});
