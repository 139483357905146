
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import { DbTimestamp, SessionBackgroundTask } from '@run-diffusion/shared';
import { SESSION_BACKGROUND_TASK_STATUS, SESSION_BACKGROUND_TASK_TYPE } from '@/constants/enums';
import moment from 'moment-timezone';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import DownloadedModelInstructions from '@/components/DownloadModelInstructions/DownloadedModelInstructions.vue';

export default Vue.extend({
	name: 'NotificationsContainer',
	data () {
		return {
			SESSION_BACKGROUND_TASK_STATUS,
			SESSION_BACKGROUND_TASK_TYPE,

			// FORM
			formValid: true,
		};
	},
	computed: {
		...mapState([
			'realTimeMoment',
			'toolbar',
			'currSessionBackgroundTasks',
			'loadingCurrSessionBackgroundTasks',
		]),
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
		onBackClick () {
			this.updateToolbar({
				notificationsOpen: !this.toolbar.notificationsOpen,
			});
		},
		getTimeDisplayText (timestamp: DbTimestamp) {
			if (!timestamp) return '';

			const hrDiff: number = this.realTimeMoment.diff(timestamp.toMillis(), 'hours');
			const minDiff: number = this.realTimeMoment.diff(timestamp.toMillis(), 'minutes') % 60;

			let secDiff: number = this.realTimeMoment.diff(timestamp.toMillis(), 'seconds') % 60;
			secDiff = Math.floor(secDiff / 10) * 10;

			if (hrDiff >= 24) {
				return moment(timestamp.toMillis()).format('MMM D [@] hh:mm a');
			} else if (minDiff > 0) {
				return `${minDiff} min ago`;
			} else if (secDiff > 0) {
				return `${secDiff} sec ago`;
			}
			return `now`;
		},
		showSessionBackgroundTaskProgressBar (sessionBackgroundTask: SessionBackgroundTask) {
			const { status, progress } = sessionBackgroundTask;
			return !!(status === SESSION_BACKGROUND_TASK_STATUS.IN_PROGRESS && progress);
		},
		getSessionBackgroundTaskTitle (sessionBackgroundTask: SessionBackgroundTask) {
			const { type, description } = sessionBackgroundTask;
			switch (type) {
				case SESSION_BACKGROUND_TASK_TYPE.DOWNLOAD_MODEL:
					return description || 'Download Model';
				case SESSION_BACKGROUND_TASK_TYPE.ALERT:
					return description || 'Message';
				default:
					return null;
			}
		},
	},
	components: {
		DownloadedModelInstructions,
		ConfirmDialog,
		EmptyState,
		LoadingSVG,
	},
});
