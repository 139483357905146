<template>
	<div></div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
	name: 'GlobalSCSS',
});
</script>

<style lang="scss">
	.transition-group-flex-container {
		display: flex;
		flex-wrap: wrap;
	}

	.transparent-img {
		width: 100vh;
		max-width: 100%;
		object-fit: cover;
	}

	/* rules-only-validation-text-field */
	.rules-only-validation-text-field {
		.v-input__slot {
			display: none !important;
		}
	}
	.rules-only-validation-text-field {
		.v-messages__message {
			font-size: 14px !important;
			font-weight: bold !important;
			color: var(--red-500);
		}
	}

	/* slide-fade & slide-fade-absolute Transition Group enter and leave animations */
	.slide-fade-enter-active, .slide-fade-leave-active, .slide-fade-absolute-enter-active, .slide-fade-absolute-leave-active {
		transition: all 0.3s ease;
	}
	.slide-fade-absolute-enter-active {
		position: relative;
	}
	.slide-fade-absolute-leave-active {
		opacity: 0;
		position: absolute;
	}
	.slide-fade-enter, .slide-fade-leave-to, .slide-fade-absolute-enter, .slide-fade-absolute-leave-to {
		/* Initial and final state for enter/leave transitions */
		opacity: 0;
		transform: translateY(10px);
	}
	.slide-fade-move, .slide-fade-absolute-move {
		transition: transform 0.3s ease;
	}

	/* Vuetify Overrides */
	.v-text-field--rounded .v-input__control {
		border-radius: 12px !important;
	}
	.v-text-field--rounded textarea::placeholder {
		color: var(--grey-550) !important;
	}

	.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot {
		background: rgba(255, 255, 255, 0.16) !important;
	}
	.theme--dark.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea {
		color: white !important;

		&::placeholder {
			color: var(--grey-500) !important;
		}
	}
	.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot input {
		color: white !important;

		&::placeholder {
			color: var(--grey-500) !important;
		}
	}

	.v-tooltip__content {
		background: rgb(0 0 0 / 63%) !important;
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
	}

	/* Column 1: 1 Shadow */
	.shadow-1-1 { box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); }
	.shadow-1-2 { box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); }
	.shadow-1-3 { box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3); }
	.shadow-1-4 { box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4); }
	.shadow-1-5 { box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); }
	.shadow-1-6 { box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.6); }
	.shadow-1-7 { box-shadow: 7px 7px 14px rgba(0, 0, 0, 0.7); }
	.shadow-1-8 { box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.8); }
	.shadow-1-9 { box-shadow: 9px 9px 18px rgba(0, 0, 0, 0.9); }
	.shadow-1-10 { box-shadow: 10px 10px 20px rgba(0, 0, 0, 1); }

	/* Column 2: 2 Shadows */
	.shadow-2-1 { box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1); }
	.shadow-2-2 { box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2), 4px 4px 8px rgba(0, 0, 0, 0.2); }
	.shadow-2-3 { box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3), 6px 6px 12px rgba(0, 0, 0, 0.3); }
	.shadow-2-4 { box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4), 8px 8px 16px rgba(0, 0, 0, 0.4); }
	.shadow-2-5 { box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5), 10px 10px 20px rgba(0, 0, 0, 0.5); }
	.shadow-2-6 { box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.6), 12px 12px 24px rgba(0, 0, 0, 0.6); }
	.shadow-2-7 { box-shadow: 7px 7px 14px rgba(0, 0, 0, 0.7), 14px 14px 28px rgba(0, 0, 0, 0.7); }
	.shadow-2-8 { box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.8), 16px 16px 32px rgba(0, 0, 0, 0.8); }
	.shadow-2-9 { box-shadow: 9px 9px 18px rgba(0, 0, 0, 0.9), 18px 18px 36px rgba(0, 0, 0, 0.9); }
	.shadow-2-10 { box-shadow: 10px 10px 20px rgba(0, 0, 0, 1), 20px 20px 40px rgba(0, 0, 0, 1); }

	/* Column 3: 3 Shadows */
	.shadow-3-1 { box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 3px 6px rgba(0, 0, 0, 0.1); }
	.shadow-3-2 { box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2), 4px 4px 8px rgba(0, 0, 0, 0.2), 6px 6px 12px rgba(0, 0, 0, 0.2); }
	.shadow-3-3 { box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3), 6px 6px 12px rgba(0, 0, 0, 0.3), 9px 9px 18px rgba(0, 0, 0, 0.3); }
	.shadow-3-4 { box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4), 8px 8px 16px rgba(0, 0, 0, 0.4), 12px 12px 24px rgba(0, 0, 0, 0.4); }
	.shadow-3-5 { box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5), 10px 10px 20px rgba(0, 0, 0, 0.5), 15px 15px 30px rgba(0, 0, 0, 0.5); }
	.shadow-3-6 { box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.6), 12px 12px 24px rgba(0, 0, 0, 0.6), 18px 18px 36px rgba(0, 0, 0, 0.6); }
	.shadow-3-7 { box-shadow: 7px 7px 14px rgba(0, 0, 0, 0.7), 14px 14px 28px rgba(0, 0, 0, 0.7), 21px 21px 42px rgba(0, 0, 0, 0.7); }
	.shadow-3-8 { box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.8), 16px 16px 32px rgba(0, 0, 0, 0.8), 24px 24px 48px rgba(0, 0, 0, 0.8); }
	.shadow-3-9 { box-shadow: 9px 9px 18px rgba(0, 0, 0, 0.9), 18px 18px 36px rgba(0, 0, 0, 0.9), 27px 27px 54px rgba(0, 0, 0, 0.9); }
	.shadow-3-10 { box-shadow: 10px 10px 20px rgba(0, 0, 0, 1), 20px 20px 40px rgba(0, 0, 0, 1), 30px 30px 60px rgba(0, 0, 0, 1); }
</style>
