
import Vue from 'vue';
import { mapState } from 'vuex';
import ListItem from '@/components/base/ListItem.vue';
import Workspaces from '@/views/Workspaces/Workspaces.vue';
import InfoAssetBanner from '@/components/base/InfoAssetBanner.vue';

export default Vue.extend({
	name: 'SpacesList',
	props: {},
	data () {
		return {};
	},
	computed: {
		...mapState([
			'user',
			'team',
		]),
	},
	components: {
		InfoAssetBanner,
		ListItem,
		Workspaces,
	},
});
