<template>
	<v-sheet
		outlined
		class="full-width pa-8"
	>
		<list-item
			icon="mdi-check-circle"
			style="width: 100%;"
			:container-styles="{
				marginBottom: 0,
			}"
		>
			<template v-slot:icon>
				<circle-icon
					:background-color="color"
					:border-color="color"
					icon-color="white"
					:icon-font-size-value="35"
					:icon-name="`mdi-numeric-${stepNumber}`"
					:width-height-value="45"
					class="mb-4"
				/>
			</template>
			<template v-slot:default>
				<div class="content-container">
					<slot name="default"></slot>
				</div>
			</template>
		</list-item>
	</v-sheet>
</template>

<script>
import Vue from 'vue';
import ListItem from './base/ListItem';
import CircleIcon from './base/CircleIcon';

export default Vue.extend({
	name: 'InstructionsStep',
	props: {
		color: { type: String, default: '#6f86ff' },
		stepNumber: { type: String, default: '1' },
	},
	components: {
		ListItem,
		CircleIcon,
	},
});
</script>

<style scoped>
	.content-container {
		padding: 0 0 0 30px;
		display: flex;
		flex-direction: column;
	}
</style>
