
import Vue from 'vue';

export default Vue.extend({
	name: 'ActionsIslandFixedBottom',
	props: {
		width: { type: String, default: null },
		height: { type: String, default: null },
		styles: { type: Object, default: null },
		detached: { type: Boolean, default: false },
	},
});
