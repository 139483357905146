
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import DialogContent from '@/components/base/DialogContent.vue';
import { titleCase } from '@/filters';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import RunnitTokens from '@/views/Runnits/RunnitTokens.vue';
import ActionsIsland from '@/components/ActionsIsland.vue';
import { db } from '@/firebase';
import { get$bindFirestoreOptions, MyBalanceMixin } from '@/mixins';
import { ClubKey } from '@run-diffusion/shared';
import moment, { Moment } from 'moment-timezone';

export default Vue.extend({
	name: 'ConfirmPlanChangeDialog',
	mixins: [
		MyBalanceMixin,
	],
	props: {
		loading: { type: Boolean, default: false },
		open: { type: Boolean, default: false },
		isPaymentFailed: { type: Boolean, default: false },
		paymentFailedData: { type: Object, default: null },
		isUpdateNotAllowed: { type: Boolean, default: false },
		updateNotAllowedData: { type: Object, default: null },
		club: { type: String, default: null },
		isAnnual: { type: Boolean, default: false },
		previewData: { type: Object, default: null },
		oldClubOffer: { type: Object, default: null },
	},
	data () {
		return {
			reloadWindowRequired: false,
			showProratedLinesDetails: false,
			showRecurringLinesDetails: false,
			tokenClubAddOn: null,
			limitClubAddOn: null,
		};
	},
	computed: {
		...mapState([
			'clubOffersMap',
		]),
		dueTodayInvoiceTotalText () {
			if (this._get(this.previewData, 'dueTodayInvoice.total') < 0) {
				return `Credit toward future subscription renewal costs`;
			}
			return 'What you will pay right now';
		},
		confirmDialogConfirmText () {
			return this.reloadWindowRequired ? 'Reload Page' : 'Confirm';
		},
		confirmDialogBodyText () {
			if (this.reloadWindowRequired) {
				return `This window has been open too long. You need to reload this page before you can update your subscription.`;
			}
			return `Are you sure you want to update your plan? ${this._get(this.previewData, 'dueTodayInvoice.total') > 0 ? ` If payment succeeds, your payment method on file will be used to cover the cost, otherwise you will be asked to change your payment method before trying again.` : ''}`
		},
		runnitTokensBalancePostUpdate () {
			if (this.previewData && this.previewData.isBillingCycleReset && this.previewData.newMonthlyTokens) {
				return this.previewData.newMonthlyTokens + this.userRunnitTokens;
			}
			return this.userRunnitTokens;
		},
		updatePlanButtonDisabled () {
			return !!(
				this.isUpdateNotAllowed ||
				this.loading
			);
		},
		recurringSubscriptionAmount () {
			if (!this.previewData || !this.previewData.recurringInvoice) return 0;
			return this.previewData.recurringInvoice.lines.data
				.filter(line =>
					line.type === 'subscription' && line.price?.recurring
				)
				.reduce((total: number, line) => (
					total + (line.amount || 0)
				), 0);
		},
	},
	watch: {
		'$route.query': {
			immediate: true,
			async handler (newVal: any, oldVal: any) {
				const newClub: ClubKey = this._get(newVal, 'club', null);
				const newTokenClubAddOnId: string = this._get(newVal, 'tokenClubAddOnId', null);
				const newLimitClubAddOnId: string = this._get(newVal, 'limitClubAddOnId', null);
				const oldClub: ClubKey = this._get(oldVal, 'club', null);
				const oldTokenClubAddOnId: string = this._get(oldVal, 'tokenClubAddOnId', null);
				const oldLimitClubAddOnId: string = this._get(oldVal, 'limitClubAddOnId', null);
				if (
					newVal &&
					(
						newClub !== oldClub ||
						newTokenClubAddOnId !== oldTokenClubAddOnId ||
						newLimitClubAddOnId !== oldLimitClubAddOnId
					)
				) {
					this.$bind(
						'tokenClubAddOn',
						db.doc(`clubOffers/${newClub}/tokenClubAddOns/${newTokenClubAddOnId}`),
						get$bindFirestoreOptions(),
					);
					this.$bind(
						'limitClubAddOn',
						db.doc(`clubOffers/${newClub}/limitClubAddOns/${newLimitClubAddOnId}`),
						get$bindFirestoreOptions(),
					);
				}
			},
		},
	},
	methods: {
		...mapActions([
			'goToStripeSubscriptionPortal',
		]),
		titleCase,
		onCancel () {
			this.$emit('on-cancel', false);
		},
		isUpgradeCalculationTimingCloseEnoughToNow () {
			if (this.previewData && this.previewData.dueTodayInvoice) {
				return moment()
					.subtract(1, 'hours')
					.isBefore(this.previewData.dueTodayInvoice.created * 1000);
			}
			return true;
		},
		onFirstUpdatePlanClick () {
			if (!this.isUpgradeCalculationTimingCloseEnoughToNow()) {
				this.reloadWindowRequired = true;
			}
		},
		onUpgrade (closeDialogCallback: Function) {
			if (this.isUpgradeCalculationTimingCloseEnoughToNow()) {
				this.$emit('on-upgrade');
				if (closeDialogCallback) closeDialogCallback();
			} else {
				window.location.reload();
			}
		},
	},
	components: {
		ActionsIsland,
		RunnitTokens,
		EmptyState,
		ConfirmDialog,
		DialogContent,
	},
});
