
import Vue from 'vue';
import WorkshopSessionLinkDialog from '@/views/Workshops/WorkshopPage/WorkshopSessionLinkDialog.vue';

export default Vue.extend({
	name: 'WorkshopSessionCard',
	props: {
		workshop: { type: Object, required: true },
		workshopSession: { type: Object, required: true },
		appOffer: { type: Object, default: null },
		softwareOffer: { type: Object, default: null },
		hardwareOffer: { type: Object, default: null },
		hasLiveSession: { type: Boolean, default: false },
	},
	components: {
		WorkshopSessionLinkDialog,
	},
});
