
import Vue from 'vue';
import _isEmpty from 'lodash/isEmpty';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import ComplexBackgroundBaseStyledMenu from '@/components/designElements/ComplexBackgroundBaseStyledMenu.vue';
import RunnitMenuOrBottomSheet from '../base/RunnitMenuOrBottomSheet.vue';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
	name: 'RunnitSingleSelect',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: [Number, String, Object, Array], default: null },
		adminOnly: { type: Boolean, default: false },
	},
	data () {
		return {
			imageSelectOpen: false,
		};
	},
	computed: {
		...mapState([
			'runnitState',
		]),
		localValue () {
			let value = this.value ? this.options.find((option) => option.value === this.value) : null

			return value;
		},
		useImageSelect () {
			return !_isEmpty(this._get(this.field, 'display.image'));
		},
		useImageDialog () {
			return this._get(this.field, 'display.image.dialogSelect');
		},
		imageMinWidth () {
			return this._get(this.field, 'display.image.imageMinWidth');
		},
		useRadioSelect () {
			return !_isEmpty(this._get(this.field, 'display.radio'));
		},
		useTextSelect () {
			return !_isEmpty(this._get(this.field, 'display.text'));
		},
		options () {
			if (this.useImageSelect) return this._get(this.field, 'display.image.options');
			if (this.useRadioSelect) return this._get(this.field, 'display.radio.options');
			if (this.useTextSelect) return this._get(this.field, 'display.text.options');
			return this._get(this.field, 'display.options') || []; // backwards compatible with old text select
		}
	},
	methods: {
		...mapActions([
			'updateRunnitState',
		]),
		onSelectImageOption (option: any, close: () => void) {
			this.$emit('input', option.value);

			this.closeImageSelect();
			if (close) {
				close();
			}
		},
		emitInput (value: string) {
			this.$emit('input', value);
		},
		openImageSelect () {
			this.updateRunnitState({
				imageSelectOpen: true,
				// Close all other potentially open runnit menus
				promptGeneratorOpen: false,
				inpaintingEditorOpen: false,
			});
		},
		closeImageSelect () {
			this.updateRunnitState({
				imageSelectOpen: false,
			});
		}
	},
	components: {
		ComplexBackgroundBaseStyledMenu,
		MenuOrBottomSheet,
		RunnitDynamicFieldLabelRow,
		RunnitMenuOrBottomSheet,
	},
});
