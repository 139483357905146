
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { db, storage } from '@/firebase';
import { NODE_RUN_RESULT_SELECTION_MODE } from '@/views/Runnits/constants';
import RunnitSettingHelperBtn from '@/views/Runnits/RunnitSettings/RunnitSettingHelperBtn.vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import ListItem from '@/components/base/ListItem.vue';
import { RunnitState } from '@/store';
import { RunnitMask, RunnitNodeField, RunnitNodeRunInputValue, RunnitNodeRunResult, RunnitUpload, UploadFile } from '@run-diffusion/shared';
import _isEqual from 'lodash/isEqual';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { IMAGE_UPLOAD_MODE } from '@/constants/enums';
import ImageInputSelectedImageContainer from '@/views/Runnits/RunnitSettings/ImageInput/ImageInputSelectedImageContainer.vue';
import { IMAGE_GALLERY_DIALOG_NAV, ImageGalleryDialogNav } from '@/components/ImageGallery/utils';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';
import InpaintingEditor from '../InpaintingEditor/InpaintingEditor.vue';
import ImageUpload from './ImageUpload.vue';

type ImgToImgSelection = 'NONE' | 'SELECT' | 'UPLOAD';
export default Vue.extend({
	name: 'ImageInput',
	mixins: [
		RunnitDynamicFieldMixin,
		RunnitsImageSelectMixin,
	],
	props: {
		field: { type: Object, required: true },
		value: { type: Object, default: null },
		nodeId: { type: String, default: null },
		adminOnly: { type: Boolean, default: false },
	},
	data () {
		const IMG_TO_IMG: Record<ImgToImgSelection, ImgToImgSelection> = {
			NONE: 'NONE',
			SELECT: 'SELECT',
			UPLOAD: 'UPLOAD',
		};

		return {
			IMAGE_UPLOAD_MODE,
			IMAGE_GALLERY_DIALOG_NAV,
			FILE_SIZE_LIMIT: 4194304, // 4 MB in bytes
			IMG_TO_IMG,

			imgToImgSelection: IMG_TO_IMG.NONE,

			isUploading: false,
			isBeingDraggedOver: false,
		};
	},
	destroyed () {
		this.exitSelectionMode(this.selectionStateTriggerId);
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
		]),
		showNoneOptionActionSection () {
			return this.imgToImgSelection === this.IMG_TO_IMG.NONE;
		},
		showSelectOptionActionSection () {
			return this.imgToImgSelection === this.IMG_TO_IMG.SELECT;
		},
		showUploadOptionActionSection () {
			return this.imgToImgSelection === this.IMG_TO_IMG.UPLOAD;
		},
		selectionStateTriggerId () {
			return `image-input-${this.field.uuid}`;
		},
		hasInpainting () {
			return !!this._get(this.field, 'display.hasInpainting');
		},
		inpaintingRequired () {
			return this.hasInpainting && this.field.required && !!this._get(this.field, 'display.inpaintingRequired');
		},
		hasStrength () {
			return !!this._get(this.field, 'display.hasStrength');
		},
		imageValue () {
			let val = this.value
			if (this.hasInpainting || this.hasStrength) {
				val = this._get(this.value, 'image') || null;
			}
			return val;
		},
		inpaintingValue () {
			if (this.hasInpainting) {
				return this._get(this.value, 'inpainting') || { mask: null };
			}
			return null;
		},
		strengthValue () {
			if (this.hasStrength) {
				return this._get(this.value, 'strength') || 0.8;
			}
			return null;
		},
	},
	watch: {
		value: {
			immediate: true,
			async handler (newVal: RunnitNodeRunInputValue, oldVal: RunnitNodeRunInputValue) {
				if (this.hasInpainting) {
					if (!oldVal && this._get(newVal, 'image') && this.imgToImgSelection === this.IMG_TO_IMG.NONE) {
						this.imgToImgSelection = this.IMG_TO_IMG.SELECT;
					}

					if (this._get(newVal, 'image') && newVal.image.nodeRunResult) {
						this.imgToImgSelection = this.IMG_TO_IMG.SELECT;
					}

					const mask = this._get(newVal, 'inpainting.mask') || {}
					await this.setMaskURL(this._get(mask, 'id') || null);
				} else {
					if (!oldVal && newVal && this.imgToImgSelection === this.IMG_TO_IMG.NONE) {
						this.imgToImgSelection = this.IMG_TO_IMG.SELECT;
					}

					if (newVal && newVal.nodeRunResult) {
						this.imgToImgSelection = this.IMG_TO_IMG.SELECT;
					}
					this.setMaskURL(null);
				}
			},
		},
		field: {
			immediate: true,
			handler (newVal: RunnitNodeField) {
				if (newVal && newVal.required && this.imgToImgSelection === this.IMG_TO_IMG.NONE) {
					this.imgToImgSelection = this.IMG_TO_IMG.SELECT;
				}
			},
		},
		imgToImgSelection: {
			immediate: true,
			handler (newVal: ImgToImgSelection, oldVal: ImgToImgSelection) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case this.IMG_TO_IMG.NONE:
						default:
							if (this.imageValue) this.onInput(null, null, null);
							this.exitSelectionMode(this.selectionStateTriggerId);
							break;
						case this.IMG_TO_IMG.UPLOAD:
							if (this.imageValue) this.onInput(null, null, null);
							this.exitSelectionMode(this.selectionStateTriggerId);
							break;
						case this.IMG_TO_IMG.SELECT:
							if (this.imageValue && this.imageValue.upload && oldVal === this.IMG_TO_IMG.UPLOAD) {
								this.onInput(null, null, null);
							}
							if (!this.imageValue) {
								this.updateRunnitState({
									imageGalleryDialogOpen: false,
								});
								this.enterSelectionMode(NODE_RUN_RESULT_SELECTION_MODE.SINGLE, this.selectionStateTriggerId, this.field, ['IMG']);
							}
							break;
					}
				}
			},
		},
		runnitState: {
			immediate: false,
			handler (newVal: RunnitState, oldVal: RunnitState) {
				if (
					newVal !== oldVal &&
					newVal.selectionStateHistory[this.selectionStateTriggerId] &&
					newVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection &&
					newVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection.uuid === this.field.uuid &&
					oldVal.selectionStateHistory[this.selectionStateTriggerId]
				) {
					const newResultUuidsList: string[] = newVal.selectionStateHistory[this.selectionStateTriggerId].selectedNodeRunResults.map(({ uuid }) => uuid);
					const oldResultUuidsList: string[] = oldVal.selectionStateHistory[this.selectionStateTriggerId].selectedNodeRunResults.map(({ uuid }) => uuid);
					const newUploadIdList: string[] = newVal.selectionStateHistory[this.selectionStateTriggerId].selectedUploads.map(({ id }) => id);
					const oldUploadIdList: string[] = oldVal.selectionStateHistory[this.selectionStateTriggerId].selectedUploads.map(({ id }) => id);
					if (
						(
							!oldVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection ||
							oldVal.selectionStateHistory[this.selectionStateTriggerId].fieldAwaitingImageGallerySelection.uuid !== this.field.uuid
						) ||
						!_isEqual(newResultUuidsList, oldResultUuidsList) ||
						!_isEqual(newUploadIdList, oldUploadIdList)
					) {
						const valueNodeRunResultUuid: string = this._get(this.imageValue, 'nodeRunResult.uuid') || null;
						const valueUploadId: string = this._get(this.imageValue, 'upload.id') || null;
						const firstSelectedNodeRunResult: RunnitNodeRunResult = this._get(newVal.selectionStateHistory[this.selectionStateTriggerId].selectedNodeRunResults, '[0]') || null;
						const firstSelectedUpload: RunnitUpload = this._get(newVal.selectionStateHistory[this.selectionStateTriggerId].selectedUploads, '[0]') || null;

						if (!firstSelectedNodeRunResult && !firstSelectedUpload && (valueNodeRunResultUuid || valueUploadId)) {
							this.onInput(null, null, null);
						} else if (firstSelectedNodeRunResult && firstSelectedNodeRunResult.uuid !== valueNodeRunResultUuid) {
							this.onInput({ nodeRunResult: firstSelectedNodeRunResult }, this.inpaintingValue, this.strengthValue);
						} else if (firstSelectedUpload && firstSelectedUpload.id !== valueUploadId) {
							this.onInput({ upload: firstSelectedUpload }, this.inpaintingValue, this.strengthValue);
						}
					}
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
		]),
		onInput (image: RunnitNodeRunInputValue, inpainting: RunnitNodeRunInputValue, strength: number) {
			if (this.hasInpainting || this.hasStrength) {
				if (image || inpainting || strength) {
					this.$emit('input', {
						image,
						inpainting,
						strength,
					});
				} else {
					this.$emit('input', null);
				}
			} else {
				this.$emit('input', image);
			}
		},
		clearValueAndStartSelectSelectionMode () {
			this.onInput(null, null, null);
			this.clearSelectionsFromState(this.selectionStateTriggerId);
			this.enterSelectionMode(NODE_RUN_RESULT_SELECTION_MODE.SINGLE, this.selectionStateTriggerId, this.field, ['IMG']);
		},
		onStrengthChange (strength) {
			this.onInput(this.imageValue, this.inpaintingValue, strength);
		},
		startLibrarySelectionMode (imageGalleryDialogInitialNav: ImageGalleryDialogNav) {
			this.updateRunnitState({
				imageGalleryDialogOpen: true,
				imageGalleryDialogInitialNav: imageGalleryDialogInitialNav,
			});
			this.enterSelectionMode(NODE_RUN_RESULT_SELECTION_MODE.SINGLE, this.selectionStateTriggerId, this.field, ['IMG']);
		},
		onUploadSelection () {
			this.imgToImgSelection = this.IMG_TO_IMG.UPLOAD;
		},
		async onInpaintingMaskChange (mask) {
			this.onInput(this.imageValue, { ...this.inpaintingValue, mask }, this.strengthValue);
			await this.setMaskURL(this._get(mask, 'id') || null);
		},
		async setMaskURL (maskId) {
			if (maskId) {
				const maskRef = db.doc(`runnitMasks/${maskId}`);
				const mask: RunnitMask = (await maskRef.get()).data() as RunnitMask;

				const maskImageRef = storage.ref(`runnitMasks/users/${mask.userId}/masks/${mask.name}`);

				let maskURL = null;
				try {
					// Exists
					maskURL = await maskImageRef.getDownloadURL();
				} catch (err) {
					// doesn't exist
				}
				this.updateRunnitState({
					inpaintingMaskUrl: maskURL,
				});
			} else {
				this.updateRunnitState({
					inpaintingMaskUrl: null,
				});
			}
		},
		handleImageUpload (upload: UploadFile) {
			this.onInput({ upload }, this.inpaintingValue, this.strengthValue);
		},
	},
	components: {
		ImageInputSelectedImageContainer,
		ListItem,
		RunnitSettingHelperBtn,
		RunnitDynamicFieldLabelRow,
		InpaintingEditor,
		ImageUpload,
	},
});
