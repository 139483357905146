var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"info-asset-container",style:({
		flexDirection: _vm.$vuetify.breakpoint.xsOnly ? 'column' : 'row',
	})},[(_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticClass:"info-asset-asset",style:({
			flex: _vm.assetFlex,
			margin: '0 0 30px 0',
		})},[_vm._t("asset")],2):_vm._e(),_c('div',{staticClass:"info-asset-info",style:({
			flex: _vm.infoFlex,
		})},[_vm._t("info")],2),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"info-asset-asset",style:({
			flex: _vm.assetFlex,
		})},[_vm._t("asset")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }