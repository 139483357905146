import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"d-flex justify-space-between mt-12",class:{
				'flex-column': _vm.$vuetify.breakpoint.xsOnly,
				'align-center': !_vm.$vuetify.breakpoint.xsOnly,
			},attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex",class:{
					'align-start': _vm.$vuetify.breakpoint.xsOnly,
					'align-center': !_vm.$vuetify.breakpoint.xsOnly,
				}},[_c('back-button',{class:{
						'mt-3': _vm.$vuetify.breakpoint.xsOnly,
					},on:{"click":_vm.goHome}}),_c('div',{staticClass:"page-title pl-6"},[_vm._v("Reporting")])],1),(_vm.selectedTeam || _vm.selectedCompany)?_c('div',{staticClass:"d-flex align-baseline",class:{
					'flex-column': _vm.$vuetify.breakpoint.xsOnly,
					'mb-4': _vm.$vuetify.breakpoint.xsOnly,
					'team-selection-container': _vm.$vuetify.breakpoint.xsOnly,
				}},[_c('div',{staticClass:"selected-team gradient-text",style:({
						margin: _vm.$vuetify.breakpoint.xsOnly ? '25px 0 0 5px' : '0 0 0 0',
						fontSize: _vm.$vuetify.breakpoint.smAndDown ? '1.2rem' : '1.5rem',
					})},[(_vm.selectedTeam)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedTeam.name)+" ")]):(_vm.selectedCompany)?_c('span',[_vm._v(" All Teams ")]):_vm._e()]),_c('team-picker',{attrs:{"allow-new-team-creation":"","allow-company-selection":"","selected-company":_vm.selectedCompany},on:{"on-company-select":_vm.onCompanySelect},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"font-size":"16px","color":"var(--primary-blue)"},attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" change "),_c(VIcon,{staticStyle:{"margin":"4px 0 0 -2px","color":"var(--primary-blue)"},attrs:{"right":""}},[_vm._v("mdi-menu-right")])],1)]}}],null,false,2401167938)})],1):_vm._e()])],1),_c(VRow,{staticStyle:{"margin-top":"0","margin-bottom":"10px"}},[_c('page-top-navigation',{staticClass:"ml-4",attrs:{"tabs-data":_vm.tabsData}})],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"d-flex justify-start flex-column",attrs:{"cols":"12"}},[_c('router-view',{attrs:{"selected-team":_vm.selectedTeam,"selected-company":_vm.selectedCompany}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }