import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnit-carousel"},[(_vm.includeViewAll && (_vm.canScrollLeft || _vm.canScrollRight))?_c(VBtn,{staticClass:"view-all-button",style:(_vm.viewAllStyles),attrs:{"text":""},on:{"click":function($event){return _vm.$emit('view-all-click')}}},[_vm._v(" "+_vm._s(_vm.viewAllText)+" ")]):_vm._e(),_c(VBtn,{staticClass:"scroll-button left",style:({
			visibility: _vm.canScrollLeft ? 'visible' : 'hidden',
		}),attrs:{"disabled":!_vm.canScrollLeft},on:{"click":_vm.scrollLeft}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-chevron-left")])],1),_c('div',{ref:"carouselWrapper",staticClass:"carousel-wrapper"},[_c('div',{ref:"carouselContent",staticClass:"carousel-content",style:({
				gap: _vm.gap
			})},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"carousel-item"},[_vm._t("item",null,{"item":item})],2)}),0)]),_c(VBtn,{staticClass:"scroll-button right",style:({
			visibility: _vm.canScrollRight ? 'visible' : 'hidden',
		}),attrs:{"disabled":!_vm.canScrollRight},on:{"click":_vm.scrollRight}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-chevron-right")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }