import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('stopping-session-dialog',{attrs:{"value":_vm.showStoppingSessionScreen,"session":_vm.toolbar.session}}),(_vm.showBootingSessionScreen)?_c('booting-session-screen',{attrs:{"session":_vm.toolbar.session}}):(!_vm.showBootingSessionScreen && !_vm.showStoppingSessionScreen)?_c('div',{staticClass:"d-flex"},[(_vm.isWorkstationUi)?_c('workstation-ui',{style:(_vm.uiStyle),attrs:{"session":_vm.toolbar.session}}):_c('iframe',{style:(_vm.uiStyle),attrs:{"id":_vm.UI_BROWSER_IFRAME_ID,"src":_vm.serverUrlUi,"frameborder":"0","title":"Launched App","allow":"clipboard-write;clipboard-read;camera;microphone;display-capture;","allowfullscreen":""}}),(_vm.allowFileBrowserSidePanel)?_c('iframe',{style:(_vm.fileBrowserIframeStyle),attrs:{"id":_vm.FILE_BROWSER_IFRAME_ID,"src":_vm.serverUrlFileBrowser,"frameborder":"0","title":"File Browser","allow":"clipboard-write;clipboard-read;display-capture;"}}):_vm._e(),((
				_vm.isComfyUi &&
				!_vm.getComfyOnboardingDismissed()
			))?_c('comfy-onboarding'):_vm._e(),_c('div',{staticClass:"d-flex justify-center",style:(_vm.fileBrowserReloadBtnContainerStyle)},[_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VSlideYTransition,[_c('base-button',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isFileBrowserOpen),expression:"isFileBrowserOpen"}],style:({
								width: _vm.RELOAD_FILE_BROWSER_BTN_WIDTH,
							}),attrs:{"call-to-action":""},on:{"click":function($event){return _vm.reloadIframe(_vm.FILE_BROWSER_IFRAME_ID)}}},'base-button',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v(" Reload ")],1)],1)]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Reload generated images")])])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }