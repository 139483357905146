import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"primary-background-circle",style:({
		width: _vm.circleWidthHeightValue,
		minWidth: _vm.circleWidthHeightValue,
		height: _vm.circleWidthHeightValue,
		minHeight: _vm.circleWidthHeightValue,
		border: _vm.$vuetify.breakpoint.xsOnly && !_vm.preventMobileResize ? 'none' : `${_vm.borderSizeValue}px solid ${_vm.borderColor}`,
		background: _vm.$vuetify.breakpoint.xsOnly && !_vm.preventMobileResize ? 'transparent' : _vm.backgroundColor,
		..._vm.primaryBackgroundStylesObj,
	})},[(_vm.iconName)?[_c(VIcon,{style:({
				position: 'relative',
				fontSize: _vm.$vuetify.breakpoint.xsOnly
					? `${_vm.iconFontSizeValue * 0.8}px`
					: `${_vm.iconFontSizeValue}px`,
				..._vm.iconStylesObj,
			}),attrs:{"color":_vm.iconColor}},[_vm._v(" "+_vm._s(_vm.iconName)+" ")])]:_c('div',{staticClass:"d-flex justify-content align-center",style:(_vm.iconStylesObj)},[_vm._t("icon")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }