<template>
	<div>
		<v-switch
			:input-value="value"
			@change="$emit('input', $event)"
			inset
			style="padding: 0;"
		>
			<template v-slot:label>
				<div class="pl-2 d-flex flex-column" style="top: 10px; position: relative;">
					<div>
						Persist your extensions (and ComfyUI nodes). The next time you boot up a session, these things will automatically be installed for you.
					</div>
					<div class="mt-1" style="font-size: 13px; opacity: 0.7;">
						*This may add up to 1 minute per extension to your startup time
					</div>
				</div>
			</template>
		</v-switch>
	</div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'EnablePersistExtensionsSettings',
	props: {
		value: { type: Boolean, default: false },
	},
});
</script>
