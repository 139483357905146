
import Vue from 'vue';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';
import TokenClubAddOnSelectItem from '@/views/Runnits/pages/RunnitsSubscriptionsPage/TokenClubAddOnSelect/TokenClubAddOnSelectItem.vue';
import { TokenClubAddOn } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'TokenClubAddOnSelect',
	components: {
		TokenClubAddOnSelectItem,
		MenuOrBottomSheet,
	},
	props: {
		tokenClubAddOns: { type: Array, required: true },
		clubOffer: { type: Object, required: true },
		selectedItem: { type: Object, default: null },
		currentPlanItem: { type: Object, default: null },
	},
	methods: {
		isCurrentPlanItem (item: TokenClubAddOn) {
			return !!(this.currentPlanItem && item && this.currentPlanItem.id === item.id);
		},
	},
});
