import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"workspace-card",class:{
			'workspace-card-hover': _vm.selectionMode && hover,
		},style:({
			border: _vm.selectionMode && _vm.selected ? '1px solid var(--primary-blue)' : '1px solid var(--grey-800)',
			minHeight: _vm.selectionMode ? '170px' : '270px',
		}),on:{"click":_vm.handleClick}},[_vm._t("content",function(){return [(_vm.workspace)?_c('div',{staticClass:"full-width d-flex align-start justify-space-between"},[_c('div',{staticClass:"full-width d-flex flex-column full-height justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"workspace-card-name mr-2",class:{
									'gradient-text': _vm.selectionMode && _vm.selected,
								},staticStyle:{"max-width":"fit-content"}},[_vm._v(" "+_vm._s(_vm._f("truncateMiddle")(_vm.workspace.name,25))+" ")]),(_vm.workspace && (_vm.user.isAdmin || _vm.isAdminProxyingUser))?_c('admin-workspace-stats',{attrs:{"workspace":_vm.workspace}}):_vm._e()],1),_c('div',{staticClass:"workspace-card-description",class:{
								'workspace-card-description-selected': _vm.selectionMode && _vm.selected,
							}},[_vm._v(" "+_vm._s(_vm._truncate(_vm.workspace.description, { length: 25 }))+" ")])]),_c('div',{staticClass:"d-flex justify-space-between align-baseline full-width",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"workspace-card-storage"},[_c('span',{class:{
									'workspace-card-storage-selected': _vm.selectionMode && _vm.selected,
								},staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm._f("prettyBytes")((_vm.workspace.storageBytes || 0),{ minimumFractionDigits: 1, maximumFractionDigits: 1 })))]),_c('span',{class:{
									'workspace-card-storage-cap-selected': _vm.selectionMode && _vm.selected,
								},staticStyle:{"color":"var(--grey-600)","font-size":"20px"}},[_vm._v(" / "+_vm._s(_vm._f("prettyBytes")((_vm.workspace.storageBytesCap || 0),{ minimumFractionDigits: 1, maximumFractionDigits: 1 })))])]),(!_vm.selectionMode)?_c('div',{staticStyle:{"position":"relative","color":"var(--grey-600)"},style:({
								'left': _vm.$vuetify.breakpoint.smAndUp ? '45px' : '70px',
							})},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.workspace.createdAt.toMillis(),'MMM D, YYYY'))+" ")]):_vm._e()])]),_c('div',{staticClass:"d-flex"},[((
							_vm.isTeamAdmin &&
							!_vm.selectionMode
						))?_c(VBtn,{staticStyle:{"position":"relative","right":"-10px"},attrs:{"icon":"","fab":"","small":"","disabled":!_vm.isTeamActive},on:{"click":function($event){return _vm.$emit('on-edit-workspace', _vm.workspace)}}},[_c(VIcon,{staticStyle:{"color":"var(--grey-500)"}},[_vm._v("mdi-cog-outline")])],1):_vm._e(),(_vm.selectionMode && _vm.selected)?_c(VIcon,{staticStyle:{"color":"var(--primary-blue)"}},[_vm._v(" mdi-check-circle ")]):(_vm.selectionMode && !_vm.selected)?_c(VIcon,{attrs:{"color":"#575757"}},[_vm._v(" mdi-circle-outline ")]):_vm._e()],1)]):_vm._e()]})],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }