
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RUNNIT_NODE_DEF_TOOL_TYPE } from '@/constants/enums';
import {
	RunnitsCRUDMixin,
} from '@/mixins';
import OfferingCard from '@/components/OfferingCard.vue';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import LoadingState from '@/components/states/LoadingState.vue';

export default Vue.extend({
	name: 'RunnitPrivatePage',
	mixins: [
		RunnitsCRUDMixin,
	],
	props: {},
	data () {
		return {
			RUNNIT_NODE_DEF_TOOL_TYPE,
			RUNNITS_OWNER_SELECTION,
		};
	},
	computed: {
		...mapState([
			'user',
			'team',
			'runnits',
			'runnitsMap',
			'runnitState',
			'loadingRunnits',
		]),
		...mapGetters([
			'runnitsLimitMap',
		]),
	},
	methods: {
		...mapActions([
			'setUpsellDialog',
		]),
	},
	components: {
		LoadingState,
		OfferingCard,
	},
});
