import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"auth-provider-pickers-outer-container"},[(_vm.warningStepHtml)?_c(VAlert,{staticClass:"mt-0 mb-6",class:{
			'mx-4': _vm.$vuetify.breakpoint.smAndUp,
		},attrs:{"shaped":"","outlined":"","type":"warning"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.warningStepHtml)}}),(!_vm.dismissedWarningStep)?[_c(VDivider,{staticClass:"my-4 warning",staticStyle:{"opacity":"0.35"}}),_c('base-button',{attrs:{"secondary":""},on:{"click":function($event){_vm.dismissedWarningStep = true}}},[_vm._v(" I Understand ")])]:_vm._e()],2):_vm._e(),_c(VExpandTransition,[(!_vm.isAwaitingWarningStepDismissal)?_c('div',{staticClass:"full-width"},[(_vm.continueWithText)?_c('div',{staticClass:"divider-container"},[_c('div',{staticClass:"divider-line mr-5",class:{
					'divider-line-mobile': _vm.$vuetify.breakpoint.xsOnly,
				}}),_c('div',{staticClass:"text-center",staticStyle:{"color":"var(--grey-400)","font-size":"14px"}},[_vm._v(_vm._s(_vm.continueWithText))]),_c('div',{staticClass:"divider-line ml-5",class:{
					'divider-line-mobile': _vm.$vuetify.breakpoint.xsOnly,
				}})]):_vm._e(),_c('div',{staticClass:"auth-provider-pickers-container",style:({
					'flex-direction': _vm.iconView ? 'row' : 'column',
				})},[(_vm.includeEmailPassword)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('div',_vm._g({staticClass:"auth-provider-container",class:{
								'mx-3': _vm.iconView,
							},style:({
								...(_vm.iconView ? {
									'max-width': '47px',
								} : {
									'max-width': '200px',
								}),
							}),on:{"click":_vm.onEmailPasswordContinue}},tooltipOn),[_c(VIcon,[_vm._v("mdi-form-textbox-password")]),(!_vm.iconView)?_c('div',{staticClass:"social-label no-select"},[_vm._v(_vm._s(_vm.getAuthProviderDisplayText('password', true)))]):_vm._e()],1)]}}],null,false,2788072541)},[_c('div',{staticClass:"tooltip-max-width no-select"},[_vm._v(" "+_vm._s(_vm.getAuthProviderDisplayText('password', false))+" ")])]):_vm._e(),(_vm.includeGoogle)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('div',_vm._g({staticClass:"auth-provider-container",class:{
								'mx-3': _vm.iconView,
							},style:({
								...(_vm.iconView ? {
									'max-width': '47px',
								} : {
									'max-width': '200px',
								}),
							}),on:{"click":_vm.onGoogleContinue}},tooltipOn),[(_vm.googleLoading)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":"24","width":"1"}}):_c('img',{staticStyle:{"margin":"0"},attrs:{"alt":"Google Logo","width":25,"src":require("../../assets/google-icon.svg")}}),(!_vm.iconView)?_c('div',{staticClass:"social-label no-select"},[_vm._v(_vm._s(_vm.getAuthProviderDisplayText('google.com', true)))]):_vm._e()],1)]}}],null,false,1542599060)},[_c('div',{staticClass:"tooltip-max-width no-select"},[_vm._v(" "+_vm._s(_vm.getAuthProviderDisplayText('google.com', false))+" ")])]):_vm._e(),(_vm.includeMicrosoft)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('div',_vm._g({staticClass:"auth-provider-container",class:{
								'mx-3': _vm.iconView,
							},style:({
								...(_vm.iconView ? {
									'max-width': '47px',
								} : {
									'max-width': '200px',
								}),
							}),on:{"click":_vm.onMicrosoftContinue}},tooltipOn),[(_vm.microsoftLoading)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":"24","width":"1"}}):_c('img',{staticStyle:{"margin":"0"},attrs:{"alt":"Microsoft Logo","width":25,"src":require("../../assets/windows-icon.png")}}),(!_vm.iconView)?_c('div',{staticClass:"social-label no-select"},[_vm._v(_vm._s(_vm.getAuthProviderDisplayText('microsoft.com', true)))]):_vm._e()],1)]}}],null,false,121842585)},[_c('div',{staticClass:"tooltip-max-width no-select"},[_vm._v(" "+_vm._s(_vm.getAuthProviderDisplayText('microsoft.com', false))+" ")])]):_vm._e(),(_vm.includeFacebook)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('div',_vm._g({staticClass:"auth-provider-container",class:{
								'mx-3': _vm.iconView,
							},style:({
								...(_vm.iconView ? {
									'max-width': '47px',
								} : {
									'max-width': '200px',
								}),
							}),on:{"click":_vm.onFacebookContinue}},tooltipOn),[(_vm.facebookLoading)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":"24","width":"1"}}):_c('img',{staticStyle:{"margin":"0"},attrs:{"alt":"Facebook Logo","width":25,"src":require("../../assets/facebook-icon.png")}}),(!_vm.iconView)?_c('div',{staticClass:"social-label no-select"},[_vm._v(_vm._s(_vm.getAuthProviderDisplayText('facebook.com', true)))]):_vm._e()],1)]}}],null,false,3064448686)},[_c('div',{staticClass:"tooltip-max-width no-select"},[_vm._v(" "+_vm._s(_vm.getAuthProviderDisplayText('facebook.com', false))+" ")])]):_vm._e(),(_vm.includeGithub)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('div',_vm._g({staticClass:"auth-provider-container",class:{
								'mx-3': _vm.iconView,
							},style:({
								...(_vm.iconView ? {
									'max-width': '47px',
								} : {
									'max-width': '200px',
								}),
							}),on:{"click":_vm.onGithubContinue}},tooltipOn),[(_vm.githubLoading)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":"24","width":"1"}}):_c('img',{staticStyle:{"margin":"0"},attrs:{"alt":"Github Logo","width":25,"src":require("../../assets/github-icon.png")}}),(!_vm.iconView)?_c('div',{staticClass:"social-label no-select"},[_vm._v(_vm._s(_vm.getAuthProviderDisplayText('github.com', true)))]):_vm._e()],1)]}}],null,false,2342949251)},[_c('div',{staticClass:"tooltip-max-width no-select"},[_vm._v(" "+_vm._s(_vm.getAuthProviderDisplayText('github.com', false))+" ")])]):_vm._e(),(_vm.includeSso)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('div',_vm._g({staticClass:"auth-provider-container",class:{
								'mx-3': _vm.iconView,
							},style:({
								...(_vm.iconView ? {
									'max-width': '47px',
								} : {
									'max-width': '200px',
								}),
							}),on:{"click":function($event){_vm.ssoProviderInputDialogOpen = true}}},tooltipOn),[(_vm.ssoLoading)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":"24","width":"1"}}):(_vm.iconView)?_c('b',{staticStyle:{"font-size":"15px"}},[_vm._v("SSO")]):_vm._e(),(!_vm.iconView)?_c('div',{staticClass:"social-label no-select"},[_vm._v(_vm._s(_vm.getAuthProviderDisplayText('saml.', true)))]):_vm._e()],1)]}}],null,false,3260720927)},[_c('div',{staticClass:"tooltip-max-width no-select"},[_vm._v(" "+_vm._s(_vm.getAuthProviderDisplayText('saml.', false))+" ")])]):_vm._e()],1)]):_vm._e()]),_c('sso-provider-input-dialog',{on:{"on-cancel":function($event){_vm.ssoProviderInputDialogOpen = false},"on-submit":_vm.onSsoContinue},model:{value:(_vm.ssoProviderInputDialogOpen),callback:function ($$v) {_vm.ssoProviderInputDialogOpen=$$v},expression:"ssoProviderInputDialogOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }