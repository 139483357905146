import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import _findIndex from 'lodash/findIndex';
import _sortBy from 'lodash/sortBy';
import {
	asyncForEach,
	Avatar,
	getAvatarFilePath,
	getRunnitNodeRunResultFilePath,
	getRunnitUploadFilePath,
	RunnitNodeRun,
	RunnitNodeRunResult,
	RunnitUpload,
} from '@run-diffusion/shared';
import { NODE_RUN_RESULT_SELECTION_MODE } from '@/views/Runnits/constants';
import { RunnitsImageSelectMixin } from './RunnitsImageSelectMixin';
import { db, storage } from '@/firebase';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { TeamUserMixin } from './TeamUserMixin';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const RunnitBulkActionsMixin = Vue.extend({
	mixins: [
		RunnitsImageSelectMixin,
		TeamUserMixin,
	],
	data () {
		return {
			RUNNIT_BULK_ACTIONS_TRIGGER_ID: 'RUNNIT_BULK_ACTIONS',

			bulkDeleteConfirmDialogOpen: false,
			deletingBulkImages: false,
			downloadingBulkImages: false,
		};
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
		]),
		isRunnitBulkActionMenuOpen () {
			return this.currentSelectionState && this.currentSelectionState.triggeringId === this.RUNNIT_BULK_ACTIONS_TRIGGER_ID;
		},
		selectedImagesCount () {
			return this._get(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID), 'selectedNodeRunResults.length', 0) +
				this._get(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID), 'selectedUploads.length', 0) +
				this._get(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID), 'selectedAvatars.length', 0);
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
		]),
		setBulkActionMenuOpen (isOpen) {
			if (isOpen) {
				this.enterSelectionMode(NODE_RUN_RESULT_SELECTION_MODE.MULTIPLE, this.RUNNIT_BULK_ACTIONS_TRIGGER_ID, null, ['IMG', 'VID']);
				return;
			}
			this.exitSelectionMode(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID);
			this.clearSelectionsFromState(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID);
		},
		setDeleteImagesDialog (isOpen) {
			// Note: make sure to add a watcher on `bulkDeleteConfirmDialogOpen` to use the double confirmation dialog ref to call the reset()
			this.bulkDeleteConfirmDialogOpen = isOpen;
		},
		async handleDownloadSelectedImages (zipFolderName = 'images.zip') {
			if (this.selectedImagesCount > 1) {
				this.handleDownloadSelectedImagesZip(zipFolderName);
			} else {
				this.handleDownloadSelectedImagesIndividually();
			}
		},
		async handleDownloadSelectedImagesZip (zipFolderName = 'images.zip') {
			try {
				this.downloadingBulkImages = true;
				const zip = new JSZip();
				await asyncForEach(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID).selectedNodeRunResults, async (nodeRunResult: RunnitNodeRunResult) => {
					const { imageUrl, fileName } = await this.fetchSrc(nodeRunResult, 'nodeRunResult');
					const fileData = await this.convertUrlToBase64(imageUrl);
					zip.file(fileName, fileData.split(',')[1], { base64: true });
				});
				await asyncForEach(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID).selectedUploads, async (upload: RunnitUpload) => {
					const { imageUrl, fileName } = await this.fetchSrc(upload, 'upload');
					const fileData = await this.convertUrlToBase64(imageUrl);
					zip.file(fileName, fileData.split(',')[1], { base64: true });
				});

				await asyncForEach(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID).selectedAvatars, async (avatar: Avatar) => {
					const { imageUrl, fileName } = await this.fetchSrc(avatar, 'avatar');
					const fileData = await this.convertUrlToBase64(imageUrl);
					zip.file(fileName, fileData.split(',')[1], { base64: true });
				});

				zip.generateAsync({ type: 'blob' }).then((content) => {
					saveAs(content, zipFolderName)
				})

				this.clearSelectionsFromState(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID);
				this.exitSelectionMode(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID);
			} catch (e) {
				console.error(e);
			} finally {
				this.downloadingBulkImages = false;
			}
		},
		async handleDownloadSelectedImagesIndividually () {
			try {
				this.downloadingBulkImages = true;
				const wait: Function = (): Promise<void> => new Promise(resolve => setTimeout(resolve, 250));

				await asyncForEach(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID).selectedNodeRunResults, async (nodeRunResult: RunnitNodeRunResult) => {
					const { imageUrl, fileName } = await this.fetchSrc(nodeRunResult, 'nodeRunResult');
					this.downloadImageByUrl(imageUrl, fileName);
					await wait();
				});
				await asyncForEach(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID).selectedUploads, async (upload: RunnitUpload) => {
					const { imageUrl, fileName } = await this.fetchSrc(upload, 'upload');
					this.downloadImageByUrl(imageUrl, fileName);
					await wait();
				});

				await asyncForEach(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID).selectedAvatars, async (avatar: Avatar) => {
					const { imageUrl, fileName } = await this.fetchSrc(avatar, 'avatar');
					this.downloadImageByUrl(imageUrl, fileName);
					await wait();
				});

				this.clearSelectionsFromState(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID);
				this.exitSelectionMode(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID);
			} catch (e) {
				console.error(e);
			} finally {
				this.downloadingBulkImages = false;
			}
		},
		async fetchSrc (input: RunnitNodeRunResult | RunnitUpload | Avatar, useCase: 'nodeRunResult' | 'upload' | 'avatar') {
			const nodeRunResult: RunnitNodeRunResult = input as RunnitNodeRunResult;
			const upload: RunnitUpload = input as RunnitUpload;
			const avatar: Avatar = input as Avatar;

			let filePath, fileName;
			if (useCase === 'upload') {
				const filePathData: { filePath: string } = getRunnitUploadFilePath(upload);
				filePath = filePathData.filePath;
				fileName = upload.name
			} else if (useCase === 'nodeRunResult') {
				const filePathData: { filePath: string } = getRunnitNodeRunResultFilePath(nodeRunResult);
				filePath = filePathData.filePath;
				fileName = nodeRunResult.file.name
			} else if (useCase === 'avatar') {
				const filePathData: { filePath: string } = getAvatarFilePath(avatar);
				filePath = filePathData.filePath;
				fileName = avatar.name
			}

			if (!fileName || !filePath) {
				throw new Error('cannot find image');
			}
			const fileRef: any = storage.ref(filePath);
			const imageUrl = await fileRef.getDownloadURL();
			return { fileName, imageUrl };
		},
		async convertUrlToBase64 (imageUrl) {
			try {
				const response = await fetch(imageUrl);
				const blob = await response.blob();

				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = () => resolve(reader.result);
					reader.onerror = reject;
					reader.readAsDataURL(blob);
				})
			} catch (err) {
				console.error('Error converting image to base64 for download', err);
			}
		},
		downloadImageByUrl (imageUrl: string, fileName: string) {
			fetch(imageUrl)
				.then(res => res.blob())
				.then(blob => {
					const url = URL.createObjectURL(blob);

					const link = document.createElement('a');
					link.href = url;
					link.download = fileName;

					document.body.appendChild(link);

					link.click();

					document.body.removeChild(link);
					URL.revokeObjectURL(url);
				})
				.catch((err) => { console.error(`failed to download image: ${fileName}`, err) });
		},
		batchNodeRunResultsByTheirRun (nodeRunResults: RunnitNodeRunResult[]) {
			return nodeRunResults.reduce((batches: Record<string, RunnitNodeRunResult[]>, nodeRunResult: RunnitNodeRunResult) => {
				if (!batches[nodeRunResult.nodeRunId]) {
					batches[nodeRunResult.nodeRunId] = [];
				}
				batches[nodeRunResult.nodeRunId].push(nodeRunResult);
				return batches;
			}, {});
		},
		async deleteNodeRunResultBatch (batch: RunnitNodeRunResult[], nodeRunId) {
			const count: number = batch.length;
			const nodeRunRef = db.doc(`runnitNodeRuns/${nodeRunId}`);
			const nodeRun: RunnitNodeRun = (await nodeRunRef.get()).data() as RunnitNodeRun;

			if (!this.isTeamAdmin && nodeRun.userId !== this.user.id) {
				return { success: false, count, reason: 'Did not generate' };
			}

			const deletedAt: any = new Date();
			const deletedByUserId = this.user.id;
			const results: RunnitNodeRunResult[] = nodeRun.results.map((nodeRunResult: RunnitNodeRunResult) => {
				let result: RunnitNodeRunResult = { ...nodeRunResult };
				if (_findIndex(batch, (nrr: RunnitNodeRunResult) => (nrr.uuid === nodeRunResult.uuid)) !== -1) {
					result = {
						...result,
						deletedAt,
						deletedByUserId,
					}
				}
				return result;
			});
			if (results.every((nodeRunResult: RunnitNodeRunResult) => (nodeRunResult.deletedAt !== null))) {
				// every result is deleted also delete the nodeRun
				try {
					await nodeRunRef.update({
						deletedAt,
						deletedByUserId: this.user.id,
						results,
					});
				} catch (err) {
					console.error('Error deleting node run', err);
					return { success: false, count, reason: 'Internal Error' }
				}
			} else {
				// there are still some results that haven't been deleted
				try {
					await nodeRunRef.update({
						results,
					});
				} catch (err) {
					console.error('Error deleting node run results', err);
					return { success: false, count, reason: 'Internal Error' }
				}
			}

			return { success: true, count, reason: 'We did good' };
		},
		async deleteUpload (upload: RunnitUpload) {
			const uploadRef = db.doc(`runnitUploads/${upload.id}`);

			if (upload.userId !== this.user.id) {
				return { success: false, count: 1, reason: 'Did not upload' };
			}

			try {
				await uploadRef.update({
					deletedAt: new Date(),
					deletedByUserId: this.user.id,
				});
			} catch (err) {
				console.error('Error deleting upload', err);
				return { success: false, count: 1, reason: 'Internal Error' };
			}

			return { success: true, count: 1, reason: 'We did good' };
		},
		async deleteAvatar (avatar: Avatar) {
			const avatarRef = db.doc(`avatars/${avatar.id}`);

			if (avatar.userId !== this.user.id) {
				return { success: false, count: 1, reason: 'Did not upload' };
			}

			try {
				await avatarRef.update({
					deletedAt: new Date(),
					deletedByUserId: this.user.id,
				});
			} catch (err) {
				console.error('Error deleting avatar', err);
				return { success: false, count: 1, reason: 'Internal Error' };
			}

			return { success: true, count: 1, reason: 'We did good' };
		},
		async handleDeleteSelectedImages () {
			this.deletingBulkImages = true;
			try {
				const resultsMap = {};
				const nodeRunResultsBatchMap = this.batchNodeRunResultsByTheirRun(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID).selectedNodeRunResults);
				await asyncForEach(Object.keys(nodeRunResultsBatchMap), async (nodeRunId) => {
					const { success, count, reason } = await this.deleteNodeRunResultBatch(nodeRunResultsBatchMap[nodeRunId], nodeRunId);
					if (!success) {
						if (!resultsMap[reason]) {
							resultsMap[reason] = 0;
						}
						resultsMap[reason] = resultsMap[reason] + count;
					} else {
						this.updateRunnitState({
							deletedImages: {
								...this.runnitState.deletedImages,
								nodeRunResults: [...this.runnitState.deletedImages.nodeRunResults, ...nodeRunResultsBatchMap[nodeRunId]],
							}
						});
					}
				});

				await asyncForEach(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID).selectedUploads, async (upload: RunnitUpload) => {
					const { success, count, reason } = await this.deleteUpload(upload);
					if (!success) {
						if (!resultsMap[reason]) {
							resultsMap[reason] = 0;
						}
						resultsMap[reason] = resultsMap[reason] + count;
					} else {
						this.updateRunnitState({
							deletedImages: {
								...this.runnitState.deletedImages,
								uploads: [...this.runnitState.deletedImages.uploads, upload],
							}
						});
					}
				});

				await asyncForEach(this.getSelectionStateByTrigger(this.RUNNIT_BULK_ACTIONS_TRIGGER_ID).selectedAvatars, async (avatar: Avatar) => {
					const { success, count, reason } = await this.deleteAvatar(avatar);
					if (!success) {
						if (!resultsMap[reason]) {
							resultsMap[reason] = 0;
						}
						resultsMap[reason] = resultsMap[reason] + count;
					} else {
						this.updateRunnitState({
							deletedImages: {
								...this.runnitState.deletedImages,
								avatars: [...this.runnitState.deletedImages.avatars, avatar],
							}
						});
					}
				});

				if (Object.keys(resultsMap).length) {
					const message = Object.keys(resultsMap).reduce((message, reason, index) => {
						return message + ` ${index ? '| ' : ''}${resultsMap[reason]}--${reason}`;
					}, 'Issues deleting: ');
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message,
						show: true,
						timeout: 30000,
					});
				}

				this.setBulkActionMenuOpen(false);
			} catch (err) {
				console.error('Error deleting images', err);
			} finally {
				this.deletingBulkImages = false;
				this.setDeleteImagesDialog(false);
			}
		},
	},
});
