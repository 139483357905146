
import Vue from 'vue';

export default Vue.extend({
	name: 'WhiteTransparentChip',
	props: {
		styles: { type: Object, default: () => ({}) },
		padding: { type: String, default: '4px 10px 5px 10px' },
		fontSize: { type: String, default: '14px' },
		width: { type: String, default: '55px' },
		height: { type: String, default: '30px' },
	},
	data () {
		return {};
	},
});
