
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import WindowStepper from '@/components/WindowStepper.vue';
import RunnitsSubscriptionCard from '@/views/Runnits/pages/RunnitsSubscriptionsPage/RunnitsSubscriptionCard.vue';
import RunnitsSubscriptionCardComingSoon from '@/views/Runnits/pages/RunnitsSubscriptionsPage/RunnitsSubscriptionCardComingSoon.vue';
import { CHECKOUT_USER_SUBSCRIPTION, ClubOffer } from '@run-diffusion/shared';
import _clamp from 'lodash/clamp';
import _omit from 'lodash/omit';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import IsMonthlySwitch from '@/views/Runnits/pages/RunnitsSubscriptionsPage/IsMonthlySwitch.vue';

export default Vue.extend({
	name: 'RunnitsSubscriptionsPage',
	data () {
		return {
			isMonthly: false,
			carouselModel: 0,
			goToCancelSubscriptionDialogOpen: false,
		};
	},
	created () {
		this.bindClubOffers();
	},
	computed: {
		...mapState([
			'clubOffers',
			'loadingClubOffers',
		]),
		...mapGetters([
			'clubOfferBenefits',
		]),
		showCcOptions () {
			return !!this.$route.query.showCcOptions;
		},
		visibleClubOffers () {
			return (this.clubOffers || []).filter((clubOffer: ClubOffer) => {
				return !!(
					this.clubOfferBenefits.isCc || // Their current subscription is an `isCc` subscription
					this.showCcOptions ||
					!clubOffer.benefits.isCc
				);
			});
		},
		carouselLength () {
			return (
				this.visibleClubOffers.length +
				1 // Enterprise coming soon selection
				// 1 Team coming soon selection
			);
		},
	},
	methods: {
		...mapActions([
			'bindClubOffers',
			'goToStripeSubscriptionPortal',
		]),
		onChangeShowCcOptions (val: boolean) {
			this.routerReplace(this.$route, this.$router, {
				name: this.$route.name,
				params: this.$route.params,
				query: {
					..._omit(this.$route.query, 'showCcOptions'),
					...(val && {
						showCcOptions: '1',
					}),
				},
			});
		},
		handleCtaClick ({ clubOffer, tokenClubAddOn, limitClubAddOn }) {
			if (clubOffer.isFreemium) {
				this.goToCancelSubscriptionDialogOpen = true;
			} else {
				const routeData = this.$router.resolve({
					name: 'GoToPayment',
					query: {
						checkoutAction: CHECKOUT_USER_SUBSCRIPTION,
						club: clubOffer.club,
						...(!this.isMonthly && {
							annual: '1',
						}),
						tokenClubAddOnId: tokenClubAddOn.id,
						limitClubAddOnId: limitClubAddOn.id,
					},
				});
				window.open(routeData.href, '_blank');
			}
		},
		handleStepClick (stepIndex: number) {
			this.carouselModel = stepIndex;
		},
		prevSlide () {
			if (this.carouselModel > 0) this.carouselModel--;
			this.carouselModel = _clamp(this.carouselModel, 0, this.carouselLength - 1);
		},
		nextSlide () {
			if (this.carouselModel < this.carouselLength - 1) this.carouselModel++;
			this.carouselModel = _clamp(this.carouselModel, 0, this.carouselLength - 1);
		},
	},
	components: {
		IsMonthlySwitch,
		ConfirmDialog,
		WindowStepper,
		RunnitsSubscriptionCard,
		RunnitsSubscriptionCardComingSoon,
	},
});
