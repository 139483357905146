
import Vue from 'vue';
import { mapState } from 'vuex';
import EmptyState from '@/components/states/EmptyState.vue';
import SoftwareOfferCard from '@/views/Sessions/SoftwareOffersList/SoftwareOfferCard.vue';

export default Vue.extend({
	name: 'SoftwareOffersList',
	props: {
		appOffer: { type: Object, required: true },
		softwareOffer: { type: Object, default: null },
	},
	computed: {
		...mapState([
			'softwareOffers',
		]),
		filteredSoftwareOffers () {
			return this.softwareOffers.filter(({ apps }) => apps.includes(this.appOffer.app));
		},
	},
	components: {
		SoftwareOfferCard,
		EmptyState,
	},
});
