
import Vue from 'vue';
import VueZoomer from 'vue-zoomer';
import moment from 'moment-timezone';
import { storage } from '@/firebase';
import { mapActions, mapState } from 'vuex';
import { ResizeObserver } from 'resize-observer';
import {
	RunnitNodeRunResult,
	RunnitUpload,
	THUMB_SIZE,
	getRunnitUploadFilePath,
	getRunnitNodeRunResultFilePath,
	Avatar,
	getAvatarFilePath,
	AVATAR_SIZE,
} from '@run-diffusion/shared';
import { NODE_RUN_RESULT_SELECTION_MODE } from '@/views/Runnits/constants';
import RunnitImageTimer from '@/views/Runnits/RunnitImageTimer.vue';
import _findIndex from 'lodash/findIndex';
import { SelectionState } from '@/store';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';
import { RUNNIT_NODE_RUN_RESULT_TYPE } from '@/constants/enums';

export default Vue.extend({
	name: 'RunnitImage',
	props: {
		timerStartAtMillis: { type: Number, default: null },
		disabled: { type: Boolean, default: false },
		nodeRunResult: { type: Object, default: null },
		upload: { type: Object, default: null },
		avatar: { type: Object, default: null },
		noClick: { type: Boolean, default: false },
		isThumb: { type: Boolean, default: false },
		isAvatar: { type: Boolean, default: false },
		clearable: { type: Boolean, default: false },
		width: { type: String, default: null },
		height: { type: String, default: null },
		isErrorPlaceholder: { type: Boolean, default: false },
		isLoadingPlaceholder: { type: Boolean, default: false },
		isInsideInfoCarousel: { type: Boolean, default: false },
		isSelectedImage: { type: Boolean, default: false },
		useZoomViewer: { type: Boolean, default: false },
		coldStartWarningSeconds: { type: Number, default: null },
	},
	mixins: [
		RunnitsImageSelectMixin,
	],
	data () {
		return {
			NODE_RUN_RESULT_SELECTION_MODE,
			WIDTH_XS_BREAKPOINT: 50,
			timerStartAtMoment: moment(),

			srcIsFetching: false,
			src: null,
			srcIsLoaded: false,
			srcIsError: false,

			// Observed dimensions
			dimensions: {
				width: 0,
				height: 0,
			},

			// VueZoomers
			zoomed: false,
			renderZoomViewer: true,
		};
	},
	mounted () {
		this.resizeObserver = new ResizeObserver(entries => {
			for (const entry of entries) {
				this.dimensions = {
					width: Math.round(entry.contentRect.width),
					height: Math.round(entry.contentRect.height),
				};
			}
		});

		this.resizeObserver.observe(this.$refs.runnitImageContainer);
	},
	beforeDestroy () {
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	},
	computed: {
		...mapState([
			'runnitState',
		]),
		computedTimerStartAtMillis () {
			return this.timerStartAtMillis || this.timerStartAtMoment.valueOf();
		},
		imgSizeStyles () {
			if (this.width || this.height) {
				return {
					...(this.width && { maxWidth: this.width }),
					...(this.height && { maxHeight: this.height }),
				};
			}
			if (this.isThumb) {
				return {
					maxHeight: `${THUMB_SIZE}px`,
					maxWidth: `${THUMB_SIZE}px`,
				};
			}
			if (this.isAvatar) {
				return {
					maxHeight: `${AVATAR_SIZE}px`,
					maxWidth: `${AVATAR_SIZE}px`,
				};
			}
			return {
				height: this.isInsideInfoCarousel ? 'auto' : '100%',
			};
		},
		isSelectionMode () {
			return !!(!this.noClick && this.currentSelectionState.nodeRunResultSelectionMode && this.currentSelectionState.canSelectTypes.includes(RUNNIT_NODE_RUN_RESULT_TYPE.IMG));
		},
		isSingleSelection () {
			return this.currentSelectionState.nodeRunResultSelectionMode === NODE_RUN_RESULT_SELECTION_MODE.SINGLE;
		},
		isMultiSelection () {
			return this.currentSelectionState.nodeRunResultSelectionMode === NODE_RUN_RESULT_SELECTION_MODE.MULTIPLE;
		},
		isSelected () {
			return !!(
				this.currentSelectionState.selectedNodeRunResults.length &&
				this.nodeRunResult &&
				_findIndex(this.currentSelectionState.selectedNodeRunResults, (n: RunnitNodeRunResult) => (n.uuid === this.nodeRunResult.uuid)) !== -1
			) ||
				!!(
					this.currentSelectionState.selectedUploads.length &&
					this.upload &&
					_findIndex(this.currentSelectionState.selectedUploads, (u: RunnitUpload) => (u.id === this.upload.id)) !== -1
				) ||
				!!(
					this.currentSelectionState.selectedAvatars.length &&
					this.avatar &&
					_findIndex(this.currentSelectionState.selectedAvatars, (a: Avatar) => (a.id === this.avatar.id)) !== -1
				);
		},
		computedLoadingContainerPadding () {
			let padding: number = 15;

			if (this.dimensions.width < 90) {
				padding = 10;
			}

			return padding;
		},
		useComparisonSlider () {
			return true;
		},
	},
	watch: {
		srcIsLoaded: {
			immediate: false,
			handler (newVal: boolean, oldVal: boolean) {
				if (newVal && newVal !== oldVal) {
					// The <img> that is wrapped by VueZoomer needs to be re-rendered to work properly
					this.renderZoomViewer = false;
					this.$nextTick(() => {
						this.renderZoomViewer = true;
					});
				}
			},
		},
		'$store.state.triggers.imageZoomResetTrigger': {
			immediate: true,
			async handler (newVal: number, oldVal: number) {
				if (
					newVal &&
					newVal !== oldVal &&
					this.$refs.zoomer
				) {
					this.onResetZoom();
				}
			},
		},
		nodeRunResult: {
			immediate: true,
			async handler (newVal: RunnitNodeRunResult, oldVal: RunnitNodeRunResult) {
				const newFileName: string = this._get(newVal, 'file.name') || null;
				const oldFileName: string = this._get(oldVal, 'file.name') || null;
				if (newVal && newFileName !== oldFileName) {
					this.timerStartAtMoment = moment();
					await this.fetchSrc(newVal, 'nodeRunResult');
				}
			},
		},
		upload: {
			immediate: true,
			async handler (newVal: RunnitUpload, oldVal: RunnitUpload) {
				const newFileName: string = this._get(newVal, 'name') || null;
				const oldFileName: string = this._get(oldVal, 'name') || null;
				if (newVal && newFileName !== oldFileName) {
					await this.fetchSrc(newVal, 'upload');
				}
			},
		},
		avatar: {
			immediate: true,
			async handler (newVal: Avatar, oldVal: Avatar) {
				const newFileName: string = this._get(newVal, 'name') || null;
				const oldFileName: string = this._get(oldVal, 'name') || null;
				if (newVal && newFileName !== oldFileName) {
					await this.fetchSrc(newVal, 'avatar');
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
		]),
		async retryLoopFetchSrc (filePath: string, numTries: number) {
			const fileRef = storage.ref(filePath);
			let count: number = 1;
			while (true) {
				try {
					this.src = await fileRef.getDownloadURL();

					if (this.isSelectedImage) {
						await this.addLoadedImageUrlToStore(this.src);
					}
					break;
				} catch (e) {
					if (count >= numTries) throw e;

					count++;
					await new Promise(resolve => setTimeout(resolve, 250)); // wait before next retry
				}
			}
		},
		async addLoadedImageUrlToStore (src) {
			const currentSelectionState: SelectionState = {
				...this.currentSelectionState,
				selectedLoadedAssets: [
					...this.currentSelectionState.selectedLoadedAssets,
					{
						src,
						nodeRunResult: this.nodeRunResult,
					}
				]
			};

			await this.updateRunnitState({
				currentSelectionState,
			});
		},
		async fetchSrc (input: RunnitNodeRunResult | RunnitUpload | Avatar, useCase: 'nodeRunResult' | 'upload' | 'avatar') {
			const nodeRunResult: RunnitNodeRunResult = input as RunnitNodeRunResult;
			const upload: RunnitUpload = input as RunnitUpload;
			const avatar: Avatar = input as Avatar;

			let filePath, thumbFilePath;
			if (useCase === 'upload') {
				const filePathData: { filePath: string, thumbFilePath: string } = getRunnitUploadFilePath(upload);
				filePath = filePathData.filePath;
				thumbFilePath = filePathData.thumbFilePath;
			} else if (useCase === 'nodeRunResult') {
				const filePathData: { filePath: string, thumbFilePath: string } = getRunnitNodeRunResultFilePath(nodeRunResult);
				filePath = filePathData.filePath;
				thumbFilePath = filePathData.thumbFilePath;
			} else if (useCase === 'avatar') {
				const filePathData: { filePath: string, thumbFilePath: string } = getAvatarFilePath(avatar);
				filePath = filePathData.filePath;
				thumbFilePath = filePathData.thumbFilePath;
			}

			if (
				(
					useCase === 'nodeRunResult' &&
					nodeRunResult &&
					nodeRunResult.file &&
					nodeRunResult.file.name
				) ||
				(
					useCase === 'upload' &&
					upload &&
					upload.name
				) ||
				(
					useCase === 'avatar' &&
					avatar &&
					avatar.name
				)
			) {
				try {
					this.srcIsFetching = true;
					this.srcIsError = false;
					this.srcIsLoaded = false;

					if (this.isThumb || this.isAvatar) {
						try {
							await this.retryLoopFetchSrc(thumbFilePath, 1);
						} catch (e) {
							// The thumb img wasn't created yet, so just default to the original
							await this.retryLoopFetchSrc(filePath, 40);
						}
					} else {
						await this.retryLoopFetchSrc(filePath, 40);
					}
				} catch (e) {
					console.error(e);
					this.srcIsError = true;
				} finally {
					this.srcIsFetching = false;
				}
			}
		},
		onSrcLoaded () {
			this.srcIsLoaded = true;
		},
		onClick () {
			if (this.isSelectionMode) {
				// TODO the value emitted from this isn't actually used.
				if (this.isSingleSelection) {
					this.$emit('on-single-select', {
						nodeRunResult: this.nodeRunResult,
						upload: this.upload,
						avatar: this.avatar,
					});
				} else if (this.isMultiSelection) {
					this.$emit('on-multi-select', {
						nodeRunResult: this.nodeRunResult,
						upload: this.upload,
						avatar: this.avatar,
					});
				}
			} else {
				this.$emit('on-click');
			}
		},
		onClearClick () {
			this.$emit('on-clear');
		},
		onZoomIn () {
			this.$refs.zoomer.zoomIn();
		},
		onResetZoom () {
			this.zoomed = false;
			this.$refs.zoomer.reset();
		},
		onZoomOut () {
			this.$refs.zoomer.zoomOut();
		},
	},
	components: {
		RunnitImageTimer,
		VZoomer: VueZoomer.Zoomer,
	},
});
