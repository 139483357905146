
import Vue from 'vue';
import _isNil from 'lodash/isNil';
import BaseSlider from '@/components/base/BaseSlider.vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';

export default Vue.extend({
	name: 'RunnitBaseSlider',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: Number, default: null },
		adminOnly: { type: Boolean, default: false },
	},
	methods: {
		_isNil,
	},
	components: {
		BaseSlider,
		RunnitDynamicFieldLabelRow,
	},
});
