
import Vue from 'vue';
import { mapActions } from 'vuex';
import BalanceCard from '@/components/BalanceCard/BalanceCard.vue';
import TimeYourSessionInputs from '@/components/TimeYourSessionInputs.vue';
import { SESSION_STATE } from '@/constants/enums';
import { SELECTED_STORAGE_TYPE } from '@/constants/constants';
import ExtendOfferPriceCard from '@/components/Toolbar/SessionTimer/ExtendOfferPriceCard.vue';
import SessionSoundsSettings from '@/components/SessionSoundsSettings.vue';
import HowToStopEarlyNotice from '@/components/HowToStopEarlyNotice.vue';
import ActionsIsland from '@/components/ActionsIsland.vue';

export default Vue.extend({
	name: 'ExtendSessionTimerDialog',
	props: {
		countDownTimerVal: { type: String, required: true },
		session: { type: Object, required: true },
	},
	data () {
		return {
			SELECTED_STORAGE_TYPE,
			dialogOpen: false,
			extending: false,
			selectedScheduledStopInHrs: 0,
			selectedScheduledStopInMins: 0,
		};
	},
	computed: {
		confirmDisabled () {
			return !!(
				(!this.selectedScheduledStopInHrs && !this.selectedScheduledStopInMins) ||
				this.extending
			);
		},
		extendMins () {
			if (
				this.selectedScheduledStopInHrs ||
				this.selectedScheduledStopInMins
			) {
				return (this.selectedScheduledStopInHrs * 60) + this.selectedScheduledStopInMins;
			}
			return 0;
		},
	},
	methods: {
		...mapActions([
			'extendSession',
		]),
		onCancel () {
			this.dialogOpen = false;
		},
		async onConfirm () {
			if (
				this.session.state !== SESSION_STATE.READY ||
				this.confirmDisabled
			) {
				return;
			}

			this.extending = true;
			const { success } = await this.extendSession({
				session: this.session,
				extendMins: this.extendMins,
			});

			if (success) {
				this.selectedScheduledStopInHrs = 0;
				this.selectedScheduledStopInMins = 0;
				this.onCancel();
			}

			this.extending = false;
		},
	},
	components: {
		ActionsIsland,
		HowToStopEarlyNotice,
		SessionSoundsSettings,
		ExtendOfferPriceCard,
		TimeYourSessionInputs,
		BalanceCard,
	},
});
