import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"full-width"},[_c(VRow,[_c('div',{staticClass:"full-width mb-4 px-3",staticStyle:{"margin":"-20px 0 0 0"}},[_c('info-asset-banner',{attrs:{"info-flex":_vm.$vuetify.breakpoint.lgAndUp ? 9 : 9,"asset-flex":_vm.$vuetify.breakpoint.lgAndUp ? 4 : 2},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('div',{staticStyle:{"font-size":"20px"}},[_vm._v(" With "),_c('b',[_vm._v("Workshops")]),_vm._v(" you can train or teach a class with a dozen or a hundred different participants at once: ")]),_c('div',{staticClass:"mt-6",class:{
							'ml-3': _vm.$vuetify.breakpoint.smAndUp,
						},staticStyle:{"font-size":"17px"}},[_c('list-item',{attrs:{"icon":"mdi-check-circle"}},[_vm._v("Manage and pay for all participants balance")]),_c('list-item',{attrs:{"icon":"mdi-check-circle"}},[_vm._v("Create an "),_c('span',{staticClass:"gradient-text"},[_vm._v("invitation link")]),_vm._v(" to send to your participants")])],1)]},proxy:true},{key:"asset",fn:function(){return [_c('div',{class:{
							'px-8': _vm.$vuetify.breakpoint.smAndDown,
							'pl-2 mr-12': _vm.$vuetify.breakpoint.mdAndUp,
						}},[_c('img',{staticStyle:{"margin":"0 -100px"},attrs:{"alt":"Spaces","width":(
								_vm.$vuetify.breakpoint.smAndUp
									? _vm.$vuetify.breakpoint.xlOnly
										? '650px'
										: '550px'
									: '340px'
							),"src":require("../Workshops/assets/online-video-meeting.png")}})])]},proxy:true}])})],1)]),(_vm.team && _vm.user)?_c('work-shops',{staticClass:"mb-12"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }