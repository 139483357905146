"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLogicOnFieldsFromPrivateField = exports.getLogicOnFieldsFromField = void 0;
const lodash_1 = require("lodash");
const getLogicOnFieldsFromField = (field, inputValues) => {
    const result = {
        allDisables: [],
        allShows: [],
        triggeredDisables: [],
        triggeredShows: [],
    };
    const logicFieldDefUuids = (0, lodash_1.keys)((0, lodash_1.get)(field, 'logic.field') || {});
    logicFieldDefUuids.forEach((fieldDefUuid) => {
        if (!field.logic)
            return;
        const val = (inputValues || {})[fieldDefUuid] || null;
        const { disable, show } = field.logic.field[fieldDefUuid];
        if (disable)
            result.allDisables.push(disable);
        if (show)
            result.allShows.push(show);
        if (disable &&
            ((disable.onValue === true && val) ||
                ((0, lodash_1.isArray)(disable.onValue) && disable.onValue.some((v) => v === val)))) {
            result.triggeredDisables.push(disable);
        }
        if (show &&
            ((show.onValue === true && val) ||
                ((0, lodash_1.isArray)(show.onValue) && show.onValue.some((v) => v === val)))) {
            result.triggeredShows.push(show);
        }
    });
    return result;
};
exports.getLogicOnFieldsFromField = getLogicOnFieldsFromField;
const getLogicOnFieldsFromPrivateField = (field, inputValues) => {
    const result = {
        allShows: [],
        triggeredShows: [],
    };
    const logicFieldDefUuids = (0, lodash_1.keys)((0, lodash_1.get)(field, 'logic.field') || {});
    logicFieldDefUuids.forEach((fieldDefUuid) => {
        if (!(0, lodash_1.get)(field, 'logic.field'))
            return;
        const val = (inputValues || {})[fieldDefUuid] || null;
        const { show } = (0, lodash_1.get)(field, `logic.field[${fieldDefUuid}]`);
        if (show) {
            result.allShows.push(show);
            if ((show.onValue === true && val) ||
                ((0, lodash_1.isArray)(show.onValue) && show.onValue.some((v) => v === val))) {
                result.triggeredShows.push(show);
            }
        }
    });
    return result;
};
exports.getLogicOnFieldsFromPrivateField = getLogicOnFieldsFromPrivateField;
