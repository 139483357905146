<template>
	<div class="px-4 pb-12 d-flex flex-column align-center justify-center" style="max-height: 600px">
		<h2 style="margin: 200px 0 50px 0;">RunDiffusion.com</h2>
		<h1 class="text-center" style="max-width: 900px; margin: 50px 0 50px 0; font-weight: 900; color: var(--grey-300)">
			We are currently experiencing an outage from our largest upstream provider
		</h1>
		<h4 class="text-center" style="color: var(--grey-500); margin: 0 0 50px 0;">
			Please visit our discord for any updates
		</h4>
		<join-our-discord invite-link-redirect-only/>
	</div>
</template>

<script>
import Vue from 'vue';
import JoinOurDiscord from '../components/Toolbar/JoinOurDiscord';
export default Vue.extend({
	name: 'ServerMaintenance',
	components: {
		JoinOurDiscord,
	},
});
</script>
