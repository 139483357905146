
import Vue from 'vue';
import RunnitImage from '@/views/Runnits/RunnitImage.vue';

export default Vue.extend({
	name: 'RunnitSelectedImage',
	props: {
		nodeRunResult: { type: Object, default: null },
		upload: { type: Object, default: null },
		coveredByMask: { type: Boolean, default: false },
	},
	components: {
		RunnitImage,
	},
});
