
import Vue from 'vue';
import ListItem from '@/components/base/ListItem.vue';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';

export default Vue.extend({
	name: 'SessionSoundsSettings',
	props: {
		previewBtnClass: { type: [String, Object], default: null },
	},
	data () {
		return {
			previewExpanded: false,
		};
	},
	computed: {
		...mapState([
			'sessionSoundsOff',
		]),
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'setSessionSoundsOff',
		]),
		onChangeSounds (isOn) {
			this.setSessionSoundsOff(!isOn);
			this.updateSnackbar({
				status: SNACKBAR_STATUS.SUCCESS,
				message: 'Sound settings updated',
				show: true,
			});
		},
		previewSessionStart () {
			this.$autoSoundMachine.playSessionStart(true);
		},
		previewSessionEndsInMinutes () {
			this.$autoSoundMachine.playSessionEndsInMinutes(true);
		},
		previewSessionEndsInSeconds () {
			this.$autoSoundMachine.playSessionEndsInSeconds(true, 4100);
		},
	},
	components: {
		ListItem,
	},
});
