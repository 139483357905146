
import Vue from 'vue';
import _trim from 'lodash/trim';
import { mapActions, mapState } from 'vuex';
import { functions } from '@/firebase';
import { Workshop } from '@run-diffusion/shared';
import { SNACKBAR_STATUS } from '@/constants/constants';
import moment from 'moment-timezone';
import ListItem from '@/components/base/ListItem.vue';
import TimePicker from '@/components/base/TimePicker.vue';
import BaseDrawer from '@/components/base/BaseDrawer.vue';
import DatePicker from '@/components/base/DatePicker.vue';
import InfoAssetBanner from '@/components/base/InfoAssetBanner.vue';
import DrawerSettingsRow from '@/components/base/DrawerSettingsRow.vue';
import DrawerSettingsContainer from '@/components/base/DrawerSettingsContainer.vue';
import { combineDateAndTime, convertMomentToDateTimeInputValues, ISO_DATE_PICKER_FORMAT } from '@/utils';

export default Vue.extend({
	name: 'AddEditWorkshop',
	props: {
		workshop: { type: Object, default: null },
		isEditMode: { type: Boolean, default: false },
		value: { type: Boolean, default: false },
	},
	data () {
		const currTimeZone = moment.tz.guess();
		return {
			currTimeZone,
			open: false,
			name: null,
			localStartAtDate: null,
			localStartAtTimeInMinutes: null,
			contactName: null,
			contactEmail: null,
			EVERY_50_GB_PRICE: 1250,
			FLOOR_PRICE: 2500,
			savingWorkshop: false,

			// TEAM USERS
			// selectedCoordinatorUsers: [],
			teamUserItems: [],
			loadingTeamUsers: false,

			// FORM
			formValid: true,
			rules: {
				required: [
					v => !!_trim(v) || 'Required',
				],
			},
		};
	},
	async created () {
		this.open = !!this.value;
		if (this.isEditMode) {
			this.initWorkshop(this.workshop);
		}
		await this.initTeamUsers();
	},
	watch: {
		value (newVal: boolean) {
			this.open = !!newVal;
		},
		workshop (newVal: Workshop) {
			if (newVal) {
				this.initWorkshop(newVal);
			}
		},
	},
	computed: {
		...mapState([
			'user',
			'team',
		]),
		shouldEnableAdd () {
			return !!(
				this.name &&
				this.contactName &&
				this.contactEmail &&
				// this.selectedCoordinatorUsers.length &&
				this.formValid
			);
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		initWorkshop (workshop) {
			this.name = workshop ? workshop.name : null;
			this.contactName = workshop ? workshop.contactName : null;
			this.contactEmail = workshop ? workshop.contactEmail : null;

			const { dateStr, timeInMinutes } = convertMomentToDateTimeInputValues(moment(workshop.startAt.toMillis()));
			this.localStartAtDate = dateStr;

			this.localStartAtTimeInMinutes = timeInMinutes;
			// this.selectedCoordinatorUsers = workshop
			// 	? _keys(workshop.selectedCoordinatorUsers).map((key) => ({
			// 		email: workshop.selectedCoordinatorUsers[key].email,
			// 		userId: workshop.selectedCoordinatorUsers[key].userId,
			// 	}))
			// 	: [{
			// 		email: this.user.email,
			// 		userId: this.user.id,
			// 	}];
		},
		setLocalStartAtDate (isoStr) {
			this.localStartAtDate = isoStr;
		},
		setLocalStartAtTimeInMinutes (time) {
			this.localStartAtTimeInMinutes = time;
		},
		async initTeamUsers () {
			const onError: Function = (e) => {
				console.error('Error loading Team Members: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading team members',
					show: true,
				});
			};

			this.loadingTeamUsers = true;
			try {
				const functionRef = functions
					.httpsCallable('getTeamMembers');
				const { success, teamMembers } = (await functionRef({
					teamId: this.team.id,
					isPending: false,
					nextPage: null,
					pageSize: 100, // TODO: finish pagination
					afterId: null,
				})).data;

				if (!success) {
					onError(new Error('getTeamMembers returned undefined response'));
				}

				this.teamUserItems = teamMembers;
			} catch (e) {
				onError(e);
			} finally {
				this.loadingTeamUsers = false;
				if (!this.isEditMode) {
					this.contactEmail = this.user.email;
					// this.selectedCoordinatorUsers = [{
					// 	email: this.user.email,
					// 	userId: this.user.id,
					// }];
				}
			}
		},
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		closeWorkshopDrawer () {
			this.setOpen(false);
			this.resetWorkshopDrawer();
			this.$emit('on-close');
		},
		resetWorkshopDrawer () {
			this.name = null;
			this.contactName = null;
			this.contactEmail = null;
			// this.selectedCoordinatorUsers = [{
			// 	email: this.user.email,
			// 	userId: this.user.id,
			// }];
		},
		async onEditWorkshop () {
			try {
				this.savingWorkshop = true;

				const onError: Function = (e) => {
					console.error('Error updating workshop: ', e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error! Problem with updating a workshop, please reach out to report issues by clicking the support button in our top toolbar',
						show: true,
					});
				};

				const functionRef = functions
					.httpsCallable('updateWorkshop');

				const { success } = (await functionRef({
					name: this.name,
					contactName: this.contactName,
					contactEmail: this.contactEmail,
					// coordinatorUserIds: this.selectedCoordinatorUsers
					// 	.reduce(
					// 		(acc, user) => ({
					// 			...acc,
					// 			[user.userId]: true,
					// 		}),
					// 		{}
					// 	),
					workshopId: this.workshop.id,
					startAt: combineDateAndTime(this.localStartAtDate, this.localStartAtTimeInMinutes),
				})).data;

				if (!success) {
					onError(new Error('updateWorkshop returned undefined response'));
				} else {
					await this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! Workshop changes saved.`,
						show: true,
					});
					this.closeWorkshopDrawer();
				}
			} catch (e) {
				console.error(e);
				await this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error! Problem editing the workshop, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			} finally {
				this.savingWorkshop = false;
			}
		},
		async onCreateWorkshop () {
			try {
				this.savingWorkshop = true;
				const onError: Function = (e) => {
					console.error('Error adding a workshop: ', e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error! Problem with adding a workshop, please reach out to report issues by clicking the support button in our top toolbar',
						show: true,
					});
				};
				const functionRef = functions
					.httpsCallable('createWorkshop');

				const { success } = (await functionRef({
					createdAt: new Date(),
					name: this.name,
					contactName: this.contactName,
					contactEmail: this.contactEmail,
					// coordinatorUserIds: this.selectedCoordinatorUsers
					// 	.reduce(
					// 		(acc, user) => ({
					// 			...acc,
					// 			[user.userId]: true,
					// 		}),
					// 		{}
					// 	),
					teamId: this.team.id,
					startAt: combineDateAndTime(this.localStartAtDate, this.localStartAtTimeInMinutes),
				})).data;

				if (!success) {
					onError(new Error('createWorkshop returned undefined response'));
				} else {
					await this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! ${this.name} Workshop added.`,
						show: true,
					});
				}

				this.$emit('on-create-success');
				this.closeWorkshopDrawer();
			} catch (e) {
				await this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'Error adding workshop, try again or open a ticket in Discord',
					show: true,
				});
			} finally {
				this.savingWorkshop = false;
			}
		},
	},
	components: {
		TimePicker,
		DatePicker,
		ListItem,
		DrawerSettingsRow,
		DrawerSettingsContainer,
		InfoAssetBanner,
		BaseDrawer,
	},
});
