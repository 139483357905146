export const sliderThumbLabelMap = {
	1: 50,
	2: 100,
	3: 150,
	4: 200,
	5: 250,
	6: 300,
	7: 350,
	8: 400,
	9: 450,
	10: 500,
	11: 550,
	12: 600,
	13: 650,
	14: 700,
	15: 750,
	16: 800,
	17: 850,
	18: 900,
	19: 950,
	20: 1000,
};

export const sliderValueMap = {
	1: 50000000000,
	2: 100000000000,
	3: 150000000000,
	4: 200000000000,
	5: 250000000000,
	6: 300000000000,
	7: 350000000000,
	8: 400000000000,
	9: 450000000000,
	10: 500000000000,
	11: 550000000000,
	12: 600000000000,
	13: 650000000000,
	14: 700000000000,
	15: 750000000000,
	16: 800000000000,
	17: 850000000000,
	18: 900000000000,
	19: 950000000000,
	20: 1000000000000,
};

export const sliderValueReverseMap = {
	50000000000: 1,
	100000000000: 2,
	150000000000: 3,
	200000000000: 4,
	250000000000: 5,
	300000000000: 6,
	350000000000: 7,
	400000000000: 8,
	450000000000: 9,
	500000000000: 10,
	550000000000: 11,
	600000000000: 12,
	650000000000: 13,
	700000000000: 14,
	750000000000: 15,
	800000000000: 16,
	850000000000: 17,
	900000000000: 18,
	950000000000: 19,
	1000000000000: 20,
};

export const sliderTicksLabels: string[] = [
	'',
	'100',
	'',
	'200',
	'',
	'300',
	'',
	'400',
	'',
	'500',
	'',
	'600',
	'',
	'700',
	'',
	'800',
	'',
	'900',
	'',
	'1TB',
];

export const sliderTicksLabelsMobile: string[] = [
	'',
	'100',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'500',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'1TB',
];
