
import Vue from 'vue';
import { LaunchedSessionTimerMixin } from '@/mixins';
import AdminSessionStats from '@/components/AdminStats/AdminSessionStats.vue';
import { mapState } from 'vuex';
import _isNil from 'lodash/isNil';

export default Vue.extend({
	name: 'SessionTimer',
	mixins: [
		LaunchedSessionTimerMixin,
	],
	props: {
		isCountingUp: { type: Boolean, default: false },
		hideBtnsIfIsLaunchedWorkshopSessionAsAttendee: { type: Boolean, default: false },
		hideToggleShowCounter: { type: Boolean, default: false },
		hideAdminStats: { type: Boolean, default: false },
		highlightAlertMinutes: { type: Number, default: 2 }, // Highlight with color if 2 minutes left (default behavior)
	},
	data () {
		return {
			showCounter: true,
		};
	},
	// TODO: When the session stops, we should show a dialog explaining why
	computed: {
		...mapState([
			'user',
			'isAdminProxyingUser',
		]),
		hasBtnsSlot () {
			return !!this.$slots.btns || !!this.$scopedSlots.btns;
		},
		showHighlightAlert () {
			if (_isNil(this.highlightAlertMinutes)) return false;
			if (this.isCountingUp) {
				return this.highlightAlertMinutes >= 0 && (this.countUpTimerData.seconds / 60) >= this.highlightAlertMinutes;
			}
			return this.highlightAlertMinutes >= 0 && (this.countDownTimerData.seconds / 60) <= this.highlightAlertMinutes;
		},
	},
	methods: {
		toggleShowCounter () {
			this.showCounter = !this.showCounter;
		},
	},
	components: {
		AdminSessionStats,
	},
});
