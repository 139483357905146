
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import ListItem from '@/components/base/ListItem.vue';

export default Vue.extend({
	name: 'SettingsBtn',
	props: {
		isListItem: { type: Boolean, default: false },
	},
	data () {
		return {
			autoSoundMachineNeedsInit: false,
		};
	},
	computed: {
		...mapState([
			'sessionSoundsOff',
		]),
	},
	watch: {
		'$route' (newVal, oldVal) {
			if (
				!this.$autoSoundMachine.isInitialized() &&
				!this.sessionSoundsOff &&
				newVal.name === 'Launch' &&
				(
					!oldVal ||
					oldVal.name !== 'Launch'
				)
			) {
				// Pop confirm dialog over all the iframes so that our $autoSoundMachine can get a click listener to init itself
				this.autoSoundMachineNeedsInit = true;
			}
		},
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
		openSettings () {
			this.updateToolbar({
				settingsOpen: true,
				mobileMenuOpen: false,
			});
		},
	},
	components: {
		ListItem,
		ConfirmDialog,
	},
});
