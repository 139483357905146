var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"empty-state"},[_c('div',{staticClass:"design-elements-container",style:({
			...(_vm.$vuetify.breakpoint.xsOnly && {
				transform: 'scale(0.8)',
			}),
		})},[_c('div',{staticClass:"empty-state-circle",style:({
					width: `${_vm.circleWidth}px`,
					height: `${_vm.circleHeight}px`,
				})}),_vm._m(0)]),_c('div',{staticClass:"empty-state-content"},[_vm._t("default",function(){return [_c('div',{staticClass:"empty-state-title",style:({
					margin: _vm.$vuetify.breakpoint.xsOnly ? '-10px 0 0 0' : '20px 0 0 0',
				})},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"empty-state-subtitle"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])]})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-containers"},[_c('div',{staticClass:"card-container card-container-one"},[_c('div',{staticClass:"card-container-image"}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"card-container-title-block",staticStyle:{"width":"70px"}}),_c('div',{staticClass:"card-container-subtitle-block",staticStyle:{"width":"150px"}})])]),_c('div',{staticClass:"card-container card-container-two"},[_c('div',{staticClass:"card-container-image"}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"card-container-title-block",staticStyle:{"width":"60px"}}),_c('div',{staticClass:"card-container-subtitle-block mb-2",staticStyle:{"width":"150px"}}),_c('div',{staticClass:"card-container-subtitle-block",staticStyle:{"width":"100px"}})])]),_c('div',{staticClass:"card-container card-container-three"},[_c('div',{staticClass:"card-container-image"}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"card-container-title-block",staticStyle:{"width":"90px"}}),_c('div',{staticClass:"card-container-subtitle-block",staticStyle:{"width":"150px"}})])])])
}]

export { render, staticRenderFns }