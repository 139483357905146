
import Vue from 'vue';

export default Vue.extend({
	name: 'GlassButton',
	props: {
		styles: { type: Object, default: () => ({}) },
		small: { type: Boolean, default: false },
		xSmall: { type: Boolean, default: false },
		selected: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
	},
	data () {
		return {};
	},
	computed: {
		computedPadding () {
			let padding = '10px 15px';

			if (this.small) {
				padding = '6px 12px';
			}

			if (this.xSmall) {
				padding = '3px 10px';
			}

			return padding;
		},
	},
});
