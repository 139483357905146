
import Vue from 'vue';

export default Vue.extend({
	props: {
		// iconName: {
		// 	type: String,
		// 	default: 'Loading Animation',
		// },
		// width: {
		// 	type: [Number, String],
		// 	default: 200,
		// },
		height: {
			type: [Number, String],
			default: 120,
		},
		// viewBox: {
		// 	type: String,
		// 	default: '0 0 100 100',
		// },
		// fill: {
		// 	type: String,
		// 	default: '#6f86ff',
		// },
	},
});
