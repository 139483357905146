import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"runnit-model-selector-container-outer",on:{"click":_vm.onOpenDialog}},[_c('div',{staticClass:"runnit-model-selector-container"},[_c('div',{staticClass:"runnit-model-image-container"},[_c('div',{staticClass:"runnit-model-image-name"},[_c('img',{staticClass:"runnit-model-image",attrs:{"alt":"Logo","height":"60px","src":require("./../assets/rundiffusion-experience-thumb-1.png")}}),(_vm.value)?_c('div',{staticClass:"runnit-model-name"},[_vm._v(" "+_vm._s(_vm.value.name)+" ")]):_c('div',{staticClass:"runnit-model-name"},[_vm._v(" Model name here ")])]),_c(VIcon,{staticStyle:{"font-size":"35px"},style:({
							color: hover ? 'var(--primary-blue)' : 'var(--grey-650)',
						})},[_vm._v("mdi-chevron-right-circle")])],1)])])]}}])}),_c('runnit-models-dialog',{attrs:{"el-id-suffix":"runnit-model-selector","models":[
			{id: '1', img: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fteams%2F2D8NEx094vgMBzEedOrJ%2Fruns%2FYJZUtNACUcypL2Cjlctr%2F5bc01fe2_e8bf-4026-a01b-5e93b8e2008f.jpeg?alt=media&token=1ab2a58a-f32f-4bff-af2c-f7964d68f8d5', name: 'test', description: 'info'},
			{id: '2', img: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fteams%2F2D8NEx094vgMBzEedOrJ%2Fruns%2FYJZUtNACUcypL2Cjlctr%2F5bc01fe2_e8bf-4026-a01b-5e93b8e2008f.jpeg?alt=media&token=1ab2a58a-f32f-4bff-af2c-f7964d68f8d5', name: 'test', description: 'info'},
			{id: '3', img: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fteams%2F2D8NEx094vgMBzEedOrJ%2Fruns%2FYJZUtNACUcypL2Cjlctr%2F5bc01fe2_e8bf-4026-a01b-5e93b8e2008f.jpeg?alt=media&token=1ab2a58a-f32f-4bff-af2c-f7964d68f8d5', name: 'test', description: 'info'},
			{id: '4', img: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fteams%2F2D8NEx094vgMBzEedOrJ%2Fruns%2FYJZUtNACUcypL2Cjlctr%2F5bc01fe2_e8bf-4026-a01b-5e93b8e2008f.jpeg?alt=media&token=1ab2a58a-f32f-4bff-af2c-f7964d68f8d5', name: 'test', description: 'info'},
			{id: '5', img: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fteams%2F2D8NEx094vgMBzEedOrJ%2Fruns%2FYJZUtNACUcypL2Cjlctr%2F5bc01fe2_e8bf-4026-a01b-5e93b8e2008f.jpeg?alt=media&token=1ab2a58a-f32f-4bff-af2c-f7964d68f8d5', name: 'test', description: 'info'},
			{id: '6', img: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fteams%2F2D8NEx094vgMBzEedOrJ%2Fruns%2FYJZUtNACUcypL2Cjlctr%2F5bc01fe2_e8bf-4026-a01b-5e93b8e2008f.jpeg?alt=media&token=1ab2a58a-f32f-4bff-af2c-f7964d68f8d5', name: 'test', description: 'info'},
			{id: '7', img: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fteams%2F2D8NEx094vgMBzEedOrJ%2Fruns%2FYJZUtNACUcypL2Cjlctr%2F5bc01fe2_e8bf-4026-a01b-5e93b8e2008f.jpeg?alt=media&token=1ab2a58a-f32f-4bff-af2c-f7964d68f8d5', name: 'test', description: 'info'},
			{id: '8', img: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fteams%2F2D8NEx094vgMBzEedOrJ%2Fruns%2FYJZUtNACUcypL2Cjlctr%2F5bc01fe2_e8bf-4026-a01b-5e93b8e2008f.jpeg?alt=media&token=1ab2a58a-f32f-4bff-af2c-f7964d68f8d5', name: 'test', description: 'info'},
			{id: '9', img: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fteams%2F2D8NEx094vgMBzEedOrJ%2Fruns%2FYJZUtNACUcypL2Cjlctr%2F5bc01fe2_e8bf-4026-a01b-5e93b8e2008f.jpeg?alt=media&token=1ab2a58a-f32f-4bff-af2c-f7964d68f8d5', name: 'test', description: 'info'},
			{id: '10', img: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/runnitResults%2Fteams%2F2D8NEx094vgMBzEedOrJ%2Fruns%2FYJZUtNACUcypL2Cjlctr%2F5bc01fe2_e8bf-4026-a01b-5e93b8e2008f.jpeg?alt=media&token=1ab2a58a-f32f-4bff-af2c-f7964d68f8d5', name: 'test', description: 'info'},
		],"show-load-more":""},on:{"on-model-select":_vm.onModelSelect},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }