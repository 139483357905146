import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VSwitch,{attrs:{"input-value":!_vm.sessionSoundsOff,"inset":""},on:{"change":_vm.onChangeSounds},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"pl-2"},[_vm._v(" Play sounds to notify me when the session is ready or is ending "),_c(VBtn,{staticClass:"ml-3",class:{
						..._vm.previewBtnClass,
						'mt-3': _vm.$vuetify.breakpoint.xsOnly,
					},attrs:{"small":"","outlined":"","rounded":""},on:{"click":function($event){$event.stopPropagation();_vm.previewExpanded = !_vm.previewExpanded}}},[_vm._v(" Listen to sounds "),_c(VIcon,{attrs:{"right":""}},[_vm._v(_vm._s(_vm.previewExpanded ? 'mdi-menu-down' : 'mdi-menu-right'))])],1)],1)]},proxy:true}])}),_c(VExpandTransition,[(_vm.previewExpanded)?_c('div',{staticClass:"pb-1 pl-12"},[_c('list-item',{attrs:{"icon":"mdi-play-box-outline"}},[_vm._v(" When the session is "),_c('b',[_vm._v("ready to go")]),_vm._v(", play "),_c(VBtn,{staticClass:"px-1",attrs:{"text":"","color":"primary"},on:{"click":_vm.previewSessionStart}},[_c('b',[_vm._v("this sound")])])],1),_c('list-item',{attrs:{"icon":"mdi-timer-alert-outline"}},[_vm._v(" When the session is "),_c('b',[_vm._v("10 or 2 minutes from ending")]),_vm._v(", play "),_c(VBtn,{staticClass:"px-1",attrs:{"text":"","color":"primary"},on:{"click":_vm.previewSessionEndsInMinutes}},[_c('b',[_vm._v("this sound")])])],1),_c('list-item',{attrs:{"icon":"mdi-alert-octagon-outline"}},[_vm._v(" When the session is "),_c('b',[_vm._v("30 seconds from ending")]),_vm._v(", play "),_c(VBtn,{staticClass:"px-1",attrs:{"text":"","color":"primary"},on:{"click":_vm.previewSessionEndsInSeconds}},[_c('b',[_vm._v("this sound")])])],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }