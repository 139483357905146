
import Vue from 'vue';

export default Vue.extend({
	name: 'WorkspacePricePreview',
	props: {
		selectedStorageAmount: { type: Number, required: true },
	},
	data () {
		return {
			EVERY_50_GB_PRICE: 1250,
			FLOOR_PRICE: 2500,
		};
	},
	computed: {
		selectedStoragePrice () {
			if (this.selectedStorageAmount === 2) {
				return this.FLOOR_PRICE;
			} else if (this.selectedStorageAmount === 1) {
				return this.EVERY_50_GB_PRICE;
			} else {
				return (this.selectedStorageAmount * this.EVERY_50_GB_PRICE) + this.EVERY_50_GB_PRICE;
			}
		},
	},
});
