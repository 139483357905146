import Vue from 'vue';
import { mapState } from 'vuex';
import moment, { Moment } from 'moment-timezone';

export const LaunchedSessionTimerMixin = Vue.extend({
	props: {
		session: { type: Object, default: null },
	},
	data () {
		return {
			TEN_MIN_TIME_VAL: '0:10:00',
			TWO_MIN_TIME_VAL: '0:02:00',
			THIRTY_SEC_TIME_VAL: '0:00:30',
		};
	},
	computed: {
		...mapState([
			'user',
			'toolbar',
			'realTimeMoment',
		]),
		launchedSession () {
			return this.session || this._get(this.toolbar, 'session') || null;
		},
		isLaunchedWorkshopSessionAsAttendee () {
			return !!(
				this.launchedSession &&
				this.launchedSession.workshopSessionId &&
				this.launchedSession.workshopSessionQueueItemId === this.user.id
			);
		},
		countDownTimerData () {
			if (
				!this.launchedSession ||
				!this.launchedSession.readyAt ||
				!this.launchedSession.scheduledStopAt
			) return {
				seconds: 0,
				hrDiff: 0,
				minDiff: 0,
				secDiff: 0,
			};

			const scheduledAtMillis: number = this.launchedSession.scheduledStopAt.toMillis();
			const scheduledAtMoment: Moment = moment(scheduledAtMillis);

			const seconds: number = scheduledAtMoment.diff(this.realTimeMoment, 'seconds');
			return {
				seconds: Math.max(0, seconds),
				hrDiff: scheduledAtMoment.diff(this.realTimeMoment, 'hours'),
				minDiff: scheduledAtMoment.diff(this.realTimeMoment, 'minutes') % 60,
				secDiff: scheduledAtMoment.diff(this.realTimeMoment, 'seconds') % 60,
			};
		},
		countUpTimerData () {
			if (
				!this.launchedSession ||
				!this.launchedSession.readyAt
			) return {
				seconds: 0,
				hrDiff: 0,
				minDiff: 0,
				secDiff: 0,
			};

			const readyAtMillis: number = this.launchedSession.readyAt.toMillis();

			const seconds: number = this.realTimeMoment.diff(readyAtMillis, 'seconds');
			return {
				seconds: Math.max(0, seconds),
				hrDiff: this.realTimeMoment.diff(readyAtMillis, 'hours'),
				minDiff: this.realTimeMoment.diff(readyAtMillis, 'minutes') % 60,
				secDiff: this.realTimeMoment.diff(readyAtMillis, 'seconds') % 60,
			};
		},
		countDownTimerVal () {
			const {
				seconds,
				hrDiff,
				minDiff,
				secDiff,
			} = this.countDownTimerData;
			if (seconds <= 0) return '0:00';
			return `${hrDiff}:${minDiff <= 9 ? '0' : ''}${minDiff}:${secDiff <= 9 ? '0' : ''}${secDiff}`;
		},
		countUpTimerVal () {
			const {
				seconds,
				hrDiff,
				minDiff,
				secDiff,
			} = this.countUpTimerData;
			if (seconds <= 0) return '0:00';
			return `${hrDiff}:${minDiff <= 9 ? '0' : ''}${minDiff}:${secDiff <= 9 ? '0' : ''}${secDiff}`;
		},
	},
});
