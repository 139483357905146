
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import SessionTimer from '@/components/Toolbar/SessionTimer/SessionTimer.vue';
import ClipboardCopy from '@/components/ClipboardCopy.vue';
import DoubleConfirmDangerDialog from '@/components/base/DoubleConfirmDangerDialog.vue';
import { BOOTING_SESSION_STATES, READY_SESSION_STATES } from '@/constants/constants';
import { SESSION_STATE } from '@/constants/enums';
import AdminSessionStats from '@/components/AdminStats/AdminSessionStats.vue';
import AdminUserStats from '@/components/AdminStats/AdminUserStats/AdminUserStats.vue';
import AdminSoftwareOfferStats from '@/components/AdminStats/AdminSoftwareOfferStats.vue';

export default Vue.extend({
	name: 'AdminSessionCard',
	props: {
		session: { type: Object, required: null },
	},
	data () {
		return {
			BOOTING_SESSION_STATES,
			stoppingSession: false,
		};
	},
	computed: {
		...mapState([
			'softwareOffersMap',
		]),
		stateColor () {
			switch (this.session.state) {
				case SESSION_STATE.PURCHASE:
				case SESSION_STATE.INIT:
				case SESSION_STATE.BOOT:
					return 'lightblue';
				case SESSION_STATE.READY:
				case SESSION_STATE.EXTEND:
					return 'lightgreen';
				case SESSION_STATE.STOP:
				case SESSION_STATE.RECONCILE:
				case SESSION_STATE.STOPPED:
					return 'orangered';
				default:
					return null;
			}
		},
		canStopSession () {
			return [
				...BOOTING_SESSION_STATES,
				...READY_SESSION_STATES,
			].includes(this.session.state);
		},
	},
	methods: {
		...mapActions([
			'stopSession',
		]),
		onManageUserClick () {
			this.routerPush(this.$route, this.$router, {
				name: 'AdminUsersManagement',
				query: {
					userEmail: this.session.email,
				},
			});
		},
		async handleStopSessionConfirm (closeAndResetCallback: Function) {
			this.stoppingSession = true;
			const { success } = await this.stopSession(this.session);
			this.stoppingSession = false;
			if (closeAndResetCallback) {
				closeAndResetCallback();
			}
		},
	},
	components: {
		AdminSoftwareOfferStats,
		AdminUserStats,
		AdminSessionStats,
		DoubleConfirmDangerDialog,
		ClipboardCopy,
		SessionTimer,
	},
});
