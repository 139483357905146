
import Vue from 'vue';

export default Vue.extend({
	name: 'ListItem',
	props: {
		icon: { type: String, default: null },
		iconColor: { type: String, default: 'var(--primary-blue)' },
		iconSize: { type: String, default: '18px' },
		centeredIcon: { type: Boolean, default: false },
		iconStyle: { type: Object, default: () => ({}) },
		iconClasses: { type: Object, default: () => ({ 'mr-4': true }) },
		containerStyles: { type: Object, default: () => ({ marginBottom: '20px !important' }) },
		contentContainerStyles: { type: Object, default: () => ({}) },
	},
});
