
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'DiscordClusterSessionsDashboard',
	computed: {
		...mapState([
			'currSessions',
		]),
	},
});
